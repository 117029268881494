define("client/pods/components/elements/slider/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "tiny-slider/src/tiny-slider"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, _tinySlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ElementsSliderComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ElementsSliderComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "carousels", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "originalSection", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tinySlider", _descriptor3, this);
    }

    updateButtonStyle(btn, btnClassesNames, icon) {
      btn.textContent = '';
      btn.classList.add(btnClassesNames);
      const span = document.createElement('span');
      span.className = 'icon';
      const i = document.createElement('i');
      i.className = icon;
      span.appendChild(i);
      btn.appendChild(span);
    }

    setProductImage(product, img) {
      img.src = product.getDefaultImageUrl('/-/scale_crop/200x200/smart/');
    }

    setup() {
      const options = this.args.options || {};
      const defaultOptions = {
        autoplay: false,
        container: '.tiny-slider-container',
        gutter: 50,
        items: 1,
        mouseDrag: true,
        slideBy: 1,
        swipeAngle: false,
        nav: false
      };
      this.tinySlider = (0, _tinySlider.tns)(R.mergeRight(defaultOptions, options));
      this.updateBtn();
    }

    updateBtn() {
      if (this.tinySlider) {
        const slider = this.tinySlider.getInfo();
        const nextBtn = slider.nextButton;
        this.updateButtonStyle(nextBtn, 'slider-button', 'fas fa-angle-right');
        const prevBtn = slider.prevButton;
        this.updateButtonStyle(prevBtn, 'slider-button', 'fas fa-angle-left');
      }
    }

    addTappable(el, link) {
      const tappable = 'u-tappable';
      const alreadyTappable = el.classList.toString().includes(tappable);
      const isLinkEmpty = R.isEmpty(link.toString());

      if (alreadyTappable && isLinkEmpty) {
        el.classList.remove(tappable);
      } else if (!isLinkEmpty) {
        el.classList.add(tappable);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carousels", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "originalSection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tinySlider", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setProductImage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setProductImage"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateBtn", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateBtn"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addTappable", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "addTappable"), _class.prototype)), _class));
  _exports.default = ElementsSliderComponent;
});