define("client/pods/components/news/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FGn/lhz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"list-results__table\"],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"list-results__table-thumb\"],[8],[0,\"\\n      \"],[1,[28,\"files/image-item\",null,[[\"image\",\"transform\"],[[24,[\"result\",\"defaultImage\"]],\"/-/resize/40x\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n        \"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"setType\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[1,[28,\"tt\",[[24,[\"result\",\"_data\",\"setType\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"tagClassNames\",\"value\"],[[23,0,[\"crud\",\"lists\",\"statuses\"]],[24,[\"statusTagClassName\"]],[24,[\"result\",\"_data\",\"status\"]]]]],false],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/news/search-results/template.hbs"
    }
  });

  _exports.default = _default;
});