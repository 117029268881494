define("client/pods/components/payments/card/panels-transactions/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let PaymentsCardPanelsTransactionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember.inject.service('date'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class PaymentsCardPanelsTransactionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "searchService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "period", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "pastMonths", _descriptor5, this);
      (0, _defineProperty2.default)(this, "adapterName", 'payments/card/transactions');
      (0, _defineProperty2.default)(this, "adapterNameFilters", 'payments/card/transactions/filters');
      this.setPeriod();
      this.pastMonths = this.dateService.getPastMonths(14);
      this.searchRecordsTask.perform();
    }

    updateFilters() {
      var _this$args;

      this.args.filters.updateProvider(this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.selectedProvider);
      this.args.filters.updatePeriod(this.period);
    }

    *searchOnPeriodUpdateTask(period) {
      this.setPeriod(period);
      yield this.searchRecordsTask.perform();
    }

    *searchRecordsTask() {
      var _this$args2;

      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.filters,
        resultsProperty: 'results',
        resultsToggle: 'resultsToggle'
      });
    } // @task
    // * onReloadSearchRecordsTask () {
    //   yield this.crud.reloadSearchRecordsTask.perform({
    //     adapterName: this.adapterName,
    //     filters: this?.args?.filters,
    //     resultsProperty: 'results',
    //     resultsToggle: 'resultsToggle',
    //     reset: true
    //   })
    // }


    setPeriod(period) {
      var _this$args3;

      period = period || (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.period) || this.dateService.getPeriod();
      this.period = R.mergeRight(period, this.dateService.displayByMonth(period));
      this.updateFilters();
    }

    setupFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      const filename = `${this.intl.t('credit card transactions')} ${dateStart}-${dateEnd}`;
      this.set('filename', filename);
    }

    displayByMonth(period) {
      period.dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      period.dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.setPeriod(period);
      this.searchRecordsTask.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "period", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pastMonths", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchOnPeriodUpdateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchOnPeriodUpdateTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchRecordsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setPeriod", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setPeriod"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupFilename", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilename"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayByMonth", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "displayByMonth"), _class.prototype)), _class));
  _exports.default = PaymentsCardPanelsTransactionsComponent;
});