define("client/pods/components/products/picker-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nAsvIe5T",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"searchContainer\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@closeOnOverlayClick\",\"@onToggle\"],[\"search products\",[22,\"modalDialogIsVisible\"],true,[28,\"fn\",[[28,\"mut\",[[24,[\"modalDialogIsVisible\"]]],null]],null]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"products/search-bar-container\",null,[[\"results\"],[[24,[\"results\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"products/search-bar-container/search-bar\",null,[[\"autofocus\",\"placeholder\",\"searchInputTask\",\"listByTask\",\"lists\",\"filters\",\"query\"],[true,\"search\",[23,2,[\"searchInputTask\"]],[23,2,[\"listByTask\"]],[23,2,[\"lists\"]],[23,2,[\"filters\"]],[24,[\"query\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"products/search-bar-container/search-results\",null,[[\"results\",\"lists\",\"onSelect\"],[[23,2,[\"results\"]],[23,2,[\"lists\"]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"helpers/pagination-controls\",null,[[\"showPage\",\"filters\",\"list\",\"onPaginationTask\"],[true,[24,[\"filters\"]],[23,2,[\"results\"]],[23,2,[\"paginateSearchRecordsTask\"]]]]],false],[0,\"\\n\\n\"],[4,\"layouts/footer-btns\",null,null,{\"statements\":[[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"elements/btn-cancel\",null,[[\"onSubmit\"],[[28,\"action\",[[23,0,[]],\"cancel\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/picker-modal/template.hbs"
    }
  });

  _exports.default = _default;
});