define("client/pods/components/extensions/apps/tw/invoices/print-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zi4Oeb3C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\"],[\"invoice number\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"inputClassNames\",\"placeholder\",\"value\"],[\"form__input-large\",\"auto\",[24,[\"customInvoiceNumber\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\"],[\"date\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"inputClassNames\",\"placeholder\",\"isDatePicker\",\"dateZ\"],[\"form__input-large\",\"auto\",true,[24,[\"customInvoiceDateZ\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1,[[28,\"hash\",null,[[\"customInvoiceNumber\",\"customInvoiceDateZ\"],[[24,[\"customInvoiceNumber\"]],[24,[\"customInvoiceDateZ\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/print-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});