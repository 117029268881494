define("client/pods/components/settings/settings-editor/3rd-party-editor/component", ["exports", "ramda-extension"], function (_exports, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleEInvoice(provider, value) {
        const model = this.model;
        const systemleadBeta = R_.dotPath('_data.beta.systemlead')(model);
        const ecPayBeta = R_.dotPath('_data.beta.ecPay')(model);

        if (systemleadBeta && ecPayBeta) {
          if (provider === 'systemlead') {
            if (value) {
              model.setData('tw_eInvoice_ecPay.enabled', false); // } else {
              //   model.setData('tw_eInvoice_ecPay.enabled', true)
            }
          }

          if (provider === 'ecPay') {
            if (value) {
              model.setData('tw_eInvoice_systemlead.enabled', false);
            }
          }
        }
      }

    }
  });

  _exports.default = _default;
});