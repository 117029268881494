define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commissions-adjustment-editor/adjustment-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wx+6E7J5",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commissions-adjustment-editor/adjustment-list/template.hbs"
    }
  });

  _exports.default = _default;
});