define("client/pods/components/workflows/panels-display/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let WorkflowsPanelDisplayComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = (_temp = class WorkflowsPanelDisplayComponent extends _component.default {
    constructor() {
      var _this$args$model, _this$args$model$_dat;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "workflowData", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isSideBarVisible", _descriptor6, this);

      if (RA.isNotNilOrEmpty((_this$args$model = this.args.model) === null || _this$args$model === void 0 ? void 0 : (_this$args$model$_dat = _this$args$model._data) === null || _this$args$model$_dat === void 0 ? void 0 : _this$args$model$_dat.stages)) {
        this.setupTask.perform();
      }
    }

    *setupTask() {
      this.workflowCollection = yield this.crud.findTask.perform({
        adapterName: 'workflows/stagesCollection',
        filters: this.args.filters,
        component: this,
        resultsProperty: ''
      });
    }

    *updateItemStageIdOnDrop(toStageId, draggedItemKey) {
      const itemsByStages = this.workflowCollection.byStages || [];
      const items = R.pipe(R.map(R.prop('items')), R.flatten)(itemsByStages);
      const itemModel = R.find(R.pathEq(['_data', '_key'], draggedItemKey))(items);
      const dirty = this.crud.setupDirty({
        adapterName: 'workflows/items',
        model: itemModel
      });
      dirty.setData('stageId', toStageId);
      yield this.crud.updateRecordTask.perform({
        adapterName: 'workflows/items',
        model: dirty,
        component: this
      });
    }

    toggleIsSideBarVisible() {
      this.isSideBarVisible = !this.isSideBarVisible;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "workflowData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSideBarVisible", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateItemStageIdOnDrop", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateItemStageIdOnDrop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleIsSideBarVisible", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsSideBarVisible"), _class.prototype)), _class));
  _exports.default = WorkflowsPanelDisplayComponent;
});