define("client/pods/components/helpers/info-tooltip-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jy4bQGiC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isViewable\"]],[24,[\"hasContent\"]]],null]],null,{\"statements\":[[4,\"modals/modal-container\",null,[[\"classNames\",\"closeOnOverlayClick\",\"toggle\",\"autoPosition\",\"hasModalOverlay\",\"hasCustomBlock\",\"modalContentClassNames\"],[[24,[\"modalContentClassNames\"]],true,[24,[\"isViewable\"]],[24,[\"autoPosition\"]],[24,[\"hasModalOverlay\"]],[24,[\"hasCustomModalBlock\"]],\"info-tooltip-modal modal-container__content--fixed-height u-margin-top--6\"]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/info-tooltip-content/template.hbs"
    }
  });

  _exports.default = _default;
});