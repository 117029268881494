define("client/pods/components/forms/form-input-row/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    error: null,
    // classNameBindings: ['error:has-error'],
    customBlock: false,
    // complete custom block template
    translate: true,
    hideIsEmpty: false,
    hasErrorClassNames: Ember.computed('error', function () {
      const error = this.error;

      if (error) {
        if (R.is(Array, error) && R.isEmpty(error)) {
          return '';
        }

        return 'has-error';
      }

      return '';
    }),
    show: Ember.computed('hideIsEmpty', 'value', 'model', 'context', function () {
      const context = this.context;
      const value = this.value || this.get(`model._data.${context}`);
      const hideIsEmpty = this.hideIsEmpty;

      if ((value == null || value === '') && hideIsEmpty) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});