define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/mixins/crud", "client/pods/components/channels/shop/campaigns/mixins/campaigns-products", "ember-concurrency", "client/utils/nventor", "jquery", "ramda-extension", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, R, _crud, _campaignsProducts, _emberConcurrency, _nventor, _jquery, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  var _default = Ember.Component.extend(_campaignsProducts.default, _crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    campaignsProducts: Ember.inject.service(),
    big: Ember.inject.service(),
    date: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    isEditing: false,
    isNew: false,
    translate: true,
    allowUploads: true,
    productsList: null,
    productsFilters: null,
    activeTemplateIndex: null,
    productsFiltersAdapterName: 'products/filters',

    willDestroyElement() {
      var _this$model;

      if (this !== null && this !== void 0 && (_this$model = this.model) !== null && _this$model !== void 0 && _this$model.isDirty) {
        var _this$model2;

        this.crud.cancelEditing({
          adapterName: this === null || this === void 0 ? void 0 : (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.adapterName,
          dirty: this === null || this === void 0 ? void 0 : this.model
        });
      }
    },

    init() {
      this._super(...arguments);

      this.set('model', null);
      this.crud.addLists(this, ['users', 'toggles/auto', 'toggles/onOff', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'products/lists/tags', 'extensions/apps/tw/invoicingAuto', 'shipping/feeType', 'commissions/methods', 'statuses', 'products/lists/pageImportBtnSubPanels']);

      if (this.isAutomations) {
        this.crud.addLists(this, ['channels/shop/campaigns/automations/statuses']);
      } else {
        this.crud.addLists(this, ['channels/shop/campaigns/statuses']);
      }

      this.set('productsList', []);
      this.setupNew();
      this.setupEditing();

      if (this.isReadonly) {
        this.set('model', this.item);
      }

      let excludes = [];

      if (this.isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }

      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.set('productsFilters', productsFilters);
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.model) {
        return false;
      }

      const templateModel = this.model.getData('templates').get('firstObject');
      this.selectTemplate(this, templateModel);
    },

    setupNew() {
      const isNew = this.isNew;

      if (isNew) {
        const campaign = this.campaign;
        const campaignDetails = this.campaignDetails || [];
        const adapterName = this.adapterName;
        const model = this.setupNewRecord({
          adapterName
        });
        const master = campaign.getData('_key');
        const overSalesLimitedQtyStatus = campaign.getData('overSalesLimitedQtyStatus');
        const sortedCampaignDetails = R.sortWith([R.ascend(R.path(['_data', 'index']))])(campaignDetails); // const last = sortedCampaignDetails.get('lastObject')
        // let nextIndex = 0
        // if (last) {
        //   nextIndex = parseInt(last.getData('index')) || 0
        // }
        // nextIndex = nextIndex + 1

        model.setData('master', master);
        model.setData('index', '');
        model.setData('overSalesLimitedQtyStatus', overSalesLimitedQtyStatus);

        if (this.isAutomations) {
          this._setupIsAutomations(model, campaign);
        } else {
          this._setupIsCampaignDetail(model, campaign);
        }

        this.set('model', model);

        if (this.loadProduct) {
          this.campaignsProducts.loadProductTask.perform(campaign, model, this.loadProduct);
        }
      }
    },

    headerTabs: Ember.computed('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels.[]', function () {
      return R_.dotPath('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels')(this) || [];
    }),

    _setupIsAutomations(model
    /* , automationModel */
    ) {
      model.setData('parentMaster', this.parentMaster);
    },

    _setupIsCampaignDetail(model, campaign) {
      const onSale = campaign.getData('onSale');
      model.setData('onSale', onSale);
    },

    setupEditing() {
      const isEditing = this.isEditing;
      const editingDefaults = this.editingDefaults;

      if (isEditing) {
        const model = this.item;
        const adapterName = this.adapterName;
        const dirty = this.setupDirty({
          adapterName,
          model: model
        });
        R.mapObjIndexed((value, key) => {
          dirty.setData(key, value);
        })(editingDefaults);

        if (R_.dotPathEq('_data.isSetWithChoices', true, dirty)) {
          R.forEach(choice => {
            const isSetPriceOnBundle = R.pathOr('', ['_data', 'isSetPriceOnBundle'], choice);

            if (!isSetPriceOnBundle) {
              choice.setData('isSetPriceOnBundle', false);
            }
          })(_nventor.default.ensurePathOr([], ['_data', 'choices'], dirty));
        }

        const noIsSetNew = R.pipe(R_.dotPath('_data.isSetNew'), RA.isNilOrEmpty)(dirty);

        if (R_.dotPathEq('_data.isSet', true, dirty) && noIsSetNew) {
          dirty.setData('isSetNew', false);
        }

        this.set('model', dirty);
      }
    },

    cancel() {
      const model = this.model;
      const adapterName = this.adapterName;
      this.cancelEditing({
        adapterName,
        dirty: model
      });

      if (this.onCancel) {
        this.onCancel();
      }
    },

    isReadonly: Ember.computed('isEditing', 'isNew', 'saveTask.isRunning', function () {
      var _this$model3;

      if (this.get('saveTask.isRunning')) {
        return true;
      }

      if (this.get('isEditing') === true) {
        return false;
      }

      if (this.get('isNew') === true) {
        return false;
      } // @TODO: QUICK FIX...


      if ((this === null || this === void 0 ? void 0 : (_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : _this$model3.isDirty) === true) {
        return false;
      }

      return true;
    }),
    isPriceReadonly: Ember.computed('isReadonly', 'model._data.isSetWithChoices', 'model._data.isSet', function () {
      const isReadonly = this.isReadonly;

      if (isReadonly) {
        return isReadonly;
      }

      if (this.get('model._data.isSet')) {
        if (this.get('model._data.isSetNew')) {
          return true;
        }

        return false;
      }

      if (this.get('model._data.isSetWithChoices')) {
        return true;
      }
    }),
    setDetailsData: Ember.computed('model._data.setDetails.@each._data', function () {
      const detailsData = R.pipe(R_.dotPathOr([], 'model._data.setDetails'), R.map(detail => {
        const data = R_.dotPathOr('', '_data')(detail);
        return data;
      }))(this);
      return detailsData;
    }),

    _filterByProductTags(zone, itemTags) {
      const allowedProductTagsInZone = R_.dotPathOr([], '_data.productTags')(zone);
      const deniedProductTagsInZone = R_.dotPathOr([], '_data.denyProductTags')(zone);
      let allowedItemsByProductTagsInZone = R_.containsAny(itemTags, allowedProductTagsInZone);

      if (RA.isNilOrEmpty(allowedProductTagsInZone)) {
        allowedItemsByProductTagsInZone = true;
      }

      let deniedItemsByProductTagsInZone = R_.containsAny(itemTags, deniedProductTagsInZone);

      if (RA.isNilOrEmpty(deniedProductTagsInZone)) {
        deniedItemsByProductTagsInZone = false;
      }

      return R.and(R.equals(true)(allowedItemsByProductTagsInZone), R.equals(false)(deniedItemsByProductTagsInZone));
    },

    applicableShippingZones: Ember.computed('campaign.synced', 'crud.lists.channels-shop-shipping-zones-all.@each.synced', 'model.synced', 'model._data.defaultShippingZoneId', function () {
      const useDefaultsShippingZones = this.campaign.getData('useDefaultsShippingZones');
      const campaignShippingZones = this.campaign.getData('shippingZonesIds') || [];
      const shippingZones = this.crud.lists['channels-shop-shipping-zones-all'] || [];
      const itemTags = this.model.getData('productTags') || [];
      const shippingZonesClone = R.concat(shippingZones)([]);
      const applicableShippingZones = R.filter(zone => {
        const zoneId = R_.dotPathOr('', '_data.zoneId')(zone);

        const filterByProductTags = this._filterByProductTags(zone, itemTags);

        if (useDefaultsShippingZones) {
          return filterByProductTags;
        }

        return R.includes(zoneId, campaignShippingZones) && filterByProductTags;
      })(shippingZonesClone);
      return applicableShippingZones;
    }),
    // overSalesLimitedQtyStatusClass: computed('model._data.overSalesLimitedQtyStatus', function () {
    //   const status = this.get('model._data.overSalesLimitedQtyStatus')
    //   return this.get('productsService').getStatusClass(status)
    // }),

    /**
     * search product
     */
    searchProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        // const model = this.get('model')
        // const contact = model.getData('contact')
        // const contactKey = model.getData('contactKey')
        // const docType = this.get('docType')
        // const docName = this.get('docName')
        // const salesPersons = this.get('hasSalesPersons');
        // get search term
        const campaign = this.campaign;
        const campaignKey = campaign.getData('_key');
        const filters = this.productsFilters; // filters.setData('query', query)

        const results = yield this.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }),

    afterReplaceRecordTask() {
      this.onDone();
    },

    afterCreateRecordTask() {
      this.onDone();
    },

    afterCancelEditing() {
      this.onDone();
    },

    updateContent(template) {
      this.model.setData('template', template);
    },

    selectTemplate(templateModel = false) {
      var _this$model4, _this$model4$_data;

      const templates = ((_this$model4 = this.model) === null || _this$model4 === void 0 ? void 0 : (_this$model4$_data = _this$model4._data) === null || _this$model4$_data === void 0 ? void 0 : _this$model4$_data.templates) || [];
      let index = (templates === null || templates === void 0 ? void 0 : templates.indexOf(templateModel)) || 0;

      if (index < 0) {
        index = 0;
      }

      this.set('activeTemplateIndex', index);
    },

    newTemplate(model) {
      const templateModel = model.addNewTemplate();
      this.selectTemplate(templateModel);
    },

    saveAsDraftTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      model,
      onAfter
    }) {
      model.setData('status', this.get('constants.productsStatus.draft'));
      yield this.replaceRecordTask.perform({
        adapterName,
        model,
        onAfter
      });
    }),
    actions: {
      // replaceTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.replaceTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },
      // insertTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.insertTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },
      showBulkDiscountConflictsModal(saved) {
        if (!saved.getData('hasBulkDiscountConflictDetails')) {
          this.onDone();
        }
      },

      toggleIsSetNew(child, model) {
        child.setData('isSetNew', true);
        model.recalculatePriceForSet(child, model);
      },

      resetItem(model) {
        model.resetDetail();
      },

      edit() {
        this.set('isEditing', true);
        this.setupEditing();
      },

      cancel() {
        this.cancel();
      },

      updateContent(template) {
        this.model.setData('template', template);
      },

      goToPanel(panel) {
        const sectionClassName = `panel-section__${panel.getData('value')}`;
        const parent = (0, _jquery.default)(this.element).closest('.modal-dialog__content').find('.parent-container');
        const goTo = parent.find(`.${sectionClassName}`);

        _nventor.default.goToElement(parent, goTo, -100);
      },

      updateOnSale(model) {
        let isOnSale = model.get('isOnSale');

        if (isOnSale === true) {
          isOnSale = 'on';
        } else {
          isOnSale = 'off';
        }

        model.setData('onSale', isOnSale);
      },

      updateProductSchedule(model) {
        const activeStatus = model.get('activeStatus') || '';
        model.setData('status', activeStatus);
      },

      // calculateSetWithChoicesPrice () {
      //   const model = this.get('model')
      //   const choices = model.getData('choices')
      //   //add up sales prices and discounted prices
      // },
      onDeselectBulkDiscount() {
        const model = this.model;
        const hasBulkDiscountSchedules = model.getData('hasBulkDiscountSchedules') || false;

        if (!hasBulkDiscountSchedules) {
          model.setData('bulkDiscountId', '');
        }
      }

    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "selectTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "newTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "newTemplate"), _obj)), _obj)));

  _exports.default = _default;
});