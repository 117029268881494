define("client/pods/products/lists/set-types/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        //   value: '',
        //   label: 'product_general'
        // }, {
        value: 'isSet',
        label: 'product set',
        translate: true
      }, {
        value: 'isSetWithChoices',
        label: 'product set with choices',
        translate: true
      }, {
        value: 'hasVariants',
        label: 'variants',
        translate: true
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});