define("client/pods/drag-drop-sort/service", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  let DragDropSortService = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_temp = class DragDropSortService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "indexProp", '_data.index');
    }

    dragStartItem(component, detail) {
      component.dragDetail = detail;
    }

    dragOverItem(component, detail, dragItem) {
      dragItem = dragItem || component.dragDetail;
      const dragIndex = dragItem.get(this.indexProp);
      const onOverItemIndex = detail.get(this.indexProp);

      if (dragIndex === onOverItemIndex) {
        component.dropPosition = '';
      } else if (dragIndex < onOverItemIndex) {
        component.dropPosition = 'insert-below';
      } else {
        component.dropPosition = 'insert-above';
      }

      return component.dropPosition || '';
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragStartItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dragStartItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragOverItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverItem"), _class.prototype)), _class));
  _exports.default = DragDropSortService;
});