define("client/pods/admin/members/lists/plans/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'free',
        label: 'free'
      }, {
        value: 'paid1',
        label: '999/m'
      }, {
        value: 'paid2',
        label: '3000/m'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});