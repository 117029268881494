define("client/pods/components/elements/btn-delete/component", ["exports", "ramda", "ember-concurrency"], function (_exports, R, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    label: 'delete',
    btnClassNames: 'button--delete',
    icon: 'fas fa-trash',
    confirmMsg: '',
    confirmMsgTranslate: false,
    requiresConfirmation: false,
    typeToDelete: false,
    typedConfirmationText: '',

    init() {
      this._super(...arguments);

      this.set('typedConfirmationText', '');
    },

    isDeleteDisabled: Ember.computed('typedConfirmationText', function () {
      const deleteKeyword = this.intl.t('delete_keyword');
      const typedConfirmationText = this.get('typedConfirmationText') || '';
      const confirmation = R.pipe(R.trim, R.toUpper)(typedConfirmationText);

      if (confirmation === deleteKeyword) {
        return false;
      }

      return true;
    }),
    deleteWithTypedConfirmationTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, ...rest) {
      yield this.onSubmit(...rest, {
        preOnAfter: onCloseDialogAction
      });
    }),
    actions: {
      copyText() {
        this.application.copyText(this.intl.t('delete_keyword'));
      },

      cancel(onCloseDialogAction) {
        this.set('typedConfirmationText', '');
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});