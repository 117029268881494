define("client/pods/components/transporters/hct-waybill/component", ["exports", "client/mixins/droppable", "client/mixins/date"], function (_exports, _droppable, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _droppable.default, {
    tagName: 'div',
    classNames: ['hct-waybill__container-outer'],
    arrivalDate: Ember.computed('model._data.arrivalDate', function () {
      const model = this.model;
      let arrivalDate = '';
      const transporterArrivalDateZ = model.getData('transporterArrivalDateZ');
      const tomorrow = this.getTomorrow();
      const tomorrowZ = this.getStartOfDayZ(tomorrow);

      if (transporterArrivalDateZ && (this.isEqualWeekday(transporterArrivalDateZ, 6) || transporterArrivalDateZ > tomorrowZ)) {
        arrivalDate = this.formatDate(transporterArrivalDateZ, 'YYYY-MM-DD');
      }

      return arrivalDate;
    })
  });

  _exports.default = _default;
});