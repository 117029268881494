define("client/pods/extensions/apps/tw/hct/accounts/filters/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/contacts/transporters/extension/hct',
    modelObj: _model.default,
    idParam: 'value',

    async findAll() {
      const data = {};
      const results = await this._super(data);
      return this._convert(results);
    },

    _convert(results) {
      const convert = R.pipe(R.prop('transporterAccounts'), R.map(account => {
        return {
          value: account.account,
          label: account.name,
          weightRate: account.rate
        };
      }), R.prepend({
        value: 'all',
        label: 'all'
      }));
      return convert(results);
    }

  });

  _exports.default = _default;
});