define("client/pods/components/helpers/info-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5W9n30zS",
    "block": "{\"symbols\":[\"@msg\",\"&default\",\"@label\",\"@customBlock\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"info-tooltip\"],[3,\"on\",[\"click\",[24,[\"toggleIsVisible\"]]]],[3,\"on\",[\"mouseenter\",[23,0,[\"handleMouseEnter\"]]]],[3,\"on\",[\"mouseleave\",[23,0,[\"handleMouseLeave\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,0,[\"isTooltipVisible\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[[23,3,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isTooltipVisible\"]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[true,[23,0,[\"hasModalOverlay\"]],[23,0,[\"isTooltipVisible\"]],[23,0,[\"hasContent\"]]]],{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"          \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/info-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});