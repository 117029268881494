define("client/pods/uploads/lists/resources/adapter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/base/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let ResourcesModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = (_temp = class ResourcesModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor2, this);
    }

    get name() {
      return this._data.name;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/uploads/resources',
    modelObj: ResourcesModel,
    idParam: false
  });

  _exports.default = _default;
});