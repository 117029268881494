define("client/pods/components/helpers/tab-link/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda-extension", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let HelpersTabLinkComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class HelpersTabLinkComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor, this);
    }

    get hasLink() {
      return R_.dotPathOr(false, 'args.hasLink')(this);
    }

    get isHoverLink() {
      return R_.dotPathOr(true, 'args.isHoverLink')(this);
    }

    get hideOpenTabBtnComputed() {
      return R.path(['args', 'hideOpenTabBtn'])(this) || false;
    }

    open() {
      if (this.hasLink) {
        this.tabsManager.openTab('left', this.args.tabOptions);
        return false;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "open", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype)), _class));
  _exports.default = HelpersTabLinkComponent;
});