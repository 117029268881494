define("client/pods/notifications/service", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    crud: Ember.inject.service(),
    fetchNotificationsTask: (0, _emberConcurrency.task)(function* () {
      const notifications = yield this.crud.findTask.perform({
        adapterName: 'notifications'
      });
      return notifications;
    })
  });

  _exports.default = _default;
});