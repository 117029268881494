define("client/pods/components/elements/element-btn-with-modal-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    tabindex: 0,
    translate: true,
    modalDialogTranslate: true,
    classNames: ['element-btn-with-modal-dialog'],
    modalDialogIsVisible: false,
    modalDialogClassName: 'has-text-left',
    modalDialogTitle: '',
    closeOnOverlayClick: true,
    isSetTypeModalVisible: false,

    init() {
      this._super(...arguments);

      this.alreadyClosed = false;

      if (this.onInit) {
        this.onInit(this);
      }

      if (this.initialModalDialogIsVisible) {
        this.set('modalDialogIsVisible', this.initialModalDialogIsVisible);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.modalDialogTitle) {
        this.set('modalDialogTitle', this.label);
        this.set('modalDialogTranslate', this.translate);
      }

      if (this.onDidInsert) {
        this.onDidInsert(this);
      }
    },

    toggleOnModalDialog() {
      this.set('modalDialogIsVisible', true);
    },

    actions: {
      showDialog() {
        if (!this.isDestroyed) {
          this.set('alreadyClosed', false);

          if (!this.disabled) {
            this.toggleOnModalDialog();

            if (this.onSubmit) {
              this.onSubmit();
            }
          }
        }
      },

      closeDialog() {
        if (!this.isDestroyed) {
          this.set('modalDialogIsVisible', false);

          if (this.onClose) {
            if (!this.alreadyClosed) {
              this.set('alreadyClosed', true);
              return this.onClose();
            }
          }
        }
      }

    }
  });

  _exports.default = _default;
});