define("client/pods/components/taxes-editor/component", ["exports", "client/mixins/big", "client/mixins/crud"], function (_exports, _big, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // the only purpose the app-container serves is to keep track of isLoggedIn state
  var _default = Ember.Component.extend(_crud.default, _big.default, {
    crud: Ember.inject.service(),
    adapterName: 'taxes',

    init() {
      this.crud.addLists(this, ['taxes']);

      this._super(...arguments);

      const isEditing = this.isEditing;

      if (isEditing) {
        const dirty = this.setupDirty();
        const rate = dirty.getDataBig('value');
        const percentage = rate.times(100).toFixed();
        dirty.setData('percentage', percentage);
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord();
        this.set('model', model);
      }
    },

    isMinRequiredMet: Ember.computed('model.{_data.label,_data.value,_data.percentage}', function () {
      const minRequired = ['label', 'value', 'percentage'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),

    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },

    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
    },

    afterRemoveRecordTask() {
      this.onRemoveItem(this.model);
    },

    actions: {
      autoSetDescription() {
        const model = this.model;
        let percentageBig = model.getDataBig('percentage');
        const rate = percentageBig.div(100).toFixed(2);
        const percentage = model.getData('percentage') + '%';
        model.setData('label', percentage);
        model.setData('value', rate);
      },

      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }

    }
  });

  _exports.default = _default;
});