define("client/pods/components/contacts/icons/detail-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    iconName: Ember.computed('detail._data.subType', function () {
      let subType = this.get('detail._data.subType');

      if (this.get('detail._data.departmentId')) {
        subType = 'department';
      }

      if (this.get('detail._data.personId')) {
        subType = 'person';
      }

      const iconsList = {
        telephone: 'phone',
        fax: 'fax',
        mobile: 'mobile-alt',
        address: 'map-marker-alt',
        billingAddress: 'file-invoice-dollar',
        deliveryAddress: 'box-open',
        note: 'comment-alt',
        link: 'link',
        email: 'at',
        person: 'user',
        department: 'network-wired'
      };
      return 'element-icon--margin fas fa-' + iconsList[subType] || '';
    })
  });

  _exports.default = _default;
});