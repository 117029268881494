define("client/pods/components/products/products-editor/details-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasInventoryOpening: Ember.computed('isNew', 'model._data.tracking', 'isInventory', function () {
      const isNew = this.isNew;
      const tracking = this.get('model._data.tracking');
      const isInventory = this.isInventory;

      if (isNew && tracking === this.get('constants.productTracking.on') && isInventory) {
        return true;
      }

      return false;
    }),
    isInventory: Ember.computed('model._data.type', function () {
      if (this.get('model._data.type') === this.get('constants.productTypes.inventory')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});