define("client/pods/workflows/stages/model", ["exports", "client/pods/base/model", "client/utils/nventor"], function (_exports, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        stageId: _nventor.default.random.alphaNum(8)
      };
    }

  });

  _exports.default = _default;
});