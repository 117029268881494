define("client/pods/components/documents/btn-merge/component", ["exports", "ramda", "ember-concurrency", "client/utils/nventor", "client/mixins/crud", "ramda-adjunct"], function (_exports, R, _emberConcurrency, _nventor, _crud, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: '',
    label: '',
    bigService: Ember.inject.service('big'),

    init() {
      this._super(...arguments);

      const mergeModel = Ember.Object.create({
        documents: []
      });
      this.set('mergeModel', mergeModel);
      this.set('toMergeDirty', '');
    },

    hasDuplicates: Ember.computed('model._data.address', 'duplicateAddressesDocNos.[]', function () {
      const model = this.model;
      const modelValue = this.get('model._data.address');
      const duplicates = this.duplicateAddressesDocNos || [];
      const paymentMethodKey = this.get('model._data.paymentMethodKey');
      const found = R.filter(R.allPass([R.propEq('value', modelValue), _nventor.default.allEqProps(['docType', 'docName'], model._data)]))(duplicates);

      if (!R.isEmpty(found)) {
        return R.map(docData => {
          docData.disabled = false;

          if (docData.paymentMethodKey !== paymentMethodKey) {
            docData.disabled = true;
          }

          if (docData.rewardPointApplied > 0) {
            docData.disabled = true;
          }

          if (docData.rewardPointEarned > 0) {
            docData.disabled = true;
          }

          return Ember.Object.create(docData);
        }, found);
      }

      return false;
    }),
    allowMergeWith: Ember.computed('model._data.docNo', 'allowMerge', 'hasDuplicates.[]', function () {
      if (!this.allowMerge) {
        return [];
      }

      const docNo = this.get('model._data.docNo');
      const duplicates = this.hasDuplicates || [];
      return R.reject(R.propEq('docNo', docNo))(duplicates);
    }),
    allowMerge: Ember.computed('model._data.isDispatched', 'hasDuplicates.[]', function () {
      const model = this.model;
      const isDispatched = model.getData('isDispatched');

      if (isDispatched) {
        return false;
      }

      const rewardPointApplied = model.getData('rewardPointApplied');

      if (rewardPointApplied) {
        return false;
      }

      const rewardPointEarned = model.getData('rewardPointApplied');

      if (rewardPointEarned) {
        return false;
      }

      if (this.hasDuplicates) {
        return true;
      }

      return false;
    }),
    hasNoMergeDocuments: Ember.computed('mergeModel.documents.[]', function () {
      const docs = this.get('mergeModel.documents') || [];
      return R.isEmpty(docs);
    }),
    mergeDocumentsTask: (0, _emberConcurrency.task)(function* () {
      this.set('mergedDetails', []);
      this.set('toMergeWithModels', []);
      const documents = this.get('mergeModel.documents') || [];
      const model = this.model;
      const adapterName = model.get('docAdapterName');
      const mergeToMaster = model.getData('_key');
      const toMergeDirty = this.setupDirty({
        adapterName,
        model
      });
      const mergedWith = [];
      toMergeDirty.setData('mergedWith', mergedWith);
      const q = R.map(docNo => {
        return this.findConcurrentTask.perform({
          adapterName,
          appendPath: `/doc/${docNo}`
        });
      }, documents);
      const toMergeWithDocumentModels = yield (0, _emberConcurrency.all)(q);
      const newDiscountAmount = this.bigService.newBig(toMergeDirty.getData('discountAmount'));
      const newRewardAmount = this.bigService.newBig(toMergeDirty.getData('rewardAmountTotal'));
      const newRewardInclTotal = this.bigService.newBig(toMergeDirty.getData('rewardInclTotal'));
      const newRewardPointApplied = this.bigService.newBig(toMergeDirty.getData('rewardPointApplied'));
      const newRewardAmountTotalWithoutPromoCode = this.bigService.newBig(toMergeDirty.getData('rewardAmountTotalWithoutPromoCode'));
      const toMergeWithDirtyModels = R.map(model => {
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        dirty.setData('isMerged', true);
        dirty.setData('mergedTo', mergeToMaster);
        mergedWith.pushObject(dirty.getData('_key'));
        newDiscountAmount.plus(dirty.getData('discountAmount') || 0);
        newRewardAmount.plus(dirty.getData('rewardAmountTotal') || 0);
        newRewardInclTotal.plus(dirty.getData('rewardInclTotal') || 0);
        newRewardPointApplied.plus(dirty.getData('rewardPointApplied') || 0);
        newRewardAmountTotalWithoutPromoCode.plus(dirty.getData('rewardAmountTotalWithoutPromoCode') || 0);
        return dirty;
      })(toMergeWithDocumentModels);
      toMergeDirty.setData('discountAmount', newDiscountAmount.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardAmountTotal', newRewardAmount.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardInclTotal', newRewardInclTotal.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardPointApplied', newRewardPointApplied.toFixed(0)); // const mergeDetailsProps = model.get('mergeDetailsProps')

      const isTaxExclusive = model.get('isTaxExclusive');
      let newIndex = 0;
      const mergedToDocNo = toMergeDirty.getData('docNo');
      const toMergeDetailsData = R.pipe(R.concat([toMergeDirty]), R.pluck('_data'), R.pluck('details'), R.flatten, R.map(detail => {
        var _detail$_data, _detail$_data4;

        if ((detail === null || detail === void 0 ? void 0 : (_detail$_data = detail._data) === null || _detail$_data === void 0 ? void 0 : _detail$_data.docNo) !== mergedToDocNo) {
          detail.setData('docNo', mergedToDocNo);
          detail.setData('master', toMergeDirty.getData('master'));
        }

        if (isTaxExclusive) {
          var _detail$_data2;

          detail.setData('price', detail === null || detail === void 0 ? void 0 : (_detail$_data2 = detail._data) === null || _detail$_data2 === void 0 ? void 0 : _detail$_data2.exclPrice);
        } else {
          var _detail$_data3;

          detail.setData('price', detail === null || detail === void 0 ? void 0 : (_detail$_data3 = detail._data) === null || _detail$_data3 === void 0 ? void 0 : _detail$_data3.inclPrice);
        }

        if (!(detail !== null && detail !== void 0 && (_detail$_data4 = detail._data) !== null && _detail$_data4 !== void 0 && _detail$_data4.isSetChild)) {
          newIndex = newIndex + 1;
        }

        detail.setData('index', newIndex);
        return detail;
      }))(toMergeWithDirtyModels); // const mergeDetailsStrategy = (key, left, right) => {
      //   if (R.includes(key, ['qty', 'allocatedInclDiscountAmount', 'allocatedInclRewardAmount', 'allocatedRewardPointApplied'])) {
      //     const leftQty = parseFloat(left) || 0
      //     const rightQty = parseFloat(right) || 0
      //     return this.bigService.autoRound(4, this.bigService.newBig(leftQty).plus(rightQty))
      //   }
      //   if (right && !left) {
      //     return right
      //   }
      //   return left
      // }
      // // calculate old qty based on props
      // const docAndProps = R.pipe(
      //   R.clone,
      //   R.concat(['master'])
      // )(mergeDetailsProps)
      // const mergedDetails = R.pipe(
      //   nventor.groupByEqProps(docAndProps),
      //   R.map(group => {
      //     const totalOldQty = R.pipe(
      //       R.pluck('oldQty'),
      //       R.reduce((acc, qty) => {
      //         qty = parseFloat(qty) || 0
      //         return acc + qty
      //       }, 0)
      //     )(group)
      //     return R.map(detail => {
      //       detail.oldQty = totalOldQty
      //       return detail
      //     })(group)
      //   }),
      //   R.unnest,
      //   nventor.groupByEqProps(mergeDetailsProps),
      //   R.map(nventor.mergeAllWithKey(mergeDetailsStrategy))
      // )(toMergeDetailsData)
      // R.forEach(dirtyModel => {
      //   const details = dirtyModel.getData('details') || []
      //   R.forEach(detailModel => {
      //     const oldQty = detailModel.getData('qty')
      //     detailModel.setData('qty', 0)
      //     detailModel.setData('allocatedInclDiscountAmount', 0)
      //     detailModel.setData('allocatedInclRewardAmount', 0)
      //     detailModel.setData('oldQty', oldQty)
      //     detailModel.setData('mergeToMaster', mergeToMaster)
      //   })(details)
      //   dirtyModel.setData('discountAmount', 0)
      //   dirtyModel.setData('rewardAmountTotal', 0)
      //   dirtyModel.setData('rewardAmountTotalWithoutPromoCode', 0)
      // }, toMergeWithDirtyModels)
      // toMergeDirty.setData('details', [])
      // let allocatedInclDiscountAmount = this.bigService.newBig(0)
      // let allocatedInclRewardAmount = this.bigService.newBig(0)
      // let allocatedRewardPointApplied = this.bigService.newBig(0)
      // R.forEach(mergedDetail => {
      //   if (mergedDetail.docNo !== toMergeDirty.getData('docNo')) {
      //     mergedDetail.docNo = toMergeDirty.getData('docNo')
      //     mergedDetail.oldQty = 0
      //   }
      //   allocatedInclDiscountAmount = allocatedInclDiscountAmount.plus(mergedDetail.allocatedInclDiscountAmount || 0)
      //   allocatedInclRewardAmount = allocatedInclRewardAmount.plus(mergedDetail.allocatedInclRewardAmount || 0)
      //   allocatedRewardPointApplied = allocatedRewardPointApplied.plus(mergedDetail.allocatedRewardPointApplied || 0)
      //   toMergeDirty.addDetail(mergedDetail)
      // })(mergedDetails)

      toMergeDirty.setData('details', toMergeDetailsData);
      toMergeDirty.sumTotals();
      this.set('toMergeDirty', toMergeDirty);
      this.set('toMergeWithModels', toMergeWithDirtyModels); // merge all details to current model
    }).enqueue().maxConcurrency(4),
    actions: {
      afterMerge(onCloseDialogAction) {
        onCloseDialogAction();
        const onAfterMerge = this.onAfterMerge;

        if (onAfterMerge) {
          onAfterMerge();
        }
      },

      addMergeDoc(mergeWithData, isSelected) {
        const documents = this.get('mergeModel.documents');

        if (isSelected) {
          documents.pushObject(mergeWithData.docNo);
        } else {
          documents.removeObject(mergeWithData.docNo);
        }
      },

      clearMergeModel() {
        this.set('mergeModel.documents', []);
      }

    }
  });

  _exports.default = _default;
});