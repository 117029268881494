define("client/pods/components/settings/settings-editor/shop-editor/component", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    shopPaymentMethodsDefaultsList: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.shopPaymentMethods.[]', function () {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.get('model._data.shopPaymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }),
    actions: {
      setShopPaymentMethodsDefault(model) {
        const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
        const currentDefault = model.getData('shopPaymentMethodsDefault');
        let resetDefault = false;

        if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
          resetDefault = true;
        }

        if (!currentDefault) {
          resetDefault = true;
        }

        if (resetDefault) {
          const first = shopPaymentMethodsDefaultsList.get('firstObject');

          if (first) {
            model.setData('shopPaymentMethodsDefault', first.getData('value'));
          } else {
            model.setData('shopPaymentMethodsDefault', '');
          }
        }
      }

    }
  });

  _exports.default = _default;
});