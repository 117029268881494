define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/districts-column/districts-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnDistrictsRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnDistrictsRowComponent extends _component.default {
    constructor() {
      var _district$_data;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isSelected", _descriptor4, this);
      const district = this.args.district;
      const districtCode = district === null || district === void 0 ? void 0 : (_district$_data = district._data) === null || _district$_data === void 0 ? void 0 : _district$_data.postcode;
      const selectedDistrictsForRegion = this.args.selectedDistrictsForRegion || [];
      this.isSelected = !!_ramda.default.find(_ramda.default.pathEq(['_data', 'postcode'], districtCode))(selectedDistrictsForRegion);
    }

    onToggleSetSelectedDistrict(country, district, isSelected) {
      const selectedDistrictPostCode = _ramda.default.pathOr('', ['_data', 'postcode'])(district);

      let districts = _ramda.default.pathOr([], ['_data', 'districts'])(country);

      if (isSelected) {
        districts = _ramda.default.append(selectedDistrictPostCode, districts);
      } else {
        districts = _ramda.default.reject(_ramda.default.equals(selectedDistrictPostCode))(districts);
      }

      country.set('_data.districts', districts);
      this.args.setSelectedDistrictsForRegion();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetSelectedDistrict", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetSelectedDistrict"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnDistrictsRowComponent;
});