define("client/pods/components/channels/shop/promo-codes/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6N6QOYAi",
    "block": "{\"symbols\":[],\"statements\":[[5,\"channels/shop/promo-codes/promo-codes-editor\",[],[[\"@model\",\"@errors\",\"@isRunning\",\"@onSaveTask\",\"@onImportTask\",\"@isNew\",\"@tab\",\"@onCancel\"],[[22,\"model\"],[22,\"errors\"],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"component\",\"onAfter\"],[[23,0,[\"adapterName\"]],[24,[\"model\"]],[23,0,[]],[24,[\"onDash\"]]]]]],null],[22,\"onImportTask\"],true,[22,\"tab\"],[22,\"onDash\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/promo-codes/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});