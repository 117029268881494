define("client/pods/components/elements/steps-bar/step-node/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selectedIsEmptyWhen: null,
    allowSameStepSubmit: false,
    hasCounter: true,
    name: Ember.computed('step', 'stepLabelParam', function () {
      const stepLabelParam = this.stepLabelParam;
      return this.get(`step.${stepLabelParam}`);
    }),
    isSelected: Ember.computed('selected', 'step', 'stepValueParam', 'selectedIsEmptyWhen', function () {
      const selected = this.selected; // const step = this.get('step')

      const stepValueParam = this.stepValueParam;
      const selectedIsEmptyWhen = this.selectedIsEmptyWhen;
      const stepValue = this.get(`step.${stepValueParam}`);

      if (stepValue === selected) {
        return true;
      }

      if (selectedIsEmptyWhen !== null && selectedIsEmptyWhen === stepValue && selected === '') {
        return true;
      }

      return false;
    }),
    allowSubmit: Ember.computed('allowSameStepSubmit', 'isSelected', function () {
      let allowSubmit = true;
      const allowSameStepSubmit = this.allowSameStepSubmit;

      if (!allowSameStepSubmit && this.isSelected) {
        allowSubmit = false;
      }

      return allowSubmit;
    }),
    notAllowSubmit: Ember.computed('allowSubmit', function () {
      return !this.allowSubmit;
    }),
    stepNo: Ember.computed('index', function () {
      const index = this.index || 0;
      return index + 1;
    }),
    actions: {
      submit()
      /* step */
      {
        const allowSubmit = this.allowSubmit;
        const customStepLabel = this.customStepLabel;

        if (!customStepLabel) {
          if (!this.disabled && allowSubmit) {
            this.onSubmit(...arguments);
          }
        }
      },

      customSubmit()
      /* step */
      {
        const allowSubmit = this.allowSubmit;

        if (!this.disabled && allowSubmit) {
          this.onSubmit(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});