define("client/pods/components/websites/panels-display/sub-panels-pages/component", ["exports", "ramda", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'websites/pages',
    showWebsitePagesNav: true,

    didInsertElement() {
      this._super(...arguments);

      this.fetchPagesCollectionTask.perform();
    },

    fetchPagesCollectionTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const collectionModel = yield this.findTask.perform({
        adapterName: 'websites/pages/collection',
        appendPath: model.getData('_key')
      });
      this.set('pagesCollection', collectionModel);
      const pages = collectionModel.get('sorted') || [];
      collectionModel.setData('pages', pages);
      const initialPage = this.tabsManager.getLoadInstructions(this.tab) || pages.get('firstObject');

      if (initialPage) {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display', initialPage);
      } else {
        this.newPanel(model);
      }
    }),

    displaySubPanel(componentName, pageModel) {
      var _pageModel, _pageModel$_data, _pageModel2, _pageModel3;

      const isDynamicPage = (_pageModel = pageModel) === null || _pageModel === void 0 ? void 0 : (_pageModel$_data = _pageModel._data) === null || _pageModel$_data === void 0 ? void 0 : _pageModel$_data.isDynamicPage;

      if (isDynamicPage) {
        return false;
      }

      if (!((_pageModel2 = pageModel) !== null && _pageModel2 !== void 0 && _pageModel2.isModel)) {
        const collectionModel = this.pagesCollection;
        const pages = collectionModel.get('sorted') || [];
        pageModel = this.tabsManager.getLoadInstructions(this.tab) || pages.get('firstObject');
      }

      if ((_pageModel3 = pageModel) !== null && _pageModel3 !== void 0 && _pageModel3.isModel) {
        const subTabOptions = {
          component: componentName,
          id: pageModel.getData('name'),
          model: pageModel
        };
        const tab = this.tab;
        tab.resetTabs();
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      } else {
        const model = this.model;
        this.newPanel(model);
      }

      this.set('showWebsitePagesNav', false);
    },

    newPanel(website, parentModel, type) {
      const collectionModel = this.pagesCollection;
      const data = {};
      const pages = collectionModel.get('sorted') || [];

      if (pages.length < 1) {
        data.isHome = true;
        data.name = this.intl.t('home page');
      } else {
        data.isHome = false;
      }

      data.master = website.getData('_key');
      data.hasTemplate = true;
      data.status = this.get('constants.status.active');
      let depth = 0;
      const posIndex = collectionModel.get('nextPosIndex');

      if (R.path(['_data', 'name'], parentModel)) {
        // index = collectionModel.getNextSubIndex(parentModel)
        const parentsArray = [];
        const grandParent = parentModel.getData('parent');

        if (grandParent) {
          parentsArray.pushObject(grandParent);
        }

        parentsArray.pushObject(parentModel.getData('_key'));
        const currentParent = R.join('/', parentsArray);
        depth = R.pipe(R.split('/'), R.length)(currentParent);
        data.parent = currentParent;
      }

      data.posIndex = posIndex;
      data.depth = depth;
      data.type = type;
      const pageModel = this.setupNewRecord({
        adapterName: 'websites/pages',
        data
      });
      pageModel.onSelectType();
      const subTabOptions = {
        component: 'websites/panels-display/sub-panels-pages/pages-new',
        value: this.intl.t('new'),
        model: pageModel
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
      this.set('showWebsitePagesNav', false);
    },

    actions: {
      toggleShowWebsitePagesNav() {
        this.set('showWebsitePagesNav', !this.showWebsitePagesNav);
      },

      newPage(onHideDropdown, type, website, parent) {
        this.newPanel(website, parent, type);

        if (onHideDropdown) {
          onHideDropdown();
        }
      },

      displaySubPanel(model) {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display', model);
      },

      afterRemovePage() {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display');
      },

      editPage(model) {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-edit', model);
      },

      reorderPages(droppedOn, position, dragged) {
        const pagesCollection = this.pagesCollection;
        let pages = pagesCollection.reorderPages(droppedOn, position, dragged);
        pages = R.pipe(R.pluck('_data'), R.map(R.pick(['_key', 'posIndex', 'depth', 'parent'])))(pages);

        if (!R.isEmpty(pages)) {
          const adapterName = 'websites/pages/collection';
          const dirty = this.setupDirty({
            adapterName,
            model: pagesCollection
          });
          dirty.setData('pages', pages);
          const master = this.get('model._data._key');
          const appendPath = `/indexes/${master}`;
          this.updateRecordTask.perform({
            adapterName,
            appendPath,
            model: dirty
          });
        }
      }

    }
  });

  _exports.default = _default;
});