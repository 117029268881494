define("client/pods/components/elements/element-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/aEW8fQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isStacked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[22,\"stackedClassNames\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[22,\"iconTop\"]],[8],[9],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[22,\"iconBottom\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"_icon\"],\" \",[22,\"iconClassNames\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-icon/template.hbs"
    }
  });

  _exports.default = _default;
});