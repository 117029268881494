define("client/pods/components/elements/print-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    tabindex: 0,
    translate: true,
    classNames: ['element-btn-with-modal-dialog'],
    iconClassName: '',
    modalDialogIsVisible: false,
    hasPrintBtn: true,
    hasPrintIcon: true,
    tooltipLabel: 'print',

    init() {
      this._super(...arguments);

      if (this.hasPrintIcon) {
        const iconClassName = this.iconClassName || 'fas fa-print';
        this.set('iconClassName', 'fas fa-print');
      }
    },

    _modalDialogTitle: Ember.computed('modalDialogTitle', 'label', function () {
      return this.modalDialogTitle || this.label;
    }),
    actions: {
      closeDialog() {
        this.set('modalDialogIsVisible', false);
      },

      printDialog() {
        window.print();
      }

    }
  });

  _exports.default = _default;
});