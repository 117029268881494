define("client/pods/components/products/price-selectors/price-selector-campaigns-automations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gNU4lJJL",
    "block": "{\"symbols\":[\"onHideDropdown\",\"history\"],\"statements\":[[4,\"lists/list-btn\",null,[[\"disabled\",\"isRunning\",\"onSubmit\",\"list\"],[[24,[\"isDisabled\"]],[24,[\"isRunning\"]],[28,\"perform\",[[24,[\"fetchHistoryOnInitTask\"]]],null],[28,\"array\",[1],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"product\",\"_data\",\"salesInclPrice\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"u-results__item\"],[3,\"action\",[[23,0,[]],\"select\",[24,[\"product\",\"_data\"]],[23,1,[]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"u-results__details\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[1,[28,\"tt\",[\"product\"],null],false],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"u-results__text u-desc u-desc--bold\"],[8],[1,[28,\"currency\",[[24,[\"product\",\"_data\",\"salesInclPrice\"]]],null],false],[0,\" (\"],[1,[28,\"tt\",[\"tax inclusive\"],null],false],[0,\")\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedData\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"products/price-selectors/price-selector-campaigns-automations/history-prices\",null,[[\"history\",\"onSelect\"],[[23,2,[]],[28,\"action\",[[23,0,[]],\"select\",[23,2,[]],[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/price-selectors/price-selector-campaigns-automations/template.hbs"
    }
  });

  _exports.default = _default;
});