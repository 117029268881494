define("client/pods/store-location/model", ["exports", "client/pods/base/model", "client/mixins/uploads", "client/mixins/statuses", "ramda"], function (_exports, _model, _uploads, _statuses, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_uploads.default, _statuses.default, {
    defaults() {
      return {
        index: 0,
        openHours: {
          monday: {
            open: 'none',
            close: 'none'
          },
          tuesday: {
            open: 'none',
            close: 'none'
          },
          wednesday: {
            open: 'none',
            close: 'none'
          },
          thursday: {
            open: 'none',
            close: 'none'
          },
          friday: {
            open: 'none',
            close: 'none'
          },
          saturday: {
            open: 'none',
            close: 'none'
          },
          sunday: {
            open: 'none',
            close: 'none'
          }
        }
      };
    },

    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    displayIndex: Ember.computed('_data.index', function () {
      return this._data.index + 1;
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;

      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    })
  });

  _exports.default = _default;
});