define("client/pods/components/contacts/statement-details/statement-transactions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    txComponent: Ember.computed('data.txType', function () {
      let txComponent = this.get('data.docName');

      if (!txComponent) {
        txComponent = this.get('data.txType');
      }

      if (txComponent) {
        txComponent = txComponent.toLowerCase();
      }

      return `contacts/statement-details/statement-transactions/tx-${txComponent}`;
    })
  });

  _exports.default = _default;
});