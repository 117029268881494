define("client/pods/components/documents/panel-display/component", ["exports", "client/mixins/date", "client/mixins/crud"], function (_exports, _date, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    init() {
      this._super(...arguments);

      const model = this.get('tab.model');
      this.set('model', model);

      if (!model.getData('discountMethod')) {
        model.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      }

      if (!model.getData('discountType')) {
        model.setData('discountType', this.get('constants.discountTypes.rate'));
      }

      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      this.tabsManager.setHeader(this.tabParent, '#' + model.getData('docNo') + ' ' + model.getData('contact'));
    }

  });

  _exports.default = _default;
});