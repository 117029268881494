define("client/pods/documents/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/utils/nventor", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct", "client/pods/components/documents/docConfig"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _nventor, _emberConcurrencyDecorators, _emberConcurrency, R, RA, _docConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  let DocumentsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._action, (_class = (_temp = class DocumentsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-consignments', _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-consignments-returns', _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-invoices', _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-orders', _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-quotes', _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, 'purchases-returns', _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-consignments', _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-consignments-returns', _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-invoices', _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-orders', _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-quotes', _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, 'sales-returns', _descriptor17, this);
    }

    *searchInputTask(args) {
      let {
        query = '',
        resultsProperty = '',
        filtersAdapterName = 'documents/filters',
        component,
        resultsToggle = false,
        global = true,
        docType,
        docName,
        filters
      } = args;

      const docConfigData = _docConfig.default.getFor({
        docType,
        docName
      });

      const adapterName = docConfigData.adapterName;

      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query
          }
        });
      }

      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle
      });

      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }

      return results;
    }

    *listByTask({
      adapterName,
      appendPath,
      match,
      documentStatus,
      dispatchStatus,
      filters,
      query,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      isVoid,
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      if (match) {
        filters.setData('match', match);
      }

      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }

      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }

      if (isVoid) {
        filters.setData('isVoid', true);
      }

      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }

        filters.setData('documentStatus', documentStatus);
      }

      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }

        filters.setData('dispatchStatus', dispatchStatus);
      }

      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }

        filters.setData('notDispatchStatus', notDispatchStatus);
      }

      if (date) {
        filters.setData('match', ['dateZ']);
        filters.setData('query', date);
      }

      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }

      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }

      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };

      try {
        const results = yield this.crud.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      } catch (e) {
        console.log(e);
      }
    }

    *listByDetailsTask({
      filters,
      adapterName,
      match,
      documentStatus,
      dispatchStatus,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      itemKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);

      if (match) {
        filters.setData('match', match);
      }

      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }

      if (itemKey) {
        filters.setData('itemKey', itemKey);
      }

      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }

      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }

        filters.setData('documentStatus', documentStatus);
      }

      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }

        filters.setData('dispatchStatus', dispatchStatus);
      }

      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }

        filters.setData('notDispatchStatus', notDispatchStatus);
      }

      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }

      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }

      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }

      const searchSettings = {
        adapterName,
        appendPath: '/details',
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }

    listByDate(onHideDropdown, dateZ, date) {
      return this.listByTask.perform({
        date,
        dateZ,
        resultsToggle: 'resultsToggle'
      }, onHideDropdown);
    }

    *hasReturns(model, {
      docType,
      docName,
      toDocType,
      toDocName
    }) {
      if (!model) {
        return {};
      }

      const master = model.getData('_key');
      const data = {
        toDocType,
        toDocName
      };
      const response = yield this.server.call('GET', `api/protected/documents/data/remaining/${docType}/${docName}/${master}`, data);
      return R.propOr({}, 'data', response);
    }

    *markPendingAsCompletedTask({
      detail,
      docType,
      docName
    }) {
      const msg = this.intl.t('are you sure you want to mark as completed?');

      if (window.confirm(msg)) {
        try {
          const pendingId = detail[`${docName}PendingId`];
          const data = {};
          data[`${docName}PendingId`] = pendingId;
          yield this.server.call('PATCH', `api/protected/documents/data/pending/${docType}/${docName}/${pendingId}`, data); // @NOTE: no realtime sync here

          Ember.set(detail, 'pendingStatus', 'complete');
        } catch (e) {
          this.errors = e;
          return false;
        }
      }
    }

    *undoMarkPendingAsCompletedTask({
      detail,
      docType,
      docName
    }) {
      const msg = this.intl.t('are you sure you want to undo?');

      if (window.confirm(msg)) {
        try {
          const pendingId = detail[`${docName}PendingId`];
          const data = {};
          data[`${docName}PendingId`] = pendingId;
          yield this.server.call('PATCH', `api/protected/documents/data/pending/${docType}/${docName}/${pendingId}/undo`, data); // @NOTE: no realtime sync here

          Ember.set(detail, 'pendingStatus', 'pending');
        } catch (e) {
          this.errors = e;
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-consignments', [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-consignments-returns', [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-invoices', [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-orders', [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-quotes', [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'purchases-returns', [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-consignments', [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-consignments-returns', [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-invoices', [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-orders', [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-quotes', [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'sales-returns', [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listByTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listByDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByDetailsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listByDate", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "listByDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasReturns", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "hasReturns"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "markPendingAsCompletedTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "markPendingAsCompletedTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "undoMarkPendingAsCompletedTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "undoMarkPendingAsCompletedTask"), _class.prototype)), _class));
  _exports.default = DocumentsService;
});