define("client/mixins/doc-status", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    // isReadonly: true,
    dispatch: Ember.inject.service(),
    batchDispatchDateZ: null,

    // user can use datepicker to set default date for batch
    init() {
      this._super(...arguments);

      this.set('batchDispatchDateZ', this.getStartOfDayZ());
    },

    toggleIsVoidTask: (0, _emberConcurrency.task)(function* ({
      onAfter
    }) {
      const model = this.model;
      let isVoid = model.getData('isVoid', false);
      let status; // let msg = 'are you sure you want to void document'

      if (isVoid === false) {
        isVoid = true;
        status = this.get('constants.documentsStatus.void');
      } else {
        // make void = false
        isVoid = false; // msg = 'are you sure you want to undo void for document'

        status = this.get('constants.documentsStatus.draft');
      } // msg = this.intl.t(msg) + ': ' + model.getData('docNo')
      // if (window.confirm(msg)) {


      const adapterName = this.get('tab.config.adapterName') || this.adapterName;
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      const key = dirty.getData('_key');
      const appendPath = `/status/${key}`;
      dirty.setData('isVoid', isVoid);
      dirty.setData('status', status);
      const saved = yield this.dataManager.updateRecord({
        adapterName,
        appendPath,
        dirty
      });

      if (onAfter) {
        onAfter(saved);
      }

      return saved; // }
    }).drop(),
    actions: {
      /**
       * toggle dispatched status from 'undispatched' -> 'dispatched'
       */
      toggleDispatched(model) {
        model = model || this.model;
        let confirmed = true;
        let appendPath;
        let dispatchStatus = model.getData('dispatchStatus', this.get('constants.dispatchStatus.undispatched'));

        if (dispatchStatus !== this.get('constants.dispatchStatus.dispatched')) {
          dispatchStatus = this.get('constants.dispatchStatus.dispatched');
          appendPath = '/dispatch';
        } else {
          // check if dispatchDate is today, otherwise ask for confirmation
          if (model.get('isDispatchedBeforeToday')) {
            const msg = this.intl.t('this may already have been dispatched on a previous day- are you sure you want to change its dispatch status');
            confirmed = window.confirm(msg);
          }

          dispatchStatus = this.get('constants.dispatchStatus.undispatched');
          appendPath = '/undispatch';
        }

        if (confirmed) {
          this.updateDispatchStatusTask.perform({
            model,
            appendPath,
            dispatchStatus
          });
        }
      },

      toggleOnHold(model) {
        model = model || this.model;
        let dispatchStatus = model.getData('dispatchStatus', this.get('constants.dispatchStatus.undispatched'));
        let appendPath;

        if (dispatchStatus !== this.get('constants.dispatchStatus.onHold')) {
          let confirmed = true;
          const dispatchDateZ = model.getData('dispatchDateZ');

          if (this.getStartOfDayZ() !== dispatchDateZ && dispatchDateZ) {
            const msg = this.intl.t('this may already have been dispatched on a previous day- are you sure you want to change its dispatch status');
            confirmed = window.confirm(msg);
          }

          if (confirmed) {
            appendPath = '/hold';
            dispatchStatus = this.get('constants.dispatchStatus.onHold');
            this.updateDispatchStatusTask.perform({
              model,
              appendPath,
              dispatchStatus
            });
          }
        } else {
          appendPath = '/undispatch';
          dispatchStatus = this.get('constants.dispatchStatus.undispatched');
          this.updateDispatchStatusTask.perform({
            model,
            appendPath,
            dispatchStatus
          });
        }
      },

      dispatchAll() {
        const msg = this.intl.t('are you sure you want to dispatch all?');

        if (window.confirm(msg)) {
          const fromStatus = this.get('constants.dispatchStatus.undispatched');
          const toStatus = this.get('constants.dispatchStatus.dispatched');
          const appendPath = '/dispatch';
          this.updateDispatchStatusBatchTask.perform({
            appendPath,
            fromStatus,
            toStatus
          });
        }
      },

      undispatchAll() {
        const msg = this.intl.t('are you sure you want to undispatch all?');

        if (window.confirm(msg)) {
          const fromStatus = this.get('constants.dispatchStatus.dispatched');
          const toStatus = this.get('constants.dispatchStatus.undispatched');
          const appendPath = '/undispatch';
          this.updateDispatchStatusBatchTask.perform({
            appendPath,
            fromStatus,
            toStatus
          });
        }
      },

      holdAll() {
        const msg = this.intl.t('are you sure you want to hold all?');

        if (window.confirm(msg)) {
          const fromStatus = this.get('constants.dispatchStatus.undispatched');
          const toStatus = this.get('constants.dispatchStatus.onHold');
          const appendPath = '/hold';
          this.updateDispatchStatusBatchTask.perform({
            appendPath,
            fromStatus,
            toStatus
          });
        }
      },

      unholdAll() {
        const msg = this.intl.t('are you sure you want to unhold all?');

        if (window.confirm(msg)) {
          const fromStatus = this.get('constants.dispatchStatus.onHold');
          const toStatus = this.get('constants.dispatchStatus.dispatched');
          const appendPath = 'undispatch';
          this.updateDispatchStatusBatchTask.perform({
            appendPath,
            fromStatus,
            toStatus
          });
        }
      }

    }
  });

  _exports.default = _default;
});