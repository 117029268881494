define("client/pods/components/store-location/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let StoreLocationTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class StoreLocationTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dropPosition", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "showUploadsRow", _descriptor5, this);
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "isDroppable", true);
      this.crud.addLists(this, ['store-location/lists/menu', 'store-location/lists/tags', 'statuses']);
    }

    get rowClassName() {
      return `table-view__detail-row ${this.dropPosition}`;
    }

    get description() {
      const div = document.createElement('div');
      div.innerHTML = R.pathOr('', ['args', 'model', '_data', 'description'])(this);
      return div.innerText;
    }

    get showOpenHours() {
      const openHours = R.pathOr({}, ['_data', 'openHours'])(this.args.model);
      const hasOpenHours = R.pipe(R.isEmpty, R.not)(openHours);

      if (hasOpenHours) {
        return R.reduce((acc, day) => {
          const time = R.propOr('', day)(openHours);
          const hasOpeningTime = R.pipe(R.pathEq(['open'], 'none'), R.not)(time);

          if (hasOpeningTime) {
            acc.push({
              day,
              time
            });
          }

          return acc;
        }, [])(this.date.getWeekdays());
      }

      return [];
    }

    drop(indexStart, indexEnd, results) {
      let reIndexStoreLocation;

      if (indexStart > indexEnd) {
        reIndexStoreLocation = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexStoreLocation = R.slice(indexStart, R.inc(indexEnd))(results);
      }

      const master = R.view(R.lensPath([0, '_data', 'master']))(results);
      this.crud.adapterCallTask.perform('updateIndexes', {
        adapterName: 'store-location/details/batch',
        appendPath: `/${master}/indexes`,
        data: reIndexStoreLocation
      });
    }

    dragOver() {
      if (this.args.tableViewOptions.onDragOver) {
        this.dropPosition = this.args.tableViewOptions.onDragOver(...arguments);
      }
    }

    dragStart() {
      if (this.args.tableViewOptions.onDragStart) {
        this.args.tableViewOptions.onDragStart(...arguments);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dropPosition", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showUploadsRow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drop", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragOver", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragStart", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype)), _class));
  _exports.default = StoreLocationTableViewDetailRowComponent;
});