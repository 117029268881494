define("client/pods/components/files/files-gallery/component", ["exports", "ramda", "ember-concurrency", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    classNames: ['images-gallery'],
    url: 'api/protected/uploads/images',
    fetchUploads: true,
    hasError: false,
    onRemove: null,
    onSetDefault: null,
    confirmationOnRemove: true,
    removeConfirmationMsg: 'are you sure you want to delete image?',
    allowSetDefault: true,
    allowRemove: true,
    allowPreview: true,
    translate: true,
    defaultImage: null,
    defaultImageTransfrom: '',
    defaultImageWidth: '200',
    defaultImageHeight: '',
    transform: '',
    imageWidth: '80',
    imageHeight: '',
    innerFilesGalleryRowClassNames: Ember.computed('filesGalleryRowClassNames', function () {
      const filesGalleryRowClassNames = R.propOr('', 'filesGalleryRowClassNames')(this);
      return `images-container-items ${filesGalleryRowClassNames}`;
    }),
    _showDefaultImagePreview: Ember.computed('showDefaultImagePreview', 'defaultImageFile._data.uuid', function () {
      if (!this.showDefaultImagePreview) {
        return false;
      }

      return R.path(['defaultImageFile', '_data', 'uuid'])(this) || false;
    }),
    // gets the file for the default image.
    // this is required because default image value may still be there in a model value,
    // but file may have already been removed from uploadsCollection
    defaultImageFile: Ember.computed('uploads.@each.synced', function () {
      const uploads = this.uploads || [];
      return R.find(R.pathEq(['_data', 'isDefault'], true))(uploads);
    }),
    files: Ember.computed('_showDefaultImagePreview', 'uploads.@each.synced', function () {
      let files = this.uploads || [];
      const showDefaultImage = this._showDefaultImagePreview;

      if (showDefaultImage) {
        files = R.reject(R.pathEq(['_data', 'uuid'], showDefaultImage))(files);
      }

      return files;
    }),
    documents: Ember.computed('uploads.@each.synced', function () {
      const uploads = this.uploads || [];
      return uploads.filterBy('isImage', false);
    }),

    async remove(file) {
      const msg = this.intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        this.set('hasError', false);
        const data = {
          uuid: file._data.uuid,
          resource: file._data.resource,
          resourceKey: file._data.resourceKey
        };

        try {
          await this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
        } catch (err) {
          this.set('hasError', true);
        }
      }
    },

    showPreview(file) {
      this.set('showPreviewModal', file);
    },

    closePreview() {
      this.set('showPreviewModal', false);
    },

    setAsDefaultImageTask: (0, _emberConcurrency.task)(function* (file) {
      yield this.onSetAsDefaultImageTask(file);
      this.closePreview();
    }),
    actions: {
      remove(file) {
        this.remove(file);
      },

      showPreview(file) {
        this.showPreview(file);
      }

    }
  });

  _exports.default = _default;
});