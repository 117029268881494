define("client/pods/components/extensions/apps/tw/e-invoices/export-excel-btn/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/big", "client/mixins/adapters-new", "client/mixins/crud", "client/utils/nventor", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _emberConcurrency, _date, _big, _adaptersNew, _crud, _nventor, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_adaptersNew.default, _big.default, _crud.default, _date.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    excel: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    tagName: '',
    isExporting: false,
    nextDispatchProcessCode: '',
    exportOrdersTotal: true,
    exportOrdersDetails: true,
    exportPayOnDeliveryOrders: true,
    exportPayOnDeliveryTransporter: true,
    exportPaidOrders: true,
    exportPaidTransporter: true,
    exportTransportersTotal: true,
    source: '',
    sourceKey: '',
    filtersAdapterName: 'channels/shop/campaigns/filters',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['dispatch/lists/processes' // 'dispatch/lists/statuses'
      ]);
      this.set('filename', this.getDefaultFilename());
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('campaignFilters', campaignFilters);
      const specs = {
        info: [{
          column: '',
          prop: '_index_'
        }, {
          column: '日期',
          prop: 'dateZ',
          transform: value => this.formatDate(value)
        }, {
          column: '出貨日期',
          prop: 'dispatchDateZ',
          transform: value => this.formatDate(value)
        }, {
          column: '收貨人',
          prop: 'contact'
        }, {
          column: '收貨人地址',
          prop: 'address'
        }, {
          column: '收貨人電話',
          prop: 'telephone'
        }, {
          column: '單號',
          prop: 'doc.ref'
        }, {
          column: '總計',
          prop: 'inclTotal',
          transform: value => parseInt(value) || value
        }, {
          column: '統編',
          prop: 'taxNumber'
        }, {
          column: '發票號碼',
          prop: 'number'
        }, {
          column: '發票日期',
          prop: 'dateLocal'
        }, {
          column: this.intl.t('provider'),
          prop: 'provider',
          transform: val => {
            if (!val) {
              return '';
            }

            return this.intl.t(val);
          }
        }, {
          column: this.intl.t('status'),
          prop: 'status',
          transform: val => {
            if (!val) {
              return '';
            }

            return this.intl.t(val);
          }
        }, {
          column: this.intl.t('upload status'),
          prop: 'uploadStatus',
          transform: val => {
            if (!val) {
              return '';
            }

            return this.intl.t(val);
          }
        }, {
          column: '商店',
          prop: 'source'
        }],
        details: [{
          column: '',
          prop: ''
        }, {
          column: '數量',
          prop: 'qty',
          transform: value => parseInt(value) || value
        }, {
          column: '品項',
          prop: 'item'
        }, {
          column: '編碼',
          prop: 'itemCode'
        }, {
          column: '含稅價',
          prop: 'discInclPrice',
          transform: value => Math.round(parseInt(value) || value, 0)
        }, {
          column: '含稅額',
          prop: 'postDiscInclAmt',
          transform: value => parseInt(value) || value
        }]
      };
      this.set('specs', specs);
    },

    getDefaultFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      return `export-${dateStart}-${dateEnd}`;
    },

    // fetchExportDataTask: task(function * ({ dispatchProcess, period, sourceKey }) {
    //   // const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   // const dispatchProcess = R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses)
    //   // this.set('dispatchProcess', dispatchProcess)
    //   // const period = this.get('period')
    //   // const filterData = {
    //   //   matches: [],
    //   //   count: 500,
    //   //   dispatchProcessesCriterias: dispatchProcess.getData('matches'),
    //   //   dispatchProcess: dispatchProcess.getData('code'),
    //   //   sourceKey,
    //   //   sort: [{
    //   //     by: 'transporterLabel',
    //   //     direction: 'ASC'
    //   //   }, {
    //   //     by: 'transporterAccount',
    //   //     direction: 'ASC'
    //   //   }, {
    //   //     by: 'timestampZ',
    //   //     direction: 'ASC'
    //   //   }]
    //   // }
    //   if (!RA.isNilOrEmpty(this.results)) {
    //     return R.map(result => {
    //       return result._data
    //     })(this.results)
    //   }
    //   const filters = this.search.setupFilters({
    //     adapterName: 'dispatch/filters',
    //     defaultFiltersData: {
    //       count: 5000,
    //       dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
    //       dispatchProcess: dispatchProcess.getData('code'),
    //       sourceKey,
    //       sort: [{
    //         by: 'transporterLabel',
    //         direction: 'ASC'
    //       }, {
    //         by: 'transporterAccount',
    //         direction: 'ASC'
    //       }, {
    //         by: 'timestampZ',
    //         direction: 'ASC'
    //       }]
    //     }
    //   })
    //   let results = []
    //   if (period) {
    //     const { dateStart, dateEnd } = period
    //     filters.setData('dispatchDateStart', dateStart)
    //     filters.setData('dispatchDateEnd', dateEnd)
    //     const path = 'api/protected/dispatch/processes/export'
    //     const response = yield this.server.call('GET', path, filters.serialize())
    //     results = R.pathOr([], ['data', 'docs'], response)
    //   }
    //   // this.set('results', results)
    //   // this.setResultsAndSummaryData(results, 'unpaginatedResults')
    //   return results
    // }).restartable(),
    exportTask: (0, _emberConcurrency.task)(function* ()
    /* filter */
    {
      if (this.hasUpdateStatus && !this.nextDispatchProcessCode) {
        this.set('errorMsg', 'please select');
        return false;
      }

      this.set('isExporting', true);
      let filename = this.filename;

      if (!filename) {
        filename = this.getDefaultFilename();
      }

      let serialized;

      if (this.onFetchExportDataTask) {
        // use passed in fetch task
        serialized = yield this.onFetchExportDataTask();
      }

      const specs = this.specs;
      const worksheets = [];
      const successful = R.filter(R.propEq('status', this.constants.eInvoicesStatus.successful))(serialized);
      worksheets.push({
        sheetName: this.intl.t('export_eInvoices_successful'),
        data: this._exportEInvoices(specs.info, [], successful, false)
      });
      const unsuccessful = R.reject(R.propEq('status', this.constants.eInvoicesStatus.successful))(serialized);
      worksheets.push({
        sheetName: this.intl.t('export_eInvoices_cancelled'),
        data: this._exportEInvoices(specs.info, [], unsuccessful, false)
      });
      worksheets.push({
        sheetName: this.intl.t('export_eInvoices_all'),
        data: this._exportEInvoices(specs.info, [], serialized, false)
      });
      worksheets.push({
        sheetName: this.intl.t('export_eInvoices_details'),
        data: this._exportEInvoicesItemsSummary(specs.info, specs.details, successful, true)
      });

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });
        this.set('isExporting', false);
        this.onCloseDialogAction();
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
    }).drop(),

    _exportEInvoices(specs, detailsSpecs = [], results, hasSummary = false) {
      const intl = this.intl;
      const columns = R.pluck('column', specs);
      let detailProps = false;

      if (!R.isEmpty(detailsSpecs)) {
        detailProps = R.pluck('prop', detailsSpecs);
      }

      let index = 0; // let totalQty = 0

      let excelData = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = [];
        detailsRows = R.pipe(R.propOr([], 'details'))(doc);

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          index
        }, specs);

        if (!detailProps) {
          return [docInfoRow];
        }

        detailsRows = R.map(detailData => {
          return this._getDataBySpecs({
            data: detailData
          }, detailsSpecs);
        })(detailsRows);
        return R.concat([docInfoRow], detailsRows);
      }), R.unnest, R.concat([columns]))(results);
      const grandTotal = parseInt(this.sumPropAndAutoRound(0, 'inclTotal', results)) || 0;
      excelData = R.append(['', '', '', '', '', '', intl.t('total'), grandTotal], excelData);
      return excelData;
    },

    _exportEInvoicesItemsSummary(specs, detailsSpecs = [], results, hasSummary = true) {
      const intl = this.intl;
      let detailProps = false;

      if (!R.isEmpty(detailsSpecs)) {
        detailProps = R.pluck('prop', detailsSpecs);
      } // let totalQty = 0


      let excelData = [];

      if (hasSummary && detailProps) {
        const groupByItem = doc => {
          const itemKey = R.prop('itemKey')(doc);

          if (itemKey) {
            return itemKey;
          }

          return R.prop('item')(doc);
        };

        const groupByPriceAndSetItem = detail => {
          let price = R.prop('discInclPrice')(detail);

          if (!price) {
            price = 0;
          }

          if (this.bigService.eq(0, price)) {
            price = 0;
          }

          if (detail.isSetChildOf) {
            return JSON.stringify({
              price,
              setPrice: detail.setPrice
            });
          }

          return JSON.stringify({
            price
          });
        };

        let grandTotal = 0;
        results = R.map(doc => {
          const allDetails = R.propOr([], 'details')(doc);
          doc.details = R.map(detail => {
            if (detail.isSetChildOf) {
              const setParent = R.find(R.propEq('itemKey', detail.isSetChildOf))(allDetails);

              if (setParent) {
                detail.setParent = setParent;
                detail.setPrice = parseInt(setParent.postDiscInclAmt) || 0;
                detail.setItem = setParent.item;
              }
            }

            return detail;
          })(allDetails);
          return doc;
        })(results);
        excelData = R.pipe(R.pluck('details'), R.flatten, R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
          const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0;
          const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', groupData)) || 0;
          const groupedByPrice = R.groupBy(groupByPriceAndSetItem)(groupData);
          const item = R.pipe(_nventor.default.safeHeadOr({}), R.propOr('', 'item'))(groupData);
          const itemCode = R.pipe(_nventor.default.safeHeadOr({}), R.propOr('', 'itemCode'))(groupData);
          const byPricesRows = R.pipe(R.mapObjIndexed((byPriceGroupData, groupData) => {
            groupData = JSON.parse(groupData);
            let groupPrice = groupData.price;
            let setPrice = groupData.setPrice;
            const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', byPriceGroupData)) || 0;
            const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', byPriceGroupData)) || 0;
            groupPrice = R.pipe(this.bigService.toFixed(0), parseInt)(groupPrice);
            const itemName = item;

            if (setPrice) {
              const setItem = R.pipe(_nventor.default.safeHeadOr({}), R.propOr('', 'setItem'))(byPriceGroupData);
              setPrice = R.pipe(this.bigService.toFixed(0), parseInt)(setPrice);
              return [itemName, itemCode, groupTotalQty, groupPrice, groupTotalInclAmt, '', setPrice, setItem];
            }

            return [itemName, itemCode, groupTotalQty, groupPrice, groupTotalInclAmt];
          }), R.values)(groupedByPrice);
          grandTotal = grandTotal + groupTotalInclAmt;
          return byPricesRows; // let mainRow = [
          //   groupTotalQty,
          //   item,
          //   itemCode,
          //   groupTotalInclAmt
          // ]
          // if (byPricesRows.length > 1) {
          //   mainRow = R.concat([mainRow])(byPricesRows)
          //   return mainRow
          // }
          // return [mainRow]
        }), R.values, R.unnest, R.concat([[intl.t('product'), intl.t('code'), intl.t('qty'), intl.t('price'), intl.t('inclusive amount'), '', intl.t('product_set_price'), intl.t('product_set')]]), R.concat(excelData), R.append([]))(results);
        excelData = R.append(['', '', '', intl.t('total'), grandTotal], excelData);
      }

      return excelData;
    },

    _getDataBySpecs({
      data,
      inclTotalBig = false,
      index,
      today
    }, specs) {
      return R.map(spec => {
        let props = R.prop('prop', spec);

        if (!R.is(Array, props)) {
          props = [props];
        }

        const values = R.map(prop => {
          return this._getPropData({
            today,
            index,
            data,
            spec,
            prop,
            inclTotalBig
          });
        })(props);

        if (props.length > 1) {
          const joinWith = R.prop('joinWith', spec) || '-';
          return R.pipe(R.reject(RA.isNilOrEmpty), R.join(joinWith))(values);
        }

        return _nventor.default.safeHeadOr('', values);
      }, specs);
    },

    _getPropData({
      today,
      index,
      data,
      spec,
      prop,
      inclTotalBig
    }) {
      const intl = this.intl;

      if (prop == null) {
        return '';
      }

      if (spec.returnProp) {
        return R.propOr('', spec.returnProp)(data);
      }

      const transform = R.prop('transform')(spec);
      let value = R_.dotPathOr('', prop, data);

      if (prop === '_index_') {
        value = index;
      }

      if (prop === '_today_') {
        return today;
      }

      if (prop === 'paymentMethodKey') {
        const v = R_.dotPathOr('', prop, data);

        if (v) {
          value = intl.t(v);
        }
      }

      if (prop === '_inclTotal_' && inclTotalBig) {
        value = inclTotalBig.toFixed(0);
      }

      if (transform) {
        value = transform(value, data);
      }

      return value;
    },

    exportData({
      results,
      filterByProp,
      filterValue,
      isReject = false,
      fromNo,
      toNo
    } = {}) {
      if (fromNo != null && toNo != null) {
        results = R.pipe(R.drop(fromNo), R.take(toNo))(results);
      }

      let filterByPath = filterByProp;

      if (!R.is(Array, filterByProp)) {
        filterByPath = [filterByProp];
      }

      if (filterByProp) {
        if (isReject) {
          results = R.reject(R.pathEq(filterByPath, filterValue))(results);
        } else {
          results = R.filter(R.pathEq(filterByPath, filterValue))(results);
        }
      }

      return results;
    },

    hasUpdateStatus: Ember.computed('exportOption', function () {
      if (this.exportOption === 'exportAndUpdateStatus') {
        return true;
      }

      return false;
    }),
    actions: {
      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      },

      selectDispatchProcess(dispatchProcess) {
        this.set('dispatchProcess', dispatchProcess);
      },

      loadShopSource(shop) {
        this.set('sourceKey', shop.getData('_key'));
      },

      unloadShopSource() {
        this.set('sourceKey', '');
      }

    }
  });

  _exports.default = _default;
});