define("client/pods/components/lists/list-searcher/component", ["exports", "ramda", "ramda-adjunct", "client/pods/components/lists/mixins/dropdown"], function (_exports, R, RA, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @NOTE:
  // what is the purpose of itemValueKey and itemLabelKey?
  // items[selectedItemValueKey] binds to model value
  // hasSelected value is either true/false UNLESS when hasSelectedKey is set.
  // hasSelected value is then bound bound to item[hasSelectedKey]
  // allowCustom: ??
  var _default = Ember.Component.extend(_dropdown.default, {
    tagName: 'div',
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    selectedItemValueKey: '_data.label',
    // the item property to display for selected item
    classNames: ['list-searcher'],
    classNameBindings: ['isDropdownVisible'],
    tagClassNames: null,
    autofocus: false,
    hasDropdown: true,
    showDropdown: false,
    onSearch: null,
    // function to search on keyUp
    onDeselect: null,
    // function on deselect searcher
    readonly: false,
    disabled: false,
    hasSelected: null,
    // determines if item is a selected value (from list) or custom value
    hasSelectedKey: null,
    // will bind hasSelected to selected[hasSelectedKey]
    allowCustom: false,
    // will remove custom values if not allowed
    value: '',
    inputIsFullW: true,
    tagAutoFocus: false,
    list: null,
    placeholder: 'search',
    showAdvancedModal: false,

    init() {
      this._super(...arguments);

      if (RA.isNilOrEmpty(this.list)) {
        this.set('list', []);
      }

      this.set('tagClassnames', ['list-searcher__tag']);
    },

    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list and has focus
     */
    isDropdownVisible: Ember.computed('showDropdown', 'list.[]', function () {
      const list = this.list || [];

      if (!this.readonly && this.showDropdown && list.length > 0) {
        return true;
      }

      return false;
    }),

    focusIn() {
      this._super(...arguments);

      const value = this.value;
      const onFocusIn = this.onFocusIn;

      if (onFocusIn) {
        onFocusIn(value);
      }
    },

    focusOut() {
      this._super(...arguments);

      const value = this.value;
      const onFocusOut = this.onFocusOut;

      if (onFocusOut) {
        onFocusOut(value);
      }

      if (!this.hasSelected && value) {
        if (!this.allowCustom) {
          this.set('value', '');
          this.set('tagAutoFocus', false);
        }
      }
    },

    // keyUp (e) {
    // },

    /**
     * compare dropdown value to item values to get item labels
     * @param {string} dropdownValue
     * @return {string} labels
     */
    // _getItemLabels(dropdownValue) {
    //
    //   const self = this;
    //   const labels = [];
    //
    //   self.get('list').forEach(function (item) {
    //
    //     if (dropdownValue === item.get(self.get('itemValueKey'))) {
    //
    //       labels.push(item.get(self.get('itemLabelKey')));
    //     }
    //   });
    //
    //   return labels.join(', ');
    // },
    actions: {
      /**
       * if hasSelected and hovering over tag it should show deselect button
       */
      handleMouseEnter(self) {
        self.set('isHover', true);

        if (this.hasSelected) {
          this.set('isShowDeselectClassNames', 'list-searcher__deselect');
        } else {
          this.set('isShowDeselectClassNames', 'list-searcher__deselect--hidden');
        }
      },

      handleMouseLeave(self) {
        self.set('isHover', false);
        this.set('isShowDeselectClassNames', 'list-searcher__deselect--hidden');
      },

      hideDropdown() {
        this.set('showDropdown', false);
      },

      onSearch() {
        if (this.onSearch) {
          let simpleSearch = true;

          if (this.hasAdvancedModal) {
            if (this.showAdvancedModal === false) {
              // 191 = '?'
              let value = this.value;

              if (R.includes('?', value)) {
                simpleSearch = false;
                value = R.replace('?', '', value);
                this.set('value', value);
                this.set('showAdvancedModal', true);
                this.onSearch(...arguments);
                this.set('showDropdown', false);
              }
            }
          }

          if (simpleSearch) {
            this.set('showDropdown', true);
            this.onSearch(...arguments);
          }
        }
      },

      selectDropdownItem(item
      /* , listSelectorComponent */
      ) {
        // set value of input to item.selectedValueKey
        const selectedItemValueKey = this.selectedItemValueKey;

        if (selectedItemValueKey) {
          this.set('value', item.get(selectedItemValueKey));
          this.set('tagAutoFocus', true);
        } // select dropdown item, set component value


        this.set('showDropdown', false); // set hasSelected if allow custom = false
        /// / @TODO: test

        const hasSelectedKey = this.hasSelectedKey;

        if (hasSelectedKey) {
          this.set('hasSelected', item.get(hasSelectedKey));
        } else {
          this.set('hasSelected', true);
        }

        if (this.onSelect) {
          this.onSelect(item, this);
        }
      },

      deselectDropdownItem(e) {
        this.set('value', '');
        this.set('tagAutoFocus', false); // @TODO: test

        const hasSelectedKey = this.hasSelectedKey;

        if (hasSelectedKey) {
          this.set('hasSelected', '');
        } else {
          this.set('hasSelected', false);
        }

        if (this.onDeselect) {
          this.onDeselect();
        }
      }

    }
  });

  _exports.default = _default;
});