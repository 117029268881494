define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/on-sale-schedules-dialog/on-sale-schedule-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopCampaignsCampaignsItemEditorOnSaleSchedulesDialogOnSaleScheduleRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsItemEditorOnSaleSchedulesDialogOnSaleScheduleRowComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
    }

    checkDateConflict() {
      const onSaleSchedules = this.args.model.getData('onSaleSchedules') || [];
      const hasDateConflict = R.pipe(R.filter(R.propEq('isActive', true)), R.length, R.gt(R.__, 1))(onSaleSchedules);

      if (hasDateConflict) {
        R.pipe(R.filter(R.propEq('isActive', true)), R.forEach(schedule => {
          schedule.set('hasDateConflict', true);
        }))(onSaleSchedules);
        this.args.model.set('hasOnSaleScheduleDateConflict', true);
      } else {
        R.forEach(schedule => {
          schedule.set('hasDateConflict', false);
        })(onSaleSchedules);
        this.args.model.set('hasOnSaleScheduleDateConflict', false);
      }
    }

    checkEmptyDiscountedPrice() {
      const onSaleSchedules = this.args.model.getData('onSaleSchedules') || [];
      const isStandardProduct = this.args.model.isStandardProduct;
      const hasEmptyDiscountedPrice = R.pipe(R.filter(R.pathEq(['_data', 'priceDiscounted'], '')), RA.isNotNilOrEmpty)(onSaleSchedules);

      if (hasEmptyDiscountedPrice && isStandardProduct) {
        this.args.model.set('hasEmptyDiscountedPrice', true);
      } else {
        this.args.model.set('hasEmptyDiscountedPrice', false);
      }
    }

    onSubmitRemoveOnSaleSchedule(onSaleSchedule) {
      const onSaleSchedules = this.args.model.getData('onSaleSchedules');
      onSaleSchedules.removeObject(onSaleSchedule);
      this.checkDateConflict();
      this.checkEmptyDiscountedPrice();
    }

    onChangeSetActivation() {
      const schedule = this.args.schedule;
      const todayZ = this.date.getStartOfDayZ();
      let dateStartZ = schedule.getData('dateStartZ');
      let dateEndZ = schedule.getData('dateEndZ');
      let isActive = false;

      if (dateStartZ && !dateEndZ && dateStartZ <= todayZ) {
        isActive = true;
      }

      if (dateEndZ && !dateStartZ && dateEndZ >= todayZ) {
        isActive = true;
      }

      if (dateStartZ && dateEndZ && dateStartZ > dateEndZ) {
        schedule.setData('dateStartZ', dateEndZ);
        schedule.set('dateStart', this.date.formatDate(dateEndZ, 'YYYY-MM-DD'));
        schedule.setData('dateEndZ', dateStartZ);
        schedule.set('dateEnd', this.date.formatDate(dateStartZ, 'YYYY-MM-DD'));
      }

      dateStartZ = schedule.getData('dateStartZ');
      dateEndZ = schedule.getData('dateEndZ');

      if (dateStartZ <= todayZ && dateEndZ >= todayZ) {
        isActive = true;
      }

      if (isActive) {
        schedule.set('isActive', true);
      } else {
        schedule.set('isActive', false);
      }

      this.checkDateConflict();
      this.checkEmptyDiscountedPrice();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveOnSaleSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveOnSaleSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChangeSetActivation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorOnSaleSchedulesDialogOnSaleScheduleRowComponent;
});