define("client/pods/components/froala/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let FroalaComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class FroalaComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "uploadcare", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isFileUploaderVisible", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "replaceImage", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "hasContent", _descriptor4, this);
    }

    didInsert(mut) {
      if (!this.args.content) {
        mut('');
      }

      this.hasContent = true;
    }

    showFileUploader(froalaEditorComponent, currentImage) {
      this.isFileUploaderVisible = true;
      this.froalaEditorComponent = froalaEditorComponent;
      this.replaceImage = currentImage;
    }

    hideFileUploader() {
      this.isFileUploaderVisible = false;
    }
    /**
     * Insert image into Froala
     * @link https://froala.com/wysiwyg-editor/docs/methods/#image.insert
     * @param onCloseDialogAction
     * @param options
     * @param uploadsModels
     * @return {*}
     */


    insertUploadedFile(onCloseDialogAction, options, uploadsModels
    /* data */
    ) {
      uploadsModels = RA.ensureArray(uploadsModels);
      const editor = R.pathOr(null, ['froalaEditorComponent', 'editor'])(this);

      if (editor) {
        R.forEach(uploadsModel => {
          const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
          const fileUrl = this.uploadcare.getFileUrl(uuid);
          const mimeType = R.pathOr('', ['_data', 'mimeType'])(uploadsModel);
          const isNoAnImage = R.includes('application')(mimeType);

          if (isNoAnImage) {
            return this.addFile({
              fileUrl,
              editor,
              uploadsModel
            });
          } // @NOTE: this becomes data-str-mime-type in the template


          return this.addImage({
            fileUrl,
            mimeType,
            uuid,
            editor
          });
        })(uploadsModels);
      }
    }
    /**
     * Insert uploaded file as link
     * @param {{ fileUrl:string, editor: FroalaComponent.editor, uploadsModel: UploadsModel }} args
     */


    addLink(args = {}) {
      const {
        fileUrl,
        editor,
        uploadsModel
      } = args;
      const fileName = R.pathOr('', ['_data', 'originalFilename'])(uploadsModel);
      editor.selection.setAtEnd(editor.link.get());
      return editor.link.insert(fileUrl, fileName, {
        target: '_blank',
        rel: 'nofollow'
      });
    }
    /**
     * Insert files to download
     * @param {{ fileUrl:string, editor: FroalaComponent.editor, uploadsModel: UploadsModel }} args
     * @return {any}
     */


    addFile(args = {}) {
      const {
        fileUrl,
        editor,
        uploadsModel
      } = args;
      const fileName = R.pathOr('', ['_data', 'originalFilename'])(uploadsModel);
      return editor.file.insert(fileUrl, fileName, {
        link: fileUrl
      });
    }
    /**
     * Insert uploaded file as an image
     * @param {{fileUrl:string, mimeType: string, uuid: string, editor: FroalaComponent.editor}} args
     * @return any
     */


    addImage(args = {}) {
      const {
        fileUrl,
        mimeType,
        uuid,
        editor
      } = args;
      const replaceId = R.pathOr(null, ['replaceImage', 'dataset', 'replaceId'])(this);
      const insertImageArgs = [fileUrl, true, {
        'data-str-mime-type': mimeType,
        'data-uuid': uuid
      }];

      if (replaceId) {
        insertImageArgs.push((0, _jquery.default)(`[data-replace-id=${replaceId}]`));
      }

      return editor.image.insert(...insertImageArgs);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadcare", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isFileUploaderVisible", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "replaceImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didInsert", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showFileUploader", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showFileUploader"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hideFileUploader", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hideFileUploader"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "insertUploadedFile", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "insertUploadedFile"), _class.prototype)), _class));
  _exports.default = FroalaComponent;
});