define("client/pods/channels/shop/campaigns/automations/details/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/mixins/products-children", "client/mixins/uploads", "client/pods/channels/shop/campaigns/details/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _productsChildren, _uploads, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  const ChannelShopCampaignsAutomationsDetailsBaseModel = _model.default.extend(_productsChildren.default, _uploads.default, {
    init() {
      this._super(...arguments);

      this.set('requiredAttrs', ['_data.itemKey', '_data.item', '_data.priceDiscounted']);
    },

    defaults() {
      return {
        qty: 1,
        status: this.get('constants.status.active'),
        deliveryDateSettings: this.get('constants.togglesAuto.auto'),
        // showOriginalPrice: this.get('constants.togglesAuto.auto'),
        onSale: this.get('constants.togglesOnOff.on'),
        showLimitedQty: this.get('constants.togglesAuto.auto'),
        showCountdownTimer: this.get('constants.togglesAuto.auto'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        commissionMethod: this.get('constants.togglesAuto.auto')
      };
    }

  });

  let ChannelShopCampaignsAutomationsDetailsModel = (_dec = Ember._tracked, (_class = (_temp = class ChannelShopCampaignsAutomationsDetailsModel extends ChannelShopCampaignsAutomationsDetailsBaseModel {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelShopCampaignsAutomationsDetailsModel;
});