define("client/pods/components/transporters/accounts-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0hr5XDa",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"u-table\"],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"name\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"account\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"rate\"],null],false],[9],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"_data\",\"transporterAccounts\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"_data\",\"isDefault\"]],\"has-text-weight-semibold\"],null]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"_data\",\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"_data\",\"isDefault\"]],\" has-text-weight-semibold \"],null]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"_data\",\"account\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"_data\",\"isDefault\"]],\" has-text-weight-semibold \"],null]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"_data\",\"rate\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[1]},null],[0,\" \"],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/accounts-editor/template.hbs"
    }
  });

  _exports.default = _default;
});