define("client/pods/components/documents/btn-refund/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "client/pods/components/documents/docConfig", "ember-concurrency-decorators", "ramda-adjunct", "ramda-extension", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _docConfig, _emberConcurrencyDecorators, RA, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  let DocumentsBtnRefundComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, (_class = (_temp = class DocumentsBtnRefundComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "documents", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "hasReturns", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "hasNoRemainingQtys", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "returnsByDocNo", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "quickLinkFromDocType", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "quickLinkFromDocName", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "quickLinkToDocType", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "quickLinkToDocName", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "isAutoCancelInvoice", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "hasAutoCancelInvoice", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "showCancelInvoiceModal", _descriptor18, this);
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'documents/filters');
      (0, _defineProperty2.default)(this, "title", `${this.intl.t('new')}: ${this.intl.t('sales-returns')}`);
      this.setupTask.perform();
    }

    get quickLinkToAdapterName() {
      return `documents/${this.quickLinkToDocType}/${this.quickLinkToDocName}`;
    }

    *setupTask() {
      const allowReturns = R_.dotPath('args.model.allowReturns')(this);
      this.quickLinkFromDocType = R_.dotPathOr('sales', 'args.quickLinkFromDocType')(this);
      this.quickLinkFromDocName = R_.dotPathOr('invoices', 'args.quickLinkFromDocName')(this);
      this.quickLinkToDocType = R_.dotPathOr('sales', 'args.quickLinkToDocType')(this);
      this.quickLinkToDocName = R_.dotPathOr('returns', 'args.quickLinkToDocName')(this);

      const config = _docConfig.default.getFor({
        docType: this.quickLinkToDocType,
        docName: this.quickLinkToDocName
      });

      const tab = Ember.Object.create({
        config
      });
      this.tab = tab;
      this.adapterName = R_.dotPath('tab.config.adapterName')(this);
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.setData('master', master);
      this.filters = filters;

      if (allowReturns) {
        yield this.fetchReturnsByDetailKeyTask.perform();
      }
    }

    *fetchReturnsByDetailKeyTask() {
      const docType = this.quickLinkFromDocType;
      const docName = this.quickLinkFromDocName;
      const toDocType = this.quickLinkToDocType;
      const toDocName = this.quickLinkToDocName;
      const model = this.args.model;
      const returnsByDetailKey = yield this.documents.hasReturns.perform(model, {
        docType,
        docName,
        toDocType,
        toDocName
      });
      this.hasReturns = RA.isNotNilOrEmpty(returnsByDetailKey);
      this.returnsByDocNo = R.pipe(R.values, R.pluck('docData'), R.flatten, R.groupBy(R.prop('docNo')), R.map(groupData => {
        const first = R.head(groupData);
        first.details = R.pipe(R.pluck('details'), R.flatten)(groupData);
        return first;
      }), R.values)(returnsByDetailKey);
      this.hasNoRemainingQtys = R.pipe(R.pathOr([], ['_data', 'details']), R.reduce((acc, detail) => {
        if (acc === false) {
          return false;
        }

        const originalQty = parseInt(detail.getData('qty')) || 0;
        const detailKey = detail.getData('_key');
        const allReturnedQty = parseInt(R.path([detailKey, 'qty'])(returnsByDetailKey)) || 0;
        return allReturnedQty >= originalQty;
      }, true))(model);
      return returnsByDetailKey;
    }

    *setupDirtyTask() {
      const model = this.args.model;
      const hasAutoCancelInvoice = R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'], model);
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model
      });
      this.hasAutoCancelInvoice = hasAutoCancelInvoice;
      dirty.set('hasAutoCancelInvoice', hasAutoCancelInvoice);
      dirty.set('isAutoCancelInvoice', !!hasAutoCancelInvoice);
      const returnsByDetailKey = yield this.fetchReturnsByDetailKeyTask.perform();
      const contactKey = dirty.getData('contactKey');

      if (contactKey) {
        const contact = yield this.crud.findTask.perform({
          adapterName: 'contacts',
          appendPath: contactKey
        });
        this.selectedContact = contact;
        dirty.set('selectedContact', contact);
      }

      dirty.setData('docNo', '');
      dirty.setData('docType', this.quickLinkToDocType);
      dirty.setData('docName', this.quickLinkToDocName);
      dirty.setData('discountAmount', '');
      dirty.setData('discountRate', ''); // link details

      const details = dirty.getData('details');
      const newDetails = R.map(detail => {
        const originalQty = parseInt(detail.getData('qty')) || 0;
        const detailKey = detail.getData('detailKey');
        const allReturnedQty = parseInt(R.path([detailKey, 'qty'])(returnsByDetailKey)) || 0;
        const maxQty = originalQty - allReturnedQty;
        detail.setData('maxQty', maxQty);
        detail.setData('docType', this.quickLinkToDocType);
        detail.setData('docName', this.quickLinkToDocName);
        detail.setData('allReturnedQty', allReturnedQty);
        detail.setData('fromRef', detail.getData('doc.ref'));
        detail.setData('originalQty', originalQty);
        detail.setData('qty', 0); // always use disc price

        detail.setData('originalPrice', detail.getData('price'));
        detail.setData('price', detail.getData('discPrice'));
        detail.setData('exclPrice', detail.getData('discExclPrice'));
        detail.setData('inclPrice', detail.getData('discInclPrice')); // remove discount amt and rate

        detail.setData('discountAmount', '');
        detail.setData('discountRate', '');
        detail.setData('fromDocType', model.getData('docType'));
        detail.setData('fromDocName', model.getData('docName'));
        detail.setData('fromDocNo', model.getData('docNo'));
        detail.setData('fromMaster', model.getData('_key'));
        detail.setData('fromDetailKey', detailKey); // @NOTE: ensure has paymentCreditCardTransActionCode

        if (dirty.get('isAllowCreditCardRefundAndValid')) {
          detail.setData('paymentCreditCardStatus', this.constants.paymentCreditCardStatus.refundRequested);

          if (dirty.getData('paymentCreditCardTransactionCode') && !detail.getData('paymentCreditCardTransactionCode')) {
            detail.setData('paymentCreditCardTransactionCode', dirty.getData('paymentCreditCardTransactionCode'));
            detail.setData('paymentCreditCardTransactionTimestampZ', dirty.getData('paymentCreditCardTransactionTimestampZ'));
          }
        }

        return detail;
      })(details);
      dirty.setData('details', newDetails);
      dirty.sumTotals();
      this.dirty = dirty;
    }

    *createReturnsRecordTask({
      appendPath = 'final',
      onAfter
    }) {
      const adapterName = this.adapterName;
      const dirty = this.dirty;
      dirty.setData('dispatchStatus', '');
      dirty.setData('dispatchProcess', '');
      dirty.setData('dispatchDateZ', '');
      dirty.setData('waybill', '');
      dirty.setData('created', '');
      dirty.setData('modified', '');
      dirty.setData('dateZ', '');
      dirty.setData('timestampZ', '');
      dirty.setData('timestampZ_latest', '');
      const details = R.pathOr([], ['_data', 'details'])(dirty);
      const nonZeroDetails = R.pipe(R.map(detail => {
        var _detail$_data;

        if (detail !== null && detail !== void 0 && (_detail$_data = detail._data) !== null && _detail$_data !== void 0 && _detail$_data.isSetChild) {
          var _detail$_data2, _parent$_data;

          // if (!detail?._data?.isSetWithChoicesChild) {
          const needle = [R.pathEq(['_data', 'index'], detail === null || detail === void 0 ? void 0 : (_detail$_data2 = detail._data) === null || _detail$_data2 === void 0 ? void 0 : _detail$_data2.index), R.pathEq(['_data', 'itemKey'], detail === null || detail === void 0 ? void 0 : detail._data.isSetChildOf)]; // } else if (detail?._data?.isSetWithChoicesChild) {
          //   needle = [
          //     R.propEq('childIndex', detail?._data?.childIndex),
          //     R.propEq('itemKey', detail?._data.isSetChildOf)
          //   ]
          // }

          const parent = R.find(R.allPass(needle))(details);
          const parentQty = (parent === null || parent === void 0 ? void 0 : (_parent$_data = parent._data) === null || _parent$_data === void 0 ? void 0 : _parent$_data.qty) || 0;

          if (parentQty) {
            var _detail$_data3;

            const childQty = (detail === null || detail === void 0 ? void 0 : (_detail$_data3 = detail._data) === null || _detail$_data3 === void 0 ? void 0 : _detail$_data3.childQty) || 1;
            const qty = childQty * parentQty;
            detail.setData('qty', qty);
          }
        }

        return detail;
      }), R.filter(detail => {
        // dont filter out child items
        const qty = parseInt(R.path(['_data', 'qty'])(detail)) || 0;
        return R.gt(qty)(0);
      }))(details);
      dirty.setData('details', nonZeroDetails);
      const isAutoCancelInvoice = dirty.get('isAutoCancelInvoice');
      return yield this.crud.createRecordTask.perform({
        adapterName,
        appendPath,
        model: dirty,
        rawResponse: true,
        onAfter,
        onAfterOptions: {
          isAutoCancelInvoice,
          wasSaved: true
        }
      });
    }

    onAfter(onCloseDialogAction, saved, savedOptions = {}) {
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }

      this.fetchReturnsByDetailKeyTask.perform();

      if (this.args.onAfterReturns) {
        this.args.onAfterReturns(saved, savedOptions);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasReturns", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasNoRemainingQtys", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "returnsByDocNo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quickLinkFromDocType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quickLinkFromDocName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quickLinkToDocType", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quickLinkToDocName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isAutoCancelInvoice", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasAutoCancelInvoice", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showCancelInvoiceModal", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchReturnsByDetailKeyTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReturnsByDetailKeyTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupDirtyTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirtyTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createReturnsRecordTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "createReturnsRecordTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onAfter", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onAfter"), _class.prototype)), _class));
  _exports.default = DocumentsBtnRefundComponent;
});