define("client/pods/application/service", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ApplicationService = (_dec = Ember._action, (_class = class ApplicationService extends Ember.Service {
    copyText(text = '', onAfter) {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (onAfter) {
        onAfter();
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "copyText", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "copyText"), _class.prototype)), _class));
  _exports.default = ApplicationService;
});