define("client/pods/components/products/products-editor/variants-editor/component", ["exports", "ramda", "client/mixins/errors", "client/utils/nventor"], function (_exports, R, _errors, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errors.default, {
    init() {
      this._super(...arguments);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        const variants = model.getData('variants') || [];
        R.forEach(variant => {
          const options = variant.getData('options') || [];
          R.forEach(option => {
            if (!option._data.optionUid) {
              const optionUid = _nventor.default.random.alphaNum(10);

              option.setData('optionUid', optionUid); // this is for legacy purposes. for when variant children were created without a optionUid

              option.set('noInitialOptionUid', true); // false until saved
            }
          })(options);
        })(variants);
        const originalModel = this.originalModel;
        model.updateVariantChildrenList(originalModel);
      }
    },

    isReadonlyAndAvailable: Ember.computed('model._data.hasVariants', 'isReadonly', function () {
      if (!this.get('model._data.hasVariants')) {
        return true;
      }

      return this.isReadonly;
    }),
    actions: {
      // removeSetDetail (model, detail) {
      //   const msg = this.get('intl').t('are you sure you want to remove')
      //   if (window.confirm(msg)) {
      //     model.removeSetDetail(detail)
      //   }
      // },
      addVariant(model) {
        model.addVariant();
      },

      removeVariant(model, variant, onUpdateVariantChildrenList) {
        const msg = this.intl.t('are you sure you want to remove');

        if (window.confirm(msg)) {
          model.removeVariant(variant);
          onUpdateVariantChildrenList();
        }
      }

    }
  });

  _exports.default = _default;
});