define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set/is-set-detail/component", ["exports", "client/mixins/crud", "ramda"], function (_exports, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    big: Ember.inject.service(),
    campaignsProducts: Ember.inject.service(),
    tagName: '',
    isSetPriceRequired: Ember.computed('item._data.isSetNew', 'isSetPriceOnBundle', function () {
      const isSetPriceOnBundle = this.get('isSetPriceOnBundle');
      const isSetNew = this.get('item._data.isSetNew');

      if (isSetPriceOnBundle) {
        if (isSetNew) {
          return false;
        }

        return true;
      }

      if (isSetNew) {
        return true;
      }

      return false;
    }),
    isSetPriceDiscountedRequired: Ember.computed('item._data.{onSale,isSetNew}', 'parentModel._data.onSale', 'isSetPriceOnBundle', function () {
      const isSetPriceOnBundle = this.get('isSetPriceOnBundle');
      const isSetNew = this.get('item._data.isSetNew');
      const itemOnSale = this.get('item._data.onSale');
      const parentOnSale = this.get('parentModel._data.onSale');

      if (isSetPriceOnBundle) {
        if (itemOnSale === 'on' || parentOnSale === 'on') {
          return true;
        }

        return false;
      }

      if (isSetNew) {
        if (itemOnSale === 'on' || parentOnSale === 'on') {
          return true;
        }

        return false;
      }

      return false;
    })
  });

  _exports.default = _default;
});