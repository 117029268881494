define("client/pods/components/channels/shop/campaigns/campaigns-editor/pages-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateContent(template) {
        this.model.setData('template', template);
      }

    }
  });

  _exports.default = _default;
});