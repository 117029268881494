define("client/pods/components/notifications/alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCc4ASPV",
    "block": "{\"symbols\":[\"@alert\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"notification-item\"],[13,2],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"e-invoice\"],null]],null,{\"statements\":[[4,\"forms/form-section\",null,[[\"title\",\"timestamp\"],[\"invoices\",[23,1,[\"timestampZ\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"extensions/apps/tw/e-invoices/print-all-btn/results\",[],[[\"@successful\",\"@unsuccessful\",\"@pending\",\"@queuedCount\"],[[23,1,[\"summaryData\",\"successful\"]],[23,1,[\"summaryData\",\"unsuccessful\"]],[23,1,[\"summaryData\",\"pending\"]],[23,1,[\"queuedCount\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"notifyUpdate\"],null]],null,{\"statements\":[[4,\"forms/form-section\",null,[[\"title\",\"timestamp\"],[\"update required, please reload\",[23,1,[\"timestampZ\"]]]],{\"statements\":[[4,\"if\",[[24,[\"alert\",\"message\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[[23,1,[\"message\"]]],[[\"translate\"],[[23,1,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[22,\"updates/update-btn\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"forms/form-section\",null,[[\"title\",\"timestamp\"],[\"invoices\",[23,1,[\"timestampZ\"]]]],{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[28,\"dateTime\",[[23,1,[\"timestampZ\"]]],null],false],[9],[0,\"\\n      \"],[1,[23,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/notifications/alert/template.hbs"
    }
  });

  _exports.default = _default;
});