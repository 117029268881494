define("client/pods/payments/card/transactions/filters/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/pods/filters/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let PaymentsCardTransactionsFiltersModel = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class PaymentsCardTransactionsFiltersModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        dateExactZ: '',
        dateExact: '',
        dateStart: '',
        dateEnd: '',
        abandonded: '',
        contact: '',
        contactKey: '',
        email: '',
        externalId: '',
        ref: '',
        partialCreditCardNumber: '',
        paymentCreditCardStatus: [],
        dispatchProcesses: [],
        multipleDispatchProcessesCriterias: [],
        paymentTransactionDateZ: '',
        sourceKey: '',
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }

    updatePeriod({
      dateStart,
      dateEnd
    }) {
      this._data.dateStart = dateStart;
      this._data.dateEnd = dateEnd;
    }

    updateProvider(provider) {
      this._data.provider = provider;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatePeriod", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updatePeriod"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateProvider", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateProvider"), _class.prototype)), _class));
  _exports.default = PaymentsCardTransactionsFiltersModel;
});