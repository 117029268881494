define("client/pods/components/products/panels-inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6JJj98/t",
    "block": "{\"symbols\":[\"adjustment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-title products-display__name u-header-1\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[\"inventory management\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n\\t\\t\"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n\\n\\t\\t\\t\"],[1,[22,\"products/inventory/inventory-editor-th\"],false],[0,\"\\n\\t\\t\\t\"],[1,[28,\"products/inventory/inventory-editor\",null,[[\"isNew\",\"isEditing\",\"onAfterCreateRecordTask\"],[true,true,[28,\"perform\",[[24,[\"fetchAdjustmentsTask\"]]],null]]]],false],[0,\"\\n\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[10,\"colspan\",\"99\"],[10,\"class\",\"u-table__row-separator\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"adjustmentsData\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"products/inventory/inventory-editor\",null,[[\"isReadonly\",\"isEditing\",\"model\"],[true,false,[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-inventory/template.hbs"
    }
  });

  _exports.default = _default;
});