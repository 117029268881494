define("client/pods/components/documents/panel-merge/component", ["exports", "ramda", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/pods/components/documents/mixins/pre-submit", "client/pods/components/documents/mixins/details", "ember-concurrency", "client/pods/components/documents/docConfig"], function (_exports, R, _date, _settings, _crud, _preSubmit, _details, _emberConcurrency, _docConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _details.default, _settings.default, _crud.default, _preSubmit.default, {
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes']);
      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;

      const config = _docConfig.default.getFor({
        docType: model._data.docType,
        docName: model._data.docName
      });

      const tab = Ember.Object.create({
        config
      });
      this.set('tab', tab);
      this.set('dontFetchRegionCode', this.get('tab.config.dontFetchRegionCode'));
      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      const dirty = this.dirty;
      const contactKey = dirty.getData('contactKey');

      if (contactKey) {
        const contact = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: contactKey
        });
        this.set('selectedContact', contact);
        dirty.set('selectedContact', contact);
      }
    }),

    afterLoadLists() {
      this._super(...arguments);

      const dirty = this.dirty; // @TODO: setup on model? but documents should be different based on context, so should not be setup in model?
      // eg. document entered here may be exclusive, but documents created via shop should be inclusive
      // set default discount types

      if (!dirty.getData('discountMethod')) {
        dirty.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      }

      if (!dirty.getData('discountType')) {
        dirty.setData('discountType', this.get('constants.discountTypes.rate'));
      }

      if (this.get('model.docName') === 'orders' || this.get('model.docName') === 'quotes') {
        dirty.setData('deliveryDateOption', this.get('constants.deliveryDateOptions.single'));
      }
    },

    mergeAllRecordsTask: (0, _emberConcurrency.task)(function* ({
      appendPath
    }) {
      const adapterName = this.adapterName;
      const dirty = this.dirty;
      const adapter = this.getAdapter(adapterName);
      const path = adapter.getPath('resourcePath', appendPath);
      const toMergeWithModels = this.toMergeWithModels;
      const documents = [];
      documents.push(adapter.serialize(dirty));
      R.forEach(toMergeModel => {
        documents.push(adapter.serialize(toMergeModel));
      }, toMergeWithModels);
      const data = {
        documents
      };
      const results = yield this.server.call('PATCH', path, data);

      if (this.onAfterMerge) {
        this.onAfterMerge(results);
      }
    }).drop(),
    actions: {
      addNewDetail() {// dont add new detail
      }

    }
  });

  _exports.default = _default;
});