define("client/pods/schedules/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    get statusClass() {
      const status = this._data.status;

      switch (status) {
        case 'inactive':
          return 'is-danger';

        case 'cancelled':
          return 'is-danger';

        case 'pending':
          return 'is-warning';

        case 'completed':
          return 'is-success';
      }
    }

  });

  _exports.default = _default;
});