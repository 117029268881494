define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/regions-column/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnComponent = (_dec = Ember.inject.service, (_class = (_temp = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
    }

    get regions() {
      const regionQuery = this.args.regionQuery || '';
      const regionsForCountry = this.args.regionsForCountry || [];

      if (RA.isNotNilOrEmpty(regionQuery)) {
        return _ramda.default.filter(region => {
          return _ramda.default.anyPass([_ramda.default.pathSatisfies(name => _ramda.default.includes(_ramda.default.toLower(regionQuery), _ramda.default.toLower(name)), ['_data', 'en']), _ramda.default.pathSatisfies(name => _ramda.default.includes(_ramda.default.toLower(regionQuery), _ramda.default.toLower(name)), ['_data', 'label']), _ramda.default.pathSatisfies(name => _ramda.default.includes(_ramda.default.toLower(regionQuery), _ramda.default.toLower(name)), ['_data', 'zh-cn']), _ramda.default.pathSatisfies(name => _ramda.default.includes(_ramda.default.toLower(regionQuery), _ramda.default.toLower(name)), ['_data', 'zh-tw'])])(region);
        })(regionsForCountry);
      }

      return regionsForCountry;
    }

    get hasSelectedRegions() {
      const regions = this.regions;

      const selectedRegions = _ramda.default.filter(_ramda.default.propEq('isSelected', true))(regions);

      if (_ramda.default.length(selectedRegions) > 0) {
        return true;
      }

      return false;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnComponent;
});