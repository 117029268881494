define("client/pods/components/froala/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMalqV7h",
    "block": "{\"symbols\":[\"@tab\",\"@basicToolbar\",\"@content\",\"@hasFileBrowser\",\"@hasVideoBrowser\",\"@options\",\"@isReadonly\"],\"statements\":[[7,\"span\",false],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"didInsert\"]],[28,\"fn\",[[28,\"mut\",[[23,3,[]]],null]],null]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"    \"],[5,\"froala-content\",[],[[\"@content\"],[[28,\"html-safe\",[[23,3,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasContent\"]]],null,{\"statements\":[[0,\"      \"],[5,\"froala-editor\",[],[[\"@basicToolbar\",\"@content\",\"@hasFileBrowser\",\"@hasVideoBrowser\",\"@options\",\"@showFileUploader\",\"@update\"],[[23,2,[]],[28,\"html-safe\",[[23,3,[]]],null],[23,4,[]],[23,5,[]],[23,6,[]],[23,0,[\"showFileUploader\"]],[28,\"to-string\",[[28,\"fn\",[[28,\"mut\",[[23,3,[]]],null]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"files/files-browser-modal\",null,[[\"resource\",\"resourceKey\",\"tab\",\"modalDialogIsVisible\",\"onSubmit\"],[\"files\",\"files\",[23,1,[]],[24,[\"isFileUploaderVisible\"]],[28,\"action\",[[23,0,[]],\"insertUploadedFile\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/froala/template.hbs"
    }
  });

  _exports.default = _default;
});