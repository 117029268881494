define("client/pods/components/products/products-editor/variants-editor/variant-editor/component", ["exports", "ramda", "client/mixins/errors", "client/mixins/sortable-actions"], function (_exports, R, _errors, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errors.default, _sortableActions.default, {
    tagName: '',
    modelProp: 'variant',
    indexProp: '_data.index',

    didInsertElement() {
      this._super(...arguments);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        const variant = this.variant;
        model.addVariantOption(variant);
      }
    },

    actions: {
      addVariantOption(model, variant, option, onUpdateVariantChildrenList) {
        model.addVariantOption(variant, option);
        onUpdateVariantChildrenList();
      },

      removeVariantOption(model, variant, option, onUpdateVariantChildrenList) {
        const msg = this.intl.t('are you sure you want to delete variant option');
        const confirm = window.confirm(msg);

        if (confirm) {
          model.removeVariantOption(variant, option);
          onUpdateVariantChildrenList();
        }
      }

    }
  });

  _exports.default = _default;
});