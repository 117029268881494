define("client/pods/components/lists/list-tags/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    list: null,
    model: null,
    valueKey: null,
    optionValueKey: '_data.value',
    optionLabelKey: '_data.label',
    translate: true,
    prefix: '',
    // prefix added to option
    suffix: '',
    // suffix added to option
    // itemClassNames: computed('tagClassNames', function () {
    //   return 'tag ' + this.get('tagClassNames')
    // }),
    // itemClassNameBindings: computed('isTag', function () {
    //   return 'isTag:is-primary'
    //   // if (this.get('isYes')) {
    //   //   return ''
    //   // }
    // }),
    // init () {
    //   this._super(...arguments)
    //   this.setup()
    // },
    // setup () {
    //   this.updateSelected()
    //   // add an observer for the model value property
    //   const valueKey = this.get('valueKey')
    //   this.addObserver('model.' + valueKey, this, 'updateSelected')
    // },
    // itemClassNames: computed('tagClassNames', function () {
    //   let classes = ['list-tags__item']
    //   const tagClassNames = this.get('tagClassNames')
    //   if (tagClassNames) {
    //     if (isArray(tagClassNames)) {
    //       // merge with classes
    //       classes = classes.concat(tagClassNames)
    //     } else {
    //       // only 1 className
    //       classes.push(tagClassNames)
    //     }
    //   }
    //   return classes
    // }),
    // // listen to changes in options and updates model.selectedProp when an option is selected but is removed.
    // // problem: need to loop through each option and then remove
    // observeOptions: observer('list.[]', function () {
    //   // @NOTE:
    //   // observer is being fired when list is first loaded.
    //   // this will cause model.valueKey to be reset (maybe unecessarily)
    //   var self = this
    //   const list = self.get('list')
    //   // const model = self.get('model');
    //   const selected = self.getModelValue()
    //   // only update model if list is not null (list is null when not yet loaded)
    //   if (list != null) {
    //     if (isArray(selected)) {
    //       // multiSelect
    //       selected.forEach(function (value) {
    //         // if model's selected value no longer is in the options list then it must be
    //         // removed from model's selected array
    //         if (!list.findBy(self.get('optionValueKey'), value)) {
    //           const model = self.get('model')
    //           model.get(self.get('valueKey')).removeObject(value)
    //         }
    //       })
    //     } else {
    //       // single select
    //       if (!list.findBy(self.get('optionValueKey'), selected)) {
    //         self.setModelValue(null)
    //       }
    //     }
    //   }
    // }),
    // // update component's selected property
    // // called on init to setup which options are selected.
    // // called when model's property is changed
    // // function is also used as an observer created on init
    // // this listens for changes on model and updates selector's value accordingly
    // updateSelected () {
    //   const self = this
    //   const model = self.get('model')
    //   const valueKey = self.get('valueKey')
    //   let selected = model.get(valueKey)
    //   if (selected == null) {
    //     selected = []
    //   } else {
    //     if (!isArray(selected)) {
    //       selected = [selected]
    //     }
    //   }
    //   this.set('selected', selected)
    // },
    // /**
    //  * return model's selected value
    //  */
    // getModelValue () {
    //   return this.get('model').get(this.get('valueKey'))
    // },
    // /**
    //  * set model's selected value
    //  */
    // setModelValue (value) {
    //   return this.get('model').set(this.get('valueKey'), value)
    // },
    selected: Ember.computed('value', 'value.[]', function () {
      // const valueKey = this.get('valueKey')
      let selected = this.value;

      if (selected == null) {
        selected = [];
      } else {
        if (!Ember.isArray(selected)) {
          selected = [selected];
        }
      }

      return selected;
    }),

    /**
     * list of tagged items to display
     */
    tagged: Ember.computed('list.[]', 'selected.[]', function () {
      // @TODO: problem:
      // in Ember integers are integers,
      // but when saving using ARANGODB,
      // integers are converted to strings.
      // when returning to Ember it expects integers
      // SOLUTION:
      // all integers should be strings in EMBER
      // return list of selected
      // option[optionValueKey] === model[valueKey]
      const list = this.list;

      if (Ember.isArray(list)) {
        const optionValueKey = this.optionValueKey;
        const selected = this.selected;
        return list.filter(option => {
          // if option = one of selected model value then return as tagged
          if (selected.indexOf(option.get(optionValueKey)) > -1) {
            return option;
          }
        });
      }
    }),
    actions: {
      /**
       * Remove selected item from the lists
       * @param {Model} item - selected item
       */
      onDeselectTagItem(item) {
        const valueKey = item.get(this.optionValueKey);

        if (valueKey) {
          let values = this.value || [];
          values = R.reject(R.equals(valueKey))(values);
          this.set('value', values);
        }
      }

    }
  });

  _exports.default = _default;
});