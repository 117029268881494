define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nMeG4RRP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isHeader\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[\"criteria\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[\"value\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"readonly\",\"disabled\",\"translate\",\"inputPvtClassNames\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\"],[true,true,[24,[\"isReadonly\"]],true,\"table-form__input\",[23,0,[\"crud\",\"lists\",\"automations-criterias\"]],\"_data.value\",\"_data.label\",[24,[\"automationModel\",\"_data\",\"criteria\"]]]]],false],[0,\"\\n\\t\\t\\t\\n\\t\\t\"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"classNames\",\"context\",\"icon\",\"tooltip\"],[[24,[\"errors\"]],\"documents__details-error\",\"condition\",\"fas fa-exclamation-circle\",true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[0,\"\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"readonly\",\"inputPvtClassNames\",\"value\"],[true,[24,[\"isReadonly\"]],\"table-form__input\",[24,[\"automationModel\",\"_data\",\"value\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"classNames\",\"context\",\"icon\",\"tooltip\"],[[24,[\"errors\"]],\"documents__details-error\",\"value\",\"fas fa-exclamation-circle\",true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value/template.hbs"
    }
  });

  _exports.default = _default;
});