define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/component", ["exports", "ramda", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/transporter", "ember-concurrency", "ramda-extension"], function (_exports, R, _searchContacts, _crud, _transporter, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { task } from 'ember-concurrency'
  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporter.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    transporterAccounts: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/tags']);
      const associatesContactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          isAssociates: true
        }
      });
      this.loadContactTask.perform(associatesContactsFilters);

      if (this.get('model._data.transporterKey')) {
        this.fetchTransporterTask.perform({
          model: this.model
        });
      }

      const adjustmentFilters = this.search.setupFilters({
        adapterName: 'reports/commissions/data/adjustment/filters'
      });
      this.set('adjustmentFilters', adjustmentFilters);
      this.loadAdjustmentTask.perform();
    },

    loadAdjustmentTask: (0, _emberConcurrency.task)(function* () {
      var _this$model, _this$model$_data;

      const campaignKey = (this === null || this === void 0 ? void 0 : (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : _this$model$_data._key) || '';
      const salesPersons = R.pipe(R_.dotPathOr([], 'model._data.salesPersons'), R.map(user => {
        var _user$_data, _user$_data2, _user$_data3;

        return {
          name: (user === null || user === void 0 ? void 0 : (_user$_data = user._data) === null || _user$_data === void 0 ? void 0 : _user$_data.name) || '',
          _key: (user === null || user === void 0 ? void 0 : (_user$_data2 = user._data) === null || _user$_data2 === void 0 ? void 0 : _user$_data2._key) || '',
          username: (user === null || user === void 0 ? void 0 : (_user$_data3 = user._data) === null || _user$_data3 === void 0 ? void 0 : _user$_data3.username) || ''
        };
      }))(this);
      const adjustmentFilters = this.get('adjustmentFilters');
      adjustmentFilters.setData('query', '');
      adjustmentFilters.setData('campaignKey', campaignKey);
      adjustmentFilters.setData('campaignSalesPersons', salesPersons);
      yield this.crud.searchRecordsTask.perform({
        adapterName: 'reports/commissions/data/adjustment',
        filters: adjustmentFilters
      });
      const results = adjustmentFilters.results;
      const totalTotalCommissionBaseCostTotal = R.pipe(R.map(result => {
        return result._data.totalCommissionBaseCostTotal;
      }), R.sum)(results);
      this.set('adjustmentFilters', adjustmentFilters);
      this.set('totalTotalCommissionBaseCostTotal', totalTotalCommissionBaseCostTotal);
    }),
    loadContactTask: (0, _emberConcurrency.task)(function* (associatesContactsFilters) {
      const associatesContacts = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts',
        filters: associatesContactsFilters
      });
      this.set('associatesContacts', associatesContacts);
    }),
    actions: {
      assignSalesPerson() {
        this.onAssignSalesPerson(...arguments);
        this.loadAdjustmentTask.perform();
      },

      unassignSalesPerson() {
        this.onUnassignSalesPerson(...arguments);
      },

      toggleCommissionMethod() {
        this.onToggleCommissionMethod(...arguments);
      }

    }
  });

  _exports.default = _default;
});