define("client/pods/components/documents/mixins/pre-submit", ["exports", "client/mixins/hct-fetch"], function (_exports, _hctFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_hctFetch.default, {
    async preSubmit(model, onSubmit) {
      if (model.getData('docType') === 'sales' && (model.getData('docName') === 'invoices' || model.getData('docName') === 'consignments') && model.getData('transporterExtension') === 'hct' && this.settings.getProp('features.extensions.tw_hct') && this.dontFetchRegionCode === false) {
        // show modal
        const transporterRegion = model.getData('transporterRegion');

        if (!transporterRegion) {
          // fetch
          await this.fetchHCTRegionDataTask.perform(model);
        }

        if (!this.isHCTIncomplete(model)) {
          return onSubmit();
        }
      } else {
        return onSubmit();
      }
    },

    actions: {
      preSubmit(model, onSubmit) {
        return this.preSubmit(model, onSubmit);
      }

    }
  });

  _exports.default = _default;
});