define("client/mixins/products-children", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.set('childAdapters.choices', 'products/choices');
      this.set('childAdapters.choices-details', 'products/choices/details');
      this.set('childAdapters.sets-details', 'products/sets/details');
      this.set('childAdapters.variants', 'products/variants');
      this.set('childAdapters.variants-options', 'products/variants/options');
      this.set('childAdapters.variants-children', 'products/variants/children');
    },

    populate(data = {}) {
      this._super(...arguments);

      data = this.transformSetDetails(data);
      data = this.transformSetWithChoices(data);
      data = this.transformVariants(data);
    },

    // statusClass: computed('_data.status', function () {
    //   const status = this.get('_data.status')
    //   const statusClasses = this.get('statusClasses')
    //   return R.propOr('is-danger', status, statusClasses)
    // }),
    isOnSale: Ember.computed('_data.onSale', function () {
      if (this.get('_data.onSale') === 'on') {
        return true;
      }

      return false;
    }),
    oldPrice: Ember.computed('_data.{isSetWithChoices,hasVariants,price,priceDiscounted,isSetPriceOnBundle}', 'synced', function () {
      if (this.get('_data.isSetWithChoices')) {
        return this.lowestSetWithChoicesPriceOld;
      }

      if (this.get('_data.hasVariants')) {
        return this.lowestVariantPriceOld;
      }

      return this.get('_data.price');
    }),
    currentPrice: Ember.computed('isOnSale', '_data.{price,priceDiscounted,isSetPriceOnBundle}', 'synced', function () {
      if (this.get('_data.isSetWithChoices')) {
        if (this.isOnSale) {
          return this.lowestSetWithChoicesPricePossible;
        }

        return this.lowestSetWithChoicesPriceOld;
      }

      if (this.get('_data.hasVariants')) {
        if (this.isOnSale) {
          return this.lowestVariantPriceCurrent;
        }

        return this.lowestVariantPriceOld;
      }

      if (this.isOnSale) {
        if (this.get('_data.priceDiscounted')) {
          return this.get('_data.priceDiscounted');
        }
      }

      return this.get('_data.price');
    }),
    variantChildrenData: Ember.computed('_data.variantChildren', function () {
      const variantChildrenData = R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.pluck('_data'))(this);
      return variantChildrenData;
    }),
    lowestVariantOldPriceRange: Ember.computed('_data.{variantChildren.[],price,priceDiscounted}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const variantChildren = this.get('_data.variantChildren') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const allPriceFilled = R.pipe(R.map(child => {
        return child._data.price || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      let variantMasterPrice = this.get('_data.price');

      if (!variantMasterPrice) {
        variantMasterPrice = 0;
      }

      if (allPriceFilled) {
        variantMasterPrice = 0;
      }

      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.path(['_data', prop], child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        const price = R.path(['_data', 'price'])(variantChild);

        if (price) {
          return price || 0;
        } // return R.path(['_data', 'priceDiscounted'])(variantChild)


        return variantMasterPrice || 0;
      }), R.concat([variantMasterPrice]), R.reject(n => R.equals(n, 0)), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceOld: Ember.computed('_data.{variantChildren.[],price,priceDiscounted}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const lowestVariantPrices = this.lowestVariantOldPriceRange || [];
      let lowestVariantPrice;

      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }

      return lowestVariantPrice;
    }),
    lowestVariantCurrentPriceRange: Ember.computed('_data.{variantChildren.[],price,priceDiscounted,onSale}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const variantChildren = this.get('_data.variantChildren') || [];

      const diff = (a, b) => parseFloat(a) - parseFloat(b);

      const onSale = this.get('_data.onSale');
      const allPriceFilled = R.pipe(R.map(child => {
        return child._data.price || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      const allPriceDiscountedFilled = R.pipe(R.map(child => {
        return child._data.priceDiscounted || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      let variantMasterPrice = this.get('_data.price');

      if (!variantMasterPrice || onSale) {
        variantMasterPrice = this.get('_data.priceDiscounted') || 0;

        if (allPriceDiscountedFilled) {
          variantMasterPrice = 0;
        }
      } else {
        if (allPriceFilled) {
          variantMasterPrice = 0;
        }
      }

      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.path(['_data', prop], child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        if (onSale === 'on') {
          // if (variantChild._data.onSale === 'on') {
          return variantChild._data.priceDiscounted || 0; // }
        }

        return variantChild._data.price || 0;
      }), R.concat([variantMasterPrice]), R.reject(n => R.equals(n, 0)), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceCurrent: Ember.computed('_data.{variantChildren.[],price,priceDiscounted,onSale}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const lowestVariantPrices = this.lowestVariantCurrentPriceRange || [];
      let lowestVariantPrice;

      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }

      return lowestVariantPrice;
    }),
    lowestSetWithChoicesPriceOld: Ember.computed('_data.{choices.[]}', 'synced', function () {
      const choices = this.get('_data.choices') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice._data.qty;
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;
        const details = choice._data.details || [];
        const lowestPrices = R.map(detail => {
          if (detail._data.hasVariants) {
            return detail.lowestVariantPriceOld;
          }

          if (detail._data.price) {
            return detail._data.price;
          }

          return detail._data.priceDiscounted;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);

        if (isSetPriceOnBundle) {
          if (choice._data.price) {
            lowestPrice = choice._data.price || 0;
          } else {
            lowestPrice = choice._data.priceDiscounted || 0;
          }

          return lowestPrice;
        }

        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),
    lowestSetWithChoicesPricePossible: Ember.computed('_data.{choices.[],onSale}', 'synced', function () {
      const choices = this.get('_data.choices') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const onSale = this.get('_data.onSale');
      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice._data.qty;
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;
        const details = choice._data.details || [];
        const lowestPrices = R.map(detail => {
          if (onSale === 'on') {
            if (detail._data.hasVariants) {
              return detail.lowestVariantPriceCurrent;
            } // if (detail._data.onSale === 'on') {


            return detail._data.priceDiscounted; // }
          }

          return detail._data.price;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);

        if (isSetPriceOnBundle) {
          if (onSale === 'on') {
            lowestPrice = choice._data.priceDiscounted || 0;
          } else {
            lowestPrice = choice._data.price || 0;
          }

          return lowestPrice;
        }

        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),
    hasPriceRange: Ember.computed('_data.{choices.[],onSale,variantChildren.[]}', 'synced', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const choices = this.get('_data.choices') || [];
      const onSale = this.get('_data.onSale');

      if (this.get('_data.hasVariants')) {
        const lowestVariantCurrentPriceRange = this.lowestVariantCurrentPriceRange || [];

        if (lowestVariantCurrentPriceRange.length > 1) {
          return true;
        }

        return false;
      }

      const onlyChoicesWithPriceRanges = R.filter(choice => {
        const details = choice._data.details || [];
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;

        if (isSetPriceOnBundle) {
          return false;
        }

        const allUniqueDetailPrices = R.pipe(R.map(detail => {
          if (onSale === 'on') {
            // if (detail._data.onSale === 'on') {
            return detail._data.priceDiscounted; // }
          }

          return detail._data.price;
        }), R.uniq)(details);

        if (allUniqueDetailPrices.length > 1) {
          return true;
        }

        return false;
      })(choices);

      if (onlyChoicesWithPriceRanges.length > 0) {
        return true;
      }

      return false;
    }),

    transformSetDetails(data) {
      if (data.setDetails) {
        const detailsRequiredAttrs = this.setDetailsRequiredAttrs;
        data.setDetails = R.pipe(R.propOr([], 'setDetails'), R.map(detailData => {
          const detailModel = this.dataManager.setAsRecord({
            adapterName: this.get('childAdapters.sets-details'),
            data: detailData,
            attrs: {
              requiredAttrs: detailsRequiredAttrs
            }
          });
          return detailModel;
        }))(data);
      }

      return data;
    },

    transformSetWithChoices(data) {
      data.choices = R.pipe(R.propOr([], 'choices'), R.map(choiceData => {
        const details = choiceData.details || [];
        choiceData.details = R.map(detailData => {
          if (detailData.hasVariants) {
            detailData = this.transformVariants(detailData);
          } else if (detailData.isSet) {
            detailData = this.transformSetDetails(detailData);
          }

          return this.dataManager.setAsRecord({
            adapterName: this.get('childAdapters.choices-details'),
            data: detailData
          });
        })(details);
        return this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.choices'),
          data: choiceData
        });
      }))(data);
      return data;
    },

    transformVariants(data) {
      data.variants = R.pipe(R.propOr([], 'variants'), R.map(variantData => {
        variantData.options = R.pipe(R.propOr([], 'options'), R.map(optionData => {
          return this.dataManager.setAsRecord({
            adapterName: this.get('childAdapters.variants-options'),
            data: optionData
          });
        }))(variantData);
        return this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants'),
          data: variantData
        });
      }))(data);
      data.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(variantChild => {
        const variantChildModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants-children'),
          data: variantChild
        });
        return variantChildModel;
      }))(data);
      return data;
    },

    isSetOrHasVariants: Ember.computed('_data.setType', function () {
      const setType = this.get('_data.setType') || '';

      if (setType === '') {
        return false;
      }

      if (setType === 'isVariantChild') {
        return false;
      }

      return true;
    }),
    hasVariantsOrChoices: Ember.computed('_data.{isSetWithChoices,hasVariants}', function () {
      if (this.get('_data.hasVariants')) {
        return true;
      }

      if (this.get('_data.isSetWithChoices')) {
        return true;
      }

      return false;
    }),

    _getAllChildModels() {
      const setDetailsModels = this._getIsSetDetailsModels();

      const choicesChildrenModels = this._getIsSetWithChoicesChildrenModels();

      return R.concat(setDetailsModels, choicesChildrenModels);
    },

    _getIsSetDetailsModels() {
      return this.get('_data.setDetails') || [];
    },

    _getIsSetWithChoicesChildrenModels() {
      const isSetWithChoices = this.get('_data.isSetWithChoices');

      if (isSetWithChoices) {
        const choices = this.get('_data.choices');
        return R.pipe(R.pluck('_data'), R.pluck('details'), R.flatten)(choices);
      }

      return [];
    },

    isSetChoicesDetailsProxyArray: Ember.computed('_data.{isSetWithChoices,choices._data.[]}', function () {
      const isSetWithChoices = this.get('_data.isSetWithChoices');

      if (isSetWithChoices) {
        const isSetWithChoicesDetailsModels = this._getIsSetWithChoicesChildrenModels();

        return R.pluck('_data')(isSetWithChoicesDetailsModels);
      }

      return [];
    }),
    // isSetDetailsProxyArray: computed('_data.{isSet,setDetails.[]}', function () {
    //   const isSet = this.get('_data.isSet')
    //   if (isSet) {
    //     const isSetDetailsModels = this._getIsSetDetailsModels()
    //     return R.pluck('_data')(isSetDetailsModels)
    //   }
    //   return []
    // }),
    allChildModels: Ember.computed('_data.{setDetails.[],variantChildren.[]}', 'isSetChoicesDetailsProxyArray.@each.itemKey', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const setDetails = this.get('_data.setDetails') || [];
      const variantChildren = this.get('_data.variantChildren') || [];
      const setWithChoicesChildrenModels = this._getIsSetWithChoicesChildrenModels() || [];
      return R.pipe(R.concat(setDetails), R.concat(setWithChoicesChildrenModels))(variantChildren);
    })
  });

  _exports.default = _default;
});