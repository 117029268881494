define("client/pods/components/documents/status-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WLxR7KLB",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isDropdownBtn\"]],[24,[\"model\",\"hasDispatchProcess\"]]],null]],null,{\"statements\":[[4,\"lists/list-btn\",null,[[\"label\",\"disabled\",\"translate\",\"hasStatusBadge\",\"statusBadgeClassNames\",\"onSubmit\"],[[24,[\"label\"]],[28,\"or\",[[24,[\"disabled\"]],[24,[\"model\",\"_data\",\"isVoid\"]]],null],false,true,[24,[\"model\",\"statusClass\"]],[28,\"action\",[[23,0,[]],\"showDropdown\"],null]]],{\"statements\":[[0,\"\\t\\t\\t\"],[14,2,[[28,\"action\",[[23,0,[]],\"hideDropdown\"],null]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isToggleBtn\"]],[24,[\"model\",\"hasDispatchProcess\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"elements/element-btn\",null,[[\"disabled\",\"hasStatusBadge\",\"statusBadgeClassNames\",\"isToggler\",\"toggleIsOn\",\"label\",\"translate\"],[[28,\"or\",[[24,[\"disabled\"]],[24,[\"model\",\"_data\",\"isVoid\"]]],null],true,[24,[\"model\",\"statusClass\"]],true,[24,[\"toggleValue\"]],[24,[\"label\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"u-models__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"tag \",[24,[\"model\",\"statusClass\"]]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"label\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/status-tag/template.hbs"
    }
  });

  _exports.default = _default;
});