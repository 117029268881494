define("client/pods/components/contacts/panels-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b8Y00mBl",
    "block": "{\"symbols\":[\"reward\",\"memberLevel\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"report-row\"],[3,\"did-insert\",[[23,0,[\"getContactWithRewardData\"]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"report-half-item\"],[8],[0,\"\\n\"],[4,\"reports/dashboard/card-stat\",null,[[\"title\"],[\"contacts rewards\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"results\"]]],null,{\"statements\":[[0,\"          \"],[7,\"h5\",true],[10,\"class\",\"is-5 has-text-left u-margin-bottom-important-5 u-margin-top-important-10\"],[8],[1,[28,\"tt\",[\"reward\"],null],false],[0,\": \"],[1,[23,1,[\"rewardName\"]],false],[9],[0,\"\\n\\n          \"],[7,\"table\",true],[10,\"class\",\"report__dash-table u-half-width\"],[8],[0,\"\\n            \"],[7,\"tr\",true],[8],[0,\"\\n              \"],[7,\"th\",true],[10,\"class\",\"u-width-40\"],[8],[1,[28,\"tt\",[\"member level\"],null],false],[9],[0,\"\\n              \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"total\"],null],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"levels\"]]],null,{\"statements\":[[0,\"              \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,2,[\"total\"]],false],[9],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/panels-report/template.hbs"
    }
  });

  _exports.default = _default;
});