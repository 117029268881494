define("client/pods/components/helpers/new-browser-tab-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0FokO8rN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",true],[11,\"href\",[29,[[22,\"link\"]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"link\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"link\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/new-browser-tab-link/template.hbs"
    }
  });

  _exports.default = _default;
});