define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PrWyZYA4",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"forms/form-section\",null,[[\"classNames\"],[\"form-section__outer-container--no-border\"]],{\"statements\":[[4,\"forms/form-input-row\",null,[[\"customBlock\"],[true]],{\"statements\":[[0,\"    \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"paginationIsFooter\",\"results\",\"hasFilters\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"tableViewOptions\"],[[24,[\"tab\"]],\"channels/shop/campaigns/items/table-view/detail-row\",false,[24,[\"automationModel\",\"sorted\"]],false,[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[28,\"hash\",null,[[\"adapterName\",\"campaign\",\"campaignDetails\",\"isAutomations\"],[\"channels/shop/campaigns/automations/details\",[24,[\"automationModel\"]],[24,[\"automationDetails\"]],true]]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"context\"],[[24,[\"errors\"]],\"details\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-row\",null,null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"label\",\"closeOnOverlayClick\"],[\"fas fa-plus\",\"add product\",false]],{\"statements\":[[0,\"      \\n      \"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor\",null,[[\"tab\",\"isNew\",\"isEditing\",\"isAutomations\",\"parentMaster\",\"campaign\",\"campaignDetails\",\"adapterName\",\"onDone\"],[[24,[\"tab\"]],true,false,true,[24,[\"master\",\"_data\",\"_key\"]],[24,[\"automationModel\"]],[24,[\"automationModel\",\"sorted\"]],\"channels/shop/campaigns/automations/details\",[23,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/template.hbs"
    }
  });

  _exports.default = _default;
});