define("client/pods/components/documents/document-print/print-list/print-doc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ishMrHh0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"printDoc\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"u-page-break\"],[8],[0,\"\\n\"],[0,\"        \"],[1,[28,\"documents/document-print\",null,[[\"model\",\"hasPrices\",\"hasPrintBtn\",\"hasReturnsDetails\",\"selectedContact\",\"onCloseDialogAction\"],[[24,[\"model\"]],[24,[\"hasPrices\"]],[24,[\"hasPrintBtn\"]],[24,[\"hasReturnsDetails\"]],[24,[\"selectedContact\"]],[24,[\"onCloseDialogAction\"]]]]],false],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/print-list/print-doc/template.hbs"
    }
  });

  _exports.default = _default;
});