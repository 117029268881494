define("client/pods/components/export/worksheet/editor/customworksheet-nested/customworksheet-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QCwUzLdI",
    "block": "{\"symbols\":[\"@customSheet\",\"@model\",\"@disabled\",\"@removeListDropdown\"],\"statements\":[[5,\"helpers/drop-container\",[],[[\"@dropPosition\",\"@droppable\",\"@onDragOver\",\"@onDrop\"],[[23,0,[\"dragAndDrop\",\"toDropPosition\"]],[23,0,[\"draggable\"]],[28,\"fn\",[[23,0,[\"dragAndDrop\",\"onDragOver\"]],[23,1,[\"_data\",\"index\"]]],null],[28,\"fn\",[[23,0,[\"dragAndDrop\",\"onDropWithIndex\"]],[23,1,[\"_data\",\"index\"]],[23,2,[\"_data\",\"defaultExportCustomSheets\"]],\"_data.index\",\"\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"nav-tree__specs-item\"],[8],[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@hideIsEmpty\"],[false]],{\"statements\":[[0,\"\\n      \"],[5,\"helpers/drag-container\",[],[[\"@onDragStart\",\"@draggable\",\"@data\"],[[28,\"fn\",[[23,0,[\"dragAndDrop\",\"onDragStart\"]],[23,1,[\"_data\",\"index\"]]],null],[23,0,[\"draggable\"]],[23,1,[\"_data\",\"index\"]]]]],[0,\"\\n\\n      \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n      \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@translate\",\"@list\",\"@defaultLabel\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@label\",\"@addItemComponent\",\"@onAfterAddItem\"],[true,false,[23,0,[\"availableCustomWorksheetAndOwnWorksheets\"]],[28,\"tt\",[\"add custom worksheet\"],null],\"_data._key\",\"_data.description\",[23,1,[\"_data\",\"value\"]],[23,1,[\"_data\",\"label\"]],\"specs/specs-editor\",[23,0,[\"fetchSpecsTask\"]]]]],[0,\"\\n      \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n      \"],[5,\"elements/btn-delete\",[],[[\"@disabled\",\"@label\",\"@tooltipLabel\",\"@onSubmit\",\"@requiresConfirmation\",\"@confirmMsg\",\"@confirmMsgTranslate\"],[[23,3,[]],\"\",\"remove\",[28,\"fn\",[[23,4,[]],[23,1,[]]],null],true,\"are you sure you want to remove this custom sheet?\",true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/export/worksheet/editor/customworksheet-nested/customworksheet-item/template.hbs"
    }
  });

  _exports.default = _default;
});