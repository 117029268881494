define("client/pods/components/schedules/btn-scheduled/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let SchedulesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class SchedulesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "settings", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "pending", _descriptor6, this);
      const resource = this.args.resource;
      const resourceKey = this.args.resourceKey;
      const filters = this.search.setupFilters({
        adapterName: 'schedules/filters',
        appendPath: '/resources',
        defaultFiltersData: {
          resource,
          resourceKey
        }
      });
      this.filters = filters;
      this.fetchScheduledTasksByResourceTask.perform();
    }

    *fetchScheduledTasksByResourceTask(options = {}) {
      if (this.filters._data.resourceKey) {
        const results = yield this.crud.searchRecordsTask.perform({
          adapterName: 'schedules',
          filters: this.filters
        });
        this.results = results;
        this.pending = R.filter(R.pathEq(['_data', 'status'], this.constants.schedulesStatus.pending))(results);
      } else {
        this.results = [];
        this.pending = [];
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pending", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchScheduledTasksByResourceTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchScheduledTasksByResourceTask"), _class.prototype)), _class));
  _exports.default = SchedulesComponent;
});