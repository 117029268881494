define("client/pods/components/files/files-browser/component", ["exports", "client/mixins/crud", "ember-concurrency", "ramda"], function (_exports, _crud, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    tagName: '',
    resource: 'files',
    resourceKey: 'files',
    allowUploads: true,
    filtersAdapterName: 'uploads/filters',
    resultsProperty: 'uploads',
    adapterName: 'uploads',
    selectedItems: Ember.A([]),
    hasSelectedItemsLimit: false,
    selectedItemsLimit: 0,

    init() {
      this._super(...arguments);

      const resultsProperty = this.resultsProperty;

      if (!this.filters) {
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {
            resultsProperty
          }
        });
        this.set('filters', filters);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this.resetSelectedItems();
    },

    setUploadsProxy(results) {
      const uploadsProxy = R.map(uploads => this.crud.setupDirty({
        adapterName: this.adapterName,
        model: uploads
      }))(results);
      this.set('uploads', uploadsProxy);
    },

    customPaginateSearchRecordsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.paginateSearchRecordsTask.perform(...arguments);
      this.setUploadsProxy(results);
    }),

    resetSelectedItems() {
      R.forEach(file => {
        file.set('isSelected', false);
      })(this.uploads);
      this.selectedItems.clear();
    },

    fetchUploadsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this.filters,
        data: {
          resource: this.resource,
          resourceKey: this.resourceKey
        }
      });
      this.setUploadsProxy(results);
    }),
    actions: {
      showPreview(file) {
        this.set('showPreviewModal', file);
      },

      submit(onCloseDialogAction, showPreviewModal) {
        if (this.allowMultipleItems) {
          this.onSubmit(this.selectedItems);
          this.resetSelectedItems();
        } else {
          this.onSubmit(showPreviewModal);
        }

        if (!onCloseDialogAction.type && onCloseDialogAction) {
          onCloseDialogAction();
        }
      },

      remove(onCloseDialogAction, showPreviewModal) {
        if (this.allowMultipleItems) {
          this.onRemove(this.selectedItems);
          this.resetSelectedItems();
        } else {
          this.onRemove(showPreviewModal);
        }
      },

      cancel() {
        this.resetSelectedItems();
        this.onCancel();
      },

      addToSelected(item, toggleModel) {
        var _this$selectedItems;

        const isOverLimit = (this === null || this === void 0 ? void 0 : (_this$selectedItems = this.selectedItems) === null || _this$selectedItems === void 0 ? void 0 : _this$selectedItems.length) >= this.selectedItemsLimit;

        if (this.hasSelectedItemsLimit && isOverLimit) {
          if (toggleModel.toggleIsOn) {
            item.isSelected = false;
          } else {
            this.selectedItems.removeObject(item);
          }

          return;
        }

        if (this.selectedItems.includes(item)) {
          this.selectedItems.removeObject(item);
        } else {
          this.selectedItems.addObject(item);
        }
      }

    }
  });

  _exports.default = _default;
});