define("client/mixins/news-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      appendPath,
      match,
      status,
      query = '',
      byLatest,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);

      if (match) {
        filters.setData('match', match);
      }

      if (status) {
        if (!Ember.isArray(status)) {
          status = [status];
        }

        filters.setData('status', status);
      }

      if (byLatest) {
        filters.setData('sort', [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]);
      }

      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }) // actions: {
    //   listByDate (onHideDropdown, dateZ, date) {
    //     return this.get('listByTask').perform({ date, dateZ, resultsToggle: 'resultsToggle' }, onHideDropdown)
    //   }
    // }

  });

  _exports.default = _default;
});