define("client/pods/components/helpers/pagination-controls/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    showPage: false,
    classNames: ['pagination', 'is-centered', 'is-small'],
    currentPage: Ember.computed('filters._data.page', function () {
      return this.get('filters._data.page') || 1;
    }),
    isNextDisabled: Ember.computed('list.[]', 'filters.{results,_data.count}', 'hasFullCount', function () {
      const count = this.get('filters._data.count') || 0;
      let results = this.get('filters.results') || false;

      if (R.isNil(results) || !results) {
        results = this.get('list') || [];
      }

      const resultsCount = results.length || 0;

      if (resultsCount < 0) {
        return true;
      }

      if (resultsCount < count) {
        return true;
      }

      if (this.hasFullCount) {
        const allPagesArray = this.allPagesArray || [];
        const lastPageNumber = R.last(allPagesArray);

        if (this.currentPage === lastPageNumber) {
          return true;
        }
      }

      return false;
    }),
    hasFullCount: Ember.computed('filters.{fullCount,_data.count,_data.page}', function () {
      const filters = this.filters;

      if (filters) {
        const hasFullCount = R.hasPath(['fullCount'])(filters);

        if (hasFullCount) {
          const fullCount = R.path(['fullCount'])(filters) || 0;
          return fullCount;
        }
      }

      return false;
    }),
    allPagesArray: Ember.computed('hasFullCount', 'filters.{fullCount,_data.count,_data.page}', function () {
      const filters = this.filters;

      if (filters) {
        const fullCount = this.hasFullCount;
        const count = R.path(['_data', 'count'])(filters);

        if (fullCount > 0 && count > 0) {
          const totalPages = Math.ceil(fullCount / count);
          return R.range(1, totalPages + 1);
        }
      }

      return [];
    }),
    actions: {
      previousPage() {
        const filters = this.filters;
        const currentPage = parseInt(filters.getData('page')) || 1;
        const page = currentPage - 1;
        filters.setData('page', page);
        this.set('showGoToPage', false);
        const context = this.context;

        if (this.onPaginationTask.perform) {
          return this.onPaginationTask.perform({
            filters,
            context
          });
        }

        return this.onPaginationTask({
          filters,
          context
        });
      },

      nextPage() {
        // @TODO: redo this. onPaginationTask should be a curried function
        const filters = this.filters;
        const component = this.component;
        const currentPage = parseInt(filters.getData('page')) || 1;
        const page = currentPage + 1;
        filters.setData('page', page);
        this.set('showGoToPage', false);
        const context = this.context;

        if (this.onPaginationTask.perform) {
          return this.onPaginationTask.perform({
            filters,
            context,
            component
          });
        }

        return this.onPaginationTask({
          filters,
          context
        });
      },

      goToPage(page) {
        if (page) {
          var _this$onPaginationTas;

          const filters = this.filters;
          page = parseInt(page) || 1;
          filters.setData('page', page);
          this.set('showGoToPage', false);
          const context = this.context;

          if (this !== null && this !== void 0 && (_this$onPaginationTas = this.onPaginationTask) !== null && _this$onPaginationTas !== void 0 && _this$onPaginationTas.perform) {
            return this.onPaginationTask.perform({
              filters,
              context
            });
          } else {
            return this.onPaginationTask();
          }
        }
      },

      showGoToPage() {
        if (!this.page) {
          this.set('page', 1);
        }

        this.set('showGoToPage', true);
      }

    }
  });

  _exports.default = _default;
});