define("client/pods/components/elements/element-toggler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnA/2alz",
    "block": "{\"symbols\":[\"@isRunning\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",[29,[\"element-toggler-container \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isOn\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"iconClassNames\"],[\"element-toggler has-text-success fas fa-toggle-on\",[24,[\"iconClassNames\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"labelOn\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"elements/element-label\",null,[[\"label\",\"translate\"],[[24,[\"labelOn\"]],[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"iconClassNames\"],[\"element-toggler fas fa-toggle-off\",[24,[\"iconClassNames\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"labelOff\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"elements/element-label\",null,[[\"label\",\"translate\"],[[24,[\"labelOff\"]],[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-label\",null,[[\"label\",\"translate\"],[[24,[\"label\"]],[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-toggler/template.hbs"
    }
  });

  _exports.default = _default;
});