define("client/pods/products/service", ["exports", "ramda", "ramda-adjunct", "ember-concurrency"], function (_exports, R, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    server: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    constants: Ember.inject.service(),
    search: Ember.inject.service(),
    crud: Ember.inject.service(),

    init() {
      this._super('...arguments');

      this.set('statusClasses', {
        [this.get('constants.productsStatus.active')]: 'is-primary',
        [this.get('constants.productsStatus.inactive')]: 'is-danger',
        [this.get('constants.productsStatus.preOrder')]: 'is-warning',
        [this.get('constants.productsStatus.draft')]: 'is-warning',
        [this.get('constants.productsStatus.outOfStock')]: 'is-black',
        [this.get('constants.productsStatus.soldOut')]: 'is-black'
      });
    },

    searchInputTask: (0, _emberConcurrency.task)(function* (args = {}) {
      let {
        adapterName = 'products',
        filtersAdapterName = 'products/filters',
        query = '',
        resultsProperty = '',
        component,
        resultsToggle = false,
        global = true,
        filters
      } = args;

      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query,
            match: [],
            sort: [{
              by: 'name',
              direction: 'ASC'
            }],
            status: [this.get('constants.status.active')]
          }
        });
      }

      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle
      });

      if (global && resultsProperty) {
        // this[resultsProperty] = results
        this.set(resultsProperty, results);
      }

      return results;
    }),

    getStatusClass(status) {
      const statusClasses = this.statusClasses;
      return R.propOr(this.unknownStatusClass, status, statusClasses);
    },

    fetchDocHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      const docType = docModel.getData('docType');
      const docName = docModel.getData('docName');
      const contactKey = docModel.getData('contactKey');
      const contact = docModel.getData('contact');
      const priceHistoryData = yield this.getHistoryTask.perform({
        docType,
        docName,
        contact,
        contactKey,
        itemKey
      });
      let preferredHistoryData;
      preferredHistoryData = this.getLatestFor(preferredKey, priceHistoryData);

      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),
    fetchPurchasesHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      docModel.setData('docType', 'purchases');
      docModel.setData('docName', 'invoices');
      return yield this.fetchDocHistoryTask.perform(docModel, preferredKey, product, itemKey);
    }),
    getHistoryTask: (0, _emberConcurrency.task)(function* ({
      docType,
      docName,
      contact,
      contactKey,
      item,
      itemKey,
      docTypes
    }) {
      const data = {
        contact,
        contactKey,
        item,
        itemKey,
        docTypes
      };
      const results = yield this.server.call('GET', `api/protected/documents/data/prices/${docType}/${docName}`, data);
      return R.propOr({}, 'data', results);
    }),
    fetchHistoryCampaignsTask: (0, _emberConcurrency.task)(function* (product, {
      itemKey
    }) {
      const results = yield this.server.call('GET', 'api/protected/documents/data/prices/campaigns', {
        itemKey
      });
      const priceHistoryData = R.propOr({}, 'data', results);
      let preferredHistoryData = this.getLatestFor('campaigns', priceHistoryData);

      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),
    fetchHistoryCampaignsAutomationsTask: (0, _emberConcurrency.task)(function* (product, {
      itemKey
    }) {
      const results = yield this.server.call('GET', 'api/protected/documents/data/prices/campaigns/automations', {
        itemKey
      });
      const priceHistoryData = R.propOr({}, 'data', results);
      let preferredHistoryData = this.getLatestFor('automations', priceHistoryData);

      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),

    getFor(prop, historyData) {
      return R.pathOr([], ['history', prop], historyData);
    },

    getLatestFor(prop, historyData) {
      // return R.pathOr([], ['history', prop], historyData)
      const forProp = this.getFor(prop, historyData);
      let found = false;

      if (!R.isEmpty(forProp)) {
        found = R.head(forProp);
      }

      return found;
    },

    getLatestDetail(props, historyData) {
      let found = '';
      R.forEach(prop => {
        if (!found) {
          const forProp = this.getFor(prop, historyData);

          if (!R.isEmpty(forProp)) {
            found = R.head(forProp);
          }
        }
      }, props);
      return found || false;
    },

    setPrice(model, historyData, onHideDropdown) {
      model.setPrice(historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setDetailPrice(detail, child, historyData, onHideDropdown) {
      detail.setDetailPrice(child, historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setProductCommissionsBaseCost(detail, historyData, onHideDropdown) {
      detail.setProductCommissionsBaseCost(detail, historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    selectChildHistoryPrice(detail, historyData, onHideDropdown) {
      detail.setChildProductPrice(detail, historyData);

      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        detail.recalculatePriceForSet(detail);
      }

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    getTaxRate(docType, taxes, product) {
      // @TODO: should get taxRateKey from product to fetch tax rate from lists.tax
      // also fetch tax rate label from lists.tax
      // if no taxRate from product then get from settings
      // PRO: if tax rate is removed, then assume taxrate no longer available
      //      but if using taxrate and label stored in products, it will use old taxrate/label instead of settings
      // CON: requires additional step to search lists.tax
      const taxRateKey = product.getData(`${docType}TaxRateKey`);
      let taxRate;

      if (taxRateKey) {
        const productTax = taxes.findBy('_data._key', taxRateKey);

        if (productTax) {
          taxRate = productTax.getData('value');
        }
      }

      if (!taxRateKey || taxRateKey === 'auto') {
        const defaultTaxRate = this.settings.getDefaultTaxRate(taxes);
        taxRate = defaultTaxRate;
      }

      if (taxRateKey === 'none') {
        taxRate = 0;
      }

      return taxRate;
    }

  });

  _exports.default = _default;
});