define("client/pods/components/documents/document-totals/spend-rewards-dialog/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DocumentsDocumentTotalsSpendRewardsDialogComponent = (_dec = Ember._action, (_class = class DocumentsDocumentTotalsSpendRewardsDialogComponent extends _component.default {
    setRewardDataUsage() {
      this.args.onSumTotals();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "setRewardDataUsage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setRewardDataUsage"), _class.prototype)), _class));
  _exports.default = DocumentsDocumentTotalsSpendRewardsDialogComponent;
});