define("client/pods/payments/card/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        dateStart: '',
        dateEnd: '',
        abandonded: '',
        contact: '',
        contactKey: '',
        email: '',
        externalId: '',
        ref: '',
        partialCreditCardNumber: '',
        paymentCreditCardStatus: [],
        dispatchProcesses: [],
        multipleDispatchProcessesCriterias: [],
        paymentTransactionDateZ: '',
        sourceKey: '',
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }

  });

  _exports.default = _default;
});