define("client/mixins/sales-persons", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.setData('salesPersons', []);
    },

    /**
     * transform salesPersons array into array of models
     */
    transformSalesPersons(rawData) {
      const self = this;

      if (R.is(Array, rawData.salesPersons)) {
        let transformed = rawData.salesPersons.map(function (data) {
          return self._createSalesPersonModel(data);
        });
        rawData.salesPersons = transformed;
      }
    },

    _createSalesPersonModel(data) {
      data._id = `members/${data._key}`;
      return this.dataManager.setAsRecord({
        adapterName: 'users',
        data: data
      });
    },

    /**
     * adds sales person
     * @param {object} data (optional) - detail data
     * @return {object} detail object
     */
    addSalesPerson(data) {
      data = data || {};
      const salesPersons = this.getOrSetData('salesPersons', []);
      const hasPerson = salesPersons.findBy('_data._key', data._key);

      if (!hasPerson) {
        const salesPerson = this._createSalesPersonModel(data);

        this.getData('salesPersons').pushObject(salesPerson);
      }
    },

    /**
     * removes sales person
     */
    removeSalesPerson(salesPerson) {
      const salesPersons = this.getData('salesPersons');
      salesPersons.removeObject(salesPerson);
    }

  });

  _exports.default = _default;
});