define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor2, this);
    }

    get currentBulkDiscountName() {
      const model = this.args.model;
      const bulkDiscounts = model.getData('bulkDiscounts') || [];
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = bulkDiscount.getData('isCurrent') || false;
      const bulkDiscountName = bulkDiscount.getData('bulkDiscountName') || '';

      if (!isCurrent && bulkDiscountName) {
        return bulkDiscountName;
      }

      let bulkDiscountId = '';

      if (!isCurrent && !bulkDiscountName) {
        bulkDiscountId = bulkDiscount.getData('bulkDiscountId') || '';
      }

      if (isCurrent) {
        const conflictsList = this.args.conflict.getData('conflictsList') || [];
        bulkDiscountId = R.pipe(R.find(R.pathEq(['_data', 'isCurrent'], true)), R.pathOr('', ['_data', 'bulkDiscountId']))(conflictsList);
      }

      return R.pipe(R.find(R.pathEq(['_data', 'bulkDiscountId'], bulkDiscountId)), R.pathOr('', ['_data', 'name']))(bulkDiscounts);
    }

    get isSelected() {
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = R.pathOr(false, ['_data', 'isCurrent'])(bulkDiscount);

      if (isCurrent) {
        return true;
      }

      return false;
    }

    *chooseBulkDiscountTask(bulkDiscountId) {
      const conflictModel = this.args.conflict;
      conflictModel.setData('bulkDiscountId', bulkDiscountId);
      this.args.updateCampaignDetailBulkDiscountTask.perform({
        model: conflictModel
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chooseBulkDiscountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "chooseBulkDiscountTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent;
});