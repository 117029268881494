define("client/pods/components/channels/shop/rewards/rewards-editor/member-levels-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "earningTypeList", _descriptor3, this);
    }

    /**
     * Append new member level Model to reward Model
     * @param {Model} reward - reward Model
     * @returns {Void}
     */
    addMemberLevelAction(reward) {
      reward.addMemberLevel();
    }

    onSubmitAddRewardEventOrder(memberLevel) {
      const hasNoOrderRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.order)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoOrderRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.order
        });
      }
    }

    onSubmitAddRewardEventBirthday(memberLevel) {
      const hasNoBirthdayRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.birthday)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoBirthdayRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.birthday,
          earnType: this.constants.rewardEarningTypes.amount
        });
      }
    }

    onSubmitAddRewardEventSignup(memberLevel) {
      const hasNoSignupRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.signup)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoSignupRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.signup,
          earnType: this.constants.rewardEarningTypes.amount
        });
      }
    }
    /**
     * Remove member level Model from reward Model
     * @param {Model} reward - reward Model
     * @param {Model} memberLevel - member level Model
     * @returns {Void}
     */


    onSubmitRemoveMemberLevel(reward, memberLevel) {
      const memberLevelList = reward.get('_data.memberLevels');
      memberLevelList.removeObject(memberLevel);
    }
    /**
     * Remove reward event Model from member level Model
     * @param {Model} memberLevel - member level Model
     * @param {Model} rewardEvent - reward event Model
     * @returns {Void}
     */


    onSubmitRemoveRewardEvent(memberLevel, rewardEvent) {
      const rewardEventList = memberLevel.get('_data.rewardsEvents');
      rewardEventList.removeObject(rewardEvent);
    }

    onSelectRewardEventType(selected) {
      const selectedEvent = selected.getData('value');

      if (selectedEvent !== this.constants.rewardEvents.order) {
        const amountEarningType = R.find(R.pathEq(['_data', 'value'], this.constants.rewardEarningTypes.amount))(this.crud.lists['channels-shop-rewards-earnings']);
        this.earningTypeList = [amountEarningType];
      } else {
        this.earningTypeList = this.crud.lists['channels-shop-rewards-earnings'];
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "earningTypeList", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R_.dotPathOr([], 'crud.lists.channels-shop-rewards-earnings')(this);
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addMemberLevelAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addMemberLevelAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddRewardEventOrder", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventOrder"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddRewardEventBirthday", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventBirthday"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddRewardEventSignup", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventSignup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveMemberLevel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveMemberLevel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveRewardEvent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveRewardEvent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSelectRewardEventType", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectRewardEventType"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent;
});