define("client/pods/components/payments/card/tab-container/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "client/mixins/payments-list-by", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda"], function (_exports, _applyDecoratedDescriptor2, _paymentsListBy, _date, _crud, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var _default = Ember.Component.extend(_crud.default, _date.default, _paymentsListBy.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    // listAdapters: [],
    // results: [],
    adapterName: 'payments/card',

    // filtersAdapterName: 'payments/card/filters',
    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['periods/months', 'periods/years', 'dispatch/lists/processes', 'payments/card/lists/providers', 'payments/card/lists/panels']);
      const provider = this.settings.getDefaultCreditCardProvider();
      this.set('selectedProvider', provider);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {} else {
        const firstPanelModel = R.pathOr([], ['crud', 'lists', 'payments-card-lists-panels', 0])(this);
        const filters = this.search.setupFilters({
          adapterName: firstPanelModel.getData('filtersAdapterName')
        });
        filters.setData('provider', this.selectedProvider);
        this.set('filters', filters);
        const period = this.getPeriod();
        period.dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        period.dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        this.set('period', period);
        this.displayPanel(firstPanelModel);
      }
    }),

    displayPanel(resource) {
      const tab = this.tab;
      const subTabOptions = {
        component: resource.getData('component'),
        period: this.period,
        filtersAdapter: resource.getData('filtersAdapterName')
      };
      const filters = this.search.setupFilters({
        adapterName: resource.getData('filtersAdapterName')
      });
      filters.setData('provider', this.selectedProvider);
      this.set('filters', filters);
      const exportFilters = this.search.setupFilters({
        adapterName: resource.getData('filtersAdapterName')
      });
      exportFilters.setData('provider', this.selectedProvider);
      this.set('exportFilters', exportFilters);
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      // dash () {
      //   this.set('resultsToggle', false)
      //   this.dash()
      // },
      display(model) {
        this.display(model);
      },

      displayByMonth(period) {
        this.displayByMonth(period);
      },

      displayByCustomMonth(mm, yyyy) {
        this.displayByMonth({
          mm,
          yyyy
        });
      },

      setSearchQuery() {
        var _this$filters, _this$filters$_data;

        this.set('afterSearchQuery', (_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : (_this$filters$_data = _this$filters._data) === null || _this$filters$_data === void 0 ? void 0 : _this$filters$_data.query);
      }

    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "displayPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "displayPanel"), _obj)), _obj)));

  _exports.default = _default;
});