define("client/pods/components/messages/tab-container/messages-window/component", ["exports", "ramda", "ember-concurrency", "client/utils/nventor", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _nventor, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    searchService: Ember.inject.service('search'),
    filtersAdapterName: 'messages/messages/filters',
    adapterName: 'messages/messages',

    didInsertElement() {
      this._super(...arguments);

      const filters = this.searchService.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.fetchMessagesTask.perform();
      this.isScrollAtTop();
      this.messagesAutoFetch();
    },

    didUpdate() {
      this._super(...arguments);

      const autoFetchToggle = this.autoFetchToggle;

      if (autoFetchToggle) {
        this.messagesAutoFetch();
      }
    },

    messagesAutoFetch() {
      const autoFetchToggle = this.autoFetchToggle;

      if (autoFetchToggle) {
        setTimeout(() => {
          this.fetchMessagesTask.perform();
          this.messagesAutoFetch();
        }, 10000);
      }
    },

    /* TODO: DOUBLE CLICK PREPARE TO MOVE TO ON VIEW */
    getMoreMessagesTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      const currentPage = parseInt(filters.getData('page')) || 1;
      const page = currentPage + 1;
      this.goDown();
      filters.setData('page', page);
      this.set('showGoToPage', false);
      yield this.paginateSearchRecordsTask.perform({
        filters
      });
    }),

    didRender() {
      const isViewAtBottom = this.isScrollAtBottom();

      if (isViewAtBottom) {
        const userModel = this.model;
        const senderId = userModel.get('_data.id');
        this.model.resetNewMessagesCount();
        this.gotToBottom();
        this.set('hasNewMsg', false); // Reset unread messages count when new message has been display on the message window

        this.deleteUnreadMessagesTask.perform(senderId);
      } else {
        this.set('hasNewMsg', true);
      }
    },

    /**
     * AUTO SCROLL TO BOTTOM ON MESSAGE WINDOW
     * */
    gotToBottom() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.$('.chat-window').animate({
          scrollTop: 20000000
        }, 'slow');
      });
    },

    /**
     * Scroll down [ X ] amount of pixels on the Message Windows
     * */
    goDown(scrollDownBy = 100) {
      this.$('.chat-window').animate({
        scrollTop: this.$('.chat-window').prop('scrollTop') + scrollDownBy
      }, 'slow');
    },

    /**
     * If the window is at the bottom then return true | false
     * @return {boolean}
     * */
    isScrollAtBottom(triggerArea = 100) {
      this.set('activeView', true);
      const scrollPosition = this.$('.chat-window').prop('scrollTop');
      const scrollHeight = this.$('.chat-window').prop('scrollHeight');
      const offsetHeight = this.$('.chat-window').prop('offsetHeight');
      const scrollOffset = scrollHeight - (scrollPosition + offsetHeight);
      return scrollOffset <= triggerArea;
    },

    /**
     * If the window is at the top then return true | false
     * @return {boolean}
     * */
    isScrollAtTop(triggerArea = 100) {
      const scrollPosition = this.$('.chat-window').prop('scrollTop');
      return scrollPosition <= triggerArea;
    },

    /**
     * If the user has new messages return true | false
     * @return {boolean}
     * */
    hasNewMessage: Ember.computed('model.newMessagesCount.[]', function () {
      const userModel = this.model;

      if (userModel) {
        const newMessagesCount = userModel.get('newMessagesCount');
        const isViewAtBottom = this.isScrollAtBottom();
        return newMessagesCount >= 1 && !isViewAtBottom;
      }
    }),

    /**
     * Should delete unread messages once a user chat window has been clicked
     * */
    deleteUnreadMessagesTask: (0, _emberConcurrency.task)(function* (senderId) {
      yield this.server.call('DELETE', `api/protected/messages/facebook/messages/unread-messages/${senderId}`);
    }).enqueue(),

    /**
     * Get last 10 messages from db sorted by user
     * */
    fetchMessagesTask: (0, _emberConcurrency.task)(function* () {
      this.gotToBottom();
      const filters = this.filters;
      const userModel = this.model;
      const senderId = userModel.get('_data.id');
      const chatMessages = yield this.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters,
        appendPath: senderId,
        resultsProperty: 'results'
      });
      userModel.setupMessages(chatMessages); // this.set('results', chatMessages)

      userModel.set('_data.unreadMessages', 0); // Reset unread messages count when pull the last 10 messages from db

      this.deleteUnreadMessagesTask.perform(senderId);
    }).enqueue(),

    /**
     * Pagination handler
     * */
    afterPaginateSearchRecordsTask(results) {
      const userModel = this.model;
      const currentMessages = userModel.get('_data.messages');
      const mergeMessages = R.concat(results, currentMessages);
      userModel.setupMessages(mergeMessages);
      this.set('results', results);
    },

    /**
     * Handle quickReplies messages
     * */
    hasQuickReplies: Ember.computed('model.chatMessages.[]', function () {
      const chatMessages = this.get('model.chatMessages');

      if (chatMessages.length >= 1) {
        return R.pipe(R.last, R.ifElse(R.pathEq(['_data', 'message', 'type'], 'options'), m => m.getQuickReplies(), () => null))(chatMessages);
      }
    }),

    /**
     * Check if the user has new messages
     * @return {boolean}
     * */
    hasMoreResults: Ember.computed('model.chatMessages', 'filters._data.count', function () {
      const count = parseInt(this.get('filters._data.count')) || 0;
      const results = this.get('model.chatMessages') || [];

      if (count === 0 && results.length === 0) {
        return false;
      } else if (results.length < count) {
        return false;
      } else {
        return true;
      }
    }),

    /**
     * Check whether or not a user is a line user
     * */
    isLineMember: Ember.computed('model.memberOf', function () {
      const memberOf = this.get('model.memberOf') || 'none';

      switch (memberOf) {
        case 'line':
          return false;

        case 'facebook':
          return false;

        default:
          return false;
      }
    }),

    /**
     * Check whether or not has past 24 hrs since the user send a message to the facebook page
     * */
    isOver24HrsLimit: Ember.computed('model.chatMessages.[],model.memberOf', function () {
      const memberOf = this.get('model.memberOf') || 'none';
      const isBot = this.get('model.isBot') || true;
      const getLastTimeStamp = R.pipe(R.filter(R.pathEq(['_data', 'fromUs'], false)), _nventor.default.safeLast, R.path(['_data', 'timestampZ']));
      const results = this.get('model.chatMessages') || [];
      const timeStamp = getLastTimeStamp(results);
      const today = new Date();
      const lastTimeStamp = new Date(timeStamp);
      lastTimeStamp.setDate(lastTimeStamp.getDate() + 1);

      if (memberOf === 'line' || isBot) {
        return false;
      }

      return R.not(today < lastTimeStamp);
    }),

    /**
     * Check for active flows to display on the bot builder
     * */
    activeFlow: Ember.computed('model._data.activeFlow,model._data.chatMessages.[].@each', function () {
      const activeFlow = this.get('model._data.activeFlow') || '';
      const messages = this.get('model._data.messages') || [];
      const endFlow = R.pipe(R.last, R.pathOr(false, ['_data', 'endFlow']))(messages);

      if (activeFlow.length >= 1 && activeFlow !== 'CANCEL' && !endFlow) {
        return true;
      } else {
        return false;
      }
    }),

    /**
     * Send message to the user ether by line | facebook
     * */
    sendMessageTask: (0, _emberConcurrency.task)(function* (postBack) {
      const isTextEmpty = R.isEmpty;
      let inText = this.messageText;
      const userModel = this.model;
      /*
      ######################################
      ### TODO: HANDLE BOT BUILDER POSTBACKS
      ######################################
          if (postBack) {
            inText = postBack
            userModel.setData('activeFlow', postBack)
          } else if (isTextEmpty(inText)) {
            return
          }
      */

      if (isTextEmpty(inText)) {
        return;
      }

      this.set('messageText', '');
      let pageId = userModel.getData('pageId');
      const recipient = userModel.getData('id');
      const locale = userModel.getData('locale');
      const messageProvider = this.get('results.0._data.messageProvider') || 'bigordr';
      const channelId = this.get('results.0._data.channelId') || '000000';
      const chatMessages = userModel.get('chatMessages') || [];
      const specialId = userModel.getData('specialId');
      const replyToken = R.pipe(R.filter(R.pathEq(['_data', 'fromUs'], false)), R.last, R.view(R.lensPath(['_data', 'replyToken'])))(chatMessages) || '000000';

      if (messageProvider === 'bigordr') {
        pageId = 'bigordr-bot-101';
      }
      /*
      ######################################################################
      ### TODO: ENHANCEMENT - USE WEBSOCKET TO PUSH MESSAGE RATHER THAN AJAX
      ######################################################################
          const tmpMessage = yield this.get('createRecordTask').perform({
            adapterName: 'messages/message',
            data: {
              message: inText,
              pageId,
              sender: pageId,
              recipient,
              timestampZ: new Date(),
              fromUs: true,
              type: 'TEXT',
              text: inText,
              messageProvider,
              channelId,
              replyToken,
              specialId,
              user: {
                userId: recipient,
                locale
              }
            }
          })
      */


      const tmpMessage = this.dataManager.setAsRecord({
        adapterName: 'messages/messages',
        data: {
          message: inText,
          pageId,
          sender: pageId,
          recipient,
          timestampZ: new Date(),
          fromUs: true
        }
      });
      this.get('model._data.messages').pushObject(tmpMessage);
      this.gotToBottom();
      /* TODO: IMPLEMENT DIFFERENT TYPES OF MESSAGES */

      const payload = {
        type: 'TEXT',
        text: inText,
        pageId,
        recipient,
        messageProvider,
        channelId,
        replyToken,
        specialId,
        user: {
          userId: recipient,
          locale
        }
      };
      yield this.server.call('POST', 'api/protected/bot/send-message', payload);
    }),
    actions: {
      gotToBottomArrow() {
        const userModel = this.model;
        const senderId = userModel.get('_data.id');
        this.model.resetNewMessagesCount();
        this.gotToBottom();
        this.deleteUnreadMessagesTask.perform(senderId);
      },

      scrollUpFromLastChild() {
        const chatWindow = this.$('.chat-window');
        const chatWindowChild = this.$('.chat-window > div');
        if (chatWindowChild.children().length <= 1) return;
        chatWindow.animate({
          scrollTop: chatWindow.scrollTop() - chatWindow.offset().top + chatWindowChild.children().eq(chatWindowChild.children().length - 3).offset().top
        }, 'slow');
      },

      redirectToFacebookPageInbox() {
        const genPageInboxUrl = (pName, pId) => {
          const parsePageName = R.pipe(R.replace(' - ', '-'), R.replace(' -', '-'), R.replace('- ', '-'), R.replace(' ', '-'));
          const page = parsePageName(pName);
          return `https://www.facebook.com/${page}-${pId}/inbox`;
        };

        const getPageName = R.view(R.lensPath([0, '_data', 'pageName']));
        const getPageId = R.view(R.lensPath([0, '_data', 'pageId']));
        const results = this.get('model.chatMessages') || [];
        const pageName = getPageName(results);
        const pageId = getPageId(results);
        const pageInboxUrl = genPageInboxUrl(pageName, pageId);
        window.open(pageInboxUrl);
      }

    }
  });

  _exports.default = _default;
});