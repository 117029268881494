define("client/pods/components/documents/panel-new/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/pods/components/documents/mixins/pre-submit", "client/pods/components/documents/mixins/details", "ramda-extension"], function (_exports, _date, _settings, _crud, _preSubmit, _details, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _details.default, _settings.default, _preSubmit.default, {
    crud: Ember.inject.service(),

    init() {
      this.crud.addLists(this, ['taxes', 'paymentMethods', 'paymentTerms']);

      this._super(...arguments);

      this.set('dontFetchRegionCode', this.get('tab.config.dontFetchRegionCode'));
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },

    afterLoadLists() {
      this._super(...arguments);

      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      const model = this.setupNewRecord();
      this.set('model', model);
      const docType = this.get('tab.config.docType');
      const docName = this.get('tab.config.docName');
      const defaultData = {
        docType,
        docName,
        paymentMethodsList: R_.dotPath('crud.lists.paymentMethods')(this),
        paymentTermsList: R_.dotPath('crud.lists.paymentTerms')(this)
      };
      model.newBlank(defaultData);
      this.addNewDetail(model);
    }

  });

  _exports.default = _default;
});