define("client/pods/components/reports/dashboard/tab-container/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    init() {
      this._super(...arguments);

      this.dash();
    },

    dash() {
      this.set('resultsToggle', false);
      const tab = this.tab;
      const subTabOptions = {
        component: 'reports/dashboard/panels-dash' // model: model

      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    },

    actions: {
      dash() {
        this.dash();
      }

    }
  });

  _exports.default = _default;
});