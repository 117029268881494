define("client/pods/components/contacts/panels-display/sub-panels-member-levels/adjust-member-level-dialog/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  let ContatcsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class ContatcsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isAdjustManually", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMemberLevel", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "adjustedPoints", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "adjustedLevel", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "toSendPoints", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "isValidPoints", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "expiryTime", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "expiryPeriod", _descriptor14, this);
    }

    get nextMemberLevel() {
      var _this$args, _this$args$contactRew, _this$args$contactRew2, _this$args2, _this$args2$contactRe, _this$args2$contactRe2, _this$args3, _this$args3$contactRe, _this$args3$contactRe2;

      let memberLevels = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$contactRew = _this$args.contactReward) === null || _this$args$contactRew === void 0 ? void 0 : (_this$args$contactRew2 = _this$args$contactRew.rewardData) === null || _this$args$contactRew2 === void 0 ? void 0 : _this$args$contactRew2.memberLevels) || [];
      const currentMemberLevelId = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$contactRe = _this$args2.contactReward) === null || _this$args2$contactRe === void 0 ? void 0 : (_this$args2$contactRe2 = _this$args2$contactRe.level) === null || _this$args2$contactRe2 === void 0 ? void 0 : _this$args2$contactRe2.memberLevelId) || '';
      const currentMemberLevelPoints = (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$contactRe = _this$args3.contactReward) === null || _this$args3$contactRe === void 0 ? void 0 : (_this$args3$contactRe2 = _this$args3$contactRe.level) === null || _this$args3$contactRe2 === void 0 ? void 0 : _this$args3$contactRe2.points) || 0;
      memberLevels = R.sortBy(R.prop('requiredPoints'))(memberLevels);
      const index = R.findIndex(R.propEq('memberLevelId', currentMemberLevelId))(memberLevels);
      const memberLevelsLength = R.length(memberLevels) - 1;
      let nextLevelIndex = index + 1;

      if (nextLevelIndex > memberLevelsLength) {
        nextLevelIndex = memberLevelsLength;
      }

      let nextMemberLevel = {};
      R.forEachObjIndexed((memberLevel, index) => {
        if (Number(index) === nextLevelIndex) {
          nextMemberLevel = memberLevel;
        }
      })(memberLevels);
      const nextLevelRequiredPoints = R.propOr(0, 'requiredPoints')(nextMemberLevel);
      const pointsToNextLevel = Number(nextLevelRequiredPoints) - Number(currentMemberLevelPoints) || 0;
      return {
        name: R.propOr('', 'name')(nextMemberLevel),
        requiredPoints: nextLevelRequiredPoints,
        pointsToNextLevel
      };
    }

    get memberLevelsList() {
      var _this$args4, _this$args4$contactRe, _this$args4$contactRe2, _this$args5, _this$args5$contactRe, _this$args5$contactRe2;

      const memberLevels = (this === null || this === void 0 ? void 0 : (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$contactRe = _this$args4.contactReward) === null || _this$args4$contactRe === void 0 ? void 0 : (_this$args4$contactRe2 = _this$args4$contactRe.rewardData) === null || _this$args4$contactRe2 === void 0 ? void 0 : _this$args4$contactRe2.memberLevels) || [];
      const currentMemberLevelId = (this === null || this === void 0 ? void 0 : (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : (_this$args5$contactRe = _this$args5.contactReward) === null || _this$args5$contactRe === void 0 ? void 0 : (_this$args5$contactRe2 = _this$args5$contactRe.level) === null || _this$args5$contactRe2 === void 0 ? void 0 : _this$args5$contactRe2.memberLevelId) || '';
      this.selectedMemberLevel = currentMemberLevelId;
      const memberLevelsModel = R.map(memberLevel => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels',
          data: memberLevel
        });
      })(memberLevels);
      return memberLevelsModel;
    }

    get adjustedValidPoints() {
      var _this$args6, _this$args6$contactRe, _this$args6$contactRe2;

      const isValidPoints = this.isValidPoints;
      const isAdjustManually = this.isAdjustManually;
      const currentValidPoints = (this === null || this === void 0 ? void 0 : (_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : (_this$args6$contactRe = _this$args6.contactReward) === null || _this$args6$contactRe === void 0 ? void 0 : (_this$args6$contactRe2 = _this$args6$contactRe.pointsData) === null || _this$args6$contactRe2 === void 0 ? void 0 : _this$args6$contactRe2.validPoints) || 0;
      let points = this.adjustedPoints || 0;

      if (isAdjustManually) {
        points = this.toSendPoints || 0;
      }

      let adjustedValidPoints = 0;

      if (isValidPoints) {
        adjustedValidPoints = Number(currentValidPoints) + Number(points) || 0;
      }

      if (adjustedValidPoints < 0) {
        adjustedValidPoints = 0;
      }

      return adjustedValidPoints;
    }

    *sendRewardTask(onCloseDialogAction) {
      var _this$args7, _this$args7$contactRe, _this$args8, _contactModel$_data;

      const rewardData = (this === null || this === void 0 ? void 0 : (_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : (_this$args7$contactRe = _this$args7.contactReward) === null || _this$args7$contactRe === void 0 ? void 0 : _this$args7$contactRe.rewardData) || {};
      const contactModel = (this === null || this === void 0 ? void 0 : (_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : _this$args8.contactModel) || {};
      const isAdjustManually = this.isAdjustManually;
      const isValidPoints = this.isValidPoints;
      let toSendPoints = this.adjustedPoints || 0;

      if (isAdjustManually) {
        toSendPoints = this.toSendPoints || 0;
      }

      let expiryTime = 0;

      if (isValidPoints) {
        expiryTime = this.expiryTime;

        if (!expiryTime) {
          window.alert(this.intl.t('no reward expiry set'));
          return false;
        }
      }

      const emailData = {
        useDefaultEmailMessage: true,
        toSendPoints,
        expiryTime,
        expiryPeriod: this.expiryPeriod,
        rewardEvent: this.constants.rewardEvents.adjustment,
        subject: '',
        message: '',
        dateStart: '',
        dateEnd: '',
        rewardKeys: [rewardData._key],
        contactKey: (contactModel === null || contactModel === void 0 ? void 0 : (_contactModel$_data = contactModel._data) === null || _contactModel$_data === void 0 ? void 0 : _contactModel$_data._key) || '',
        memberLevelIds: [],
        hasBirthdayFilter: false,
        hasSubscriptionFilter: false,
        shopKeys: [],
        statuses: ['active'],
        isNotification: false,
        isValidPoints
      };
      const response = yield this.server.call('PUT', 'api/protected/contacts/rewards/send/adjustment', emailData);
      let sent = 0;

      if (response.data) {
        sent = R.length(response.data);
      }

      onCloseDialogAction();
      window.alert('sent: ' + sent + ' rewards.');
    }

    onToggleSetAdjustMemberLevel() {
      var _this$args9, _this$args9$contactRe, _this$args9$contactRe2;

      const currentMemberLevelId = (this === null || this === void 0 ? void 0 : (_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : (_this$args9$contactRe = _this$args9.contactReward) === null || _this$args9$contactRe === void 0 ? void 0 : (_this$args9$contactRe2 = _this$args9$contactRe.level) === null || _this$args9$contactRe2 === void 0 ? void 0 : _this$args9$contactRe2.memberLevelId) || '';
      const isAdjustManually = this.isAdjustManually;

      if (isAdjustManually) {
        this.isAdjustManually = true;
      } else {
        this.isAdjustManually = false;
      }

      this.toSendPoints = 0;
      this.adjustedPoints = 0;
      this.selectedMemberLevel = currentMemberLevelId;
    }

    onSelectMemberLevel(selectedItem) {
      var _this$args10, _this$args10$contactR, _this$args11, _this$args11$contactR, _this$args11$contactR2;

      const selectedLevel = (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem._data) || {};
      const currentLevel = (this === null || this === void 0 ? void 0 : (_this$args10 = this.args) === null || _this$args10 === void 0 ? void 0 : (_this$args10$contactR = _this$args10.contactReward) === null || _this$args10$contactR === void 0 ? void 0 : _this$args10$contactR.level) || {};
      const currentLevelPoints = (this === null || this === void 0 ? void 0 : (_this$args11 = this.args) === null || _this$args11 === void 0 ? void 0 : (_this$args11$contactR = _this$args11.contactReward) === null || _this$args11$contactR === void 0 ? void 0 : (_this$args11$contactR2 = _this$args11$contactR.level) === null || _this$args11$contactR2 === void 0 ? void 0 : _this$args11$contactR2.points) || 0;

      if (selectedLevel.memberLevelId === currentLevel.memberLevelId) {
        this.adjustedPoints = 0;
      } else {
        const selectedLevelRequiredPoints = selectedLevel.requiredPoints || 0;
        const pointsDifference = Number(selectedLevelRequiredPoints) - Number(currentLevelPoints) || 0;
        this.adjustedPoints = pointsDifference;
      }
    }

    onInputCheckMemberLevel() {
      var _this$args12, _this$args12$contactR, _this$args12$contactR2, _this$args13, _this$args13$contactR, _this$args13$contactR2;

      const points = this.toSendPoints || 0;
      const currentLevelPoints = (this === null || this === void 0 ? void 0 : (_this$args12 = this.args) === null || _this$args12 === void 0 ? void 0 : (_this$args12$contactR = _this$args12.contactReward) === null || _this$args12$contactR === void 0 ? void 0 : (_this$args12$contactR2 = _this$args12$contactR.level) === null || _this$args12$contactR2 === void 0 ? void 0 : _this$args12$contactR2.points) || 0;
      const adjustedPoints = Number(currentLevelPoints) + Number(points) || 0;
      const memberLevels = (this === null || this === void 0 ? void 0 : (_this$args13 = this.args) === null || _this$args13 === void 0 ? void 0 : (_this$args13$contactR = _this$args13.contactReward) === null || _this$args13$contactR === void 0 ? void 0 : (_this$args13$contactR2 = _this$args13$contactR.rewardData) === null || _this$args13$contactR2 === void 0 ? void 0 : _this$args13$contactR2.memberLevels) || [];
      const selectedLevel = R.pipe(R.sortBy(R.prop('requiredPoints')), R.filter(memberLevel => {
        const requiredPoints = R.propOr(0, 'requiredPoints')(memberLevel);

        if (Number(requiredPoints) <= adjustedPoints) {
          return true;
        }
      }), R.last())(memberLevels) || {};
      this.adjustedLevel = selectedLevel.name;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isAdjustManually", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMemberLevel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "adjustedPoints", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "adjustedLevel", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toSendPoints", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isValidPoints", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expiryTime", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expiryPeriod", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.constants.timePeriods.days;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendRewardTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "sendRewardTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetAdjustMemberLevel", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetAdjustMemberLevel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSelectMemberLevel", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectMemberLevel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onInputCheckMemberLevel", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onInputCheckMemberLevel"), _class.prototype)), _class));
  _exports.default = ContatcsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent;
});