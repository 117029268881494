define("client/pods/components/channels/shop/items-limits-export-btn/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _nventor, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  let ChannelsShopItemsLimitsExportBtnComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, (_class = (_temp = class ChannelsShopItemsLimitsExportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "excel", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "export", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "fileName", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "exportData", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "deconstructSet", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "onlyHasActivity", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "today", _descriptor11, this);
      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
    }

    getDefaultFilename() {
      const name = R.path(['args', 'model', '_data', 'name'])(this) || '';
      return `${name}-${this.intl.t('limited qty')}-${this.today}`;
    }

    *fetchDataTask(onCloseDialogAction) {
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/limits/filters',
        defaultFiltersData: {
          master,
          count: 1000
        }
      });
      const exportData = yield this.crud.searchRecordsTask.perform({
        adapterName: 'channels/shop/campaigns/details/limits',
        appendPath: `/master/${master}`,
        filters
      });
      const specsForSummary = [{
        column: this.intl.t('product'),
        prop: '_data.item'
      }, {
        column: this.intl.t('product code'),
        prop: '_data.itemCode'
      }, {
        column: this.intl.t('sold qty'),
        color: '',
        prop: '_data.salesQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('limited sales qty'),
        color: '',
        prop: '_data.hasSalesLimitedQty',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limited qty'),
        color: '',
        prop: '_data.salesLimitedQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('increase/decrease limited qty'),
        color: '',
        prop: '',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('allow preOrder'),
        color: '',
        prop: '_data.allowPreOrder',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limited preOrder qty'),
        color: '',
        prop: '_data.hasSalesPreOrderLimitedQty',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limit preOrder qty'),
        color: '',
        prop: '_data.salesPreOrderLimitedQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('increase/decrease preOrder qty'),
        color: '',
        prop: '',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }];
      const worksheetData = R.map(row => {
        return R.map(spec => {
          let value;
          const path = spec.prop;

          if (!path) {
            value = '';
          }

          value = R_.dotPath(path)(row);

          if (spec.transform) {
            value = spec.transform(value, row, spec);
          }

          return value;
        })(specsForSummary);
      })(exportData);
      const headers = R.pluck('column')(specsForSummary);
      this.exportData = {
        headers,
        data: worksheetData
      };
    }

    *exportTask(onCloseDialogAction) {
      const exportData = this.exportData;
      const headers = exportData.headers || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.prepend(headers)(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fileName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "exportData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deconstructSet", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onlyHasActivity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "today", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopItemsLimitsExportBtnComponent;
});