define("client/pods/components/channels/shop/items-limits-import-btn/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct", "ramda-extension", "ramda", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, RA, R_, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  let ChannelsShopItemsLimitsImportBtnComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, (_class = (_temp = class ChannelsShopItemsLimitsImportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "excel", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "export", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "hasUnsuccessful", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "hasSuccessful", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "isImported", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "isPaidImported", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "isShowImportModal", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "sheets", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSheets", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "showImportPaidModal", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "successful", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "successfulImportData", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "templateFilename", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "importData", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "importedSheetHeaders", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "unsuccessful", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "unsuccessfulImportData", _descriptor22, this);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/import/limits/specs']);
      this.resetImportData();
    }

    get specsForImportDataHeaders() {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('headerValue'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }

    get specsForImportDataValues() {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('value'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }

    resetImportData() {
      this.importData = '';
      this.successful = [];
      this.importedSheetHeaders = [];
      this.hasUnsuccessful = false;
      this.unsuccessfulImportData = [];
      this.isImported = false;
    }

    zipHeadersWithRowData(headers, row) {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
      const importDataHeaders = R.pluck('value')(headers);
      const rowData = {};

      _nventor.default.mapIndexed((header, index) => {
        if (!R.isEmpty(header)) {
          var _spec$_data;

          let value = row[index];
          const spec = R.find(R.pathEq(['_data', 'value'], header))(specsForImportData);

          if (spec !== null && spec !== void 0 && (_spec$_data = spec._data) !== null && _spec$_data !== void 0 && _spec$_data.transform) {
            var _spec$_data2;

            value = (_spec$_data2 = spec._data) === null || _spec$_data2 === void 0 ? void 0 : _spec$_data2.transform(value, row, header);
          }

          rowData[header] = value;
        }
      })(importDataHeaders);

      return rowData;
    }

    getSheetData(sheetObj) {
      const sheetData = R.propOr([], 'data', sheetObj);
      const sheetHeaders = R.propOr([], 'headers', sheetObj);
      return R.pipe(R.map(row => {
        const rowData = this.zipHeadersWithRowData(sheetHeaders, row);
        return rowData;
      }))(sheetData);
    }

    *importDataTask() {
      const filteredWorkbook = this.filteredWorkbook;
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
      let sheetHeaders = [];
      const importData = R.pipe(R.mapObjIndexed(sheetObj => {
        if (RA.isNilOrEmpty(sheetHeaders)) {
          sheetHeaders = R.propOr([], 'headers', sheetObj);
        }

        return this.getSheetData(sheetObj);
      }), R.values, R.flatten)(filteredWorkbook);
      sheetHeaders = R.pipe(R.map(header => {
        const spec = R.find(R.pathEq(['_data', 'value'], header.value))(specsForImportData);

        if (spec) {
          var _spec$_data3;

          header.label = spec === null || spec === void 0 ? void 0 : (_spec$_data3 = spec._data) === null || _spec$_data3 === void 0 ? void 0 : _spec$_data3.label;
          return header;
        }

        return false;
      }), R.reject(R.equals(false)))(sheetHeaders);
      this.importedSheetHeaders = sheetHeaders;
      const path = 'api/protected/channels/shop/campaigns/details/limits/import';
      const payload = {
        batch: importData,
        master: this.args.model.getData('_key')
      };
      const specsForImportDataValues = this.specsForImportDataValues;

      try {
        const response = yield this.server.call('PATCH', path, payload);
        this.isImported = true;
        const unsuccessful = R.pipe(R.pathOr([], ['data', 'unsuccessful']), R.map(row => {
          row.cells = R.values(R.pick(specsForImportDataValues, row));
          return row;
        }), R.values)(response);

        if (unsuccessful.length > 0) {
          this.hasUnsuccessful = true;
          this.unsuccessfulImportData = unsuccessful;
        }

        const successful = R.pipe(R.pathOr([], ['data', 'successful']), R.map(resp => {
          const row = R.propOr({}, 'model')(resp);
          row.cells = R.values(R.pick(specsForImportDataValues, row));
          return row;
        }), R.values)(response);
        this.successful = successful;

        if (successful.length > 0) {
          this.hasSuccessful = true;
          this.successfulImportData = successful;
        }
      } catch (err) {
        this.isImported = false;
        this.crud.setValidationErrors('errors', err, this);
      }
    }

    get importParamValues() {
      const specs = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
      const values = R.pipe(R.map(R.path(['_data', 'value'])), R.reject(RA.isNilOrEmpty))(specs);
      return values;
    }

    get toDisplayImportData() {
      if (!this.isImported) {
        return this.importData || [];
      }

      return this.unsuccessfulImportData;
    }

    get successfulCount() {
      const data = this.successful || [];
      return data.length;
    }

    get unsuccessfulCount() {
      const data = this.unsuccessfulImportData || [];
      return data.length;
    }

    get sheetNames() {
      const workbook = this.workbook || {};
      const sheetNames = R.keys(workbook) || [];
      return R.map(sheetName => {
        return Ember.Object.create({
          value: sheetName,
          label: sheetName
        });
      })(sheetNames);
    }

    get workbook() {
      const sheets = this.sheets || {};
      const name = R.pathOr([], [0, 'specsForImportDataHeaders'])(this);
      return R.mapObjIndexed(sheetData => {
        const sheetObject = Ember.Object.create();
        sheetObject.set('headers', this.matchDataWithHeaders(sheetData));
        sheetData = R.reject(row => {
          if (R.includes(name, row)) {
            return true;
          }

          return false;
        })(sheetData);
        sheetObject.set('data', sheetData);
        return sheetObject;
      })(sheets);
    }

    get filteredWorkbook() {
      const sheets = this.workbook || {};
      const selectedSheets = this.selectedSheets || [];
      const allowedSheets = {};
      R.mapObjIndexed((sheetObject, sheetName) => {
        if (R.includes(sheetName, selectedSheets)) {
          allowedSheets[sheetName] = sheetObject;
          return sheetObject;
        }
      })(sheets);
      return allowedSheets;
    }

    matchDataWithHeaders(data) {
      if (data.length > 0) {
        const importDataHeaders = R.head(data);
        const allowedHeaders = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-limits-specs')(this) || [];
        return R.map(importDataHeader => {
          importDataHeader = R.trim(`${importDataHeader}`);
          const found = R.find(R.pathEq(['_data', 'headerValue'], importDataHeader))(allowedHeaders);
          const value = R.pathOr('', ['_data', 'value'], found);
          return Ember.Object.create({
            value
          });
        })(importDataHeaders);
      }

      return false;
    }

    showImportModal(sheets) {
      this.sheets = sheets;
      const sheetNames = this.sheetNames || [];
      const selectedSheets = R.map(sheetName => {
        return sheetName.value;
      }, sheetNames);
      this.selectedSheets = selectedSheets;
      this.isShowImportModal = true;
    }

    closeImportModal() {
      const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);

      if (this.isShowImportModal) {
        this.isShowImportModal = false;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasUnsuccessful", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasSuccessful", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isImported", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isPaidImported", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isShowImportModal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sheets", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSheets", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showImportPaidModal", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "successful", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "successfulImportData", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "templateFilename", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "importData", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "importedSheetHeaders", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unsuccessful", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unsuccessfulImportData", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resetImportData", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "resetImportData"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "zipHeadersWithRowData", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "zipHeadersWithRowData"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "importDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "importDataTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showImportModal", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "showImportModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeImportModal", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "closeImportModal"), _class.prototype)), _class));
  _exports.default = ChannelsShopItemsLimitsImportBtnComponent;
});