define("client/pods/admin/service", ["exports", "ramda", "ember-concurrency", "client/mixins/date"], function (_exports, R, _emberConcurrency, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_date.default, {
    dataManager: Ember.inject.service(),
    server: Ember.inject.service(),
    intl: Ember.inject.service(),
    connectAsAdminTask: (0, _emberConcurrency.task)(function* () {
      const model = yield this.dataManager.findRecord({
        adapterName: 'admin'
      });
      yield this.server.call('GET', 'api/protected/admin/connections');
      return model;
    })
  });

  _exports.default = _default;
});