define("client/pods/documentation/model", ["exports", "client/pods/base/model", "client/mixins/statuses"], function (_exports, _model, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_statuses.default, {
    defaults() {
      return {
        quickTip: '',
        status: this.get('constants.status.active')
      };
    },

    hasDocumentation: Ember.computed('_data._id', function () {
      return this.get('_data._id');
    }) // isAllowEdit: computed('storage.tokenData', function () {
    //   const userData = this.get('storage').get('tokenData')
    //   if (userData.isAdmin || userData.isEditor) {
    //     return true
    //   }
    //   return false
    // })

  });

  _exports.default = _default;
});