define("client/pods/components/documents/sales/invoices/print-btns/component", ["exports", "client/mixins/emails-dispatched", "client/mixins/crud"], function (_exports, _emailsDispatched, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _emailsDispatched.default, {
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    settings: Ember.inject.service(),
    tagName: '',
    showPaymentLinkBtn: Ember.computed('settings.settingsModel._data.creditCard_tapPay', 'model._data.{paymentMethodKey,isVoid}', function () {
      const isVoid = this.model._data.isVoid || '';

      if (isVoid) {
        return false;
      }

      const isTapPay = this.settings.getProp('creditCard_tapPay.enabled');

      if (isTapPay) {
        const paymentMethod = this.model._data.paymentMethodKey || '';

        if (paymentMethod === this.constants.paymentMethods.creditCard && !this.model._data.paymentCreditCardTransactionCode && this.model._data.externalUrl) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      goToPaymentPage(onHideDropdown) {
        const url = this.model.paymentLink;
        window.open(url, '_blank');
        onHideDropdown();
      }

    }
  });

  _exports.default = _default;
});