define("client/pods/components/guests/campaign-main/campaign-activity/component", ["exports", "ramda", "client/mixins/big", "client/mixins/doc-status", "ember-concurrency", "client/pods/documents/model", "client/utils/nventor"], function (_exports, R, _big, _docStatus, _emberConcurrency, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, _docStatus.default, {
    crud: Ember.inject.service(),
    guests: Ember.inject.service(),
    isSearching: false,

    didInsertElement() {
      this._super(); // this.set('filters', filters)


      this.fetchDocumentsTask.perform();
    },

    fetchDocumentsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: 'filters'
      });
      yield this._fetchDataTask.perform(filters);
    }),
    paginateSearchRecordsTask: (0, _emberConcurrency.task)(function* ({
      filters
    }) {
      yield this._fetchDataTask.perform(filters);
    }),
    _fetchDataTask: (0, _emberConcurrency.task)(function* (filters) {
      this.set('filters', filters);
      const data = filters.serialize();
      const response = yield this.guests.call('GET', 'api/guests/channels/shop/campaigns/activity', data);
      const results = R.pipe(R.propOr([], 'data'), R.map(result => {
        return _nventor.default.createModel(_model.default, result);
      }))(response);
      this.set('results', results);
    }),
    // searchCampaignTask: task(function * (query) {
    //   const filters = this.get('filters')
    //   if (query === '') {
    //     return yield this.get('fetchDocumentsTask').perform()
    //   }
    //   return yield this.get('searchInputTask').perform({
    //     adapterName: this.get('adapterName'),
    //     resultsToggle: '',
    //     resultsProperty: 'listByResults',
    //     filters
    //   }, query) || []
    // }),
    allActivity: Ember.computed('results.[]', 'filter', function () {
      const results = this.results || [];
      const filter = this.filter;

      if (!Ember.isEmpty(results)) {
        return R.filter(result => {
          if (filter) {
            const detailValues = R.pipe(R.propOr([], 'details'), R.pluck(['_data']), R.values, R.pluck(['item']))(result._data);
            const values = R.pipe(R.pick(['telephone', 'contact', 'transporterContactPerson', 'waybill', 'address', 'docNo']), R.values, R.concat(detailValues))(result._data);
            const matches = R.filter(value => {
              if (value.indexOf(filter) > -1) {
                return true;
              }

              return false;
            })(values);

            if (matches.length > 0) {
              return true;
            }

            return false;
          }

          return true;
        })(results);
      }

      return [];
    }),
    total: Ember.computed('allActivity.[]', function () {
      const isVoid = this.get('constants.documentsStatus.void');
      const allActivity = this.allActivity || [];
      return R.pipe(R.pluck('_data'), R.values, R.reject(doc => doc.status === isVoid), this.sumPropAndAutoRound(0, 'inclTotal'))(allActivity);
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.fetchDocumentsTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});