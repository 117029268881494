define("client/pods/components/modals/modal-dialog/modal-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SD6HwwvY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"u-full-width u-no-print\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"tt\",[[24,[\"title\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[24,[\"onClose\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"delete is-small is-pulled-right\"],[3,\"action\",[[23,0,[]],[24,[\"onClose\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-dialog/modal-header/template.hbs"
    }
  });

  _exports.default = _default;
});