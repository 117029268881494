define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "atQtf5nO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-cell\",null,[[\"label\",\"errors\",\"context\"],[\"item\",[24,[\"errors\"]],\"item\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"lists/list-searcher\",null,[[\"allowCustom\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"selectedItemValueKey\",\"value\",\"hasSelectedKey\",\"hasSelected\",\"hasSelectedIcon\",\"readonly\",\"disabled\",\"isRunning\",\"onSearch\",\"onSelect\",\"onDeselect\"],[false,[24,[\"productsList\"]],\"_data._key\",\"_data.name\",\"_data.name\",[24,[\"automationModel\",\"_data\",\"item\"]],\"_data._key\",[24,[\"automationModel\",\"_data\",\"itemKey\"]],\"fas fa-bookmark\",[24,[\"isReadonly\"]],[24,[\"isReadonly\"]],[24,[\"searchRecordsTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"searchRecordsTask\"]],[28,\"hash\",null,[[\"adapterName\",\"query\",\"resultsProperty\"],[\"products\",[24,[\"automationModel\",\"_data\",\"item\"]],\"productsList\"]]]],null],[24,[\"onLoadItem\"]],[24,[\"onResetItem\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"forms/form-cell\",null,[[\"label\",\"errors\",\"context\"],[\"criteria\",[24,[\"errors\"]],\"criteria\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"readonly\",\"disabled\",\"translate\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\"],[true,true,[24,[\"isReadonly\"]],true,[23,0,[\"crud\",\"lists\",\"automations-criterias\"]],\"_data.value\",\"_data.label\",[24,[\"automationModel\",\"_data\",\"criteria\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"forms/form-cell\",null,[[\"label\",\"errors\",\"context\"],[\"value\",[24,[\"errors\"]],\"value\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"readonly\",\"value\"],[true,[24,[\"isReadonly\"]],[24,[\"automationModel\",\"_data\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-value/template.hbs"
    }
  });

  _exports.default = _default;
});