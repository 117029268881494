define("client/pods/components/websites/panels-display/sub-panels-pages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ytUq3Q8",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showWebsitePagesNav\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"panel-grid__sidebar\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"sidebar-container\"],[8],[0,\"\\n        \"],[1,[28,\"websites/panels-display/sub-panels-pages/nav-menu-nested\",null,[[\"menu\",\"pagesCollection\",\"classNames\",\"isExpanded\",\"labelKey\",\"itemHasContentKey\",\"translate\",\"isViewable\",\"website\",\"tab\",\"hasSettingsModal\",\"onSelect\",\"onDrop\"],[[24,[\"pagesCollection\",\"menu\"]],[24,[\"pagesCollection\"]],\"pages-nav\",true,\"pageName\",\"_data.hasTemplate\",false,true,[24,[\"model\"]],[24,[\"tab\"]],true,[28,\"action\",[[23,0,[]],\"displaySubPanel\"],null],[28,\"action\",[[23,0,[]],\"reorderPages\"],null]]]],false],[0,\"\\n\"],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"showWebsitePagesNav\",\"pagesCollection\",\"model\",\"website\",\"hasWebBuilder\",\"onNew\",\"onDisplay\",\"onAfterRemove\",\"onEdit\",\"toggleShowWebsitePagesNav\"],[[23,1,[]],[24,[\"showWebsitePagesNav\"]],[24,[\"pagesCollection\"]],[23,1,[\"model\"]],[24,[\"model\"]],true,[28,\"action\",[[23,0,[]],\"newPage\"],null],[28,\"action\",[[23,0,[]],\"displaySubPanel\"],null],[28,\"action\",[[23,0,[]],\"afterRemovePage\"],null],[28,\"action\",[[23,0,[]],\"editPage\",[23,1,[\"model\"]]],null],[28,\"action\",[[23,0,[]],\"toggleShowWebsitePagesNav\"],null]]]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/template.hbs"
    }
  });

  _exports.default = _default;
});