define("client/pods/workflows/items/activities/collection/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data = {}) {
      data.collection = this._transformCollection(data);

      this._super(...arguments);
    },

    _transformCollection(data) {
      return R.pipe(R.pathOr([], ['collection']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/items/activities',
          data
        });
      }))(data);
    },

    byStatus: Ember.computed('synced', '_data.{collection.@each.synced}', function () {
      var _this$_data;

      const collection = ((_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.collection) || [];
      const statuses = ['isPinned', 'isIncomplete', 'isComplete'];

      const groupingFn = activity => {
        var _activity$_data, _activity$_data2, _activity$_data3;

        if ((_activity$_data = activity._data) !== null && _activity$_data !== void 0 && _activity$_data.isPinned) {
          return 'isPinned';
        }

        if (((_activity$_data2 = activity._data) === null || _activity$_data2 === void 0 ? void 0 : _activity$_data2.type) === 'notes') {
          return 'isComplete';
        }

        if ((_activity$_data3 = activity._data) !== null && _activity$_data3 !== void 0 && _activity$_data3.isComplete) {
          return 'isComplete';
        }

        return 'isIncomplete';
      };

      const byStatus = R.groupBy(groupingFn)(collection);
      return R.pipe(R.map(status => {
        const activities = R.prop(status)(byStatus);

        if (activities) {
          return {
            status,
            activities
          };
        }

        return [];
      }), R.reject(RA.isNilOrEmpty))(statuses);
    })
  });

  _exports.default = _default;
});