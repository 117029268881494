define("client/pods/components/products/price-selectors/price-selector-campaigns/component", ["exports", "ramda", "ramda-extension", "ember-concurrency"], function (_exports, R, R_, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    isReadonly: false,
    fetchHistoryOnInitTask: (0, _emberConcurrency.task)(function* () {
      const isSet = R_.dotPath('_data.isSet', (this === null || this === void 0 ? void 0 : this.item) || {}) || false;
      const isSetWithChoices = R_.dotPath('_data.isSetWithChoices', (this === null || this === void 0 ? void 0 : this.item) || {}) || false;
      const product = this.product;
      const itemKey = this.itemKey;
      const onFetchHistoryTask = this.onFetchHistoryTask;
      const {
        priceHistoryData
      } = yield onFetchHistoryTask(product, itemKey, isSet, isSetWithChoices);
      this.set('priceHistoryData', priceHistoryData);
    }),
    sortedData: Ember.computed('priceHistoryData.[]', function () {
      // const campaignDetailKey = this?.campaignDetailKey || ''
      const forCampaigns = this.productsService.getFor('campaigns', this.priceHistoryData); // remove itself so it wouldn't show as history price
      // forCampaigns = R.reject(campaign => R.propEq('_key', campaignDetailKey, campaign), forCampaigns)
      // forCampaigns = R.reject(campaign => R.propEq('campaignDetailKey', campaignDetailKey, campaign), forCampaigns)

      const forDocuments = this.productsService.getFor('documents', this.priceHistoryData);
      return R.concat(forCampaigns, forDocuments);
    }),
    isDisabled: Ember.computed('isReadonly', 'itemKey', function () {
      if (!this.itemKey) {
        return true;
      }

      return this.isReadonly;
    }),
    actions: {
      select(history, onHideDropdown) {
        this.onSelect(history, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});