define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/numbers/row/blanks/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  // import * as R from 'ramda'
  let ExtensionsAppsTwEInvoicesTracksManagerNumbersRowBlanksComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ExtensionsAppsTwEInvoicesTracksManagerNumbersRowBlanksComponent extends _component.default {
    addBlankNumberSet() {
      this.args.model.addBlankNumberSet();
    }

    checkBlanks(isFocusOut) {
      const model = this.args.model;
      model.checkBlanksAllowed(isFocusOut);
    }

    removeBlankNumberSet(blank) {
      const model = this.args.model;
      model.removeBlankNumberSet(blank);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "addBlankNumberSet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addBlankNumberSet"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "checkBlanks", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "checkBlanks"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeBlankNumberSet", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeBlankNumberSet"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerNumbersRowBlanksComponent;
});