define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/bulk-discount-schedules-dialog/bulk-discount-schedule-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogBulkDiscountScheduleRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogBulkDiscountScheduleRowComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
    }

    checkDateConflict() {
      const bulkDiscountSchedules = this.args.model.getData('bulkDiscountSchedules') || [];
      const hasDateConflict = R.pipe(R.filter(R.propEq('isActive', true)), R.length, R.gt(R.__, 1))(bulkDiscountSchedules);

      if (hasDateConflict) {
        R.pipe(R.filter(R.propEq('isActive', true)), R.forEach(schedule => {
          schedule.set('hasDateConflict', true);
        }))(bulkDiscountSchedules);
        this.args.model.set('hasBulkDiscountScheduleDateConflict', true);
      } else {
        R.forEach(schedule => {
          schedule.set('hasDateConflict', false);
        })(bulkDiscountSchedules);
        this.args.model.set('hasBulkDiscountScheduleDateConflict', false);
      }
    }

    checkEmptyBulkDiscountId() {
      const bulkDiscountSchedules = this.args.model.getData('bulkDiscountSchedules') || [];
      const hasEmptyBulkDiscountId = R.pipe(R.filter(R.anyPass([R.pathEq(['_data', 'bulkDiscountId'], ''), R.pathEq(['_data', 'bulkDiscountId'], null)])), RA.isNotNilOrEmpty)(bulkDiscountSchedules);

      if (hasEmptyBulkDiscountId) {
        this.args.model.set('hasEmptyBulkDiscountId', true);
      } else {
        this.args.model.set('hasEmptyBulkDiscountId', false);
      }
    }

    onSubmitRemoveBulkDiscountSchedule(bulkDiscountSchedule) {
      const bulkDiscountSchedules = this.args.model.getData('bulkDiscountSchedules');
      bulkDiscountSchedules.removeObject(bulkDiscountSchedule);
      this.checkDateConflict();
      this.checkEmptyBulkDiscountId();
    }

    onChangeSetActivation() {
      this.checkDateConflict();
      this.checkEmptyBulkDiscountId();
    }

    onChangeBulkDiscount(selected) {
      const hasSchedule = selected.getData('hasSchedule') || false;
      const bulkDiscountId = this.args.schedule.getData('bulkDiscountId');

      if (bulkDiscountId && hasSchedule) {
        const selectedDateStartZ = selected.getData('dateStartZ') || '';
        const selectedDateEndZ = selected.getData('dateEndZ') || '';
        this.args.schedule.setData('dateStartZ', selectedDateStartZ);
        this.args.schedule.set('dateStart', this.date.formatDate(selectedDateStartZ, 'YYYY-MM-DD'));
        this.args.schedule.setData('dateEndZ', selectedDateEndZ);
        this.args.schedule.set('dateEnd', this.date.formatDate(selectedDateEndZ, 'YYYY-MM-DD'));
      }

      if (!bulkDiscountId) {
        this.args.schedule.setData('dateStartZ', '');
        this.args.schedule.set('dateStart', '');
        this.args.schedule.setData('dateEndZ', '');
        this.args.schedule.set('dateEnd', '');
      }

      this.onChangeSetActivation();
      this.checkEmptyBulkDiscountId();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveBulkDiscountSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveBulkDiscountSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChangeSetActivation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChangeBulkDiscount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBulkDiscount"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogBulkDiscountScheduleRowComponent;
});