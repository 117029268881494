define("client/helpers/compare", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_date.default, {
    compute(params
    /* ,  hash */
    ) {
      //@TODO: clean up and test
      if (params[3]) {
        //handle case insensitive conditions if 4 param is passed.
        params[0] = params[0].toLowerCase();
        params[2] = params[2].toLowerCase();
      }

      let v1 = params[0];
      let operator = params[1];
      let v2 = params[2];

      switch (operator) {
        case '==':
          return v1 == v2;

        case '!=':
          return v1 != v2;

        case '===':
          return v1 === v2;

        case '<':
          return v1 < v2;

        case '<=':
          return v1 <= v2;

        case '>':
          return v1 > v2;

        case '>=':
          return v1 >= v2;

        case '&&':
          return !!(v1 && v2);

        case '||':
          return !!(v1 || v2);

        default:
          return false;
      }
    }

  });

  _exports.default = _default;
});