define("client/pods/shortcuts/service", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/utils/nventor"], function (_exports, _applyDecoratedDescriptor2, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  var _default = Ember.Service.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    keysPressed: {},
    alreadyTriggered: false,

    _hasAnyMatch(shortcutKeysArray, keysPressedArray) {
      return R.reduce((acc, shortcutKeys) => {
        if (acc) {
          return acc;
        }

        const intersection = R.intersection(shortcutKeys)(keysPressedArray);

        if (intersection.length === shortcutKeys.length) {
          acc = true;
        }

        return acc;
      }, false)(shortcutKeysArray);
    },

    matchByKeysPressed(shortcutsMap) {
      const keysPressedArray = R.keys(this.keysPressed || {});
      const matched = R.find(shortcutObj => {
        let shortcutKeys = shortcutObj.keys;

        if (!R.is(Array, shortcutKeys[0])) {
          shortcutKeys = [shortcutKeys];
        } // shortcutKeys should always be an array of array.
        // if a single array, make it an array of array.
        // this allows for 1 handler to have many shortcuts
        // shortcutKeys = [
        //   ['key1', 'key2'], // option1
        //   ['key1', 'key3'], // option2
        // ]


        return this._hasAnyMatch(shortcutKeys, keysPressedArray);
      })(shortcutsMap);
      return matched;
    },

    onKeyDown(shortcutsMap, e) {
      this.keysPressed[e.key] = true; // shortcuts are onKeyDown to appear more instant

      if (!this.alreadyTriggered) {
        const matched = this.matchByKeysPressed(shortcutsMap, this.keysPressed);

        if (matched) {
          var _matched$handler;

          e.preventDefault();
          this.set('alreadyTriggered', true);
          this.onKeyUp(false, e);

          if (matched !== null && matched !== void 0 && (_matched$handler = matched.handler) !== null && _matched$handler !== void 0 && _matched$handler.perform) {
            return matched.handler.perform();
          }

          return matched.handler();
        }
      }
    },

    onKeyUp(handler, e) {
      if (e.key === 'Meta') {
        // problem with meta. it will only always trigger one key up event, even when many keys were pressed
        this.keysPressed = {};
      } else {
        delete this.keysPressed[e.key];
      }

      if (handler) {
        return handler();
      }

      this.set('alreadyTriggered', false);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "matchByKeysPressed", [_dec], Object.getOwnPropertyDescriptor(_obj, "matchByKeysPressed"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "onKeyDown", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onKeyDown"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "onKeyUp", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onKeyUp"), _obj)), _obj)));

  _exports.default = _default;
});