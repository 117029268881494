define("client/pods/users/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  let UsersService = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class UsersService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "storage", _descriptor, this);
    }

    getProp(prop) {
      const userData = this.storage.get('tokenData') || {};
      return userData[prop] || '';
    }

    findPersonByKey(users, person) {
      var _person$_data;

      const personKey = ((_person$_data = person._data) === null || _person$_data === void 0 ? void 0 : _person$_data._key) || person._key;
      return _ramda.default.find(_ramda.default.pathEq(['_data', '_key'], personKey))(users);
    }

    assign(model, propPath, person) {
      const users = model.get(propPath) || [];
      const found = this.findPersonByKey(users, person);

      if (!found) {
        users.pushObject(person);
        model.set(propPath, users);
      } else {
        this.unassign(model, propPath, found);
      }
    }

    unassign(model, propPath, person) {
      const users = model.get(propPath) || [];
      const toRemove = this.findPersonByKey(users, person);
      users.removeObject(toRemove);
      model.set(propPath, users);
    }

    _createModel(data) {
      data._id = `members/${data._key}`;
      return this.dataManager.setAsRecord({
        adapterName: 'users',
        data: data
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "storage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getProp", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getProp"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "findPersonByKey", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "findPersonByKey"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "assign", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unassign", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype)), _class));
  _exports.default = UsersService;
});