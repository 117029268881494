define("client/pods/components/documents/panel-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IiUJtxsc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/editor-main\",null,[[\"tab\",\"model\",\"isEditing\",\"onEdit\",\"onDash\",\"onSetupDirty\",\"onUpdateDocInfoTask\"],[[24,[\"tab\"]],[24,[\"model\"]],false,[28,\"action\",[[23,0,[]],[24,[\"onEdit\"]],[24,[\"model\"]]],null],[24,[\"onDash\"]],[24,[\"onSetupDirty\"]],[24,[\"onUpdateDocInfoTask\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-display/template.hbs"
    }
  });

  _exports.default = _default;
});