define("client/pods/components/comments/comments-manager/component", ["exports", "ramda", "ramda-extension", "client/mixins/date", "client/mixins/crud", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, _date, _crud, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    commentsService: Ember.inject.service('comments'),
    tagName: 'span',
    classNames: ['comments__display-container'],
    tooltipLabel: 'comments',
    label: '',
    adapterName: 'comments',
    showEditor: false,
    isEditing: false,

    init() {
      this._super(...arguments);

      this.set('isEditing', false);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      this.setupNewCommentRecord();
      const master = this.master;

      if (master) {
        // scheduleOnce('afterRender', this, () => {
        this.fetchCommentsTask.perform(master); // })
      }
    },

    setupNewCommentRecord() {
      if (!this.isDestroyed) {
        const master = this.master;
        const tokenData = this.storage.get('tokenData');
        const data = {
          username: tokenData.username,
          master: master
        };
        const model = this.setupNewRecord({
          data
        });
        this.set('newModel', model);
      }
    },

    comments: Ember.computed('commentsCollection._data.comments.[]', 'commentsCollection.synced', function () {
      return this.get('commentsCollection._data.comments') || [];
    }),
    commentsCount: Ember.computed('comments.[]', 'commentsCollection.synced', function () {
      const comments = this.comments || [];
      return comments.length;
    }),
    // comments: computed('related.[]', function () {
    //   console.log('========fetching comments=====for related')
    //   // console.log(this.get('master'))
    //   console.log(this.get('related'))
    //   // const master = this.get('master')
    //   // if (master) {
    //   return this.get('fetchCommentsTask').perform()
    //   // }
    //   // return []
    // }),
    fetchCommentsTask: (0, _emberConcurrency.task)(function* (master) {
      let data = {};

      if (master) {
        data.master = master;
      }

      const metaData = this.metaData;

      if (metaData) {
        data = R.mergeRight(data, metaData);
      }

      data.related = this.related || [];
      this.commentsService.qFetch(data, (batchCommentsCollection = []) => {
        if (!this.isDestroyed) {
          if (!R.is(Array, batchCommentsCollection)) {
            return false;
          }

          const commentsCollection = R.find(R_.dotPathEq('_data.master', master))(batchCommentsCollection) || [];
          this.set('commentsCollection', commentsCollection);
        }
      });
    }),
    createCommentTask: (0, _emberConcurrency.task)(function* (onUpdateContent) {
      try {
        const nowZ = this.getNowZ();
        const type = this.type;
        const subType = this.subType;
        const model = this.newModel; // const message = model.getData('message.string')
        // model.setData('message', message)

        if (!model.getData('message')) {
          return false;
        }

        model.setData('dateZ', nowZ);
        model.setData('type', type);

        if (subType) {
          model.setData('subType', subType);
        }

        const metaData = this.metaData;

        if (metaData) {
          Object.keys(metaData).map(key => {
            const value = metaData[key];
            model.setData(key, value);
          });
        }

        if (this.onBeforeCreateComment) {
          this.onBeforeCreateComment(model);
        }

        yield this.createRecordTask.perform({
          model
        });
        this.setupNewCommentRecord();

        if (onUpdateContent) {
          onUpdateContent();
        }
      } catch (err) {
        this.set('errors', err.data);

        _nventor.default.throw('createCommentTask failed', err);
      }
    }).drop(),

    afterRemoveRecordTask(model) {
      const dirty = this.dirty;
      const comments = this.comments;
      comments.removeObject(model);

      if (dirty && model) {
        if (dirty.getData('_id') === model.getData('_id')) {
          this.cancelEdit();
        }
      }
    },

    willDestroyElement() {
      this.cancelEdit();
    },

    cancelEdit() {
      this.set('dirty', '');
      this.set('isEditing', false);
    },

    actions: {
      toggleEditor() {
        this.toggleProperty('showEditor');
      },

      edit(model) {
        const dirty = this.setupDirty({
          adapterName: this.adapterName,
          model
        });
        this.set('dirty', dirty);
        this.set('isEditing', true);
      },

      cancelEdit() {
        this.cancelEdit();
      }

    }
  });

  _exports.default = _default;
});