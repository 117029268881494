define("client/pods/components/blogs/tab-container/component", ["exports", "client/mixins/crud", "ember-concurrency", "client/mixins/blogs-list-by", "client/mixins/sortable-actions"], function (_exports, _crud, _emberConcurrency, _blogsListBy, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _blogsListBy.default, _sortableActions.default, {
    crud: Ember.inject.service(),
    results: null,
    resultsToggle: false,
    adapterName: 'blogs',
    filtersAdapterName: 'blogs/filters',
    blogs: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['blogs/lists/menu', 'blogs/lists/tags', 'statuses']);
    },

    afterLoadLists() {
      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          master: this.master
        }
      });
      this.set('filters', filters);
      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
        this.dash(results);
      }
    }),
    fetchBlogsTask: (0, _emberConcurrency.task)(function* () {
      const blogs = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this.filters
      });
      this.set('blogs', blogs);
    }),

    refreshDash() {
      this.dash();
    },

    dash(results) {
      this.set('showResults', false);

      if (!results) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
      }

      const tab = this.tab;
      const subTabOptions = {
        component: 'blogs/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    display(model, additionalOptions = {}) {
      this.set('showResults', false);
      const panelOptions = {
        component: 'blogs/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    },

    new(subTabOptions) {
      this.set('resultsToggle', false); // create tab within product tab

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      dash() {
        this.set('resultsToggle', false); // should deselect results if any..
        // this.deselectResult();

        this.dash();
      },

      display(model, options) {
        this.set('resultsToggle', false);

        if (model) {
          this.display(model, options);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(subTabOptions) {
        this.new(subTabOptions);
      },

      edit(model, additionalOptions) {
        this.set('resultsToggle', false);
        const tab = this.tab;
        const panelOptions = {
          component: 'blogs/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
      },

      import(subTabOptions) {
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }

    }
  });

  _exports.default = _default;
});