define("client/mixins/transporter-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    transporterAccounts: Ember.computed('transporter', function () {
      return this.get('transporter._data.transporterAccounts') || [];
    }),
    defaultTransporterAccount: Ember.computed('transporterAccounts.[]', function () {
      const defaultAccount = this.transporterAccounts.filterBy('_data.isDefault', true).get('firstObject');

      if (!defaultAccount) {
        return this.transporterAccounts.get('firstObject');
      }

      return defaultAccount;
    }),
    defaultTransporterAccountNumber: Ember.computed('defaultTransporterAccount', function () {
      return this.get('defaultTransporterAccount._data.account') || '';
    })
  });

  _exports.default = _default;
});