define("client/constants/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const constants = {
    status: {
      inactive: 'inactive',
      suspended: 'suspended',
      active: 'active'
    },
    productTracking: {
      on: 'on',
      off: 'off'
    },
    productTypes: {
      inventory: 'inventory',
      service: 'service'
    },
    contactsRelationship: {
      customer: 'customer',
      supplier: 'supplier'
    },
    dispatchStatus: {
      undispatched: 'undispatched',
      onHold: 'onHold',
      dispatched: 'dispatched'
    },
    productsStatus: {
      active: 'active',
      inactive: 'inactive',
      preOrder: 'preOrder',
      outOfStock: 'outOfStock',
      // @deprecated no longer used
      soldOut: 'soldOut',
      draft: 'draft'
    },
    documentsStatus: {
      draft: 'draft',
      final: 'final',
      void: 'void'
    },
    paymentCreditCardStatus: {
      approved: 'approved',
      declined: 'declined',
      refundRequested: 'refundRequested',
      refunded: 'refunded',
      paidOut: 'paidOut',
      pending: 'pending'
    },
    paymentVirtualAccountStatus: {
      approved: 'approved',
      pending: 'pending',
      expired: 'expired',
      attentionRequired: 'attentionRequired'
    },
    banksStatus: {
      inactive: 'inactive',
      suspended: 'suspended',
      active: 'active'
    },
    pendingStatus: {
      new: 'new',
      pending: 'pending',
      complete: 'complete'
    },
    campaignStatus: {
      online: 'online',
      offline: 'offline'
    },
    campaignProductsImageRatio: {
      oneByOne: 'oneByOne',
      fourByThree: 'fourByThree'
    },
    dispatchProcess: {
      unprepared: 'unprepared',
      undispatched: 'undispatched',
      awaitingPayment: 'awaitingPayment',
      outOfStock: 'outOfStock',
      preparing: 'preparing',
      dispatched: 'dispatched'
    },
    discountTypes: {
      rate: 'rate',
      amount: 'amount'
    },
    discountMethods: {
      onTotal: 'onTotal',
      perLine: 'perLine'
    },
    eInvoicesStatus: {
      queued: 'queued',
      // processing: 'processing',
      unsuccessful: 'unsuccessful',
      successful: 'successful',
      cancelled: 'cancelled',
      pending: 'pending',
      attentionRequired: 'attentionRequired',
      // will no longer retry
      // willRetry: 'willRetry',
      pendingCancellation: 'pendingCancellation' // failed: 'failed'

    },
    eInvoicesUploadStatus: {
      notIssued: 'notIssued',
      issued: 'issued',
      uploaded: 'uploaded',
      uploadedConfirmed: 'uploadedConfirmed'
    },
    schedulesStatus: {
      inactive: 'inactive',
      cancelled: 'cancelled',
      pending: 'pending',
      complete: 'complete'
    },
    taxMethods: {
      auto: 'auto',
      none: 'none',
      exclusive: 'exclusive',
      inclusive: 'inclusive'
    },
    taxes: {
      auto: 'auto',
      zero: 'zero'
    },
    paymentMethods: {
      none: 'none',
      cash: 'cash',
      cashBeforeDelivery: 'cashBeforeDelivery',
      creditCard: 'creditCard',
      account: 'account',
      consignment: 'consignment',
      free: 'free'
    },
    paymentTermsFrom: {
      invoiceMonth: 'invoiceMonth',
      invoiceDate: 'invoiceDate'
    },
    paymentTermsEnd: {
      exactDate: 'exactDate',
      monthEnd: 'monthEnd'
    },
    deliveryDateOptions: {
      single: 'single',
      multiple: 'multiple'
    },
    invoicing: {
      auto: 'auto',
      on: 'on',
      off: 'off'
    },
    ledgers: {
      active: 'active',
      inactive: 'inactive'
    },
    isTransporter: {
      on: 'on',
      off: 'off'
    },
    togglesAuto: {
      auto: 'auto',
      on: 'on',
      off: 'off'
    },
    togglesOnOff: {
      on: 'on',
      off: 'off'
    },
    deliveryDateSettings: {
      fromOrderDate: 'fromOrderDate',
      fromSetDate: 'fromSetDate'
    },
    shippingFeeType: {
      zones: 'zones',
      flat: 'flat'
    },
    shippingFeeZoneType: {
      single: 'single',
      multiple: 'multiple'
    },
    userTypes: {
      employee: 'employee',
      external: 'external'
    },
    commissionMethod: {
      baseCost: 'baseCost',
      flat: 'flat',
      percentagePrice: 'percentagePrice'
    },
    automationsWhen: {
      orderValue: 'orderValue',
      itemQty: 'itemQty',
      itemValue: 'itemValue'
    },
    automationsWhenItemQtyType: {
      products: 'products',
      tags: 'tags',
      all: 'all'
    },
    automationsCriterias: {
      greaterThan: 'greaterThan',
      greaterThanOrEqualTo: 'greaterThanOrEqualTo',
      isBetween: 'isBetween',
      lessThan: 'lessThan',
      lessThanOrEqualTo: 'lessThanOrEqualTo',
      equals: 'equals',
      notEquals: 'notEquals'
    },
    automationsThenActions: {
      mustAdd: 'mustAdd',
      mayAdd: 'mayAdd'
    },
    automationsThenSelectionTypes: {
      all: 'all',
      between: 'between'
    },
    transporterExtensions: {
      hct: 'hct',
      kerry: 'kerry'
    },
    websitePageTypes: {
      page: 'page',
      link: 'link'
    },
    websitePageLinkTypes: {
      url: 'url',
      product: 'product'
    },
    detailLimitTypes: {
      inventory: 'inventory',
      campaign: 'campaign'
    },
    timePeriods: {
      days: 'days',
      weeks: 'weeks',
      months: 'months',
      years: 'years'
    },
    rewardEvents: {
      birthday: 'birthday',
      order: 'order',
      signup: 'signup',
      promotion: 'promotion',
      opening: 'opening',
      adjustment: 'adjustment'
    },
    rewardEarningTypes: {
      rate: 'rate',
      amount: 'amount'
    },
    rewardOrderTypes: {
      total: 'total',
      quantity: 'quantity'
    },
    rewardMaxDeductionType: {
      rate: 'rate',
      amount: 'amount'
    },
    rewardMethod: {
      earn: 'earn',
      use: 'use'
    },
    rewardPointStatus: {
      valid: 'valid',
      pending: 'pending'
    },
    workflowsItemsStatuses: {
      open: 'open',
      successful: 'successful',
      unsuccessful: 'unsuccessful'
    },
    workflowsItemsActivitiesTypes: {
      activity: 'activity',
      call: 'call',
      email: 'email',
      event: 'event',
      meeting: 'meeting',
      notes: 'notes'
    }
  };
  var _default = constants;
  _exports.default = _default;
});