define("client/pods/components/channels/shop/campaigns/panels-new/component", ["exports", "client/mixins/crud", "client/utils/nventor"], function (_exports, _crud, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'channels/shop/campaigns',
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      // set default campaign settings
      const model = this.setupNewRecord();
      this.set('model', model); // const shopPaymentMethods = this.get('settings').getProp('shopPaymentMethods')
      // const shopGroupByTags = this.get('settings').getProp('shopGroupByTags')
      // const shopAllowShipping = this.get('settings').getProp('shopAllowShipping')
      // const shopAllowPickup = this.get('settings').getProp('shopAllowPickup')
      // const shopPaymentMethodsDefault = this.get('settings').getProp('shopPaymentMethodsDefault')
      // const cashShopPaymentMethodFee = this.get('settings').getProp('cashShopPaymentMethodFee')
      // const shopCampaignExpiredTemplate = this.get('settings').getProp('shopCampaignExpiredTemplate')
      // const shopOverSalesLimitedQtyStatus = this.get('settings').getProp('shopOverSalesLimitedQtyStatus')
      // const facebookPage = this.get('settings').getProp('facebookDefaultPage')
      // model.setData('paymentMethods', shopPaymentMethods)
      // model.setData('paymentMethodsDefault', shopPaymentMethodsDefault)
      // model.setData('cashPaymentMethodFee', cashShopPaymentMethodFee)
      // model.setData('expiredTemplate', shopCampaignExpiredTemplate)
      // model.setData('groupByTags', shopGroupByTags)
      // model.setData('allowShipping', shopAllowShipping)
      // model.setData('allowPickup', shopAllowPickup)
      // model.setData('overSalesLimitedQtyStatus', shopOverSalesLimitedQtyStatus)
      // model.setData('facebookPage', facebookPage)
      // model.setData('productsImagesRatio', this.get('settings').getProp('shopProductsImagesRatio'))
      // model.setData('productsOverviewStyle', this.get('settings').getProp('shopProductsOverviewStyle'))
      // const password = nventor.random.alphaNum(8).toLowerCase()
      // model.setData('password', password)

      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },

    actions: {
      cancel() {
        return this.onDash();
      }

    }
  });

  _exports.default = _default;
});