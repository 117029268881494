define("client/pods/documents/mixins/details", ["exports", "ramda", "client/utils/nventor", "client/pods/documents/details/model", "client/mixins/sortable-details"], function (_exports, R, _nventor, _model, _sortableDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_sortableDetails.default, {
    newBlankDetail({
      taxesList
    }) {
      const details = this.getOrSetData('details', []);
      const lastDetail = details.get('lastObject');
      let mustAddDetail = true;

      if (lastDetail) {
        if (!lastDetail.getData('item') && !lastDetail.getData('itemCode')) {
          mustAddDetail = false;
        }
      }

      if (mustAddDetail) {
        const taxRate = this.settings.getDefaultTaxRate(taxesList);
        const newData = {
          discountType: this.get('constants.discountTypes.rate'),
          taxRate,
          roundingCurrency: this.getData('roundingCurrency'),
          unroundedCurrency: this.getData('unroundedCurrency'),
          roundingInventoryQty: this.settings.getProp('roundingInventoryQty'),
          roundingInventoryPrice: this.settings.getProp('roundingInventoryPrice')
        };
        const docName = this.docName;

        if (docName === 'orders' || docName === 'quotes') {
          newData.deliveryDateZ = this.getNextDeliveryDateZ();
        }

        return this.addDetail(newData);
      }

      return false;
    },

    /**
     * creates new detail row
     * @param {object} data (optional) - detail data
     * @return {object} detail object
     */
    addDetail(data) {
      data = data || {}; // create details array if it doesnt exist

      let details = this.getData('details');

      if (!details) {
        details = [];
        this.setData('details', details);
      } // only add index if not exists


      if (data.index == null) {
        let index = details.length + 1;
        data.index = index;
      }

      const detailModel = this.dataManager.setAsRecord({
        adapterName: 'documents/details',
        data: data
      });
      detailModel.set('requiredAttrs', this.detailsRequiredAttrs);
      this.getData('details').pushObject(detailModel);
      this.resetIndexes();
      return detailModel;
    },

    /**
     * remove current detail
     * @param  {object} detail
     */
    removeDetail(detail) {
      const details = this.getData('details'); // remove detail

      if (detail.getData('isSet')) {
        const master = detail.getData('itemKey');
        const index = detail.getData('index'); // const childIndex = detail.getData('childIndex')

        const removeChildren = R.pipe(R.filter(child => {
          if (child.getData('isSetChildOf') === master && child.getData('index') === index) {
            return true;
          }

          return false;
        }), R.forEach(child => {
          details.removeObject(child);
        }));
        removeChildren(details);
      }

      details.removeObject(detail);
      this.resetIndexes(details);
      return details;
    },

    removeAllDetails() {
      this.setData('details', []);
      this.resetSortOrder();
    },

    /**
     * resets detail line to blank state
     */
    resetDetail(detail) {
      // @TODO move this to detail model!?
      const data = detail.get('_data');
      const keys = Object.keys(data);
      keys.forEach(function (key) {
        let allowReset = true; // @TODO: could set a disallowResetAttrs array,
        // @TODO: eg. disallowResetAttrs: ['index', 'deliveryDateZ']
        // @TODO: then it will not allow resetting those values

        if (key === 'index' || key === 'deliveryDateZ') {
          allowReset = false;
        }

        if (allowReset) {
          let value = detail.getData(key) || ''; // if (R.is(Array, value)) {
          //   value = []
          // } else {
          //   if (R.is(Object, value)) {
          //     value = {}
          //   }
          // }

          detail.setData(key, value);
        }
      });
      detail.setData('roundingCurrency', this.getData('roundingCurrency'));
      detail.setData('unroundedCurrency', this.getData('unroundedCurrency'));
      detail.setData('roundingInventoryQty', this.settings.getProp('roundingInventoryQty'));
      detail.setData('roundingInventoryPrice', this.settings.getProp('roundingInventoryPrice'));
    },

    incompleteItemsDetails() {
      const details = this.getData('details');
      const requiredDetails = this.detailsRequiredAttrs;
      let hasEmptyCount = 0;
      details.forEach(function (detail) {
        let hasEmpty = false;
        requiredDetails.forEach(function (attr) {
          if (!detail.getData(attr)) {
            hasEmpty = true;
          }
        });

        if (hasEmpty) {
          hasEmptyCount++;
        }
      });

      if (hasEmptyCount === 1 && details.length === 1) {
        return true;
      }

      if (hasEmptyCount > 1) {
        return true;
      }

      return false;
    },

    removeEmptyDetails() {
      const details = this.getData('details');
      const requiredDetails = this.detailsRequiredAttrs;
      details.forEach(function (detail) {
        let isEmpty = true;
        requiredDetails.forEach(function (attr) {
          if (detail.getData(attr)) {
            isEmpty = false;
          }
        });

        if (isEmpty) {
          // remove
          details.removeObject(detail);
        }
      });
    }

  });

  _exports.default = _default;
});