define("client/pods/channels/shop/campaigns/automations/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details"], function (_exports, R, _model, _sortableDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sortableDetails.default, {
    toSortProp: '_data.details',

    init() {
      this._super(...arguments);

      this.set('adapters', {
        products: 'products',
        details: 'channels/shop/campaigns/automations/details'
      });
    },

    defaults() {
      return {
        when: this.get('constants.automationsWhen.orderValue'),
        whenItemQtyType: this.get('constants.automationsWhenItemQtyType.products'),
        whenItemQtyTags: [],
        criteria: this.get('constants.automationsCriterias.greaterThanOrEqualTo'),
        thenAction: this.get('constants.automationsThenActions.mustAdd'),
        thenSelectionType: this.get('constants.automationsThenSelectionTypes.all'),
        thenSelectionQty: 1,
        status: this.get('constants.status.active')
      };
    },

    populate(data, attrs) {
      data.details = this._transformDetails(data.details);

      this._super(data, attrs);
    },

    _transformDetails(details = []) {
      return details.map(detail => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.details'),
          data: detail
        });
      });
    },

    hasDetails: Ember.computed('_data.details.@each.synced', function () {
      let details = this.get('_data.details') || [];
      details = R.reject(R.propEq('isDeleted', true))(details);

      if (R.isEmpty(details)) {
        return false;
      }

      return true;
    }),
    isAutoAdd: Ember.computed('_data.thenAction', function () {
      return this.get('_data.thenAction') === this.get('constants.automationsThenActions.mustAdd');
    }),
    isMayAdd: Ember.computed('_data.thenAction', function () {
      return this.get('_data.thenAction') === this.get('constants.automationsThenActions.mayAdd');
    }),
    isChooseBetween: Ember.computed('_data.thenSelectionType', function () {
      return this.get('_data.thenSelectionType') === this.get('constants.automationsThenSelectionTypes.between');
    })
  });

  _exports.default = _default;
});