define("client/pods/grapesjs/plugins/products", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const bigordrProducts = 'bigordr-products';
  const bigordrProductsWrapper = 'bigordr-products-wrapper';
  const columnComponentClassName = 'bigordr-cell';

  var _default = editor => {
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrProducts])(editor);
    const {
      disabled,
      onDbClick,
      onDrop,
      intl,
      isReadOnly,

      /** @type {Categories} */
      categories
    } = options;

    if (disabled) {
      return;
    }

    const {
      DomComponents,
      BlockManager
    } = editor;
    const noProductsMessage = `<div data-no-products-message='true' style='margin: 50px;text-align: center;grid-area: btnLeft/products/btnRight;'><h1>${intl.t('no products found, double click to refresh or add new products')}</h1></div>`;
    const style = `
  <style>
    .bigordr-products-wrapper {
      display: grid;
      grid-template-rows: auto;
      grid-template-areas: "btnLeft products btnRight";
      grid-template-columns: auto auto auto;
    }

    .bigordr-products-wrapper-controls {
    align-self: center;
    margin: 10px;
    }

    .bigordr-products {
      overflow-x: auto;
      white-space: nowrap;
      padding: 15px 0;
      display: flex;
    }

    .slider__products-card {
      display: inline-block;
      margin: 0 10px;
    }

    .checkout-form__extra-max-width{
    max-width: 1255px;
    }
  </style>
`;
    DomComponents.addType('noProductsMessage', {
      model: {
        defaults: {
          tagName: 'div',
          'data-no-products-message': 'true',
          style: {
            margin: '50px',
            'text-align': 'center',
            'grid-area': 'btnLeft/products/btnRight'
          },
          attributes: {
            'data-no-products-message': 'true'
          },
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: false,
          highlightable: false,
          hoverable: false,
          layerable: false,
          propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
          removable: false,
          resizable: false,
          selectable: false,
          stylable: false,
          components: [{
            type: 'text',
            tagName: 'h1',
            content: intl.t('no products found, double click to refresh or add new products'),
            traits: []
          }],
          traits: []
        }
      }
    });
    DomComponents.addType(bigordrProducts, {
      model: {
        defaults: {
          tagName: 'div',
          classes: [bigordrProducts],
          traits: []
        }
      }
    });
    DomComponents.addType(bigordrProductsWrapper, {
      model: {
        defaults: {
          defaultNoProductsMessage: noProductsMessage,
          defaultStyle: style,
          tagName: 'div',
          classes: [bigordrProductsWrapper],
          draggable: `.${columnComponentClassName}`,
          droppable: false,
          components: [{
            type: 'noProductsMessage',
            traits: []
          }, {
            type: bigordrProducts,
            style: {
              'grid-area': 'products'
            },
            badgable: false,
            copyable: false,
            draggable: false,
            droppable: false,
            editable: false,
            highlightable: false,
            hoverable: false,
            layerable: false,
            propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
            removable: false,
            resizable: false,
            selectable: false,
            stylable: false,
            traits: []
          }],
          traits: []
        }
      },
      view: {
        events: {
          dblclick: 'onDbClick'
        },

        onDbClick(event) {
          if (onDbClick && !isReadOnly) {
            const element = event.target.parentElement;
            onDbClick(element);
          }
        }

      }
    });
    BlockManager.add(`${bigordrProducts}-block`, {
      label: intl.t('product'),
      category: categories.shop,
      content: {
        type: bigordrProductsWrapper,
        content: `${style}` // draggable: rowComponentClassNames

      },
      render: () => `
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'>
        <g transform='matrix(1,0,0,1,38.5979,8.83922)'>
            <g transform='matrix(1,0,0,1,-79.7883,-78.1175)'>
                <g transform='matrix(1,0,0,1,37.8139,455.871)'>
                    <g transform='matrix(-0.694823,-3.04913e-16,1.52756e-16,-0.602169,647.959,604.311)'>
                        <rect x='209.331' y='237.609' width='600.169' height='507.391' style='fill:white;'/>
                        <path d='M809.5,237.609L209.331,237.609L209.331,745L809.5,745L809.5,237.609ZM759.127,295.733L259.704,295.733L259.704,686.877L759.127,686.877L759.127,295.733Z'/>
                    </g>
                    <g transform='matrix(0.993398,0,0,0.663184,-259.355,-401.328)'>
                        <path d='M766.929,839.922L347.146,839.922L408.125,748.581L705.95,748.581L766.929,839.922Z'/>
                    </g>
                    <g transform='matrix(4.57099e-17,0.841621,-1.0989,7.04059e-17,695.157,-185.091)'>
                        <rect x='494.977' y='314.602' width='40.355' height='100.893'/>
                    </g>
                    <g transform='matrix(-1.74942,0,0,1.09689,1527.02,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-2.00868,0,0,1.09689,1744.48,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.96583,0,0,1.09689,1595.75,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.05195,0,0,1.09689,1028.68,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.05195,0,0,1.09689,1147.26,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                </g>
                <g transform='matrix(1,0,0,1,480.557,455.871)'>
                    <g transform='matrix(-0.694823,-3.04913e-16,1.52756e-16,-0.602169,647.959,604.311)'>
                        <rect x='209.331' y='237.609' width='600.169' height='507.391' style='fill:white;'/>
                        <path d='M809.5,237.609L209.331,237.609L209.331,745L809.5,745L809.5,237.609ZM759.127,295.733L259.704,295.733L259.704,686.877L759.127,686.877L759.127,295.733Z'/>
                    </g>
                    <g transform='matrix(0.993398,0,0,0.663184,-259.355,-401.328)'>
                        <path d='M766.929,839.922L347.146,839.922L408.125,748.581L705.95,748.581L766.929,839.922Z'/>
                    </g>
                    <g transform='matrix(4.57099e-17,0.841621,-1.0989,7.04059e-17,695.157,-185.091)'>
                        <rect x='494.977' y='314.602' width='40.355' height='100.893'/>
                    </g>
                    <g transform='matrix(-1.74942,0,0,1.09689,1527.02,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-2.00868,0,0,1.09689,1744.48,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.96583,0,0,1.09689,1595.75,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.05195,0,0,1.09689,1028.68,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                    <g transform='matrix(-1.05195,0,0,1.09689,1147.26,-330.238)'>
                        <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                    </g>
                </g>
            </g>
            <g transform='matrix(1,0,0,1,179.397,72.2187)'>
                <g transform='matrix(-0.694823,-3.04913e-16,1.52756e-16,-0.602169,647.959,604.311)'>
                    <rect x='209.331' y='237.609' width='600.169' height='507.391' style='fill:white;'/>
                    <path d='M809.5,237.609L209.331,237.609L209.331,745L809.5,745L809.5,237.609ZM759.127,295.733L259.704,295.733L259.704,686.877L759.127,686.877L759.127,295.733Z'/>
                </g>
                <g transform='matrix(0.993398,0,0,0.663184,-259.355,-401.328)'>
                    <path d='M766.929,839.922L347.146,839.922L408.125,748.581L705.95,748.581L766.929,839.922Z'/>
                </g>
                <g transform='matrix(4.57099e-17,0.841621,-1.0989,7.04059e-17,695.157,-185.091)'>
                    <rect x='494.977' y='314.602' width='40.355' height='100.893'/>
                </g>
                <g transform='matrix(-1.74942,0,0,1.09689,1527.02,-330.238)'>
                    <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                </g>
                <g transform='matrix(-2.00868,0,0,1.09689,1744.48,-330.238)'>
                    <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                </g>
                <g transform='matrix(-1.96583,0,0,1.09689,1595.75,-330.238)'>
                    <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                </g>
                <g transform='matrix(-1.05195,0,0,1.09689,1028.68,-330.238)'>
                    <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                </g>
                <g transform='matrix(-1.05195,0,0,1.09689,1147.26,-330.238)'>
                    <rect x='656.97' y='593.001' width='15.497' height='84.821'/>
                </g>
            </g>
        </g>
    </svg>

  `
    });
    editor.on('block:drag:stop', component => {
      if (component && component.is && component.is(bigordrProductsWrapper)) {
        editor.select(component);

        if (onDrop) {
          onDrop(component);
        }
      }
    });
  };

  _exports.default = _default;
});