define("client/pods/components/elements/element-input/component", ["exports", "ramda", "client/mixins/is-dirty", "client/mixins/date", "client/mixins/has-dropdown", "client/pods/components/elements/mixins/has-date-picker", "client/mixins/translate"], function (_exports, R, _isDirty, _date, _hasDropdown, _hasDatePicker, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_translate.default, _hasDatePicker.default, _date.default, _hasDropdown.default, _isDirty.default, {
    parser: Ember.inject.service(),
    tagName: 'span',
    classNames: ['element-input', 'control'],
    classNameBindings: ['isFullW:element-input--fullw', 'isRunning:is-loading', 'zIndex:u-z-index--2000'],
    datePickerClassName: null,
    autofocus: false,
    autocomplete: 'off',
    translate: true,
    isDatePicker: false,
    isFloat: false,
    isInt: false,
    isDropdownVisible: false,
    focusInAction: null,
    allowCalculations: true,
    type: 'text',
    isFullW: false,
    // used for when is in a dropdown/list searcher, set width to fullw of outer component
    onInput: null,
    // external action to trigger when input has changed (via keyboard)
    onSelectDate: null,
    // external action to trigger when date selected
    dateZ: null,

    // zulu date value binding (only for isDatePicker=true)
    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      // storing original value
      this.setOriginalValue(this.value);
    },

    showRequiredError: Ember.computed('value', 'isRequired', function () {
      if (this.isRequired && !this.value) {
        return true;
      }

      return false;
    }),
    isRequiredIcon: Ember.computed('value', 'isRequired', function () {
      let iconClassNames = 'fas fa-asterisk element-icon--xs';

      if (this.isRequired && !this.value) {
        iconClassNames += ' has-text-danger';
      }

      return iconClassNames;
    }),
    hasIconsLeft: Ember.computed('prefix', 'leftIcon', 'negative', function () {
      return this.prefix || this.leftIcon || this.negative;
    }),
    hasIconsRight: Ember.computed('suffix', 'leftIcon', 'hasCheckIcon', 'isRequired', function () {
      return this.suffix || this.rightIcon || this.hasCheckIcon || this.isRequired;
    }),

    // focusIn (e) {
    //   if (this.onFocusIn) {
    //     const value = this.get('value')
    //     this.onFocusIn(value, e)
    //   }
    // },
    focusOut(e) {
      this._super(e);

      if (this.hasCalculationExpression) {
        const result = this.result;

        if (result) {
          this.set('value', result);
        }
      }

      this.set('result', '');
      const isDirty = this.get('isDirty');

      if (this.isFloat) {
        var float = parseFloat(this.value);

        if (isNaN(float)) {
          float = 0;
        }

        this.set('value', float);

        if (this.onInput) {
          this.onInput(float, e, isDirty, this.resetOriginalValue);
        }
      }

      if (this.isInt) {
        var int = parseInt(this.value);

        if (isNaN(int)) {
          int = 0;
        }

        this.set('value', int);

        if (this.onInput) {
          this.onInput(int, e, isDirty, this.resetOriginalValue);
        }
      }

      if (this.onValidate) {
        this.onValidate(e, this.valueKey, this.value, isDirty);
      }

      if (this.isDatePicker) {
        const value = this.value;
        let dateZ = '';

        if (value) {
          const validDate = this.getMoment(value, this.dateFormat);

          if (validDate.isValid()) {
            dateZ = this.getStartOfDayZ(value);
            this.set('dateZ', dateZ);
          } else {
            dateZ = '';
            this.set('dateZ', dateZ);
            this.set('value', dateZ);
          }
        } else {
          this.set('dateZ', dateZ);
        }

        if (this.onInput) {
          this.onInput(dateZ, e, isDirty, this.resetOriginalValue);
        }
      }

      if (this.onFocusOut) {
        this.onFocusOut(this.value, this.valueKey, e, isDirty);
      }

      if (this.onFocusOutSave && isDirty) {
        this.onFocusOutSave(this.value, this.valueKey, e, isDirty);
        this.resetOriginalValue();
      }
    },

    hasCalculationExpression: Ember.computed('value', function () {
      let value = this.value;

      if (value) {
        value = value.toString();

        if (value.charAt(0) === '=' && value.length > 1 && this.allowCalculations) {
          return true;
        }
      }

      return false;
    }),

    keyDown(e) {
      this._super(e);

      if (this.hasCalculationExpression) {
        const keyCode = e.keyCode || e.which;

        if (keyCode === 13) {
          const result = this.result;

          if (result) {
            this.set('value', result);
            return false;
          }
        }
      }
    },

    keyUp(e) {
      this._super(e);

      const value = this.value;
      let result = '';

      if (this.hasCalculationExpression) {
        const keyCode = e.keyCode || e.which;

        if (keyCode === 13) {
          const result = this.result;

          if (result) {
            this.set('value', result);
            return false;
          }
        } // begin calculations
        // =12*12+3


        const expr = R.takeLast(value.length - 1, value);

        try {
          result = this.parser.evaluate(expr);
        } catch (e) {
          result = '';
        }
      }

      this.set('result', result);

      if (this.onKeyUp) {
        const isDirty = this.get('isDirty');
        this.onKeyUp(e, value, isDirty);
      }
    },

    actions: {
      input(value, e) {
        if (this.maxValue) {
          if (value > this.maxValue) {
            value = this.maxValue;
            this.set('value', value);
          }
        }

        if (this.minValue) {
          if (value < this.minValue) {
            value = this.minValue;
            this.set('value', value);
          }
        }

        if (this.onInput) {
          return this.onInput(...arguments);
        }
      },

      submit() {
        if (this.onSubmit) {
          this.onSubmit(this.value);
        }
      }

    }
  });

  _exports.default = _default;
});