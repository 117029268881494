define("client/pods/dispatch/lists/print-all-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'pickingList',
        label: 'picking list',
        component: 'dispatch/panels-display/display-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-people-carry'
      }, {
        value: 'deliveryNotes',
        label: 'delivery notes',
        component: 'documents/document-print/print-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-truck'
      }, {
        value: 'waybills',
        label: 'hct waybills',
        component: 'transporters/hct-waybills-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-sticky-note'
      }, {
        value: 'hctSummary',
        label: 'hct summary',
        component: 'transporters/hct-summary',
        tabType: 'reuseSubTab',
        icon: 'fas fa-sticky-note' // }, {
        //   value: 'eInvoices',
        //   label: 'tw_eInvoicing',
        //   component: 'extensions/apps/tw/e-invoices/print-all-btn',
        //   icon: 'fas fa-file-alt'

      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});