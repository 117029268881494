define("client/pods/components/channels/shop/campaigns/campaigns-editor/products-editor/component", ["exports", "ramda", "ember-concurrency", "client/mixins/crud", "client/mixins/sortable-actions"], function (_exports, R, _emberConcurrency, _crud, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _sortableActions.default, {
    crud: Ember.inject.service(),
    modelProp: 'model',
    indexProp: '_data.index',
    adapterName: 'channels/shop/campaigns/details',
    filtersAdapterName: 'channels/shop/campaigns/details/filters',

    init() {
      this._super(...arguments); // const model = this.model
      // const master = model.getData('_key')
      // const filters = this.search.setupFilters({
      //   adapterName: this.filtersAdapterName,
      //   defaultFiltersData: {
      //     master
      //   }
      // })


      this.crud.addLists(this, ['channels/shop/campaigns/lists/noOfLines', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio']); // this.set('filters', filters)

      this.set('selectedProducts', []);
    },

    didInsertElement() {
      this._super(...arguments);

      const isEditing = this.get('isEditing');

      if (!isEditing) {
        this.fetchCampaignDetailsTask.perform();
      }
    },

    toDisplayResults: Ember.computed('isBulkEditing', '_sortedDetails.[]', '_dirtyDetails.[]', function () {
      if (this.get('isBulkEditing')) {
        return this.get('_dirtyDetails') || [];
      }

      return this.get('_sortedDetails') || [];
    }),

    _getSortedDetails() {
      var _this$filters;

      const details = this.get('filters.results') || [];
      let results = details;
      const sortArray = ((_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : _this$filters.getData('sort')) || [];
      const sortBy = R.pathOr('', ['firstObject', '_data', 'by'])(sortArray);

      if (sortBy === 'index' && sortArray.length === 1) {
        const sortDirection = R.pathOr('', ['firstObject', '_data', 'direction'])(sortArray);

        if (sortDirection === 'ASC') {
          results = R.pipe(R.reject(R.pathEq(['isDeleted'], true)), R.sortWith([R.ascend(R.path(['_data', 'index']))]))(details);
        }

        if (sortDirection === 'DESC') {
          results = R.pipe(R.reject(R.pathEq(['isDeleted'], true)), R.sortWith([R.descend(R.path(['_data', 'index']))]))(details);
        }
      }

      return results;
    },

    _sortedDetails: Ember.computed('filters.results.@each.synced', 'filters.synced', 'model.synced', function () {
      return this._getSortedDetails();
    }),
    _dirtyDetails: Ember.computed('filters.results.[]', 'filters.synced', 'isBulkEditing', function () {
      return this._getSortedDetails();
    }),
    reorderAndSaveDetailsTask: (0, _emberConcurrency.task)(function* (details) {
      if (!R.isEmpty(details) && !this.isGuest) {
        const adapterName = 'channels/shop/campaigns/details/batch';
        const master = this.get('model._data._key');
        const appendPath = `/${master}/indexes`;
        return yield this.adapterCallTask.perform('updateIndexes', {
          adapterName,
          appendPath,
          data: details
        });
      }

      return false;
    }).drop(),
    fixIndexesTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      if (!this.isGuest) {
        const master = this.get('model._data._key');
        const msg = this.intl.t('are you sure you want to re-index all products? this may take some time.');

        if (window.confirm(msg)) {
          const resp = yield this.server.call('PATCH', `api/protected/channels/shop/campaigns/details/master/${master}/fix-indexes`);

          if (onCloseDialogAction) {
            onCloseDialogAction();
          }

          return resp;
        }
      }

      return false;
    }).drop(),
    saveProductSettingsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      yield this.crud.replaceRecordTask.perform({
        adapterName: 'channels/shop/campaigns',
        model: this.dirty
      });
      onCloseDialogAction();
    }).drop(),
    // drafts: computed('results.@each.synced', function () {
    //   const campaignDetails = this.results || []
    //   return R.filter(R.pathEq(['_data', 'status'], 'draft'))(campaignDetails)
    // }),
    actions: {
      resetDetailsUpdated() {
        this.model.set('detailsUpdated', []);
      },

      afterCreateItem(onCloseDialogAction) {
        this.fetchCampaignDetailsTask.perform();

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      },

      reorderAndSaveDetails(detail) {
        var _this$filters2;

        const model = this.model;
        const dragItem = this.dragDetail;
        const details = ((_this$filters2 = this.filters) === null || _this$filters2 === void 0 ? void 0 : _this$filters2.results) || [];
        const sortedDetails = model.reorderDetails(details, detail, dragItem);
        this.reorderAndSaveDetailsTask.perform(sortedDetails);
      },

      editProductSettings() {
        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns',
          model: this.model
        });
        this.set('dirty', dirty);
      },

      cancelProductSettingsEditing(onCloseDialogAction) {
        this.crud.cancelEditing({
          adapterName: 'channels/shop/campaigns',
          dirty: this.dirty
        });
        this.set('dirty', false);
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});