define("client/pods/components/guests/app-logged-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oEvucBJO",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchSettingsTask\",\"isRunning\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"app-grid u-no-print guest-app\"],[8],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-grid__content\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"tabsManager\",\"left\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isActive\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"guestResource\",\"tabs\",\"tab\"],[[24,[\"guestResource\"]],[24,[\"tabsManager\",\"left\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"id\",\"print-container\"],[8],[0,\"\\n\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/app-logged-in/template.hbs"
    }
  });

  _exports.default = _default;
});