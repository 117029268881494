define("client/pods/components/faqs/faqs-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let FaqsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = (_temp = class FaqsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "currentIndex", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "faq", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "showAllDetails", _descriptor7, this);
      (0, _defineProperty2.default)(this, "adapterName", this.args.adapterName);
      (0, _defineProperty2.default)(this, "removeMessage", 'are you sure you want to delete');
      this.setupEditing();
    }

    setupEditing() {
      const originalModel = this.model;
      this.model = this.crud.setupDirty({
        adapterName: this.args.adapterName,
        model: this.model
      });

      if (this.args.isNew) {
        const faqModel = this.crud.setupNewRecord({
          adapterName: 'faqs'
        });
        this.faq = faqModel;

        this.model._data.faqs.pushObject(faqModel);
      } else {
        const faqModel = this.args.faq;
        const currentIndex = R.indexOf(faqModel)(originalModel._data.faqs);
        const dirtyFaq = this.crud.setupDirty({
          adapterName: 'faqs',
          model: faqModel
        });

        this.model._data.faqs.replace(currentIndex, 1, [dirtyFaq]);

        this.faq = dirtyFaq;
      }
    }

    *removeFaqTask({
      adapterName,
      onAfter
    }) {
      const msg = this.intl.t(this.removeMessage);

      if (window.confirm(msg)) {
        const dirty = this.model;
        const faq = dirty._data.faqs[this.currentIndex];

        dirty._data.faqs.removeObject(faq);

        yield this.crud.updateRecordTask.perform({
          adapterName,
          onAfter,
          model: dirty
        });
        this.args.onRemove();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentIndex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.currentIndex;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "faq", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showAllDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeFaqTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeFaqTask"), _class.prototype)), _class));
  _exports.default = FaqsEditorComponent;
});