define("client/pods/components/products/panels-display/activity-display/activity-display-doc/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/mixins/doc-list-by"], function (_exports, _date, _settings, _crud, _docListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _docListBy.default, _settings.default, {
    crud: Ember.inject.service(),
    filtersAdapterName: 'documents/filters',

    init() {
      this.crud.addLists(this, ['dispatch/lists/processes']);

      this._super(...arguments);

      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');
      this.set('docType', docType);
      this.set('docName', docName);
      const adapterName = `documents/${docType}/${docName}/details`;
      this.set('adapterName', adapterName);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          sort: [{
            by: 'dateZ',
            direction: 'DESC'
          }, {
            by: 'docNo',
            direction: 'DESC'
          }]
        }
      });
      this.set('filters', filters);
    },

    async didInsertElement() {
      const adapterName = this.adapterName;
      const model = this.model;
      const itemKey = model.getData('_key');
      const filters = this.filters;
      const results = await this.listByDetailsTask.perform({
        filters,
        adapterName,
        itemKey
      });
      this.set('results', results);
    },

    actions: {
      async displayByMonth(period) {
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        const model = this.model;
        const itemKey = model.getData('_key');
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = await this.listByDetailsTask.perform({
          filters,
          adapterName,
          itemKey,
          dateStart,
          dateEnd
        });
        this.set('results', results);
      },

      async displayByCustomMonth() {
        const dateEndString = this.customDateEnd;
        const dateStartString = this.customDateStart;
        let dateEnd;
        let dateStart;

        if (dateEndString) {
          if (!dateStartString) {
            const dateEndObj = this.getMonthEnd(dateEndString);
            dateStart = this.formatDateForUrl(dateEndObj.startOf('month'));
            dateEnd = this.formatDateForUrl(dateEndString);
          } else {
            if (dateStartString > dateEndString) {
              dateEnd = this.formatDateForUrl(dateStartString);
              dateStart = this.formatDateForUrl(dateEndString);
            } else {
              dateEnd = this.formatDateForUrl(dateEndString);
              dateStart = this.formatDateForUrl(dateStartString);
            }
          }

          const model = this.model;
          const itemKey = model.getData('_key');
          const adapterName = this.adapterName;
          const filters = this.filters;
          const results = await this.listByDetailsTask.perform({
            filters,
            adapterName,
            itemKey,
            dateStart,
            dateEnd
          });
          this.set('results', results);
        }
      }

    }
  });

  _exports.default = _default;
});