define("client/pods/components/files-manager/finder/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let FilesManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = (_temp = class FilesManagerComponent extends _component.default {
    // individual model in uploadsCollection._data.uploads
    // collection model that holds _data.uploads
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "loading", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedResource", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "tab", _descriptor6, this);
      (0, _defineProperty2.default)(this, "adapterName", 'uploads');
      (0, _defineProperty2.default)(this, "collectionAdapterName", 'uploads/collection');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'uploads/filters');
      (0, _defineProperty2.default)(this, "finderLabel", this.args.finderLabel || 'files_manager_tags');
      this.tab = this.tabsManager.getActiveSubTab(this.args.tab);

      if (RA.isNilOrEmpty(this.tab)) {
        this.tab = this.args.tab;
      }
    }

    get options() {
      var _this$args;

      const externalOptions = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.options) || {};
      return (0, R.mergeRight)({
        isDefault: true
      }, externalOptions);
    }

    get filesBrowserFilter() {
      return this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
    }

    get allowMultipleItems() {
      return R.pathOr(true, ['args', 'allowMultipleItems'])(this);
    }

    willDestroy() {
      this.tabsManager.closeTab(this.tab.subTabs, this.tab.subTabs[0]);
    }

    setResource(resourceName) {
      this.display = false;
      this.selectedResource = resourceName;
      const panelOptions = {
        component: 'files/files-browser'
      };
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedResource", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tab", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setResource", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setResource"), _class.prototype)), _class));
  _exports.default = FilesManagerComponent;
});