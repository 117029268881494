define("client/translations/zh-tw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "": "",
    "#": "#",
    "%": "%",
    "...": "...",
    "1 day": "1天",
    "1 hour": "1小時",
    "1 point equal to": "一點 = $",
    "1 week": "一週",
    "120+ days": "120天以上",
    "15 minutes": "15分鐘",
    "2 days": "2天",
    "2 hours": "2小時",
    "3 days": "3天",
    "3 hours": "3小時",
    "30 days": "30天",
    "30 minutes": "30分鐘",
    "3rd party": "第三方平台",
    "4 days": "4天",
    "4 hours": "4小時",
    "5 hours": "5小時",
    "6 hours": "6小時",
    "60 days": "60天",
    "90 days": "90天",
    "ATM_BOT": "台灣銀行 ATM",
    "ATM_CATHAY": "國泰世華銀行 ATM (暫不提供)",
    "ATM_CHINATRUST": "中國信託 ATM",
    "ATM_ESUN": "玉山銀行 ATM (暫不提供)",
    "ATM_FIRST": "第一銀行 ATM",
    "ATM_FUBON": "台北富邦 ATM (暫不提供)",
    "ATM_LAND": "土地銀行 ATM",
    "ATM_TACHONG": "大眾銀行 ATM (2018 年已併到元大銀行",
    "ATM_TAISHIN": "台新銀行 ATM",
    "HCT": "新竹物流",
    "Taiwan": "台灣",
    "_blank_": " ",
    "abandoned": "放棄",
    "access token": "Access token",
    "account": "帳戶",
    "account description": "帳戶描述",
    "account last 5 digits": "後五碼",
    "account number": "帳戶號碼",
    "account type": "帳戶類別",
    "accountant (view only)": "帳務人員(只能查看)",
    "accounts": "帳戶",
    "active": "有效的",
    "activity": "交易歷史",
    "add": "增加",
    "add activity": "新增事件",
    "add all": "添加全部",
    "add bulk": "批次上架",
    "add business": "Add Business",
    "add cell": "增加儲存格",
    "add custom worksheet": "增加自訂工作表",
    "add department": "新增部門",
    "add detail": "增加詳細資料",
    "add link to image": "在圖片新增連結",
    "add new adjustment": "增加新佣金調整",
    "add person": "新增聯絡人",
    "add product": "增加產品",
    "add product to shop": "上架產品",
    "add row": "增加橫列",
    "add schedule": "增加時間表",
    "add surcharge": "新增附加費用",
    "add tab": "新增標頭標籤",
    "add to cart": "加入購物車",
    "addAll": "添加全部",
    "added": "已加入",
    "additional description": "更多描述",
    "additional free shipping rule": "額外免運費條件",
    "additional information": "其他資料",
    "additional message": "更多電子信箱內文",
    "address": "地址",
    "addresses": "地址",
    "adjust all commission base costs": "調整所有抽成底價",
    "adjust inventory": "調整庫存",
    "adjust member level": "調整會員等級",
    "adjusted commission base cost": "調整後佣金成本基數",
    "adjusted commission total amount": "調整後佣金總數",
    "adjustment": "調整",
    "adjustment this month": "本月調整",
    "admin": "管理員",
    "advanced": "進階",
    "advancedTemplate": "進階模板",
    "all": "全部",
    "all activity": "所有紀錄",
    "all qty": "所有數量",
    "all undispatched": "全部取消出貨",
    "allow access date end": "允許登入結束日期",
    "allow access date start": "允許登入開始日期",
    "allow discount for on sale items": "允許特價商品可以再折扣",
    "allow e-invoices to be issued": "允許開立電子發票",
    "allow on sale items": "允許特價商品",
    "allow pick up": "可自取",
    "allow preOrder": "允許預購",
    "allow public template editor": "可建網頁公用模板",
    "allow shipping": "可運輸",
    "allow to earn reward points": "允許累積會員點數",
    "allow to re-index all products": "允許重新排序所有產品",
    "allow to spend reward points": "允許使用會員點數折抵消費金額",
    "allow track reward for non member": "允許累積非會員買家的點數（非會員買家不能看到以及使用會員點數，成為會員後先前累積的點數就能看到和使用）",
    "allowed product tags": "允許商品標籤",
    "allowed shop": "可使用商店",
    "already imported": "先前已匯入",
    "already sent": "已送出",
    "always charge shipping fee": "一定要收運費",
    "always display wizard": "永遠顯示快速建立模示",
    "always hide menu": "永遠隱藏主選單",
    "amount": "金額",
    "amount due": "總撥款",
    "answer": "答案",
    "apply to all product": "適用於全部商品",
    "approve": "批准",
    "are you sure you want to cancel invoice number": "您確定要取消發票",
    "are you sure you want to cancel this order?": "您確定要取消訂單嗎?",
    "are you sure you want to cancel?": "您確定要取消?",
    "are you sure you want to clear page": "您確定要刪除嗎?",
    "are you sure you want to close?": "您確定要關閉嗎?",
    "are you sure you want to delete": "您確定要刪除嗎?",
    "are you sure you want to delete comment": "您確定要刪除留言",
    "are you sure you want to delete image?": "您確定要刪除照片?",
    "are you sure you want to delete variant option": "Are you sure you want to delete variant option? Related child products will be deleted.",
    "are you sure you want to dispatch all?": "您確定要全部發送嗎?",
    "are you sure you want to hold all?": "您確定要全部暫停嗎?",
    "are you sure you want to logout": "您確定要登出嗎?",
    "are you sure you want to mark as completed?": "你確認要標記為已完成？",
    "are you sure you want to merge contacts": "你確認要合併聯絡人？",
    "are you sure you want to re-index all products? this may take some time.": "您確認要重新排序所有商品嗎？這可能會花上一些時間。",
    "are you sure you want to re-sync product": "要重新整理嗎？",
    "are you sure you want to remove": "您確定要刪除嗎?",
    "are you sure you want to remove entire row?": "你確認要刪除這個橫列嗎？",
    "are you sure you want to remove this cell?": "你確認要刪除這個儲存格嗎？",
    "are you sure you want to remove this custom sheet?": "你確認要刪除這個自訂工作表嗎？",
    "are you sure you want to reprint": "您確定要重新列印?",
    "are you sure you want to reset all rows?": "您確定要重置所有的橫列嗎？",
    "are you sure you want to revert to draft": "您確定要回覆成草稿?",
    "are you sure you want to save as template": "您確定要存為模板?",
    "are you sure you want to undispatch all?": "您確定要全部取消發送嗎?",
    "are you sure you want to undo cancel?": "您確定要回復已取消?",
    "are you sure you want to undo void for document": "您確定要回復作廢的文件",
    "are you sure you want to undo?": "您確定要回復?",
    "are you sure you want to unhold all?": "您確定要全部取消暫停嗎?",
    "are you sure you want to void document": "您確定要作廢文件",
    "area": "地區",
    "array": {
      "min": "必填 {limit}"
    },
    "arrival date": "配送日期",
    "assign to": "指派給",
    "at least 6 characters": "至少6個字符",
    "atm_approved": "付款成功",
    "atm_attentionRequired": "需要手動處理",
    "atm_eSun": "玉山銀行",
    "atm_expired": "付款日已超過",
    "atm_pending": "未付款",
    "atm_pending_short": "未付款",
    "atm_ubot": "聯邦銀行",
    "attentionRequired": "需要手動處理",
    "auto": "自動",
    "auto cancel expired atm orders": "自動取消過期的ATM訂單",
    "auto generate detail row": "自動產生商品細項橫列",
    "auto generate sales history (optional)": "自動產生銷售紀錄（非必填）",
    "auto index": "自動編號",
    "auto link member": "會員資料是否和聯絡人資料自動連結？",
    "auto print invoices": "自動列印統一發票",
    "auto send birthday rewards": "自動發送生日獎勵",
    "automatically end campaign": "自動結束活動",
    "automatically send payment reminders": "自動發送付款提醒信",
    "automatically update all following invoices": "自動更新其他發票號碼",
    "automation": "滿額禮或加購",
    "automation priority": "條件排序",
    "automation_action_mayAdd": "顯示其他產品",
    "automation_action_mustAdd": "自動將產品添加到購物車",
    "automation_selection_type": "添加方式",
    "automation_then": "則",
    "automation_title": "顯示標題",
    "automation_when": "當",
    "automations": "滿額禮或加購",
    "available": "可得到",
    "available preOrder qty": "可用預購數量",
    "available qty": "可用庫存",
    "available to sell qty": "可賣數量",
    "average": "平均",
    "average item price": "平均價格",
    "average order value": "平均單價",
    "average price": "平均價格",
    "awaiting payment": "未付款",
    "awaitingPayment": "未付款",
    "b2b": "B2B,",
    "b2c": "B2C",
    "back to previous": "回上一頁",
    "back_page": "回到上一頁",
    "balance sheet": "資產負債表",
    "bank": "銀行",
    "banking details": "銀行帳號資料",
    "banner bottom": "下方橫幅圖",
    "banner top": "上方橫幅圖",
    "barcode": "國際條碼",
    "base cost": "抽成底價",
    "basic": "基本",
    "batch number": "批號",
    "beginning of month": "月初",
    "beta": "新功能(測試中/可試用)",
    "bigordr-banner": "bigordr-banner",
    "bigordr-cell": "Cell",
    "bigordr-flex-cell": "bigordr flex cell",
    "bigordr-grid": "bigordr-grid",
    "bigordr-grid-images": "bigordr-grid-images",
    "bigordr-inner-row": "bigordr-inner-row",
    "bigordr-strip": "bigordr-strip",
    "billing address": "公司地址",
    "billingAddress": "公司地址",
    "birthday": "生日",
    "birthday email": "生日祝賀信件",
    "birthday reward recipients": "生日獎勵收受人",
    "birthday rewards": "生日獎勵",
    "blog": "文章",
    "blogs": "文章",
    "blogs images ratio": "文章圖片長寬比例",
    "blogs tags": "Blogs tags",
    "book qty": "帳面庫存量",
    "bot": "機器人",
    "browse": "瀏覽",
    "bulk discount": "量販折扣",
    "bulk discount schedules": "量販折扣時間表",
    "bulk discounts": "量販折扣",
    "bulk edit": "大量編輯",
    "businesses": "Businesses",
    "button": "按鈕",
    "button label": "按鈕標籤",
    "button1": "按鈕1",
    "button2": "按鈕2",
    "button3": "按鈕3",
    "by orders": "By Orders",
    "by products": "By Products",
    "byAllOrder": "顯示所有商品",
    "byDetails": "顯示訂購商品數量",
    "byDetailsWithGrandTotals": "顯示訂購商品數量加總計",
    "callback url": "Callback Url",
    "campaign": "活動",
    "campaign images": "活動照片",
    "campaign period": "活動期間",
    "campaign products": "活動產品",
    "campaign qty": "活動數量",
    "campaign_description": "商店簡介",
    "campaign_expired_template": "已結團內文",
    "campaigns": "活動",
    "can preOrder": "可預購",
    "can split by transporter": "可依照物流業者拆單",
    "cancel": "取消",
    "cancel invoice": "取消統一發票",
    "cancel invoice reason": "取消統一發票原因（最多20個字符）",
    "cancel order": "取消訂單",
    "cancel_save": "取消儲存",
    "cancelled": "已取消",
    "cancelled invoices": "已取消發票",
    "cannot import due to incorrect data": "訂單資料有錯誤無法匯入",
    "cannot issue e-invoices": "無法開立",
    "cannot merge. contacts are linked to different members": "Cannot merge, contacts are linked to different members.",
    "cannot refund credit card": "無法信用卡退刷總計",
    "cannot send": "無法送出",
    "cannot upload file, files must be under 10mb": "無法上傳文件，檔案必須小於10 MB。",
    "cart": "購物車",
    "cart items": "購物車品項",
    "cash": "貨到付款",
    "cash before delivery": "ATM轉帳",
    "cash book": "銀行帳戶",
    "cash on delivery": "貨到付款",
    "cash payment fee": "貨到付款手續費",
    "cashBeforeDelivery": "ATM轉帳",
    "category": "性質",
    "cc_approved": "刷卡成功",
    "cc_awaitingPayment": "刷卡未付款",
    "cc_awaiting_3ds_verification": "3D驗證未完成",
    "cc_declined": "刷卡失敗",
    "cc_paidOut": "已撥款",
    "cc_partial_card_number": "卡碼",
    "cc_ref": "付款編碼",
    "cc_refund": "刷卡退款",
    "cc_refundRequested": "刷卡申請退款中",
    "cc_refund_total": "退款總計",
    "cc_refunded": "刷卡已退款",
    "cc_total": "刷卡金額",
    "cc_unsuccessful": "不成功",
    "change language? will reload page": "您確定要更新語言?會更新頁面",
    "change password": "變更密碼",
    "channel": "通路",
    "channel id": "Channel Id",
    "channel secret": "Channel Secret",
    "channels": "通路",
    "charge shipping fee": "收運費",
    "charge to different account": "選擇其他帳號",
    "charge to different customer account": "選擇其他的顧客帳號",
    "charge to different supplier account": "選擇其他的供應商帳號",
    "chat messages": "聊天訊息",
    "check order status": "訂單查詢",
    "checkout": "結帳",
    "checkout logs": "Checkout Logs",
    "checkout/details": "結帳顧客資料 (步驟 2/4)",
    "checkout/payment": "結帳付款 (步驟 3/4)",
    "checkout/success": "結帳成功 (步驟 4/4)",
    "checkout/summary": "結帳購物車內容 (步驟 1/4)",
    "choose between": "擇其一",
    "choose from qty": "Choose From Qty",
    "choose qty": "任選數量",
    "choose template": "套用模板",
    "chooseBetween": "擇其一",
    "clear page": "刪除內文",
    "close": "關閉",
    "close preview": "顏色預覽",
    "closing balance": "期終餘額",
    "cm": "公分",
    "code": "編碼",
    "color template": "顏色模板",
    "color theme": "顏色主題",
    "combine labels": "結合標籤",
    "comment": "留言",
    "comments": "留言",
    "commission": "抽成",
    "commission %": "抽成%",
    "commission % per item": "每件抽成%",
    "commission amount": "抽成金額",
    "commission amount per item": "每件抽成金額",
    "commission base cost": "抽成基本成本",
    "commission base cost per item": "每件商品抽成成本基數",
    "commission base costs": "抽成底價",
    "commission calculation": "抽成計算",
    "commission due date": "抽成付款日期",
    "commission due from last month": "上個月到期抽成",
    "commission due this month": "本月到期抽成",
    "commission payable to": "支付抽成者",
    "commission per unit": "每單位抽成",
    "commission refunded this month": "本月取消退回抽成",
    "commission total": "總抽成",
    "commission total amount": "抽成總金額",
    "commission_adjustment_error_msg": "發生錯誤！最大折扣金額為10",
    "commission_byTotal": "抽成",
    "commission_normalPercent": "折扣%",
    "commission_perItem": "抽成",
    "commission_twPercent": "折",
    "commissions": "抽成",
    "commissions adjustment": "佣金調整",
    "commissions calculation method": "抽成計算方法",
    "commissions due": "應付抽成",
    "commissions due returned": "應付退貨抽成",
    "commissions generated": "產生的抽成",
    "commissions generated returned": "退貨抽成",
    "commissions payouts": "抽成支出",
    "commissions refunded": "抽成已取消退回",
    "commissions summary for this month": "本月抽成總結",
    "company": "公司",
    "company address": "公司地址",
    "company name": "公司名",
    "company opening hours": "公司營業時間",
    "company telephone": "公司電話",
    "complete": "已完成",
    "complex": "進階",
    "compound": "Compound",
    "confirm password": "再次確認密碼",
    "confirm_ignore": "已處理不要理",
    "connect domain": "連接網域",
    "connection error": "連線錯誤",
    "consignment": "寄賣",
    "consignment delivery": "寄賣出貨單",
    "consignment qty": "寄賣數量",
    "consignment receipt": "寄賣收據",
    "consignments": "寄賣",
    "contact": "聯絡人",
    "contact email": "收件人電子信箱",
    "contact info": "連絡資料",
    "contact is not a member, send reward": "聯絡人並非會員，您確定要寄送獎勵嗎？",
    "contact name": "收件人姓名",
    "contact person": "聯絡人",
    "contact phone number": "聯絡電話",
    "contact us": "聯絡我們",
    "contacts": "聯絡資料",
    "contacts rewards": "聯絡人點數",
    "contacts without member level": "沒有會員等級的聯絡人",
    "contains an invalid value": "輸入錯誤：內含無效數值",
    "contains duplicate value": "輸入錯誤，有重複值",
    "conversion rate": "轉換率",
    "convert set items to only show set child items": "Convert set items to only show set child items",
    "convert to date": "轉換成日期",
    "convert to index": "轉換成指數",
    "convert to number": "轉換成數字",
    "copy": "複製",
    "copy & paste": "複製貼上",
    "copy from above": "複製上面",
    "copy images": "複製圖片",
    "copy link": "複製連結",
    "copy payment link": "複製付款連結",
    "cost of sales": "Cost Of Sales",
    "countries": "國家",
    "country": "國家",
    "create from": "create from",
    "create hct contact": "新增新竹物流連絡資料",
    "create new adjustment": "抽成調整",
    "create new commissions": "新增抽成計算",
    "created by": "建立人",
    "created date": "輸入日期",
    "credit": "貸記",
    "credit card": "信用卡",
    "credit card history": "刷卡歷史",
    "credit card provider: tap pay": "Credit Card provider: Tap Pay",
    "credit card refunded email": "已退刷通知信",
    "credit card status": "信用卡狀態",
    "credit card transactions": "信用卡明細",
    "credit note": "Credit Note",
    "creditCard": "線上刷卡",
    "creditors": "債權人",
    "criteria": "符合此條件",
    "currency": "貨幣",
    "currency decimal rounding": "貨幣尾數",
    "current": "本月",
    "current active": "正在進行的活動",
    "current bulk discount": "Current Bulk Discount",
    "current document": "目前文件",
    "current month": "本月",
    "current points": "目前點數",
    "current year": "本年",
    "current_lang": "繁體中文",
    "custom": "自訂",
    "custom width": "自訂寬度",
    "customer": "顧客",
    "customer account": "顧客帳號",
    "customer activity": "客戶歷史",
    "customer payment method": "銷貨付款方式",
    "customer payment terms": "銷貨付款條件",
    "customer statements": "顧客對帳表",
    "customer tags": "顧客標籤",
    "customize worksheet": "自訂工作表",
    "customized export": "自訂匯出",
    "daily revenue": "每日營業額",
    "dashboard": "分析",
    "data": "資料",
    "data out of date": "資料已變動",
    "data was updated": "資料已變動",
    "date": "日期",
    "date conflict": "日期衝突",
    "date end": "結束日期",
    "date start": "開始日期",
    "date_period": "日期",
    "day": "天",
    "day of month": "Day Of Month",
    "days": "天",
    "debit": "借記",
    "debit note": "Debit Note",
    "debtors": "債務人",
    "decrease": "減少",
    "default": "預設",
    "default email content": "預設電子郵件內容",
    "default facebook page": "預設的Facebook頁面",
    "default for documents": "設為文件預設",
    "default image": "預設照片",
    "default item tax": "預設稅值",
    "default name": "預設名稱",
    "default payment method": "預設付款方式",
    "default purchase price setting": "Default Purchase Price Setting",
    "default selling price setting": "Default Selling Price Setting",
    "default settings": "預設值",
    "default shipping": "預設運送地區",
    "default status for when over sales limited qty": "當已售出數量超過限制銷售數量狀態預設",
    "default tax": "預設稅值",
    "default tax on purchases for products": "Default Tax On Purchases For Products",
    "default tax on purchases for services": "Default Tax On Purchases For Services",
    "default tax on sales for products": "Default Tax On Sales For Products",
    "default tax on sales for services": "Default Tax On Sales For Services",
    "defaultCanceledEmailMessage": "已取消您的訂單，以下為取消此訂單的相關資訊",
    "defaultDispatchedEmailMessage": "您的訂單今日已幫您出貨",
    "defaultOrderedEmailMessage": "感謝您的支持與喜愛，您已完成訂單，如下為您的訂單明細",
    "defaultRefundedEmailMessage": "已退刷，以下列商品已完成退刷",
    "defaults": "預設",
    "defaults faqs": "預設:常見問題",
    "delete": "刪除",
    "delete_keyword": "刪除",
    "deleted": "已刪除",
    "delivered": "出貨",
    "delivered qty": "出貨數量",
    "deliveries": "出貨",
    "delivery": "出貨",
    "delivery address": "收件地址",
    "delivery date": "出貨日期",
    "delivery date options": "交貨日期選項",
    "delivery note": "出貨單",
    "delivery notes": "出貨單",
    "deliveryAddress": "收件地址",
    "denied product tags": "禁止商品標籤",
    "department": "部門",
    "department name": "部門名稱",
    "departments": "部門",
    "description": "敘述",
    "deselect all": "取消全選",
    "detail": "詳細資料",
    "detailLimitTypes_campaign": "活動數量",
    "detailLimitTypes_inventory": "庫存量",
    "detailType_address": "地址 / 公司地址 / 收件地址",
    "detailType_email": "電子信箱",
    "detailType_note": "備註 / 連結",
    "detailType_phone": "電話 / 傳真 / 手機",
    "detailed statement": "詳細說明",
    "details": "詳細資料",
    "did not receive an activation email? resend activation email": "Did not receive an activation email? Resend activation email-",
    "dimensions_height": "高",
    "dimensions_length": "長",
    "dimensions_width": "寬",
    "disc": "折扣",
    "discInclTotal": "含稅折扣",
    "discount": "折扣",
    "discount %": "折扣 %",
    "discount amount": "折扣額",
    "discount for on sale items": "特價商品折扣",
    "discount rate": "折扣%",
    "discount rate for on sale items": "特價商品折扣比例",
    "discount total": "總折扣",
    "discount when buy items": "折扣當購買商品",
    "discount when items value": "當購物滿 <金額> 折扣",
    "discounts": "折扣",
    "dispatch": "出貨部門",
    "dispatch (view only)": "出貨人員(只能查看)",
    "dispatch all": "全部出貨",
    "dispatch date": "出貨日期",
    "dispatch process for new orders": "Dispatch process for new orders",
    "dispatch qty": "出貨數量",
    "dispatch status": "出貨狀態",
    "dispatch today": "今天出貨",
    "dispatched": "已出貨",
    "dispatched by date": "依出貨日期",
    "display in shop": "使用進階模板",
    "display order": "顯示順序",
    "display products in shop": "同時使用進階模板及預設商品模板",
    "display style": "顯示風格",
    "display wizard": "快速建立模示",
    "display_faq": "顯示",
    "district": "區域",
    "districts": "區域",
    "dividends": "紅利",
    "doc": "文件",
    "doc-pending": "未交總表",
    "docNo": "文件號碼",
    "document": "文件",
    "document delivery dates": "出貨日期",
    "document discount rates": "Document Discount Rates",
    "document number": "文件號碼",
    "document_row_action_buttons": "文件欄按鈕列表",
    "documentationId": "說明編號",
    "documents": "文件",
    "does not contain required value": "輸入錯誤",
    "domain": "網址",
    "domain connected": "網域已連接",
    "domain not connected": "網域未連接",
    "domain_validation_step1": "<ol class='spaced-list'> <li>In a second browser window or tab, sign in to your domain host account. For details, see&nbsp;<a href='/a/answer/48323' rel='noopener'>Identify your domain&nbsp;host</a>.</li> <li>Go to your domain’s DNS records. The page might be called something like <strong>DNS Management</strong>, <strong>Name</strong> <strong>Server Management</strong>, <strong>Control Panel</strong>, or <strong>Advanced Settings</strong>. </li> <li>Select the option to add a new record.</li> </ol>",
    "domain_validation_step1_label": "Step 1: Go to your domain's DNS records",
    "domain_validation_step2": "<ol class='spaced-list'> <li>For the record type, select <strong>TXT</strong>.</li> <li>In the <strong>Name/Host/Alias</strong> field, enter <strong>@</strong> or leave it blank.<br> Your host might require you&nbsp;to enter your domain, which looks like <em>example.com</em>,&nbsp;into this field. Your other DNS records might indicate what you should enter. </li> <li>In the <strong>Time to Live (TTL)</strong> field, enter <strong>86400</strong> or leave the default.</li> <li>In the <strong>Value/Answer/Destination</strong> field, paste the verification record you copied in step 1.</li> <li>Save the record.</li> </ol>",
    "domain_validation_step2_label": "Step 2: Add the verification record to your domain host",
    "domain_validation_step3_label": "Step 3: Come back and verify",
    "done": "完成",
    "dont have an account? try control pod for free": "Don't have an account? Try Control Pod for FREE! 還沒有帳戶嗎？現在可以免費試用",
    "double-row": "Double Row",
    "download": "下載",
    "draft": "草稿",
    "drafts": "草稿",
    "due": "付款日期",
    "due date": "預定日期",
    "duplicate": "資料重複",
    "duplicate addresses": "地址重複",
    "duplicate product": "產品重複",
    "duplicate value": "資料重複",
    "duplicate waybills": "托運單重複",
    "duplicated contact data": "聯絡人資料已重複（自動連結時找到信箱有重複）",
    "duration": "持續時間",
    "each": "每個",
    "earliest": "最舊的",
    "earned points": "會員點數",
    "earned reward points": "獲得的會員點數",
    "ecPay": "綠界科技",
    "ecPay_eInvoice": "綠界科技: 電子發票",
    "ecPay_hash_iv": "Hash IV",
    "ecPay_hash_key": "Hash Key",
    "ecPay_merchant": "商家",
    "ecPay_merchant_id": "商家編號",
    "ecPay_payments": "ecPay_payments",
    "ecPay_virtualAccount": "ecPay_virtualAccount enabled",
    "edit": "編輯",
    "edit sales invoice": "Edit Sales Invoice",
    "edit sales order": "編輯訂單",
    "email": "電子信箱",
    "email delivery note": "寄出貨單電子信",
    "email document": "寄電子文件",
    "email message": "電子信箱內文",
    "email not sent": "無法發送電子信",
    "email placeholder": "語法",
    "email sent to": "電子信已發送",
    "email settings": "電子信箱設定",
    "email signature": "電子信箱簽名檔",
    "email subject": "電子信箱主旨",
    "email_canceled_order_default_subject": "已取消您的訂單",
    "email_dispatched_default_subject": "出貨通知",
    "email_event_delivered": "已發送",
    "email_event_failed": "發送錯誤",
    "email_event_opened": "已打開",
    "email_placeholder_desc": "您可以直接複製語法放到您想顯示的位置",
    "email_queued": "排程中",
    "email_refunded_order_default_subject": "已退刷",
    "emails": "電子信箱",
    "emails to send": "電子信發送數量",
    "employee": "員工",
    "empty": "空",
    "enable": "啟用",
    "enable facebook chat": "啟用Facebook聊天",
    "enable facebook chat by default": "預設開啟Facebook聊天功能",
    "enable facebook pixel conversion api": "Enable Facebook Pixel Conversion API",
    "enable line chat": "啟用Line聊天",
    "enable line chat by default": "預設開啟Line聊天功能",
    "enabled": "已啟用",
    "end": "止",
    "end date": "終止日期",
    "end of invoice month": "從月底開始算",
    "end of month": "月底",
    "end on": "止",
    "enquiry": "詢價單",
    "enter fullscreen": "進入全螢幕",
    "entered amount exceeds the outstanding amount": "輸入金額超過未付金額",
    "equals": "等於",
    "error message": "錯誤信息",
    "error occurred": "發生錯誤",
    "error occurred please try again later": "發生錯誤",
    "errors": "錯誤信息",
    "estimated earnings": "預計收益",
    "exact date": "指定日期",
    "example": "範例",
    "exclude shipping from sales history": "銷售紀錄排除運費",
    "excluding tax discount": "未稅折扣",
    "exclusive amount": "未稅額",
    "exclusive base cost": "抽成未稅底價",
    "exclusive discount": "未稅折扣",
    "exclusive price": "未稅單價",
    "exclusive selling price": "未稅售價",
    "exclusive total": "未稅總計",
    "exclusive total after discount": "折扣後未稅總計",
    "existing": "現有的",
    "exit fullscreen": "離開全螢幕",
    "expand sub menus": "永遠顯示子選單",
    "expected close date": "預計結束日期",
    "expected dispatch date": "預計出貨日期",
    "expenses": "開銷",
    "expire after days": "幾天後過期?",
    "expired": "已過期",
    "export": "匯出",
    "export HCT data": "匯出新竹物流資料",
    "export all data": "匯出全部已出貨",
    "export count": "匯出數量",
    "export data": "匯出資料",
    "export data and update status": "匯出及更新狀態",
    "export dispatched data": "匯出出貨資料",
    "export items by payment status": "匯出交易歷史和付款狀態",
    "export limited qtys": "匯出限制數量",
    "export method": "輸出方法",
    "export_all_transporters": "全部物流資料",
    "export_eInvoices_all": "電子發票(成功&作廢)",
    "export_eInvoices_cancelled": "電子發票(作廢)",
    "export_eInvoices_details": "電子發票&產品明細",
    "export_eInvoices_successful": "電子發票(成功)",
    "export_orders_details": "訂單產品明細",
    "export_orders_info": "訂單明細",
    "export_orders_per_row": "訂購商品每項一列排序",
    "export_orders_total": "訂單總表",
    "export_paid": "已收款",
    "export_paid-available": "已付款(現)",
    "export_paid-preOrder": "已付款(預)",
    "export_paid-total": "已付款(現+預)",
    "export_paid_export_orders_info": "已收款-訂單明細",
    "export_paid_export_transporter": "已收款-物流資料",
    "export_pay_on_delivery": "貨到付款",
    "export_pay_on_delivery_export_orders_info": "貨到付款-訂單明細",
    "export_pay_on_delivery_export_transporter": "貨到付款-物流資料",
    "export_preOrderLimitedQty": "原始預購庫存",
    "export_preOrderLimitedQty_remaining": "剩餘預購庫存",
    "export_salesLimitedQty": "原始現有庫存",
    "export_salesLimitedQty_remaining": "剩餘現有庫存",
    "export_total-available": "加總(現)",
    "export_total-preOrder": "加總(預)",
    "export_total-total": "加總(現+預)",
    "export_transporter": "物流資料",
    "export_unpaid-available": "未付款(現)",
    "export_unpaid-preOrder": "未付款(預)",
    "export_unpaid-total": "未付款(現+預)",
    "extensions": "擴充表單",
    "external": "外部",
    "facebook": "Facebook",
    "facebook chat": "Facebook聊天",
    "facebook login": "Facebook登入",
    "facebook page": "Facebook頁面",
    "facebook page inbox": "Facebook頁面訊息",
    "facebook page url": "Facebook網址",
    "facebook pixel": "Facebook Pixel",
    "facebook plugin": "Facebook聊天",
    "faqs": "常見問題",
    "faqs for product": "產品:常見問題",
    "faqs for shop": "商店:常見問題",
    "fax": "傳真",
    "features": "功能",
    "fees": "手續費",
    "female": "女",
    "fetching data": "正在捉取資料",
    "file name": "檔案名稱",
    "files manager": "Files Manager",
    "files_manager_tags": "Tags",
    "filters": "篩選",
    "final": "正式",
    "first name": "姓名",
    "first waybill number": "托運單第一號",
    "fiscal": "Fiscal",
    "flat fee": "固定運費",
    "floor": "下限",
    "for documents": "文件",
    "forgot your password": "忘記密碼?",
    "fourByThree": "4x3",
    "free": "免費",
    "free shipping condition": "免運金額門檻",
    "free shipping for orders greater and equal to": "訂單金額達多少免運",
    "frequently asked questions": "常見問題",
    "friday": "星期五",
    "friday opening hours": "星期五營業時間",
    "from": "從",
    "from day of order": "從下單日期開始",
    "from email address": "寄件人電子信箱",
    "from email name": "寄件人",
    "from end of invoice month": "From End Of Invoice Month",
    "from invoice date": "From Invoice Date",
    "from name": "寄件人",
    "from person": "發行人",
    "ftp password": "FTP 密碼",
    "ftp user": "FTP 使用者名稱",
    "full width": "全寬度",
    "full width video": "全寬度影片",
    "g": "公克",
    "gender": "性別",
    "general": "一般",
    "generate": "產生",
    "generate sales invoice data": "產生銷售資料",
    "generate settings": "產生設定",
    "get last price for all": "Get last price for all",
    "get last price for company": "Get last price for company",
    "get price set in products": "Get price set in products",
    "gjs-Attachment": "圖檔位置",
    "gjs-Background": "背景",
    "gjs-Background Color": "底色",
    "gjs-Blur": "模糊",
    "gjs-Border": "框線",
    "gjs-Border Color": "框線顏色",
    "gjs-Border Radius": "外框線弧度",
    "gjs-Border Style": "框線樣式",
    "gjs-Border Width": "框線寬度",
    "gjs-Bottom": "下",
    "gjs-Box Shadow": "外框陰影",
    "gjs-Center": "中",
    "gjs-Color": "文字顏色",
    "gjs-Dimension": "進階（調整尺寸）",
    "gjs-Font Family": "字型",
    "gjs-Font Size": "字體大小",
    "gjs-Height": "高",
    "gjs-Image": "圖片",
    "gjs-Images": "圖片",
    "gjs-Insert your text here": "請在這裡輸入文字",
    "gjs-Inside": "內側",
    "gjs-Justify": "調整",
    "gjs-Layer 1": "第一層",
    "gjs-Left": "左",
    "gjs-Margin": "外距（元素與元素之距離）",
    "gjs-Max-width": "最大寬度",
    "gjs-Min-height": "最小高度",
    "gjs-Opacity": "透明度",
    "gjs-Outside": "外側",
    "gjs-Padding": "內距（元素內容與元素邊界之距離）",
    "gjs-Position": "位置",
    "gjs-Repeat": "重複圖片",
    "gjs-Repeat-x": "水平重複填滿",
    "gjs-Repeat-y": "垂直重複填滿",
    "gjs-Right": "右",
    "gjs-Shadow type": "陰影樣式",
    "gjs-Size": "尺寸",
    "gjs-Spread": "粗度",
    "gjs-Style": "風格",
    "gjs-Text align": "對齊方式",
    "gjs-Top": "上",
    "gjs-Width": "寬",
    "gjs-X position": "橫座標",
    "gjs-Y position": "直座標",
    "gjs-auto": "自動",
    "gjs-box-shadow-color": "顏色",
    "gjs-center bottom": "下方置中",
    "gjs-center center": "置中",
    "gjs-center top": "上方置中",
    "gjs-contain": "原始大小",
    "gjs-cover": "填滿",
    "gjs-dashed": "虛線",
    "gjs-dotted": "點線",
    "gjs-double": "雙框線",
    "gjs-fixed": "固定",
    "gjs-left bottom": "坐下方",
    "gjs-left center": "左方置中",
    "gjs-left top": "左上方",
    "gjs-local": "原始位置",
    "gjs-non-Repeat": "不重複",
    "gjs-none": "無框線",
    "gjs-right bottom": "右下方",
    "gjs-right center": "右方置中",
    "gjs-right top": "右上方",
    "gjs-scroll": "滾動",
    "gjs-solid": "實線",
    "gjs-title": "標題",
    "greater than": "大於",
    "greater than or equal to": "大於或等於",
    "greaterThan": "大於",
    "greaterThanOrEqualTo": "大於或等於",
    "grid": "格線",
    "group by tags": "使用標籤分類",
    "has images": "是否有照片?",
    "has limit": "數量有限",
    "has surcharge on free shipping": "免運費但有附加費用",
    "has variants": "附加選項商品",
    "hasVariants": "附加選項商品",
    "hct contact": "新竹物流連絡資料",
    "hct summary": "新竹物流總表",
    "hct summary successfully submitted": "成功上傳新竹物流總表",
    "hct waybills": "新竹物托運單",
    "hct_region_error": "查無此區號，請檢查配送地址",
    "help": "說明",
    "hidden": "隱藏",
    "hide all": "全隱藏",
    "hide payouts details": "隱藏支出細節",
    "hold all": "全部暫停",
    "home": "首頁",
    "home fax": "住家傳真",
    "home page": "首頁",
    "home phone": "住家電話",
    "horizontal": "水平",
    "href": "連結",
    "image": "照片",
    "image link": "圖片連結",
    "images": "照片",
    "import": "匯入",
    "import data": "匯入資料",
    "import dispatched": "匯入已出貨",
    "import limited qtys": "匯入限制數量",
    "import paid": "匯入已付款",
    "import_transporter_template": "下載匯入物流資料表格",
    "inactive": "無效的",
    "inclTotal": "含稅總計",
    "including tax discount": "含稅折扣",
    "inclusive amount": "含稅額",
    "inclusive discount": "含稅折扣",
    "inclusive discounted price": "含稅折扣價",
    "inclusive price": "含稅單價",
    "inclusive selling price": "含稅售價",
    "inclusive total": "含稅總計",
    "inclusive total after discount": "折扣後含稅總計",
    "inclusive total after returns": "折讓後含稅總計",
    "inclusive_total_short": "含稅總計",
    "income statement": "收入狀態",
    "incorrect": "輸入錯誤",
    "incorrect array order": "輸入錯誤",
    "incorrect array order and/or incorrect number of items": "輸入錯誤",
    "incorrect byte": "輸入錯誤",
    "incorrect bytes": "輸入錯誤",
    "incorrect date": "輸入日期錯誤",
    "incorrect date format": "輸入日期格式錯誤",
    "incorrect date range": "輸入日期範圍不正確",
    "incorrect decimal places": "輸入錯誤，須為數值，小數位不正確",
    "incorrect length": "輸入行長度錯誤",
    "incorrect number": "輸入錯誤，須為數值",
    "incorrect number of items": "輸入錯誤",
    "incorrect total": "金額不符",
    "incorrect uri scheme": "輸入錯誤，網址格式有誤",
    "increase": "增加",
    "increase/decrease limited qty": "+/- 限制數量",
    "increase/decrease preOrder qty": "+/- 預購數量",
    "index": "編號",
    "individual": "個人",
    "insert": "插入",
    "instagram url": "Instagram網址",
    "invalid": "錯誤",
    "invalid image type": "照片格式錯誤",
    "invalid number of days": "無效的日數",
    "invalid number of months": "無效的月數",
    "invalid password": "密碼錯誤",
    "invalid rate": "無效費率",
    "invalid status": "狀態錯誤",
    "invalid username or password": "使用者名稱或密碼錯誤",
    "inventory": "庫存",
    "inventory activity": "庫存歷史",
    "inventory adjustment": "庫存調整",
    "inventory available": "可用庫存",
    "inventory book qty": "帳面庫存量",
    "inventory in": "入庫",
    "inventory management": "庫存管理",
    "inventory on hand": "現有庫存",
    "inventory on hand qty": "現有庫存量",
    "inventory out": "出庫",
    "inventory qty": "庫存量",
    "inventory report": "列印庫存表",
    "inventory status report": "庫存狀態",
    "invoice": "發票",
    "invoice date": "從發票開立日期",
    "invoice description": "發票產品名稱",
    "invoice number": "發票號碼",
    "invoices": "統一發票",
    "is associates": "合作夥伴",
    "is between": "之間",
    "is member": "已是會員",
    "is not allowed": "輸入錯誤",
    "is product set": "Is Product Set",
    "is transporter": "物流公司",
    "is unlimited": "不限次數",
    "isBetween": "之間",
    "isComplete": "完成",
    "isIncomplete": "未完成",
    "isPinned": "已置頂",
    "isSet": "組合商品",
    "isSetChild": "組合商品子項",
    "isSetWithChoices": "任選商品",
    "isVariantChild": "產品變體子項",
    "issue invoice": "開立統一發票",
    "issue invoices now": "立即開立電子發票",
    "item": "品項",
    "item quantity": "產品數量",
    "item value": "產品單價",
    "item was not invoiced": "未開發票商品",
    "itemQuantity": "產品數量",
    "itemValue": "產品單價",
    "items": "品項",
    "items total price is not equal to the set price": "商品總價格不等於組合價格！",
    "join business": "Join Business",
    "join with": "連接",
    "kg": "公斤",
    "label": "標籤",
    "label for transporter": "給物流公司的備註",
    "language is now set to": "現在設定的語言是",
    "last 4 digits": "後四碼",
    "last month": "上個月",
    "last name": "姓氏",
    "last waybill number": "托運最後一號",
    "latest": "最新的",
    "latest customers": "最新的顧客",
    "latest suppliers": "最新的供應商",
    "launch": "啟動",
    "lead time": "交貨時間",
    "ledger": "分類帳戶",
    "ledgers": "分類帳戶",
    "left": "左側",
    "less than": "小於",
    "less than or equal to": "小於或等於",
    "lessThan": "小於",
    "lessThanOrEqualTo": "小於或等於",
    "limit by": "限制方式",
    "limit preOrder qty": "限制預購數量",
    "limited preOrder qty": "預購數量有限",
    "limited qty": "限制數量",
    "limited qty per person": "每人限制數量",
    "limited sales qty": "限制銷售數量",
    "line chat": "Line聊天",
    "line id": "Line帳號",
    "link": "連結",
    "linked to": "連結到",
    "list all": "全部列出",
    "list all abandoned": "列出所有被廢棄的",
    "loading": "載入中",
    "locked": "鎖住",
    "log into new company?": "您確定要登入 [ {company} ]? 會更新頁面",
    "login": "登入",
    "logo": "商標",
    "logo size": "Logo大小",
    "logout": "登出",
    "mailing list": "電子報",
    "main": "主要的",
    "main account number": "主要帳戶",
    "male": "男",
    "manage duplicates": "合併",
    "mark as completed": "已完成",
    "mark as paid": "標記為已付款",
    "match_free_shipping_any": "任何產品",
    "match_free_shipping_for": "當",
    "match_free_shipping_only": "只當購物車內有商品時",
    "max order price": "最高訂單金額",
    "mayAdd": "顯示其他產品",
    "member": "會員",
    "member email": "會員電子信箱",
    "member level": "會員等級",
    "member level description": "會員等級敘述",
    "member level expiry time": "會員等級到期時間",
    "member level name": "會員等級名稱",
    "member level point required": "所需點數",
    "member level points": "會員等級累積點數",
    "member level status": "狀態",
    "member level will be adjusted to": "會員等級將會調整為",
    "member levels": "會員等級",
    "members": "會員",
    "menu": "目錄",
    "menu font size": "主選單字體大小",
    "menu position": "主選單位置",
    "menu style": "主選單格式",
    "merge": "合併",
    "merge_document": "併單",
    "merged": "已合併",
    "merged_document": "已併單",
    "message": "訊息",
    "message to display when campaign has ended": "活動結束顯示的消息",
    "messages": "訊息",
    "method": "方法",
    "min order price": "最低訂單金額",
    "minimum cart amount": "折扣碼最低購買額度",
    "minimum spend requirement": "使用會員點數最低消費金額",
    "missing data": "資料錯誤或不足",
    "mm": "公釐",
    "mm1": "一",
    "mm10": "十",
    "mm11": "十一",
    "mm12": "十二",
    "mm2": "二",
    "mm3": "三",
    "mm4": "四",
    "mm5": "五",
    "mm6": "六",
    "mm7": "七",
    "mm8": "八",
    "mm9": "九",
    "mobile": "手機",
    "monday": "星期一",
    "monday opening hours": "星期一營業時間",
    "month": "月",
    "month end": "月底",
    "month1": "一月",
    "month10": "十月",
    "month11": "十一月",
    "month12": "十二月",
    "month2": "二月",
    "month3": "三月",
    "month4": "四月",
    "month5": "五月",
    "month6": "六月",
    "month7": "七月",
    "month8": "八月",
    "month9": "九月",
    "monthly revenue": "每月營業額",
    "months": "月",
    "more": "更多",
    "multiple": "多次",
    "multiple delivery dates": "多次出貨日期",
    "multiple discount rate": "多重折扣率",
    "multiple discounts": "多次折扣",
    "multiple documents found. please capture manually": "找到多筆文件，請人工選取",
    "must be a bigger number": "輸入錯誤，必須是一個更大的數字",
    "must be a boolean": "輸入錯誤",
    "must be a buffer or a string": "輸入錯誤",
    "must be a credit card": "必須是信用卡號",
    "must be a float or double": "輸入錯誤，須為數值，可有小數點",
    "must be a negative number": "輸入錯誤，須為數值，必須是負數",
    "must be a number": "輸入錯誤，須為數值",
    "must be a positive number": "輸入錯誤，須為數值，必須是正數",
    "must be a smaller number": "輸入錯誤，必須是一個更小的數字",
    "must be a string": "輸入錯誤，須為字串",
    "must be a valid GUID": "必須是有效的GUID",
    "must be a valid ISO 8601 date": "必須是有效的ISO 8601日期",
    "must be a valid base64 string": "必須是有效的base64字串",
    "must be a valid date": "輸入日期格式錯誤",
    "must be a valid email": "輸入錯誤，電子信箱格式不正確",
    "must be a valid hostname": "必須是有效的主機名",
    "must be a valid ip address": "必須是有效的IP地址",
    "must be a valid port": "輸入錯誤，必須是有效的連接埠",
    "must be a valid relative uri": "輸入錯誤，必須是網址",
    "must be a valid timestamp or number of milliseconds": "輸入錯誤，時間格式須為毫秒數",
    "must be a valid timestamp or number of second": "輸入錯誤，時間格式須為秒數",
    "must be a valid uri": "輸入錯誤，必須是有效的網址",
    "must be an array": "輸入錯誤",
    "must be an integer": "輸入錯誤，須為數值，不可有小數點",
    "must be unicode normalized": "必須是unicode",
    "must exclude value": "輸入錯誤",
    "must not have leading or trailing whitespace": "前後不可有空格",
    "must only contain alpha-numeric and underscore characters": "輸入錯誤，只可包含字母、數字與底線",
    "must only contain alpha-numeric characters": "輸入錯誤，只可包含字母與數字",
    "must only contain hexadecimal characters": "必須只包含十六進制字串",
    "must only contain uppercase characters": "只能大寫字母",
    "mustAdd": "自動將產品添加到購物車",
    "my businesses": "My Businesses",
    "my profile": "我的資料",
    "name": "名稱",
    "need points to reach level": "仍須 {points} 點數達到",
    "never expire": "無限期",
    "new": "新增",
    "new account": "新帳戶",
    "new bulk discount": "New Bulk Discount",
    "new customer": "新增客戶",
    "new customer and supplier": "新增客戶及供應商",
    "new link": "新連結",
    "new member level": "新會員等級",
    "new orders": "新訂單",
    "new page": "新頁面",
    "new private": "New Private",
    "new product": "新產品",
    "new products editor": "新產品編輯模式",
    "new sales invoice": "New Sales Invoice",
    "new sales order": "新增訂單",
    "new shipping zone": "新增配送區域",
    "new supplier": "新增供應商",
    "new total must be equal to the original document total": "新總價不等於原始總價",
    "new window": "新視窗",
    "news": "最新消息",
    "next": "下一頁",
    "next active": "下一個進行的活動",
    "next step": "下一步",
    "next step conversion rate": "下一步轉換率",
    "next workflow": "下個工作流程",
    "night": "晚上",
    "no": "否",
    "no active schedule": "時間表上沒有正在進行的活動",
    "no comments": "無留言",
    "no commission this month": "本月無抽成！",
    "no data": "無資料",
    "no data to export": "無匯出資料",
    "no details": "無詳細資料",
    "no dispatched data": "無已出貨資料",
    "no emails to send": "無發送電子信",
    "no products added yet": "未選擇產品",
    "no products found, double click to refresh or add new products": "找不到產品，若已加入產品請點擊兩次更新畫面或加入新產品",
    "no reward expiry set": "未設到期時間",
    "no reward points set": "未設要發送的點數",
    "no tax allowed": "不允許有稅",
    "no_of_documents_unit": "筆",
    "no_of_times": "次",
    "none": "無",
    "noon": "下午",
    "normal percentage": "一般折數百分比",
    "not a day of the month (1-31)": "不正確的日數 (1-31)",
    "not a url": "網址格式錯誤",
    "not an email": "電子郵件格式錯誤",
    "not date": "須填日期",
    "not dispatched": "未出貨",
    "not email": "電子郵件格式錯誤",
    "not equals": "不等於",
    "not float": "請輸入數字",
    "not found": "查無此項",
    "not matching any of the allowed alternatives": "輸入錯誤",
    "not member": "非會員",
    "not unique": "資料重複",
    "not yet saved": "未儲存",
    "not yet set": "未設定",
    "notEquals": "不等於",
    "note": "備註",
    "note is required on checkout": "顧客注意事項為必填",
    "notes": "備註",
    "notifications": "通知",
    "number": "號碼",
    "number of days": "Number Of Days",
    "number of months": "月數",
    "number of orders": "訂單數量",
    "number of results": "搜尋數量",
    "number of selections": "選擇的數量",
    "number of transactions": "交易數量",
    "off": "關",
    "office fax": "辦公室傳真",
    "office phone": "公司電話",
    "offline": "離線中",
    "old exclusive price": "舊的未稅價格",
    "old inclusive price": "舊的含稅價格",
    "on": "開",
    "on hand qty": "現有庫存量",
    "on hold": "暫停",
    "on sale": "特價中",
    "on sale schedules": "特價時間表",
    "on set date": "在設定日期",
    "on total": "總額計算",
    "onHold": "暫停",
    "once off": "一次性",
    "oneByOne": "1x1 (正方形)",
    "online": "上線中",
    "only include items with sales activity": "隱藏無交易產品",
    "only show shipping description, rate is quoted after order": "只顯示配送資訊，運費會在下單後報價",
    "open hours": "營業時間",
    "open_tab": "開新分頁",
    "opening balance": "期初盈餘",
    "opening inventory book qty": "現有庫存量",
    "opening inventory book value": "現有庫存價值",
    "opening inventory on hand qty": "帳面庫存量",
    "opening inventory on hand value": "帳面庫存價值",
    "optional": "非必填",
    "options": "選項",
    "order": "訂單",
    "order #": "訂單 #",
    "order canceled email": "已取消通知信",
    "order date": "訂購日期",
    "order dispatched email": "訂單出貨通知信",
    "order number": "訂單號碼",
    "order success email": "訂單成功信",
    "order summary": "訂單明細",
    "order value": "訂單總計",
    "orderValue": "訂單總計",
    "ordered": "訂購",
    "ordered date": "訂購日期",
    "ordered qty": "訂購數量",
    "ordered_exclusive_total": "訂購未稅額",
    "ordered_inclusive_total": "訂購含稅額",
    "orders": "訂單",
    "orders by date": "依訂購日期",
    "original commission base cost": "原始佣金成本基數",
    "original commission total amount": "原始佣金總數",
    "original document total": "原始總價",
    "original inclusive price": "含稅原價",
    "original total commission": "原始總佣金",
    "other": "其他",
    "other income": "其他收入",
    "out of stock": "缺貨中",
    "outOfStock": "缺貨中",
    "outstanding": "未出貨",
    "outstanding qty": "未出貨數量",
    "outstanding total": "未付總金額",
    "outstanding_exclusive_total": "未交未稅額",
    "outstanding_inclusive_total": "未交含稅額",
    "overdue commission": "逾期抽成",
    "overview": "總覽",
    "packages": "件數",
    "packages_short": "件數",
    "packaging_dimensions": "包裝尺寸",
    "packaging_dimensions_unit_default": "預設包裝尺寸單位",
    "page": "頁",
    "page is hidden on menu": "在主選單隱藏",
    "page url": "頁面網址",
    "page views": "瀏覽量",
    "page views per person": "每人平均瀏覽量",
    "paid": "已付款",
    "passed allowed refund period": "已超過可退刷日期",
    "password": "密碼",
    "password (at least 8 characters)": "密碼（至少8個字符）",
    "payment": "付款",
    "payment amount": "付款金額",
    "payment date": "付款日期",
    "payment due": "付款日期",
    "payment due date": "付款日期",
    "payment last 5 digits": "後五碼",
    "payment link": "付款連結",
    "payment method": "付款方式",
    "payment methods": "付款方式",
    "payment reminder email": "付款提醒信",
    "payment reminder email message": "付款提醒電子郵件內容",
    "payment reminder email subject": "付款提醒電子郵件主旨",
    "payment reminders": "付款提醒",
    "payment status": "付款狀態",
    "payment terms": "付款條件",
    "payments": "付款",
    "payout": "撥款",
    "payouts this month": "本月支出",
    "pending": "未完成",
    "pending points": "待定點數",
    "pending qty": "未出庫存",
    "pendingCancellation": "取消處理中",
    "per line": "個別計算",
    "percentage of price": "價格的百分比",
    "person": "聯絡人",
    "person + contact": "聯絡人 + 顧客",
    "person name": "聯絡人姓名",
    "persons": "聯絡人",
    "persons_count": "人量",
    "phone": "電話",
    "pick a color theme": "選擇一個顏色主題",
    "pick up": "自取",
    "pick up locations": "自取地址",
    "picking list": "檢貨單",
    "pin": "置頂",
    "pinned": "已置頂",
    "pixel id": "Pixel ID",
    "plan": "方案",
    "please select": "請選擇",
    "point": "點數",
    "points": "點數",
    "points to be earned": "要發送的點數",
    "position": "職稱",
    "post office": "郵局",
    "postal codes": "郵遞區號",
    "power": "權力用戶",
    "pre order": "預購中",
    "preOrder": "預購中",
    "preferred delivery time": "偏好的送達時段",
    "prepare for dispatch": "準備出貨",
    "preparing": "準備中",
    "preset commissions": "預設抽成",
    "preview": "預覽",
    "preview merge": "預覽合併",
    "previous": "上一頁",
    "price": "單價",
    "price (for accounting purpose)": "價格（作帳用）",
    "price range": "Price Range",
    "pricing": "定價",
    "print": "列印",
    "print all": "列印全部",
    "print all delivery notes": "列印全部出貨單",
    "print all eInvoices": "開立全部電子發票",
    "print all invoices": "列印全部統一發票",
    "print all waybills": "列印全部托運單",
    "print delivery note": "列印出貨單",
    "print hct summary": "列印新竹物流總表",
    "print invoice": "列印統一發票",
    "print invoices": "列印統一發票",
    "print picking list": "列印檢貨單",
    "print waybill": "列印托運單",
    "print waybills starting position": "開始列印位置",
    "print_delivery_note_prices": "列印請款單",
    "printer error, please check printer connection": "列印錯誤",
    "private": "Private",
    "private templates": "我的模板",
    "process_order": "排序",
    "processing": "處理中",
    "product": "產品",
    "product code": "編碼",
    "product decimal places": "產品尾數",
    "product grouping": "產品分類",
    "product images": "產品照片",
    "product info": "產品資料",
    "product information": "產品介紹",
    "product limits": "產品限制數量",
    "product price rounding": "產品價格尾數",
    "product schedules": "產品上架時間表",
    "product set": "組合商品",
    "product set with choices": "任選商品",
    "product sets": "組合商品",
    "product status": "商品狀態",
    "product tags": "產品標籤",
    "product type": "產品種類",
    "product_description": "產品規格",
    "product_general": "一般",
    "product_set": "組合商品",
    "product_set_price": "組合價格",
    "product_sets": "組合商品",
    "product_sets_contents": "組合內容",
    "product_sets_with_choices": "任選商品",
    "product_variants_contents": "附加選項商品內容",
    "products": "產品",
    "products images ratio": "產品圖片長寬比例",
    "products overview name": "商店預覽名稱",
    "products overview style": "商店預覽風格",
    "profile": "簡介",
    "promo code": "優惠代碼",
    "promo code (at least 6 characters)": "優惠代碼(至少6個字符)",
    "promo codes": "優惠代碼",
    "promotion": "促銷",
    "promotion email": "促銷信件",
    "provider": "平台",
    "public status": "資料公開狀態（客戶可看見）",
    "public templates": "預設模板",
    "purchase price setting": "Purchase Price Setting",
    "purchases": "進貨",
    "purchases consignment": "進項寄賣",
    "purchases consignment overview": "進項寄賣槪要",
    "purchases consignment receipt": "寄賣進貨單",
    "purchases consignment return": "進項寄賣退貨單",
    "purchases enquiry": "詢價單",
    "purchases invoice": "進貨單",
    "purchases order": "採購單",
    "purchases price history": "進價歷史",
    "purchases return": "進貨折讓單",
    "purchases terms": "進貨條件",
    "purchases-consignments": "寄賣進貨單",
    "purchases-consignments-returns": "進項寄賣退貨單",
    "purchases-invoices": "進貨單",
    "purchases-orders": "採購單",
    "purchases-pending": "未交總表",
    "purchases-pending: display tax method": "進貨未交總表: 顯示未稅或含稅",
    "purchases-purchases-consignments": "進貨: 寄賣進貨單",
    "purchases-purchases-consignments-returns": "進貨: 進項寄賣退貨單",
    "purchases-purchases-invoices": "進貨: 進貨單",
    "purchases-purchases-orders": "進貨: 採購單",
    "purchases-purchases-quotes": "進貨: 詢價單",
    "purchases-purchases-returns": "進貨: 進貨折讓單",
    "purchases-quotes": "詢價單",
    "purchases-returns": "進貨折讓單",
    "purchases: on consignment": "購買：寄賣",
    "purchases: on consignment qty": "購買：寄賣量",
    "purchases_delivered_exclusive_total": "已進貨未稅額",
    "purchases_delivered_inclusive_total": "已進貨含稅額",
    "purchasing exclusive price": "進貨未稅價",
    "purchasing inclusive price": "進貨含稅價",
    "qty": "數量",
    "qty sold": "售出數量",
    "quantity": "數量",
    "query": "查詢",
    "question": "問題",
    "questions": "問題",
    "queued": "排程中",
    "quick add": "快速增加",
    "quickTip": "小提示",
    "quote": "報價單",
    "rate": "費率",
    "ratio_original": "原始",
    "re-index all products": "重新排序所有產品",
    "re-login": "重新登入",
    "re-order qty": "再訂購數量",
    "reason": "理由",
    "receipt": "收款",
    "receipts": "收款",
    "record commissions": "紀錄抽成",
    "ref": "參照編號",
    "reference": "參照編號",
    "referenced field is not a date": "輸入日期格式錯誤",
    "referenced field is not a number": "輸入錯誤，須為數值",
    "refresh": "重整",
    "refund": "退款",
    "refund amount": "退款金額",
    "refund credit card": "信用卡退刷",
    "refund credit card total": "信用卡退刷總計",
    "refunds": "退款",
    "regenerate document number": "regenerate document number",
    "region": "地區",
    "region code": "地區代碼",
    "regions": "地區",
    "related": "相關的",
    "relationship": "關係",
    "relationships": "關係",
    "remove": "刪除",
    "remove all row": "刪除橫列",
    "remove cell": "刪除儲存格",
    "replace": "更換",
    "report": "報表",
    "report password": "報表密碼",
    "reports": "報表",
    "required": "必填",
    "resend": "重寄",
    "reset commission base costs value": "重設抽成成本基數金額",
    "resource": "資源",
    "results": "搜尋結果",
    "return": "退貨",
    "return qty": "退貨數量",
    "returns": "退貨",
    "returns application email": "退貨申請信",
    "returns inclusive total": "折讓含稅總計",
    "revenue": "營業額",
    "revenue by campaign": "商店營業額",
    "revenue by channel": "通路營業額",
    "revenue by contact": "顧客營業額",
    "revenue by day": "每日營業額",
    "revenue by month": "每月營業額",
    "revenue by payment method": "付款方式營業額",
    "revenue by product": "產品營業額",
    "revenue by promo code": "優惠代碼營業額",
    "revenue for campaign": "商店營業額",
    "revenue for month": "本月營業額",
    "revenue for year": "本年營業額",
    "revert to draft": "回覆成草稿",
    "reward": "獎勵",
    "reward earning rate from total order": "會員點數為訂單總價 {rate}％",
    "reward event": "獎勵類型",
    "reward event allow on sale": "允許特價",
    "reward event allow with promo code": "允許使用折扣碼",
    "reward event earning": "購物點數（買多少送多少點數）",
    "reward event expiry": "到期時間",
    "reward event max deduction": "每筆最高折抵金額",
    "reward event rate per Point": "每點點數兌換率（每點點數換多少購物金）",
    "reward event requirement": "獎勵最低購物金額（買多少才有購物點數）",
    "reward event start": "起始時間",
    "reward event status": "狀態",
    "reward event type": "獎勵類型",
    "reward history": "獎勵歷史紀錄",
    "reward name": "獎勵名稱",
    "reward notification email": "獎勵通知信",
    "reward points": "會員點數",
    "rewardInclTotal": "含稅購物金",
    "reward_adjustment": "調整",
    "reward_birthday": "生日",
    "reward_opening": "期初點數",
    "reward_order": "訂單",
    "reward_promotion": "促銷",
    "reward_signup": "註冊",
    "rewards": "獎勵",
    "rewards inclusive total": "含稅購物金",
    "rewards used": "會員點數",
    "right": "右側",
    "role": "角色",
    "row": "Row",
    "sales": "銷貨",
    "sales consignment": "銷項寄賣",
    "sales consignment delivery": "寄賣出貨單",
    "sales consignment overview": "銷項寄賣槪要",
    "sales consignment return": "銷項寄賣退貨單",
    "sales invoice": "出貨單",
    "sales order": "銷貨訂單",
    "sales person": "業務人員",
    "sales persons": "業務人員",
    "sales price history": "售價歷史",
    "sales quote": "報價單",
    "sales report": "銷售報表",
    "sales return": "銷貨折讓單",
    "sales terms": "銷貨條件",
    "sales-consignments": "寄賣出貨單",
    "sales-consignments-returns": "銷項寄賣退貨單",
    "sales-invoices": "出貨單",
    "sales-orders": "銷貨訂單",
    "sales-pending": "銷貨: 未交總表",
    "sales-pending: display tax method": "銷貨未交總表: 顯示未稅或含稅",
    "sales-quotes": "進貨: 報價單",
    "sales-returns": "銷貨折讓單",
    "sales-sales-consignments": "銷售: 寄賣出貨單",
    "sales-sales-consignments-returns": "銷售: 銷項寄賣退貨單",
    "sales-sales-invoices": "銷售: 出貨單",
    "sales-sales-orders": "銷售: 銷貨訂單",
    "sales-sales-quotes": "銷售: 報價單",
    "sales-sales-returns": "銷售: 銷貨折讓單",
    "sales: on consignment": "銷售：寄賣",
    "sales: on consignment qty": "銷售：寄賣量",
    "sales_delivered_exclusive_total": "已出貨未稅額",
    "sales_delivered_inclusive_total": "已出貨含稅額",
    "same as date end": "同結束日期",
    "saturday": "星期六",
    "saturday opening hours": "星期六營業時間",
    "save": "儲存",
    "save and update next invoice number": "儲存更新下一個發票號碼",
    "save as color template": "存為顏色模板",
    "save as copy": "另存為副本",
    "save as draft": "儲存成草稿",
    "save as template": "存為模板",
    "save as void": "儲存成作廢",
    "saved": "已儲存",
    "schedule": "排程",
    "schedule status": "活動狀態",
    "scheduled": "排程",
    "scheduled_tasks": "預定任務",
    "scheduled_time": "排程時間",
    "schedules": "排程",
    "search": "搜尋",
    "search filters": "搜尋篩選",
    "search images": "搜尋照片",
    "search products": "搜尋產品",
    "search reference": "搜尋參照編號",
    "search reward name": "搜尋獎勵名稱",
    "search shipping zones": "搜尋配送區域",
    "select": "選擇",
    "select all": "全選",
    "select all main island": "選取所有本島地區",
    "select all outlying": "選取所有偏遠地區",
    "select company": "選擇公司",
    "select dispatch date": "選擇出貨日",
    "select images": "選擇圖片",
    "select reward program first": "先選取獎勵計劃",
    "selected items": "已選擇",
    "selected orders": "已選擇的訂單",
    "selling exclusive price": "銷貨未稅價",
    "selling inclusive price": "銷貨含稅價",
    "selling price setting": "Selling Price Setting",
    "send": "發送",
    "send a copy of order email to address": "電子郵件的副本發送地址",
    "send all dispatched emails": "發送所有出貨通知信",
    "send all emails": "發送所有電子信",
    "send birthday reward": "寄送生日獎勵",
    "send birthday rewards manually": "手動寄送生日獎勵",
    "send dispatched email": "發送已出貨通知信",
    "send email": "寄電子信",
    "send email message": "寄電子信",
    "send payment reminder when overdue by": "逾期時發送付款提醒",
    "send reward": "發送獎勵",
    "send reward manually": "手動寄送獎勵",
    "send void email": "發送已取消通知信",
    "service": "服務",
    "set as bundle": "設總價",
    "set as default": "設為預設",
    "set as default business": "設為預設公司",
    "set as default for": "設為預設",
    "set commission base cost": "設定抽成底價",
    "set commission total directly": "直接設定抽成總金額",
    "set default": "設為預設值",
    "set period": "選擇期間",
    "set prices later": "現不要設定價格",
    "set prices now": "立即設定價格",
    "set shipping zones": "設定配送區域",
    "setTypes": "組合種類",
    "settings": "設定",
    "shipping": "運輸",
    "shipping default payment method": "預設付款方式",
    "shipping fee": "運費",
    "shipping fee type": "運費種類",
    "shipping payment method": "付款方式",
    "shipping rate": "運費費率",
    "shipping type": "運送類型",
    "shipping zone": "配送區域",
    "shipping zone details": "配送區域細節",
    "shipping zones": "配送區域",
    "shipping_multiple": "額外運費",
    "shipping_single": "運送區域",
    "shop": "商店",
    "shop name": "商店名稱",
    "shop settings": "商店設定",
    "shop url": "商店網址",
    "shop url (at least 6 characters)": "商店網址 (至少6個字符)",
    "shop url prefix": "商店網址開頭",
    "shop_products_overview_style_condensed": "已壓縮",
    "shop_products_overview_style_full": "原圖",
    "short description": "重點描述",
    "show all": "顯示所有",
    "show all colors": "顯示所有顏色",
    "show available quantity": "顯示剩餘數量",
    "show bot": "顯示機器人",
    "show countdown timer": "顯示倒數計時器",
    "show discounted price vs original price": "顯示折扣價格與原始價格",
    "show label \"more\" on product": "顯示「...更多...」",
    "show label more": "顯示「...更多...」",
    "show less": "檢視更少",
    "show limited qty": "顯示限制數量",
    "show logo on menu": "在主選單顯示Logo",
    "show more": "檢視更多",
    "show number of lines": "顯示描述行數",
    "show original price": "顯示原價",
    "show page on menu": "在主選單顯示",
    "show payouts details": "顯示支出細節",
    "show prices": "顯示價格",
    "show product description": "顯示產品重點描述",
    "show product info": "顯示產品資料",
    "show quick nav": "快速結帳按鈕(左下角)",
    "show returns": "顯示折讓",
    "show sales quantity": "顯示銷售數量",
    "show shipping options on dispatching": "顯示運輸方式",
    "show shipping waybills on dispatching": "顯示付款方式",
    "show tags on filter": "在篩選清單顯示標籤",
    "show tags on page": "在頁面顯示標籤",
    "show website name on menu": "在主選單顯示官網名稱",
    "sign up": "註冊",
    "signature": "簽名",
    "signup": "註冊",
    "signup reward email": "註冊會員獎勵通知信",
    "simple": "Simple",
    "single delivery date": "單次出貨日期",
    "single discount": "單一折扣",
    "single discount rate": "單一折扣率",
    "slider": "圖片輪播",
    "social links": "社群媒體連結",
    "sold out": "已售完",
    "sold qty": "已售出數量",
    "sold qty (to invoice)": "已售出數量(未紀錄)",
    "soldOut": "已售完",
    "some": "部分",
    "sort by": "排序方式",
    "sort customer order using shop product index": "依照商品上架順序排序顧客訂單",
    "sort direction": "排序方向",
    "spend reward points": "套用會員點數",
    "split": "拆單",
    "split qty": "拆單數量",
    "split_document": "拆單",
    "split_shipping": "拆單",
    "stage": "階段",
    "stages": "階段",
    "start": "從",
    "start date": "起始日期",
    "start from": "從",
    "startup": "啟動",
    "statement": "對帳表",
    "stats for campaign": "活動數據",
    "stats for month": "本月",
    "stats for year": "本年",
    "status": "狀態",
    "status for when over sales limited qty": "當已售出數量超過限制銷售數量狀態",
    "step": "步驟",
    "sticky top": "置頂",
    "store location": "實體通路",
    "store locations": "實體通路",
    "string": {
      "max": "max length {limit}"
    },
    "sub account number": "次要帳戶",
    "sub page": "子頁面",
    "subject": "主旨",
    "submit hct summary": "上傳新竹物流總表",
    "subtotal": "小計",
    "successful": "成功",
    "sunday": "星期日",
    "sunday opening hours": "星期日營業時間",
    "super": "超級用戶",
    "supplier": "供應商",
    "supplier account": "供應商帳號",
    "supplier activity": "供應商歷史",
    "supplier payment method": "進貨付款方式",
    "supplier payment terms": "進貨付款條件",
    "supplier reconciliations": "供應商對帳表",
    "surcharge": "附加費用",
    "surcharge type": "附加費用類型",
    "surcharge_floor": "下限",
    "suspended": "暫停",
    "systemlead": "e首發票",
    "systemlead_companyId": "Company ID",
    "systemlead_remind": "低於一定數量時通知",
    "systemlead_remindCount": "當字軌配號低於一定數量時，發送通知給您",
    "systemlead_salt": "Salt",
    "systemlead_showZeroValueItems": "在電子發票顯示0元產品",
    "tab name": "頁籤",
    "tabs": "頁籤",
    "tag": "標籤",
    "tags": "標籤",
    "taiwan percentage": "台灣折數百分比",
    "tapPay": "Tap Pay",
    "tapPay_3ds": "3D驗證",
    "tapPay_merchant_id": "商家編號",
    "tapPay_partner_key": "Partner Key",
    "target": "目標視窗",
    "task": "任務",
    "tax": "稅",
    "tax %": "稅 %",
    "tax after discount": "折扣後稅",
    "tax allowed": "允許稅率",
    "tax exclusive": "未稅",
    "tax inclusive": "含稅",
    "tax method": "計稅方式",
    "tax number": "統編",
    "tax on purchases": "進貨稅",
    "tax on sales": "銷貨稅",
    "tax rate": "稅率",
    "tax_auto": "自動",
    "tax_exclusive": "未稅",
    "tax_inclusive": "含稅",
    "tax_none": "無",
    "taxes": "稅",
    "tel": "電話",
    "telephone": "電話",
    "template": "內文",
    "terms": "交易條件",
    "text": "內文",
    "themeGeneralLetterSpacing": "總體文字間隔",
    "themeHeaderH1LetterSpacing": "H1標題文字間隔",
    "themeHeaderH2LetterSpacing": "H2標題文字間隔",
    "themeHeaderH3LetterSpacing": "H3標題文字間隔",
    "themeHeaderH4LetterSpacing": "H4標題文字間隔",
    "themeHeaderH5LetterSpacing": "H5標題文字間隔",
    "themeHeaderH6LetterSpacing": "H6標題文字間隔",
    "themeNavTreeItemMarginX": "主選單子元素水平方向外距",
    "themeNavTreeItemPaddingX": "主選單子元素水平方向內距",
    "themes": "設定",
    "this may already have been dispatched on a previous day- are you sure you want to change its dispatch status": "這可能已經在前一天出貨過了。您確定要更改其發送狀態嗎？",
    "this month": "本月",
    "this product set is outdated": "這個組合商品已經過期",
    "this product set is outdated, click here to change it to the new type (set item prices individually)": "這個組合商品已經過期，請點擊這裡以更新資訊（分別設定各項商品價格",
    "this window": "本視窗",
    "threeByOne": "3x1",
    "threw an error when running default method": "輸入錯誤",
    "thursday": "星期四",
    "thursday opening hours": "星期四營業時間",
    "time": "時間",
    "title": "標題",
    "to": "收方",
    "to issue e-invoices": "可開立",
    "to print": "列印",
    "to send": "可送出",
    "to send birthday": "寄送生日獎勵",
    "to send promotion": "寄送促銷獎勵",
    "today": "今日",
    "toggles_no": "沒有",
    "toggles_yes": "有",
    "too long": "太長",
    "total": "總計",
    "total active contacts": "全部活躍的聯絡人",
    "total adjustment": "總調整",
    "total amount paid": "已付款金額",
    "total commission": "總抽成",
    "total commission base cost": "總抽成成本基數",
    "total commissions due": "應付總抽成",
    "total commissions due returned": "應付總退貨抽成",
    "total commissions due this month": "本月到期總抽成",
    "total commissions generated": "抽成總額",
    "total commissions generated returned": "總退貨抽成",
    "total commissions generated this month": "本月產生總抽成",
    "total credit card amount": "總信用卡金額",
    "total due after adjustment": "調整後總應付抽成",
    "total due after payouts": "支出後總應付抽成",
    "total inclusive discounted price": "折扣後含稅總價",
    "total inclusive price": "含稅總價",
    "total limited qty": "總限制數量",
    "total orders": "總訂單",
    "total returns": "總退貨",
    "total revenue": "總營業額",
    "total sales": "總銷售",
    "total_invoices_amount": "總電子發票金額",
    "total_invoices_cancelled": "總已取消金額",
    "total_invoices_pending": "總未完成金額",
    "total_invoices_pendingCancellation": "總取消處理中金額",
    "total_invoices_successful": "總電子發票金額",
    "total_rounding_difference_explanation": "總金額的差額是因四捨五入產生",
    "track inventory": "庫存追踪",
    "tracking": "追踪",
    "tracking code": "追踪碼",
    "tracking codes": "追踪碼",
    "transport": "貨運",
    "transporter": "貨運公司",
    "transporter account": "物流公司客代",
    "transporter accounts": "物流公司客代",
    "transporter notes": "貨運公司筆記",
    "transporter_account_short": "物流公司客代",
    "tuesday": "星期二",
    "tuesday opening hours": "星期二營業時間",
    "twEInvoicePrint": "E-Invoice Allow Print",
    "tw_auto_print_invoices": "預設開立電子發票",
    "tw_eInvoice_always_print": "E-Invoice Always Print",
    "tw_eInvoice_carrierType_app": "手機載具",
    "tw_eInvoice_carrierType_b2b": "營業人發票",
    "tw_eInvoice_carrierType_person": "自然人憑證",
    "tw_eInvoice_carrier_id": "載具編號",
    "tw_eInvoice_carrier_type": "載具",
    "tw_eInvoice_carrier_type_optional": "載具 (非必填)",
    "tw_eInvoice_company_name": "公司抬頭",
    "tw_eInvoice_print_custom_block": "列印自訂區域",
    "tw_eInvoice_print_custom_block_placeholder": "在列印附註或明細時每行限制字數為：\\\\n 英文34個字元，\\\\n 中文17個字元，\\\\n 如果中英夾雜則1個中文等於2個英文字元的長度，\\\\n 但我們不能保證中英文並用時列印格式是否能維持正確。\\\\n\n",
    "tw_eInvoice_print_reload": "重新載入電子發票印表機",
    "tw_eInvoice_provider_bigOrdr": "BigOrdr",
    "tw_eInvoice_provider_systemlead": "Systemlead",
    "tw_eInvoice_reprint": "重新列印電子發票",
    "tw_eInvoices_allow_numbers": "可開立發票字軌",
    "tw_eInvoices_attention_required": "需要手動處理",
    "tw_eInvoices_blankUploaded_successful": "已上傳",
    "tw_eInvoices_blankUploaded_unsuccessful": "未上傳",
    "tw_eInvoices_book": "本號",
    "tw_eInvoices_captured": "已確認紀錄",
    "tw_eInvoices_captured_invoices": "已確認紀錄發票",
    "tw_eInvoices_captured_returns": "已確認紀錄發票折讓",
    "tw_eInvoices_count": "張數",
    "tw_eInvoices_custom_description": "自訂說明",
    "tw_eInvoices_first_number": "起始號",
    "tw_eInvoices_issue_results": "開立電子發票結果",
    "tw_eInvoices_last_number": "終止號",
    "tw_eInvoices_no_track_numbers": "無電子發票字軌",
    "tw_eInvoices_notify_email_address": "電子發票通知電子信箱",
    "tw_eInvoices_numbers": "字軌",
    "tw_eInvoices_numbers_available_count": "剩餘發票數",
    "tw_eInvoices_numbers_blank_end": "空白終止號",
    "tw_eInvoices_numbers_blank_numbers": "空白號",
    "tw_eInvoices_numbers_blank_remaining_unused": "未使用空白號",
    "tw_eInvoices_numbers_blank_start": "空白起始號",
    "tw_eInvoices_numbers_blank_upload_all_remaining_unused": "含所有未使用空白號",
    "tw_eInvoices_numbers_books_end": "本號迄",
    "tw_eInvoices_numbers_books_range": "本號起迄",
    "tw_eInvoices_numbers_books_start": "本號起",
    "tw_eInvoices_numbers_current_books_range": "本字軌範圍",
    "tw_eInvoices_numbers_end": "終止號",
    "tw_eInvoices_numbers_enter_blanks": "輸入空白號",
    "tw_eInvoices_numbers_last": "最後取號",
    "tw_eInvoices_numbers_next": "下次取號",
    "tw_eInvoices_numbers_no_available_count": "無可開立發票字軌",
    "tw_eInvoices_numbers_remaining": "剩餘發票數",
    "tw_eInvoices_numbers_start": "起始號",
    "tw_eInvoices_numbers_upload_blanks": "上傳空白號",
    "tw_eInvoices_numbers_upload_blanks_not_uploaded": "未上傳",
    "tw_eInvoices_prefix": "字軌名稱",
    "tw_eInvoices_print_aesKey": "電子發票 AESKey",
    "tw_eInvoices_print_allow_print": "允許列印電子發票",
    "tw_eInvoices_print_allow_shop": "允許在商店顯示是否列印電子發票",
    "tw_eInvoices_scheduled": "電子發票預計在今晚九點自動發送",
    "tw_eInvoices_uncaptured_total": "未確認紀錄總額",
    "tw_eInvoicing": "電子發票",
    "tw_eInvoives_include_until_current_date": "所有未開電子發票至",
    "tw_eInvoives_last_number": "最後開立發票號碼",
    "tw_eInvoives_no_allocated": "未分配電子發票號碼",
    "tw_invoicing": "統一發票",
    "tw_issue_print_eInvoice": "開立且列印電子發票",
    "tw_print_eInvoices": "開立電子發票",
    "tw_print_eInvoices_now": "立即開立電子發票",
    "tw_print_invoices": "列印統一發票",
    "twoByOne": "2x1",
    "txt verification": "txt verification",
    "type": "種類",
    "type delete to delete": "輸入「 刪除 」",
    "unable to add new busines- please try again later-": "Unable to add new busines- Please try again later-",
    "unable to add new option- please try again later-": "無法新增新的選項，請稍候再嘗試",
    "unable to create profile- please try again later-": "無法建立簡介，請稍候再嘗試",
    "unable to get businesses- please try again later-": "Unable to get businesses- Please try again later-",
    "unable to update profile- please try again later-": "無法更新簡介，請稍候再嘗試",
    "unable to verify account- please try again later-": "無法辨識帳號，請稍候再嘗試",
    "unallocated": "未分配",
    "unassigned": "未指定",
    "undispatched": "未出貨",
    "undispatched or due": "未出貨 / 未到期",
    "undo": "回復",
    "undo cancel invoice": "回復已取消發票",
    "undo void": "取消作廢",
    "unhold all": "全部取消暫停",
    "unit": "單位",
    "unlink": "去除連結",
    "unprepared": "未準備",
    "unprinted": "未列印",
    "unsuccessful": "不成功",
    "update failed the data is out of date as it may have been changed by someone else please try again": "無法更新，您的資料已有人變動？",
    "update required, please reload": "已更新版本。請更新頁面",
    "update status": "更新狀態",
    "updated": "已更新",
    "updated date": "更新日期",
    "upload files": "上傳檔案",
    "upload image": "上傳照片",
    "upload images": "上傳照片",
    "upload images to campaigns": "上傳活動照片",
    "upload images to products": "上傳產品照片",
    "upload logo": "上傳商標",
    "upload status": "上傳狀態",
    "uploading please wait": "上傳中請稍候...",
    "url": "網址",
    "usage limit": "使用限度",
    "use average price for each product": "每個產品都使用平均價格",
    "use campaign period": "使用活動期間",
    "use custom date": "使用自訂時間",
    "use default birthday message in rewards": "使用預設生日祝賀訊息（在獎勵）",
    "use default birthday message in settings": "使用預設生日祝賀訊息（在設定）",
    "use default description": "使用預設敘述",
    "use default message in settings": "使用預設出貨通知信",
    "use default promotion message in rewards": "使用預設促銷訊息（在獎勵）",
    "use default promotion message in settings": "使用預設促銷訊息（在設定）",
    "use default width": "使用預設寬度",
    "use defaults from settings": "系統預設",
    "use exclusive selling price to calculate commission": "使用未稅售價計算佣金",
    "use last price for all": "Use last price for all",
    "use last price for company": "Use last price for company",
    "use normal percentage": "使用百分比折扣",
    "use preset commissions to fill commission value": "將預設抽成設為抽成金額",
    "use price set in products": "Use price set in products",
    "use product set children": "Use Product Set children",
    "use taiwan percentage": "使用折（例如：8.5折）",
    "user": "使用者",
    "user colors": "使用者顏色",
    "user didnt complete the transaction": "未完成信用卡交易",
    "username": "使用者名稱",
    "username or email": "使用者名稱或電子信箱",
    "users": "使用者",
    "valid": "valid",
    "valid points": "有效點數",
    "validate domain": "validate domain",
    "validity date": "有效期",
    "value": "金額",
    "value is required": "必填",
    "values are": "Values are",
    "variant": "附加選項商品",
    "variant child": "產品變體子項",
    "variant code": "規格編碼",
    "variant options": "規格名稱",
    "variant type": "產品規格",
    "variants": "附加選項商品",
    "vat": "增值稅",
    "vertical": "垂直",
    "video": "影片",
    "view": "檢視",
    "view available placeholders": "顯示可插入的語法內容",
    "virtual account": "虛擬帳號",
    "virtual account bank code": "分行",
    "virtual account bank name": "銀行",
    "virtual account expiry date": "最後可轉帳日期",
    "virtual account number": "虛擬帳號",
    "virtual_account_from_account": "轉出帳號",
    "virtual_account_provider_eSun": "玉山銀行虛擬帳號",
    "virtual_account_provider_ecPay": "綠界科技虛擬帳號",
    "virtual_account_provider_ubot": "聯邦銀行虛擬帳號",
    "void": "作廢",
    "waybill": "托運編碼",
    "waybills per page": "每頁托運單張數",
    "web": "官網",
    "webpage": "網頁",
    "webpage_content": "網頁內文",
    "webpage_show_on_footer": "在頁尾顯示",
    "webpages": "網頁",
    "website": "官網",
    "website admin mode": "網站管理者模式",
    "website info": "網站資訊",
    "website template": "網頁模版",
    "website url": "官網網址",
    "website url (at least 6 characters)": "官網網址 (至少6個字符)",
    "website_action_color": "加入購物車-按鈕底色",
    "website_action_final_color": "結帳-按鈕顏底色",
    "website_action_final_hover_color": "結帳-按鈕滑鼠滑過變色",
    "website_action_final_text": "結帳-按鈕顏色",
    "website_action_final_text_color": "結帳-按鈕文字顏色",
    "website_action_hover_color": "加入購物車-按鈕滑鼠滑過變色",
    "website_action_text_color": "加入購物車-按鈕字體色",
    "website_active_color": "選定顏色",
    "website_background_primary_color": "背景主色",
    "website_background_secondary_color": "背景第二次色",
    "website_border_color": "邊框顏色",
    "website_button_border_color": "按鈕邊框顏色",
    "website_button_border_color2": "按鈕邊框顏色2",
    "website_button_border_color3": "按鈕邊框顏色3",
    "website_button_border_radius": "按鈕圓角值",
    "website_button_border_radius_2": "按鈕圓角值2",
    "website_button_border_radius_3": "按鈕圓角值3",
    "website_button_color": "按鈕顏色",
    "website_button_color2": "按鈕顏色2",
    "website_button_color3": "按鈕顏色3",
    "website_button_hover_color": "滑鼠移到按鈕的顏色",
    "website_button_hover_color2": "滑鼠移到按鈕的顏色2",
    "website_button_hover_color3": "滑鼠移到按鈕的顏色3",
    "website_button_hover_text_color": "滑鼠移到文字的顏色",
    "website_button_hover_text_color2": "滑鼠移到文字的顏色2",
    "website_button_hover_text_color3": "滑鼠移到文字的顏色3",
    "website_button_text_color": "按鈕文字顏色",
    "website_button_text_color2": "按鈕文字顏色2",
    "website_button_text_color3": "按鈕文字顏色3",
    "website_chat_button_color": "Line/FB-對話按鈕顏色",
    "website_chat_button_hover_color": "Line/FB-滑鼠移到對話按鈕的顏色",
    "website_chat_button_text_color": "Line/FB-對話內文文字顏色",
    "website_count_down_color": "倒數計時顏色",
    "website_count_down_text_color": "倒數計時文字顏色",
    "website_footer_background_color": "頁尾背景色",
    "website_footer_background_primary_color": "頁尾背景色",
    "website_footer_header_color": "頁尾標題景色",
    "website_footer_link_color": "頁尾連結景色",
    "website_footer_link_hover_color": "頁尾連結滑鼠滑過變色",
    "website_footer_message": "頁尾內容",
    "website_footer_text_color": "頁尾字體景色",
    "website_headers": "自訂HTML Head內容",
    "website_input_background_color": "輸入格背景色",
    "website_input_border_color": "欄位邊框顏色",
    "website_input_border_focus_color": "欄位邊框聚焦顏色",
    "website_input_text_color": "輸入格文字色",
    "website_link__hover_color": "連結滑鼠滑過變色",
    "website_link_color": "連結字體顏色",
    "website_link_hover_color": "滑鼠移到網站連結的顏色",
    "website_menu_background_primary_color": "主選單背景主要色",
    "website_menu_background_secondary_color": "主選單背景次要色",
    "website_menu_border_color": "主選單外框色",
    "website_menu_link_color": "主選單字體色",
    "website_menu_link_hover_color": "主選單字體滑鼠滑過變色",
    "website_menu_type": "種類",
    "website_primary_color": "主顏色",
    "website_sale_color": "特賣標籤顏色",
    "website_sale_text_color": "特賣標籤文字顏色",
    "website_scripts": "Javascript程式語言",
    "website_section_action": "加入購物車",
    "website_section_action_final_btn": "結帳",
    "website_section_backgrounds": "背景",
    "website_section_button_1": "按鈕1",
    "website_section_button_2": "按鈕2",
    "website_section_button_3": "按鈕3",
    "website_section_buttons": "按鈕",
    "website_section_chat": "Line/FB聊天",
    "website_section_count_down": "倒數",
    "website_section_footer": "頁尾",
    "website_section_inputs": "輸入格",
    "website_section_links": "連結",
    "website_section_nav": "主選單",
    "website_section_other": "其他",
    "website_section_sales": "特賣",
    "website_section_tags": "標籤",
    "website_section_text": "文字",
    "website_section_text_basic": "一般",
    "website_seo_description": "搜尋引擎優化描述",
    "website_seo_keywords": "搜尋引擎優化及關鍵字",
    "website_tag_color": "標籤顏色",
    "website_tag_text_color": "標籤文字顏色",
    "website_text_2_color": "第二階層內文的顏色",
    "website_text_3_color": "第三階層內文的顏色",
    "website_text_color": "文字顏色",
    "website_type_link": "網址",
    "website_type_link_product": "產品",
    "website_type_link_url": "網址",
    "website_type_page": "商頁",
    "websites": "官網",
    "wednesday": "星期三",
    "wednesday opening hours": "星期三營業時間",
    "weekday1": "星期一",
    "weekday2": "星期二",
    "weekday3": "星期三",
    "weekday4": "星期四",
    "weekday5": "星期五",
    "weekday6": "星期六",
    "weekday7": "星期日",
    "weeks": "星期",
    "weight": "重量",
    "weight_unit_default": "預設重量單位",
    "welcome to bigordr": "歡迎來到BigOrdr!",
    "with grand totals": "加總計",
    "with prices": "含價格",
    "without prices": "不含價格",
    "workflows": "工作流程",
    "workflows_items_activities_call": "電話通話",
    "workflows_items_activities_email": "電子信箱",
    "workflows_items_activities_event": "事件",
    "workflows_items_activities_meeting": "會議",
    "workflows_items_activities_notes": "注意事項",
    "workflows_items_activity": "事件",
    "workflows_items_status_open": "進行中",
    "workflows_items_status_re-open": "Re-Open",
    "workflows_items_status_successful": "成功",
    "workflows_items_status_unsuccessful": "不成功",
    "year": "年",
    "years": "年",
    "yes": "是",
    "youtube channel url": "Youtube頻道連結",
    "zone priority": "運送區域優先程度",
    "zones fee": "地區運費"
  };
  _exports.default = _default;
});