define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commissions-adjustment-editor/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorTableViewDetailRow = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorTableViewDetailRow extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showDetails", _descriptor, this);
    }

    // @action
    // toggleAllDetails () {
    //   const results = this?.args?.tableViewOptions?.results || []
    //   R.forEach(model => {
    //     this.toggleDetail(model)
    //   })(results)
    // }
    toggleDetail(model) {
      this.showDetails = !this.showDetails;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showDetails", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleDetail", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDetail"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorTableViewDetailRow;
});