define("client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QYtYKCDv",
    "block": "{\"symbols\":[\"onHideDropdown\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid__header-nav\"],[8],[0,\"\\n\"],[4,\"layouts/section-row\",null,[[\"classNames\"],[\"section-row__outer-container--no-margin\"]],{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-btn\",null,[[\"isToggler\",\"translate\",\"icon\",\"label\",\"disabled\",\"toggleIsOn\"],[true,false,[23,0,[\"icon\"]],[24,[\"model\",\"pageName\"]],[24,[\"disabled\"]],[24,[\"showWebsitePagesNav\"]]]]],false],[0,\"\\n\\n\"],[4,\"lists/list-btn\",null,[[\"icon\"],[\"fas fa-plus\"]],{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"icon\",\"disabled\",\"onSubmit\"],[\"new page\",\"fas fa-plus\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"onNew\"]],[23,1,[]],\"page\",[24,[\"website\"]]],null]]]],false],[0,\"\\n\\n      \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"icon\",\"disabled\",\"onSubmit\"],[\"new link\",\"fas fa-plus\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"onNew\"]],[23,1,[]],\"link\",[24,[\"website\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"model\",\"_data\",\"isHome\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"icon\",\"disabled\",\"onSubmit\"],[\"sub page\",\"fas fa-plus\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"onNew\"]],[23,1,[]],\"page\",[24,[\"website\"]],[24,[\"model\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[28,\"websites/websites-editor/pages-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"model\",\"website\",\"hasWebBuilder\",\"pagesCollection\",\"onEdit\",\"toggleShowWebsitePagesNav\"],[[24,[\"tab\"]],false,false,[24,[\"model\"]],[24,[\"website\"]],[24,[\"hasWebBuilder\"]],[24,[\"pagesCollection\"]],[24,[\"onEdit\"]],[24,[\"toggleShowWebsitePagesNav\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template.hbs"
    }
  });

  _exports.default = _default;
});