define("client/pods/components/documents/document-print/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['u-print-container'],
    hasPrices: true,
    hasPrintBtn: true,

    // @NOTE: PAGE SIZE INFO
    // A4: 210 × 297 millimeters OR 8.27 × 11.69 inches
    // US Letter: 216 × 279 millimeters or 8.5 × 11 inches
    // pixels (1px = 1/96th of 1in)
    // inches (1in = 96px = 2.54cm)
    // work out height in px for given page size
    // A4 SIZES:
    //  HEIGHT: 11.69*96 = 1122.24 ~ round to: 1122px for A4
    //  WIDTH: 8.27*96 = 793.92 ~ round to: 793px
    //
    // US LETTER
    // 11*96 = 1056
    init() {
      this.set('firstRender', true);
      this.set('page', {
        a4: 1040
      });

      this._super(...arguments);

      const model = this.model; // const details = model.getData('details')

      const details = model.detailsWithDiscountAndReward;
      const pagesData = [{
        page: 1,
        details: details
      }];
      this.set('pagesData', pagesData);
    },

    didInsertElement() {
      const self = this;

      self._super(...arguments);

      const model = self.get('model'); // const details = model.getData('details')

      const details = model.detailsWithDiscountAndReward;
      const pageHeight = self.get('page.a4');
      const headerHeight = self.$('.documents__header-container').outerHeight() || 0;
      const allowedHeight = pageHeight - headerHeight;
      let currentPage = 0;
      let pageAccumulatedHeight = 0;
      const pagesData = [];
      const rows = self.$('.u-print-table__data-row');
      rows.each((index, row) => {
        row = self.$(row);
        const rowHeight = row.outerHeight() || 0;
        pageAccumulatedHeight = pageAccumulatedHeight + rowHeight;

        if (pageAccumulatedHeight > allowedHeight) {
          ++currentPage;
          pageAccumulatedHeight = 0;
        }

        if (!pagesData[currentPage]) {
          pagesData[currentPage] = {
            page: currentPage + 1,
            details: []
          };
        }

        pagesData[currentPage].details.pushObject(details.objectAt(index));
      });
      self.set('pagesData', pagesData);
    },

    didRender() {
      this._super(...arguments);

      if (this.firstRender) {
        const footerContainer = this.$('.documents__footer-container');
        const footerContainerHeight = footerContainer.outerHeight() || 0;
        const pageHeight = this.get('page.a4');
        const pages = this.$('.u-print-page');
        const lastPage = pages.last();
        this.$('.documents__footer-container').remove();
        const lastPageHeight = lastPage.outerHeight() || 0;
        const pagesData = this.pagesData;

        if (lastPageHeight + footerContainerHeight > pageHeight) {
          let nextPage = parseInt(pages.length);
          nextPage = nextPage + 1;
          pagesData.pushObject({
            page: nextPage,
            details: []
          });
          this.set('pagesData', pagesData);
        }
      }

      this.set('firstRender', false);
    },

    subHeaderComponent: Ember.computed('model._data.{docType,docName}', function () {
      return 'documents/document-print/sub-header-references';
    }),
    detailsComponent: Ember.computed('model._data.{docType,docName}', function () {
      return 'documents/document-print/details-prices';
    }),
    footerComponent: Ember.computed('model._data.{docType,docName}', function () {
      var _this$model, _this$model$_data, _this$model2, _this$model2$_data;

      const docType = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : _this$model$_data.docType;
      const docName = (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$_data = _this$model2._data) === null || _this$model2$_data === void 0 ? void 0 : _this$model2$_data.docName;

      if (docType === 'sales') {
        if (docName === 'quotes') {
          return 'documents/document-print/footer-sales-terms';
        }

        if (docName === 'orders') {
          return 'documents/document-print/footer-sales-terms';
        }

        if (docName === 'invoices') {
          return 'documents/document-print/footer-sales-delivery-notes';
        }

        if (docName === 'consignments') {
          return 'documents/document-print/footer-sales-delivery-notes';
        }
      }

      if (docType === 'purchases') {
        if (docName === 'quotes') {
          return 'documents/document-print/footer-purchases-terms';
        }

        if (docName === 'orders') {
          return 'documents/document-print/footer-purchases-orders';
        }
      }

      return '';
    }),
    showTaxes: Ember.computed('model.{_data.taxMethodKey}', 'settings.settingsModel._data.taxRateKey', function () {
      const taxSettings = this.get('settings.settingsModel._data.taxRateKey');

      if (taxSettings === 'none' || taxSettings === 'zero') {
        return false;
      }

      const modelTaxMethod = this.get('model._data.taxMethodKey');

      if (modelTaxMethod === 'none' || modelTaxMethod === 'zero') {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});