define("client/pods/components/blogs/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let BlogsTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class BlogsTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dropPosition", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showUploadsRow", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isReadyMode", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isEditMode", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "modelProxy", _descriptor7, this);
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "isDroppable", true);
      (0, _defineProperty2.default)(this, "adapterName", 'blogs');
      this.crud.addLists(this, ['blogs/lists/menu', 'blogs/lists/tags', 'statuses']);
      this.modelProxy = this.args.model;
    }

    get rowClassName() {
      return `table-view__detail-row ${this.dropPosition}`;
    }

    get description() {
      const div = document.createElement('div');
      div.innerHTML = R.pathOr('', ['args', 'model', '_data', 'description'])(this);
      return div.innerText;
    }

    get updateRecordIndexOnDrop() {
      return R.pathOr(true, ['args', 'tableViewOptions', 'updateRecordIndexOnDrop'])(this);
    }

    *updateRecordTask() {
      this.modelProxy = yield this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        model: this.modelProxy,
        tab: this.args.tab
      });
      this.cancelDirty();
    }

    setupDirty() {
      this.modelProxy = this.crud.setupDirty({
        model: this.args.model,
        adapterName: this.adapterName
      });
      this.isReadyMode = false;
      this.isEditMode = true;
    }

    cancelDirty() {
      if (this.modelProxy.isDirty) {
        this.crud.cancelEditing({
          adapterName: this.adapterName,
          model: this.modelProxy
        });
      }

      this.isReadyMode = true;
      this.isEditMode = false;
    }

    drop(indexStart, indexEnd, results) {
      let reIndexBlogs;

      if (indexStart > indexEnd) {
        reIndexBlogs = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexBlogs = R.slice(indexStart, R.inc(indexEnd))(results);
      }

      const master = R.view(R.lensPath([0, '_data', 'master']))(results);

      if (this.updateRecordIndexOnDrop) {
        this.crud.adapterCallTask.perform('updateIndexes', {
          adapterName: 'blogs/details/batch',
          appendPath: `/${master}/indexes`,
          data: reIndexBlogs
        });
      }
    }

    dragOver() {
      if (this.args.tableViewOptions.onDragOver) {
        this.dropPosition = this.args.tableViewOptions.onDragOver(...arguments);
      }
    }

    dragStart() {
      if (this.args.tableViewOptions.onDragStart) {
        this.args.tableViewOptions.onDragStart(...arguments);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dropPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showUploadsRow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isReadyMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isEditMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modelProxy", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecordTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupDirty", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelDirty", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancelDirty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drop", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragOver", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragStart", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype)), _class));
  _exports.default = BlogsTableViewDetailRowComponent;
});