define("client/pods/components/documents/panel-edit/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/pods/components/documents/mixins/pre-submit", "client/pods/components/documents/mixins/details", "ember-concurrency"], function (_exports, _date, _settings, _crud, _preSubmit, _details, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _details.default, _settings.default, _crud.default, _preSubmit.default, {
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes']);
      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      this.set('dontFetchRegionCode', this.get('tab.config.dontFetchRegionCode'));
      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      const dirty = this.setupDirty({
        adapterName,
        model: this.get('tab.model')
      });
      this.set('dirty', dirty);
      const contactKey = dirty.getData('contactKey');

      if (contactKey) {
        const contact = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: contactKey
        });
        this.set('selectedContact', contact);
        dirty.set('selectedContact', contact);
      }

      this.tabsManager.setHeader(this.tabParent, '#' + dirty.getData('docNo') + ' ' + dirty.getData('contact'));
    }),

    afterLoadLists() {
      this._super(...arguments);

      const dirty = this.dirty; // @TODO: setup on model? but documents should be different based on context, so should not be setup in model?
      // eg. document entered here may be exclusive, but documents created via shop should be inclusive
      // set default discount types

      if (!dirty.getData('discountMethod')) {
        dirty.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      }

      if (!dirty.getData('discountType')) {
        dirty.setData('discountType', this.get('constants.discountTypes.rate'));
      }

      if (this.get('tab.config.docName') === 'orders' || this.get('tab.config.docName') === 'quotes') {
        dirty.setData('deliveryDateOption', this.get('constants.deliveryDateOptions.single'));
      }

      this.addNewDetail(dirty);
    }

  });

  _exports.default = _default;
});