define("client/pods/components/websites/websites-editor/templates/private-template/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let WebsitesWebsitesEditorTemplatesPrivateTemplateComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class WebsitesWebsitesEditorTemplatesPrivateTemplateComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showPreview", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "page", _descriptor2, this);
    }

    onClick() {
      const onClick = R.pathOr(null, ['templateOptions', 'onClick'])(this.args);

      if (onClick) {
        onClick(...arguments);
      }
    }

    togglePreview(page) {
      this.page = page;
      this.showPreview = !!!this.showPreview;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPreview", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "togglePreview", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreview"), _class.prototype)), _class));
  _exports.default = WebsitesWebsitesEditorTemplatesPrivateTemplateComponent;
});