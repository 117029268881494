define("client/pods/components/channels/shop/shipping-zones/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ilqLz2En",
    "block": "{\"symbols\":[\"@tab\",\"@onDisplay\",\"@onDash\",\"@onCancel\"],\"statements\":[[5,\"channels/shop/shipping-zones/shipping-zones-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@isRunning\",\"@onSaveTask\",\"@onRemoveRecordTask\",\"@onCancel\"],[[23,1,[]],[23,0,[\"dirty\"]],[23,0,[\"errors\"]],true,false,[28,\"or\",[[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],[23,0,[\"crud\",\"removeRecordTask\",\"isRunning\"]]],null],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[]],[23,0,[\"dirty\"]],[23,2,[]],[28,\"hash\",null,[[\"tab\"],[[23,1,[]]]]],[23,1,[]]]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"component\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[23,0,[]],[23,3,[]],[28,\"hash\",null,[[\"tab\"],[[23,1,[]]]]],[23,1,[]]]]]],null],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});