define("client/pods/components/products/products-editor/sets-editor/details-editor/component", ["exports", "client/mixins/errors", "client/mixins/crud", "ember-concurrency"], function (_exports, _errors, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _errors.default, {
    tagName: '',
    showUploadsRow: false,

    init() {
      this._super(...arguments);

      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes: [{
            key: 'setType',
            value: 'isSet'
          }, {
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }]
        }
      });
      this.set('productsFilters', productsFilters);
    },

    searchProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const filters = this.productsFilters; // filters.setData('query', query)

        const results = yield this.searchInputTask.perform({
          adapterName,
          filters
        }, query);
        return results;
      }
    })
  });

  _exports.default = _default;
});