define("client/pods/components/export/worksheet/editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ExportWorksheetEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ExportWorksheetEditorComponent extends _component.default {
    constructor() {
      var _this$args, _this$args$model, _this$args$model$_dat;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "specsCollection", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "listAlreadyUsedInDropdown", _descriptor4, this);
      this.fetchSpecsTask.perform();
      this.listAlreadyUsedInDropdown = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$model = _this$args.model) === null || _this$args$model === void 0 ? void 0 : (_this$args$model$_dat = _this$args$model._data) === null || _this$args$model$_dat === void 0 ? void 0 : _this$args$model$_dat.defaultExportCustomSheets) || [];
    }

    get availableCustomWorksheets() {
      var _this$specsCollection, _this$specsCollection2;

      const originalList = (this === null || this === void 0 ? void 0 : (_this$specsCollection = this.specsCollection) === null || _this$specsCollection === void 0 ? void 0 : (_this$specsCollection2 = _this$specsCollection._data) === null || _this$specsCollection2 === void 0 ? void 0 : _this$specsCollection2.custom) || [];
      const listAlreadyUsedInDropdown = this.listAlreadyUsedInDropdown || [];
      return R.filter(list => {
        const listKey = list._data._key;
        const listKeyAlreadyUsedInDropdown = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('value'), R.reject(RA.isNilOrEmpty))(listAlreadyUsedInDropdown);
        return !R.includes(listKey, listKeyAlreadyUsedInDropdown);
      })(originalList);
    }

    addDefaultExportCustomSheets(selectedSpec) {
      var _model$_data;

      const model = this.args.model;
      const defaultCustomSheets = model.getData('defaultExportCustomSheets') || [];
      const data = {
        value: selectedSpec.getData('_key'),
        label: selectedSpec.getData('description'),
        index: defaultCustomSheets.length
      };
      const modelData = this.dataManager.setAsRecord({
        adapterName: 'dispatch/lists/processes/custom-worksheets',
        data
      });
      defaultCustomSheets.pushObject(modelData);
      model.setData('defaultExportCustomSheets', defaultCustomSheets);
      this.listAlreadyUsedInDropdown = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.defaultExportCustomSheets) || [];
    }

    removeListDropdown(customSheet) {
      const model = this.args.model;
      const defaultExportCustomSheets = model.getData('defaultExportCustomSheets');
      defaultExportCustomSheets.removeObject(customSheet);
      RA.mapIndexed((val, idx) => {
        defaultExportCustomSheets.set(`${idx}.index`, idx);
      })(defaultExportCustomSheets);
      model.setData('defaultExportCustomSheets', defaultExportCustomSheets);
    }

    *fetchSpecsTask() {
      const specsCollection = yield this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      this.specsCollection = specsCollection;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "specsCollection", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listAlreadyUsedInDropdown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addDefaultExportCustomSheets", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addDefaultExportCustomSheets"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeListDropdown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeListDropdown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchSpecsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchSpecsTask"), _class.prototype)), _class));
  _exports.default = ExportWorksheetEditorComponent;
});