define("client/pods/components/faqs/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "client/utils/nventor", "client/pods/documents/proxy/model", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _nventor, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let FaqComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = (_temp = class FaqComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showAllDetails", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "adapterName", _descriptor3, this);
      this.detailsRowComponent = this.args.detailsRowComponent || 'faqs/table-view/faqs-row';
    }

    get faqsProxy() {
      return R.map(faq => _nventor.default.createModel(_model.default, {}, {
        model: this.model,
        faq,
        isSelected: false,
        showDetails: false
      }), this.model._data.faqs);
    }

    addFaq(onCloseDialogAction = null) {
      this.model = this.args.model;

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showAllDetails", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "adapterName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.adapterName;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addFaq", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addFaq"), _class.prototype)), _class));
  _exports.default = FaqComponent;
});