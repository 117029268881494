define("client/pods/components/elements/btn-save/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    translate: true,
    label: '',
    tooltipLabel: 'save',
    btnClassNames: 'is-primary',
    icon: 'fas fa-save',
    defaultIcon: Ember.computed('icon', function () {
      if (this.icon) {
        return this.icon;
      }

      return 'fas fa-save';
    })
  });

  _exports.default = _default;
});