define("client/pods/grapesjs/slider/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        src: '',
        link: '',
        buttonlabel: '',
        pixelratio: '2048x1024',
        ratio: '2:1'
      };
    },

    populate(data, attrs) {
      data = this._transform(data);

      this._super(data, attrs);
    },

    _transform({
      src,
      pixelratio,
      link,
      buttonlabel
    }) {
      if (pixelratio) {
        this.setRatio(pixelratio);
      }

      if (link) {
        this.setData('link', link);
      }

      if (buttonlabel) {
        this.setData('buttonlabel', buttonlabel);
      }

      if (src.includes('placeholder')) {
        const regex = this.getRegex();
        this._data.src = R.pipe(R.split(regex), R.head)(src);
        return this._data;
      }

      this._data.src = this.setSrc(src);
      return this._data;
    },

    getRegex() {
      return new RegExp(/\d{2,4}x\d{2,4}/gm);
    },

    getSrcWithRatio: Ember.computed('_data.{src,pixelratio,ratio}', function () {
      const src = this.getData('src');
      const pixelratio = this.getData('pixelratio');

      if (src.includes('placeholder')) {
        const regex = this.getRegex();
        const requiredReplacePixelRatio = R.test(regex)(src);

        if (requiredReplacePixelRatio) {
          return R.replace(regex, this.getData('pixelratio'))(src);
        }

        return `${src}/${pixelratio}/?text=Image`;
      }

      return `${src}-/scale_crop/${pixelratio}/center/`;
    }),
    isPlaceholder: Ember.computed('_data.src', function () {
      const src = this.getData('src');
      return src.includes('placeholder');
    }),

    setSrc(src) {
      return R.pipe(R.match(/https:\/\/ucarecdn\.com\/\S{36}\//mg), R.head)(src);
    },

    setRatio(pixelRatio) {
      switch (pixelRatio) {
        case '2048x682':
          this.setData('ratio', '3:1');
          this.setData('pixelratio', pixelRatio);
          break;

        case '2048x1024':
          this.setData('ratio', '2:1');
          this.setData('pixelratio', pixelRatio);
          break;

        case '2048x1536':
          this.setData('ratio', '4:3');
          this.setData('pixelratio', pixelRatio);
          break;

        default:
          this.setData('ratio', '2:1');
          this.setData('pixelratio', pixelRatio);
          break;
      }

      return this;
    }

  });

  _exports.default = _default;
});