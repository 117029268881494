define("client/pods/emails/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll(data = {}, appendPath) {
      const customData = {
        list: [{
          resource: 'api/protected/emails/lists/menu/actions/activity',
          component: 'emails/panels-activity',
          label: 'activity',
          action: 'activity',
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/emails/lists/menu/actions/scheduled',
          component: 'emails/panels-scheduled',
          label: 'scheduled',
          action: 'scheduled',
          tabType: 'replaceSubTab'
        }]
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});