define("client/pods/components/settings/defaults/company-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _isReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsContactInfo', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.get('model._data.useDefaultsContactInfo');
    })
  });

  _exports.default = _default;
});