define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "27ZrFUWl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"automationModel\",\"isDeleted\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-editor/automations-editor/when-editor\",null,[[\"isEditing\",\"isNew\",\"isReadonly\",\"tab\",\"searchProductsTask\",\"productsList\",\"master\",\"automationModel\",\"automationDetails\",\"errors\"],[false,false,true,[24,[\"tab\"]],[24,[\"searchProductsTask\"]],[24,[\"productsList\"]],[24,[\"master\"]],[24,[\"automationModel\"]],[24,[\"automationDetails\"]],[24,[\"errors\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details\",null,[[\"tab\",\"errors\",\"searchProductsTask\",\"productsList\",\"isReadonly\",\"isEditing\",\"isNew\",\"isDisabled\",\"onEditAutomationItem\",\"onSetAsDefaultImageTask\",\"selectedAutomation\",\"master\",\"automationModel\",\"automationDetails\",\"onLoadItem\",\"onResetItem\"],[[24,[\"tab\"]],[24,[\"errors\"]],[24,[\"searchProductsTask\"]],[24,[\"productsList\"]],[24,[\"isReadonly\"]],[24,[\"isEditing\"]],[24,[\"isNew\"]],[24,[\"isDisabled\"]],[24,[\"onEditAutomationItem\"]],[24,[\"onSetAsDefaultImageTask\"]],[24,[\"selectedAutomation\"]],[24,[\"master\"]],[24,[\"automationModel\"]],[24,[\"automationDetails\"]],[24,[\"onLoadItem\"]],[24,[\"onResetItem\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});