define("client/pods/components/contacts/contacts-editor/component", ["exports", "client/mixins/search-contacts", "client/mixins/editor", "client/mixins/date"], function (_exports, _searchContacts, _editor, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_searchContacts.default, _editor.default, _date.default, {
    crud: Ember.inject.service(),
    contactsList: null,
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    isEditing: false,
    disable: false,
    isRendered: false,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['statuses', 'contacts/lists/taxMethods', 'contacts/lists/relationships', 'contacts/lists/detailTypes', 'contacts/lists/tags', 'contacts/lists/isTransporter', 'contacts/transporters', 'paymentMethods', 'paymentTerms', {
        name: 'users',
        appendPath: 'internal'
      }]);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('isRendered', true);
    },

    /**
    * only focus child components when component has finished rendering.
    * eg. when creating new contact it will autofocus on first input,
    * when editing, child components with autofocus
    * should not override first inputs autofocus
    */
    autofocusComponents: Ember.computed('isRendered', function () {
      if (this.isRendered) {
        return true;
      }

      return false;
    }),
    // determines if min form requirements are met
    isMinRequiredMet: Ember.computed('model._data.name', function () {
      const minRequired = ['name'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),

    /**
    * removes not required taxMethods.
    */
    // removeNotRequired () {
    //   const model = this.get('model')
    //   if (!this.get('hasSales')) {
    //     model.setData('salesTaxMethodKey', '')
    //     model.setData('salesPaymentMethodKey', '')
    //     model.setData('salesPaymentTermsKey', '')
    //     model.setData('salesAccount', '')
    //   }
    //   if (!this.get('hasPurchases')) {
    //     model.setData('purchasesTaxMethodKey', '')
    //     model.setData('purchasesPaymentMethodKey', '')
    //     model.setData('purchasesPaymentTermsKey', '')
    //     model.setData('purchasesAccount', '')
    //   }
    // },

    /**
    * assign sales rep to contact
    */
    assignSalesPerson(person) {
      const model = this.model;
      model.addSalesPerson(person.serialize());
    },

    /**
    * assign sales rep to contact
    */
    unassignSalesPerson(person) {
      const model = this.model;
      model.removeSalesPerson(person);
    },

    // confirmClose () {
    // // @TODO: test
    // // check if model has changed.
    // // @TODO: how to check if model is dirty before asking to close?
    // // @TODO: if model is not dirty close without prompt
    //   const msg = this.get('intl').t('are you sure you want to close?')
    //   return window.confirm(msg)
    // },
    actions: {
      /**
       * pass model to outer component's save action
       * (do not handle saving here as different actions are required for create/update)
       */
      // submit () {
      //   const model = this.get('model')
      //   // should remove customer/supplier tax rates if not required
      //   this.removeNotRequired()
      //   this.get('onSubmit')(model)
      // },
      // cancel () {
      //   if (this.confirmClose()) {
      //     this.get('onCancel')()
      //   }
      // },

      /**
      * assign sales rep to contact
      */
      assignSalesPerson(person) {
        this.assignSalesPerson(person);
      },

      /**
      * unassign sales rep
      */
      unassignSalesPerson(person) {
        this.unassignSalesPerson(person);
      },

      /**
      * add detail to model based on detail type
      */
      addDetail(model, detailType) {
        model.addDetail({
          type: detailType.getData('value'),
          subType: detailType.getData('defaultSubType')
        });
      },

      /**
       * remove detail from model
       */
      removeDetail(model, detail
      /* , component */
      ) {
        model.removeDetail(detail);
      },

      /**
       * add person to model
       */
      addPerson(model
      /* , component */
      ) {
        model.addPerson();
      },

      /**
       * remove deparment from model
       */
      removePerson(model, person) {
        model.removePerson(person);
      },

      /**
       * add department to model
       */
      addDepartment(model
      /* , component */
      ) {
        model.addDepartment();
      },

      /**
       * remove deparment from model
       */
      removeDepartment(model, department) {
        model.removeDepartment(department);
      },

      // loadPaymentMethod(type, paymentMethod) {
      //
      //   if (paymentMethod) {
      //     // check paymentMethod selection to determine if has payment terms
      //     const hasPaymentTerms = paymentMethod.getData('hasPaymentTerms');
      //
      //     this.get('model').setData(`${type}HasPaymentTerms`, hasPaymentTerms);
      //   }
      // },
      //
      // loadPaymentTerm(type, paymentTerm) {
      //
      //   this.get('model').setData(`${type}PaymentTerms`, paymentTerm.getData('label'));
      // },
      //
      // // searchContact(relationship, e, value) {
      // //
      // //   /* jshint unused: false */
      // //
      // //   this.searchContact(relationship, value);
      // // },
      //
      // loadAccount(type, contact) {
      //   // @TODO TEST
      //
      //   const key = contact.getData('_key');
      //   const  model = this.get('model');
      //   model.setData(`${type}AccountKey`, key);
      //
      //   model.setData(`${type}PaymentMethodKey`, contact.getData(`${type}PaymentMethodKey`));
      //   model.setData(`${type}HasPaymentTerms`, contact.getData(`${type}HasPaymentTerms`));
      //   model.setData(`${type}PaymentTermsKey`, contact.getData(`${type}PaymentTermsKey`));
      //   model.setData(`${type}PaymentTerms`, contact.getData(`${type}paymentTerms`));
      // },
      //
      // unloadAccount(type) {
      //
      //   const model = this.get('model');
      //   model.setData(`${type}AccountKey`, '');
      // },
      //
      // loadTransporter(contact) {
      //   // @TODO TEST
      //
      //   const key = contact.getData('_key');
      //   const  model = this.get('model');
      //   model.setData('transporterKey', key);
      // },
      //
      // unloadTransporter() {
      //
      //   const model = this.get('model');
      //   model.setData('transporterKey', '');
      // },
      addRelationship(option) {
        const relationship = option.getData('value');
        const model = this.model;
        let type = 'sales';

        if (relationship === 'supplier') {
          type = 'purchases';
        }

        const settingsModel = this.settings.getModel();
        model.setData(`${type}PaymentMethodKey`, settingsModel.getData(`${type}PaymentMethodKey`, this.get('constants.paymentMethods.cash')));
        model.setData(`${type}TaxMethodKey`, this.get('constants.taxMethods.auto'));
      },

      removeRelationship(option) {
        const self = this;
        const relationship = option.getData('value');
        const model = this.model;
        let type = 'sales';

        if (relationship === 'supplier') {
          type = 'purchases';
          model.setData('isTransporter', self.get('constants.isTransporter.off'));
          model.setData('transporterAccounts', []);
          model.setData('defaultTransporterAccount', '');
        }

        model.setData(`${type}PaymentTerms`, '');
        model.setData(`${type}PaymentTermsKey`, '');
      },

      setTransporterAccountsList() {
        const model = this.model;
        const accounts = model.getData('transporterAccounts');

        if (!accounts) {
          model.setData('transporterAccounts', []);
        }
      }

    }
  });

  _exports.default = _default;
});