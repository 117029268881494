define("client/pods/components/dispatch/pre-dispatch-modal/component", ["exports", "ramda", "client/mixins/date", "client/mixins/transporter", "client/mixins/hct-fetch", "client/mixins/doc-contacts", "ember-concurrency"], function (_exports, R, _date, _transporter, _hctFetch, _docContacts, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docContacts.default, _hctFetch.default, _transporter.default, {
    dispatch: Ember.inject.service(),

    init() {
      var _this$model, _this$model$_data, _this$model2, _this$model2$_data, _this$model3, _this$model3$_data, _this$model4, _this$model4$_data, _this$model5, _this$model5$_data, _this$model6, _this$model6$_data, _this$model7, _this$model7$_data, _this$model8, _this$model8$_data, _this$model9, _this$model9$_data, _this$model10, _this$model10$_data, _this$model11, _this$model11$_data;

      this._super(...arguments);

      if (this.startWithDirty) {
        this.setupDirty();
        this.editTransporter.perform(this.model);
      }

      this.set('original', {
        transporterLabel: this === null || this === void 0 ? void 0 : (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : _this$model$_data.transporterLabel,
        waybill: this === null || this === void 0 ? void 0 : (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$_data = _this$model2._data) === null || _this$model2$_data === void 0 ? void 0 : _this$model2$_data.waybill,
        packages: this === null || this === void 0 ? void 0 : (_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : (_this$model3$_data = _this$model3._data) === null || _this$model3$_data === void 0 ? void 0 : _this$model3$_data.packages,
        contactPerson: this === null || this === void 0 ? void 0 : (_this$model4 = this.model) === null || _this$model4 === void 0 ? void 0 : (_this$model4$_data = _this$model4._data) === null || _this$model4$_data === void 0 ? void 0 : _this$model4$_data.person,
        address: this === null || this === void 0 ? void 0 : (_this$model5 = this.model) === null || _this$model5 === void 0 ? void 0 : (_this$model5$_data = _this$model5._data) === null || _this$model5$_data === void 0 ? void 0 : _this$model5$_data.address,
        telephone: this === null || this === void 0 ? void 0 : (_this$model6 = this.model) === null || _this$model6 === void 0 ? void 0 : (_this$model6$_data = _this$model6._data) === null || _this$model6$_data === void 0 ? void 0 : _this$model6$_data.telephone,
        dateZ: this === null || this === void 0 ? void 0 : (_this$model7 = this.model) === null || _this$model7 === void 0 ? void 0 : (_this$model7$_data = _this$model7._data) === null || _this$model7$_data === void 0 ? void 0 : _this$model7$_data.transporterArrivalDateZ,
        account: this === null || this === void 0 ? void 0 : (_this$model8 = this.model) === null || _this$model8 === void 0 ? void 0 : (_this$model8$_data = _this$model8._data) === null || _this$model8$_data === void 0 ? void 0 : _this$model8$_data.transporterAccountNumber,
        transporterNotes: this === null || this === void 0 ? void 0 : (_this$model9 = this.model) === null || _this$model9 === void 0 ? void 0 : (_this$model9$_data = _this$model9._data) === null || _this$model9$_data === void 0 ? void 0 : _this$model9$_data.transporterNotes,
        transporterBatchLabel: this === null || this === void 0 ? void 0 : (_this$model10 = this.model) === null || _this$model10 === void 0 ? void 0 : (_this$model10$_data = _this$model10._data) === null || _this$model10$_data === void 0 ? void 0 : _this$model10$_data.transporterBatchLabel,
        transporterRegion: this === null || this === void 0 ? void 0 : (_this$model11 = this.model) === null || _this$model11 === void 0 ? void 0 : (_this$model11$_data = _this$model11._data) === null || _this$model11$_data === void 0 ? void 0 : _this$model11$_data.transporterRegion
      });
    },

    getAdapterName(model) {
      const docType = model.getData('docType');
      const docName = model.getData('docName');
      return `documents/${docType}/${docName}`;
    },

    updateTransporterTask: (0, _emberConcurrency.task)(function* () {
      const dirty = this.dirty;
      const adapterName = this.getAdapterName(dirty);
      const appendPath = 'transporter';
      let saved;

      if (this.settings.getProp('features.extensions.tw_hct') && dirty._data.transporterExtension === 'hct') {
        // show modal
        const transporterRegion = dirty.getData('transporterRegion');

        if (!transporterRegion) {
          // fetch
          yield this.fetchHCTRegionDataTask.perform(dirty);
        }

        if (!this.isHCTIncomplete(dirty)) {
          saved = yield this.updateRecordTask.perform({
            adapterName,
            appendPath,
            model: dirty
          });
        }
      } else {
        saved = yield this.updateRecordTask.perform({
          adapterName,
          appendPath,
          model: dirty
        });
      }

      if (saved) {
        const dirty = this.dirty;
        this.set('original', {
          transporterLabel: dirty.get('_data.transporterLabel'),
          waybill: dirty.get('_data.waybill'),
          packages: dirty.get('_data.packages'),
          contactPerson: dirty.get('_data.person'),
          address: dirty.get('_data.address'),
          telephone: dirty.get('_data.telephone'),
          dateZ: dirty.get('_data.transporterArrivalDateZ'),
          account: dirty.get('_data.transporterAccountNumber'),
          transporterNotes: dirty.get('_data.transporterNotes'),
          transporterBatchLabel: dirty.get('_data.transporterBatchLabel'),
          transporterRegion: dirty.get('_data.transporterRegion')
        });
        this.set('dirty', '');
      }

      this.setupDirty();
      return saved;
    }),
    preSetIsDispatchedProcessTask: (0, _emberConcurrency.task)(function* () {
      const modelProxy = this.modelProxy;
      modelProxy.set('isSelected', false);
      return yield this.onSetIsDispatchedProcessTask.perform(...arguments);
    }),

    setupDirty() {
      const model = this.model;
      const adapterName = this.getAdapterName(model);
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      this.set('dirty', dirty);
    },

    editTransporter: (0, _emberConcurrency.task)(function* (model = this.model) {
      const adapterName = this.getAdapterName(model);
      const contactKey = model.getData('contactKey');
      const dirty = this.dirty || this.dataManager.getDirtyRecord(adapterName, model); // this.findAndLoadTransporterByKey(dirty, dirty.getData('transporterKey'))

      if (contactKey) {
        const contact = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: contactKey
        });
        dirty.set('selectedContact', contact);
      }

      this.set('dirty', dirty);
    }),
    hasTransporterDataChanged: Ember.computed('dirty._data.{transporterLabel,waybill,packages,person,address,telephone,dateZ,transporterAccountNumber,transporterNotes,transporterBatchLabel,transporterRegion}', 'original.{transporterLabel,waybill,packages,person,address,telephone,dateZ,transporterAccountNumber,transporterNotes,transporterBatchLabel,transporterRegion}', function () {
      var _this$original;

      const dirty = this.dirty;
      const transporterLabel = dirty.get('_data.transporterLabel');
      const waybill = dirty.get('_data.waybill');
      let packages = dirty.get('_data.packages');

      if (R.is(Number)(packages)) {
        packages = R.toString(packages);
      }

      let originalPackages = this === null || this === void 0 ? void 0 : (_this$original = this.original) === null || _this$original === void 0 ? void 0 : _this$original.packages;

      if (R.is(Number)(originalPackages)) {
        originalPackages = R.toString(originalPackages);
      }

      const contactPerson = dirty.get('_data.person');
      const address = dirty.get('_data.address');
      const telephone = dirty.get('_data.telephone');
      const dateZ = dirty.get('_data.transporterArrivalDateZ');
      const account = dirty.get('_data.transporterAccountNumber');
      const transporterNotes = dirty.get('_data.transporterNotes');
      const transporterBatchLabel = dirty.get('_data.transporterBatchLabel');
      const transporterRegion = dirty.get('_data.transporterRegion');

      if (!R.isEmpty(dirty)) {
        const original = this === null || this === void 0 ? void 0 : this.original;

        if ((original === null || original === void 0 ? void 0 : original.transporterLabel) !== transporterLabel || (original === null || original === void 0 ? void 0 : original.waybill) !== waybill || originalPackages !== packages || (original === null || original === void 0 ? void 0 : original.contactPerson) !== contactPerson || (original === null || original === void 0 ? void 0 : original.address) !== address || (original === null || original === void 0 ? void 0 : original.telephone) !== telephone || (original === null || original === void 0 ? void 0 : original.dateZ) !== dateZ || (original === null || original === void 0 ? void 0 : original.account) !== account || (original === null || original === void 0 ? void 0 : original.transporterNotes) !== transporterNotes || (original === null || original === void 0 ? void 0 : original.transporterBatchLabel) !== transporterBatchLabel || (original === null || original === void 0 ? void 0 : original.transporterRegion) !== transporterRegion) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      cancelEditTransporter() {
        const dirty = this.dirty;
        const adapterName = this.getAdapterName(dirty);
        this.dataManager.cancelDirty(adapterName, dirty);
        this.set('dirty', '');
      },

      close(onCloseDialogAction) {
        onCloseDialogAction();
        const onClosePreDispatchProcessDialog = this.onClosePreDispatchProcessDialog;

        if (onClosePreDispatchProcessDialog) {
          onClosePreDispatchProcessDialog();
        }
      }

    }
  });

  _exports.default = _default;
});