define("client/pods/components/websites/panels-display/sub-panels-3rd-party/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9k9ACF8L",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor/3rd-party-editor\",null,[[\"tab\",\"isEditing\",\"isReadonly\",\"model\"],[[24,[\"tab\"]],false,true,[24,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"websites/panels-display/sub-panels-footer-btns\",null,[[\"tab\",\"hasEditBtn\",\"copyErrors\",\"model\",\"dirty\",\"onEditPanel\",\"onPrepareDuplicateRecord\",\"duplicateRecordTask\",\"onAssignSalesPerson\",\"onUnassignSalesPerson\",\"onToggleCommissionMethod\",\"errorMsg\"],[[24,[\"tab\"]],true,[24,[\"copyErrors\"]],[24,[\"model\"]],[24,[\"dirty\"]],[24,[\"onEditPanel\"]],[24,[\"onPrepareDuplicateRecord\"]],[24,[\"duplicateRecordTask\"]],[24,[\"onAssignSalesPerson\"]],[24,[\"onUnassignSalesPerson\"]],[24,[\"onToggleCommissionMethod\"]],[24,[\"errorMsg\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-3rd-party/template.hbs"
    }
  });

  _exports.default = _default;
});