define("client/pods/workflows/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let WorkflowsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class WorkflowsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "workflows", _descriptor4, this);
    }

    *searchWorkflows({
      filters,
      query,
      excludes
    }) {
      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: 'workflows/filters',
          defaultFiltersData: {
            query,
            excludes
          }
        });
      }

      filters.setData('query', query);
      this.workflows = yield this.crud.searchRecordsTask.perform({
        adapterName: 'workflows',
        appendPath: '/type/workflows',
        filters
      });
    }

    setupWorkflowAsDirty({
      model,
      component,
      resultsProperty
    }) {
      const dirty = this.crud.setupDirty({
        adapterName: 'workflows',
        model,
        component,
        resultsProperty
      });
      dirty.removeUnassignedStage();
      return dirty;
    }

    loadWorkflowProps({
      props,
      model
    }, selected) {
      return R.mapObjIndexed((toProp, fromProp) => {
        const fromValue = selected.getData(fromProp);
        model.set(toProp, fromValue);
      })(props);
    }

    unloadWorkflowProps({
      props,
      model
    }, selected) {
      return R.mapObjIndexed((toProp, fromProp) => {
        model.set(toProp, '');
      })(props);
    }

    newWorkflow({
      component,
      resultsProperty,
      workflows,
      type
    }) {
      // get last index
      const lastWorkflow = R.pipe(R.sortWith([R.ascend(R.path(['_data', 'index']))]), _nventor.default.safeLast)(workflows);
      const lastIndex = R.pathOr(-1, ['_data', 'index'])(lastWorkflow);
      const index = lastIndex + 1;
      const newModel = this.crud.setupNewRecord({
        adapterName: 'workflows',
        data: {
          index,
          type
        }
      });

      if (type === 'workflows') {
        newModel.addStage();
      }

      if (resultsProperty) {
        component[resultsProperty] = newModel;
      }

      return newModel;
    }

    newWorkflowItem({
      component,
      workflow,
      resultsProperty
    }) {
      if (RA.isNotNilOrEmpty(workflow)) {
        const newItemData = workflow.getNewItemData();
        const newModel = this.crud.setupNewRecord({
          adapterName: 'workflows/items',
          data: newItemData
        });

        if (resultsProperty) {
          component[resultsProperty] = newModel;
        }

        return newModel;
      }
    }

    newWorkflowItemActivity({
      component,
      workflow,
      item,
      type = 'notes',
      resultsProperty
    }) {
      if (RA.isNotNilOrEmpty(item)) {
        var _item$_data, _item$_data2, _item$_data3;

        const newModel = this.crud.setupNewRecord({
          adapterName: 'workflows/items/activities',
          data: {
            workflowItemKey: (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data._key,
            workflowKey: (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2.workflowKey,
            users: (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3.users,
            type
          }
        });

        if (component && resultsProperty) {
          component[resultsProperty] = newModel;
        }

        return newModel;
      }
    }

    resetNewWorkflowItemActivity({
      component,
      resultsProperty,
      typeProperty
    }) {
      if (component && resultsProperty) {
        component[resultsProperty] = '';
        component[typeProperty] = '';
      }
    }

    *markActivityAsCompleted({
      component,
      activity
    }) {
      const dirty = this.crud.setupDirty({
        adapterName: 'workflows/items/activities',
        model: activity
      });

      if (dirty.getData('isComplete')) {
        const nowZ = this.dateService.getNowZ();
        dirty.setData('dateZCompleted', nowZ);
      } else {
        dirty.setData('dateZCompleted', '');
      }

      yield this.crud.updateRecordTask.perform({
        adapterName: 'workflows/items/activities',
        model: dirty,
        component
      });
    }

    *markItemAsClosed({
      component,
      dirty,
      isReOpen,
      onAfter
    }) {
      // dirty.setData('isSuccessful', isSuccessful)
      // dirty.setData('isClosed', true)
      // const nowZ = this.dateService.getNowZ()
      // dirty.setData('dateZClosed', nowZ)
      // if (isSuccessful) {
      //   dirty.setData('status', 'successful')
      // } else {
      //   dirty.setData('status', 'unsuccessful')
      // }
      if (isReOpen) {
        dirty.setData('status', 'open');
        dirty.setData('reason', '');
        dirty.setData('isSuccessful', false);
        dirty.setData('isClosed', false);
      }

      yield this.crud.updateRecordTask.perform({
        adapterName: 'workflows/items',
        model: dirty,
        component,
        onAfter
      });
    }

    getNextWorkflow({
      dirty,
      workflows,
      nextWorkflowKey
    }) {
      var _nextWorkflow$_data;

      // debugger
      const nextWorkflow = R.find(R.pathEq(['_data', '_key'], nextWorkflowKey))(workflows) || {};
      const stages = (nextWorkflow === null || nextWorkflow === void 0 ? void 0 : (_nextWorkflow$_data = nextWorkflow._data) === null || _nextWorkflow$_data === void 0 ? void 0 : _nextWorkflow$_data.stages) || [];
      const stage = stages.get('firstObject');
      return {
        workflow: nextWorkflow,
        stage
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "workflows", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchWorkflows", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchWorkflows"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupWorkflowAsDirty", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupWorkflowAsDirty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadWorkflowProps", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadWorkflowProps"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unloadWorkflowProps", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "unloadWorkflowProps"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newWorkflow", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "newWorkflow"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newWorkflowItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "newWorkflowItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newWorkflowItemActivity", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "newWorkflowItemActivity"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resetNewWorkflowItemActivity", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "resetNewWorkflowItemActivity"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "markActivityAsCompleted", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markActivityAsCompleted"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "markItemAsClosed", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markItemAsClosed"), _class.prototype)), _class));
  _exports.default = WorkflowsService;
});