define("client/pods/contacts/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/mixins/sales-persons", "client/mixins/contacts-is-parent", "client/mixins/statuses", "client/mixins/contacts-departments"], function (_exports, R, RA, _model, _salesPersons, _contactsIsParent, _statuses, _contactsDepartments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import OptionsModel from '../options/model'
  // import nventor from '../../utils/nventor'
  // details and persons mixins are included with departments
  var _default = _model.default.extend(_salesPersons.default, _contactsIsParent.default, _contactsDepartments.default, _statuses.default, {
    defaults() {
      return {
        isAllowedLinkMember: this.settings.getProp('isAllowedLinkMember', false),
        salesPaymentMethodKey: this.get('constants.paymentMethods.cash'),
        salesTaxMethodKey: this.get('constants.taxMethods.auto'),
        purchasesPaymentMethodKey: this.get('constants.paymentMethods.cash'),
        purchasesTaxMethodKey: this.get('constants.taxMethods.auto'),
        status: this.get('constants.status.active'),
        invoicing: this.get('constants.invoicing.auto'),
        isTransporter: this.get('constants.isTransporter.off')
      };
    },

    /**
     * custom populate function. (overrides data mixin default)
     * will create child detail models
     * @param {object/json} data - model data
     */
    populate(data = {}, attrs) {
      const self = this;
      data = data || {};
      self.transformSalesPersons(data);
      self.transformDetails(data);
      self.transformPersons(data);
      self.transformDepartments(data);
      self.transformTransporterAccounts(data); // populate docName info
      // self.overwriteData(data)

      this._super(data, attrs);
    },

    birthday: Ember.computed('_data.birthday,_data.member.birthday', function () {
      let birthday = this.getData('birthday');

      if (RA.isNilOrEmpty(birthday)) {
        birthday = this.getData('member.birthday');
      }

      return birthday || '';
    }),
    gender: Ember.computed('_data.gender,_data.member.gender', function () {
      let gender = this.getData('gender');

      if (RA.isNilOrEmpty(gender)) {
        gender = this.getData('member.gender');
      }

      return gender || '';
    }),
    otherGender: Ember.computed('_data.otherGender,_data.member.otherGender', function () {
      let otherGender = this.getData('otherGender');

      if (RA.isNilOrEmpty(otherGender)) {
        otherGender = this.get('member.otherGender');
      }

      return otherGender || '';
    }),
    defaultTelephone: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'phone';
      let defaultPhone = this.getDefaultValue({
        type,
        subType: 'telephone'
      });

      if (!defaultPhone) {
        defaultPhone = this.getDefaultValue({
          type,
          subType: 'mobile'
        });
      }

      return defaultPhone;
    }),
    defaultFax: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'phone';
      return this.getDefaultValue({
        type,
        subType: 'fax'
      });
    }),
    defaultEmail: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'email';
      return this.getDefaultValue({
        type
      });
    }),
    defaultAddress: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'address';
      return this.getDefaultValue({
        type
      });
    }),

    getDefaultValue({
      type,
      subType
    }) {
      const detailsData = this._getDetailsList({
        type,
        subType
      });

      return detailsData.defaultValue || '';
    },

    getDetailsList({
      type,
      subType,
      defaultProp = false
    }) {
      const detailsData = this._getDetailsList({
        type,
        subType
      });

      if (defaultProp && detailsData.defaultValue != null) {
        this.set(defaultProp, detailsData.defaultValue);
      }

      return detailsData.detailList;
    },

    _getDetailsList({
      type,
      subType
    }) {
      const detailList = [];
      const defaultList = []; // search for default address in contact.details, contacts.details.departments, etc...
      // store all addresses in addresses list

      const details = this.getData('details', []);

      this._addDetailsToList({
        type,
        subType,
        details,
        detailList,
        defaultList
      });

      const persons = this.getData('persons', []);
      persons.forEach(person => {
        const context = person.getData('name');
        const details = person.getData('details', []);

        this._addDetailsToList({
          type,
          subType,
          details,
          detailList,
          defaultList,
          context
        });
      });
      const departments = this.getData('departments', []);
      departments.forEach(department => {
        const departmentName = department.getData('name');
        const context = departmentName;
        const details = department.getData('details', []);

        this._addDetailsToList({
          type,
          subType,
          details,
          detailList,
          defaultList,
          context
        });

        const deptPersons = department.getData('persons', []);
        deptPersons.forEach(person => {
          const context = departmentName + ': ' + person.getData('name');
          const details = person.getData('details', []);

          this._addDetailsToList({
            type,
            subType,
            details,
            detailList,
            defaultList,
            context
          });
        });
      });
      const defaultValue = this.getDefaultDetailValue({
        detailList,
        defaultList
      });
      return {
        defaultValue,
        defaultList,
        detailList
      };
    },

    getDefaultDetailValue({
      detailList,
      defaultList
    }) {
      let defaultDetail = detailList.get('firstObject');

      if (!Ember.isEmpty(defaultList)) {
        defaultDetail = defaultList.get('firstObject');
      }

      if (defaultDetail) {
        return defaultDetail.getData('value');
      }

      return '';
    },

    hasSales: Ember.computed('_data.relationships.[]', function () {
      const relationships = this.getData('relationships') || [];

      if (relationships.indexOf('customer') > -1) {
        return true;
      }

      return false;
    }),
    hasPurchases: Ember.computed('_data.relationships.[]', function () {
      const relationships = this.getData('relationships') || [];

      if (relationships.indexOf('supplier') > -1) {
        return true;
      }

      return false;
    }),

    _addDetailsToList({
      type,
      subType = false,
      details,
      detailList,
      defaultList,
      context
    }) {
      details.forEach(detail => {
        // context does not belong to _data
        detail.set('context', context);
        let matchSubType = true;

        if (subType) {
          if (detail.getData('subType') !== subType) {
            matchSubType = false;
          }
        }

        if (detail.getData('type') === type && matchSubType) {
          detailList.push(detail);

          if (detail.getData('isDefault')) {
            defaultList.push(detail);
          }
        }
      });
    },

    transformTransporterAccounts(rawData) {
      if (Ember.isArray(rawData.transporterAccounts)) {
        rawData.transporterAccounts = rawData.transporterAccounts.map(data => {
          data.isDefault = rawData.defaultTransporterAccount === data.account;
          return this.dataManager.setAsRecord({
            adapterName: 'base',
            data: data
          });
        });
      }
    }

  });

  _exports.default = _default;
});