define("client/pods/components/channels/shop/campaigns/campaigns-editor/emails-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../../../../utils/nventor';
  // import config from '../../../../../../../config/environment';
  // import { inject as service } from '@ember/service';
  var _default = Ember.Component.extend({});

  _exports.default = _default;
});