define("client/pods/components/news/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let NewsTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class NewsTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dropPosition", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showUploadsRow", _descriptor4, this);
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "isDroppable", true);
      this.crud.addLists(this, ['news/lists/menu', 'news/lists/tags', 'statuses']);
    }

    get rowClassName() {
      return `table-view__detail-row ${this.dropPosition}`;
    }

    get description() {
      const div = document.createElement('div');
      div.innerHTML = R.pathOr('', ['args', 'model', '_data', 'description'])(this);
      return div.innerText;
    }

    drop(indexStart, indexEnd, results) {
      let reIndexNews;

      if (indexStart > indexEnd) {
        reIndexNews = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexNews = R.slice(indexStart, R.inc(indexEnd))(results);
      }

      const master = R.view(R.lensPath([0, '_data', 'master']))(results);
      this.crud.adapterCallTask.perform('updateIndexes', {
        adapterName: 'news/details/batch',
        appendPath: `/${master}/indexes`,
        data: reIndexNews
      });
    }

    dragOver() {
      if (this.args.tableViewOptions.onDragOver) {
        this.dropPosition = this.args.tableViewOptions.onDragOver(...arguments);
      }
    }

    dragStart() {
      if (this.args.tableViewOptions.onDragStart) {
        this.args.tableViewOptions.onDragStart(...arguments);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dropPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showUploadsRow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drop", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragOver", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragStart", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype)), _class));
  _exports.default = NewsTableViewDetailRowComponent;
});