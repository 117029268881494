define("client/pods/search/service", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setupFilters({
      adapterName,
      altStorageProp = false,
      rememberMe = false,
      rememberProps = [],
      defaultFiltersData = {},
      onAfter
    }) {
      const storageProp = altStorageProp || adapterName;

      if (rememberMe === true) {
        defaultFiltersData = this.getFromStorage(storageProp) || defaultFiltersData;
      }

      defaultFiltersData.filtersAdapterName = adapterName;
      defaultFiltersData.storageProp = storageProp;
      defaultFiltersData.rememberMe = rememberMe;
      defaultFiltersData.rememberProps = rememberProps;
      const filters = this.dataManager.setAsRecord({
        adapterName,
        data: defaultFiltersData
      });
      return filters;
    },

    copyFilters({
      filters,
      adapterName,
      altStorageProp = false,
      rememberMe = false,
      lastSearchSettingsProperty = 'lastSearchSettings',
      defaultFiltersData
    }) {
      let toCopyDefaultFiltersData = filters.serialize();
      toCopyDefaultFiltersData = R.mergeRight(toCopyDefaultFiltersData, defaultFiltersData);
      adapterName = adapterName || defaultFiltersData.filtersAdapterName;
      const copy = this.setupFilters({
        adapterName,
        altStorageProp,
        rememberMe,
        defaultFiltersData: toCopyDefaultFiltersData
      });
      copy.set('lastSearchSettingsProperty', lastSearchSettingsProperty);
      return copy;
    },

    getFromStorage(storageProp) {
      storageProp = R.replace('/', '__')(storageProp);
      return this.storage.get(`settings__${storageProp}__filters`) || false;
    },

    setToStorage(filters, rememberProps = false) {
      let storageProp = filters.getData('storageProp');
      storageProp = R.replace('/', '__')(storageProp);
      rememberProps = rememberProps || filters.getData('rememberProps') || [];
      let filterData = filters.serialize();

      if (!R.isEmpty(rememberProps)) {
        filterData = R.pick(rememberProps)(filterData);
      }

      this.storage.set(`settings__${storageProp}__filters`, filterData);
      return filters;
    }

  });

  _exports.default = _default;
});