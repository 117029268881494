define("client/pods/components/documents/editor-main/editor-info/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/big", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _date, _searchContacts, _crud, _big, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * documents editor shared functions
   */
  var _default = Ember.Component.extend(_crud.default, _date.default, _big.default, _searchContacts.default, {
    contactsService: Ember.inject.service('contacts'),
    tagName: '',
    roundingCurrency: 0,
    unroundedCurrency: 8,
    rewardFiltersAdapter: 'channels/shop/rewards/filters',
    rewardAdapter: 'channels/shop/rewards',

    init() {
      this._super(...arguments);

      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters'
      });
      this.set('campaignFilters', campaignFilters);
      const rewardFilters = this.search.setupFilters({
        adapterName: this.rewardFiltersAdapter,
        defaultFiltersData: {
          resultsProperty: 'rewardsList',
          query: ''
        }
      });
      this.set('rewardFilters', rewardFilters);
    },

    didInsertElement() {
      this._super(...arguments);

      const model = this.model;
      this.getAndSetRewardsDataTask.perform({
        model
      });
    },

    isCashBeforeDelivery: Ember.computed('model._data.paymentMethodKey', function () {
      const cashBeforeDelivery = R.pathOr('', ['constants', 'paymentMethods', 'cashBeforeDelivery'])(this);
      return R.pathEq(['model', '_data', 'paymentMethodKey'], cashBeforeDelivery)(this);
    }),
    docContactRelationship: Ember.computed('docType', function () {
      const docType = this.docType;

      if (docType === 'sales') {
        return 'customer';
      }

      return 'supplier';
    }),
    isPaymentMethodReadonly: Ember.computed('isReadonly', 'model._data.{paymentMethodKey,paymentCreditCardTransactionCode,virtualAccount}', function () {
      if (this.model._data.paymentCreditCardTransactionCode && this.model._data.docType === 'sales' && this.model._data.docName === 'invoices') {
        return true;
      }

      if (this.isReadonly) {
        return true;
      }

      const model = this.model;

      if (model.getData('paymentCreditCardTransactionCode')) {
        return true;
      }

      const hasVirtualAccount = _nventor.default.isNilOrEmptyDotPathOr(false, '_data.virtualAccount.virtualAccountNumber')(model);

      const isPaid = R.pathEq(['_data', 'virtualAccount', 'status'], 'paid')(model);

      if (hasVirtualAccount && isPaid) {
        return true;
      }

      return false;
    }),
    showDiscountMethodLabel: Ember.computed('model._data.{discountMethod,discountRate,discountAmount}', function () {
      const model = this.model;
      const discountMethodKey = model.getData('discountMethod');
      const discountRate = model.getData('discountRate');
      const discountAmount = model.getData('discountAmount');
      let showDiscount = true;

      if (discountMethodKey === this.get('constants.discountMethods.onTotal')) {
        // per total
        if ((Ember.isEmpty(discountRate) || discountRate === 0 || discountRate === '0') && (Ember.isEmpty(discountAmount) || discountAmount === 0 || discountAmount === '0')) {
          showDiscount = false;
        }
      }

      return showDiscount;
    }),
    showSettingsAutoPrintInvoices: Ember.computed('model._data.autoPrintInvoices', function () {
      const autoPrintInvoices = this.get('model._data.autoPrintInvoices');

      if (!autoPrintInvoices || autoPrintInvoices === 'auto') {
        return true;
      }

      return false;
    }),
    isQuote: Ember.computed('model._data.docName', function () {
      const docName = this.docName;

      if (docName === 'quotes') {
        return true;
      }

      return false;
    }),

    toggleDeliveryDateZ(deliveryDateOptionModel) {
      const deliveryDateOption = deliveryDateOptionModel.getData('value');
      const model = this.model;
      const details = model.getData('details');

      if (deliveryDateOption === '1') {
        // changing from single to multiple
        const deliveryDateZ = model.getData('deliveryDateZ');
        details.forEach(function (detail) {
          detail.setData('deliveryDateZ', deliveryDateZ);
        });
      } else {
        // changing from multiple to single
        // use latest delivery date in details
        let latestDeliveryDateZ = this.getStartOfDayZ();
        details.forEach(function (detail) {
          if (detail.getData('deliveryDateZ') > latestDeliveryDateZ) {
            latestDeliveryDateZ = detail.getData('deliveryDateZ');
          }
        });
        model.setData('deliveryDateZ', latestDeliveryDateZ);
      }
    },

    /**
     * Observer: re-sums totals when details has changed
     * @type {[type]}
     */
    // detailsHasChanged: observer('recalculateTotals', 'model._data.discountAmount', 'model._data.discountRate', function () {
    //   this.sumTotals()
    // }),

    /**
     * assign sales rep to contact
     */
    assignSalesPerson(person) {
      const model = this.model;
      model.addSalesPerson(person.serialize());
    },

    /**
     * assign sales rep to contact
     */
    unassignSalesPerson(person) {
      const model = this.model;
      model.removeSalesPerson(person);
    },

    fetchRewardTask: (0, _emberConcurrency.task)(function* ({
      query
    }) {
      query = query.trim();

      if (query) {
        const filters = this.rewardFilters;
        const adapterName = this.rewardAdapter;
        filters._data.query = query;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          filters,
          component: this,
          resultsProperty: 'rewardsList'
        });
        return results;
      }
    }),
    rewardName: Ember.computed('model._data.rewardData.name', function () {
      return this.model.getData('rewardData.name') || '';
    }),
    actions: {
      addAndLoadContactAndUpdateDoInfo(onCloseDialogAction, model, contact) {
        onCloseDialogAction();

        if (this.isEditing) {
          model.setData('contact', contact.getData('name'));
          this.onLoadContact(contact);
        } else {
          return this.onLoadContactAndUpdateDoInfo(model, contact); // const dirty = this.get('onSetupDirty')({
          //   adapterName,
          //   model: this.get('model')
          // })
          // dirty.setData('contact', contact.getData('name'))
          // this._loadContact(dirty, docType, contact)
          // return this.get('onUpdateDocInfoTask').linked().perform(dirty)
        } // model.setData('contact', contact.getData('name'))
        // update contact
        // this.get('onLoadContact')(contact)

      },

      cancelAddContact(onCloseDialogAction) {
        onCloseDialogAction();
      },

      loadShopSource(shop) {
        const model = this.model;
        model.set('rewardKey', shop.getData('rewardKey'));
        model.setData('sourceKey', shop.getData('_key'));
        this.getAndSetRewardsDataTask.perform({
          model
        });
      },

      unloadShopSource() {
        const model = this.model;
        model.set('rewardKey', '');
        model.setData('sourceKey', '');
      },

      loadExternalPod(pod) {
        const model = this.model;
        model.setData('externalPodKey', pod.getData('podKey'));
      },

      unloadExternalPod() {
        const model = this.model;
        model.setData('externalPodKey', '');
      },

      loadPaymentMethod(paymentMethod) {
        this.model.loadPaymentMethod({
          paymentMethod
        });
      },

      loadPaymentTerms(paymentTerms) {
        this.model.loadPaymentTerms({
          paymentTerms
        });
      },

      toggleDeliveryDateZ(deliveryDateOptionModel) {
        this.toggleDeliveryDateZ(deliveryDateOptionModel);
      },

      /**
       * assign sales rep to contact
       */
      assignSalesPerson(person) {
        this.assignSalesPerson(person);
      },

      /**
       * unassign sales rep
       */
      unassignSalesPerson(person) {
        this.unassignSalesPerson(person);
      },

      toggleChannelSource(shop) {
        const model = this.model;

        if (shop.getData('_key')) {
          model.setData('source', shop.getData('name'));
        } else {
          model.setData('source', '');
        }
      },

      onLoadRewardItem(selectedItem) {
        const rewardData = R.pipe(R.propOr({}, '_data'), R.pick(['_key', 'name', 'memberLevels']))(selectedItem);
        this.model.setData('rewardData', rewardData);
      },

      onResetRewardItem() {
        this.model.setData('rewardData', {});
      },

      setCarrierType() {
        const model = this.model;

        if (model.getData('taxNumber')) {
          this.model.setData('twEInvoiceCarrierType', 'b2b'); // this.model.setData('twEInvoiceCarrierId', '')
        } else {
          this.model.setData('twEInvoiceCarrierType', ''); // this.model.setData('twEInvoiceCarrierId', '')
        }
      }

    }
  });

  _exports.default = _default;
});