define("client/pods/reports/commissions/data/adjustment/model", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var _default = _model.default.extend((_dec = Ember._action, (_obj = {
    defaults() {
      return {
        selectedCommissionBaseCostMethod: 'normalPercent',
        isCalculatingCommissionsByExclAmt: true
      };
    },

    populate(data = {}) {
      data = data || {};
      data = this.transformDetails(data); // populate docName info

      this.overwriteData(data);

      this._super(...arguments);
    },

    transformDetails(rawData) {
      // const self = this
      rawData.details = R.pipe(R.pathOr([], ['details']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'reports/commissions/data/adjustment/details',
          data
        });
      }))(rawData);
      return rawData;
    },

    addNewRow(data) {
      if (RA.isNilOrEmpty(data)) {
        data = {
          item: '',
          itemKey: '',
          manual: true // commissionBaseCost: '',

        };
      }

      const detailModel = this.dataManager.setAsRecord({
        adapterName: 'reports/commissions/data/adjustment/details',
        data
      });
      let details = this.getData('details') || [];
      details.pushObject(detailModel);
      return detailModel;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "addNewRow", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewRow"), _obj)), _obj)));

  _exports.default = _default;
});