define("client/pods/components/extensions/apps/tw/hct/settings-editor/component", ["exports", "client/mixins/crud", "client/mixins/search-contacts", "ember-concurrency", "ramda-extension"], function (_exports, _crud, _searchContacts, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, {
    crud: Ember.inject.service(),
    tagName: '',
    showPasswordEditor: false,
    transporterExtension: 'hct',

    init() {
      this.crud.addLists(this, ['contacts/transporters']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const transporters = R_.dotPath('crud.lists.contacts-transporters')(this) || [];
      const transporterModel = transporters.findBy('_data.transporterExtension', this.transporterExtension);

      if (transporterModel) {
        this.set('transporterModel', transporterModel);
      } else {
        this.set('transporterModel', false);
      }
    },

    unlinkTransporterTask: (0, _emberConcurrency.task)(function* ({
      model
    }) {
      const msg = this.intl.t('are you sure you want to remove');

      if (window.confirm(msg)) {
        const adapterName = 'contacts/transporters';
        const dirty = this.setupDirty({
          adapterName,
          model: model
        });
        dirty.setData('transporterExtension', '');
        yield this.updateRecordTask.perform({
          adapterName,
          model: dirty
        });
        this.set('transporterModel', false);
      }
    }).drop(),
    actions: {
      loadTransporter(model) {
        this.set('transporterModel', model);
      }

    }
  });

  _exports.default = _default;
});