define("client/pods/components/color-themes/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ColorThemesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ColorThemesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "colorsResults", _descriptor3, this);

      if (!this.args.displaySelected) {
        this.colorsFilters = this.search.setupFilters({
          adapterName: 'websites/filters',
          defaultFiltersData: {
            resultsProperty: 'colorsResults',
            match: ['all']
          }
        });
        this.searchTask.perform();
      }
    }

    *searchTask() {
      const q = [];
      const query = this.query;
      q.push(this.searchColorTemplatesTask.perform({
        query,
        filters: this.colorsFilters
      }));
      yield (0, _emberConcurrency.all)(q);

      if (this.args.autoSelect) {
        this.selectColorTheme(this.colorsResults[0]);
      }
    }

    *searchColorTemplatesTask({
      adapterName = 'websites',
      query,
      filters
    }) {
      const resultsProperty = R.pathOr('', ['_data', 'resultsProperty'])(filters);
      this[resultsProperty] = yield this.crud.searchRecordsConcurrentlyTask.unlinked().perform({
        adapterName,
        appendPath: 'sharedColorThemes',
        filters,
        query
      });
      return this[resultsProperty];
    }

    displayColorTemplateModal() {
      this.args.toggleColorTemplates();
    }

    selectColorTheme(selectedTheme) {
      if (this.args.isReadonly) {
        return;
      }

      if (this.args.displaySelected) {
        return this.displayColorTemplateModal(...arguments);
      }

      const themeName = R.pathOr('', ['_data', 'name'])(selectedTheme);
      R.pipe(R.pathOr({}, ['_data']), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme/))), R.fromPairs, R.mapObjIndexed((value, key) => {
        this.args.model.setData(key, value);
      }))(selectedTheme);
      this.args.model.setData('themeName', themeName);
      const onSelect = this.args.onSelect;

      if (onSelect) {
        onSelect(selectedTheme);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "colorsResults", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchColorTemplatesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchColorTemplatesTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayColorTemplateModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "displayColorTemplateModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectColorTheme", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectColorTheme"), _class.prototype)), _class));
  _exports.default = ColorThemesComponent;
});