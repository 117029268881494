define("client/pods/components/menus/tab-menu/tab-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['tab-item'],
    classNameBindings: ['isActive:is-active'],
    translate: true,
    menuLabelKey: '_data.label',

    click() {
      const item = this.item;
      this.onSelect(item);
    },

    isActive: Ember.computed('selected', function () {
      return this.selected === this.item;
    }),
    label: Ember.computed('menuLabelKey', 'item.synced', function () {
      const item = this.item;
      const labelKey = this.menuLabelKey;
      return item.get(labelKey);
    })
  });

  _exports.default = _default;
});