define("client/pods/components/documents/editor-main/editor-btns-display/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    server: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    showVerticalDispatchProcessBar: false,
    isReQueued: false,
    showReQueueBtn: Ember.computed('model._data.isTempDocNo', function () {
      var _this$model, _this$model$_data, _this$model2, _this$model2$_data;

      const twoMinLater = this.dateService.getMoment((_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : _this$model$_data.timestampZ).add(2, 'minutes');
      const nowZ = this.dateService.getNowZ();

      if (twoMinLater.isAfter(nowZ)) {
        return false;
      }

      if ((_this$model2 = this.model) !== null && _this$model2 !== void 0 && (_this$model2$_data = _this$model2._data) !== null && _this$model2$_data !== void 0 && _this$model2$_data.isTempDocNo) {
        return true;
      }

      return false;
    }),
    reQueueToGenerateDocumentNumber: (0, _emberConcurrency.task)(function* (model) {
      var _model$_data, _model$_data2, _model$_data3;

      const results = yield this.server.call('PATCH', `api/protected/documents/${model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.docType}/${model === null || model === void 0 ? void 0 : (_model$_data2 = model._data) === null || _model$_data2 === void 0 ? void 0 : _model$_data2.docName}/number/${model._data._key}`, {
        tempDocNo: model === null || model === void 0 ? void 0 : (_model$_data3 = model._data) === null || _model$_data3 === void 0 ? void 0 : _model$_data3.docNo
      });
      this.set('isReQueued', true);
    }).drop()
  });

  _exports.default = _default;
});