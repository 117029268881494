define("client/pods/components/contacts/panels-dash/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'contacts',

    didInsertElement() {
      this._super(...arguments);

      this.tabsManager.setHeader(this.tabParent, '');
    }

  });

  _exports.default = _default;
});