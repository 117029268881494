define("client/pods/websites/pages/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda", "client/pods/base/model", "client/utils/nventor", "client/mixins/templates"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, R, _model, _nventor, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  const WebsitesPagesBaseModel = _model.default.extend(_templates.default, {
    defaults() {
      return {
        css: '',
        hasTemplate: false,
        html: '',
        pageDataString: '{"html":"","css":"* { box-sizing: border-box; } body {margin: 0;}","assets":[],"styles":[],"components":[]}',
        products: [],
        showOnMenu: true,
        templateImages: [],
        type: this.get('constants.websitePageTypes.page')
      };
    },

    isPage: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.page')) {
        return true;
      }

      return false;
    }),
    isLink: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.link')) {
        return true;
      }

      return false;
    }),
    isProductLink: Ember.computed('isLink', '_data.linkType', function () {
      const linkType = this.get('_data.linkType');

      if (this.isLink && linkType === this.get('constants.websitePageLinkTypes.product')) {
        return true;
      }

      return false;
    }),
    previewUrl: Ember.computed('_data.{_key,_rev,version}', 'synced', function () {
      const pageKey = this.get('_data._key');
      const version = this.get('_data.version');
      const previewHostingUrl = this.get('settings.config.APP.previewHostingUrl');
      const podKey = this.settings.getPodKey();
      return `${previewHostingUrl}/${podKey}/${pageKey}/${version}-preview.html`;
    }),
    isParent: Ember.computed('sub.[]', 'synced', function () {
      const sub = this.sub || [];

      if (R.isEmpty(sub)) {
        return false;
      }

      return true;
    }),
    lastParentSegment: Ember.computed('_data.parent', function () {
      const allParentKeys = this.get('_data.parent') || '';
      return R.pipe(R.split('/'), _nventor.default.safeLast)(allParentKeys);
    }),
    pageDataJson: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');

      try {
        return JSON.parse(pageDataString);
      } catch (e) {
        return '';
      }
    }),
    templateFromPageDataString: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');

      try {
        const pageData = JSON.parse(pageDataString);
        return R.propOr('', 'html', pageData);
      } catch (e) {
        return '';
      }
    }),
    pageName: Ember.computed('_data.{name,pageName,_rev}', 'synced', function () {
      const pageData = R.pathOr({}, ['_data'])(this);
      const pageName = R.prop('pageName')(pageData);

      if (pageData.isHome) {
        return pageName || this.intl.t('home page');
      }

      if (pageData.isDynamicPage) {
        return pageName || this.intl.t(pageData.name);
      }

      return R.propOr('no-name', 'name')(pageData);
    }),

    onSelectType() {
      const type = this.get('_data.type');

      if (type === this.get('constants.websitePageTypes.link')) {
        this.setData('linkType', this.get('constants.websitePageLinkTypes.url'));
        this.setData('hasTemplate', false);
      } else {
        this.setData('linkType', '');
        this.setData('hasTemplate', true);
      }
    },

    serialize(model) {
      const data = this._super(model); // dont allow '__' in url


      let url = data.url || '';
      url = R.replace(/__/g, '')(url);
      url = R.replace(/--/g, '')(url);
      data.url = url;
      return data;
    }

  });

  let WebsitesPagesModel = (_dec = Ember._tracked, (_class = (_temp = class WebsitesPagesModel extends WebsitesPagesBaseModel {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WebsitesPagesModel;
});