define("client/pods/components/export/worksheet/editor/customworksheet-nested/customworksheet-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda, RA, _ramdaExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let ExportWorksheetEditorCustomspecItem = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class ExportWorksheetEditorCustomspecItem extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor, this);
      (0, _defineProperty2.default)(this, "tagName", 'span');
      (0, _defineProperty2.default)(this, "classNames", []);
      (0, _defineProperty2.default)(this, "translate", true);
      (0, _defineProperty2.default)(this, "item", null);
      (0, _defineProperty2.default)(this, "isViewable", true);
      (0, _defineProperty2.default)(this, "labelKey", 'label');
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "hasDropBelowContainer", true);
      (0, _defineProperty2.default)(this, "hasDropAboveContainer", true);
      (0, _defineProperty2.default)(this, "draggable", true);
    }

    get availableCustomWorksheetAndOwnWorksheets() {
      var _this$args, _this$args$specsColle, _this$args$specsColle2, _this$args2, _this$args3, _customSheet$_data;

      const originalList = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$specsColle = _this$args.specsCollection) === null || _this$args$specsColle === void 0 ? void 0 : (_this$args$specsColle2 = _this$args$specsColle._data) === null || _this$args$specsColle2 === void 0 ? void 0 : _this$args$specsColle2.custom) || [];
      const availableCustomWorksheets = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.availableCustomWorksheets) || [];

      if (RA.isNilOrEmpty(availableCustomWorksheets)) {
        return [];
      }

      const customSheet = this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.customSheet;

      const customSpec = _ramda.default.find(_ramdaExtension.default.dotPathEq('_data._key', customSheet === null || customSheet === void 0 ? void 0 : (_customSheet$_data = customSheet._data) === null || _customSheet$_data === void 0 ? void 0 : _customSheet$_data.value))(originalList);

      return _ramda.default.insert(1, customSpec, availableCustomWorksheets);
    }

    get _hasDropAboveContainer() {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }

      return false;
    }

    drop() {
      if (this.onDrop) {
        this.onDrop(...arguments);
      }
    }

    select(disabled, item) {
      if (!disabled) {
        this.onSelect(item);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drop", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = ExportWorksheetEditorCustomspecItem;
});