define("client/pods/components/reports/commissions/panels-all/payout-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let ReportsCommissionsPanelsAllPayoutEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class ReportsCommissionsPanelsAllPayoutEditor extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "payoutModel", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "originalEditAmt", _descriptor7, this);
    }

    get isAllowed() {
      if (this.args.isEditing) {
        return true;
      } // THIS LOGIC IS USED IF PAYOUTS IS ONLY ALLOWED ON LATEST MONTH
      // REMOVED FOR NOW
      // const mm = this?.args?.mm || {}
      // const yyyy = this?.args?.yyyy || {}
      // const period = this.date.getPeriod(this.date.getNowZ())
      // const amount = Number(this?.args?.model?._data?.outstandingTotal) || 0
      // if (mm.value === period.mm && yyyy.value === period.yyyy) {
      //   return true
      // }
      // return false


      return true;
    }

    cancel(dirty, onCloseDialogAction) {
      this.crud.cancelEditing({
        adapterName: 'reports/commissions/data/payouts',
        dirty
      });

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    closeModalDialogAndReloadTab(onCloseDialogAction) {
      this.args.onReloadAfterPayout();
      onCloseDialogAction();
    }

    get maxPayoutWithEditAmt() {
      var _this$args;

      const originalEditAmt = this.originalEditAmt;
      const maxPayoutAmount = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.maxPayoutAmount) || 0;
      return parseInt(originalEditAmt) + parseInt(maxPayoutAmount);
    }

    createModel(model) {
      var _this$args2, _this$args2$payoutMod, _this$args2$payoutMod2;

      this.originalEditAmt = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$payoutMod = _this$args2.payoutModel) === null || _this$args2$payoutMod === void 0 ? void 0 : (_this$args2$payoutMod2 = _this$args2$payoutMod._data) === null || _this$args2$payoutMod2 === void 0 ? void 0 : _this$args2$payoutMod2.amount) || 0;

      if (this.args.isEditing) {
        const payoutModel = this.dataManager.setAsRecord({
          adapterName: 'reports/commissions/data/payouts',
          data: this.args.payoutModel
        });
        this.payoutModel = this.crud.setupDirty({
          adapterName: 'reports/commissions/data/payouts',
          model: payoutModel,
          component: this
        }); // this.maxPayoutWithEditAmt = parseInt(this.payoutModel._data.amount) + parseInt(this.args.maxPayoutAmount)
      } else {
        var _model$_data;

        const todayZ = this.date.getStartOfDayZ();
        const payoutModel = this.crud.setupNewRecord({
          adapterName: 'reports/commissions/data/payouts',
          data: {
            dateZ: todayZ,
            amount: model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.outstandingTotal,
            comments: '',
            salesPerson: model._data.salesPerson,
            salesPersonKey: model._data.salesPersonKey
          }
        }); // this.maxPayoutWithEditAmt = 0 + parseInt(this.args.maxPayoutAmount)

        this.payoutModel = payoutModel;
      }

      this.updateDateParams();
    }

    updateDateParams() {
      var _payoutModel$_data;

      const payoutModel = this.payoutModel;
      const dateZ = payoutModel === null || payoutModel === void 0 ? void 0 : (_payoutModel$_data = payoutModel._data) === null || _payoutModel$_data === void 0 ? void 0 : _payoutModel$_data.dateZ;
      const period = this.date.getPeriod(dateZ);
      payoutModel.setData('ref', period.monthStart); // payoutModel.setData('dateZ', period.dateZ)

      payoutModel.setData('mm', period.mm);
      payoutModel.setData('yyyy', period.yyyy);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "payoutModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "originalEditAmt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeModalDialogAndReloadTab", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeModalDialogAndReloadTab"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createModel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "createModel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateDateParams", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateDateParams"), _class.prototype)), _class));
  _exports.default = ReportsCommissionsPanelsAllPayoutEditor;
});