define("client/pods/channels/shop/rewards/member-levels/rewards-events/model", ["exports", "client/pods/base/model", "client/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        type: _constants.default.rewardEvents.order,
        earnRequirementType: _constants.default.rewardOrderTypes.total,
        earnRequirementValue: '',
        earnType: _constants.default.rewardEarningTypes.rate,
        earnRate: '',
        earnAmount: '',
        startPeriod: _constants.default.timePeriods.days,
        startTime: 0,
        expiryPeriod: _constants.default.timePeriods.years,
        expiryTime: 1,
        isNeverExpire: false,
        spendMaximumType: _constants.default.rewardMaxDeductionType.amount,
        spendMaximumRate: '',
        spendMaximumAmount: '',
        ratePerPoint: '',
        allowOnSale: false,
        allowWithPromoCode: false,
        messageSuccess: '',
        status: _constants.default.status.active
      };
    },

    populate(data, attrs) {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});