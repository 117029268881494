define("client/pods/components/channels/shop/shipping-zones/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cfy1/2TJ",
    "block": "{\"symbols\":[\"@tab\",\"@errors\",\"@onEdit\",\"@onDash\"],\"statements\":[[5,\"channels/shop/shipping-zones/shipping-zones-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@onEdit\",\"@onDash\"],[[23,1,[]],[23,1,[\"model\"]],[23,2,[]],false,false,[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});