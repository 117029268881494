define("client/pods/components/extensions/sub/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JU5FrHK7",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__header-title\"],[8],[0,\"\\n\\n\\t\\t\"],[1,[28,\"menus/tab-menu\",null,[[\"menu\",\"classNames\",\"itemClassNames\",\"onSelect\"],[[23,0,[\"crud\",\"lists\",\"extensions-lists-submenu\"]],\"u-tab-menu\",\"u-tab-menu__item\",[28,\"fn\",[[24,[\"tabsManager\",\"triggerMenuAction\"]],[23,0,[]]],null]]]],false],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\\n\"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content u-section\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchOrdersTask\",\"isRunning\"]]]],{\"statements\":[[0,\"    \\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onFetch\"],[[24,[\"tab\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"fetch\"],null]]]],false],[0,\"\\n    \\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/sub/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});