define("client/pods/components/products/products-picker/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  let ProductsProductsPickerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class ProductsProductsPickerComponent extends _component.default {
    constructor() {
      var _this$args, _this$args$campaign, _this$args$campaign$_, _this$args2, _this$args2$campaign, _this$args2$campaign$, _this$args3, _this$args3$campaign, _this$args3$campaign$;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsProducts", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "drafts", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "filtersForDrafts", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "step", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "productStep", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "currentDraftModel", _descriptor11, this);
      (0, _defineProperty2.default)(this, "adapterName", 'products');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'products/filters');
      this.crud.addLists(this, ['products/lists/filters/search', 'products/lists/filters/sort', 'products/lists/filters/status', 'products/lists/tags', 'products/lists/types', 'filters/count', 'filters/sortDirection']);
      this.filtersForDrafts = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          resultsProperty: 'drafts',
          count: 1,
          page: 1,
          status: this.constants.productsStatus.draft,
          master: (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$campaign = _this$args.campaign) === null || _this$args$campaign === void 0 ? void 0 : (_this$args$campaign$_ = _this$args$campaign._data) === null || _this$args$campaign$_ === void 0 ? void 0 : _this$args$campaign$_._key,
          sort: [{
            by: 'timestampZ_latest',
            direction: 'ASC'
          }]
        }
      });
      this.filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'results',
          appendPath: `campaigns/${(_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$campaign = _this$args2.campaign) === null || _this$args2$campaign === void 0 ? void 0 : (_this$args2$campaign$ = _this$args2$campaign._data) === null || _this$args2$campaign$ === void 0 ? void 0 : _this$args2$campaign$._key}`,
          count: 20,
          page: 1,
          campaignKey: (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$campaign = _this$args3.campaign) === null || _this$args3$campaign === void 0 ? void 0 : (_this$args3$campaign$ = _this$args3$campaign._data) === null || _this$args3$campaign$ === void 0 ? void 0 : _this$args3$campaign$._key
        }
      });
      this.customSearchTask.perform();
    }

    *customSearchTask() {
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName,
        filters,
        component: this
      });

      this._setResultsAsDirty({
        filters
      });
    }

    *customSearchAdvancedTask() {
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchAdvancedTask.perform({
        adapterName,
        filters,
        component: this
      });

      this._setResultsAsDirty({
        filters
      });
    }

    *customPaginateTask({
      filters
    }) {
      const adapterName = this.adapterName;
      filters.setData('query', '');
      yield this.crud.paginateSearchRecordsTask.perform({
        adapterName,
        filters,
        component: this
      });

      this._setResultsAsDirty({
        filters
      });
    }

    _setResultsAsDirty({
      filters
    }) {
      let results = filters.get('results') || [];
      results = R.pipe(R.map(product => {
        var _product$_data;

        const itemKey = product === null || product === void 0 ? void 0 : (_product$_data = product._data) === null || _product$_data === void 0 ? void 0 : _product$_data._key;

        if (itemKey) {
          const dirty = this.crud.setupDirty({
            adapterName: 'products',
            model: product
          });
          let isSelected = false;
          const alreadyAdded = R_.dotPath('_data.campaignDetail.campaignDetailKey')(product);

          if (alreadyAdded) {
            isSelected = true;
          }

          dirty.set('isSelected', isSelected);
          dirty.set('itemKey', itemKey); // dirty.set('product', product)

          dirty.set('campaignDetail', alreadyAdded);
          return dirty;
        }

        return null;
      }), R.reject(RA.isNilOrEmpty))(results);
      filters.set('results', results);
    }

    *loadDraftData() {
      const adapterName = 'channels/shop/campaigns/details';
      const filters = this.filtersForDrafts;
      filters.setData('query', '');
      return yield this.crud.searchRecordsTask.perform({
        adapterName,
        filters,
        component: this
      });
    }

    *addProductTask(product, toggleValue) {
      const campaign = this.args.campaign;

      if (toggleValue) {
        var _this$constants, _this$constants$produ;

        // campaignsProducts
        const detail = campaign.addDetail({
          status: this === null || this === void 0 ? void 0 : (_this$constants = this.constants) === null || _this$constants === void 0 ? void 0 : (_this$constants$produ = _this$constants.productsStatus) === null || _this$constants$produ === void 0 ? void 0 : _this$constants$produ.draft
        });

        try {
          yield this.campaignsProducts.loadProductTask.perform(this.args.campaign, detail, product);
          const created = yield this.crud.createRecordTask.perform({
            adapterName: detail.adapterName,
            model: detail
          });

          if (created) {
            product.set('campaignDetail', created);
          }

          if (!created) {
            product.set('isSelected', !toggleValue);
          }
        } catch (e) {
          product.set('isSelected', !toggleValue);
          return false;
        }
      } else {
        try {
          // const drafts = this.drafts || []
          // const detail = R.find(R.pathEq(['_data', 'itemKey'], product?._data?._key))(drafts)
          const campaignDetail = product.campaignDetail;

          if (campaignDetail) {
            const removed = yield this.crud.removeRecordTask.perform({
              adapterName: campaignDetail.adapterName,
              model: campaignDetail
            });

            if (removed) {
              // this.drafts.removeObject(campaignDetail)
              product.set('campaignDetail', '');
            } else {
              product.set('isSelected', !toggleValue);
            }
          }
        } catch (e) {
          product.set('isSelected', !toggleValue);
          return false;
        }
      }
    } // get resultsProxy () {
    //   const products = this.results || []
    //   debugger
    //   // const campaignDetails = this.args.campaignDetails || []
    //   // const drafts = this.drafts || []
    //   return R.pipe(
    //     R.map(product => {
    //       const itemKey = product?._data?._key
    //       if (itemKey) {
    //         let isSelected = false
    //         const alreadyAdded = R_.dotPath('_data.campaignDetail.campaignDetailKey')(product)
    //         if (alreadyAdded) {
    //           isSelected = true
    //         }
    //         return EmberObject.create({
    //           isSelected,
    //           itemKey,
    //           product,
    //           campaignDetail: alreadyAdded
    //         })
    //       }
    //     }),
    //     R.reject(RA.isNilOrEmpty)
    //   )(products)
    // }
    // @action
    // onAfterSearch (results) {
    //   debugger
    //   const productsArray = this.productsArray || []
    //   return R.map(model => {
    //     const isInArray = R.pipe(
    //       R.find(R.pathEq(['_data', 'itemKey'], model._data.itemKey)),
    //       RA.isNilOrEmpty,
    //       R.not
    //     )(productsArray)
    //     if (isInArray) {
    //       model.set('isSelected', true)
    //     } else {
    //       model.set('isSelected', false)
    //     }
    //     return model
    //   })(results)
    // }


    addAndLoadProduct(onCloseDialogAction, campaign, product) {
      onCloseDialogAction();
      this.customSearchTask.perform(); // @TODO: toggle the product on?
    }

    onCancel() {
      this.args.onDone();
    }

    *nextStepTask() {
      this.step = this.step + 1;
      this.productStep = this.productStep + 1;
      Ember.set(this, 'currentDraftModel', ''); // const drafts = this.drafts || []

      const drafts = yield this.loadDraftData.perform();
      const currentDraftModel = R.path([0])(drafts);

      if (currentDraftModel) {
        // const dirty = this.crud.setupDirty({
        //   adapterName: 'channels/shop/campaigns/details',
        //   model: currentDraftModel,
        //   resultsProperty: ''
        // })
        setTimeout(() => {
          Ember.set(this, 'currentDraftModel', currentDraftModel);
        }, 100);
        return currentDraftModel;
      }

      Ember.set(this, 'currentDraftModel', '');
      return this.args.onDone();
    }

    nextProduct() {
      this.productStep = this.productStep + 1;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drafts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filtersForDrafts", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "step", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productStep", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentDraftModel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSearchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customSearchTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSearchAdvancedTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customSearchAdvancedTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customPaginateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customPaginateTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadDraftData", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadDraftData"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addProductTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addAndLoadProduct", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addAndLoadProduct"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCancel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nextStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "nextStepTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nextProduct", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "nextProduct"), _class.prototype)), _class));
  _exports.default = ProductsProductsPickerComponent;
});