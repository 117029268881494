define("client/pods/components/contacts/panels-display/sub-panels-details/component", ["exports", "client/mixins/crud", "ramda-adjunct"], function (_exports, _crud, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    editAction: 'edit',
    updateAction: 'update',
    dashAction: 'dash',
    showDetailEditor: false,
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    init: function () {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes', 'statuses', 'contacts/lists/relationships', 'contacts/lists/detailTypes']);
    },
    // lowestVariantOldPriceRange: computed('_data.{variantChildren.[],price,priceDiscounted}', function () {
    //   const variantChildren = this.get('_data.variantChildren') || []
    actions: {}
  });

  _exports.default = _default;
});