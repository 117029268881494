define("client/pods/components/workflows/tab-container/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda-adjunct", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, RA, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  let WorkflowsTabContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('workflows'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class WorkflowsTabContainerComponent extends _component.default {
    // @tracked filtersAdapterName = 'workflows/filters'
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "workflowsService", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "adapterName", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "currentWorkflow", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor10, this);
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'workflows/stagesCollection/filters');
      (0, _initializerDefineProperty2.default)(this, "newModel", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "newItemModel", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "showSideBar", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "status", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "userKey", _descriptor15, this);
      const type = R.path(['args', 'tab', 'type'])(this);
      this.crud.addLists(this, ['users/related', 'workflows/lists/menu', 'workflows/lists/items/statuses', {
        name: 'workflows',
        appendPath: `type/${type}`
      }]); // this.filters = this.search.setupFilters({ adapterName: this.filtersAdapterName })
      // this.filters.setData('status', 'open')

      this.filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          status: ['open'],
          users: [],
          sort: [{
            by: 'timestampZ',
            direction: 'ASC'
          }]
        }
      }); // this.dash()
    }

    get workflowsForType() {
      var _this$args$tab;

      const listId = this.crud.getListId({
        adapterName: 'workflows',
        appendPath: `type/${(_this$args$tab = this.args.tab) === null || _this$args$tab === void 0 ? void 0 : _this$args$tab.type}`
      });
      return this.crud.lists[listId] || [];
    } // get workflowsForTypeGrouped () {
    //   const workflows = this.workflowsForType || []
    //   return R.groupBy(R.propOr('', 'tags'))(workflows)
    // }


    get allowedStatuses() {
      const statuses = this.crud.lists['workflows-lists-items-statuses'] || [];

      if (this.currentWorkflow) {
        var _this$currentWorkflow, _this$currentWorkflow2;

        const hasNextWorkflowKey = (_this$currentWorkflow = this.currentWorkflow) === null || _this$currentWorkflow === void 0 ? void 0 : (_this$currentWorkflow2 = _this$currentWorkflow._data) === null || _this$currentWorkflow2 === void 0 ? void 0 : _this$currentWorkflow2.nextWorkflowKey;

        if (hasNextWorkflowKey) {
          return R.reject(R.pathEq(['_data', 'value'], 'successful'))(statuses);
        }
      }

      return statuses;
    } // @task
    // * fetchDefaultDataTask () {
    //   return yield this.crud.searchRecordsTask.perform({
    //     adapterName: this.adapterName,
    //     filters: this.filters,
    //     component: this
    //   })
    // }
    // @action
    // dash (model, results) {
    //   // const filters = this.filters
    //   // if (RA.isNilOrEmpty(filters.results)) {
    //   //   const adapterName = this.adapterName
    //   //   filters.setData('query', '')
    //   //   this.fetchDefaultDataTask.perform({ adapterName, filters })
    //   // }
    //   const subTabOptions = {
    //     component: 'workflows/panels-dash'
    //   }
    //   return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions)
    // }


    display(workflow) {
      var _workflow$_data;

      const workflowKey = workflow === null || workflow === void 0 ? void 0 : (_workflow$_data = workflow._data) === null || _workflow$_data === void 0 ? void 0 : _workflow$_data._key;
      this.filters.setData('workflowKey', workflowKey);
      this.filters.setData('status', [this.status]);
      const subTabOptions = {
        component: 'workflows/panels-display',
        model: workflow
      };
      this.currentWorkflow = workflow;
      this.showSideBar = false;
      return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions);
    }

    displayItem(item) {
      const subTabOptions = {
        component: 'workflows/panels-display-item',
        model: item
      };
      return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions);
    } // @task
    // * updateWorkflowIndexesOnDrop (fromModelIndex, droppedAtIndex, results) {
    //   debugger
    //   // const workflows = this.filters?.results || []
    //   // const workflowModel = R.find(R.pathEq(['_data', '_key'], fromWorkflowKey))(workflows)
    //   // workflowModel.setData('index', toWorkflowIndex)
    //   // yield this.crud.updateRecordTask.perform({
    //   //   adapterName: 'workflows',
    //   //   model: workflowModel,
    //   //   component: this
    //   // })
    // }


    updateWorkflowIndexesOnDrop(indexStart, indexEnd, results) {
      let reIndexBlogs;

      if (indexStart > indexEnd) {
        reIndexBlogs = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexBlogs = R.slice(indexStart, R.inc(indexEnd))(results);
      }

      this.crud.adapterCallTask.perform('updateIndexes', {
        adapterName: 'workflows/batch',
        appendPath: '/indexes',
        data: reIndexBlogs
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "workflowsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "adapterName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'workflows';
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentWorkflow", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newModel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newItemModel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showSideBar", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "status", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'open';
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userKey", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "display", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayItem", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "displayItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateWorkflowIndexesOnDrop", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateWorkflowIndexesOnDrop"), _class.prototype)), _class));
  _exports.default = WorkflowsTabContainerComponent;
});