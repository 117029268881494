define("client/pods/components/transporters/hct-editor/component", ["exports", "ramda", "client/mixins/date", "client/utils/nventor", "client/mixins/search-contacts", "ember-concurrency", "client/mixins/transporter-accounts", "client/mixins/hct-fetch"], function (_exports, R, _date, _nventor, _searchContacts, _emberConcurrency, _transporterAccounts, _hctFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _hctFetch.default, _searchContacts.default, _transporterAccounts.default, {
    crud: Ember.inject.service(),
    // @TODO: figure out a way to handle hct extension. since it will be an external extension, how will model
    // @TODO: know that that data is incomplete?
    // @TODO: move transporter into a modal dialog if isHCT
    // @TODO: then ater processing hct data (on server) then respond with isComplete.
    tagName: 'div',
    isReadonly: false,
    isPickerOnly: false,
    // true: only has transport selector. false: will have additional form info
    isModal: false,
    requestTimer: '',
    // used to keep track of latest timer for when making hct calls
    autoFetchTransporter: true,

    async init() {
      this.crud.addLists(this, ['extensions/apps/tw/hct/batches']);
      this.autoFetchTransporter = R.propOr(true, 'autoFetchTransporter')(this);

      this._super(...arguments);

      const model = this.model;
      const transporterKey = model.getData('transporterKey');

      if (!this.transporter && transporterKey && this.autoFetchTransporter) {
        const transporter = await this.findTask.perform({
          adapterName: 'contacts',
          appendPath: transporterKey
        });
        this.set('transporter', transporter);
      }
    },

    afterLoadLists() {
      this._super(...arguments);

      const model = this.model;

      if (model.getData('transporterExtension') === 'hct') {
        if (!model.getData('transporterAccountNumber')) {
          this.setDefaultHCTAccount(model);
        }

        if (this.fetchHCTRegionDataOnInit && model.getData('transporterExtension') === 'hct' && !model.getData('transporterRegion')) {
          this.fetchHCTRegionDataTask.perform(model);
        }
      }
    },

    /**
     * disable if component isReadonly or if has already been despatched
     * @return {[type]} [description]
     */
    isDisabled: Ember.computed('isReadonly', 'model._data.transporterIsDispatched', 'disabled', function () {
      if (!R.isNil(this.disabled)) {
        return this.disabled;
      }

      const isReadonly = this.isReadonly;
      const model = this.model;
      const isDispatched = model.getData('transporterIsDispatched');

      if (isReadonly || isDispatched) {
        return true;
      }

      return false;
    }),

    setDefaultHCTAccount(model) {
      model.setData('transporterBatchNumber', '1');
      model.setData('transporterBatchLabel', '1');

      if (!model.getData('transporterAccountNumber')) {
        model.setData('transporterAccountNumber', this.defaultTransporterAccountNumber);

        if (!model.getData('packages')) {
          model.setData('packages', '1');
        }

        this.setHCTWeightRate(this.defaultTransporterAccount);
      }
    },

    unsetDefaultHCTAccount(model) {
      model.setData('transporterBatchNumber', '');
      model.setData('transporterBatchLabel', '');
      model.setData('transporterAccountNumber', '');
      model.setData('transporterAccount', '');
      model.setData('packages', '');
      model.setData('transporterWeightRate', '');
      model.setData('transporterExtension', '');
    },

    /**
     * update hct region data
     */
    // updateHCTRegion(model) {
    //
    //   const self = this;
    //
    //   const hasPreviousTimer = self.get('requestTimer');
    //   if (hasPreviousTimer) {
    //
    //     clearTimeout(hasPreviousTimer);
    //   }
    //
    //   return new Ember.RSVP.Promise(function (resolve, reject) {
    //
    //     if (model.getData('isHCT') && model.getData('address') && !isPickerOnly) {
    //
    //       //do not fetch hct region data immediately on key press changes,
    //       //allow a slight delay before fetching, this will minimise http calls
    //       const requestTimer = setTimeout(function () {
    //
    //         //must wait for first request to finish before requesting again
    //         const hasPreviousRequest = self.get('request');
    //         if (hasPreviousRequest) {
    //           hasPreviousRequest.then(function () {
    //
    //             return resolve(self._fetchHCTRegionData(model));
    //           });
    //         } else {
    //
    //           return resolve(self._fetchHCTRegionData(model));
    //         }
    //       }, 600);
    //
    //     } else {
    //
    //       model.setData('transporterRegion', '');
    //       model.setData('transporterRegionIsOuter', '');
    //     }
    //
    //     self.set('requestTimer', requestTimer);
    //   });
    // },
    unloadHCT(model) {
      model.setData('transporterRegion', '');
      model.setData('transporterRegionIsOuter', '');
      this.unsetDefaultHCTAccount(model);
    },

    setHCTWeightRate(account) {
      if (account) {
        const weightRate = account.getData('rate');
        const model = this.model;
        model.setData('transporterWeightRate', weightRate);
        model.setData('transporterAccount', account.getData('name'));
      }
    },

    fetchAndSubmitTask: (0, _emberConcurrency.task)(function* () {
      try {
        const model = this.model;
        const transporterRegion = model.getData('transporterRegion');

        if (transporterRegion !== '' && transporterRegion != null && transporterRegion !== false) {
          model.set('showHCTModal', false);
          return this._submit(model);
        }

        const fetchedTransporterRegion = yield this.fetchHCTRegionDataTask.perform(model);
        const errors = this.isHCTIncomplete(model);

        if (errors) {
          this.set('errors', errors);
          return false;
        }

        if (fetchedTransporterRegion) {
          return this._submit(model);
        }
      } catch (err) {
        this.set('errors', err.data);

        _nventor.default.throw('fetchAndSubmitTask failed', err);
      }
    }).drop(),

    _submit(model) {
      model.set('showHCTModal', false);
      return this.onSubmitTask.perform({
        appendPath: 'final'
      });
    },

    actions: {
      loadTransporterContactPerson(person) {
        const model = this.model;
        const name = person.getData('name');
        model.setData('person', name);
      },

      /**
       * action to handle when address has changed with input change
       */
      hctAddressChanged() {
        const model = this.model;
        model.setData('transporterRegion', '');
        model.setData('transporterRegionIsOuter', '');

        if (this.fetchHCTRegionDataOnInit && model.getData('transporterExtension') === 'hct') {
          this.fetchHCTRegionDataTask.perform(model);
        }
      },

      hctSetWeightRate(account) {
        this.setHCTWeightRate(account);
      },

      loadTransporterAddress(prop, detail) {
        this.onLoadContactDetail(prop, detail);
        this.send('hctAddressChanged');
      } // async submit () {
      //   const self = this
      //   const model = this.get('model')
      //   const transporterRegion = model.getData('transporterRegion')
      //   const errors = this.isHCTIncomplete()
      //   if (errors) {
      //     this.set('errors', errors)
      //     return false
      //   }
      //   await this.get('fetchAndSubmitTask').perform()
      //   return self.fetchHCTRegionData(model).then(function (fetchedTransporterRegion) {
      //     if (fetchedTransporterRegion) {
      //       model.set('showHCTModal', false)
      //       self.get('onSubmit')(model)
      //     }
      //     return fetchedTransporterRegion
      //   })
      // },
      // submitDraft () {
      // }


    }
  });

  _exports.default = _default;
});