define("client/pods/components/units-editor/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'units',

    init() {
      this._super(...arguments);

      const isEditing = this.isEditing;

      if (isEditing) {
        const dirty = this.setupDirty();
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord();
        this.set('model', model);
      }
    },

    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },

    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
    },

    afterRemoveRecordTask() {
      this.onRemoveItem(this.model);
    },

    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }

    }
  });

  _exports.default = _default;
});