define("client/pods/components/files/uploadcare-widget/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'images-uploader',
    name: 'content',
    label: 'upload files',
    resource: '',
    resourceKey: '',
    uploadcare: Ember.inject.service(),
    // settings
    multiple: true,
    multipleMax: '',
    multipleMin: '',
    imagesOnly: true,
    previewStep: false,
    crop: false,
    imageShrink: '',
    clearable: false,
    // should always be false as no way to hook into "remove" function of widget
    tabs: 'file url',
    secureSignature: '',
    secureExpire: '',
    cdnBase: 'https://ucarecdn.com/',

    didInsertElement() {
      const self = this;

      self._super(...arguments);

      self.initWidget();
    },

    initWidget() {
      const self = this;
      self.set('error', '');

      if (self.get('resourceKey') && self.get('resource')) {
        const widget = self.get('uploadcare').getUc().initialize((0, _jquery.default)(this.element))[0]; // uploadcare.Widget('[role=uploadcare-uploader]');

        self.set('widget', widget);

        const onUploadHandler = function (info) {
          self.updateServer(info);
          widget.onUploadComplete.remove(this);
        };

        self.set('onUploadHandler', onUploadHandler);
        widget.onUploadComplete(onUploadHandler);
      }
    },

    updateServer(info) {
      return this.onUpdateServer(info);
    },

    willDestroyElement() {
      if (this.widget) {
        this.widget.onUploadComplete.remove(this.onUploadHandler);
      }
    },

    actions: {
      triggerUploadBtn() {
        (0, _jquery.default)(this.element).find('.uploadcare--widget__button_type_open').click();
      }

    }
  });

  _exports.default = _default;
});