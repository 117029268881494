define("client/pods/components/dispatch/panels-display/print-all-dialog/component", ["exports", "ramda", "ember-concurrency", "ramda-extension"], function (_exports, R, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    tagName: 'span',
    hasPrices: true,
    hasReturnsDetails: true,
    nextDispatchProcessCode: '',

    init() {
      this._super(...arguments);

      const subPanels = this.printSubPanels || [];

      if (subPanels.length > 0) {
        this.tabsManager.displaySubPanel(this, subPanels.get('firstObject'));
      }

      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
    },

    printBadge: Ember.computed('toPrintModelsList.[]', 'hasSelected', function () {
      if (this.hasSelected > 0) {
        const models = this.toPrintModelsList || [];
        return models.length;
      }

      return this.intl.t('all');
    }),
    printSubPanels: Ember.computed('isDispatched', 'crud.lists.dispatch-lists-print-all-sub-panels.[]', function () {
      const subPanels = R_.dotPath('crud.lists.dispatch-lists-print-all-sub-panels')(this) || [];
      const isDispatched = this.isDispatched;
      return R.reject(subPanel => {
        if (!isDispatched) {
          if (subPanel.getData('value') === 'waybills') {
            return true;
          }

          if (subPanel.getData('value') === 'hctSummary') {
            return true;
          }

          if (subPanel.getData('value') === 'eInvoices') {
            return true;
          }
        }

        return false;
      })(subPanels);
    }),
    updateBtnLabel: Ember.computed('nextDispatchProcessCode', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      let label = this.intl.t('update status');

      if (nextDispatchProcessCode) {
        const dispatchService = this.dispatch;
        const dispatchProcesses = this.dispatchProcesses || [];
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          let name = nextDispatchProcess.getData('name');

          if (nextDispatchProcess.getData('translate')) {
            name = this.intl.t(name);
          }

          label = `${label}: ${name}`;
        }
      }

      return label;
    }),
    isDisableSetNextDispatchProcess: Ember.computed('nextDispatchProcessCode', 'dispatchProcess', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      const dispatchProcess = this.dispatchProcess;

      if (nextDispatchProcessCode && dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        return false;
      }

      return true;
    }),
    setNextDispatchProcessTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      // const models = this.toPrintModelsList
      const dispatchService = this.dispatch;
      const filters = this.filters;
      const dispatchProcesses = this.dispatchProcesses || [];
      const dispatchProcess = this.dispatchProcess;
      const nextDispatchProcessCode = this.nextDispatchProcessCode;

      if (dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          const filtersData = filters.serialize();
          const defaultFiltersData = R.mergeRight(filtersData, {
            matches: [],
            count: 5000,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code'),
            sort: filters.getData('sort')
          });

          if (this.period) {
            defaultFiltersData.dispatchDateZ = this.period.date;
          } else {
            defaultFiltersData.dispatchDateZ = '';
          }

          const unpaginatedFilters = this.search.setupFilters({
            adapterName: 'documents/filters',
            defaultFiltersData
          });
          const results = yield this.get('dispatch.updateEntireDispatchProcessTask').perform(unpaginatedFilters, nextDispatchProcess);
          this.onRefresh(nextDispatchProcess);
          onCloseDialogAction();
          return results;
        }

        onCloseDialogAction();
      }
    }).drop()
  });

  _exports.default = _default;
});