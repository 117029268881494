define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/numbers/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  let ExtensionsAppsTwEInvoicesTracksManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class ExtensionsAppsTwEInvoicesTracksManagerComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "newModel", _descriptor2, this);
    }

    newAllocatedNumbers() {
      this.newModel = this.args.model.newAllocatedNumbers();
    }

    *createTask(allocatedModel) {
      const saved = yield this.crud.createRecordTask.perform({
        adapterName: 'extensions/apps/tw/e-invoices/tracks/numbers/allocated',
        model: allocatedModel
      });
      this.newModel = null;
      return saved;
    }

    *updateTask(allocatedModel) {
      return yield this.crud.updateRecordTask.perform({
        adapterName: 'extensions/apps/tw/e-invoices/tracks/numbers/allocated',
        model: allocatedModel
      });
    }

    *removeTask(allocatedModel) {
      const hasKey = R.hasPath(['_data', '_key'])(allocatedModel);

      if (!hasKey) {
        this.newModel = null;
      } else {
        yield this.crud.removeRecordTask.perform({
          adapterName: 'extensions/apps/tw/e-invoices/tracks/numbers/allocated',
          model: allocatedModel
        });
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newModel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newAllocatedNumbers", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "newAllocatedNumbers"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerComponent;
});