define("client/pods/components/websites/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u9wCG8Gm",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor\",null,[[\"tab\",\"model\",\"errors\",\"isEditing\",\"isNew\",\"saveTask\",\"onSetAsDefaultImageTask\",\"onDash\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"dirty\"]],[24,[\"errors\"]],true,false,[24,[\"updateRecordTask\"]],[24,[\"onSetAsDefaultImageTask\"]],[24,[\"onDash\"]],[28,\"action\",[[23,0,[]],\"cancel\",[24,[\"dirty\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});