define("client/pods/components/admin/payments/card/panels-refunds/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "on6r8D8u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[28,\"or\",[[24,[\"searchInputTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"admin/payments/card/payments-display/payments-details\",null,[[\"details\",\"onMarkIsRefundedTask\"],[[24,[\"filters\",\"results\"]],[24,[\"markIsRefundedTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"helpers/pagination-controls\",null,[[\"showPage\",\"filters\",\"list\",\"onPaginationTask\"],[true,[24,[\"filters\"]],[24,[\"filters\",\"results\"]],[24,[\"paginateSearchRecordsTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/panels-refunds/template.hbs"
    }
  });

  _exports.default = _default;
});