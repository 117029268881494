define("client/pods/components/channels/shop/rewards/tab-container/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ChannelsShopRewardsTabContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class ChannelsShopRewardsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor5, this);
      (0, _defineProperty2.default)(this, "adapterName", 'channels/shop/rewards');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'channels/shop/rewards/filters');
      this.crud.addLists(this, ['statuses', 'channels/shop/rewards/deductions', 'channels/shop/rewards/earnings', 'channels/shop/rewards/periods', 'channels/shop/rewards/requirements', 'channels/shop/rewards/types', 'channels/shop/rewards/lists/menu', 'channels/shop/rewards/lists/sub-panels', 'channels/shop/rewards/lists/sub-panels-editor', 'websites']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      Ember.set(this, 'filters', filters);
    }

    afterLoadLists() {
      this.fetchAndDisplayTask.perform();
    }

    *fetchAndDisplayTask() {
      const filters = this.filters;
      const model = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        query: '',
        filters
      });
      Ember.set(this, 'results', model);
      this.dashFn(model);
    }

    dashFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-dash',
        model: model
      };

      if (!model) {
        const filters = this.filters;
        filters.setData('query', '');
        this.fetchAndDisplayTask.perform();
      }

      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    displayFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    editFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-edit',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    dash() {
      this.dashFn();
    }

    display(model) {
      if (model) {
        this.displayFn(model);
      } else {
        this.dashFn();
      }
    }

    edit(model, additionalOptions) {
      this.editFn(model, additionalOptions);
    }

    new() {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-new'
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchAndDisplayTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAndDisplayTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dash", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dash"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "display", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "edit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "new", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "new"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsTabContainerComponent;
});