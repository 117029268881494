define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/rate-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "acmxQE6j",
    "block": "{\"symbols\":[\"@isReadonly\",\"@rate\",\"@onRemove\",\"@onNewRow\",\"@isHeader\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"min order price\"],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"max order price\"],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"shipping fee\"],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"td\",true],[10,\"class\",\"u-v-align-middle-important\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"elements/element-label\",[],[[\"@translate\",\"@label\"],[false,[23,0,[\"minRate\"]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@onFocusOut\",\"@value\"],[[23,1,[]],[28,\"fn\",[[23,4,[]],[23,2,[]]],null],[23,2,[\"_data\",\"max\"]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@onFocusOut\",\"@value\"],[[23,1,[]],[28,\"fn\",[[23,4,[]],[23,2,[]]],null],[23,2,[\"_data\",\"rate\"]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"elements/element-pushbtn\",[],[[\"@disabled\",\"@classNames\",\"@tabindex\",\"@tab\",\"@onSubmit\"],[[23,1,[]],\"u-close-btn\",-1,[23,0,[\"tab\"]],[28,\"fn\",[[23,3,[]],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/rate-editor/template.hbs"
    }
  });

  _exports.default = _default;
});