define("client/pods/components/files/files-gallery/gallery-item/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['files__gallery-item'],
    hasError: false,
    onRemove: null,
    onSetDefault: null,
    defaultImage: null,
    confirmationOnRemove: true,
    removeConfirmationMsg: 'are you sure you want to delete image?',
    isHover: false,
    transform: '',
    allowModal: true,
    showModal: false,
    innerFilesGalleryRowClassNames: Ember.computed('galleryItemClassNames', function () {
      var _this$file, _this$file$_data;

      const galleryItemClassNames = R.propOr('', 'galleryItemClassNames')(this);
      let appendClassName;

      if (this !== null && this !== void 0 && (_this$file = this.file) !== null && _this$file !== void 0 && (_this$file$_data = _this$file._data) !== null && _this$file$_data !== void 0 && _this$file$_data.isDefault) {
        appendClassName = 'default';
      }

      return `${appendClassName} ${galleryItemClassNames}`;
    })
  });

  _exports.default = _default;
});