define("client/pods/components/websites/panels-display/sub-panels-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AgJsnmcH",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__tab-header\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"menus/tab-menu\",null,[[\"menu\",\"onSelect\"],[[24,[\"docTypesFiltered\"]],[28,\"action\",[[23,0,[]],[24,[\"tabsManager\",\"displaySubPanel\"]],[23,0,[]]],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content u-container\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"model\"],[[23,1,[]],[24,[\"model\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"websites/panels-display/sub-panels-footer-btns\",null,[[\"tab\",\"hasEditBtn\",\"copyErrors\",\"model\",\"dirty\",\"onEditPanel\",\"onPrepareDuplicateRecord\",\"duplicateRecordTask\",\"onAssignSalesPerson\",\"onUnassignSalesPerson\",\"onToggleCommissionMethod\",\"errorMsg\"],[[24,[\"tab\"]],true,[24,[\"copyErrors\"]],[24,[\"model\"]],[24,[\"dirty\"]],[24,[\"onEditPanel\"]],[24,[\"onPrepareDuplicateRecord\"]],[24,[\"duplicateRecordTask\"]],[24,[\"onAssignSalesPerson\"]],[24,[\"onUnassignSalesPerson\"]],[24,[\"onToggleCommissionMethod\"]],[24,[\"errorMsg\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-activity/template.hbs"
    }
  });

  _exports.default = _default;
});