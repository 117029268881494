define("client/pods/tabs-manager/service", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/pods/tabs-manager/model", "client/pods/options/model", "client/utils/nventor"], function (_exports, _applyDecoratedDescriptor2, R, _model, _model2, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  // @NOTE: if tab is not toggling between visible=true/false then make sure tagName is set on the component
  // const TabModel = EmberObject.extend({
  //   init () {
  //     this._super(...arguments)
  //
  //     this.resetTabs()
  //   },
  //   resetTabs () {
  //     const tabs = []
  //     tabs._history = []
  //     tabs._closed = []
  //     this.set('subTabs', tabs)
  //   },
  //
  //   subTabsArray: computed('subTabs.{[],@each.id}', 'tabsUpdated', function () {
  //     return this.subTabs
  //   })
  // })
  const self = (_dec = Ember._action, (_obj = {
    tabsUpdated: 0,

    init() {
      this._super(...arguments);

      this.resetTabs();
    },

    resetTabs() {
      this._resetTabs('left');

      this._resetTabs('right');

      this._resetTabs('sidebarRight');
    },

    setHeader(tab, header = '') {
      if (tab) {
        tab.set('header', R.take(30)(header));
      }
    },

    _createOptionsModel(tabOptions = []) {
      const allTabOptions = Object.assign(...tabOptions);

      if (!allTabOptions.isModel) {
        const TabsManagerModel = _model2.default.create();

        TabsManagerModel.populate(allTabOptions);
        return TabsManagerModel;
      }

      return allTabOptions;
    },

    _resetTabs(tabPosition) {
      const tabs = [];
      tabs._history = [];
      tabs._closed = [];
      this.set(tabPosition, tabs);
    },

    openTab(tabPosition, ...tabOptions) {
      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabPosition = tabOptionsModel.getData('tabPosition') || tabPosition;
      const tabs = this.get(tabPosition);
      const rememberTabScrollTop = true;

      const tab = this._createTab(tabs, tabOptionsModel, false, rememberTabScrollTop); // set active


      this.selectTab(tabs, tab); // if (tabPosition === 'sidebarRight') {
      //   const sidebarRight = window.$('.app-grid__sidebar-right')
      //   if (!sidebarRight.hasClass('bot-sidebar-right')) {
      //     sidebarRight.addClass('bot-sidebar-right')
      //   }
      // }
    },

    // createTabOnParent (parent, subTabsProperty, tabOptions) {
    //   const tabs = R.prop(subTabsProperty)(parent) || []
    //   const tabOptionsModel = this._createOptionsModel(tabOptions)
    //   const rememberTabScrollTop = true
    //   const tab = this._createTab(tabs, tabOptionsModel, false, rememberTabScrollTop)
    //   parent.set(subTabsProperty, tabs)
    //   this.selectTab(tabs, tab)
    // },
    _createTab(tabs, tabOptionsModel, tab = false, rememberTabScrollTop = false) {
      const tabOptions = R.pathOr({}, ['_data'])(tabOptionsModel);
      tabOptions.rememberTabScrollTop = rememberTabScrollTop; // let tab

      if (tabOptions.tabType === 'singleton') {
        // check that tab doesnt already exist
        let idParam = 'component';
        let needle = tabOptions.component;

        if (tabOptions.id) {
          idParam = 'id';
          needle = tabOptions.id;
        }

        const isExists = tabs.findBy(idParam, needle);

        if (isExists) {
          if (tabOptions.id) {
            if (tabOptions.id === isExists.id) {
              return isExists;
            }
          } else {
            return isExists;
          }
        }
      }

      if (tabOptions.tabType === 'replace') {
        const isExists = tabs.findBy('component', tabOptions.component);
        tabs.removeObject(isExists);
        this.removeTabFromHistory(tabs, isExists);
      }

      const current = _model.default.create(tabOptions);

      let tabId = tabOptions.id;

      if (!tabId) {
        tabId = _nventor.default.random.alphaNum(8);
        current.set('id', tabId);
      } // @TODO: change id to position
      // no longer need id
      // id does not work when creating tabs within tabs.
      // eg. product tab is created. then new panel is created within product tab.
      // it will increment current product tab's id
      // const id = this.get('id') + 1;
      // this.set('id', id);
      // current.set('id', id);
      // if current model also implements tabs mixin, reset tabs and history so
      // that sub tabs will not be shared across other tab instances
      // if (current.resetTabs) {
      //   current.resetTabs();
      // }
      // tab = current


      tabs.pushObject(current);

      if (tab) {
        tab.set('tabsUpdated', tabId);
      }

      return current;
    },

    selectTab(tabs, tab) {
      if (tab) {
        const scrollTop = window.$(window).scrollTop();
        tabs.forEach(tab => {
          if (tab.get('isActive')) {
            tab.set('scrollTop', scrollTop);
          }

          tab.set('isActive', false);
        });
        tab.set('isActive', true);
        const newScrollTop = tab.get('scrollTop') || 0;

        if (tab.rememberTabScrollTop) {
          setTimeout(function () {
            window.$(window).scrollTop(newScrollTop);
          }, 100);
        }

        this.addTabToHistory(tabs, tab);
      }
    },

    getLastSelectedTab(tabs) {
      return tabs.get('_history').get('firstObject');
    },

    addTabToHistory(tabs, tab) {
      const history = tabs._history;
      history.removeObject(tab);
      history.unshiftObject(tab);
    },

    removeTabFromHistory(tabs, tab) {
      const history = tabs._history;
      history.removeObject(tab);
    },

    addToClosed(tabs, tab) {
      const closed = tabs._closed;
      closed.unshiftObject(tab);
    },

    /**
     * close tab
     * @param  {object} tab - tab to close
     */
    closeTab: function (tabs, tab) {
      // @TODO: set isActive=false
      // remove tab
      // set isClosing so that the view can destroy itself
      tab.set('isClosing', true);
      tabs.removeObject(tab); // remove tab from history

      this.removeTabFromHistory(tabs, tab);
      this.addToClosed(tabs, tab); // select next tab in history

      const lastSelectedTab = this.getLastSelectedTab(tabs);
      this.selectTab(tabs, lastSelectedTab); // const sidebarRight = this.get('sidebarRight') || []
      // if (sidebarRight.length === 0) {
      //   const sidebarRight = window.$('.app-grid__sidebar-right')
      //   if (!sidebarRight.hasClass('bot-sidebar-right')) {
      //     sidebarRight.removeClass('bot-sidebar-right')
      //   }
      // }
    },

    recoverLastTab() {
      const tabs = this.tabs;
      const deletedHistory = this.deletedHistory;
      const deleted = deletedHistory.shiftObject(); // add deleted tab back

      tabs.pushObject(deleted);
      this.selectTab(deleted);
    },

    getActiveSubTab(tab) {
      const subTabs = tab.subTabs || []; // hide all other tabs

      return subTabs.findBy('isActive', true) || {};
    },

    isActiveSubTab(tab, data) {
      const active = this.getActiveSubTab(tab) || {};
      return R.pipe(R.keys, R.head, R.eqProps(R.__, active, data))(data);
    },

    reuseSubTab(tab, ...tabOptions) {
      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabOptionsModel.setData('tabType', 'singleton');
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      this.selectTab(subTabs, subTab);
    },

    appendSubTab(tab, ...tabOptions) {
      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabOptionsModel.setData('tabType', 'append');
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      this.selectTab(subTabs, subTab);
    },

    replaceSubTab(tab, ...tabOptions) {
      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabOptionsModel.setData('tabType', 'replace');
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      this.selectTab(subTabs, subTab);
      return subTabs;
    },

    getLoadInstructions(tab) {
      const loadInstructions = tab.get('loadInstructions');

      if (loadInstructions) {
        return _nventor.default.createModel(_model2.default, loadInstructions);
      }

      return false;
    },

    triggerMenuAction(component, menu) {
      const action = R.path(['_data', 'action'])(menu);

      if (action) {
        return component[action](menu);
      }
    },

    displaySubPanel(component, resourceData = {}) {
      const tabType = R.pathOr('replaceSubTab', ['_data', 'tabType'])(resourceData);
      const tabOptions = resourceData._data || {};
      const subTabOptions = R.mergeRight({}, tabOptions);
      const tab = component.tab || component.args.tab;
      return self[tabType](tab, subTabOptions);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "openTab", [_dec], Object.getOwnPropertyDescriptor(_obj, "openTab"), _obj)), _obj));

  var _default = Ember.Service.extend(self);

  _exports.default = _default;
});