define("client/pods/components/extensions/apps/tw/e-invoices/tab-container/component", ["exports", "client/mixins/date", "client/mixins/big", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices", "client/mixins/crud", "client/mixins/e-invoices-list-by", "ember-concurrency"], function (_exports, _date, _big, _invoices, _crud, _eInvoicesListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _eInvoicesListBy.default, _invoices.default, _big.default, _date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    model: null,
    errors: null,
    customDateZ: '',
    adapterName: 'extensions/apps/tw/e-invoices',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    hasManagementBtns: true,
    selectedProvider: 'bigOrdr',

    init() {
      this.crud.addLists(this, ['periods/months', 'periods/years', 'extensions/apps/tw/e-invoices/lists/providers']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      const pastDays = this.dateService.getPastDays(14);
      this.set('pastDays', pastDays);
      const pastMonths = this.dateService.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const defaultEInvoiceProvider = this.settingsService.getDefaultEInvoiceProvider();
      this.set('selectedProvider', defaultEInvoiceProvider);
    },

    // refreshTask: task(function * () {
    //   const period = this.period
    //   this.displaySummaryPanel(period)
    // }),
    afterSearchInputTask(results) {
      this.set('hasStatement', false);
      return results;
    },

    displaySummaryPanel({
      dateStart,
      dateEnd
    }) {
      const period = this.dateService.getPeriod(dateEnd);
      const currentInvoiceMonth = this.dateService.getEvenInvoiceMonth(period.mm);
      const currentInvoicePeriod = this.dateService.getPeriod(`${period.yyyy}-${currentInvoiceMonth}-01`);
      currentInvoicePeriod.dateStart = dateStart;
      currentInvoicePeriod.dateEnd = dateEnd;
      const subTabOptions = {
        component: 'extensions/apps/tw/e-invoices/panels-display',
        period: currentInvoicePeriod
      };
      this.set('hasStatement', true);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      /**
       * display dispatched list for given date
       * @param  {string} date (YYYY-MM-DD)
       */
      displayByMonth(period) {
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
      },

      displayByCustomMonth(mm, yyyy) {
        const dateStart = this.formatDateForUrl(`${yyyy}-${mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(yyyy, mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
      }

    }
  });

  _exports.default = _default;
});