define("client/pods/documents/mixins/linking", ["exports", "client/utils/nventor", "client/pods/base/model", "client/mixins/big"], function (_exports, _nventor, _model, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),

    preLinkInfo(linkFromDetail, key) {
      const linkTo = this.linkTemp;

      const linkFrom = _nventor.default.createModel(_model.default, linkFromDetail.getData('doc'));

      if (this[`_link_${key}`]) {
        return this[`_link_${key}`]({
          linkTo,
          key,
          linkFrom
        });
      }

      return this._link({
        key,
        linkFrom,
        linkTo
      });
    },

    linkInfoFromTemp(key) {
      if (this[`_link_${key}`]) {
        return this[`_link_${key}`]({
          linkTo: this,
          linkFrom: this.linkTemp,
          key
        });
      }

      return this._link({
        linkTo: this,
        linkFrom: this.linkTemp,
        key
      });
    },

    _link({
      linkTo,
      linkFrom,
      key
    }) {
      linkTo = linkTo || this;
      let value;

      if (linkFrom) {
        value = linkFrom.getData(key) || '';
      } else {
        value = '';
      }

      linkTo.setData(key, value);
      return true;
    },

    // _link_transporterLabel ({ linkTo, linkFrom }) {
    //   if (this.get('docName') !== 'returns' && this.get('docName') !== 'consignments-returns') {
    //     this._link({ linkTo, linkFrom, key: 'transporterExtension' })
    //     this._link({ linkTo, linkFrom, key: 'transporterAccount' })
    //     this._link({ linkTo, linkFrom, key: 'transporterAccountNumber' })
    //     this._link({ linkTo, linkFrom, key: 'transporterBatchLabel' })
    //     this._link({ linkTo, linkFrom, key: 'transporterBatchNumber' })
    //     this._link({ linkTo, linkFrom, key: 'person' })
    //     this._link({ linkTo, linkFrom, key: 'transporterContactPhone' })
    //     this._link({ linkTo, linkFrom, key: 'transporterKey' })
    //     this._link({ linkTo, linkFrom, key: 'transporterLabel' })
    //     this._link({ linkTo, linkFrom, key: 'transporterRegion' })
    //     this._link({ linkTo, linkFrom, key: 'transporterWeightRate' })
    //   }
    // },
    _link_discount({
      linkTo,
      linkFrom
    }) {
      if (linkTo.allowDiscounts) {
        if (linkFrom.getData('doc.discountType') === this.get('constants.discountTypes.rate')) {
          this._link({
            linkTo,
            linkFrom,
            key: 'discountType'
          });

          this._link({
            linkTo,
            linkFrom,
            key: 'discountRate'
          });

          this._link({
            linkTo,
            key: 'discountAmount'
          });
        } else {
          this._link({
            linkTo,
            linkFrom,
            key: 'discountType'
          });

          this._link({
            linkTo,
            linkFrom,
            key: 'discountAmount'
          });

          this._link({
            linkTo,
            key: 'discountRate'
          });
        }
      }
    },

    _link_paymentMethod({
      linkTo,
      linkFrom
    }) {
      this._link({
        linkTo,
        linkFrom,
        key: 'paymentMethodKey'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentLastFiveDigits'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'hasPaymentTerms'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentTermsKey'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentTermsMonths'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentTermsDays'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentTermsFrom'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentTermsEnd'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentCreditCardProvider'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentCreditCardStatus'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentCreditCardTransactionCode'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'paymentCreditCardTransactionTimestampZ'
      });
    },

    _link_transporter({
      linkTo,
      linkFrom
    }) {
      this._link({
        linkTo,
        linkFrom,
        key: 'transporterLabel'
      });

      this._link({
        linkTo,
        linkFrom,
        key: 'transporterKey'
      });
    },

    linkFromTemp() {
      this.linkAttrs.map(key => {
        let param;

        if (_nventor.default.confirm.isObject(key)) {
          param = key.param;
        } else {
          param = key;
        }

        this.linkInfoFromTemp(param);
      });
    },

    loadLinkedDetail(linkFromDetail, linkToDetail) {
      linkToDetail.setData('ref', linkFromDetail.getData('doc.docNo'));
      linkToDetail.setData('fromRef', linkFromDetail.getData('doc.ref'));
      linkToDetail.setData('fromDocType', linkFromDetail.getData('doc.docType'));
      linkToDetail.setData('fromDocName', linkFromDetail.getData('doc.docName'));
      linkToDetail.setData('fromDocNo', linkFromDetail.getData('doc.docNo'));
      linkToDetail.setData('fromMaster', linkFromDetail.getData('master'));
      linkToDetail.setData('fromDetailKey', linkFromDetail.getData('detailKey'));
      const paymentCreditCardTransactionCode = linkFromDetail.getData('paymentCreditCardTransactionCode') || linkFromDetail.getData('doc.paymentCreditCardTransactionCode');
      const paymentCreditCardTransactionTimestampZ = linkFromDetail.getData('paymentCreditCardTransactionTimestampZ') || linkFromDetail.getData('doc.paymentCreditCardTransactionTimestampZ');
      linkToDetail.setData('paymentCreditCardTransactionCode', paymentCreditCardTransactionCode);
      linkToDetail.setData('paymentCreditCardTransactionTimestampZ', paymentCreditCardTransactionTimestampZ);
      linkToDetail.setData('itemKey', linkFromDetail.getData('itemKey'));
      linkToDetail.setData('itemCode', linkFromDetail.getData('itemCode'));
      linkToDetail.setData('item', linkFromDetail.getData('item'));
      linkToDetail.setData('invoiceDescription', linkFromDetail.getData('invoiceDescription'));
      linkToDetail.setData('unit', linkFromDetail.getData('unit'));
      linkToDetail.setData('unitKey', linkFromDetail.getData('unitKey'));
      const taxMethodKey = this.getData('taxMethodKey');
      let inclPrice = linkFromDetail.getData('discInclPrice') || linkFromDetail.getData('inclPrice');
      let exclPrice = linkFromDetail.getData('discExclPrice') || linkFromDetail.getData('exclPrice');
      const roundingInventoryPrice = linkFromDetail.getData('roundingInventoryPrice') || 2;
      inclPrice = this.autoRound(roundingInventoryPrice, inclPrice);
      exclPrice = this.autoRound(roundingInventoryPrice, exclPrice);

      if (taxMethodKey === this.get('constants.taxMethods.inclusive')) {
        linkToDetail.setData('price', inclPrice);
        linkToDetail.setData('exclPrice', exclPrice);
      } else {
        linkToDetail.setData('price', exclPrice);
        linkToDetail.setData('inclPrice', inclPrice);
      }

      linkToDetail.setData('taxRate', linkFromDetail.getData('taxRate')); // if (linkToDetail.docType === 'sales' && linkToDetail.docName !== 'returns') {
      //   linkToDetail.setData('discountType', linkFromDetail.getData('discountType'))
      //   linkToDetail.setData('discountRate', linkFromDetail.getData('discountRate'))
      //   linkToDetail.setData('discountAmount', linkFromDetail.getData('discountAmount'))
      // }

      linkToDetail.setData('ordersPendingId', linkFromDetail.getData('ordersPendingId'));
      linkToDetail.setData('consignmentsPendingId', linkFromDetail.getData('consignmentsPendingId')); // linkToDetail.setData('isLinked', true)
      // @TODO: link detail discount, link order number
      // where to get qty from:
      // doc=orders/invoices/consignments, linkFrom=quotes : qty = linkFrom.qty
      // doc=invoices/consignments, linkFrom=orders : qty = linkFrom.outstandingQty
      // doc=returns, linkFrom=invoices : qty = linkFrom.qty
      // doc=consignments-returns, linkFrom=orders : qty = linkFrom.qty

      linkToDetail.setData('qty', linkFromDetail.getData('linkQty')); // const linkFromDocData = linkFromDetail.getData('doc')
      // if (!isEmpty(linkFromDocData.salesPersons)) {
      //   const salesPersons = []
      //   this.setData('salesPersons', salesPersons)
      //   linkFromDocData.salesPersons.forEach(function (salesPerson) {
      //     this.addSalesPerson(salesPerson)
      //   })
      // }

      if (!Ember.isEmpty(this.getData('salesPersons'))) {// linkToDetail.setData('commissionBaseCost', linkFromDetail.getData('commissionBaseCost'))
      }

      this.sumTotals();
      return true;
    }

  });

  _exports.default = _default;
});