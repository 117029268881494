define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/bulk-discount-schedules-dialog/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor4, this);
      this.model = this.args.model;

      if (this.args.isBulkEditing) {
        this.model = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details',
          model: this.args.model
        });
      }

      this.setupInitSchedule();
    }

    setupInitSchedule() {
      const hasBulkDiscountSchedules = this.model.getData('hasBulkDiscountSchedules');
      const bulkDiscountSchedules = this.model.getData('bulkDiscountSchedules') || [];

      if (hasBulkDiscountSchedules && _ramdaAdjunct.default.isNilOrEmpty(bulkDiscountSchedules)) {
        this.model.addBulkDiscountSchedule();
      }
    }

    onSubmitAddBulkDiscountSchedule() {
      this.model.addBulkDiscountSchedule();
    }

    onToggleSetHasBulkDiscountSchedule() {
      this.setupInitSchedule();
      const model = this.model;
      const hasBulkDiscountSchedules = model.getData('hasBulkDiscountSchedules') || false;

      if (!hasBulkDiscountSchedules) {
        model.set('hasEmptyBulkDiscountId', false);
        model.setData('bulkDiscountSchedules', []);
        model.setData('bulkDiscountId', '');
      }
    }

    saveBulkDiscountSchedules(updateBulkEditingTask) {
      if (this.args.updateBulkDiscountId) {
        this.args.updateBulkDiscountId(this.model);
      }

      if (updateBulkEditingTask) {
        updateBulkEditingTask.perform({
          appendPath: '/bulk-discount-conflict',
          model: this.model
        });
      }

      this.args.onCloseDialogAction();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddBulkDiscountSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddBulkDiscountSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetHasBulkDiscountSchedule", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetHasBulkDiscountSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveBulkDiscountSchedules", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "saveBulkDiscountSchedules"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorBulkDiscountSchedulesDialogComponent;
});