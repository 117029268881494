define("client/pods/components/tabs/for-each/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TabsForEach extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "tagName", '');
    }

  }

  _exports.default = TabsForEach;
});