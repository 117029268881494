define("client/pods/components/guests/campaign-main/component", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    url: '',
    password: '',

    init() {
      this.crud.addLists(this, ['guests/shop/report/lists/subPanels']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const subPanels = R_.dotPath('crud.lists.guests-shop-report-lists-subPanels')(this) || [];
      const initialPanel = subPanels.get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    }

  });

  _exports.default = _default;
});