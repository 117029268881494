define("client/pods/notifications/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        _id: 'notifications'
      };
    },

    populate(data = {}) {
      data.alerts = R.pipe(R.propOr([], 'alerts'), R.map(alert => {
        return Ember.Object.create(alert);
      }))(data);

      this._super(...arguments);
    },

    markAllAsRead() {
      const unread = this.unread || [];
      R.forEach(unread => {
        unread.set('read', true);
      })(unread);
      this.updateSynced();
    },

    unread: Ember.computed('synced', function () {
      return R.pipe(R.pathOr([], ['_data', 'alerts']), R.reject(R.propEq('read', true)), R.sortWith([R.descend(R.prop('timestampZ'))]))(this);
    }),
    read: Ember.computed('synced', function () {
      return R.pipe(R.pathOr([], ['_data', 'alerts']), R.filter(R.propEq('read', true)), R.sortWith([R.descend(R.prop('timestampZ'))]))(this);
    })
  });

  _exports.default = _default;
});