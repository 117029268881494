define("client/mixins/droppable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['_isDragOver:is-drag-over', 'dropPositionClassName'],
    droppable: true,
    _isDragOver: Ember.computed('isDragOver', 'droppable', function () {
      if (!this.droppable) {
        return false;
      }

      return this.isDragOver;
    }),

    getTransferData(event) {
      let data = event.dataTransfer.getData('text/data');
      data = JSON.parse(data);
      return data;
    },

    dropPositionClassName: Ember.computed('dropPosition', 'isDragOver', function () {
      if (!this.isDragOver) {
        return '';
      }

      return this.dropPosition;
    }),

    dragLeave(event) {
      event.preventDefault();

      if (this.droppable) {
        this.set('isDragOver', false);
      }

      return false;
    },

    dragOver(event) {
      event.preventDefault();

      if (this.droppable) {
        this.set('isDragOver', true);

        if (this.onDragOver) {
          this.onDragOver(event);
        }
      }

      return false;
    },

    drop(event) {
      event.preventDefault();

      if (this.droppable) {
        this.set('isDragOver', false);
        const data = this.getTransferData(event);

        if (this.onDrop) {
          this.onDrop(data, event);
        }
      }
    }

  });

  _exports.default = _default;
});