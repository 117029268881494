define("client/pods/channels/shop/campaigns/lists/import/limits/specs/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "ramda"], function (_exports, _adapter, _adaptersNew, _model, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    async findAll() {
      return [{
        headerValue: this.intl.t('product'),
        label: 'product',
        value: 'item'
      }, {
        headerValue: this.intl.t('product code'),
        label: 'product code',
        value: 'itemCode'
      }, {
        headerValue: this.intl.t('limited sales qty'),
        label: 'limited sales qty',
        value: 'hasSalesLimitedQty',

        transform(value, row, header) {
          if (_nventor.default.try(R.toUpper, value) === 'Y') {
            return true;
          }

          return false;
        }

      }, {
        headerValue: this.intl.t('limited qty'),
        label: 'limited qty',
        value: 'salesLimitedQty'
      }, {
        headerValue: this.intl.t('increase/decrease limited qty'),
        label: 'increase/decrease limited qty',
        value: 'salesLimitedQtyAdjustment'
      }, {
        headerValue: this.intl.t('allow preOrder'),
        label: 'allow preOrder',
        value: 'allowPreOrder',

        transform(value, row, header) {
          if (_nventor.default.try(R.toUpper, value) === 'Y') {
            return true;
          }

          return false;
        }

      }, {
        headerValue: this.intl.t('limited preOrder qty'),
        label: 'limited preOrder qty',
        value: 'hasSalesPreOrderLimitedQty',

        transform(value, row, header) {
          if (_nventor.default.try(R.toUpper, value) === 'Y') {
            return true;
          }

          return false;
        }

      }, {
        headerValue: this.intl.t('limit preOrder qty'),
        label: 'limit preOrder qty',
        value: 'salesPreOrderLimitedQty'
      }, {
        headerValue: this.intl.t('increase/decrease preOrder qty'),
        label: 'increase/decrease preOrder qty',
        value: 'salesPreOrderLimitedQtyAdjustment'
      }]; // return [{
      //   headerValue: '',
      //   label:  ',
      // }, {
      //   headerValue: '帳號',
      //   label:   value: 'paymentLastFiveDigits,
      //   required: true
      // }, {
      //   headerValue: '存',
      //   label:   value: 'inclTotal,
      //   required: true
      // }]
    }

  });

  _exports.default = _default;
});