define("client/pods/components/documents/editor-main/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/utils/nventor", "client/mixins/doc-status", "client/mixins/dispatch", "client/mixins/sortable-actions", "client/pods/options/model", "ember-concurrency", "client/mixins/transporter", "client/mixins/doc-contacts", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _date, _settings, _nventor, _docStatus, _dispatch, _sortableActions, _model, _emberConcurrency, _transporter, _docContacts, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * documents/editor-main component
   * purpose of this component is to allow for shared properties
   * of documents info and documents detail.
   * (as compared to editor mixin where its properties/functions are only for doc info)
   */
  var _default = Ember.Component.extend(_date.default, _docContacts.default, _docStatus.default, _dispatch.default, _settings.default, _sortableActions.default, _transporter.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    storage: Ember.inject.service(),
    contactsList: null,
    productsList: null,
    linksList: null,
    isEditing: false,
    recalculateTotals: 0,
    // determines whether totals need to be recalculated
    alreadyAddedDetails: false,
    linkFromDetails: null,
    isVisibleLinkConflictsModal: false,
    showDispatchProcesses: false,
    hasManagePods: false,
    submitDraftAppendPath: 'draft',
    submitFinalAppendPath: 'final',
    showCancelInvoiceModal: false,

    init() {
      this._super(...arguments);

      this.setup();
      const adapters = [{
        adapterName: 'users',
        appendPath: 'internal'
      }, 'units', 'taxes', 'taxMethods', 'discounts/types', 'documents/lists/discountmethods', 'paymentMethods', 'paymentTerms', 'paymentTerms/from', 'paymentTerms/end', 'documents/lists/deliverydatesoptions', 'dispatch/lists/processes', 'extensions/apps/tw/invoicingAuto', 'extensions/apps/tw/e-invoices/carrier-types', 'toggles/onOff'];
      const userData = this.storage.get('tokenData');

      if (userData.isAdmin) {
        this.set('hasManagePods', true);
      }

      const associatesContactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          count: 20,
          isAssociates: true
        }
      });
      this.loadContactTask.perform(associatesContactsFilters);
      const docType = this.docType;
      adapters.pushObject(`documents/${docType}/terms`);
      this.crud.addLists(this, adapters);
    },

    setup() {
      // products lists is populated when searching for products
      this.set('contactsList', []);
      this.set('productsList', []);
      this.set('linksList', []);
      this.set('selectedPaymentMethods', []);
      this.set('docType', this.get('tab.config.docType'));
      this.set('docName', this.get('tab.config.docName'));
      this.set('dontFetchRegionCode', this.get('tab.config.dontFetchRegionCode'));
      this.set('hasDeliveryDate', this.get('tab.config.hasDeliveryDate'));
      this.set('hasValidityDate', this.get('tab.config.hasValidityDate'));
      this.set('hasTaxNumber', this.get('tab.config.hasTaxNumber'));
      this.set('hasCommission', this.get('tab.config.hasCommission'));
      this.set('adapterName', this.get('tab.config.adapterName'));
    },

    afterLoadLists() {
      this._super(...arguments);

      this.set('listsIsLoaded', Math.random());
      this.setDefaultTerms();
    },

    loadContactTask: (0, _emberConcurrency.task)(function* (associatesContactsFilters) {
      const associatesContacts = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts',
        filters: associatesContactsFilters
      });
      this.set('associatesContacts', associatesContacts);
    }),

    async setDefaultTerms() {
      if (this.isNew) {
        const docType = this.docType;
        const docName = this.docName;
        const defaultTermsKey = await this.settings.getDefaultTerms(docType, docName);
        const terms = this.docTermsList || [];
        const found = terms.findBy('_data._key', defaultTermsKey);

        if (found) {
          this.loadTerms(found);
        }
      }
    },

    loadTerms(term) {
      const model = this.model;
      model.setData('terms', term.getData('value'));
    },

    allowTwInvoicing: Ember.computed('docType', 'docName', '', function () {
      const docType = this.docType;
      const docName = this.docName;
      return this.settings.allowTwInvoicing(docType, docName);
    }),
    docTermsList: Ember.computed('listsIsLoaded', 'docType', function () {
      const docType = this.docType;
      return this.get(`crud.lists.documents-${docType}-terms`);
    }),
    isAllowPrintInvoice: Ember.computed('docType', 'docName', 'model._data.status', function () {
      const docType = this.docType;
      const docName = this.docName;
      const model = this.model;
      const status = this.get('model._data.status');

      if (docType === 'sales' && docName === 'invoices') {
        if (status === 'final') {
          return true;
        }

        if (status === 'void') {
          if (model.get('hasEInvoices')) {
            return true;
          }
        }
      }

      return false;
    }),
    printBtnsComponent: Ember.computed('docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;
      return `documents/${docType}/${docName}/print-btns`;
    }),

    /**
     * determines if document isDraft
     * (computed property)
     *
     * @type {Boolean}
     */
    isDraft: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');

      if (status === this.get('constants.documentsStatus.final')) {
        return false;
      }

      return true;
    }),

    /**
     * determines if document isReadonly based on isEditing
     * isReadonly is opposite to isEditing
     * (computed property)
     *
     * @type {Boolean}
     */
    isReadonly: Ember.computed('isEditing', 'onSubmitTask.isRunning', 'model._data.isVoid', function () {
      if (this.get('model._data.isVoid')) {
        return true;
      }

      if (this.get('onSubmitTask.isRunning')) {
        return true;
      }

      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    // isEditingInfo: computed('isEditing', 'isSplitting', 'isMerging', function () {
    //   if (this.get('isSplitting')) {
    //     return false
    //   }
    //   if (this.get('isMerging')) {
    //     return false
    //   }
    //   return this.get('isEditing')
    // }),

    /**
     * determine if there is a single or mulitple delivery dates (line by line)
     */
    hasSingleDeliveryDate: Ember.computed('model._data.deliveryDateOption', function () {
      const deliveryDateOption = this.get('model._data.deliveryDateOption');

      if (deliveryDateOption === this.get('constants.deliveryDateOptions.single')) {
        return true;
      }

      return false;
    }),
    hasDiscount: Ember.computed('model._data.{discountRate,discountAmount}', function () {
      // @TODO: test
      const model = this.model;
      const discRateBig = model.getDataBig('discountRate');
      const discAmountBig = model.getDataBig('discountAmount');

      if (discRateBig.gt(0) || discAmountBig.gt(0)) {
        return true;
      }

      return false;
    }),
    // /**
    //  * returns true if discount is a %
    //  * @type {Boolean}
    //  */
    // isDiscountRate: computed('model._data.discountType', function () {
    //   const model = this.get('model')
    //   const discType = model.getData('discountType')
    //   if (discType === this.get('constants.discountTypes.rate')) {
    //     return true
    //   }
    //   return false
    // }),

    /**
     * check if any salesPersons are assigned to contact
     * @type {Boolean}
     */
    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');

      if (Ember.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    // _linkAttrInfo (linkFromInfoObject, key, linkFromKey) {
    //   console.log('=====link from info ===: ' + key)
    //   const linkFromInfo = linkFromInfoObject[key]
    //   console.log(linkFromInfo)
    //   return this._linkAttr(linkFromInfo, key, linkFromKey)
    // },
    // _linkAttr (linkFromDetail, key, linkFromKey) {
    //   const model = this.get('model')
    //   linkFromKey = linkFromKey || key
    //   let value = linkFromDetail.getData(linkFromKey)
    //   if (value !== '') {
    //     model.setData(key, value)
    //   }
    //   return true
    // },
    relatedComments: Ember.computed('model._data.details.[]', function () {
      const related = [];
      const model = this.model;

      if (model) {
        const details = model.getData('details', []);
        R.forEach(function (detail) {
          const fromMaster = R_.dotPath('_data.fromMaster')(detail);

          if (fromMaster) {
            if (!related.findBy('master', fromMaster)) {
              related.push({
                param: 'master',
                value: fromMaster
              });
            }
          }
        })(details);
      }

      return related;
    }),

    /**
       * gets delivery date to insert into new Detail
       * will return todays date as default, or will
       * return previous line
       */
    getNextDeliveryDateZ() {
      // check previous line's delivery date
      const lastDetail = this.getLastDetail();

      if (lastDetail) {
        const lastDeliveryDate = lastDetail.getData('deliveryDateZ');

        if (lastDeliveryDate) {
          return lastDeliveryDate;
        }
      }

      const model = this.model;
      return model.getData('deliveryDateZ') || this.getStartOfDayZ();
    },

    getLastDetail() {
      const model = this.model;

      if (model) {
        const details = model.getData('details');

        if (!Ember.isEmpty(details)) {
          return details.get('lastObject');
        }
      }

      return false;
    },

    async resetDetail(detail) {
      const model = this.model;
      model.resetDetail(detail);
      const taxes = R_.dotPath('crud.lists.taxes')(this);
      detail.setData('discountType', this.get('constants.discountTypes.rate'));
      const defaultTaxRate = await this.settings.getDefaultTaxRate(taxes);
      detail.setData('taxRate', defaultTaxRate);

      if (this.docName === 'orders' || this.docName === 'quotes') {
        const todayZ = this.getStartOfDayZ();
        detail.setData('deliveryDateZ', todayZ);
      }

      model.sumTotals();
    },

    _getLinkConflicts(linkFromInfoResolutions) {
      // const linkFromInfoResolutions = this.get('linkFromInfoResolutions') || []
      if (Ember.isEmpty(linkFromInfoResolutions)) {
        return [];
      }

      return linkFromInfoResolutions.filter(infoGroup => {
        const values = infoGroup.values.filter(item => item.value != null); // const values = infoGroup.values.filter(item => item.value !== '' && item.value != null)

        if (values.length > 1) {
          return true;
        }

        return false;
      });
    },

    loadLinked() {
      const model = this.model;
      this.set('isVisibleLinkConflictsModal', false);
      model.linkFromTemp();
      const linkFromDetails = this.linkFromDetails;
      linkFromDetails.filter(linkFromDetail => linkFromDetail.isEditorValue !== true).map(linkFromDetail => {
        const linkToDetail = this.linkToDetail;
        model.loadLinkedDetail(linkFromDetail, linkToDetail);
        this.onAddNewDetail();
        this.set('linkToDetail', this.get('model._data.details').get('lastObject'));
      });
      this.set('linkFromInfoResolutions', []); // const transporterKey = model.getData('transporterKey')
      // this.findAndLoadTransporterByKey(model, transporterKey)
    },

    selectConflictResolution(conflictCategory, valueGroup) {
      const model = this.model;
      const key = conflictCategory.param;
      const linkFromDetail = valueGroup.get('linkFromDetails').get('firstObject');
      conflictCategory.set('selected', []);
      conflictCategory.get('selected').pushObject(valueGroup);
      model.preLinkInfo(linkFromDetail, key);
    },

    sendEmailTask: (0, _emberConcurrency.task)(function* ({
      email,
      params
    }) {
      const model = this.model;
      const key = model.getData('_key');
      const adapterName = this.adapterName;
      const adapter = this.server.getAdapter(adapterName);
      const payload = R.mergeRight(email._data, params);
      const result = yield adapter.call({
        method: 'POST',
        appendPath: `emails/${key}`,
        data: payload
      });
      return result;
    }).drop(),

    removeAllDetails() {
      const model = this.model;
      model.removeAllDetails();
      this.onAddNewDetail();
    },

    getAndSetRewardsDataTask: (0, _emberConcurrency.task)(function* ({
      model
    }) {
      var _model$_data, _model$_data$rewardDa, _model$_data2, _model$_data2$rewardD, _contactRewardData$re;

      const contactKey = (model === null || model === void 0 ? void 0 : model.getData('contactKey')) || ''; // get contactData

      let contactData = (model === null || model === void 0 ? void 0 : model.get('selectedContact')) || {}; // on create
      // get rewardKey

      let rewardKey = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : (_model$_data$rewardDa = _model$_data.rewardData) === null || _model$_data$rewardDa === void 0 ? void 0 : _model$_data$rewardDa._key) || ''; // on update

      if (RA.isNilOrEmpty(rewardKey)) {
        rewardKey = (model === null || model === void 0 ? void 0 : model.get('rewardKey')) || ''; // on create
      }

      let rewardName = (model === null || model === void 0 ? void 0 : (_model$_data2 = model._data) === null || _model$_data2 === void 0 ? void 0 : (_model$_data2$rewardD = _model$_data2.rewardData) === null || _model$_data2$rewardD === void 0 ? void 0 : _model$_data2$rewardD.name) || ''; // on update

      if (RA.isNilOrEmpty(rewardName) && RA.isNotNilOrEmpty(rewardKey)) {
        const rewardData = yield this.dataManager.findRecord({
          adapterName: 'channels/shop/rewards',
          appendPath: `/valid/${rewardKey}`
        });
        rewardName = R.pathOr('', ['_data', 'name'])(rewardData); // on create
      }

      if (!contactKey || !rewardKey) {
        return false;
      }

      if (RA.isNilOrEmpty(contactData)) {
        contactData = yield this.dataManager.findRecord({
          // on update
          adapterName: 'contacts',
          appendPath: `/${contactKey}`
        });
      }

      let contactValidPoints = 0;
      let rewardEventForOrder = {};
      const contactRewardData = R.pipe(R.pathOr([], ['_data', 'contactRewardsData']), R.find(R.pathEq(['rewardData', '_key'], rewardKey)))(contactData) || {};
      contactValidPoints = R.pathOr(0, ['pointsData', 'validPoints'])(contactRewardData);
      rewardEventForOrder = R.pipe(R.pathOr([], ['level', 'rewardsEvents']), R.find(R.propEq('type', this.constants.rewardEvents.order)))(contactRewardData);
      model.setData('rewardData', {
        _key: rewardKey,
        name: rewardName,
        memberLevels: (contactRewardData === null || contactRewardData === void 0 ? void 0 : (_contactRewardData$re = contactRewardData.rewardData) === null || _contactRewardData$re === void 0 ? void 0 : _contactRewardData$re.memberLevels) || []
      });
      this.set('contactValidPoints', contactValidPoints);
      this.set('rewardEventForOrder', rewardEventForOrder);
    }),
    actions: {
      sumTotals() {
        const contactValidPoints = this.get('contactValidPoints');
        const rewardEventForOrder = this.get('rewardEventForOrder');
        this.model.sumTotals({
          contactValidPoints,
          rewardEventForOrder
        });
      },

      preLoadLinkedDetails(linkFromDetails, linkToDetail) {
        // @TODO: this can be done whilst adding linked detail?
        if (!Ember.isArray(linkFromDetails)) {
          linkFromDetails = [linkFromDetails];
        }

        const model = this.model;

        const editorValue = _nventor.default.createModel(_model.default, {
          doc: model.serialize()
        }, {
          isEditorValue: true
        });

        linkFromDetails.pushObject(editorValue);
        const possibleConflictProperties = model.get('linkAttrs').filter(key => {
          if (key.excludeForDocName) {
            if (!Ember.isArray(key.excludeForDocName)) {
              key.excludeForDocName = [key.excludeForDocName];
            }

            if (key.excludeForDocName.indexOf(this.docName) > -1) {
              return false;
            }
          }

          return true;
        });
        const linkFromInfoResolutions = possibleConflictProperties.map(key => {
          let component;
          let param;
          let group;
          let label;
          let translate;
          let translatePrefix;

          if (_nventor.default.confirm.isObject(key)) {
            if (key.param) {
              param = key.param;
            }

            if (key.group) {
              group = key.group;
            }

            if (key.translate) {
              translate = key.translate;
            }

            if (key.translatePrefix) {
              translatePrefix = key.translatePrefix;
            }

            component = key.component;
            label = key.label || param;
          } else {
            param = key;
          }

          if (!component) {
            component = 'documents/links-conflict/generic-conflict';
          }

          let numberOfDifferentValues = linkFromDetails.map(item => {
            if (group) {
              return JSON.stringify(group.map(param => _nventor.default.confirm.get(item._data.doc, param, '')));
            } else {
              return _nventor.default.confirm.get(item._data.doc, param, '');
            }
          }).filter((value, index, self) => self.indexOf(value) === index);

          if (group) {
            numberOfDifferentValues = numberOfDifferentValues.map(JSON.parse);
          }

          const linkFromInfoResolutionsGroup = Ember.Object.create({
            param: param,
            label: label || param,
            component,
            translate,
            translatePrefix,
            group: group,
            selected: [],
            values: numberOfDifferentValues.map(value => {
              let filteredLinkFromDetails;

              if (group) {
                filteredLinkFromDetails = group.reduce((filtered, param, index) => {
                  return filtered.filter(item => _nventor.default.confirm.get(item._data.doc, param, '') === value[index]);
                }, linkFromDetails);
                value = group.reduce((valueObj, param, index) => {
                  valueObj[param] = value[index];
                  return valueObj;
                }, {});
              } else {
                filteredLinkFromDetails = linkFromDetails.filter(item => _nventor.default.confirm.get(item._data.doc, param, '') === value);
              }

              return Ember.Object.create({
                value: value,
                linkFromDetails: filteredLinkFromDetails
              });
            })
          });
          this.selectConflictResolution(linkFromInfoResolutionsGroup, linkFromInfoResolutionsGroup.get('values').get('firstObject'));
          return linkFromInfoResolutionsGroup;
        });
        this.set('linkFromDetails', linkFromDetails);
        this.set('linkToDetail', linkToDetail);

        const hasLinkConflicts = this._getLinkConflicts(linkFromInfoResolutions);

        if (!Ember.isEmpty(hasLinkConflicts)) {
          this.set('hasLinkConflicts', hasLinkConflicts);
          this.set('isVisibleLinkConflictsModal', true);
        } else {
          this.set('hasLinkConflicts', []);
          this.set('isVisibleLinkConflictsModal', false);
          this.loadLinked();
        }
      },

      loadLinked() {
        this.loadLinked();
      },

      removeDetail(detail) {
        const model = this.model;
        const details = model.getData('details'); // only remove detail if current line is not last line

        if (details.get('lastObject').getData('index') === detail.getData('index')) {
          this.resetDetail(detail);
        } else {
          model.removeDetail(detail);
        } // recalculate totals


        model.sumTotals();
      },

      resetDetail(detail) {
        this.resetDetail(detail);
      },

      removeAllDetails() {
        this.removeAllDetails();
      },

      toggleIsVoidStatus() {
        const model = this.model;
        let isVoid = model.getData('isVoid') || false;

        if (isVoid) {
          isVoid = false;
        } else {
          isVoid = true;
        }

        model.setData('isVoid', isVoid);
      },

      selectConflictResolution(conflictCategory, valueGroup) {
        this.selectConflictResolution(conflictCategory, valueGroup);
      },

      setupSendEmail() {
        const model = this.model;
        const tokenData = this.storage.get('tokenData');
        const docType = this.docType;
        const docName = this.docName;
        let docNo = model.getData('docNo');

        if (model.getData('ref') && model.getData('sendRefAsDocNo')) {
          docNo = model.getData('ref');
        }

        const subject = this.intl.t(`${docType}-${docName}`) + ' - #' + docNo;
        const email = Ember.Object.create({
          _data: {
            toAddress: model.getData('email'),
            toName: model.getData('contact'),
            fromName: tokenData.name,
            fromAddress: tokenData.email,
            subject
          }
        });
        this.set('email', email);
      },

      clearSendEmail() {
        this.set('email', null);
      },

      async sendEmail(email, onCloseDialogAction, params = {}) {
        await this.sendEmailTask.perform({
          email,
          params
        });
        onCloseDialogAction();
      },

      cancelSendEmail(onCloseDialogAction) {
        onCloseDialogAction();
        this.set('email', null);
      },

      loadTerms(term) {
        this.loadTerms(term);
      },

      toggleDispatchProcesses() {
        this.toggleProperty('showDispatchProcesses');
      },

      showPreDispatchProcessDialog() {
        this.set('showPreDispatchProcess', true);
      },

      closePreDispatchProcessDialog() {
        this.set('showPreDispatchProcess', false);
      },

      showWaybills(model, dispatchProcess) {
        if (!R.is(Array, model) && dispatchProcess.getData('code') === 'dispatched' && model.getData('transporterExtension') === 'hct') {
          this.set('showWaybills', true);
        }
      },

      quickLinkAllQtys(isQuickLinkAll) {
        const details = this.model.getData('details') || []; // need to first set all as 0, otherwise detail.remainingQty will be wrong if user already entered a qty

        let newDetails = R.map(detail => {
          detail.setData('qty', 0);
          return detail;
        })(details);

        if (isQuickLinkAll) {
          newDetails = R.map(detail => {
            detail.setData('qty', detail.remainingQty);
            return detail;
          })(details);
        }

        this.model.setData('details', newDetails);
        this.model.sumTotals();
      },

      afterReturns(saved, savedOptions = {}) {
        if (savedOptions.isAutoCancelInvoice) {
          this.set('showCancelInvoiceModal', true);
        } else if (saved) {
          var _saved$model, _saved$model$_data, _saved$_data;

          if (((saved === null || saved === void 0 ? void 0 : (_saved$model = saved.model) === null || _saved$model === void 0 ? void 0 : (_saved$model$_data = _saved$model._data) === null || _saved$model$_data === void 0 ? void 0 : _saved$model$_data.isVoid) === true || (saved === null || saved === void 0 ? void 0 : (_saved$_data = saved._data) === null || _saved$_data === void 0 ? void 0 : _saved$_data.isVoid) === true) && this.docType === 'sales' && this.docName === 'invoices') {
            if (saved.lastSuccessfulEInvoiceNumber) {
              this.set('showCancelInvoiceModal', true);
            }
          }
        }
      }

    }
  });

  _exports.default = _default;
});