define("client/pods/components/channels/shop/campaigns/campaigns-report/component", ["exports", "ember-concurrency", "client/utils/nventor"], function (_exports, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    guests: Ember.inject.service(),
    revenueBy: 'ordered',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/reports/filters', 'taxMethods/filters', 'periods/months', 'periods/years']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: this.revenueBy,
        taxMethods: 'inclusive'
      }));
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),

    didInsertElement() {
      this._super(...arguments);

      let sourceKey = '';

      if (!this.isGuest) {
        var _model$_data;

        const model = this.model;
        sourceKey = model.getData('_key');

        if (!(model !== null && model !== void 0 && (_model$_data = model._data) !== null && _model$_data !== void 0 && _model$_data.dateEndZ)) {
          this._setCurrentPeriod();
        }
      } else {
        const tokenData = this.tokenData || {};

        if (tokenData !== null && tokenData !== void 0 && tokenData.version) {
          if (!(tokenData !== null && tokenData !== void 0 && tokenData.campaignDateEndZ)) {
            this._setCurrentPeriod();
          }
        }
      }

      this.fetchRevenueReportTask.perform({
        sourceKey
      });
    },

    _setCurrentPeriod() {
      const period = this.dateService.getPeriod();
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
    },

    resetPeriodTask: (0, _emberConcurrency.task)(function* ({
      sourceKey,
      onAfter
    }) {
      this.set('dateStart', '');
      this.set('dateEnd', '');
      this.set('mm', '');
      this.set('yyyy', '');
      yield this.fetchRevenueReportTask.perform({
        sourceKey,
        onAfter
      });
    }),
    fetchRevenueReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey,
      onAfter
    }) {
      this.set('campaignsActivity', null);
      yield this._fetchReportTask.perform('campaigns-revenue', {
        sourceKey
      });

      if (onAfter) {
        onAfter();
      }
    }),
    fetchActivityReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      yield this._fetchReportTask.perform('campaigns-activity', {
        sourceKey
      });
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function* (reportName, {
      sourceKey
    }) {
      const params = {
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'contact', 'item', 'itemSets', 'trackingCodes', 'paymentMethodKey', 'status']
      };
      const mm = this.mm;
      const yyyy = this.yyyy;

      if (mm && yyyy) {
        var _this$showCampaignRep;

        const monthEnd = this.dateService.getMonthEnd(yyyy, mm).format('YYYY-MM-DD');
        const dateStart = `${yyyy}-${mm}-01`;
        const dateEnd = monthEnd;
        const revenueBy = (_this$showCampaignRep = this.showCampaignReportBy) === null || _this$showCampaignRep === void 0 ? void 0 : _this$showCampaignRep.revenueBy;

        if (revenueBy === 'dispatched') {
          params.dispatchDateStart = dateStart;
          params.dispatchDateEnd = dateEnd;
        } else {
          params.dateStart = dateStart;
          params.dateEnd = dateEnd;
        }

        this.set('dateStart', dateStart);
        this.set('dateEnd', dateEnd);
      } else {
        this.set('dateStart', '');
        this.set('dateEnd', '');
      }

      let report;

      if (this.isGuest) {
        params.report = reportName;
        report = yield this.guests.call('GET', 'api/guests/channels/shop/campaigns/report', params);
      } else {
        params.sourceKey = sourceKey;
        report = yield this.server.call('GET', `api/protected/reports/${reportName}`, params);
      }

      const reportNameCamelCase = _nventor.default.dashToCamelCase(reportName);

      this.set(reportNameCamelCase, report.data);
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});