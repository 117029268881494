define("client/pods/components/helpers/info-tooltip-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasContent: true,
    isViewable: false,
    closeOnOverlayClick: true,
    hasModalOverlay: true,
    autoPosition: true
  });

  _exports.default = _default;
});