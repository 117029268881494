define("client/pods/components/products/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWsTCKgI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"products/products-editor\",null,[[\"model\",\"errors\",\"onSaveTask\",\"onImportTask\",\"isNew\",\"tab\",\"isDuplicate\",\"onCancel\",\"isRunning\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\"],[[23,0,[\"adapterName\"]],[24,[\"model\"]],[24,[\"onAfterCreateRecordTask\"]]]]]],null],[24,[\"onImportTask\"]],true,[24,[\"tab\"]],[24,[\"isDuplicate\"]],[24,[\"onCancel\"]],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});