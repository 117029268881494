define("client/pods/components/helpers/creator-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['u-desc', 'u-desc--mini', 'u-desc--italics'],
    iconOnly: false,
    popupIsVisible: false,
    sortedModified: Ember.computed('model._data.modified.[]', function () {
      let modified = this.get('model._data.modified') || [];

      if (!Ember.isArray(modified)) {
        modified = [modified];
      }

      return modified.sortBy('md');
    }),
    isPopupVisible: Ember.computed('showPopup', 'sortedModified.[]', function () {
      if (this.iconOnly && this.showPopup) {
        return true;
      }

      if (this.sortedModified.length > 0 && this.showPopup) {
        return true;
      }

      return false;
    }),
    actions: {
      togglePopup() {
        this.toggleProperty('showPopup');
      }

    }
  });

  _exports.default = _default;
});