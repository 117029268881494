define("client/pods/contacts/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let ContactsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class ContactsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "all", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "customer", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "supplier", _descriptor6, this);
    }

    *searchInputTask({
      relationship = 'all',
      query = '',
      resultsProperty = '',
      component,
      resultsToggle = false,
      global = true,
      match = []
    }) {
      const relationships = [];

      if (relationship === 'all') {
        relationships.push('customer');
        relationships.push('supplier');
      } else {
        relationships.push(relationship);
      }

      const filters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          relationships,
          query,
          match,
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          status: [this.constants.status.active]
        }
      });
      const results = yield this.crud.searchInputTask.perform({
        adapterName: 'contacts',
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle
      });

      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }

      return results;
    }

    loadContactProps({
      props,
      model
    }, selected) {
      R.mapObjIndexed((toProp, fromProp) => {
        const fromValue = selected.getData(fromProp);
        model.set(toProp, fromValue);
      })(props);
    }

    unloadContactProps({
      props,
      model
    }, selected) {
      R.mapObjIndexed((toProp, fromProp) => {
        model.set(toProp, '');
      })(props);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "all", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "supplier", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadContactProps", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactProps"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unloadContactProps", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "unloadContactProps"), _class.prototype)), _class));
  _exports.default = ContactsService;
});