define("client/pods/documents/mixins/adapters", ["exports", "client/mixins/adapters-new"], function (_exports, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../utils/nventor'
  var _default = Ember.Mixin.create(_adaptersNew.default, {
    serialize(model) {
      const data = this._super(model);

      const detailsRequiredAttrs = this.detailsRequiredAttrs;
      const details = data.details || [];
      data.details = details.filter(detail => this.isNotEmptyDetail(detail, detailsRequiredAttrs));
      return data;
    }

  });

  _exports.default = _default;
});