define("client/mixins/payments-list-by", ["exports", "ramda", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    listByTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      appendPath,
      match,
      abandoned,
      status,
      paymentCreditCardStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);

      if (match) {
        filters.setData('match', match);
      }

      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }

      if (abandoned) {
        filters.setData('abandoned', abandoned);
      }

      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }

      if (status) {
        if (!R.is(Array, status)) {
          status = [status];
        }

        filters.setData('status', status);
      }

      if (paymentCreditCardStatus) {
        if (!R.is(Array, paymentCreditCardStatus)) {
          paymentCreditCardStatus = [paymentCreditCardStatus];
        }

        filters.setData('paymentCreditCardStatus', paymentCreditCardStatus);
      }

      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }

      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }

      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }

      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.listByTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});