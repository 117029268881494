define("client/pods/websites/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll(data = {}, appendPath) {
      const list = [{
        component: 'websites/panels-dash',
        resource: 'api/protected/websites/lists/menu/actions/dash',
        label: 'dashboard',
        action: 'dash',
        tabType: 'replaceSubTab'
      }, {
        component: 'websites/panels-new',
        resource: 'api/protected/websites/lists/menu/actions/new',
        label: 'new',
        action: 'new',
        tabType: 'replaceSubTab'
      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});