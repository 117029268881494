define("client/pods/components/channels/shop/campaigns/campaigns-editor/3rd-party-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X2norxqH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"settings/defaults/3rd-party-editor\",null,[[\"isReadonly\",\"errors\",\"model\"],[[24,[\"isReadonly\"]],[24,[\"errors\"]],[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/3rd-party-editor/template.hbs"
    }
  });

  _exports.default = _default;
});