define("client/pods/components/tabs/tab-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: [''],
    classNameBindings: ['tab.isActive', 'tab.isClosing'],
    // activeClassName: 'tab-header--active',
    // closeIconClassName: 'u-icon__close',
    translate: true,
    // labelKey: 'label',
    // labelPrefixKey: 'labelPrefix',
    allowClose: true,
    // should tabs be allowed to be closed
    // dynamically sets activeClassName to tab when active
    // isActive: computed('tab.isActive', function () {
    //   if (this.get('tab.isActive')) {
    //     return this.get('activeClassName') || 'is-active';
    //   }
    //   return '';
    // }),
    tabTitle: Ember.computed('title', 'label', function () {
      const title = this.title;

      if (!title) {
        return this.label;
      }

      return title;
    }),

    click() {
      const tabs = this.tabs;
      const tab = this.tab;
      this.tabsManager.selectTab(tabs, tab);
    },

    actions: {
      closeTab: function () {
        const tabs = this.tabs;
        const tab = this.tab;
        this.tabsManager.closeTab(tabs, tab);
      }
    }
  });

  _exports.default = _default;
});