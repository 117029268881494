define("client/pods/components/elements/element-inputpvt/component", ["exports", "ember-concurrency", "jquery", "ramda"], function (_exports, _emberConcurrency, _jquery, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    attributeBindings: ['placeholder', 'disabled'],
    // classNames: ['element-inputpvt'],
    classNames: ['input', 'is-small'],
    // classNameBindings: ['hasError:has-error', 'isDatePicker'],
    classNameBindings: ['isDatePicker'],
    autofocus: false,
    hasError: null,
    translate: true,
    placeholder: '',
    readonly: '',

    /**
     * input event:
     * similar to keyUp except only triggers if input value changes
     * (unsupported in IE < 9)
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    input(e) {
      if (this.regex) {
        const regex = new RegExp(this.regex);
        const regexValue = R.pipe(R.split(regex), R.join(''))(this.value);
        Ember.set(this, 'value', regexValue);
      }

      this.debounceTask.perform(e);
    },

    debounceTask: (0, _emberConcurrency.task)(function* (e) {
      const DEBOUNCE_MS = 450;
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

      if (this.onInput) {
        this.onInput(this.value, e);
      }
    }).restartable(),

    didInsertElement() {
      this._super(...arguments);

      if (this.autofocus && !this.readonly && !this.disabled) {
        (0, _jquery.default)(this.element).select();
      }
    }

  });

  _exports.default = _default;
});