define("client/pods/components/tabs/for-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g7sAYyKI",
    "block": "{\"symbols\":[\"subTab\",\"index\",\"&default\",\"@tabs\"],\"statements\":[[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"isActive\"]],\"tab-visible\"],null]]]],[11,\"hidden\",[28,\"if\",[[23,1,[\"isActive\"]],false,true],null]],[8],[0,\"\\n    \"],[14,3,[[23,1,[]],[23,2,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/tabs/for-each/template.hbs"
    }
  });

  _exports.default = _default;
});