define("client/pods/websites/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda", "ramda-adjunct", "client/pods/base/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, R, RA, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  /**
   * Website Model Type
   * @typedef {Object} WebsiteDataType
   * @property {boolean} facebookPluginEnable
   * @property {boolean} hasCustomDomain
   * @property {boolean} lineChatEnable
   * @property {boolean} menuExpandSubMenus
   * @property {boolean} menuIsAlwaysHidden
   * @property {boolean} menuShowName
   * @property {boolean} menuShowLogo
   * @property {boolean} menuShowLogoSize
   * @property {boolean} widthDefault
   * @property {boolean} widthFull
   * @property {boolean} useDefaultsContactInfo
   * @property {string} defaultHomePageTemplate
   * @property {string} domain
   * @property {string} menuPosition
   * @property {string} menuStyle
   * @property {string} name
   * @property {string} status
   * @property {string} subdomain
   * @property {string} blogsImagesRatio
   * @property {string} themeActionColor
   * @property {string} themeActionFinalColor
   * @property {string} themeActionFinalFocusColor
   * @property {string} themeActionFinalHoverColor
   * @property {string} themeActionFinalTextColor
   * @property {string} themeActionHoverColor
   * @property {string} themeActionTextColor
   * @property {string} themeActiveColor
   * @property {string} themeBackgroundPrimaryColor
   * @property {string} themeBackgroundSecondaryColor
   * @property {string} themeBorderColor
   * @property {string} themeButtonBorderRadius
   * @property {string} themeButtonBorderColor
   * @property {string} themeButtonBorderColor2
   * @property {string} themeButtonBorderColor3
   * @property {string} themeButtonColor
   * @property {string} themeButtonColor2
   * @property {string} themeButtonColor3
   * @property {string} themeButtonHoverColor
   * @property {string} themeButtonHoverColor2
   * @property {string} themeButtonHoverColor3
   * @property {string} themeButtonTextColor
   * @property {string} themeButtonTextColor2
   * @property {string} themeButtonTextColor3
   * @property {string} themeChatButtonColor
   * @property {string} themeChatButtonHoverColor
   * @property {string} themeChatButtonTextColor
   * @property {string} themeCountDownColor
   * @property {string} themeCountDownTextColor
   * @property {string} themeFooterBackgroundColor
   * @property {string} themeFooterLinkColor
   * @property {string} themeFooterLinkHoverColor
   * @property {string} themeFooterTextColor
   * @property {string} themeInputBackgroundColor
   * @property {string} themeInputBorderFocusColor
   * @property {string} themeInputTextColor
   * @property {string} themeLinkColor
   * @property {string} themeLinkHoverColor
   * @property {string} themeMenuBackgroundPrimaryColor
   * @property {string} themeMenuBackgroundSecondaryColor
   * @property {string} themeMenuBorderColor
   * @property {string} themeMenuLinkColor
   * @property {string} themeMenuLinkHoverColor
   * @property {string} themeSaleColor
   * @property {string} themeSaleTextColor
   * @property {string} themeTagColor
   * @property {string} themeTagTextColor
   * @property {string} themeText2Color
   * @property {string} themeText3Color
   * @property {string} themeTextColor
   * @property {string} widthCustom
   */

  /**
   * Website Model Type
   * @typedef {Object} WebsiteType
   * @property {WebsiteDataType} _data
   */
  const WebsitesBaseModel = _model.default.extend({
    defaults() {
      const settings = this.settings;
      const linkColor = '#000000';
      const linkHoverColor = '#262626';
      const primaryColor = '#e4666f';
      const primaryContrastColor = '#ffffff';
      const primaryHoverColor = '#fa2e3c';
      const textColor = '#666666';
      const inputTextColor = '#363636';
      const whiteTextColor = '#ffffff';
      const secondaryColor = '#000000';
      const secondaryContrastColor = '#ffffff';
      const secondaryHoverColor = '#262626';
      const neutralColor = '#ffffff';
      const neutralHoverColor = '#eeeeee';
      const backgroundColor = '#ffffff';
      const borderColor = '#eeeeee';
      const secondaryBackgroundColor = '#eeeeee';
      const secondaryBackgroundTextColor = '#666666';
      const secondaryBackgroundLinkColor = '#000000';
      const secondaryBackgroundLinkHoverColor = '#262626';
      const chatColor = '#66aee4';
      const chatHoverColor = '#4390ca';
      const chatTextColor = '#ffffff';
      const saleColor = '#ff4a68';
      const saleTextColor = '#ffffff';
      const activeColor = '#000000';
      const tagColor = '#f5f5f5';
      const tagTextColor = '#666666';
      const defaultRadius = '20px';
      const defaultSpacing = '0px';
      const blogsImagesRatio = settings.getProp('websiteBlogsImagesRatio');
      return {
        name: '',
        subdomain: '',
        domain: '',
        defaultHomePageTemplate: {
          pageDataString: '{"html":"","css":"*{box-sizing: border-box;} body {margin: 0;}","assets":[],"styles":[],"components":[]}'
        },
        themeName: '',
        hasCustomDomain: false,
        blogsImagesRatio: settings.getProp('websiteBlogsImagesRatio'),
        openHours: {
          monday: {
            open: 'none',
            close: 'none'
          },
          tuesday: {
            open: 'none',
            close: 'none'
          },
          wednesday: {
            open: 'none',
            close: 'none'
          },
          thursday: {
            open: 'none',
            close: 'none'
          },
          friday: {
            open: 'none',
            close: 'none'
          },
          saturday: {
            open: 'none',
            close: 'none'
          },
          sunday: {
            open: 'none',
            close: 'none'
          }
        },
        facebookPluginEnable: settings.getProp('facebookPluginEnable', false),
        lineChatEnable: settings.getProp('lineChatEnable', false),
        status: this.get('constants.status.active'),
        widthDefault: true,
        widthFull: false,
        widthCustom: '1344',
        menuIsAlwaysHidden: false,
        menuExpandSubMenus: false,
        menuStyle: 'horizontal',
        menuShowName: true,
        menuShowLogo: true,
        menuShowLogoSize: '20px',
        menuFontSize: '14px',
        menuPosition: 'left',
        showQuickNav: true,
        useDefaultsContactInfo: true,
        themeActionColor: primaryColor,
        themeActionTextColor: primaryContrastColor,
        themeActionHoverColor: primaryHoverColor,
        themeActionFinalColor: secondaryColor,
        themeActionFinalFocusColor: secondaryHoverColor,
        themeActionFinalHoverColor: secondaryHoverColor,
        themeActionFinalTextColor: secondaryContrastColor,
        themeActiveColor: activeColor,
        themeBackgroundPrimaryColor: backgroundColor,
        themeBackgroundSecondaryColor: backgroundColor,
        themeBorderColor: borderColor,
        themeTagColor: tagColor,
        themeTagTextColor: tagTextColor,
        themeButtonBorderRadius: defaultRadius,
        themeButtonBorderRadius2: defaultRadius,
        themeButtonBorderRadius3: defaultRadius,
        themeButtonBorderColor: borderColor,
        themeButtonColor: neutralColor,
        themeButtonHoverColor: neutralHoverColor,
        themeButtonHoverTextColor: primaryHoverColor,
        themeButtonTextColor: textColor,
        themeButtonBorderColor2: '#b8b8b8',
        themeButtonColor2: '#b8b8b8',
        themeButtonHoverColor2: '#666666',
        themeButtonHoverTextColor2: primaryHoverColor,
        themeButtonTextColor2: secondaryContrastColor,
        themeButtonBorderColor3: '#666666',
        themeButtonColor3: '#666666',
        themeButtonHoverColor3: '#b8b8b8',
        themeButtonHoverTextColor3: primaryHoverColor,
        themeButtonTextColor3: secondaryContrastColor,
        themeChatButtonColor: chatColor,
        themeChatButtonHoverColor: chatHoverColor,
        themeChatButtonTextColor: chatTextColor,
        themeCountDownColor: secondaryColor,
        themeCountDownTextColor: whiteTextColor,
        themeFooterHeaderColor: primaryColor,
        themeFooterBackgroundColor: secondaryBackgroundColor,
        themeFooterLinkColor: secondaryBackgroundLinkColor,
        themeFooterLinkHoverColor: secondaryBackgroundLinkHoverColor,
        themeFooterTextColor: secondaryBackgroundTextColor,
        themeInputTextColor: inputTextColor,
        themeInputBackgroundColor: backgroundColor,
        themeInputBorderFocusColor: borderColor,
        themeLinkColor: linkColor,
        themeLinkHoverColor: linkHoverColor,
        themeMenuBackgroundPrimaryColor: backgroundColor,
        themeMenuBackgroundSecondaryColor: backgroundColor,
        themeMenuBorderColor: borderColor,
        themeMenuLinkColor: linkColor,
        themeMenuLinkHoverColor: linkHoverColor,
        themeText2Color: textColor,
        themeText3Color: textColor,
        themeTextColor: textColor,
        themeSaleColor: saleColor,
        themeSaleTextColor: saleTextColor,
        themeNavTreeItemPaddingX: '0px',
        themeNavTreeItemMarginX: '0px',
        themeGeneralLetterSpacing: defaultSpacing,
        themeHeaderH1LetterSpacing: defaultSpacing,
        themeHeaderH2LetterSpacing: defaultSpacing,
        themeHeaderH3LetterSpacing: defaultSpacing,
        themeHeaderH4LetterSpacing: defaultSpacing,
        themeHeaderH5LetterSpacing: defaultSpacing,
        themeHeaderH6LetterSpacing: defaultSpacing
      };
    },

    populate(data, attrs) {
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);

      this._super(data, attrs);
    },

    colors: Ember.computed('synced', function () {
      return R.pipe(R.propOr({}, '_data'), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme(?!Name)/))), R.sort(([a], [b]) => a.localeCompare(b)), R.map(R.zipObj(['key', 'value'])))(this);
    }),
    wizardTabRequirements: [['name', 'subdomain'], ['defaultHomePageTemplate', 'themeName']],
    wizardRequirementsMet: Ember.computed('_data.{name,subdomain,defaultHomePageTemplate,themeName}', function () {
      const wizardTabRequirements = this.wizardTabRequirements || [];
      const requirements = R.pipe(R.map(req => R.pick(req)(this._data)), R.reject(R.isEmpty))(wizardTabRequirements);

      if (R.isEmpty(requirements)) {
        return requirements;
      }

      const noEmpty = R.pipe(R.isEmpty, R.not);
      return R.pipe(R.map(R.pipe(R.map(noEmpty), R.values, RA.allEqualTo(true))))(requirements);
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;

      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    }) // serialize (model) {
    //   const data = this._super(model)
    //   // dont allow '__' in url
    //   const subdomain = data.subdomain || ''
    //   data.subdomain = R.replace(/__/g, '')(subdomain)
    //   const domain = data.domain || ''
    //   data.domain = R.replace(/__/g, '')(domain)
    //   return data
    // }

  });

  let WebsitesModel = (_dec = Ember._tracked, (_class = (_temp = class WebsitesModel extends WebsitesBaseModel {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WebsitesModel;
});