define("client/pods/tabs-manager/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let TabsManagerModel = (_dec = Ember._tracked, (_class = (_temp = class TabsManagerModel extends Ember.Object {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "subTabs", _descriptor, this);
      this.resetTabs();
    }

    resetTabs() {
      const tabs = [];
      tabs._history = [];
      tabs._closed = [];
      this.subTabs = tabs;
    }

    get subTabsArray() {
      return this.subTabs;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "subTabs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TabsManagerModel;
});