define("client/pods/components/settings/defaults/3rd-party-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSocialLinksReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsSocialLinks', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.get('model._data.useDefaultsSocialLinks');
    }),
    isFacebookReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsFacebook', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.get('model._data.useDefaultsFacebook');
    })
  });

  _exports.default = _default;
});