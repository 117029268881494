define("client/pods/components/admin/payments/card/panels-abandoned/payments-detail/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      var _this$tempDoc, _this$tempDoc$_data, _this$tempDoc$_data$p;

      this._super(...arguments);

      this.set('transactionCode', this === null || this === void 0 ? void 0 : (_this$tempDoc = this.tempDoc) === null || _this$tempDoc === void 0 ? void 0 : (_this$tempDoc$_data = _this$tempDoc._data) === null || _this$tempDoc$_data === void 0 ? void 0 : (_this$tempDoc$_data$p = _this$tempDoc$_data.paymentResponse) === null || _this$tempDoc$_data$p === void 0 ? void 0 : _this$tempDoc$_data$p.transactionCode);
    },

    fetchGMODataTask: (0, _emberConcurrency.task)(function* (tempDoc) {
      const response = yield this.server.call('GET', 'api/protected/admin/payments/card/temp/response', {
        _key: tempDoc._data._key,
        eventId: tempDoc._data.eventId
      });
      const request = R.pathOr({}, ['data', 'request'])(response);
      tempDoc.set('request', request);
      const event = R.pathOr('', ['data', 'event'])(response);
      tempDoc.set('event', event);
      const response3ds = R.pathOr('', ['data', 'response3ds'])(response);
      tempDoc.set('response3ds', response3ds);

      try {
        const requestString = JSON.stringify(request, undefined, 2);
        tempDoc.set('requestString', requestString);
        const eventString = JSON.stringify(event, undefined, 2);
        tempDoc.set('eventString', eventString);
        const response3dsString = JSON.stringify(response3ds, undefined, 2);
        tempDoc.set('response3dsString', response3dsString);
      } catch (e) {
        console.log(e);
      }
    })
  });

  _exports.default = _default;
});