define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-footer-btns/component", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),

    init() {
      this.crud.addLists(this, ['dispatch/lists/processes', 'users/lists/menu', 'periods/months', 'periods/years']);

      this._super(...arguments);

      const period = this.dateService.getPeriod();
      const dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.setNewPeriod({
        dateStart,
        dateEnd
      });
    },

    setNewPeriod(period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
    },

    isDispatchedDispatchProcess: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses);
    })
  });

  _exports.default = _default;
});