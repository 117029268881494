define("client/pods/components/files/files-browser-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCkwRXkE",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@allowMultipleItems\",\"@resource\",\"@resourceKey\",\"@finderLabel\",\"@tab\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@closeOnOverlayClick\",\"@onToggle\"],[\"search images\",[22,\"modalDialogIsVisible\"],true,[28,\"fn\",[[28,\"mut\",[[24,[\"modalDialogIsVisible\"]]],null]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"files-manager/finder\",[],[[\"@allowMultipleItems\",\"@resource\",\"@resourceKey\",\"@finderLabel\",\"@onSubmitTask\",\"@onCloseDialogAction\",\"@tab\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"onSubmitTask\"]],[23,1,[]],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-browser-modal/template.hbs"
    }
  });

  _exports.default = _default;
});