define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+K0M3C2",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor/has-variants/variants-detail\",null,[[\"tab\",\"salesPersons\",\"isHeaders\"],[[24,[\"tab\"]],[24,[\"campaign\",\"_data\",\"salesPersons\"]],true]]],false],[0,\"\\n\"],[4,\"each\",[[24,[\"variantChildrenSorted\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor/has-variants/variants-detail\",null,[[\"tab\",\"campaign\",\"parentModel\",\"item\",\"isSetPriceOnBundle\",\"isReadonly\",\"child\"],[[24,[\"tab\"]],[24,[\"campaign\"]],[24,[\"parentModel\"]],[24,[\"item\"]],[24,[\"isSetPriceOnBundle\"]],[24,[\"isReadonly\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/template.hbs"
    }
  });

  _exports.default = _default;
});