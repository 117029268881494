define("client/pods/components/channels/shop/campaigns/mixins/campaigns-products", ["exports", "ramda", "ramda-extension", "ember-concurrency"], function (_exports, R, R_, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fetchIsSetProductTask: (0, _emberConcurrency.task)(function* (itemKey) {
      // @TODO: not sure if used
      const adapterName = 'products';
      const appendPath = `/${itemKey}`;
      return yield this.dataManager.findRecord({
        adapterName,
        appendPath
      });
    }).enqueue().maxConcurrency(5),

    hasDuplicate(item, product) {
      // const campaignDetails = this.get('campaignDetails')
      // const hasEqualItemKey = R.pathEq(['_data', 'itemKey'], product.getData('_key'))
      // const found = R.find(hasEqualItemKey)(campaignDetails)
      // if (found) {
      //   const msg = this.get('intl').t('duplicate product')
      //   window.alert(msg)
      //   item.resetDetail(item)
      //   return true
      // }
      return false;
    },

    loadProductTask: (0, _emberConcurrency.task)(function* (campaign, item, product) {
      if (!this.hasDuplicate(item, product)) {
        yield this._loadProductTask.perform(...arguments);
      }
    }),
    _loadProductTask: (0, _emberConcurrency.task)(function* (campaign, item, product) {
      this.set('product', product);
      const itemKey = product.getData('_key');
      const productsService = this.productsService;
      const {
        preferredHistoryData
      } = yield productsService.get('fetchHistoryCampaignsTask').perform(product, {
        itemKey
      });
      yield item.loadProduct(campaign, item, product, preferredHistoryData);

      if (R_.dotPathEq('_data.isSet', true, item)) {
        item.recalculatePriceForSet(item);
      }
    }),
    // currently not used
    loadAutomationProductTask: (0, _emberConcurrency.task)(function* (campaign, model, item, product) {
      if (!this.hasDuplicate(model, item, product)) {
        this.set('product', product);
        const itemKey = product.getData('_key');
        const productsService = this.productsService;
        const {
          preferredHistoryData
        } = yield productsService.get('fetchHistoryCampaignsAutomationsTask').perform(product, {
          itemKey
        }); // this.set('priceHistoryData', priceHistoryData)
        // const item = this.get('item')

        yield model.loadProduct(campaign, item, product, preferredHistoryData); // if (this.addNewDetail) {
        //   this.addNewDetail()
        // }
      }
    }),
    reSyncProductTask: (0, _emberConcurrency.task)(function* (campaign, item, itemKey, {
      onAfter
    } = {}) {
      const msg = this.intl.t('are you sure you want to re-sync product');

      if (window.confirm(msg)) {
        const product = yield this.findTask.perform({
          adapterName: 'products',
          appendPath: `/${itemKey}`
        });

        this._loadProductTask.perform(campaign, item, product);

        if (onAfter) {
          onAfter();
        }
      }
    }).drop(),
    actions: {
      addAndLoadProduct(onCloseDialogAction, campaign, item, product) {
        onCloseDialogAction();
        item.setData('itemKey', product.getData('_key'));
        item.setData('itemCode', product.getData('code'));
        this.loadProductTask.perform(campaign, item, product);
      },

      // currently not used
      addAndLoadAutomationProduct(onCloseDialogAction, campaign, model, item, product) {
        onCloseDialogAction();
        item.setData('itemKey', product.getData('_key'));
        item.setData('itemCode', product.getData('code'));
        this.loadAutomationProductTask.perform(campaign, model, item, product);
      },

      cancelAddProduct(onCloseDialogAction) {
        onCloseDialogAction();
      },

      setLastItemCode(value) {
        this.set('lastItemCode', value);
      }

    }
  });

  _exports.default = _default;
});