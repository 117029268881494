define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/regions-column/regions-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnRegionsRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnRegionsRowComponent extends _component.default {
    constructor() {
      var _country$_data, _region$_data;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isSelected", _descriptor4, this);
      const country = this.args.country;
      const countryRegions = country === null || country === void 0 ? void 0 : (_country$_data = country._data) === null || _country$_data === void 0 ? void 0 : _country$_data.regions;
      const region = this.args.region;
      const regionCode = region === null || region === void 0 ? void 0 : (_region$_data = region._data) === null || _region$_data === void 0 ? void 0 : _region$_data.key;
      this.isSelected = _ramda.default.includes(regionCode, countryRegions);
      region.isSelected = this.isSelected;
    }

    onToggleSetSelectedRegion(country, region, isSelected) {
      const selectedRegionKey = _ramda.default.pathOr('', ['_data', 'key'])(region);

      let regions = _ramda.default.pathOr([], ['_data', 'regions'])(country);

      if (isSelected) {
        regions = _ramda.default.append(selectedRegionKey, regions);
      } else {
        regions = _ramda.default.reject(_ramda.default.equals(selectedRegionKey))(regions);
      }

      country.set('_data.regions', regions);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetSelectedRegion", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetSelectedRegion"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesRegionsColumnRegionsRowComponent;
});