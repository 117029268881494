define("client/pods/components/elements/element-toggler/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    translate: true,
    toggle: false,
    toggleValue: false,
    toggleOnValue: true,
    toggleOffValue: false,
    tagName: '',

    init() {
      this._super(...arguments);

      const toggleValue = this.toggleValue;

      if (toggleValue === this.toggleOnValue) {
        this.set('toggle', true);
      } else {
        this.set('toggle', false);
      }
    },

    isOn: Ember.computed('toggleValue', 'toggleOnValue', function () {
      if (this.toggleValue === this.toggleOnValue) {
        return true;
      }

      return false;
    }),
    isOff: Ember.computed('isOn', function () {
      if (this.isOn) {
        return false;
      }

      return true;
    }),
    actions: {
      toggle() {
        const disabled = this.disabled;

        if (!disabled) {
          this.toggleProperty('toggle');
          const toggle = this.toggle;

          if (toggle) {
            this.set('toggleValue', this.toggleOnValue);
          } else {
            this.set('toggleValue', this.toggleOffValue);
          }

          if (this.onToggleWhenFalse && !toggle) {
            return this.onToggleWhenFalse();
          }

          if (this.onToggleWhenTrue && toggle) {
            return this.onToggleWhenTrue();
          }

          const onToggle = this.onToggle;

          if (onToggle) {
            return onToggle(toggle);
          }
        }
      }

    }
  });

  _exports.default = _default;
});