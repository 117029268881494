define("client/pods/websites/lists/types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: this.get('constants.websitePageTypes.page'),
        label: 'website_type_page'
      }, {
        value: this.get('constants.websitePageTypes.link'),
        label: 'website_type_link'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});