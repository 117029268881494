define("client/pods/components/ledgers/accounts/aging/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSv/DAQJ",
    "block": "{\"symbols\":[\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-header-1\"],[8],[0,\"\\n\\t\"],[1,[28,\"tt\",[[24,[\"period\",\"name\"]]],null],false],[0,\" \"],[1,[24,[\"period\",\"yyyy\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"forms/form-section\",[],[[\"@title\"],[[23,1,[\"label\"]]]],{\"statements\":[[0,\"\\n\\t\"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@detailsRowComponent\",\"@filters\",\"@results\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@paginationIsFooter\",\"@isRunning\"],[[23,1,[]],\"ledgers/accounts/aging/panels-display/table-view/detail-row\",[23,1,[\"filters\"]],[23,1,[\"filters\",\"results\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],false,[28,\"or\",[[23,0,[\"crud\",\"searchRecordsTask\",\"isRunning\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/accounts/aging/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});