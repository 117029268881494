define("client/pods/components/contacts/panels-display/sub-panels-member-levels/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ContactsPanelsDisplaySubPanelsMemberLevelsComponent = (_dec = Ember.inject.service('date'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class ContactsPanelsDisplaySubPanelsMemberLevelsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "contactRewardData", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isShowHistory", _descriptor4, this);
    }

    getContactRewardData(contactReward) {
      let used = [];
      const rewardsData = R.propOr([], 'contactRewards')(contactReward);
      const rewards = R.pipe(R.reject(R.propEq('method', 'use')), R.sortWith([R.ascend(R.prop('startDateZ')), R.ascend(R.prop('expireDateZ')), R.descend(R.prop('points'))]), R.map(reward => {
        const usedUsageRewards = R.propOr([], 'usedUsageRewards')(reward);
        const startDateZ = R.propOr([], 'startDateZ')(reward);
        const expiryDateZ = R.propOr([], 'expireDateZ')(reward);
        const todayZ = this.dateService.getStartOfDayZ();
        const type = R.prop('type')(reward);
        Ember.set(reward, 'isAffectMemberLevel', false);

        if (type === this.constants.rewardEvents.order || type === this.constants.rewardEvents.opening || type === this.constants.rewardEvents.adjustment) {
          Ember.set(reward, 'isAffectMemberLevel', true);
        }

        Ember.set(reward, 'isExpired', false);

        if (expiryDateZ < todayZ) {
          Ember.set(reward, 'isExpired', true);
        }

        Ember.set(reward, 'isPending', false);

        if (startDateZ > todayZ) {
          Ember.set(reward, 'isPending', true);
        }

        Ember.set(reward, 'labelType', `reward_${reward.type}`);
        let differences = [];

        if (RA.isNotNilOrEmpty(usedUsageRewards)) {
          differences = R.symmetricDifference(used, usedUsageRewards);
          used = usedUsageRewards;
        }

        let rewardUsage = [];

        if (RA.isNotNilOrEmpty(differences)) {
          differences = R.map(difference => {
            if (R.contains('-', difference)) {
              difference = R.replace(/-/g, '', difference);
            }

            return difference;
          })(differences);
          rewardUsage = R.pipe(R.filter(usedReward => {
            const key = R.prop('_key')(usedReward);
            const isUsage = R.propEq('method', 'use')(usedReward);
            const isLargerThanZero = R.gt(R.propOr(0, 'points')(usedReward), 0);
            const isInThisEarn = R.includes(key, differences);
            return isUsage && isLargerThanZero && isInThisEarn;
          }), R.sortWith([R.descend(R.prop('dateZ'))]))(rewardsData);
        }

        Ember.set(reward, 'usageData', rewardUsage);
        return reward;
      }), R.reverse)(rewardsData);
      const rewardName = R.pathOr('', ['rewardData', 'name'])(contactReward);
      return {
        rewards,
        rewardName
      };
    }

    onSubmitShowHistory(contactReward) {
      this.isShowHistory = true;
      this.contactRewardData = this.getContactRewardData(contactReward);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactRewardData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isShowHistory", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitShowHistory", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitShowHistory"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDisplaySubPanelsMemberLevelsComponent;
});