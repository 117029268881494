define("client/pods/grapesjs/plugins/tabs/components/index", ["exports", "client/pods/grapesjs/plugins/tabs/components/Tab", "client/pods/grapesjs/plugins/tabs/components/Tabs", "client/pods/grapesjs/plugins/tabs/components/TabContent", "client/pods/grapesjs/plugins/tabs/components/TabContents", "client/pods/grapesjs/plugins/tabs/components/TabContainer", "ramda"], function (_exports, _Tab, _Tabs, _TabContent, _TabContents, _TabContainer, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (editor, config = {}) => {
    const options = R.pathOr({}, ['Config', 'pluginsOpts', 'tabs'])(editor);
    const dc = editor.DomComponents;
    const opts = { ...options,
      ...config,
      defaultModel: dc.getType('default').model,
      editor
    };
    [_Tab.default, _Tabs.default, _TabContent.default, _TabContents.default, _TabContainer.default].map(c => c(dc, opts));
  };

  _exports.default = _default;
});