define("client/pods/components/app-logged-in/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // the only purpose the app-container serves is to keep track of isLoggedIn state
  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    crud: Ember.inject.service(),
    excel: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    notificationsService: Ember.inject.service('notifications'),
    updates: Ember.inject.service(),
    token: '',
    // token is only stored if its valid
    classNames: ['u-full-height'],
    isMaintenanceMode: false,
    isBotVisible: false,
    isNotificationsVisible: false,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['application/lists/main', 'languages']);
      this.setupTask.perform();
    },

    allowedMenu: Ember.computed('crud.lists.application-lists-main.[]', 'settings.settingsModel.synced', function () {
      const allowed = this.crud.lists['application-lists-main'] || []; // if (!this.settings.getProp('beta.workflows')) {
      //   allowed = R.reject(R.pathEq(['_data', 'resource'], '/api/protected/workflows'))(allowed)
      // }

      return allowed;
    }),
    setupTask: (0, _emberConcurrency.task)(function* () {
      this.fetchSettingsTask.perform();
      const notifications = yield this.notificationsService.fetchNotificationsTask.perform();
      this.set('notifications', notifications);
    }),
    fetchSettingsTask: (0, _emberConcurrency.task)(function* () {
      yield this.settings.setupTask.perform();
    }),
    total: 0,
    successful: 0,
    tries: 0,
    // maintenanceTask: task(function * () {
    //   if (this.isMaintenanceMode) {
    //     // count = count || 1
    //     // console.log('===count: ' + count)
    //     yield this.server.call('GET', 'api/protected/documents/maintenance').then(results => {
    //       if (!this.isDestroyed) {
    //         this.set('total', this.total + results.total)
    //         this.set('tries', this.tries + results.tries)
    //         this.set('successful', this.successful + results.successful)
    //         if (results.hasMore === false) {
    //           // console.log('DONE!!')
    //         } else {
    //           this.maintenanceTask.perform()
    //         }
    //       }
    //     })
    //   }
    // }),
    // payTask: task(function * () {
    //   yield this.server.call('POST', 'api/protected/admin/maintenance', {})
    // }),
    hasMultiplePods: Ember.computed('podChanged', function () {
      const pods = this.auth.getUserProp('pods') || [];

      if (pods.length > 1) {
        return true;
      }

      return false;
    }),
    loggedInAsLabel: Ember.computed('podChanged', function () {
      return `${this.auth.getUserProp('username')} (${this.auth.getUserProp('company')})`;
    }),
    actions: {
      openTab(tabOptions) {
        // hide menu
        this.toggleProperty('mainMenuActive');
        this.tabsManager.openTab('left', tabOptions);
      },

      openChatTab() {
        this.toggleProperty('isMessagesVisible');
        const tabOptions = {
          resource: '/api/protected/messages',
          component: 'messages/tab-container',
          label: 'chat messages',
          icon: 'fas fa-comments',
          tabPosition: 'sidebarRight',
          tabType: 'singleton'
        };
        this.tabsManager.openTab('sidebarRight', tabOptions);
      },

      logout() {
        const reload = true;
        this.auth.logout(reload);
      },

      markAllAsRead() {
        this.notifications.markAllAsRead();
      },

      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
        window.location.reload();
      },

      onChangePod(onCloseDialogAction, loginData) {
        this.set('podChanged', Math.random()); // onCloseDialogAction()
      }

    } // exportTask: task(function * (onCloseDialogAction /* filter */) {
    //   let data = invData
    //   data = R.pipe(
    //     R.reject(R.propEq('void', true)),
    //     R.map(d => {
    //       d.dispatchedDate = this.dateService.formatDate(d.dispatchDateZ, 'YYYY-MM-DD')
    //       d.timestamp = this.dateService.formatDate(d.timestampZ, 'YYYY-MM-DD')
    //       d.inclTotal = parseInt(d.inclTotal)
    //       d.item = R.join(' | ')(d.item)
    //       return R.omit([
    //         'docNo',
    //         'cancelledInvoiceNumber',
    //         'dispatchDateZ',
    //         'timestampZ',
    //         'void',
    //         'status',
    //         'invoiceTotal'
    //       ])(d)
    //     }),
    //     R.reject(R.equals(false))
    //   )(data)
    //   const columns = R.keys(data[0])
    //   data = R.map(R.values)(data)
    //   data.unshift(columns)
    //   const worksheets = [{
    //     sheetName: 'data',
    //     data
    //   }]
    //   yield this.excel.exportFile({
    //     worksheets,
    //     title: '11DataexportFile'
    //   })
    // }).drop()

  });

  _exports.default = _default;
});