define("client/pods/components/channels/shop/campaigns/panels-display/component", ["exports", "client/mixins/crud", "client/config/environment", "client/utils/nventor", "ember-concurrency", "ramda-extension"], function (_exports, _crud, _environment, _nventor, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    application: Ember.inject.service(),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',
    copyErrors: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['users', 'statuses', 'toggles/auto', 'toggles/onOff', 'toggles/onOff', 'paymentMethods/shop', 'channels/shop/campaigns/statuses', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/subPanels', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'extensions/apps/tw/invoicingAuto', 'products/lists/tags', 'shipping/feeType', 'commissions/methods', 'dispatch/lists/processes']);
      const tab = this.tab;
      const model = tab.get('model');
      this.set('model', model);
      this.set('copyErrors', []);
      this.set('config', _environment.default);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },

    afterLoadLists() {
      this._super(...arguments);

      const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || [];
      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || subPanels.get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');

      if (Ember.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    duplicateRecordTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      model
    } = {}) {
      adapterName = adapterName || this.adapterName;
      model = model || this.dirty;
      return yield this.createRecordTask.perform({
        adapterName,
        model,
        errorsProp: 'copyErrors'
      }); // try {
      //   let saved = yield this.get('dataManager').createRecord(adapterName, model)
      //   return this.get('onDisplay')(saved)
      // } catch (err) {
      //   this.set('copyErrors', err.data)
      //   nventor.throw('duplicateRecordTask failed', err)
      // }
    }).drop(),
    // displaySubPanel (resourceModel) {
    //   const subTabOptions = {
    //     component: resourceModel.getData('component'),
    //     value: resourceModel.getData('value')
    //   }
    //   const tab = this.tab
    //   this.tabsManager.reuseSubTab(tab, subTabOptions)
    // },
    actions: {
      editPanel() {
        const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
        const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || [];
        const subTabOptions = subPanels.findBy('_data.component', activeSubTab.get('component'));
        const tabOptions = {
          loadInstructions: {
            component: subTabOptions.get('_data.editingComponent'),
            value: subTabOptions.get('_data.value')
          }
        };
        this.onEdit(this.model, tabOptions);
      },

      prepareDuplicateRecord() {
        const model = this.model;
        const adapterName = this.adapterName;
        const adapter = this.dataManager.getAdapter(adapterName);
        const data = adapter.serialize(model);
        const newModel = this.setupNewRecord({
          adapterName,
          data
        });
        let name = model.getData('name');
        let shopUrl = model.getData('shopUrl');
        name = `${name}-copy-` + _nventor.default.random.digits(3);
        shopUrl = `${shopUrl}-copy-` + _nventor.default.random.digits(3);
        newModel.setData('status', this.get('constants.status.inactive'));
        newModel.setData('name', name);
        newModel.setData('password', '');
        newModel.setData('shopUrl', shopUrl);
        newModel.setData('_key', '');
        newModel.setData('_id', '');
        newModel.setData('_rev', '');
        newModel.setData('modified', '');
        newModel.setData('created', '');
        newModel.setData('copyFromKey', model.getData('_key'));
        this.set('dirty', newModel);
      },

      cancelDuplicateRecord(dirty) {
        const adapterName = this.adapterName;
        this.dataManager.cancelDirty(adapterName, dirty); // this.cancelEditing({
        //   adapterName,
        //   dirty
        // })
      },

      assignSalesPerson(person) {
        const dirty = this.dirty;
        dirty.addSalesPerson(person.serialize()); // set commission default to

        dirty.setData('commissionMethod', this.get('constants.commissionMethod.percentagePrice'));
      },

      unassignSalesPerson(person) {
        const dirty = this.dirty;
        dirty.removeSalesPerson(person);
      },

      toggleCommissionMethod() {
        const dirty = this.dirty;
        dirty.setData('commissionFlat', '');
        dirty.setData('commissionPercentage', '');
      }

    }
  });

  _exports.default = _default;
});