define("client/pods/components/settings/settings-editor/component", ["exports", "ramda", "client/config/environment", "client/utils/nventor", "client/mixins/transporter", "ramda-extension"], function (_exports, R, _environment, _nventor, _transporter, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transporter.default, {
    crud: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    hctModel: false,

    init() {
      this.crud.addLists(this, ['settings/editorSubPanels', 'taxMethods', 'taxMethods/filters', 'taxes', 'paymentMethods', 'paymentMethods/shop', 'paymentTerms', 'shipping/feeType', 'products/lists/tags', 'products/lists/types', 'products/lists/tracking', 'channels/shop/campaigns/statuses', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'settings/rounding', 'discounts/types', 'toggles/onOff', 'units/dimensions', 'units/weight']);

      if (this.settings.getProp('features.extensions.tw_invoices') || this.settings.getProp('features.extensions.tw_eInvoices')) {
        this.crud.addLists(this, ['extensions/apps/tw/invoicing']);
      }

      if (this.settings.getProp('features.sales.documents')) {
        this.crud.addLists(this, ['documents/sales/terms']);
      }

      if (this.settings.getProp('features.purchases.documents')) {
        this.crud.addLists(this, ['documents/purchases/terms']);
      }

      this._super(...arguments);

      this.set('config', _environment.default);
      this.set('editingPanels', []);
    },

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      const subPanels = R_.dotPath('crud.lists.settings-editorSubPanels')(this) || [];

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }

      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    isDisabled: Ember.computed('isEditing', function () {
      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    actions: {
      editPanel() {
        const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
        const tabOptions = {
          loadInstructions: {
            component: activeSubTab.get('component')
          }
        };
        this.onEdit(this.model, tabOptions);
      }

    }
  });

  _exports.default = _default;
});