define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/invoice-management/component", ["exports", "client/mixins/date", "ember-concurrency", "ramda-adjunct"], function (_exports, _date, _emberConcurrency, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    printer: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      const customData = Ember.Object.create({
        email: this.get('invoice._data.email'),
        telephone: this.get('invoice._data.telephone')
      });
      this.set('custom', customData);
    },

    allowCancel: Ember.computed('invoice._data.{cancellableDateZ,status}', 'modalDialogIsVisible', function () {
      const cancellableDateZ = this.get('invoice._data.cancellableDateZ');
      const status = this.get('invoice._data.status');

      if (status !== 'successful') {
        return false;
      } // @NOTE: this is for backwards compatablility
      // can be removed after sep 2021


      const currentPeriod = this.dateService.getPeriod(cancellableDateZ);
      const period = this.dateService.getPeriod(cancellableDateZ);
      let cancellableDate = this.dateService.getMoment(cancellableDateZ);
      const now = this.getMoment();

      if (RA.isOdd(period.mm) && currentPeriod.mm === period.mm) {
        cancellableDate = cancellableDate.date(15);
      }

      if (now.isBefore(cancellableDate)) {
        return true;
      }

      return false;
    }),
    queryStatusTask: (0, _emberConcurrency.task)(function* () {
      var _this$invoice, _this$invoice$_data;

      const key = (_this$invoice = this.invoice) === null || _this$invoice === void 0 ? void 0 : (_this$invoice$_data = _this$invoice._data) === null || _this$invoice$_data === void 0 ? void 0 : _this$invoice$_data._key;
      yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'extensions/apps/tw/e-invoices',
        data: {
          _key: key
        }
      });
    })
  });

  _exports.default = _default;
});