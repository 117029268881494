define("client/pods/documents/mixins/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../utils/nventor'
  var _default = Ember.Mixin.create({
    // setup new document model with defaults here.
    // use defaults from settings
    newBlank({
      docType,
      docName,
      paymentMethodsList,
      paymentTermsList
    }) {
      const todayZ = this.getStartOfDayZ();
      const settingsModel = this.settings.getModel();
      this.setData('docType', docType);
      this.setData('docName', docName);
      this.setData('taxMethodKey', settingsModel.getData(`${docType}TaxMethodKey`, this.get('constants.taxMethods.exclusive')));
      let paymentMethodKey;

      if (docName === 'consignments' || docName === 'consignments-returns') {
        paymentMethodKey = this.get('constants.paymentMethods.consignment');
      } else {
        paymentMethodKey = settingsModel.getData(`${docType}PaymentMethodKey`, this.get('constants.paymentMethods.cash'));
      }

      const paymentMethod = paymentMethodsList.findBy('_data.value', paymentMethodKey);
      this.loadPaymentMethod({
        paymentMethod,
        paymentMethodKey
      });
      const paymentTermsKey = settingsModel.getData(`${docType}PaymentTermsKey`);
      const paymentTerms = paymentTermsList.findBy('_data._key', paymentTermsKey);
      this.loadPaymentTerms({
        paymentTerms,
        paymentTermsKey
      });
      this.setData('discountType', settingsModel.getData('discountType', this.get('constants.discountTypes.rate')));
      this.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      this.setData('dateZ', todayZ);
      this.setData('roundingCurrency', settingsModel.getData('roundingCurrency'));
      this.setData('unroundedCurrency', settingsModel.getData('unroundedCurrency'));
      this.setData('roundingInventoryQty', settingsModel.getData('roundingInventoryQty'));
      this.setData('roundingInventoryPrice', settingsModel.getData('roundingInventoryPrice'));

      if (docName === 'quotes') {
        // @TODO: validity is currently set at 1 month from date. should get from settings
        const validityDate = this.getMoment(todayZ).add(1, 'months');
        this.setData('validityDateZ', validityDate.toISOString());
      }

      if (docName === 'orders') {
        this.setData('deliveryDateOption', this.get('constants.deliveryDateOptions.single'));
        this.setData('deliveryDateZ', todayZ);
      }

      if (this.hasPaymentReminders) {
        this.setData('paymentRemindersAutoEmail', settingsModel.getData('paymentRemindersAutoEmail'));
      } // if (docType === 'sales' && docName === 'invoices') {
      //   this.setData('autoPrintInvoices', settingsModel.getData('autoPrintInvoices'))
      // }

    }

  });

  _exports.default = _default;
});