define("client/pods/campaigns-products/service", ["exports", "ramda", "ramda-extension", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    server: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    constants: Ember.inject.service(),

    init() {
      this._super('...arguments');

      this.set('statusClasses', {
        [this.get('constants.productsStatus.active')]: 'is-primary',
        [this.get('constants.productsStatus.inactive')]: 'is-danger',
        [this.get('constants.productsStatus.preOrder')]: 'is-warning',
        [this.get('constants.productsStatus.outOfStock')]: 'is-black',
        [this.get('constants.productsStatus.soldOut')]: 'is-black'
      });
    },

    getStatusClass(status) {
      const statusClasses = this.statusClasses;
      return R.propOr(this.unknownStatusClass, status, statusClasses);
    },

    hasDuplicate(item, product) {
      // const campaignDetails = this.get('campaignDetails')
      // const hasEqualItemKey = R.pathEq(['_data', 'itemKey'], product.getData('_key'))
      // const found = R.find(hasEqualItemKey)(campaignDetails)
      // if (found) {
      //   const msg = this.get('intl').t('duplicate product')
      //   window.alert(msg)
      //   item.resetDetail(item)
      //   return true
      // }
      return false;
    },

    loadProductTask: (0, _emberConcurrency.task)(function* (campaign, item, product) {
      if (!this.hasDuplicate(item, product)) {
        yield this._loadProductTask.perform(...arguments);
      }
    }),
    _loadProductTask: (0, _emberConcurrency.task)(function* (campaign, item, product) {
      this.set('product', product);
      const itemKey = product.getData('_key');
      const isSet = R_.dotPath('_data.isSet', item) || false;
      const isSetWithChoices = R_.dotPath('_data.isSetWithChoices', item) || false;
      const {
        preferredHistoryData
      } = yield this.fetchHistoryCampaignsTask.perform(product, itemKey, isSet, isSetWithChoices);
      yield item.loadProduct(campaign, item, product, preferredHistoryData);

      if (R_.dotPathEq('_data.isSet', true, item)) {
        item.recalculatePriceForSet(item, item);
        item.setData('isSetNew', true);
      }

      if (R_.dotPathEq('_data.isSetWithChoices', true, item)) {
        R.forEach(choice => {
          const isSetPriceOnBundle = R.pathOr('', ['_data', 'isSetPriceOnBundle'], choice);

          if (!isSetPriceOnBundle) {
            choice.setData('isSetPriceOnBundle', false);
          }
        })(_nventor.default.ensurePathOr([], ['_data', 'choices'], item));
        item.recalculatePrice(item);
      }
    }),
    fetchDocHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      const docType = docModel.getData('docType');
      const docName = docModel.getData('docName');
      const contactKey = docModel.getData('contactKey');
      const contact = docModel.getData('contact');
      const priceHistoryData = yield this.getHistoryTask.perform({
        docType,
        docName,
        contact,
        contactKey,
        itemKey
      });
      let preferredHistoryData;
      preferredHistoryData = this.getLatestFor(preferredKey, priceHistoryData);

      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),
    fetchPurchasesHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      docModel.setData('docType', 'purchases');
      docModel.setData('docName', 'invoices');
      return yield this.fetchDocHistoryTask.perform(docModel, preferredKey, product, itemKey);
    }),
    getHistoryTask: (0, _emberConcurrency.task)(function* ({
      docType,
      docName,
      contact,
      contactKey,
      item,
      itemKey,
      docTypes
    }) {
      const data = {
        contact,
        contactKey,
        item,
        itemKey,
        docTypes
      };
      const results = yield this.server.call('GET', `api/protected/documents/data/prices/${docType}/${docName}`, data);
      return R.propOr({}, 'data', results);
    }),
    fetchHistoryCampaignsTask: (0, _emberConcurrency.task)(function* (product, itemKey, isSet, isSetWithChoices) {
      const results = yield this.server.call('GET', 'api/protected/documents/data/prices/campaigns', {
        itemKey,
        isSet,
        isSetWithChoices
      });
      const priceHistoryData = R.propOr({}, 'data', results);
      let preferredHistoryData = this.getLatestFor('campaigns', priceHistoryData);

      if (!preferredHistoryData && product) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),
    fetchHistoryCampaignsAutomationsTask: (0, _emberConcurrency.task)(function* (product, {
      itemKey
    }) {
      const results = yield this.server.call('GET', 'api/protected/documents/data/prices/campaigns/automations', {
        itemKey
      });
      const priceHistoryData = R.propOr({}, 'data', results);
      let preferredHistoryData = this.getLatestFor('automations', priceHistoryData);

      if (!preferredHistoryData && product) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),

    getFor(prop, historyData) {
      return R.pathOr([], ['history', prop], historyData);
    },

    getLatestFor(prop, historyData) {
      // return R.pathOr([], ['history', prop], historyData)
      const forProp = this.getFor(prop, historyData);
      let found = false;

      if (!R.isEmpty(forProp)) {
        found = R.head(forProp);
      }

      return found;
    },

    getLatestDetail(props, historyData) {
      let found = '';
      R.forEach(prop => {
        if (!found) {
          const forProp = this.getFor(prop, historyData);

          if (!R.isEmpty(forProp)) {
            found = R.head(forProp);
          }
        }
      }, props);
      return found || false;
    },

    setPrice(model, historyData, onHideDropdown) {
      model.setPrice(historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setDetailPrice(campaign, detail, child, historyData, onHideDropdown) {
      detail.setDetailPrice(campaign, child, historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setProductCommissionsBaseCost(campaign, parent, detail, child, historyData, onHideDropdown) {
      parent.setProductCommissionsBaseCost(campaign, child, historyData);

      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        parent.recalculatePriceForSet(detail, parent);
      }

      if (R.pathEq(['_data', 'isSetWithChoices'], true, detail)) {
        parent.recalculatePrice(detail);
      }

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    selectChildHistoryPrice(campaign, parent, detail, child, historyData, onHideDropdown) {
      parent.setChildProductPrice(campaign, child, historyData);

      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        parent.recalculatePriceForSet(detail, parent);
      }

      if (R.pathEq(['_data', 'isSetWithChoices'], true, detail)) {
        parent.recalculatePrice(detail);
      }

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    getTaxRate(docType, taxes, product) {
      // @TODO: should get taxRateKey from product to fetch tax rate from lists.tax
      // also fetch tax rate label from lists.tax
      // if no taxRate from product then get from settings
      // PRO: if tax rate is removed, then assume taxrate no longer available
      //      but if using taxrate and label stored in products, it will use old taxrate/label instead of settings
      // CON: requires additional step to search lists.tax
      const taxRateKey = product.getData(`${docType}TaxRateKey`);
      let taxRate;

      if (taxRateKey) {
        const productTax = taxes.findBy('_data._key', taxRateKey);

        if (productTax) {
          taxRate = productTax.getData('value');
        }
      }

      if (!taxRateKey || taxRateKey === 'auto') {
        const defaultTaxRate = this.settings.getDefaultTaxRate(taxes);
        taxRate = defaultTaxRate;
      }

      if (taxRateKey === 'none') {
        taxRate = 0;
      }

      return taxRate;
    }

  });

  _exports.default = _default;
});