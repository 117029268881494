define("client/pods/components/modals/modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lUu9hmIG",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@modalDialogContainerClassName\",\"@title\",\"@modalDialogContentClassName\",\"@isRunning\",\"@modalDialogIsVisible\"],\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"modal-dialog\"],[13,2],[3,\"did-insert\",[[23,0,[\"didInsert\"]]]],[3,\"will-destroy\",[[23,0,[\"willDestroyElement\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog__container \",[23,3,[]]]]],[8],[0,\"\\n      \"],[1,[28,\"modals/modal-dialog/modal-header\",null,[[\"title\",\"translate\",\"onClose\"],[[23,4,[]],[23,0,[\"translate\"]],[28,\"fn\",[[23,0,[\"close\"]]],null]]]],false],[0,\"\\n      \\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog__content \",[23,5,[]]]]],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[23,6,[]]]],{\"statements\":[[0,\"          \"],[14,1,[[28,\"fn\",[[23,0,[\"close\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"modals/modal-overlay\",null,[[\"closeOnOverlayClick\",\"onClose\",\"toggle\"],[[23,0,[\"closeOnOverlayClick\"]],[28,\"fn\",[[23,0,[\"close\"]]],null],[23,7,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});