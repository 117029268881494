define("client/pods/components/websites/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e7jeZwQk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor\",null,[[\"tab\",\"model\",\"errors\",\"isEditing\",\"isNew\",\"saveTask\",\"onAfter\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"errors\"]],true,true,[24,[\"createRecordTask\"]],[28,\"action\",[[23,0,[]],\"onAfter\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});