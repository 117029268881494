define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-faqs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uavtakcN",
    "block": "{\"symbols\":[\"@tab\",\"@lists\",\"@model\",\"@isReadonly\",\"@isDisabled\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"forms/form-section\",null,[[\"title\",\"errors\",\"context\",\"documentationResource\"],[\"defaults faqs\",[24,[\"errors\"]],\"faqsSettings\",\"campaigns\"]],{\"statements\":[[0,\"      \"],[5,\"faqs\",[],[[\"@adapterName\",\"@tab\",\"@lists\",\"@model\",\"@isReadonly\",\"@isDisabled\"],[\"settings\",[23,1,[]],[23,2,[]],[23,0,[\"settings\",\"settingsModel\"]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"forms/form-section\",null,[[\"title\",\"errors\",\"context\",\"documentationResource\"],[\"faqs for shop\",[24,[\"errors\"]],\"faqsCampaigns\",\"campaigns\"]],{\"statements\":[[0,\"      \"],[5,\"faqs\",[],[[\"@adapterName\",\"@tab\",\"@lists\",\"@model\",\"@isReadonly\",\"@isDisabled\"],[\"channels/shop/campaigns\",[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-faqs/template.hbs"
    }
  });

  _exports.default = _default;
});