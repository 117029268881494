define("client/pods/components/helpers/multiline-truncate/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let HelpersMultilineTruncate = (_dec = Ember._tracked, (_class = (_temp = class HelpersMultilineTruncate extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "shortenedContent", _descriptor, this);
      const content = this.args.content || '';
      const char = this.args.char || 100;
      const byLines = this.args.byLines;
      this.shortenedContent = content;

      if (content.length > char && !byLines) {
        this.shortenedContent = R.take(char, content) + '...';
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "shortenedContent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HelpersMultilineTruncate;
});