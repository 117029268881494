define("client/pods/documents/details/model", ["exports", "client/pods/base/model", "client/pods/documents/details/calculations-exclusive-or-inclusive"], function (_exports, _model, _calculationsExclusiveOrInclusive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import TaxCalculationsMethodMixin from './calculations-always-inclusive'
  var _default = _model.default.extend(_calculationsExclusiveOrInclusive.default, {
    populate(data = {}) {
      const self = this;
      self.set('pending', data.pending);
      delete data.pending;
      self.set('consignments', data.consignments);
      delete data.consignments;
      const docModel = this.transformDoc(data);
      self.set('doc', docModel);

      if (data.detail) {
        // this is for populating results from listing by details
        const detailData = data.detail;
        self.overwriteData(detailData);
      } else {
        self.overwriteData(data);
      }
    },

    transformDoc(rawData) {
      if (rawData.doc) {
        var _rawData$doc;

        if (rawData !== null && rawData !== void 0 && (_rawData$doc = rawData.doc) !== null && _rawData$doc !== void 0 && _rawData$doc.details) {
          delete rawData.doc.details;
        }

        const docData = rawData.doc;
        delete rawData.doc;
        return this.dataManager.setPartialRecord({
          adapterName: `documents/${docData.docType}/${docData.docName}`,
          data: docData
        });
      }

      return false;
    },

    getRounding() {
      return {
        roundingCurrency: this.getData('roundingCurrency', this.get('settings.roundingCurrency')),
        unroundedCurrency: this.getData('unroundedCurrency', this.get('settings.unroundedCurrency')),
        roundingInventoryPrice: this.getData('roundingInventoryPrice', this.get('settings.roundingInventoryPrice'))
      };
    },

    isDiscountRate: Ember.computed('_data.discountType', function () {
      const discType = this.getData('discountType');

      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }

      return false;
    }),

    /**
     * @deprecated
     * deduct discount from lineAmt (discount rate set on each item:)
     * Discount is rounded per line item
     */
    _deductDiscount(lineAmtBig) {
      // const { roundingCurrency } = this.getRounding()
      // let discRateBig = this.getDataBig('discountRate')
      // let discAmountBig = this.getDataBig('discountAmount')
      // if (this.isDiscountRate && discRateBig.gt(0)) {
      //   // discount is %, divide by 100
      //   discRateBig = discRateBig.div(100)
      //   discAmountBig = lineAmtBig.times(discRateBig)
      // }
      // if (discAmountBig.gt(0)) {
      //   const discAmtRounded = discAmountBig.toFixed(roundingCurrency)
      //   // use rounded discount
      //   lineAmtBig = lineAmtBig.minus(discAmtRounded)
      //   // store discounted unit price for display and reporting purposes only
      //   const qtyBig = this.getDataBig('qty')
      //   const discPriceRounded = lineAmtBig.div(qtyBig).toFixed(roundingCurrency)
      //   this.setData('discPrice', discPriceRounded)
      //   this.setData('discAmt', discAmtRounded)
      // } else {
      //   this.setData('discPrice', '')
      //   this.setData('discAmt', '')
      // }
      return lineAmtBig;
    },

    _calculateLineCommission() {
      const {
        roundingCurrency
      } = this.getRounding(); // const commissionBaseCost = this.getDataBig('commissionBaseCost')

      const commissionBaseCost = 0;
      let commission = 0;

      if (commissionBaseCost > 0 && !Ember.isEmpty(this.get('model._data.salesPersons'))) {
        const exclAmt = this.getDataBig('exclAmt');
        const qty = this.getDataBig('qty');
        const baseAmt = qty.times(commissionBaseCost);
        commission = exclAmt.minus(baseAmt).toFixed(roundingCurrency);
      }

      this.setData('commissionAmt', commission);
    },

    // @TODO: #473 refactor to handle decimals
    remainingQty: Ember.computed('_data.{qty,originalQty,allReturnedQty}', function () {
      const originalQty = parseInt(this._data.originalQty) || 0;
      const qty = parseInt(this._data.qty) || 0;
      const allReturnedQty = parseInt(this._data.allReturnedQty) || 0;
      return originalQty - qty - allReturnedQty;
    }),
    originalRemainingQty: Ember.computed('_data.{originalQty,allReturnedQty}', function () {
      const originalQty = parseInt(this._data.originalQty) || 0;
      const allReturnedQty = parseInt(this._data.allReturnedQty) || 0;
      return originalQty - allReturnedQty;
    }),
    hasDiscount: Ember.computed('_data.allocatedInclDiscountUnrounded', function () {
      const allocatedDisc = parseInt(R.path(['_data', 'allocatedInclDiscountUnrounded'])(this)) || 0;

      if (allocatedDisc > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});