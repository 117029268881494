define("client/pods/server/service", ["exports", "client/config/environment", "client/utils/nventor"], function (_exports, _environment, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * There are 4 ways to connect to the server from code
   * 1. code -> crudMixin (find/search/save/replace/remove...) -> dataManagerService -> (cache) -> adapter (adapterMixin) -> ajaxService
   *    for normal models:
   *      - caches responses
   *      - has realtime updates
   *      - adds config.host to path
   *
   * 2. code -> crudMixin (getAdapter) -> server (getAdapter) -> adapter (adapterMixin) -> ajaxService
   *    for connections that don't necessarily fit into rest way of doing things.
   *      - does not cache responses
   *      - no realtime updates
   *      - adds config.host to path
   *
   * 3. code -> server (call) -> ajaxService
   *    for simply calling server
   *      - no adapter for this connection
   *      - no caches
   *      - no realtime updates
   *      - adds config.host to path
   *
   * 4. code -> ajaxService
   *    raw call to server
   *      - must specify host in path
   *      - can connect to other servers
   *
   */
  var _default = Ember.Service.extend({
    host: _environment.default.APP.serverUrl,
    hostingHost: _environment.default.APP.hostingUrl,
    ajax: Ember.inject.service(),

    login(credentials) {
      // var url = 'app/login'
      // always stringify data when sending to Server
      // const data = JSON.stringify(credentials)
      const url = this.host + '/app/login';
      return this.ajax.POST(url, credentials);
    },

    loginToCompany(podKey, token) {
      // var url = 'app/login'
      // always stringify data when sending to Server
      // const data = JSON.stringify(credentials)
      const url = `${this.host}/api/protected/app/login/company`;
      return this.ajax.POST(url, {
        podKey
      }, token);
    },

    call(method, path, data, token) {
      const url = this.host + `/${path}`;
      return this.ajax[method](url, data, token);
    },

    //
    callOuter(method, url, data, token, hasToken = false) {
      return this.ajax[method](url, data, token, hasToken);
    },

    // getFromHosting(method, paths, data, token) {
    //   let url = this.get('hostingHost')
    //   let hasToken = false
    //   if (CONFIG.environment === 'development') {
    //     hasToken = true
    //   }
    //   const promises = R.map(path => {
    //     if (path) {
    //       url += `/${path}`
    //     }
    //     this.get('ajax')._ajax('GET', url, { data, token, hasToken })
    //   })(paths)
    //   return all(promises)
    // },
    postFormData(path, data, token) {
      const url = this.host + `/${path}`;
      const config = {
        contentType: false,
        processData: false
      };
      return this.ajax._ajax('POST', url, {
        data,
        config,
        token
      });
    },

    getAdapter(adapterName) {
      const adapter = Ember.getOwner(this).lookup(`adapter:${adapterName}`);

      if (!adapter) {
        _nventor.default.throw(`SERVER: getAdapterError. Invalid adpaterName: ${adapterName}.`);
      }

      return adapter;
    }

  });

  _exports.default = _default;
});