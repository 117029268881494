define("client/mixins/contacts-is-parent", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = Ember.Mixin.create({
    departmentsList: Ember.computed('_data.departments.[]', function () {
      return this.getData('departments') || [];
    }),
    allPersonsAndDepartmentsList: Ember.computed('_data.persons.[]', 'departmentsList._data.persons.[]', function () {
      const persons = this.getData('persons') || [];
      const departments = this.departmentsList;
      const personsInDepartments = R.pipe(R.map(department => {
        const persons = department.getData('persons') || [];
        return R.map(person => {
          person.context = department.getData('name');
          person.parent = department;
          return person;
        })(persons);
      }), R.flatten)(departments);
      return R.pipe(R.concat(personsInDepartments), R.concat(persons))(departments);
    }),
    allDetailsList: Ember.computed('_data.details.[]', 'allPersonsAndDepartmentsList._data.details.[]', function () {
      const details = this.getData('details') || [];
      const otherDetails = R.pipe(R.map(parent => {
        const details = parent.getData('details');
        return R.map(detail => {
          detail.context = parent.getData('name');
          detail.parent = parent;
          return detail;
        }, details);
      }), R.flatten)(this.allPersonsAndDepartmentsList);
      return R.concat(details, otherDetails);
    }),
    detailsList: Ember.computed('_data.details.[]', function () {
      return this.getData('details') || [];
    }),
    allPhonesList: Ember.computed('allDetailsList.[]', function () {
      const allDetailsList = this.allDetailsList || [];
      return this.getDetailsTypeList(allDetailsList, 'phone');
    }),
    allEmailsList: Ember.computed('allDetailsList.[]', function () {
      const allDetailsList = this.allDetailsList || [];
      return this.getDetailsTypeList(allDetailsList, 'email');
    }),
    allAddressesList: Ember.computed('allDetailsList.[]', function () {
      const allDetailsList = this.allDetailsList || [];
      return this.getDetailsTypeList(allDetailsList, 'address');
    }),
    phonesList: Ember.computed('detailsList.[]', function () {
      const detailsList = this.detailsList || [];
      return this.getDetailsTypeList(detailsList, 'phone');
    }),
    emailsList: Ember.computed('detailsList.[]', function () {
      const detailsList = this.detailsList || [];
      return this.getDetailsTypeList(detailsList, 'email');
    }),
    addressesList: Ember.computed('detailsList.[]', function () {
      const detailsList = this.detailsList || [];
      return this.getDetailsTypeList(detailsList, 'address');
    }),

    getDetailsTypeList(detailsList, type, subType) {
      return R.filter(detail => {
        if (detail.getData('type') === type) {
          if (subType != null) {
            if (detail.getData('subType') === subType) {
              return true;
            }
          } else {
            return true;
          }
        }

        return false;
      }, detailsList);
    },

    getDefaultPersonOrDepartment(docType, docName) {
      const personsList = this.allPersonsAndDepartmentsList || []; // load default person

      const docCode = `${docType}-${docName}`;
      const defaults = R.filter(p => {
        const isDefault = p.getData('isDefault');
        const isDefaultFor = p.getData('isDefaultFor');

        if (isDefault && isDefaultFor === 'all') {
          return true;
        }

        const isDefaultForDocs = p.getData('isDefaultForDocs');

        if (!R.isEmpty(isDefaultForDocs) && !R.isNil(isDefaultForDocs)) {
          if (isDefaultForDocs.indexOf(docCode) > -1) {
            return true;
          }
        }

        return false;
      }, personsList);
      let personOrDepartment = defaults.get('firstObject');
      return personOrDepartment;
    },

    getDefaultDetail({
      contact,
      type,
      subType
    }) {
      const detailsList = this.detailsList || [];
      const detailsTypeList = this.getDetailsTypeList(detailsList, type, subType);
      const defaults = R.filter(detail => detail.getData('isDefault'), detailsTypeList);
      let defaultDetail = defaults.get('firstObject');

      if (defaultDetail) {
        return defaultDetail;
      }

      const parent = this.parent;

      if (parent) {
        defaultDetail = parent.getDefaultDetail({
          type,
          subType
        });

        if (defaultDetail) {
          return defaultDetail;
        }
      }

      if (contact) {
        return contact.getDefaultDetail({
          type,
          subType
        });
      }

      return defaultDetail;
    }

  });

  _exports.default = _default;
});