define("client/pods/components/products/products-editor/variants-editor/child-editor/component", ["exports", "ember-concurrency", "client/mixins/crud"], function (_exports, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: '',
    linkChildToExistingProduct: false,
    isReadonlyOrDisabled: Ember.computed('isReadonly', 'isSetChild', function () {
      if (this.isReadonly) {
        return true;
      }

      if (this.isSetChild) {
        return true;
      }

      return false;
    }),
    showSearchProductToggler: Ember.computed('linkChildToExistingProduct', 'childProduct._data._key', function () {
      const hasKey = this.get('childProduct._data._key');

      if (hasKey) {
        return false;
      }

      return true;
    }),
    searchNonSetProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products'
    }, query) {
      const model = this.model;

      if (query.trim() !== '') {
        const filters = this.search.setupFilters({
          adapterName: 'products/filters',
          defaultFiltersData: {
            query,
            excludes: [{
              key: 'setType',
              value: 'isSetWithChoices'
            }, {
              key: 'setType',
              value: 'hasVariants'
            }, {
              key: 'setType',
              value: 'isSet'
            }, {
              key: 'isVariantChild',
              value: true
            }, {
              key: '_key',
              value: model.getData('_key')
            }]
          }
        });
        const results = yield this.searchRecordsTask.perform({
          adapterName,
          filters
        });
        this.set('productsList', results);
        return results;
      }
    }),
    actions: {
      deselectChildItem() {
        this.set('childProduct._data._key', '');
        this.set('linkChildToExistingProduct', false);
      },

      loadChildItem(product) {
        this.set('childProduct._data._key', product.getData('_key'));
      },

      startSearch() {
        this.set('childProduct._data.name', '');
      }

    }
  });

  _exports.default = _default;
});