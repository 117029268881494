define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ChannelsShopCampaignsPanelsDisplaySubPanelsProductsLimitsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsPanelsDisplaySubPanelsProductsLimitsComponent extends _component.default {
    // @tracked isSettingUpBulkDirty = false
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "batch", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isBulkEditing", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor5, this);
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/limits/filters',
        defaultFiltersData: {
          master
        }
      });
      this.filters = filters;
      this.crud.addLists(this, ['filters/count', 'channels/shop/campaigns/lists/limitTypes', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'products/lists/menu', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypesFilters', 'statuses', 'toggles/yesNo']);
    }

    _getBulkEditingDirty(batch) {
      return R.map(detail => {
        return this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/limits',
          model: detail,
          attrs: {
            product: detail.product
          }
        });
      })(batch);
    }

    *fetchCampaignDetailsLimitsTask() {
      const model = this.args.model;
      const master = model.getData('_key');
      let batch = [];

      if (master) {
        model.set('detailsLimitsUpdated', false);
        batch = yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details/limits',
          appendPath: `/master/${master}`,
          filters: this.filters,
          component: this
        });
        model.set('detailsLimits', batch);
      } else {
        model.set('detailsLimits', batch);
      }

      this.batch = batch;
    }

    *customPaginateSearchRecordsTask() {
      let batch = yield this.crud.paginateSearchRecordsTask.perform(...arguments);

      if (this.isBulkEditing) {
        batch = this._getBulkEditingDirty(batch);
      }

      this.batch = batch;
      const model = this.args.model;
      model.set('detailsLimits', batch);
    }

    bulkEdit() {
      this.isBulkEditing = true;
      const batch = this.batch || [];
      this.batch = this._getBulkEditingDirty(batch);
    }

    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;

      if (!this.isBulkEditing) {
        this.fetchCampaignDetailsLimitsTask.perform();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "batch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isBulkEditing", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchCampaignDetailsLimitsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsLimitsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customPaginateSearchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customPaginateSearchRecordsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bulkEdit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "bulkEdit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleBulkEdit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkEdit"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsPanelsDisplaySubPanelsProductsLimitsComponent;
});