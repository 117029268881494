define("client/pods/components/reports/commissions/panels-all/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  let ReportsCommissionsPanelsAllTableViewDetailRow = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class ReportsCommissionsPanelsAllTableViewDetailRow extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "panelsDisplayModel", _descriptor2, this);
    }

    getModel(summaryModel) {
      var _this$args, _this$args$tableViewO;

      const salesPersons = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$tableViewO = _this$args.tableViewOptions) === null || _this$args$tableViewO === void 0 ? void 0 : _this$args$tableViewO.salesPersons) || [];
      const summaryModelSalesPersonKey = R_.dotPathOr('', '_data.salesPersonKey', summaryModel);
      const model = R.find(data => R_.dotPathEq('_data._key', summaryModelSalesPersonKey, data))(salesPersons);
      this.panelsDisplayModel = model;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "panelsDisplayModel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getModel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getModel"), _class.prototype)), _class));
  _exports.default = ReportsCommissionsPanelsAllTableViewDetailRow;
});