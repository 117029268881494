define("client/pods/components/helpers/is-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2u0nL5+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[4,\"if\",[[24,[\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"class\",\"button is-loading u-borderless\"],[8],[1,[28,\"tt\",[\"loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"isRunning\"]],[24,[\"hasPlaceholder\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"is-loading-container \",[28,\"if\",[[24,[\"isFullscreen\"]],\"is-loading-container--fullscreen\"],null]]]],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"class\",[29,[\"button is-small \",[28,\"if\",[[24,[\"isRunning\"]],\"is-loading\"],null],\" u-borderless u-backgroundless\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"wasSaved\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"classNames\"],[\"fas fa-check-circle\",\"u-fade-in--quick has-text-success\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/is-loading/template.hbs"
    }
  });

  _exports.default = _default;
});