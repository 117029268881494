define("client/pods/documents/model", ["exports", "client/pods/base/model", "client/mixins/sales-persons", "client/pods/documents/mixins/new", "client/pods/documents/mixins/linking", "client/pods/documents/mixins/details", "client/pods/documents/mixins/calculations", "client/pods/documents/mixins/contacts", "client/mixins/date", "ramda", "ramda-adjunct", "client/config/environment"], function (_exports, _model, _salesPersons, _new, _linking, _details, _calculations, _contacts, _date, R, RA, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DetailModel from './details/model'
  var _default = _model.default.extend(_salesPersons.default, _new.default, _linking.default, _date.default, _details.default, _calculations.default, _contacts.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    unroundedCurrency: 8,
    showHCTModal: false,
    print: true,
    printWaybill: true,
    printAsPackingList: false,

    init() {
      this._super(...arguments); // used for when copied to dirty.


      this.set('_attrs', ['eInvoicesCollection']);
      this.set('mergeDetailsProps', ['fromMaster', 'exclPrice', 'itemCode', 'itemKey', 'ref', 'taxRate', 'unitKey', 'sourceKey']);
      this.set('linkTemp', _model.default.create());
      this.set('linkAttrs', [{
        label: 'payment method',
        param: 'paymentMethod',
        // this corresponds to _link_paymentMethod method in linking mixin
        component: 'documents/links-conflict/payment-method-conflict',
        group: [// 'isConsignment',
        'paymentMethodKey', 'paymentTerms', 'paymentTermsKey']
      }, {
        param: 'paymentDueDateZ',
        label: 'payment due date',
        component: 'documents/links-conflict/payment-due-conflict'
      }, 'account', {
        param: 'taxMethodKey',
        label: 'tax method',
        translate: true,
        translatePrefix: 'tax_'
      }, {
        param: 'taxNumber',
        label: 'tax number'
      }, // {
      //   param: 'discount',
      //   group: ['discountType', 'discountAmount', 'discountRate'],
      //   component: 'documents/links-conflict/discount-conflict'
      // },
      'address', {
        param: 'transporter',
        // this corresponds to _link_transporter method in linking mixin
        label: 'transporter',
        group: ['transporterLabel', 'transporterKey'],
        component: 'documents/links-conflict/transporter-conflict',
        excludeForDocName: 'returns'
      }, {
        param: 'notes',
        component: 'documents/links-conflict/notes-conflict'
      }]);
      this.set('adapters', {
        eInvoicesCollection: 'extensions/apps/tw/e-invoices/collection'
      });
      this.set('sortArrayInstructions', [{
        direction: 'ascend',
        prop: '_data.index'
      }, {
        direction: 'ascend',
        prop: '_data.childIndex'
      }, {
        direction: 'ascend',
        prop: '_data.grandChildIndex'
      }]);
    },

    defaults() {
      return {
        rewardData: {},
        currency: 'NTD'
      };
    },

    docAdapterName: Ember.computed('_data.{docType,docName}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      return `documents/${docType}/${docName}`;
    }),
    title: Ember.computed('_data.{docType,docName}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      return `${docType}-${docName}`;
    }),

    /**
     * create model when dataManager is not available. this is when in public viewing mode
     */
    _createModelForPublicMode(data) {
      return Ember.Object.create({
        _data: data
      });
    },

    docStatus: Ember.computed('_data.{status,dispatchProcess}', 'hasDispatchProcess', function () {
      const status = this.getData('status');
      let docStatus = status;

      if (this.get('_data.isMerged')) {
        return 'merged_document';
      }

      if (!this.hasDispatchProcess) {
        return docStatus;
      }

      const dispatchProcess = this.getData('dispatchProcess');

      if (status === this.get('constants.documentsStatus.final')) {
        docStatus = dispatchProcess;
      }

      if (!docStatus) {
        docStatus = this.get('constants.dispatchProcess.unprepared');
      }

      return docStatus;
    }),
    statusClass: Ember.computed('_data.{status,dispatchProcess}', 'hasDispatchProcess', function () {
      const status = this.get('_data.status');
      const dispatchProcess = this.get('_data.dispatchProcess');

      if (this.isMerged) {
        return 'is-danger';
      }

      if (status === this.get('constants.documentsStatus.draft')) {
        return 'is-warning';
      }

      if (status === this.get('constants.documentsStatus.void')) {
        return 'is-danger';
      }

      if (!this.hasDispatchProcess) {
        return 'is-success';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.awaitingPayment') && status === this.get('constants.documentsStatus.final')) {
        return 'is-dark';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.dispatched') && status === this.get('constants.documentsStatus.final')) {
        return 'is-success';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.unprepared') || dispatchProcess === '') {
        return 'is-info';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.outOfStock') || dispatchProcess === '') {
        return 'is-black';
      }

      return 'is-dark';
    }),
    // dispatchStatusClass: computed('_data.dispatchStatus', function () {
    //   const dispatchStatus = this.get('_data.dispatchStatus')
    //   if (dispatchStatus === this.get('constants.dispatchStatus.onHold')) {
    //     return 'is-dark'
    //   }
    //   if (dispatchStatus === this.get('constants.dispatchStatus.undispatched') || !dispatchStatus) {
    //     return 'is-info'
    //   }
    //   if (dispatchStatus === this.get('constants.dispatchStatus.dispatched')) {
    //     return 'is-success'
    //   }
    // }),
    allowSplit: Ember.computed('_data.{isDispatched,details.[]}', function () {
      if (this.get('_data.isDispatched')) {
        return false;
      }

      const details = this.get('_data.details');

      if (details.length < 1) {
        return false;
      }

      if (details.length === 1) {
        const detail = details.get('firstObject');

        if (detail) {
          const qty = parseFloat(detail.getData('qty')) || 0;

          if (qty <= 1) {
            return false;
          }
        }
      }

      return true;
    }),
    hasPrintWaybill: Ember.computed('_data.{transporterExtension,isDispatched,status}', function () {
      const transporterExtension = this.getData('transporterExtension');
      const isDispatched = this.getData('isDispatched');
      const status = this.getData('status');

      if (R.equals(transporterExtension, 'hct') && R.equals(isDispatched, true) && R.equals(status, 'final')) {
        return true;
      }

      return false;
    }),
    isDispatched: Ember.computed('synced', '_data.{isDispatched,status}', function () {
      const isDispatched = this.getData('isDispatched');
      const status = this.getData('status');

      if (R.equals(isDispatched, true) && R.equals(status, 'final')) {
        return true;
      }

      return false;
    }),
    notDispatched: Ember.computed('isDispatched', function () {
      return !this.isDispatched;
    }),
    allowTransporterQuickChange: Ember.computed('notDispatched', 'isDispatchedBeforeToday', function () {
      if (this.notDispatched) {
        return true;
      }

      if (!this.isDispatchedBeforeToday) {
        return true;
      }

      return false;
    }),
    hasEInvoiceInfoChanged: Ember.computed('_data.{email,taxNumber,address,status}', 'requirePrintEInvoice', function () {
      return Math.random();
    }),
    hasEInvoices: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];

      if (invoices.length > 0) {
        return true;
      }

      return false;
    }),
    successfulEInvoices: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      return R.filter(R.pathEq(['_data', 'status'], 'successful'), invoices);
    }),
    lastSuccessfulEInvoice: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      const successful = R.filter(R.pathEq(['_data', 'status'], 'successful'), invoices);

      if (successful.length > 0) {
        return R.head(successful);
      }

      return false;
    }),
    lastSuccessfulEInvoiceNumber: Ember.computed('synced', 'lastSuccessfulEInvoice._data.number', function () {
      return R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'])(this);
    }),
    paymentLink: Ember.computed('_data.externalUrl', function () {
      var _this$_data;

      return `${_environment.default.APP.bigordrHost}/erp/payments/card/doc/${(_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.externalUrl}`;
    }),
    refOrDocNo: Ember.computed('_data.{ref,docNo,sendRefAsDocNo}', function () {
      const sendRefAsDocNo = this.get('_data.sendRefAsDocNo');
      const ref = this.get('_data.ref');

      if (sendRefAsDocNo && ref) {
        return ref;
      }

      return this.get('_data.docNo');
    }),
    isDispatchedBeforeToday: Ember.computed('_data.{dispatchStatus,dispatchDateZ}', function () {
      const dispatchStatus = this.getData('dispatchStatus');
      const dispatchDateZ = this.getData('dispatchDateZ');

      if (this.isBeforeToday(dispatchDateZ) && dispatchStatus === this.get('constants.dispatchStatus.dispatched')) {
        return true;
      }

      return false;
    }),
    // @TODO use new isComplete computed in base. simply set requiredAttrs in model. still need to work out how details will also work
    // @TODO simply add _data.details.@each.isComplete? and then for details also set requiredAttrs
    isComplete: Ember.computed('_data.{contact,dateZ,details.@each.hasIncomplete}', 'incompleteItems.[]', function () {
      // @NOTE: overwrites base.isComplete
      const self = this;
      let hasIncomplete = false;

      if (!self.getData('contact')) {
        hasIncomplete = true;
      }

      if (!self.getData('dateZ')) {
        hasIncomplete = true;
      }

      const details = self.getData('details') || [];
      let detailsIncomplete = 0;
      details.forEach(function (detail) {
        if (detail.get('hasIncomplete')) {
          detailsIncomplete++;
        }
      });

      if (details.length === 1 && detailsIncomplete === 1) {
        hasIncomplete = true;
      }

      if (detailsIncomplete > 1) {
        hasIncomplete = true;
      }

      self.addToIncomplete('info', hasIncomplete);
      const incompleteItems = this.incompleteItems;

      if (incompleteItems.length > 0) {
        hasIncomplete = true;
      }

      if (hasIncomplete) {
        return false;
      }

      return true;
    }),
    isDispatchableToday: Ember.computed('_data.{status,dispatchProcess,dateZ}', function () {
      if (this.getData('isDispatched') && this.getData('status') === this.get('constants.documentsStatus.final')) {
        return true;
      }

      const dateZ = this.getData('dateZ');

      if (this.isSameOrBefore(dateZ)) {
        return true;
      }

      return false;
    }),
    isMissingHCTData: Ember.computed('_data.{address,status,isDispatched,transporterExtension,transporterLabel,waybill,transporterRegion,transporterRegionIsOuter}', function () {
      let missingFields = ['transporterLabel', 'transporterRegion', 'transporterAccount', 'transporterAccountNumber', 'transporterBatchNumber', 'address'];

      if (this.getData('isDispatched') && this.getData('status') === this.get('constants.documentsStatus.final')) {
        missingFields = R.concat(['waybill'], missingFields);
      }

      return R.pipe(R.reject(field => {
        return this.getData(field);
      }), R.length, R.gt(R.__, 0))(missingFields);
    }),
    isMissingHCTDataClassNames: Ember.computed('_data.{address,status,isDispatched,transporterExtension,transporterRegionIsOuter}', 'isMissingHCTData', function () {
      let classNames = '';
      const transporterExtension = this.getData('transporterExtension');

      if (transporterExtension === 'hct') {
        if (this.getData('transporterRegionIsOuter')) {
          classNames = 'is-warning';
        }

        if (this.isMissingHCTData) {
          classNames = 'is-danger';
        }
      }

      return classNames;
    }),

    /**
     * custom populate function.
     * will create child detail models
     * @param {object/json} data - model data
     */
    populate(data = {}, attrs) {
      const self = this;
      data = data || {};
      const detailsRequiredAttrs = self.get('detailsRequiredAttrs'); // create models for each detail

      if (data.details) {
        data.details = data.details.map(detailData => {
          // const commissionBaseCost = detailData.commissionBaseCost || ''
          // if (RA.isNilOrEmpty(detailData.commissionBaseCostFinal)) {
          //   detailData.commissionBaseCostFinal = commissionBaseCost
          // }
          if (this.dataManager) {
            const detailModel = this.dataManager.setAsRecord({
              adapterName: 'documents/details',
              data: detailData
            });
            detailModel.set('requiredAttrs', detailsRequiredAttrs);
            return detailModel;
          } else {
            return this._createModelForPublicMode(detailData);
          }
        });
      }

      if (data.eInvoices) {
        const eInvoicesCollection = self.transformEInvoices(data);
        this.set('eInvoicesCollection', eInvoicesCollection);
      }

      self.transformSalesPersons(data);
      const returns = self.transformReturnsDetails(data);
      self.set('returnsDetails', returns); // populate docName info
      // self.overwriteData(data)

      this._super(data, attrs);
    },

    transformEInvoices(data) {
      const eInvoicesCollection = data.eInvoices || [];
      delete data.eInvoices;
      const collectionData = {
        master: data._key,
        invoices: eInvoicesCollection
      };

      if (this.dataManager) {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.eInvoicesCollection'),
          data: collectionData
        });
      } else {
        return this._createModelForPublicMode(collectionData);
      }
    },

    transformReturnsDetails(data) {
      const returns = data.returns || [];
      delete data.returns;

      if (this.dataManager) {
        let adapterName;

        if (data.docType === 'sales') {
          adapterName = 'documents/sales/returns/details';
        } else {
          adapterName = 'documents/purchases/returns/details';
        }

        return R.map(returnDetailData => {
          return this.dataManager.setPartialRecord({
            adapterName,
            data: returnDetailData
          });
        })(returns);
      } else {
        return R.map(returnDetailData => {
          return this._createModelForPublicMode(returnDetailData);
        })(returns);
      }
    },

    // sortedDetailsNetReturns: computed('sortedDetailsWithDiscountAndReward.[]', 'returnsDetails.[]', function () {
    //   const returnsDetails = this.returnsDetails || []
    //   console.log('====returnsDetails======')
    //   console.log(returnsDetails)
    //   console.log('====sortedDetailsWithDiscountAndReward======')
    //   const sortedDetailsWithDiscountAndReward = this.sortedDetailsWithDiscountAndReward || []
    //   console.log(this.sortedDetailsWithDiscountAndReward)
    //   if (RA.isNotNilOrEmpty(returnsDetails)) {
    //     debugger
    //   }
    //   return sortedDetailsWithDiscountAndReward
    // }),

    /**
     * returns true if discount is a %
     * @type {Boolean}
     */
    isDiscountRate: Ember.computed('_data.discountType', function () {
      const discType = this.getData('discountType');

      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }

      return false;
    }),
    isTaxExclusive: Ember.computed('_data.taxMethodKey', function () {
      const taxMethodKey = this.getData('taxMethodKey'); // no taxMethodKey (none) is also treated as exclusive

      if (taxMethodKey === this.get('constants.taxMethods.exclusive') || taxMethodKey === this.get('constants.taxMethods.none')) {
        return true;
      }

      return false;
    }),
    isSales: Ember.computed('_data.docType', function () {
      if (this.get('_data.docType') === 'sales') {
        return true;
      }

      return false;
    }),
    isSalesReturns: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.docType') === 'sales' && this.get('_data.docName') === 'returns') {
        return true;
      }

      return false;
    }),
    isSalesInvioces: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.docType') === 'sales' && this.get('_data.docName') === 'invoices') {
        return true;
      }

      return false;
    }),
    hasDispatchProcess: Ember.computed('_data.{docType,docName,paymentMethodKey}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      const paymentMethodKey = this.get('_data.paymentMethodKey');

      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments')) {
        if (docName === 'invoices' && paymentMethodKey === 'constants.paymentMethods.consignment') {
          return false;
        }

        return true;
      }

      return false;
    }),
    dispatchProcess: Ember.computed('_data.dispatchProcess', function () {
      if (this.get('_data.dispatchProcess') === '') {
        return 'unprepared';
      }

      return this.get('_data.dispatchProcess');
    }),
    hasTaxMethod: Ember.computed('_data.taxMethodKey', function () {
      const taxMethodKey = this.getData('taxMethodKey'); // no taxMethodKey (none) is also treated as exclusive

      if (taxMethodKey === this.get('constants.taxMethods.none') || taxMethodKey === '') {
        return false;
      }

      return true;
    }),
    hasRewards: Ember.computed('_data.rewardInclTotal', function () {
      const rewardInclTotal = parseInt(this._data.rewardInclTotal) || 0;

      if (rewardInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscount: Ember.computed('_data.discInclTotal', function () {
      const discInclTotal = this.get('_data.discInclTotal') || 0;

      if (discInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscountRate: Ember.computed('_data.discountRate', function () {
      if (!this.hasDiscount) {
        return false;
      }

      const discInclTotal = this.getData('discountRate') || 0;

      if (discInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscountAmount: Ember.computed('hasDiscountRate', '_data.{discountType,discountAmount}', function () {
      if (!this.hasDiscount) {
        return false;
      }

      const discountType = this.getData('discountType') || '';

      if (discountType === this.get('constants.discountTypes.amount') && this.getData('discountAmount')) {
        return true;
      }

      return false;
    }),
    calculatedDiscExclTotal: Ember.computed('hasDiscountAmount', '_data.{discExclTotal,discInclTotal,details.[]}', function () {
      if (this.hasDiscountAmount) {
        if (this.getData('discExclTotal')) {
          return this.getData('discExclTotal');
        }

        const details = this.getData('details') || [];
        const roundingCurrency = this.getData('roundingCurrency') || 0;
        return this.big.sumPropAndRound(roundingCurrency, '_data.allocatedExclDiscountUnrounded', details);
      }

      return '';
    }),
    returnsInclTotal: Ember.computed('returnsDetails.[]', 'synced', function () {
      const returnsDetails = this.returnsDetails || [];

      if (RA.isNilOrEmpty(returnsDetails)) {
        return 0;
      }

      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.postDiscInclAmt', returnsDetails);
    }),
    netInclTotal: Ember.computed('returnsInclTotal', 'returnsDetails.[]', 'synced', function () {
      const returnsDetails = this.returnsDetails || [];

      if (RA.isNilOrEmpty(returnsDetails)) {
        return 0;
      }

      const roundingCurrency = this.getData('roundingCurrency') || 0;
      const returnsInclTotal = this.big.sumPropAndRound(roundingCurrency, '_data.postDiscInclAmt', returnsDetails);
      const inclTotal = this.getData('inclTotal') || 0;
      return this.big.newBig(inclTotal).minus(returnsInclTotal).toFixed(roundingCurrency);
    }),
    hasPaymentReminders: Ember.computed('_data.{docType,docName,paymentMethodKey,paymentCreditCardTransaction}', function () {
      const docType = this.getData('docType');
      const docName = this.getData('docName');

      if (docType === 'sales') {
        if (docName === 'quotes' || docName === 'invoices' || docName === 'orders') {
          if (this.getData('paymentMethodKey') === this.constants.paymentMethods.cashBeforeDelivery) {
            return true;
          }
        }
      }

      return false;
    }),
    hasDispatchStatus: Ember.computed('_data.{status,docType,docName}', function () {
      const docType = this.getData('docType');
      const docName = this.getData('docName');
      const status = this.getData('status');

      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments') && status === 'final') {
        return true;
      }

      return false;
    }),
    isDraft: Ember.computed('_data.status', function () {
      const status = this.getData('status');

      if (status === this.get('constants.documentsStatus.draft')) {
        return true;
      }

      return false;
    }),
    isConsignment: Ember.computed('_data.docName', function () {
      const docName = this.getData('docName');

      if (docName === 'consignments') {
        return true;
      }

      return false;
    }),
    requirePrintEInvoice: Ember.computed('_data.allowPrintInvoice', 'eInvoicesCollection._data.invoices.[]', function () {
      const settings = this.settings;
      const eInvoices = this.get('eInvoicesCollection._data.invoices') || [];
      const hasTwEInvoices = settings.hasTwEInvoices(this);

      if (hasTwEInvoices) {
        const successfulInvoices = R.filter(R.pathEq(['_data', 'status'], 'successful'), eInvoices);

        if (R.isEmpty(successfulInvoices)) {
          return true;
        }
      }

      return false;
    }),
    allowDispatchProcesses: Ember.computed('_data.{dispatchDateZ,timestampZ_latest,status}', function () {
      if (this.hasDispatchStatus) {
        const status = this.get('_data.status');

        if (status === this.get('constants.documentsStatus.void')) {
          return false;
        }

        const isDispatched = this.get('_data.isDispatched');
        const dispatchDateZ = this.get('_data.dispatchDateZ');
        const timestampZ = this.get('_data.timestampZ_latest');

        if (isDispatched && this.isBeforeToday(dispatchDateZ) && this.isBeforeToday(timestampZ)) {
          return false;
        }

        return true;
      }

      return false;
    }),
    allowUndoVoid: Ember.computed('_data.{isVoid,paymentCreditCardStatus}', function () {
      if (this.getData('paymentCreditCardStatus') === this.constants.paymentCreditCardStatus.refunded) {
        return false;
      }

      if (this.getData('isVoid') === true) {
        return true;
      }

      return false;
    }),

    loadPaymentMethod({
      paymentMethod,
      paymentMethodKey
    } = {}) {
      if (paymentMethodKey != null) {
        this.setData('paymentMethodKey', paymentMethodKey);
      }

      const hasPaymentTerms = paymentMethod.getData('hasPaymentTerms') || false;
      this.setData('paymentMethod', paymentMethod.getData('label'));
      this.setData('hasPaymentTerms', hasPaymentTerms);
      this.loadPaymentTerms();
    },

    loadPaymentTerms({
      paymentTerms,
      paymentTermsKey
    } = {}) {
      const hasPaymentTerms = this.getData('hasPaymentTerms');

      if (!hasPaymentTerms) {
        this.setData('paymentTermsKey', '');
        this.setData('paymentTermsMonths', '');
        this.setData('paymentTermsDays', '');
        this.setData('paymentTermsFrom', '');
        this.setData('paymentTermsEnd', '');
        return false;
      }

      if (paymentTermsKey != null) {
        this.setData('paymentTermsKey', paymentTermsKey);
      }

      paymentTermsKey = this.getData('paymentTermsKey');

      if (!paymentTermsKey) {
        this.setData('paymentTermsMonths', '0');
        this.setData('paymentTermsDays', '0');
        this.setData('paymentTermsFrom', this.get('constants.paymentTermsFrom.invoiceMonth'));
        this.setData('paymentTermsEnd', this.get('constants.paymentTermsEnd.exactDate'));
        return false;
      }

      this.setData('paymentTerms', paymentTerms.getData('label'));
      this.setData('paymentTermsMonths', paymentTerms.getData('months'));
      this.setData('paymentTermsDays', paymentTerms.getData('days'));
      this.setData('paymentTermsFrom', paymentTerms.getData('from'));
      this.setData('paymentTermsEnd', paymentTerms.getData('end'));
      return true;
    },

    hasDuplicateProduct(product) {
      const details = this.getData('details');
      const found = details.filterBy('_data.itemKey', product.getData('_key'));

      if (found.length > 1) {
        return true;
      }

      return false;
    },

    loadProduct(taxRate, detail, product, historyData) {
      detail.setData('unit', product.getData('unit'));
      detail.setData('unitKey', product.getData('unitKey'));
      detail.setData('item', product.getData('name'));
      detail.setData('invoiceDescription', product.getData('invoiceDescription'));
      detail.setData('roundingInventoryQty', product.getData('roundingInventoryQty', this.settings.getProp('roundingInventoryQty')));
      detail.setData('roundingInventoryPrice', product.getData('roundingInventoryPrice', this.settings.getProp('roundingInventoryPrice')));
      detail.setData('taxRate', taxRate);
      this.setDetailPrice(detail, historyData);
    },

    setDetailPrice(detail, historyData) {
      let price;
      const isTaxExclusive = this.isTaxExclusive;

      if (!price) {
        if (isTaxExclusive) {
          price = historyData.exclPrice;
        } else {
          price = historyData.inclPrice;
        }
      }

      if (!price) {
        const docType = this.get('_data.docType');
        let method = 'Excl';

        if (!isTaxExclusive) {
          method = 'Incl';
        }

        price = R.propOr('', `${docType}${method}Price`, historyData);
      }

      price = this._setPreBulkDiscountPrice({
        price,
        historyData
      });
      detail.setData('price', price);
      this.setProductCommissionsBaseCost(detail, historyData);
      this.sumTotals();
    },

    _setPreBulkDiscountPrice({
      price,
      historyData
    }) {
      const roundingCurrency = this.getData('roundingCurrency');
      const bulkDiscountAmt = R.propOr(0, 'bulkDiscountAmt')(historyData);

      if (R.gt(bulkDiscountAmt, 0)) {
        price = this.big.newBig(price).plus(bulkDiscountAmt).toFixed(roundingCurrency);
      }

      return price;
    },

    /**
     * determine if there is a single discount or has multiple discounts (line by line)
     */
    hasSingleDiscount: Ember.computed('_data.discountMethod', function () {
      const discountMethod = this.get('_data.discountMethod');

      if (discountMethod === this.get('constants.discountMethods.onTotal')) {
        return true;
      }

      return false;
    }),
    hasLineByLineDiscount: Ember.computed('_data.discountMethod', function () {
      return !this.hasSingleDiscount;
    }),

    setProductCommissionsBaseCost(detail, historyData) {
      const hasSalesPersons = this.hasSalesPersons;

      if (hasSalesPersons) {// @TODO: should check the sales person from linking doc and to linking doc.
        // @TODO: commission may be different from sales persons
        // const commissionBaseCost = R.propOr(0, 'commissionBaseCost', historyData)
        // if (commissionBaseCost !== 0 && commissionBaseCost !== '0') {
        //   // set base cost only if its non 0
        //   detail.setData('commissionBaseCost', commissionBaseCost)
        // }
      }
    },

    hasSalesPersons: Ember.computed('_data.salesPersons.[]', function () {
      const salesPersons = this.getData('salesPersons') || [];

      if (R.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    isCreditCardPayment: Ember.computed('_data.paymentMethodKey', function () {
      if (this._data.paymentMethodKey === this.constants.paymentMethods.creditCard) {
        return true;
      }

      return false;
    }),
    allowReturns: Ember.computed('_data.{docType,docName}', function () {
      // @TODO: allow other docs at a later stage
      if (this._data.docType !== 'sales' || this._data.docName !== 'invoices') {
        return false;
      }

      if (this._data.isVoid) {
        return false;
      }

      if (this._data.status !== this.constants.documentsStatus.final) {
        return false;
      }

      if (this._data.paymentMethodKey === this.constants.paymentMethods.creditCard && this._data.paymentCreditCardStatus !== this.constants.paymentCreditCardStatus.approved) {
        return false;
      }

      return true;
    }),
    allowDiscounts: Ember.computed('_data.{docType,docName}', function () {
      // @TODO: allow other docs at a later stage
      if (R.includes(this._data.docName)(['returns', 'consignments', 'consignments-returns'])) {
        return false;
      }

      return true;
    }),
    allowCreditCardRefunds: Ember.computed('_data.{docType,docName,paymentMethodKey}', function () {
      if (!this._data.docType === 'sales' || !this._data.docName === 'returns') {
        return false;
      }

      if (this._data.isVoid) {
        return false;
      } // if (this._data.status !== this.constants.documentsStatus.final) {
      //   return false
      // }


      if (this._data.paymentMethodKey !== this.constants.paymentMethods.creditCard) {
        return false;
      } // if (!this._data.paymentCreditCardTransactionCode) {
      //   return false
      // }


      return true;
    }),
    isAllowCreditCardRefundAndValid: Ember.computed('_data.{paymentCreditCardTransactionTimestampZ,paymentCreditCardTransactionCode}', 'synced', function () {
      let allowCreditCardRefund = false;
      const transactionDateZ = this.getData('paymentCreditCardTransactionTimestampZ');

      if (transactionDateZ) {
        const oneMonthAgo = this.dateService.getMoment().subtract(1, 'month');
        allowCreditCardRefund = oneMonthAgo.isBefore(transactionDateZ);
      }

      return allowCreditCardRefund;
    }),
    detailsWithDiscountAndReward: Ember.computed('_data.details.[]', 'synced', 'hasDiscountAmount', 'hasRewards', 'calculatedDiscExclTotal', '_data.discInclTotal', '_data.rewardPointApplied', '_data.rewardExclTotal', '_data.rewardInclTotal', function () {
      const originalDetails = this.getData('details') || [];
      return this.addDiscountAndRewardToDetails(originalDetails);
    }),
    sortedDetailsWithDiscountAndReward: Ember.computed('sorted.[]', 'synced', 'hasDiscountAmount', 'hasRewards', 'calculatedDiscExclTotal', '_data.discInclTotal', '_data.rewardPointApplied', '_data.rewardExclTotal', '_data.rewardInclTotal', function () {
      let originalDetails = this.get('sorted') || [];
      let lastSetId = '';
      originalDetails = R.map(detail => {
        if (detail._data.setId && detail._data.setId !== lastSetId) {
          detail.set('isFirstSetChoiceItem', true);
          lastSetId = detail._data.setId;
        }

        return detail;
      })(originalDetails);
      return this.addDiscountAndRewardToDetails(originalDetails);
    }),

    addDiscountAndRewardToDetails(originalDetails) {
      const details = R.concat(originalDetails, []);
      const hasDiscount = this.get('hasDiscount') || false;
      const hasRewards = this.get('hasRewards') || false;
      const hasNoDiscountDetail = R.pipe(R.find(R.propEq(['_data', 'item'], this.intl.t('discount amount'))), RA.isNilOrEmpty())(details);
      const hasNoRewardDetail = R.pipe(R.find(R.propEq(['_data', 'item'], `${this.intl.t('rewards used')} (${this.getData('rewardPointApplied')} ${this.intl.t('points')})`)), RA.isNilOrEmpty())(details);
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      let discountDetail = {};

      if (hasDiscount && hasNoDiscountDetail) {
        const inclAmt = this.getData('discInclTotal') || 0;
        const exclAmt = this.get('calculatedDiscExclTotal') || this.getData('discExclTotal') || 0;
        const discountTaxAmount = this.big.newBig(inclAmt).minus(exclAmt).toFixed(roundingCurrency);
        let item = `${this.intl.t('discount')}: $ ${this.intl.formatNumber(this.getData('discountAmount'))}`;

        if (this.get('hasDiscountRate')) {
          item = `${this.intl.t('discount')}: ${this.getData('discountRate')}%`;
        }

        discountDetail = this.dataManager.setAsRecord({
          adapterName: 'documents/details',
          data: {
            item,
            exclAmt: R.negate(exclAmt),
            taxAmt: R.negate(discountTaxAmount),
            inclAmt: R.negate(inclAmt)
          }
        });
        details.pushObject(discountDetail);
      }

      let rewardDetail = {};

      if (hasRewards && hasNoRewardDetail) {
        const inclAmt = this.getData('rewardInclTotal') || 0;
        const exclAmt = this.getData('rewardExclTotal') || 0;
        const rewardTaxAmount = this.big.newBig(inclAmt).minus(exclAmt).toFixed(roundingCurrency);
        rewardDetail = this.dataManager.setAsRecord({
          adapterName: 'documents/details',
          data: {
            item: `${this.intl.t('rewards used')} (${this.getData('rewardPointApplied')} ${this.intl.t('points')})`,
            exclAmt: R.negate(exclAmt),
            taxAmt: R.negate(rewardTaxAmount),
            inclAmt: R.negate(inclAmt)
          }
        });
        details.pushObject(rewardDetail);
      }

      return details;
    },

    hasTaxRoundingDifference: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      const exclTotal = this.getData('exclTotal');
      const calculatedExclTotal = this.big.sumPropAndRound(roundingCurrency, '_data.exclAmt', details);

      if (exclTotal !== calculatedExclTotal) {
        return true;
      }

      return false;
    }),
    calculatedExclTotal: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.exclAmt', details);
    }),
    calculatedTaxTotal: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.taxAmt', details);
    }) // creditCardRefunds: computed('_data.creditCardRefunds.[]', function () {
    //   const creditCardRefunds = this._data.creditCardRefunds || []
    //   if (RA.isNilOrEmpty(creditCardRefunds)) {
    //     return ''
    //   }
    //   return this._data.creditCardRefunds
    // })

  });

  _exports.default = _default;
});