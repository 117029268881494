define("client/pods/components/news/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I/RDNg3K",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"placeholder\",\"value\",\"isRunning\",\"onSubmit\",\"onInput\"],[[24,[\"autofocus\"]],\"search\",[24,[\"filters\",\"_data\",\"query\"]],[28,\"or\",[[24,[\"searchTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null],[28,\"perform\",[[24,[\"searchTask\"]],[24,[\"searchOptions\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]]],null],[28,\"perform\",[[24,[\"searchTask\"]],[24,[\"searchOptions\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]]],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"news/search-filters\",null,[[\"adapterName\",\"filters\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\"]],[24,[\"results\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/news/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});