define("client/pods/components/dispatch/panels-display/print-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isPrintable: Ember.computed('model._data.dispatchProcess', function () {
      const model = this.model;

      if (model.get('dispatchProcess') === this.get('dispatchProcess._data.code')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});