define("client/pods/documents/mixins/contacts", ["exports", "client/pods/components/documents/docConfig"], function (_exports, _docConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    loadContact({
      docType,
      contact,
      paymentMethodsList,
      paymentTermsList
    }) {
      // @TODO TEST
      const key = contact.getData('_key');
      const salesPersons = contact.getData('salesPersons', []);

      const config = _docConfig.default.getFor({
        docType,
        docName: this._data.docName
      });

      this.set('selectedContact', contact);
      this.setData('contactKey', key);
      this.setData('salesPersons', salesPersons);

      if (config.hasTaxNumber) {
        this.setData('taxNumber', contact.getData('taxNumber'));
      }

      this.setData('autoPrintInvoices', contact.getData('autoPrintInvoices'));
      const settingsModel = this.settings.getModel();
      this.setData('twEInvoicePrint', contact.getData('tw_eInvoice_always_print'));
      this.setData('roundingCurrency', contact.getData('roundingCurrency', settingsModel.getData('roundingCurrency')));
      this.setData('unroundedCurrency', contact.getData('unroundedCurrency', settingsModel.getData('unroundedCurrency')));
      this.setData('roundingInventoryQty', contact.getData('roundingInventoryQty', settingsModel.getData('roundingInventoryQty')));
      this.setData('roundingInventoryPrice', contact.getData('roundingInventoryPrice', settingsModel.getData('roundingInventoryPrice')));

      this._loadContactPaymentMethod({
        docType,
        contact,
        paymentMethodsList,
        paymentTermsList
      }); // if taxtMethod is on auto, do not change taxMethod as it is already set by panel defaults


      const contactTaxMethodKey = contact.getData(`${docType}TaxMethodKey`);

      if (contactTaxMethodKey !== 'auto' && contactTaxMethodKey !== '' && contactTaxMethodKey != null) {
        // not auto
        this.setData('taxMethodKey', contactTaxMethodKey);
      }

      this.loadContactPerson();
    },

    unloadContact() {
      const empty = '';
      const emptyArray = [];
      this.set('selectedContact', empty);
      this.setData('contact', empty);
      this.setData('contactKey', empty);
      this.setData('salesPersons', emptyArray);
      this.setData('address', empty);
      this.setData('email', empty);
      this.setData('telephone', empty);
      this.setData('twEInvoicePrint', false);
    },

    _loadContactPaymentMethod({
      docType,
      contact,
      paymentMethodsList,
      paymentTermsList
    }) {
      const paymentMethodKey = contact.getData(`${docType}PaymentMethodKey`);

      if (paymentMethodKey !== 'auto' && paymentMethodKey !== '' && paymentMethodKey != null) {
        const paymentMethod = paymentMethodsList.findBy('_data.value', paymentMethodKey);
        this.loadPaymentMethod({
          paymentMethod,
          paymentMethodKey
        });
      }

      const paymentTermsKey = contact.getData(`${docType}PaymentTermsKey`);

      if (paymentTermsKey !== 'auto' && paymentTermsKey !== '' && paymentTermsKey != null) {
        const paymentTerms = paymentTermsList.findBy('_data._key', paymentTermsKey);
        this.setData('paymentTermsKey', paymentTermsKey);
        this.loadPaymentTerms({
          paymentTerms,
          paymentTermsKey
        });
      }
    },

    // loadDefaultContactDetails () {
    //   const personOrDepartment = this.loadContactPerson()
    //   this.loadContactTelephone(personOrDepartment)
    // },
    loadContactPerson(personOrDepartment) {
      const selectedContact = this.selectedContact;

      if (selectedContact) {
        if (!personOrDepartment) {
          personOrDepartment = selectedContact.getDefaultPersonOrDepartment(this.getData('docType'), this.getData('docName'));
        }

        let parent = selectedContact;

        if (personOrDepartment) {
          this.setData('person', personOrDepartment.getData('name'));
          parent = personOrDepartment;
        }

        this.loadDetail(selectedContact, parent, 'telephone', 'phone');
        this.loadDetail(selectedContact, parent, 'email', 'email');
        this.loadDetail(selectedContact, parent, 'address', 'address');
        return personOrDepartment;
      }
    },

    loadDetail(selectedContact, personOrDepartment, setProp, type) {
      const defaultDetail = personOrDepartment.getDefaultDetail({
        contact: selectedContact,
        type
      });
      let value = '';

      if (defaultDetail) {
        value = defaultDetail.getData('value');
      }

      if (value) {
        this.setData(setProp, value);
      }

      return defaultDetail;
    }

  });

  _exports.default = _default;
});