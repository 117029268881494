define("client/pods/components/ledgers/accounts/aging/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NHoO9c53",
    "block": "{\"symbols\":[\"subTab\",\"result\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__sidebar u-results\"],[8],[0,\"\\n\\t\\t\"],[2,\" list past 12 months \"],[0,\"\\n\\n\"],[4,\"lists/list-results\",null,[[\"list\",\"onSelect\"],[[24,[\"pastMonths\"]],[28,\"perform\",[[24,[\"displayAgingTask\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[[23,2,[\"name\"]]],null],false],[0,\" \"],[1,[23,2,[\"yyyy\"]],false],[0,\"\\n\\n\"]],\"parameters\":[2]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content u-section\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"displayAgingTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\"],[[24,[\"tab\"]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/accounts/aging/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});