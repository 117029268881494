define("client/pods/components/channels/shop/campaigns/campaigns-editor/reward-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ChannelsShopCampaignsCampaignsEditorRewardEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorRewardEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "rewardResults", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "rewardFiltersAdapter", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor5, this);
      (0, _defineProperty2.default)(this, "adapterName", 'channels/shop/rewards');
      this.filters = this.search.setupFilters({
        adapterName: this.rewardFiltersAdapter,
        defaultFiltersData: {
          resultsProperty: 'rewardsList',
          query: ''
        }
      });
    }

    *fetchRewardTask({
      query
    }) {
      query = query.trim();

      if (query) {
        const filters = this.filters;
        const adapterName = this.adapterName;
        filters.setData('query', query);
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          filters,
          component: this,
          resultsProperty: 'rewardsList'
        });
        return results;
      }
    }

    onLoadItem(selectedItem) {
      this.args.model.set('_data.rewardData', selectedItem._data);
      this.args.model.set('_data.rewardKey', selectedItem._data._key);
      this.args.model.set('_data.isAllowSpendReward', true);
      this.args.model.set('_data.isAllowEarnReward', true);
    }

    onResetItem() {
      this.args.model.set('_data.rewardData', '');
      this.args.model.set('_data.rewardKey', '');
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rewardResults", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rewardFiltersAdapter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'channels/shop/rewards/filters';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchRewardTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRewardTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onLoadItem", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onLoadItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onResetItem", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onResetItem"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorRewardEditorComponent;
});