define("client/pods/components/app-companies/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  let AppCompaniesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = (_temp = class AppCompaniesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "auth", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "settings", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "pods", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedPod", _descriptor9, this);
      const pods = this.auth.getUserProp('pods') || [];
      const defaultPod = this.auth.getUserProp('pod') || [];
      this.selectedPod = defaultPod;
      this.pods = R.map(pod => {
        return Ember.Object.create(pod);
      })(pods);
    }

    *loginToCompanyTask() {
      const podKey = this.selectedPod;
      let confirmed = true;

      if (this.args.confirm) {
        const pods = this.pods || [];
        const podData = R.find(R.propEq('podKey', podKey))(pods);
        const msg = this.intl.t('log into new company?', {
          company: podData.company
        });
        confirmed = window.confirm(msg);
      }

      if (confirmed) {
        const loginData = yield this.auth.loginToCompanyTask.perform(podKey);

        if (this.args.onChange) {
          // clear local storage
          this.dataManager.resetCache();
          this.tabsManager.resetTabs(); // reload pod settings

          yield this.settings.setupTask.perform();
          this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company, {
            queryParams: {
              reload: true
            }
          });
          this.args.onChange(loginData);
        } else {
          return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company);
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "settings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pods", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedPod", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loginToCompanyTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loginToCompanyTask"), _class.prototype)), _class));
  _exports.default = AppCompaniesComponent;
});