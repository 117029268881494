define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/rules-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor4, this);
    }

    get bulkDiscountsWhen() {
      const whenLists = this.crud.lists['automations-when'];
      const bulkDiscountsWhenLists = R.concat(whenLists, []);
      return R.filter(whenData => {
        const when = R.pathOr('', ['_data', 'value'])(whenData);
        return R.includes(when, ['itemQty', 'itemValue']);
      })(bulkDiscountsWhenLists);
    }

    onSubmitRemoveRule(bulkDiscount, rule) {
      const rules = bulkDiscount.getData('rules');
      rules.removeObject(rule);
    }

    onToggleSetDescription(rule) {
      const isDefaultDescription = rule.getData('isDefaultDescription');

      if (isDefaultDescription) {
        rule.setData('description', '');
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveRule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveRule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetDescription", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetDescription"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent;
});