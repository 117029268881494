define("client/pods/components/reports/commissions/tab-container/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  let ReportsCommissionsTabContainer = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class ReportsCommissionsTabContainer extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "resultsToggle", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "yyyy", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "mm", _descriptor10, this);
      (0, _defineProperty2.default)(this, "adapterName", 'users/associates');
      (0, _defineProperty2.default)(this, "filtersName", 'users/associates/filters');
      this.crud.addLists(this, ['users/lists/menu', 'commissions/lists/panels']);
      this.dash();
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.filters = filters;
    }

    afterLoadLists() {
      this.loadTabTask.perform();
    }

    *loadTabTask() {
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters
      });
      this.filters = filters;
      this.dash(filters.results);
      this.resultsToggle = true;
    }

    *markAsPayoutsTask({
      adapterName,
      model,
      onAfter
    }) {
      const dateZ = this.date.getNowZ();
      const period = this.date.getPeriod(dateZ);
      const payload = this.dataManager.setAsRecord({
        adapterName,
        data: {
          salesPerson: model._data.salesPerson,
          salesPersonKey: model._data.salesPersonKey,
          amount: model._data.amount,
          comments: model._data.comments,
          ref: period.monthStart,
          dateZ: dateZ,
          mm: period.mm,
          yyyy: period.yyyy
        }
      });
      yield this.crud.createRecordTask.perform({
        adapterName,
        model: payload,
        onAfter
      }); // yield this.server.call('POST', 'api/protected/reports/commissions/payouts', payload)
    }
    /**
     * display the user
     * @param  {object} user model
     */


    display(model) {
      const subTabOptions = {
        component: 'reports/commissions/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    dash() {
      this.resultsToggle = false;
      const tab = this.args.tab;
      const subTabOptions = {
        component: 'users/panels-dash' // model: model

      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    } // @TODO: NEED TO USE NGUARD TO RESTRICT CERTAIN ACTIONS


    onDash() {
      // should deselect results if any..
      // this.deselectResult();
      this.dash(); // search () {
    }

    onDisplay(model) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      this.display(model);
    }

    onDisplayWithDate(model, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      this.display(model);
    }

    displayPanel(resource) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    displayPanelWithDate(resource, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resultsToggle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "yyyy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "markAsPayoutsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markAsPayoutsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDash", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDash"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDisplay", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplay"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDisplayWithDate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplayWithDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayPanel", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayPanelWithDate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanelWithDate"), _class.prototype)), _class));
  _exports.default = ReportsCommissionsTabContainer;
});