define("client/pods/components/products/products-editor/component", ["exports", "ramda", "client/utils/nventor", "ramda-extension"], function (_exports, R, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    defaultProductType: null,
    defaultProductStatus: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'products/lists/editorSubPanels', 'settings/roundingAuto', 'units', 'units/dimensions', 'units/weight', 'statuses', 'products/lists/pageImportBtnSubPanels']); // if (this.get('isEditing') || this.get('isNew')) {
      // const model = this.get('model')
      // const originalModel = this.get('originalModel')
      // model.updateVariantChildrenList(originalModel)
      // }
      // this.set('editingPanels', [])
    },

    // didReceiveAttrs() {
    //   this.get('fetchVariantChildrenTask').perform()
    // },
    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      const subPanels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel); // this.setEditorSubPanels()
    },

    // setEditorSubPanels () {
    //   let panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || []
    //   let removeAll = false
    //   const setType = this.get('model._data.setType')
    //   if (this.settings.getProp('beta.productSets')) {
    //     if (nventor.isNilOrEmpty(setType)) {
    //       removeAll = true
    //     }
    //   }
    //   if (setType !== 'isSet' && setType !== 'isSetWithChoices' && setType !== 'hasVariants') {
    //     removeAll = true
    //   }
    //   if (setType === 'isSet' || removeAll) {
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels)
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels)
    //   }
    //   if (setType === 'isSetWithChoices' || removeAll) {
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels)
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels)
    //   }
    //   if (setType === 'hasVariants' || removeAll) {
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels)
    //     panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels)
    //   }
    //   this.set('editorSubPanels', panels)
    // },
    editorSubPanels: Ember.computed('crud.lists.products-lists-editorSubPanels.[]', 'model._data.setType', function () {
      let panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];

      if (this.settings.getProp('beta.productSets')) {
        const setType = this.get('model._data.setType');

        if (setType === 'isSet' || _nventor.default.isNilOrEmpty(setType)) {
          panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
          panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        }

        if (setType === 'isSetWithChoices' || _nventor.default.isNilOrEmpty(setType)) {
          panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
          panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        }

        if (setType === 'hasVariants' || _nventor.default.isNilOrEmpty(setType)) {
          panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
          panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        }
      } else {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      return panels;
    }),
    isProductSetsReadonly: Ember.computed('isNew', 'isEditing', 'isReadonly', 'originalModel._data.setType', function () {
      if (this.isNew) {
        return false;
      }

      let isProductSetsReadonly = false; // option cannot be edited after saving

      if (this.isEditing) {
        isProductSetsReadonly = true;
      }

      if (!isProductSetsReadonly) {
        isProductSetsReadonly = this.isReadonly;
      }

      return isProductSetsReadonly;
    }),
    // displaySubPanel (resourceData) {
    //   const subTabOptions = {
    //     component: resourceData.getData('component'),
    //     value: resourceData.getData('value')
    //   }
    //   const tab = this.tab
    //   this.tabsManager.reuseSubTab(tab, subTabOptions)
    // },
    actions: {
      /**
       * sales tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      salesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const purchasesTaxKey = model.getData('purchasesTaxRateKey'); // manually set tax rate

        model.setData('salesTaxRate', taxRate);

        if (purchasesTaxKey == null || purchasesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('purchasesTaxRateLabel', taxModel.getData('label'));
          model.setData('purchasesTaxRateKey', taxModel.getData('_key')); // model.setData('purchasesTaxRate', taxRate);
        }
      },

      /**
       * purchases tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      purchasesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const salesTaxKey = model.getData('salesTaxRateKey'); // manually set tax rate

        model.setData('purchasesTaxRate', taxRate);

        if (salesTaxKey == null || salesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('salesTaxRateLabel', taxModel.getData('label'));
          model.setData('salesTaxRateKey', taxModel.getData('_key')); // model.setData('salesTaxRate', taxRate);
        }
      },

      setSetType(setType) {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
        const selectedSetType = setType.getData('value');
        model.setData(selectedSetType, true); // this.setEditorSubPanels()
      },

      deselectSetType() {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
      },

      updateVariantChildrenList(originalModel) {
        const model = this.model;
        return model.updateVariantChildrenList(originalModel);
      }

    }
  });

  _exports.default = _default;
});