define("client/pods/protected/company/route", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // inject ember intl service
    auth: Ember.inject.service(),
    queryParams: {
      reload: {
        refreshModel: true
      },
      lang: {
        refreshModel: true
      }
    },

    model(params) {
      const isAuth = this.auth.isAlreadyAuthenticated(params.company_name, params.user_id, params);
      return {
        isAuth,
        params
      };
    },

    afterModel(model) {
      const params = model.params || {};

      if (params.reload) {
        setTimeout(() => {
          _nventor.default.removeAndUpdateQueryParams(['reload']);

          window.location.reload();
        }, 500);
      }
    }

  });

  _exports.default = _default;
});