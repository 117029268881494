define("client/pods/components/workflows/related/editor/detail-searcher/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let WorkflowsRelatedEditorDetailSearcherComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('channels/shop/campaigns'), _dec3 = Ember.inject.service('contacts'), _dec4 = Ember.inject.service('documents'), _dec5 = Ember.inject.service('products'), _dec6 = Ember.inject.service('websites'), (_class = (_temp = class WorkflowsRelatedEditorDetailSearcherComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "contactsService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "documentsService", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "productsService", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "websitesService", _descriptor6, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documentsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "websitesService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkflowsRelatedEditorDetailSearcherComponent;
});