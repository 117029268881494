define("client/pods/grapesjs/plugins/double-row", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const pluginName = 'double-row';
    const {
      BlockManager
    } = editor;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl = {
        t: txt => txt
      },

      /** @type {Categories} */
      categories
    } = options;
    const blockId = `${pluginName}-block`;
    const blockLabel = intl.t(pluginName);
    BlockManager.add(blockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: 'row',
        components: [{
          type: 'cell',
          components: [{
            type: 'row'
          }, {
            type: 'row'
          }]
        }]
      },
      render: () => `
<svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;">
    <g transform="matrix(1.15939,0,0,1.15939,-76.9273,-146.697)">
        <g transform="matrix(0.987223,1.2326e-32,-6.16298e-33,0.839935,1.85777,232.008)">
            <rect x="196.848" y="321.016" width="631.615" height="327.658" style="fill:white;stroke:black;stroke-width:32.94px;"/>
        </g>
        <g transform="matrix(0.987223,1.2326e-32,-6.16298e-33,0.839935,1.85777,-43.2039)">
            <rect x="196.848" y="321.016" width="631.615" height="327.658" style="fill:white;stroke:black;stroke-width:32.94px;"/>
        </g>
        <g transform="matrix(9.35262e-17,1.5274,-1.5274,9.35262e-17,1251.14,-435.413)">
            <g transform="matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)">
                <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:18.07px;stroke-linejoin:round;"/>
            </g>
            <g transform="matrix(3.97777e-17,-0.649619,0.649619,3.97777e-17,272.62,652.086)">
                <path d="M243.134,868.614L243.134,813.425L188.356,813.425L188.356,790.36L243.134,790.36L243.134,735.583L266.473,735.583L266.473,790.36L321.25,790.36L321.25,813.425L266.473,813.425L266.473,868.614L243.134,868.614Z" style="fill:white;fill-rule:nonzero;stroke:white;stroke-width:30.42px;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:2;"/>
            </g>
        </g>
    </g>
</svg>
`
    });
  };

  _exports.default = _default;
});