define("client/pods/components/payments/virtual-accounts/history/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  // import * as R_ from 'ramda-extension'
  let PaymentsCardHistoryComponent = (_dec = Ember.inject.service('payments'), _dec2 = Ember._tracked, (_class = (_temp = class PaymentsCardHistoryComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "paymentsService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "doc", _descriptor2, this);
      this.setupTask.perform();
    }

    *setupTask() {
      const docKey = this.args.docKey;

      if (docKey) {
        const response = yield this.paymentsService.fetchHistory.perform(docKey);
        this.doc = response;
      } else {
        this.doc = this.args.model;
      }
    }

    get hasTransactions() {
      var _this$doc, _this$doc$_data, _this$doc2, _this$doc2$_data, _this$doc3, _this$doc3$_data;

      const payByLinkRequestsUnsuccessful = ((_this$doc = this.doc) === null || _this$doc === void 0 ? void 0 : (_this$doc$_data = _this$doc._data) === null || _this$doc$_data === void 0 ? void 0 : _this$doc$_data.payByLinkRequestsUnsuccessful) || [];
      const allRefundsTransactions = ((_this$doc2 = this.doc) === null || _this$doc2 === void 0 ? void 0 : (_this$doc2$_data = _this$doc2._data) === null || _this$doc2$_data === void 0 ? void 0 : _this$doc2$_data.allRefundsTransactions) || [];
      const inclTotal = (_this$doc3 = this.doc) === null || _this$doc3 === void 0 ? void 0 : (_this$doc3$_data = _this$doc3._data) === null || _this$doc3$_data === void 0 ? void 0 : _this$doc3$_data.inclTotal;

      if (!RA.isNilOrEmpty(allRefundsTransactions) || inclTotal || !RA.isNilOrEmpty(payByLinkRequestsUnsuccessful)) {
        return true;
      }

      return false;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paymentsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "doc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class));
  _exports.default = PaymentsCardHistoryComponent;
});