define("client/pods/components/channels/shop/rewards/rewards-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda-extension", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopRewardsRewardsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class ChannelsShopRewardsRewardsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor3, this);
      this.crud.addLists(this, ['channels/shop/rewards/lists/sub-panels-editor']);
    }

    afterLoadLists() {
      const tab = this.args.tab;
      const subPanels = R_.dotPathOr([], 'crud.lists.channels-shop-rewards-lists-sub-panels-editor')(this);

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel);
    }

    get isReadonly() {
      if (this.args.isEditing) {
        return false;
      }

      return true;
    }

    get isDisabled() {
      if (this.args.isEditing) {
        return false;
      }

      return true;
    }

    editPanel() {
      const activeSubTab = this.tabsManager.getActiveSubTab(this.args.tab);
      const tabOptions = {
        loadInstructions: {
          component: activeSubTab.get('component')
        }
      };
      this.args.onEdit(this.args.model, tabOptions);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "editPanel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "editPanel"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsRewardsEditorComponent;
});