define("client/pods/components/websites/panels-display/sub-panels-activity/sub-panels-activity-doc/component", ["exports", "client/mixins/doc-list-by", "client/mixins/crud", "client/mixins/doc-status", "ember-concurrency"], function (_exports, _docListBy, _crud, _docStatus, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, _docStatus.default, {
    crud: Ember.inject.service(),

    init() {
      this.crud.addLists(this, ['documents/lists/filters/search', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatchStatus', 'documents/lists/filters/sort', 'filters/count', 'filters/sortDirection']);

      this._super(...arguments);
    },

    adapterName: Ember.computed('tab.{docType,docName}', function () {
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');
      return `documents/${docType}/${docName}`;
    }),
    page: 1,
    count: 20,
    isSearching: false,

    afterLoadLists() {
      this._super(...arguments);

      const count = this.count;
      const model = this.model;
      const sourceKey = model.getData('_key');
      const filters = this.setupSearchFilters({
        match: ['all'],
        sourceKey,
        documentStatus: ['all'],
        dispatchStatus: ['all'],
        count,
        sort: {
          by: 'timestampZ',
          direction: 'DESC'
        }
      });
      this.set('filters', filters);
      this.fetchCampaignActivityTask.perform();
    },

    fetchCampaignActivityTask: (0, _emberConcurrency.task)(function* ({
      match,
      documentStatus,
      dispatchStatus,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      isVoid
    } = {}, onHideDropdown) {
      const sourceKey = this.model.getData('_key');
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');
      const adapterName = `documents/${docType}/${docName}`;
      yield this.listByTask.perform({
        adapterName,
        match,
        notDispatchStatus,
        query,
        isVoid,
        sourceKey,
        documentStatus,
        dispatchStatus,
        date,
        dateStart,
        dateEnd,
        resultsProperty: 'listByResults'
      });

      if (onHideDropdown) {
        onHideDropdown();
      }
    }),
    searchCampaignTask: (0, _emberConcurrency.task)(function* (query) {
      const filters = this.filters;
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');

      if (query === '') {
        return yield this.fetchCampaignActivityTask.perform();
      }

      return yield this.searchInputTask.perform({
        adapterName: `documents/${docType}/${docName}`,
        resultsToggle: '',
        resultsProperty: 'listByResults',
        filters
      }, query) || [];
    }),
    allActivity: Ember.computed('listByResults.[]', function () {
      // const isSearching = this.get('isSearching')
      // if (isSearching) {
      const searchResults = this.listByResults || [];

      if (!Ember.isEmpty(searchResults)) {
        return searchResults;
      }

      return []; // }
      // return this.get('listByResults') || []
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.fetchCampaignActivityTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});