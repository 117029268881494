define("client/pods/components/documentations/documentation-icon/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  let DocumentationIconComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class DocumentationIconComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "users", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "displayModal", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "tags", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isEditing", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isNew", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "displayMoreToggle", _descriptor8, this);
      (0, _defineProperty2.default)(this, "adapterName", 'documentation');
    }

    get iconVisible() {
      /**
       * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_Operators
       * */
      if (!this.args.documentationId) {
        return false;
      }

      if (!this.args.documentation) {
        return false;
      }

      return !!(this.args.documentation.isActive && this.documentHasTemplate || this.allowedEdit);
    }

    get documentHasTemplate() {
      const documentation = this.args.documentation;
      return !_nventor.default.isNilOrEmpty(R.path(['_data', 'template'])(documentation));
    }

    get allowedEdit() {
      const isAdmin = this.users.getProp('isAdmin');
      const isEditor = this.users.getProp('isEditor');
      return !!(isAdmin || isEditor);
    }

    showInfo() {
      this.displayModal = !this.displayModal;
    }

    setupNewRecord() {
      this.dirty = this.crud.setupNewRecord({
        adapterName: this.adapterName,
        data: {
          documentationId: this.args.documentationId
        }
      });
      this.isEditing = true;
      this.isNew = true;
    }

    setupInnerEditing() {
      this.dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model: this.args.documentation
      });
      this.isEditing = true;
    }

    updateModel(model) {
      this.args.component.updateDocumentation(model);
      this.cancel();
    }

    cancel() {
      this.dirty = null;
      this.isNew = false;
    }

    delete() {
      this.cancel();
      this.args.component.deleteDocumentation();
    }

    toggleDisplayMore() {
      this.displayMoreToggle = !this.displayMoreToggle;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "users", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tags", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isEditing", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isNew", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayMoreToggle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showInfo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "showInfo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupNewRecord", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupNewRecord"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupInnerEditing", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setupInnerEditing"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateModel", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateModel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleDisplayMore", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDisplayMore"), _class.prototype)), _class));
  _exports.default = DocumentationIconComponent;
});