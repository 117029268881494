define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "guests", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "big", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "commissionBaseCostModel", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "original", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsDetails", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "selectedCommissionBaseCostMethod", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "commissionBaseCostFilters", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "isMethodSelectorDisabled", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "selectedCommissionBaseCostMethodModel", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "isUsingSetChild", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "isCalculatingCommissionsByExclAmt", _descriptor16, this);
      const model = this.args.model;
      const master = model.getData('_key');
      const commissionBaseCostFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/preset-commissions/filters',
        defaultFiltersData: {
          master
        }
      }); // commissionBaseCostFilters.setData('count', 20)
      // commissionBaseCostFilters.setData('isForCommission', true)

      Ember.set(this, 'commissionBaseCostFilters', commissionBaseCostFilters);
      this.crud.addLists(this, ['channels/shop/campaigns/commissions-adjustment', 'channels/shop/campaigns/lists/commission-types']);
    }

    *getSelectedCommissionBaseCostMethodTask() {
      const model = this.args.model;
      const campaignKey = model.getData('_key');
      const data = {
        campaignKey
      };
      const result = yield this.server.call('GET', 'api/protected/channels/shop/campaigns/details/commission-base-cost-method', data);
      const selectedCommissionBaseCostMethod = R.pathOr('', ['data', 'selectedCommissionBaseCostMethod'], result);
      const isUsingSetChild = R.pathOr('', ['data', 'isUsingSetChild'], result);
      const isCalculatingCommissionsByExclAmt = R.pathOr('', ['data', 'isCalculatingCommissionsByExclAmt'], result);

      if (RA.isNotNilOrEmpty(selectedCommissionBaseCostMethod)) {
        this.selectedCommissionBaseCostMethod = selectedCommissionBaseCostMethod;
        this.isMethodSelectorDisabled = true;

        if (RA.isNotNilOrEmpty(isUsingSetChild)) {
          this.isUsingSetChild = isUsingSetChild;
        }

        if (RA.isNotNilOrEmpty(isCalculatingCommissionsByExclAmt)) {
          this.isCalculatingCommissionsByExclAmt = isCalculatingCommissionsByExclAmt;
        }

        yield this.fetchCampaignDetailsTask.perform();
      }

      this.selectedCommissionBaseCostMethodModel = R.propOr({}, 'data', result);
    }

    *fetchCampaignDetailsTask() {
      const isUsingSetChild = this.isUsingSetChild;
      this.commissionBaseCostFilters.setData('isUsingSetChild', isUsingSetChild);

      if (this.args.isUsingAverage) {
        const model = this.args.model;
        const master = model.getData('_key');
        const results = yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details/preset-commissions',
          appendPath: `/master/${master}`,
          filters: this.commissionBaseCostFilters,
          resultsProperty: 'results'
        });
        Ember.set(this, 'commissionBaseCostFilters.results', results);
      } else {
        yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          filters: this.commissionBaseCostFilters,
          resultsProperty: 'results'
        });
      } // const selectedCommissionBaseCostMethod = R.pipe(
      //   R.pathOr([], ['commissionBaseCostFilters', 'results']),
      //   R.head,
      //   R.pathOr('', ['commissionBaseCostNew', '_data', 'selectedCommissionBaseCostMethod'])
      // )(this)
      // if (RA.isNotNilOrEmpty(selectedCommissionBaseCostMethod)) {
      //   this.selectedCommissionBaseCostMethod = selectedCommissionBaseCostMethod
      //   this.isMethodSelectorDisabled = true
      // }


      yield this.createDirtyTask.perform();
    }

    *saveSelectedCommissionBaseCostMethodTask({
      selectedCommissionBaseCostMethod
    }) {
      const model = this.args.model;
      const campaignKey = model.getData('_key');
      const selectedCommissionBaseCostMethodModel = this.selectedCommissionBaseCostMethodModel;
      const isUsingSetChild = this.isUsingSetChild;
      const isCalculatingCommissionsByExclAmt = this.isCalculatingCommissionsByExclAmt;

      const _key = R.propOr('', '_key', selectedCommissionBaseCostMethodModel);

      const _rev = R.propOr('', '_rev', selectedCommissionBaseCostMethodModel);

      const data = {
        _key,
        _rev,
        campaignKey,
        selectedCommissionBaseCostMethod,
        isUsingSetChild,
        isCalculatingCommissionsByExclAmt
      };

      if (RA.isNotNilOrEmpty(_key)) {
        yield this.server.call('PATCH', 'api/protected/channels/shop/campaigns/details/commission-base-cost-method', data);
      } else {
        yield this.server.call('POST', 'api/protected/channels/shop/campaigns/details/commission-base-cost-method', data);
      }

      yield this.getSelectedCommissionBaseCostMethodTask.perform();
    }

    *createDirtyTask() {
      const results = R.pathOr([], ['commissionBaseCostFilters', 'results'])(this);
      const dirtyResults = R.map(model => {
        const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', model);
        this.commissionBaseCostNew = commissionBaseCostNew;

        const _key = R.pathOr('', ['_data', '_key'], commissionBaseCostNew);

        let isNew = false;

        if (RA.isNilOrEmpty(_key)) {
          isNew = true;
        }

        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          model: commissionBaseCostNew,
          isNew
        });
        model.set('commissionBaseCostNew', dirty);
        return model;
      })(results);
      Ember.set(this, 'commissionBaseCostFilters.results', dirtyResults);
    }

    *resetPresetCommissionsTask() {
      const model = this.args.model;
      const master = model.getData('_key');
      const data = {
        master,
        isForCommission: true,
        isUsingSetChild: this.isUsingSetChild,
        page: 1,
        count: 1000
      };
      yield this.server.call('PATCH', 'api/protected/channels/shop/campaigns/details/commission-base-cost/reset', data);
      this.isMethodSelectorDisabled = false;
      Ember.set(this, 'commissionBaseCostFilters.results', []);
      Ember.set(this, 'commissionBaseCostFilters.page', 1);
    }

    cancelAllModels(dirty, onCloseDialogAction) {
      R.forEach(model => {
        this.crud.cancelEditing({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          dirty: model.commissionBaseCostNew
        });
      })(dirty);
      this.isUsingSetChild = true;
      this.isCalculatingCommissionsByExclAmt = true;

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "guests", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "big", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commissionBaseCostModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "original", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsDetails", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCommissionBaseCostMethod", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'normalPercent';
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commissionBaseCostFilters", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isMethodSelectorDisabled", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCommissionBaseCostMethodModel", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isUsingSetChild", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCalculatingCommissionsByExclAmt", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getSelectedCommissionBaseCostMethodTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getSelectedCommissionBaseCostMethodTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchCampaignDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveSelectedCommissionBaseCostMethodTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveSelectedCommissionBaseCostMethodTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createDirtyTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createDirtyTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resetPresetCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resetPresetCommissionsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelAllModels", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAllModels"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor;
});