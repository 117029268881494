define("client/pods/components/channels/shop/promo-codes/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UlJbCUv6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"layouts/table-view\",null,[[\"tab\",\"tableClassNames\",\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onEdit\"],[[24,[\"tab\"]],\"promo-codes-dash\",\"channels/shop/promo-codes/table-view/detail-row\",[24,[\"results\"]],[24,[\"filters\"]],[24,[\"onSearchTask\"]],[24,[\"onReloadSearchRecordsTask\"]],[24,[\"onEdit\"]]]]],false],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[4,\"layouts/footer-btns\",null,null,{\"statements\":[[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-left\"]],{\"statements\":[[0,\"        \"],[1,[28,\"helpers/pagination-controls\",null,[[\"filters\",\"list\",\"showPage\",\"onPaginationTask\"],[[24,[\"filters\"]],[24,[\"results\"]],true,[24,[\"onPaginationTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/promo-codes/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});