define("client/pods/components/documents/document-print/sub-header-references/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showFromPerson: false,
    showSubHeader: Ember.computed('model._data.{ref,paymentMethodKey}', 'showFromPerson', function () {
      const ref = this.get('model._data.ref');
      const paymentMethodKey = this.get('model._data.paymentMethodKey');
      const showFromPerson = this.showFromPerson;

      if (ref) {
        return true;
      }

      if (paymentMethodKey) {
        return true;
      }

      if (showFromPerson) {
        return true;
      }
    })
  });

  _exports.default = _default;
});