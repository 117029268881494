define("client/pods/components/user-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    auth: Ember.inject.service(),
    updates: Ember.inject.service(),
    username: '',
    password: '',

    init() {
      this.crud.addLists(this, ['languages']);

      this._super(...arguments);
    },

    actions: {
      async login() {
        const credentials = {
          username: this.username,
          password: this.password
        };
        await this.auth.get('loginTask').perform(credentials);
      },

      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }

    }
  });

  _exports.default = _default;
});