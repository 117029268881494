define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/variants-detail/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    tagName: ''
  });

  _exports.default = _default;
});