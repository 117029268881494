define("client/pods/components/reports/dashboard/card-revenue-by-group/card-detail/component", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isDate: false,
    roundingCurrency: 0,

    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },

    averageItemPrice: Ember.computed('detail.average.{exclPrice,inclPrice,postDiscExclAmt,postDiscInclAmt}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclPrice', 'postDiscInclPrice');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclPrice', 'inclPrice');
      }

      return value;
    }),
    averageOrderValue: Ember.computed('detail.average.{exclTotal,inclTotal,postDiscExclTotal,postDiscInclTotal}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclTotal', 'postDiscInclTotal');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclTotal', 'inclTotal');
      }

      return value;
    }),
    amount: Ember.computed('detail.{exclAmt,inclAmt,postDiscExclAmt,postDiscInclAmt}', 'isTaxExclusive', function () {
      let value = this.getValue('detail', 'postDiscExclAmt', 'postDiscInclAmt');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail', 'exclAmt', 'inclAmt');
      }

      return value;
    })
  });

  _exports.default = _default;
});