define("client/pods/components/open-hours/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uOMnv9fQ",
    "block": "{\"symbols\":[\"day\",\"@isReadonly\",\"@model\",\"@allowCopyTime\"],\"statements\":[[4,\"each\",[[23,0,[\"weekdays\"]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@errors\",\"@label\"],[\"companyOpeningHours\",\"settings\",[23,0,[\"errors\"]],[29,[[23,1,[]],\" opening hours\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"lists/list-dropdown\",[],[[\"@classNames\",\"@disabled\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@onSelect\",\"@translate\",\"@value\"],[\"date-open-hours\",[23,2,[]],true,\"_data.label\",\"_data.value\",[23,0,[\"crud\",\"lists\",\"date-lists-hours\"]],[28,\"fn\",[[23,0,[\"updateModel\"]],[23,1,[]],\"open\"],null],false,[28,\"get-dot-path\",[\"_data.openHours\",[23,1,[]],\"open\",[23,3,[]]],null]]]],[0,\"\\n    -\\n    \"],[5,\"lists/list-dropdown\",[],[[\"@classNames\",\"@disabled\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@onSelect\",\"@translate\",\"@value\"],[\"date-open-hours\",[28,\"if\",[[28,\"or\",[[28,\"get-dot-path\",[\"openDays\",[23,1,[]],\"hasOpeningTime\",[23,3,[]]],null],[23,2,[]]],null],true,false],null],true,\"_data.label\",\"_data.value\",[23,0,[\"crud\",\"lists\",\"date-lists-hours\"]],[28,\"fn\",[[23,0,[\"updateModel\"]],[23,1,[]],\"close\"],null],false,[28,\"get-dot-path\",[\"_data.openHours\",[23,1,[]],\"close\",[23,3,[]]],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[4,\"unless\",[[28,\"eq\",[[23,1,[]],\"monday\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"elements/element-btn\",[],[[\"@disabled\",\"@icon\",\"@onSubmit\",\"@tooltipLabel\"],[[23,2,[]],\"far fa-copy\",[28,\"fn\",[[23,0,[\"copyOpeningHours\"]],[23,1,[]]],null],\"copy from above\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/open-hours/template.hbs"
    }
  });

  _exports.default = _default;
});