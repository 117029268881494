define("client/pods/components/menus/nav-tree/nav-nested/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8L6UjW7",
    "block": "{\"symbols\":[\"sub\"],\"statements\":[[1,[28,\"menus/nav-tree/nav-item\",null,[[\"item\",\"classNames\",\"labelKey\",\"onSelect\"],[[24,[\"item\"]],\"nav-tree__item-link\",[24,[\"labelKey\"]],[28,\"action\",[[23,0,[]],[24,[\"onExpand\"]],[23,0,[]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isExpanded\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"sub\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"menus/nav-tree\",null,[[\"classNames\",\"sub\",\"labelKey\",\"onSelect\",\"onExpand\"],[\"nav-tree__children-container\",[23,0,[\"sub\"]],[24,[\"labelKey\"]],[24,[\"onSelect\"]],[24,[\"onExpand\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"groups\"]]],null,{\"statements\":[[4,\"each\",[[23,0,[\"groups\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"nav-tree__group-container\"],[8],[0,\"\\n        \"],[1,[28,\"menus/nav-tree\",null,[[\"classNames\",\"sub\",\"labelKey\",\"onSelect\",\"onExpand\"],[\"nav-tree__children-container\",[23,1,[]],[24,[\"labelKey\"]],[24,[\"onSelect\"]],[24,[\"onExpand\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-tree/nav-nested/template.hbs"
    }
  });

  _exports.default = _default;
});