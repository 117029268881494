define("client/pods/extensions/apps/tw/e-invoices/tracks/adapter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/apps/tw/e-invoices/tracks/model", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let TrackModel = (_dec = Ember._tracked, (_class = (_temp = class TrackModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/extensions/apps/tw/e-invoices/tracks',
    modelObj: TrackModel,
    idParam: '_id',

    issue({
      payload
    }) {
      const path = this.getPath('resourcePath');
      return this.server.call('POST', path, payload).then(R.propOr([], 'data'));
    },

    issueBatch({
      payload
    }) {
      const path = `${this.getPath('resourcePath')}/batch`;
      return this.server.call('POST', path, payload).then(R.propOr([], 'data'));
    }

  });

  _exports.default = _default;
});