define("client/pods/comments/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let CommentsService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = (_temp = class CommentsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "queue", _descriptor2, this);
    }

    qFetch(data, cb) {
      this.queue.pushObject({
        data,
        cb
      });
      const count = this.queue.length;
      setTimeout(() => {
        if (count > 0 && count === this.queue.length) {
          return this.fetchAllCommentsTask.perform(this.queue, count);
        }
      }, 200);
    }

    *fetchAllCommentsTask(batch, count) {
      const commentsCollection = yield this.crud.searchRecordsTask.perform({
        method: 'POST',
        adapterName: 'comments/collection',
        appendPath: '/batch/master',
        data: {
          batch: R.pluck('data')(batch)
        }
      });
      this.queue = R.drop(count)(this.queue);
      const cbs = R.pluck('cb')(batch);
      R.forEach(cb => {
        cb(commentsCollection);
      })(cbs);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "queue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchAllCommentsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAllCommentsTask"), _class.prototype)), _class));
  _exports.default = CommentsService;
});