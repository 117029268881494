define("client/pods/components/channels/shop/campaigns/panels-schedule/component", ["exports", "ember-concurrency", "client/mixins/date", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by"], function (_exports, _emberConcurrency, _date, _campaignsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_campaignsListBy.default, _date.default, {
    filtersAdapterName: 'channels/shop/campaigns/filters',

    init() {
      this._super(...arguments); // fetch all campaigns for month


      const current = this.getPeriod();
      this.set('current', current);
      const dateStart = current.monthStart;
      const dateEnd = current.monthEnd;
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          count: 1000
        }
      });
      this.set('filters', filters);
      this.fetchScheduleTask.perform({
        dateStart,
        dateEnd
      });
      this.tabsManager.setHeader(this.tabParent, `${dateStart}-${dateEnd}`);
    },

    fetchScheduleTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    }) {
      const filters = this.filters;
      const results = yield this.listByTask.perform({
        adapterName: 'channels/shop/campaigns',
        filters,
        dateStart: dateStart,
        dateEnd: dateEnd
      });
      this.set('results', results);
    }),
    actions: {
      previousMonth(previous) {
        this.fetchScheduleTask.perform({
          dateStart: previous.monthStart,
          dateEnd: previous.monthEnd
        });
      },

      nextMonth(next) {
        this.fetchScheduleTask.perform({
          dateStart: next.monthStart,
          dateEnd: next.monthEnd
        });
      }

    }
  });

  _exports.default = _default;
});