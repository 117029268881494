define("client/pods/components/menus/nav-tree/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-tree'],
    translate: true,
    menu: Ember.computed('list.[]', 'sub.[]', function () {
      const sub = this.sub || [];

      if (!R.isEmpty(sub)) {
        return sub;
      }

      const list = this.get('list', []);
      return list.map(function (item) {
        if (item._data) {
          return item._data;
        }

        return item;
      });
    }),
    actions: {
      toggleSubMenu(component) {
        component.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});