define("client/pods/components/blogs/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qS/ZGRb3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content flex-container-row flex-container--wrap flex-container--justify-center\"],[8],[0,\"\\n\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@lists\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@onReloadSearchRecordsTask\",\"@onSearchTask\",\"@results\",\"@list\",\"@onPaginationTask\",\"@tableViewOptions\"],[\"blogs/table-view/detail-row\",[23,0,[\"filters\"]],[23,0,[\"crud\",\"lists\"]],[22,\"onDisplay\"],[22,\"onEdit\"],[22,\"onDisplay\"],[23,0,[\"fetchBlogsTask\"]],[23,0,[\"fetchBlogsTask\"]],[22,\"results\"],[22,\"results\"],[22,\"onPaginationTask\"],[28,\"hash\",null,[[\"adapterName\",\"onMainActionIcon\",\"onMainActionLabel\",\"onRemove\",\"tab\"],[[23,0,[\"adapterName\"]],\"fas fa-toilet-paper\",\"select\",[24,[\"onRemove\"]],[24,[\"tab\"]]]]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[4,\"layouts/footer-btns\",null,null,{\"statements\":[[0,\"      \"],[1,[28,\"helpers/pagination-controls\",null,[[\"showPage\",\"tagName\",\"filters\",\"list\",\"onPaginationTask\"],[true,\"span\",[24,[\"filters\"]],[24,[\"results\"]],[24,[\"onPaginationTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});