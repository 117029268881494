define("client/pods/channels/shop/rewards/member-levels/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        name: '',
        description: '',
        requiredPoints: '',
        type: '',
        status: _constants.default.status.active,
        expiryTime: 1,
        expiryPeriod: _constants.default.timePeriods.years,
        isNeverExpire: false
      };
    },

    populate(data, attrs) {
      data.rewardsEvents = this.transformRewardEvents(data);

      this._super(data, attrs);
    },

    transformRewardEvents(data) {
      const rewardsEvents = R.propOr([], 'rewardsEvents')(data);
      return R.map(rewardEvent => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels/rewards-events',
          data: rewardEvent
        });
      })(rewardsEvents);
    },

    addRewardEvent(data) {
      const rewardsEvents = this.getData('rewardsEvents') || [];
      const rewardEventModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards/member-levels/rewards-events',
        data
      });
      rewardsEvents.pushObject(rewardEventModel);
      this.setData('rewardsEvents', rewardsEvents);
    }

  });

  _exports.default = _default;
});