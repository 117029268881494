define("client/pods/components/products/products-editor/sets-with-choices-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nt/yNMp2",
    "block": "{\"symbols\":[\"choice\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"model\",\"_data\",\"choices\"]]],null,{\"statements\":[[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"products/products-editor/sets-with-choices-editor/choices-editor\",null,[[\"tab\",\"errors\",\"errorIndex\",\"model\",\"onRemoveChoice\",\"choice\",\"isEditing\",\"isNew\",\"isReadonly\"],[[24,[\"tab\"]],[24,[\"errors\"]],[23,2,[]],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"removeChoice\",[24,[\"model\"]],[23,1,[]]],null],[23,1,[]],[24,[\"isEditing\"]],[24,[\"isNew\"]],[24,[\"isReadonlyAndAvailable\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\\t\"],[1,[28,\"elements/element-btn\",null,[[\"icon\",\"disabled\",\"onSubmit\"],[\"fas fa-plus\",[24,[\"isReadonlyAndAvailable\"]],[28,\"action\",[[23,0,[]],\"addChoice\",[24,[\"model\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"context\"],[[24,[\"errors\"]],\"choices\"]]],false],[0,\"\\n\\t\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/sets-with-choices-editor/template.hbs"
    }
  });

  _exports.default = _default;
});