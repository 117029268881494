define("client/pods/drag-and-drop/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let DragAndDropService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class DragAndDropService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "draggedIndex", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "toDropPosition", _descriptor3, this);
    }

    *updateRecordsTask() {
      return yield this.crud.updateRecordTask.perform(...arguments);
    }

    onDragStart(draggedIndex, data = {}) {
      this.draggedIndex = draggedIndex;
      this.draggedData = data;
    }

    onDragOver(hoveredIndex) {
      if (this.draggedIndex === hoveredIndex) {
        this.toDropPosition = '';
      } else if (this.draggedIndex < hoveredIndex) {
        this.toDropPosition = 'drop-container--below';
      } else {
        this.toDropPosition = 'drop-container--above';
      }
    }

    onDragOverCell(hoveredIndex) {
      if (this.draggedIndex === hoveredIndex) {
        this.toDropPosition = '';
        return this.toDropPosition;
      }

      if (this.draggedIndex < hoveredIndex) {
        this.toDropPosition = 'drop-container--right';
        return this.toDropPosition;
      }

      if (this.draggedIndex > hoveredIndex) {
        this.toDropPosition = 'drop-container--left';
        return this.toDropPosition;
      }
    }

    onDrop(droppedAt, results, outerDrop, draggedFrom) {
      const modelsIndexStart = R.view(R.lensPath([0, 'index']))(results);
      const fromModel = R.find(R.propEq('index', draggedFrom))(results);
      const droppedAtIndex = R.findIndex(R.propEq('index', droppedAt))(results);
      const fromModelIndex = R.findIndex(R.propEq('index', draggedFrom))(results);
      results.removeAt(fromModelIndex);
      results.insertAt(droppedAtIndex, fromModel);
      R.forEachObjIndexed((m, index) => {
        m.set('_data.index', parseInt(index) + modelsIndexStart);
      })(results);

      if (outerDrop) {
        outerDrop(fromModelIndex, droppedAtIndex, results);
      }
    }

    onDropWithIndex(droppedAt, results, indexDotPath = '_data.index', outerDrop, draggedFrom) {
      const modelsIndexStart = R_.dotPathOr(0, `${0}.${indexDotPath}`)(results);
      const fromModel = R.find(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      const droppedAtIndex = R.findIndex(R_.dotPathEq(indexDotPath, droppedAt))(results);
      const fromModelIndex = R.findIndex(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      results.removeAt(fromModelIndex);
      results.insertAt(droppedAtIndex, fromModel);
      R.forEachObjIndexed((m, index) => {
        m.set(indexDotPath, parseInt(index) + modelsIndexStart);
      })(results);

      if (outerDrop) {
        outerDrop(fromModelIndex, droppedAtIndex, results);
      }
    }

    onDropCell(droppedIndex, dirtyModel, originalIndex) {
      if (!R.equals(droppedIndex, originalIndex)) {
        const specs = R.pathOr(null, ['_data', 'specs'])(dirtyModel);

        if (R.equals(droppedIndex.parentIndex, originalIndex.parentIndex)) {
          let specRow = specs.get(`${droppedIndex.parentIndex}`);
          const movedItem = specRow.find(spec => spec === specRow[originalIndex.childIndex]);
          const remainingItems = specRow.filter(spec => spec !== specRow[originalIndex.childIndex]);
          specRow = R.flatten([RA.list(RA.sliceTo(droppedIndex.childIndex, remainingItems)), RA.list(movedItem), RA.list(RA.sliceFrom(droppedIndex.childIndex, remainingItems))]);
          specs.set(`${droppedIndex.parentIndex}`, specRow);
          dirtyModel.setData('specs', specs);
        } else {
          let specRowOfOriginalCell = specs.get(`${originalIndex.parentIndex}`);
          const movedItem = specRowOfOriginalCell.find(spec => spec === specRowOfOriginalCell[originalIndex.childIndex]); // for row that is left by original cell

          specRowOfOriginalCell = specRowOfOriginalCell.filter(spec => spec !== specRowOfOriginalCell[originalIndex.childIndex]);
          specs.set(`${originalIndex.parentIndex}`, specRowOfOriginalCell); // new row of original cell

          let specRowOfDestination = specs.get(`${droppedIndex.parentIndex}`);
          specRowOfDestination = R.flatten([RA.list(RA.sliceTo(droppedIndex.childIndex, specRowOfDestination)), RA.list(movedItem), RA.list(RA.sliceFrom(droppedIndex.childIndex, specRowOfDestination))]);
          specs.set(`${droppedIndex.parentIndex}`, specRowOfDestination);
          dirtyModel.setData('specs', specs);
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "draggedIndex", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toDropPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateRecordsTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecordsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDragStart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDragStart"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDragOver", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOver"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDragOverCell", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOverCell"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDrop", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDropWithIndex", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onDropWithIndex"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDropCell", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDropCell"), _class.prototype)), _class));
  _exports.default = DragAndDropService;
});