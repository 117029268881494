define("client/pods/components/products/price-selectors/price-selector-campaigns-automations/component", ["exports", "ramda", "ember-concurrency"], function (_exports, R, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    isReadonly: false,
    fetchHistoryOnInitTask: (0, _emberConcurrency.task)(function* () {
      const product = this.product;
      const itemKey = this.itemKey;
      const onFetchHistoryTask = this.onFetchHistoryTask;
      const {
        priceHistoryData
      } = yield onFetchHistoryTask(product, itemKey);
      this.set('priceHistoryData', priceHistoryData);
    }),
    sortedData: Ember.computed('priceHistoryData.[]', function () {
      let forAutomations = this.productsService.getFor('automations', this.priceHistoryData); // remove itself so it wouldn't show as history price

      forAutomations = R.reject(automation => R.propEq('_key', this.campaignDetailKey, automation), forAutomations);
      const forDocuments = this.productsService.getFor('documents', this.priceHistoryData);
      return R.concat(forAutomations, forDocuments);
    }),
    isDisabled: Ember.computed('isReadonly', 'itemKey', function () {
      if (!this.itemKey) {
        return true;
      }

      return this.isReadonly;
    }),
    actions: {
      select(history, onHideDropdown) {
        this.onSelect(history, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});