define("client/pods/components/modals/modal-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQeqshrj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"tab-grid__modal-overlay modal-overlay \",[22,\"modalClassNames\"]]]],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});