define("client/pods/components/products/products-editor/template/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let ProductsProductsEditorTemplateComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class ProductsProductsEditorTemplateComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
    }

    updateContent(template) {
      var _this$args, _this$args$model;

      this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$model = _this$args.model) === null || _this$args$model === void 0 ? void 0 : _this$args$model.setData('template', template);
    }

    removeTemplate() {
      try {
        var _this$args$parentMode;

        const templateModel = this.args.model;
        (_this$args$parentMode = this.args.parentModel) === null || _this$args$parentMode === void 0 ? void 0 : _this$args$parentMode.removeTemplate(templateModel);
        this.args.reselectFirst();
      } catch (e) {
        console.error(e);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateContent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateContent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeTemplate"), _class.prototype)), _class));
  _exports.default = ProductsProductsEditorTemplateComponent;
});