define("client/pods/grapesjs/plugins/image", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global grapesjs */
  const pluginName = 'optimized-image';
  const Plugin = grapesjs.plugins.add(pluginName, (editor, options = {}) => {
    const components = editor.DomComponents;
    const styles = `
    <style>
    .optimized-image {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    </style>
  `;
    const defaultOptions = {
      extend: 'image',
      model: {
        defaults: {
          classes: [pluginName],
          components: [styles],
          traits: []
        }
      },
      view: {
        onRender({
          model
          /* , editor, el */

        }) {
          // add id as class name. so, style can be added to a specific image
          model.addClass(model.getId()); // append default style

          model.append(styles);
        }

      }
    };
    options = R.mergeDeepRight(defaultOptions, options);
    components.addType(pluginName, options);
  });
  var _default = Plugin;
  _exports.default = _default;
});