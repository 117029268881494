define("client/pods/components/lists/list-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBIUOGpJ",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"each\",[[24,[\"tagged\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"lists/list-option\",null,[[\"translate\",\"selected\",\"optionValueKey\",\"optionLabelKey\",\"isDefaultKey\",\"classNames\",\"isTag\",\"isSelected\",\"prefix\",\"suffix\",\"option\",\"isReadonly\",\"allowDeselect\",\"onDeselectTagItem\"],[[24,[\"translate\"]],[24,[\"selected\"]],[24,[\"optionValueKey\"]],[24,[\"optionLabelKey\"]],[24,[\"isDefaultKey\"]],[23,1,[\"_data\",\"classNames\"]],true,true,[24,[\"prefix\"]],[24,[\"suffix\"]],[23,1,[]],[24,[\"isReadonly\"]],[24,[\"allowDeselect\"]],[28,\"action\",[[23,0,[]],\"onDeselectTagItem\",[23,1,[]]],null]]]],false],[0,\"\\n  \"],[1,[23,1,[\"classNames\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/lists/list-tags/template.hbs"
    }
  });

  _exports.default = _default;
});