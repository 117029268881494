define("client/pods/components/elements/element-dropdown-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['list-btn'],
    hasDropdown: true,
    showDropdown: false,
    onSubmit: 'defaultToggleDropdown',
    // can supply custom function to search on onSubmit action
    readonly: false,
    translate: false,
    value: '',
    // list: [],
    resultsComponent: 'lists/list-results',
    // component to display results (defaults to list-results)

    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list
     */
    isDropdownVisible: Ember.computed('showDropdown', function () {
      if (this.readonly === false && this.showDropdown) {
        return true;
      }

      return false;
    }),
    actions: {
      defaultToggleDropdown() {// @TODO: should remove this
        // this.set('showDropdown', true);
      },

      hideDropdown() {
        this.set('showDropdown', false);
      },

      showDropdown() {
        this.set('showDropdown', true);
      }

    }
  });

  _exports.default = _default;
});