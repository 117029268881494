define("client/pods/components/contacts/panels-duplicates/row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ContactsPanelsDuplicatesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = class ContactsPanelsDuplicatesComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "mergedData", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "mergedIntoKey", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "mergePreviewModel", _descriptor5, this);
    }

    *fetchMergePreviewModelTask(duplicateData) {
      const mergePreviewData = yield this.dataManager.ajaxCall('GET', 'contacts', '/duplicates/merge', {
        email: duplicateData.email
      });
      const previewModelData = (mergePreviewData === null || mergePreviewData === void 0 ? void 0 : mergePreviewData.modelPreview) || {};
      this.mergePreviewModel = this.dataManager.setAsRecord({
        adapterName: 'contacts/preview',
        data: previewModelData
      });
    }

    *mergeContactsTask(duplicateData, onCloseDialogAction) {
      const msg = this.intl.t('are you sure you want to merge contacts');

      if (window.confirm(msg)) {
        var _this$mergedData, _this$mergedData$mode;

        this.mergedData = yield this.dataManager.ajaxCall('PATCH', 'contacts', '/duplicates/merge', {
          email: duplicateData.email
        });
        this.mergedIntoKey = (_this$mergedData = this.mergedData) === null || _this$mergedData === void 0 ? void 0 : (_this$mergedData$mode = _this$mergedData.model) === null || _this$mergedData$mode === void 0 ? void 0 : _this$mergedData$mode._key;
        onCloseDialogAction();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mergedData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mergedIntoKey", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mergePreviewModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchMergePreviewModelTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchMergePreviewModelTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mergeContactsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "mergeContactsTask"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDuplicatesComponent;
});