define("client/pods/channels/shop/campaigns/details/filters/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/pods/filters/model", "client/utils/nventor", "client/pods/filters/sort/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  const ChannelsShopCampaignsDetailsFiltersBaseModel = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        status: [],
        sort: [_nventor.default.createModel(_model2.default, {
          by: 'index',
          direction: 'ASC'
        })]
      };
    }

  });

  let ChannelsShopCampaignsDetailsFiltersModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = (_temp = class ChannelsShopCampaignsDetailsFiltersModel extends ChannelsShopCampaignsDetailsFiltersBaseModel {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor2, this);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopCampaignsDetailsFiltersModel;
});