define("client/pods/components/ledgers/journals/cashbook/cashbook-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  let LedgersJournalsCashbookCashbookEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, (_class = (_temp = class LedgersJournalsCashbookCashbookEditor extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "txType", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "contactsList", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "docType", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "accountKey", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "selectedBankAccount", _descriptor12, this);
      (0, _defineProperty2.default)(this, "adapterName", 'ledgers/journals/cashbook');
      this.txFilters = this.search.setupFilters({
        adapterName: 'ledgers/journals/cashbook/filters'
      });
    }

    *setupTask() {
      var _this$args, _this$args$tab, _this$args2, _this$args3, _this$args3$tab;

      const txType = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$tab = _this$args.tab) === null || _this$args$tab === void 0 ? void 0 : _this$args$tab.txType) || '';
      const accountKey = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.accountKey) || '';
      const txFilters = this.txFilters;

      if (txType !== 'overview') {
        txFilters.setData('txType', txType);
      }

      yield this.fetchAccountTask.perform({
        accountKey,
        txType,
        txFilters
      });
      const banksList = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      const account = banksList.findBy('_data._key', accountKey);
      this.account = account;
      this.contactsLists = [];
      this.txType = txType;
      this.accountKey = accountKey;
      this.docType = (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$tab = _this$args3.tab) === null || _this$args3$tab === void 0 ? void 0 : _this$args3$tab.docType) || '';
    }

    *fetchStatementTask({
      key,
      docType,
      dateStart,
      dateEnd
    }) {
      const params = {
        key,
        docType,
        dateStart,
        dateEnd
      };
      const result = yield this.crud.findTask.perform({
        adapterName: 'contacts/statements',
        appendPath: `/${docType}`,
        params
      });
      return result;
    }

    get displayAction() {
      return this.txType;
    }

    get defaultDescription() {
      let desc = 'payment';

      if (this.txType === 'cashIn') {
        desc = 'receipt';
      }

      return this.intl.t(desc);
    }

    *fetchAccountTask({
      accountKey,
      txType = 'overview',
      docType,
      txFilters = {}
    }) {
      let appendPath = `/${accountKey}`;

      if (txType !== '') {
        appendPath = `${appendPath}/${txType}`;
      }

      const banksList = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      const bankAccount = banksList.findBy('_data._key', accountKey);
      this.selectedBankAccount = bankAccount;
      const txData = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        appendPath,
        filters: txFilters
      });
      return txData;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "txType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactsList", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "docType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedBankAccount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatementTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchAccountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAccountTask"), _class.prototype)), _class));
  _exports.default = LedgersJournalsCashbookCashbookEditor;
});