define("client/pods/components/dispatch/processes-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    dispatchProcessCount: Ember.computed('dispatchProcessesByCount._data.processes.@each.count', 'dispatchProcess._data.code', function () {
      const dispatchProcessesByCount = this.dispatchProcessesByCount;

      if (dispatchProcessesByCount) {
        const code = this.get('dispatchProcess._data.code'); // @NOTE: the code below is required because of the case where the 'code' contains a .
        // ember's get is a dot path. which will then not be able to get by code

        const _data = dispatchProcessesByCount._data || {};

        return _data[code] || 0;
      }

      return 0;
    }),
    isEditable: Ember.computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
      if (this.get('dispatchProcess._data._key')) {
        return true;
      }

      return false;
    }),
    hasProcessCount: Ember.computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
      if (this.dispatchProcessCount > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});