define("client/pods/components/extensions/apps/tw/kerry/create-contact/component", ["exports", "client/mixins/crud", "client/mixins/search-contacts", "client/mixins/transporter-accounts", "ember-concurrency"], function (_exports, _crud, _searchContacts, _transporterAccounts, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporterAccounts.default, {
    tagName: '',
    isLinkContact: false,

    init() {
      this._super(...arguments);

      const model = this.setupNewRecord({
        adapterName: 'contacts'
      });
      this.set('model', model);
    },

    saveTask: (0, _emberConcurrency.task)(function* ({
      model,
      onCloseDialogAction
    }) {
      let saved;
      model.setData('isTransporter', this.get('constants.isTransporter.on')); // model.setData('isKerry', true)

      model.setData('transporterExtension', 'kerry');

      if (this.isLinkContact) {
        const adapterName = 'contacts/transporters';
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        saved = yield this.updateRecordTask.perform({
          adapterName,
          model: dirty
        });
      } else {
        model.setData('relationships', [this.get('constants.contactsRelationship.supplier')]);
        saved = yield this.createRecordTask.perform({
          adapterName: 'contacts',
          model
        });
      }

      onCloseDialogAction();
      this.onLoadTransporterContact(saved);
    }).drop(),
    actions: {
      loadTransporter(model, transporter) {
        this.set('isLinkContact', true);
        this.set('transporter', transporter);
        model.setData('_key', transporter.getData('_key'));
        model.setData('_rev', transporter.getData('_rev'));
        model.setData('code', transporter.getData('code'));
        model.setData('transporterAccounts', transporter.getData('transporterAccounts'));
        model.setData('defaultTransporterAccount', this.defaultTransporterAccountNumber);
      },

      unloadTransporter(model) {
        this.set('isLinkContact', false);
        this.set('transporter', '');
        model.setData('_key', '');
        model.setData('_rev', '');
        model.setData('code', '');
        model.setData('transporterAccounts', []);
        model.setData('defaultTransporterAccount', '');
      },

      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});