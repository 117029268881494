define("client/pods/filters/model", ["exports", "ramda", "client/pods/base/model", "client/utils/nventor", "client/pods/filters/sort/model"], function (_exports, R, _model, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20
      };
    },

    populate(data = {}) {
      this.transformSort(data);
      return this._super(...arguments);
    },

    transformSort(rawData) {
      const sort = R.propOr([], 'sort')(rawData);

      if (!R.isEmpty(sort)) {
        rawData.sort = R.map(sortData => _nventor.default.createModel(_model2.default, sortData))(sort);
      }

      return rawData;
    },

    addSort(by, direction, {
      toNumber = false
    } = {}) {
      return this._setSortBy('add', by, direction, {
        toNumber
      });
    },

    replaceSort(by, direction, {
      toNumber = false
    } = {}) {
      return this._setSortBy('replace', by, direction, {
        toNumber
      });
    },

    _setSortBy(method, by, direction, {
      toNumber
    }) {
      // this.set('direction', direction)
      let sortByObject = this.getSortByObject(by);
      let sort = [];

      if (method === 'add') {
        sort = this.getData('sort') || [];

        if (sortByObject) {
          if (direction) {
            sortByObject.setData('direction', direction);
            sortByObject.setData('toNumber', toNumber);
          } else {
            sort.removeObject(sortByObject);
          }
        } else {
          sortByObject = _nventor.default.createModel(_model2.default, {
            by,
            direction,
            toNumber
          });
        }
      } else {
        sortByObject = _nventor.default.createModel(_model2.default, {
          by,
          direction,
          toNumber
        });
      }

      if (direction) {
        sort.pushObject(sortByObject);
      }

      return this.setData('sort', sort);
    },

    getSortByObject(by) {
      const sort = this.getData('sort') || [];
      const found = R.find(R.pathEq(['_data', 'by'], by))(sort);

      if (found) {
        return found;
      }

      return '';
    },

    getPropLength(prop) {
      const value = this.get(prop) || [];
      return value.length;
    },

    statusesCount: Ember.computed('_data.statuses.[]', function () {
      return this.getPropLength('_data.statuses');
    })
  });

  _exports.default = _default;
});