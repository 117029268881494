define("client/pods/components/websites/websites-editor/website-wizard/quick-templates/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let WebsitesWebsitesEditorWebsiteWizardQuickTemplatesComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class WebsitesWebsitesEditorWebsiteWizardQuickTemplatesComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "template", _descriptor, this);
    }

    onClick(templates, templateModel) {
      const id = templateModel.getData('_id');
      this.template = templateModel;
      R.pipe(R.map(template => {
        template.setData('selected', false);
        return template;
      }), R.filter(R.pathEq(['_data', '_id'], id)), R.map(template => {
        template.setData('selected', true);
        return template;
      }), R.head)(templates);
      this.args.model.setData('defaultHomePageTemplate', templateModel._data);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "template", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = WebsitesWebsitesEditorWebsiteWizardQuickTemplatesComponent;
});