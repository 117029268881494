define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "batch", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isBulkEditing", _descriptor5, this);
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          master,
          resultsProperty: 'results',
          resultsAdapterName: 'channels/shop/campaigns/details',
          appendPath: `/master/${master}`,
          statuses: ['active', 'inactive', 'preOrder', 'soldOut', 'draft'],
          sort: [{
            by: 'index',
            direction: 'ASC'
          }]
        }
      });
      Ember.set(this, 'filters', filters);
    } // _getBulkEditingDirty (batch) {
    //   return R.map(detail => {
    //     return this.crud.setupDirty({
    //       adapterName: 'channels/shop/campaigns/details',
    //       model: detail
    //     })
    //   })(batch)
    // }


    *fetchCampaignDetailsTask() {
      const model = this.args.model;
      const master = model.getData('_key');

      if (master) {
        model.set('detailsUpdated', false);
        yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath: `/master/${master}`,
          filters: this.filters
        });
      }
    } // @TODO: use the new crud service. but this does not work correctly
    // @task
    // * customPaginateSearchRecordsTask ({ filters }) {
    //   const model = this.args.model
    //   const master = model.getData('_key')
    //   let batch = yield this.crud.paginateSearchRecordsTask.perform({
    //     adapterName: 'channels/shop/campaigns/details',
    //     appendPath: `/master/${master}`,
    //     filters,
    //   })
    //   if (this.isBulkEditing) {
    //     batch = this._getBulkEditingDirty(batch)
    //   }
    //   this.batch = batch
    //   model.set('details', batch)
    // }


    bulkEdit() {
      this.isBulkEditing = true; // const batch = this.batch || []
      // const model = this.args.model
      // this.batch = this._getBulkEditingDirty(batch)
      // model.set('batch', this._getBulkEditingDirty(batch))
    }

    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;

      if (!this.isBulkEditing) {
        this.fetchCampaignDetailsTask.perform();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "batch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isBulkEditing", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchCampaignDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bulkEdit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "bulkEdit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleBulkEdit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkEdit"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent;
});