define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "big", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsProducts", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "productList", _descriptor5, this);
      (0, _defineProperty2.default)(this, "productsFilters", null);
      (0, _defineProperty2.default)(this, "productsFiltersAdapterName", 'products/filters');
      let excludes = [];

      if (this.isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }

      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.productsFilters = productsFilters;
      this.productList = [];
    }

    get useExclAmt() {
      var _this$args, _this$args$adjustment, _this$args$adjustment2;

      let useExclAmt = this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$adjustment = _this$args.adjustmentModel) === null || _this$args$adjustment === void 0 ? void 0 : (_this$args$adjustment2 = _this$args$adjustment._data) === null || _this$args$adjustment2 === void 0 ? void 0 : _this$args$adjustment2.isCalculatingCommissionsByExclAmt;

      if (RA.isNilOrEmpty(useExclAmt)) {
        useExclAmt = true;
      }

      return useExclAmt;
    }

    get isInputReadonly() {
      var _this$args2, _this$args3, _this$args3$model, _this$args3$model$_da, _this$args4, _this$args4$model, _this$args4$model$_da;

      if (this !== null && this !== void 0 && (_this$args2 = this.args) !== null && _this$args2 !== void 0 && _this$args2.isChoices) {
        return true;
      }

      if (this !== null && this !== void 0 && (_this$args3 = this.args) !== null && _this$args3 !== void 0 && (_this$args3$model = _this$args3.model) !== null && _this$args3$model !== void 0 && (_this$args3$model$_da = _this$args3$model._data) !== null && _this$args3$model$_da !== void 0 && _this$args3$model$_da.isSetWithChoices) {
        return true;
      }

      if (this !== null && this !== void 0 && (_this$args4 = this.args) !== null && _this$args4 !== void 0 && (_this$args4$model = _this$args4.model) !== null && _this$args4$model !== void 0 && (_this$args4$model$_da = _this$args4$model._data) !== null && _this$args4$model$_da !== void 0 && _this$args4$model$_da.hasVariants) {
        return true;
      } // if (this?.args?.model?._data?.isSet && !this?.args?.model?._data?.isSetChild) {
      //   return true
      // }

    }

    loadItem(item, product) {
      item.setData('item', product.getData('name'));
    }

    removeAdjustmentRow(item) {
      const details = this.args.details;
      details.removeObject(item);
    }

    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const campaignKey = this.args.campaignKey;
        const filters = this.productsFilters;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadItem", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeAdjustmentRow", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdjustmentRow"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails;
});