define("client/pods/components/grapesjs/btn-modal/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let GrapesjsBtnModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class GrapesjsBtnModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selectedTheme", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "gjsBtn", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "gjsBtnContent", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "gjsBtnHref", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "gjsBtnTarget", _descriptor6, this);
      this.crud.addLists(this, ['grapesjs/lists/btn-modal/target']);
      this.setDefaults();
    }

    get page() {
      const button = `<button class='button ${this.selectedTheme}' style='margin: 0 10px;'>${this.gjsBtnContent}</button>`;
      return {
        pageDataJson: {
          html: `<div class='ember-view' id='ember3'>
          <div class='columns page-container '>
            <div class='column page__content right'>
              <div class='ember-view' id='ember21'>
                <div id='page-custom-content' style='background-color: var(--themeBackgroundPrimaryColor);'>
                  <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
                    <br>
                    <div class='bigordr-cell' style='display: flex;justify-content: center;'>
                    ${button}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`
        }
      };
    }

    setDefaults() {
      this.gjsBtn = this.args.editor.getSelected();
      const gjsBtnAttributes = this.gjsBtn.getAttributes();
      this.selectedTheme = R.pathOr('button1', ['data-gjs-button-theme'])(gjsBtnAttributes);
      this.gjsBtnTarget = R.pathOr('_blank', ['target'])(gjsBtnAttributes);
      this.gjsBtnContent = R.pathOr('button', ['content'])(gjsBtnAttributes);
      this.gjsBtnHref = R.pathOr('https://bigordr.com', ['href'])(gjsBtnAttributes);
    }

    selectTheme(themeName, onCloseDialogAction) {
      this.selectedTheme = themeName;
      onCloseDialogAction();
    }

    setBtnTheme(onCloseDialogAction) {
      const gjsBtnClasses = this.gjsBtn.getClasses();
      this.gjsBtn.setClass(R.reject(R.test(/button\d/))(gjsBtnClasses));

      if (!R.includes(this.selectedTheme)(gjsBtnClasses)) {
        this.gjsBtn.addClass(this.selectedTheme);
      }

      const previousAttributes = this.args.editor.getSelected().getAttributes();
      const updatedAttributes = R.mergeRight(previousAttributes, {
        'data-gjs-button-theme': this.selectedTheme,
        target: this.gjsBtnTarget,
        href: this.gjsBtnHref,
        content: this.gjsBtnContent
      });
      this.args.editor.getSelected().setAttributes(updatedAttributes);
      onCloseDialogAction();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedTheme", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button1';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gjsBtn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gjsBtnContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gjsBtnHref", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'https://bigordr.com';
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gjsBtnTarget", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '_blank';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDefaults", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaults"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectTheme", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "selectTheme"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setBtnTheme", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setBtnTheme"), _class.prototype)), _class));
  _exports.default = GrapesjsBtnModalComponent;
});