define("client/pods/components/documents/docConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    getFor({
      docType,
      docName
    }) {
      const docConfig = this[docType][docName];
      return Object.assign({
        docType,
        docName,
        adapterName: `documents/${docType}/${docName}`,
        displayComponent: 'documents/panel-display',
        newComponent: 'documents/panel-new',
        editComponent: 'documents/panel-edit'
      }, docConfig);
    },

    sales: {
      consignments: {
        dontFetchRegionCode: false,
        hasDeliveryDate: false,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: true
      },
      'consignments-returns': {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: true
      },
      invoices: {
        dontFetchRegionCode: false,
        hasDeliveryDate: false,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: true
      },
      orders: {
        dontFetchRegionCode: true,
        hasDeliveryDate: true,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: true
      },
      quotes: {
        dontFetchRegionCode: false,
        hasDeliveryDate: false,
        hasValidityDate: true,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: false
      },
      returns: {
        dontFetchRegionCode: false,
        hasDeliveryDate: false,
        hasTaxNumber: true,
        hasCommission: true,
        allowLinking: true
      }
    },
    purchases: {
      consignments: {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: true
      },
      'consignments-returns': {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: true
      },
      invoices: {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: true
      },
      orders: {
        dontFetchRegionCode: true,
        hasDeliveryDate: true,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: true
      },
      quotes: {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasVadlidityDate: true,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: false
      },
      returns: {
        dontFetchRegionCode: true,
        hasDeliveryDate: false,
        hasTaxNumber: false,
        hasCommission: false,
        allowLinking: true
      }
    }
  };
  _exports.default = _default;
});