define("client/pods/settings/model", ["exports", "client/pods/base/model", "client/utils/nventor", "client/pods/options/model", "client/pods/faqs/model", "ramda", "ramda-adjunct"], function (_exports, _model, _nventor, _model2, _model3, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      data.paymentReminderEmails = this._transformPaymentReminderEmails(data.paymentReminderEmails);
      data.facebookPages = this._transformFacebookPages(data.facebookPages);
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);
      data.returnsEmailCopyTo = this.emails.joinAsString(data.returnsEmailCopyTo);
      data.tw_eInvoices_notifyEmailAddress = this.emails.joinAsString(data.tw_eInvoices_notifyEmailAddress);
      data.faqs = this._transformFaqs(data);

      this._super(data, attrs);
    },

    defaults() {
      return {
        blogsImagesRatio: this.get('constants.status.oneByOne'),
        autoSendBirthdayRewards: false,
        shopDispatchProcess: this.get('constants.dispatchProcess.unprepared'),
        shopSortOrderDetailsByIndex: false,
        faqs: [],
        isAllowedLinkMember: false,
        shopVirtualAccountAutoExpiry: false,
        shopVirtualAccountExpiryDays: 7,
        salesPendingTaxMethod: this.get('constants.taxMethods.exclusive'),
        purchasesPendingTaxMethod: this.get('constants.taxMethods.exclusive'),
        openHours: {
          monday: {
            open: 'none',
            close: 'none'
          },
          tuesday: {
            open: 'none',
            close: 'none'
          },
          wednesday: {
            open: 'none',
            close: 'none'
          },
          thursday: {
            open: 'none',
            close: 'none'
          },
          friday: {
            open: 'none',
            close: 'none'
          },
          saturday: {
            open: 'none',
            close: 'none'
          },
          sunday: {
            open: 'none',
            close: 'none'
          }
        },
        creditCard_gmo: {
          enabled: false
        },
        creditCard_tapPay: {
          enabled: false,
          merchantId: '',
          partnerKey: ''
        },
        features: {
          extensions: {
            tw_eInvoices_print_allow_print: false,
            tw_eInvoices_print_allow_shop: false,
            tw_eInvoice_print_custom_block: '',
            tw_eInvoices_print_aesKey: ''
          }
        },
        tw_payments_ecPay: {
          enabledVirtualAccount: false,
          merchantId: '',
          hashKey: '',
          hashIv: ''
        },
        tw_eInvoice_ecPay: {
          enabled: false,
          hasCustom: false,
          merchant: '',
          merchantId: '',
          hashKey: '',
          hashIv: ''
        },
        tw_eInvoice_systemlead: {
          companyId: '',
          enabled: false,
          hasCustom: false,
          remind: true,
          remindCount: 100,
          salt: ''
        }
      };
    },

    _transformPaymentReminderEmails(emails = []) {
      return R.map(this.newBlankPaymentReminderEmail, emails);
    },

    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model3.default, faq)))(data);
    },

    newBlankPaymentReminderEmail(data = {}) {
      return _nventor.default.createModel(_model2.default, data);
    },

    _transformFacebookPages(data = []) {
      return R.map(this.newFacebookPage, data);
    },

    createFacebookPages(data = []) {
      const pages = this._transformFacebookPages(data);

      this.setData('facebookPages', pages);
    },

    newFacebookPage(data = {}) {
      return _nventor.default.createModel(_model2.default, data);
    },

    hasCashPaymentFee: Ember.computed('_data.shopPaymentMethods.[]', function () {
      const paymentMethods = this.getData('shopPaymentMethods');

      if (paymentMethods.indexOf('cash') > -1) {
        return true;
      }

      return false;
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;

      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    }),
    allowPrintEInvoices: Ember.computed('_data.{tw_eInvoices_allow_print,tw_eInvoice_systemlead.enabled}', function () {
      const twEInvoiceSystemleadEnabled = R.pathOr(false, ['_data', 'tw_eInvoice_systemlead', 'enabled'])(this);
      const twEInvoicesAllowPrint = R.pathOr(false, ['_data', 'features', 'extensions', 'tw_eInvoices_print_allow_print'])(this);
      return twEInvoiceSystemleadEnabled && twEInvoicesAllowPrint;
    })
  });

  _exports.default = _default;
});