define("client/pods/components/ledgers/journals/cashbook/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, _date, _crud, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    search: Ember.inject.service(),
    model: null,
    errors: null,
    adapterName: 'ledgers/journals/cashbook',

    init() {
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'ledgers/journals/cashbook/lists/menu']);
      this.set('contactsList', []);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments); // when tab component is created, it will automatically fetch txData for default bank account.
      // this can only happen once list of bank accounts has been fetched.


      const defaultBankAccountKey = this.getDefaultBankAccountKey();
      this.set('selectedBankAccountKey', defaultBankAccountKey);
      this.resourceDataRedirect();
    },

    resourceDataRedirect() {
      const resourceData = R_.dotPathOr({}, 'tab.resourceData')(this);

      if (RA.isNotNilOrEmpty(resourceData)) {
        this.tabsManager.displaySubPanel(this, resourceData);
      }
    },

    getDefaultBankAccountKey() {
      const bankAccounts = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      let defaultAccount = bankAccounts.findBy('_data.isDefault', true);

      if (!defaultAccount) {
        defaultAccount = bankAccounts.get('firstObject');
      }

      this.set('account', defaultAccount);

      if (defaultAccount) {
        return defaultAccount.getData('_key');
      }

      return '';
    }

  });

  _exports.default = _default;
});