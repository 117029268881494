define("client/pods/components/contacts/statement-details/statement-transactions/tx-cashin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IlLY5ay",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[8],[1,[28,\"date\",[[23,1,[\"dateZ\"]]],null],false],[9],[0,\"\\n\\n  \"],[7,\"td\",true],[8],[9],[0,\"\\n\\n  \"],[7,\"td\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"compare\",[[23,1,[\"docType\"]],\"===\",\"sales\"],null]],null,{\"statements\":[[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"amount\"]]],[[\"negative\"],[true]]],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"amount\"]]],null],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"discount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"date\",[[23,1,[\"dateZ\"]]],null],false],[9],[0,\"\\n\\n    \"],[7,\"td\",true],[8],[9],[0,\"\\n\\n    \"],[7,\"td\",true],[8],[1,[28,\"tt\",[\"discount\"],null],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"compare\",[[23,1,[\"docType\"]],\"===\",\"sales\"],null]],null,{\"statements\":[[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"discount\"]]],[[\"negative\"],[true]]],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"discount\"]]],null],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/statement-details/statement-transactions/tx-cashin/template.hbs"
    }
  });

  _exports.default = _default;
});