define("client/pods/components/contacts/panels-display/sub-panels-statements/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  let ContactsPanelsDisplaySubPanelsStatements = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, (_class = (_temp = class ContactsPanelsDisplaySubPanelsStatements extends _component.default {
    constructor() {
      var _this$args$tab;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "statement", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedBankAccountKey", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "resourceData", _descriptor8, this);
      (0, _defineProperty2.default)(this, "adapterName", 'contacts/statements');
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'ledgers/journals/cashbook/lists/menu']);
      this.docType = (this === null || this === void 0 ? void 0 : (_this$args$tab = this.args.tab) === null || _this$args$tab === void 0 ? void 0 : _this$args$tab.docType) || ''; // get list for 12 month

      const pastMonths = this.date.getPastMonths(12);
      this.pastMonths = pastMonths;
    }

    afterLoadLists() {
      // when tab component is created, it will automatically fetch txData for default bank account.
      // this can only happen once list of bank accounts has been fetched.
      const defaultBankAccountKey = this.getDefaultBankAccountKey();
      this.selectedBankAccountKey = defaultBankAccountKey; // show current month statement

      const thisMonth = this.date.getPeriod();
      this.displayByMonth.perform(thisMonth); // get resourceData for quick links to cashbook

      this.getResourceData();
    }

    getResourceData() {
      const resourceData = R.pipe(R_.dotPathOr([], 'crud.lists.ledgers-journals-cashbook-lists-menu'), R.find(R.pathEq(['_data', 'docType'], this.docType)))(this);
      this.resourceData = resourceData;
    }

    getDefaultBankAccountKey() {
      const bankAccounts = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      let defaultAccount = bankAccounts.findBy('_data.isDefault', true);

      if (!defaultAccount) {
        defaultAccount = bankAccounts.get('firstObject');
      }

      if (defaultAccount) {
        var _defaultAccount, _defaultAccount$_data;

        return ((_defaultAccount = defaultAccount) === null || _defaultAccount === void 0 ? void 0 : (_defaultAccount$_data = _defaultAccount._data) === null || _defaultAccount$_data === void 0 ? void 0 : _defaultAccount$_data._key) || '';
      }

      return '';
    }

    *fetchStatementTask(dateStart, dateEnd) {
      var _this$args, _model$_data;

      const model = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.model) || {};
      const key = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data._key) || '';
      const docType = (this === null || this === void 0 ? void 0 : this.docType) || '';
      const allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);
      const params = {
        key,
        docType,
        dateStart,
        dateEnd,
        allowedPaymentMethodForStatement
      };
      const result = yield this.crud.findTask.perform({
        adapterName: this.adapterName,
        appendPath: `/${docType}`,
        params
      });
      this.statement = result;
    }

    *displayByMonth(period) {
      const dateStart = this.date.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.date.formatDateForUrl(this.date.getMonthEnd(period.yyyy, period.mm));
      yield this.fetchStatementTask.perform(dateStart, dateEnd);
    }

    *displayByCustomMonth() {
      const dateEndString = this.customDateEnd;
      const dateStartString = this.customDateStart;
      let dateEnd;
      let dateStart;

      if (dateEndString) {
        if (!dateStartString) {
          const dateEndObj = this.date.getMonthEnd(dateEndString);
          dateStart = this.date.formatDateForUrl(dateEndObj.startOf('month'));
          dateEnd = this.date.formatDateForUrl(dateEndString);
        } else {
          if (dateStartString > dateEndString) {
            dateEnd = this.date.formatDateForUrl(dateStartString);
            dateStart = this.date.formatDateForUrl(dateEndString);
          } else {
            dateEnd = this.date.formatDateForUrl(dateEndString);
            dateStart = this.date.formatDateForUrl(dateStartString);
          }
        }

        yield this.fetchStatementTask.perform(dateStart, dateEnd);
      }
    }

    openCashbookTab() {
      const tabOptions = {
        component: 'ledgers/journals/cashbook/tab-container',
        title: 'cash book',
        resourceData: this.resourceData,
        loadInstructions: {
          find: {
            id: this.selectedBankAccountKey
          }
        }
      };
      this.tabsManager.openTab('left', tabOptions);
    } // printStatement() {
    //
    //   window.open('https://localhost:9000/api/protected/contacts/statements/print/sales/2017-11-01/2017-11-30/3040087962211', '_blank');
    // }


  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "statement", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedBankAccountKey", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resourceData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatementTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayByMonth", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "displayByMonth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayByCustomMonth", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "displayByCustomMonth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openCashbookTab", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openCashbookTab"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDisplaySubPanelsStatements;
});