define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRow = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRow extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "big", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsProducts", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "productList", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "commissionBaseCostNew", _descriptor6, this);
    }

    getCommissionBaseCostNew() {
      const model = this.args.model;
      const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', model);
      this.commissionBaseCostNew = commissionBaseCostNew;
    }

    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const campaignKey = this.args.campaignKey;
        const filters = this.productsFilters;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }

    *saveCommissionBaseCostNewModelTask({
      model,
      selectedCommissionBaseCostMethod,
      isUsingAverage
    }) {
      const adapterName = 'channels/shop/campaigns/details/commission-base-cost';
      model.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);

      if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent')) {
        model.setData('commissionBaseCostInTwPercent', '');
        model.setData('changedCommissionBaseCost', '');
        model.setData('commissionBaseCostTotal', '');
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'twPercent')) {
        model.setData('commissionBaseCostInPercent', '');
        model.setData('changedCommissionBaseCost', '');
        model.setData('commissionBaseCostTotal', '');
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'perItem')) {
        model.setData('commissionBaseCostInPercent', '');
        model.setData('commissionBaseCostInTwPercent', '');
        model.setData('commissionBaseCostTotal', '');
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'byTotal')) {
        model.setData('commissionBaseCostInPercent', '');
        model.setData('commissionBaseCostInTwPercent', '');
        model.setData('commissionBaseCostTotal', '');
      }

      if (isUsingAverage) {
        model.setData('isUsingAverage', true);
      }

      const _key = R.pathOr('', ['_data', '_key'], model);

      let savedModel;

      if (RA.isNilOrEmpty(_key)) {
        savedModel = yield this.crud.queueCreateRecordTask.perform({
          adapterName,
          model,
          component: this
        });
      } else {
        savedModel = yield this.crud.queueUpdateRecordTask.perform({
          adapterName,
          model,
          component: this
        });
      }

      const originalModel = this.args.model;
      originalModel.setData('commissionBaseCostNew', R.propOr({}, '_data', savedModel));
      let newModel;

      if (isUsingAverage) {
        newModel = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/preset-commissions',
          model: originalModel
        });
      } else {
        newModel = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details',
          model: originalModel
        });
      }

      const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', newModel);
      const dirty = this.crud.setupDirty({
        adapterName: 'channels/shop/campaigns/details/commission-base-cost',
        model: commissionBaseCostNew
      });
      this.commissionBaseCostNew = dirty;
    }

    *setCommissionBaseCostFromHistoryTask({
      model,
      history,
      onHideDropdown
    }) {
      var _this$args, _this$args$tableViewO, _this$args2, _this$args2$tableView;

      const selectedCommissionBaseCostMethod = this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$tableViewO = _this$args.tableViewOptions) === null || _this$args$tableViewO === void 0 ? void 0 : _this$args$tableViewO.selectedCommissionBaseCostMethod;
      const isUsingAverage = this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$tableView = _this$args2.tableViewOptions) === null || _this$args2$tableView === void 0 ? void 0 : _this$args2$tableView.isUsingAverage;
      const commissionBaseCosts = ['commissionBaseCostInPercent', 'commissionBaseCostInTwPercent', 'changedCommissionBaseCost', 'commissionBaseCostTotal'];
      R.forEach(commissionBaseCost => {
        let commissionHistory = '';

        if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent') && R.equals(commissionBaseCost, 'commissionBaseCostInPercent')) {
          commissionHistory = history.getData(commissionBaseCost);
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'twPercent') && R.equals(commissionBaseCost, 'commissionBaseCostInTwPercent')) {
          commissionHistory = history.getData(commissionBaseCost);
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'perItem') && R.equals(commissionBaseCost, 'changedCommissionBaseCost')) {
          commissionHistory = history.getData(commissionBaseCost);
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'byTotal') && R.equals(commissionBaseCost, 'commissionBaseCostTotal')) {
          commissionHistory = history.getData(commissionBaseCost);
        }

        model.setData(commissionBaseCost, commissionHistory);
      })(commissionBaseCosts);
      yield this.saveCommissionBaseCostNewModelTask.perform({
        model,
        selectedCommissionBaseCostMethod,
        isUsingAverage
      });

      if (onHideDropdown) {
        onHideDropdown();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commissionBaseCostNew", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getCommissionBaseCostNew", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getCommissionBaseCostNew"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveCommissionBaseCostNewModelTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveCommissionBaseCostNewModelTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCommissionBaseCostFromHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setCommissionBaseCostFromHistoryTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRow;
});