define("client/pods/components/settings/defaults/3rd-party-editor/social-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "THtdBwt2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"facebook page url\",[24,[\"errors\"]],\"facebookUrl\",\"settings\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"facebookUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"instagram url\",[24,[\"errors\"]],\"instagramUrl\",\"settings\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"instagramUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"line id\",[24,[\"errors\"]],\"lineId\",\"settings\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"lineId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"youtube channel url\",[24,[\"errors\"]],\"youtubeUrl\",\"settings\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"youtubeUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/defaults/3rd-party-editor/social-links/template.hbs"
    }
  });

  _exports.default = _default;
});