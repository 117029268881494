define("client/pods/components/elements/element-dropdown-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1lA/tD5X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"class\",\"button\"],[12,\"disabled\",[22,\"disabled\"]],[3,\"action\",[[23,0,[]],\"showDropdown\"]],[8],[0,\"\\n    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"icon\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-btn\",null,[[\"icon\",\"disabled\",\"classNames\",\"prefix\",\"label\",\"isToggler\",\"toggleIsOn\",\"onSubmit\"],[[24,[\"icon\"]],[24,[\"disabled\"]],[24,[\"btnClassNames\"]],[24,[\"prefix\"]],[24,[\"label\"]],true,[24,[\"showDropdown\"]],[28,\"action\",[[23,0,[]],[24,[\"onSubmit\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isDropdownVisible\"]]],null,{\"statements\":[[4,\"modals/modal-container\",null,[[\"toggle\",\"modalContentClassNames\"],[[24,[\"showDropdown\"]],\"u-margin-top--6\"]],{\"statements\":[[0,\"    \"],[14,1,[[28,\"action\",[[23,0,[]],\"hideDropdown\"],null]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-dropdown-btn/template.hbs"
    }
  });

  _exports.default = _default;
});