define("client/pods/workflows/items/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 50,
        query: '',
        match: ['all'],
        documentStatus: ['all'],
        dispatchStatus: ['all'],
        toName: '',
        toAddress: '',
        subject: '',
        docNo: '',
        sourceKey: '',
        sort: {
          by: 'timestampZ',
          direction: 'ASC'
        }
      };
    }

  });

  _exports.default = _default;
});