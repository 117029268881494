define("client/pods/data-manager/service", ["exports", "client/utils/nventor", "client/config/environment", "ramda-adjunct", "ramda"], function (_exports, _nventor, _environment, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  window.Ed = {};
  window.Ed.CACHE = {};
  window.Ed.edit = {};
  window.Ed.sync = {};
  window.Ed.syncList = {};

  var _default = Ember.Service.extend({
    big: Ember.inject.service(),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    emails: Ember.inject.service(),
    intl: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    server: Ember.inject.service(),
    settings: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    users: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.resetCache();
    },

    resetCache() {
      // resets store on create
      window.Ed = {};
      window.Ed.CACHE = {};
      window.Ed.edit = {};
      window.Ed.sync = {};
      window.Ed.syncList = {};
      this.set('cache', window.Ed.CACHE);
      this.set('editingRegistry', window.Ed.edit);
      this.set('syncQ', window.Ed.sync);
    },

    _getCacheId(adapterName, record) {
      const adapter = this.getAdapter(adapterName);

      if (!record) {
        return false;
      }

      const idParam = adapter.get('idParam');
      const idPrefix = adapter.get('idPrefix');

      if (idParam === false) {
        return false;
      }

      let cacheId; // if (record._data) {
      //   if (!R.is(Array, idParam)) {
      //     cacheId = this._getIdFromMultipleParams(idParam, record._data)
      //   } else {
      //     cacheId = record._data[idParam]
      //   }
      // } else {
      //   cacheId = this._getIdFromMultipleParams(idParam, record)
      // }

      let data;

      if (record._data) {
        data = record._data;
      } else {
        data = record;
      }

      if (R.is(Array, idParam)) {
        cacheId = this._getIdFromMultipleParams(idParam, data);
      } else {
        cacheId = data[idParam];
      }

      if (cacheId) {
        if (idPrefix) {
          cacheId = `${idPrefix}/${cacheId}`;
        }

        return cacheId;
      }

      if (!R.isEmpty(data)) {
        _nventor.default.throw('AdapterName: ' + adapterName + ' Cannot get id from record/data. idParam: ' + idParam, record);
      }
    },

    _getIdFromMultipleParams(idParams, record) {
      if (!R.is(Array, idParams)) {
        idParams = [idParams];
      }

      return R.pipe(R.map(param => R.propOr(param, param, record)), R.join('--'))(idParams);
    },

    getFromCache(cacheId) {
      if (cacheId === false) {
        return false;
      }

      const cache = this.cache;
      return cache[cacheId];
    },

    _storeToCache(model, cacheId) {
      const cache = this.cache;

      if (cacheId !== false) {
        cache[cacheId] = model;

        if (cache[cacheId]) {
          return cache[cacheId];
        }
      }

      return model;
    },

    _moveCached(oldCacheId, newCacheId) {
      const cache = this.cache;
      const model = cache[oldCacheId];
      cache[newCacheId] = model;
      delete cache[oldCacheId];
    },

    getAdapter(adapterName) {
      const adapter = Ember.getOwner(this).lookup(`adapter:${adapterName}`);

      if (!adapter) {
        if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
          debugger; //eslint-disable-line
        }

        _nventor.default.throw(`DATA MANAGER: getAdapterError. Invalid adpaterName: ${adapterName}.`);
      }

      adapter.set('syncAdapterName', adapterName);
      return adapter;
    },

    findAll({
      method = 'GET',
      adapterName,
      appendPath,
      modelAdapterName,
      data = {},
      rawResponse,
      filters,
      onAfter,
      options = {},
      debug
    } = {}) {
      // @NOTE: must supply DATA if options object is also supplied
      const adapter = this.getAdapter(adapterName);

      if (filters) {
        if (filters._data) {
          const filtersData = filters.serialize();
          data = R.mergeRight(data, filtersData);
        } else {
          const keys = Object.keys(filters) || [];
          keys.forEach(key => {
            data[key] = filters[key];
          });
        }
      } // check if appendPath was supplied


      if (_nventor.default.confirm.isObject(appendPath)) {
        options = _nventor.default.copy(data);
        data = _nventor.default.copy(appendPath);
        appendPath = null;
      } // findAll may return [results] array or an object with an array as one of the props. the prop is set in the adapter


      options.filters = filters;

      if (debug) {
        debugger; //eslint-disable-line
      }

      return adapter.findAll(data, appendPath, options).then(responseData => {
        if (debug) {
          debugger; //eslint-disable-line
        }

        if (adapter.afterFindAll) {
          return adapter.afterFindAll(responseData);
        } // convert each JSON data to a model instance (record)


        let resultsArray = responseData;

        if (adapter.resultsProperty) {
          resultsArray = R.propOr([], adapter.resultsProperty, responseData);
        }

        if (options.excludeDeleted) {
          resultsArray = R.reject(R.pathEq(['isDeleted'], true))(resultsArray);
        }

        if (rawResponse) {
          return resultsArray;
        }

        const results = R.map(json => {
          // if record already exists in cache, it will update the data
          // cache can be turned off by using options
          return this.setAsRecord({
            adapterName: modelAdapterName || adapterName,
            data: json,
            options,
            debug
          });
        })(resultsArray);

        if (adapter.resultsProperty) {
          responseData[adapter.resultsProperty] = results;
          return responseData;
        }

        return results;
      });
    },

    setAsRecord({
      adapterName,
      data = {},
      attrs,
      options = {},
      debug
    }) {
      if (debug) {
        debugger; //eslint-disable-line
      }

      if (data._data) {
        // already a class. dont set as class again. this will only happen when sync update has action=push
        return data;
      } // if (nventor.isNilOrEmpty(data)) {
      //   return {}
      // }
      // store cache of each individual result model: default = true


      const cache = R.propOr(true, 'cache', options);
      const adapter = this.getAdapter(adapterName);

      if (adapter.beforePopulate) {
        data = adapter.beforePopulate(data);
      }

      if (cache && adapter.get('idParam')) {
        const cacheId = this._getCacheId(adapterName, data);

        if (cacheId == null && !R.isEmpty(data)) {
          _nventor.default.throw('Cannot Set as Record: no id found in model for ' + adapterName + '. Id: ' + cacheId);
        }

        const hasCached = this.getFromCache(cacheId);

        if (hasCached) {
          this.setEditingToOutOfDate(cacheId, data);
          return this._refreshModel({
            record: hasCached,
            data,
            options,
            attrs,
            debug
          });
        }

        const record = this.newRecord({
          adapterName,
          data,
          attrs,
          debug
        });
        return this._storeToCache(record, cacheId);
      }

      return this.newRecord({
        adapterName,
        data,
        attrs,
        debug
      });
    },

    setPartialRecord({
      adapterName,
      data,
      attrs = {}
    }) {
      // create partial
      attrs.isPartial = true;
      return this.setAsRecord({
        adapterName,
        data,
        attrs
      });
    },

    mergeAndSetPartialRecord({
      adapterName,
      data,
      attrs = {},
      options = {}
    }) {
      // create partial
      attrs.isPartial = true;
      options.mergeWithCache = true;
      return this.setAsRecord({
        adapterName,
        data,
        attrs,
        options
      });
    },

    newRecord({
      adapterName,
      data = {},
      attrs = {},
      debug
    }) {
      const adapter = this.getAdapter(adapterName);

      if (debug) {
        debugger; //eslint-disable-line
      } // create model but dont store to cache


      try {
        if (adapterName !== 'settings') {
          attrs.settings = this.settings;
        }

        attrs.dataManager = this;
        attrs.productsService = this.productsService;
        attrs.crud = this.crud;
        attrs.dateService = this.dateService;
        attrs.uploadcare = this.uploadcare;
        attrs.server = this.server;
        attrs.big = this.big;
        attrs.users = this.users;
        attrs.emails = this.emails;
        attrs.intl = this.intl;
        attrs.adapterName = adapterName;
        return adapter.createModel(adapterName, adapter, data, attrs);
      } catch (e) {
        // console.log('context: ', data)
        _nventor.default.throw('Cannot create new record for adapter ' + adapterName + '. Error: ' + e, data);
      }
    },

    findRecord({
      adapterName,
      appendPath = '',
      params = {},
      filters = {},
      token,
      options
    }) {
      const adapter = this.getAdapter(adapterName);

      if (Ember.isNone(adapter)) {
        return _nventor.default.throw('Incorrect adapterName given: `' + adapterName + '`');
      }

      if (_nventor.default.confirm.isObject(appendPath)) {
        params = appendPath;
        appendPath = null;
      }

      if (RA.isNotNilOrEmpty(filters)) {
        let filtersData = filters;

        if (filters.serialize) {
          filtersData = filters.serialize();
        }

        params = R.mergeRight(filtersData, params);
      }

      return adapter.find(params, appendPath, token).then(found => {
        return this.setAsRecord({
          adapterName,
          data: found,
          options
        });
      });
    },

    /**
     * call method on adapter directly. (custom method)
     * will use the adpaters serializer and methods.
     * used for batches or when wanting to update but need to bypass the data manager cache
     */
    adapterCall(method, adapterName, appendPath, model) {
      const adapter = this.getAdapter(adapterName);
      let serializedData = model;
      serializedData = adapter.serialize(model); // call custom method on adapter

      return adapter[method](serializedData, appendPath);
    },

    /**
     * call ajax method bypassing data manager but
     * will use the adpaters serializer and methods.
     * used for batches or when wanting to update but need to bypass the data manager cache
     */
    ajaxCall(method, adapterName, appendPath = '', model = {}) {
      const adapter = this.getAdapter(adapterName);
      let serializedData = model;

      if (model._data) {
        serializedData = adapter.serialize(model);
      }

      return adapter.call({
        method,
        data: serializedData,
        appendPath
      }).then(updatedData => {
        return updatedData;
      }).catch(err => {
        // pass error down the promise chain
        throw err;
      });
    },

    async createRecord({
      adapterName,
      appendPath,
      model,
      rawResponse = false
    }) {
      const adapter = this.getAdapter(adapterName);

      if (Ember.isNone(adapter)) {
        return _nventor.default.throw('Incorrect adapterName given: `' + adapterName + '`');
      }

      if (_nventor.default.confirm.isObject(appendPath)) {
        model = appendPath;
        appendPath = null;
      }

      const data = adapter.serialize(model);
      const newRecord = this.copyRecord(adapterName, model);
      const date = new Date();
      newRecord.set('createdAt', date.getTime());
      newRecord.startSaving();
      const response = await adapter.save(data, appendPath);
      const createdData = R.propOr(response, 'model', response);
      newRecord.stopSaving();
      newRecord.populate(createdData);

      const cacheId = this._getCacheId(adapterName, newRecord);

      newRecord.setData('_cacheId', cacheId); // save instance to cache..

      const newModel = this._storeToCache(newRecord, cacheId);

      if (rawResponse) {
        return {
          rawResponse: response,
          model: newModel
        };
      }

      return newModel;
    },

    /**
     * update models
     */
    updateRecord({
      adapterName,
      appendPath,
      dirty,
      updateProps = {},
      excludeProps = {},
      rawResponse = false
    }) {
      // check if appendPath was supplied
      if (_nventor.default.confirm.isObject(appendPath)) {
        dirty = appendPath;
        appendPath = null;
      }

      const adapter = this.getAdapter(adapterName);

      const cacheId = this._getCacheId(adapterName, dirty);

      const original = this.getFromCache(cacheId); // set saving on both original and dirty model

      dirty.startSaving();
      original.startSaving(); // send data as an object

      let data = adapter.serialize(dirty);

      if (!_nventor.default.isNilOrEmpty(excludeProps)) {
        data = R.omit(excludeProps)(data);
        data._isPartial = true;
      }

      if (!_nventor.default.isNilOrEmpty(updateProps)) {
        // if (adapterName === 'settings') {
        //   updateProps = updateProps.concat([
        //     'company',
        //     'dispatchedEmailSubject',
        //     'emailFromAddress',
        //     'emailFromName',
        //     'emailSignature',
        //     'orderedEmailSubject'
        //   ])
        // }
        updateProps = updateProps.concat(['_key', '_id', '_rev']);
        data = R.pick(updateProps)(data);
        data._isPartial = true;
      }

      data._cacheId = cacheId;
      return adapter.update(data, appendPath).then(response => {
        const updatedData = R.propOr(response, 'model', response); // repopulates model with data supplied or data from syncQ

        this._refreshModel({
          record: original,
          data: updatedData
        }); // update record will not update model, the server will send data via websocket and update the model via sync


        dirty.stopSaving();
        original.stopSaving();

        this._deregisterEditing(dirty, cacheId);

        if (rawResponse) {
          return {
            rawResponse: response,
            model: original
          };
        }

        return original;
      }).catch(err => {
        dirty.stopSaving();
        original.stopSaving();

        if (err.revisionError) {
          // handle revision error..
          this.setEditingToOutOfDate(cacheId); // throw an error to allow the other .catch to chain
        } // pass error down the promise chain


        throw err;
      });
    },

    replaceRecord({
      adapterName,
      appendPath,
      dirty,
      rawResponse = false
    }) {
      // check if appendPath was supplied
      if (_nventor.default.confirm.isObject(appendPath)) {
        dirty = appendPath;
        appendPath = null;
      }

      const adapter = this.getAdapter(adapterName);

      const cacheId = this._getCacheId(adapterName, dirty);

      const original = this.getFromCache(cacheId); // set saving on both original and dirty model

      dirty.startSaving();
      original.startSaving(); // send data as an object

      const data = adapter.serialize(dirty);
      data._cacheId = cacheId;
      return adapter.replace(data, appendPath).then(response => {
        const updatedData = R.propOr(response, 'model', response);
        /* jshint unused: false */
        // repopulates model with data supplied or data from syncQ

        this._refreshModel({
          record: original,
          data: updatedData
        }); // update record will not update model, the server will send data via websocket and update the model via sync


        dirty.stopSaving();
        original.stopSaving();

        this._deregisterEditing(dirty, cacheId);

        if (rawResponse) {
          return {
            rawResponse: response,
            model: original
          };
        }

        return original;
      }).catch(err => {
        dirty.stopSaving();
        original.stopSaving();

        if (err.revisionError) {
          // handle revision error..
          this.setEditingToOutOfDate(cacheId); // throw an error to allow the other .catch to chain
        } // pass error down the promise chain


        throw err;
      });
    },

    removeRecord({
      adapterName,
      appendPath,
      model,
      batch
    }) {
      const adapter = this.getAdapter(adapterName);

      if (_nventor.default.confirm.isObject(appendPath)) {
        model = appendPath;
        appendPath = null;
      }

      const cacheId = this._getCacheId(adapterName, model);

      let data = {};
      let batchData;

      if (!RA.isNilOrEmpty(batch)) {
        batchData = R.map(model => {
          return adapter.serialize(model);
        })(batch);
      } else {
        data = adapter.serialize(model);
      }

      data._cacheId = cacheId;
      data.batch = batchData;
      return adapter.remove(data).then(isDeleted => {
        var _isDeleted$model;

        if (!RA.isNilOrEmpty(batch)) {
          // model is an array
          R.forEach(deleted => {
            if (isDeleted === true) {
              // remove instance from cache..
              this._removeModel(deleted);
            }
          })(batch);
          return true;
        }

        if (isDeleted === true || isDeleted !== null && isDeleted !== void 0 && isDeleted.deleted || isDeleted !== null && isDeleted !== void 0 && (_isDeleted$model = isDeleted.model) !== null && _isDeleted$model !== void 0 && _isDeleted$model.isDeleted) {
          // remove instance from cache..
          this._removeModel(model);

          return true;
        } // @TODO: handle error


        return false;
      });
    },

    /**
     * used when model is not a record.
     */
    updateModelWith({
      adapterName,
      original,
      dirty
    }) {
      const adapter = this.getAdapter(adapterName);
      const data = adapter.serialize(dirty);
      original.populate(data);
      return original;
    },

    getDirtyRecord(adapterName, record, attrs = {}) {
      const cacheId = this._getCacheId(adapterName, record); // check if record (original) is in cache, if not then must add to cache
      // this needs to be done because on updating record,
      // it will need to update original


      this._storeToCache(record, cacheId);

      const dirty = this.copyRecord(adapterName, record);
      dirty.set('dataManager', this);
      dirty.set('settings', this.settings);
      dirty.set('isDirty', true);

      if (!_nventor.default.isNilOrEmpty(attrs)) {
        dirty.setProperties(attrs);
      }

      const originalData = dirty.serialize();
      const originalString = JSON.stringify(originalData);
      dirty.set('originalSerialized', originalString); // how to register when there are:
      //  .details = [record, record]
      // add record to editing

      this._registerEditing(dirty, cacheId);

      return dirty;
    },

    cancelDirty(adapterName, dirty) {
      if (dirty.get('isDirty')) {
        const cacheId = this._getCacheId(adapterName, dirty);

        this._deregisterEditing(dirty, cacheId);

        return this.getFromCache(cacheId);
      }

      return dirty;
    },

    _removeModel(model) {
      model.set('isDeleted', true);
      model.set('isEditable', false);

      if (model.updateSynced) {
        model.updateSynced();
      }
    },

    _refreshModel({
      record,
      data,
      action,
      propPath,
      options,
      attrs = {}
    }) {
      if (action && propPath) {
        if (action === 'arrayUnshift') {
          const recordData = record._data;
          const array = R.path(propPath, recordData);
          array.unshiftObject(data);
          data = recordData;
        }

        if (action === 'arrayPush') {
          const recordData = record._data;
          const array = R.path(propPath, recordData);
          array.pushObject(data);
          data = recordData;
        }

        const newData = record.serialize();
        record.populate(newData);
      } else {
        // @TODO: if isPartial then check if revs equal. if equal merge
        // if no rev, then check if props match.
        // or never have isPartial without a rev?
        if (record.populate) {
          const oldData = record.serialize(); // @NOTE: there is still a problem here with partial data.
          // if originally a record is a partial, then when syncing with a full model,
          // the record still remains as a partial
          // if partial record contains: {
          //   name: 'abc',
          //   age: 2
          // } and is then updated with a new partial of {
          //   name: 'aaa'
          // } if age is no longer there then the record cannot remove the age prop

          if (record.isPartial || attrs.isPartial) {
            const refreshWithData = R.mergeRight(oldData, data);
            record.populate(refreshWithData);
          } else {
            record.populate(data);
          }
        }

        if (!_nventor.default.isNilOrEmpty(attrs)) {
          record.setProperties(attrs);
        }
      }

      return record;
    },

    _registerEditing(dirty, id, idPrefix) {
      const registry = this.editingRegistry; // each time a model is being edited a random UID is given
      // so that only that specific instance can be deregistered
      // when no longer editing
      // (there can be multiple dirty models in different places)

      const isEditing = Math.random();
      dirty.set('isEditing', isEditing);

      if (id) {
        if (idPrefix) {
          id = `${idPrefix}/${id}`;
        } // add instance to editing list
        // because the same model can be edited in multiple places,
        // an array is required to keep track of the different places the model is being edited


        registry[id] = registry[id] || [];
        registry[id].pushObject(dirty);
      }
    },

    /**
     * SYNC
     * deregisters the object instance from the editing list
     * @param  {object} dirty - editing model copy instance
     */
    _deregisterEditing(dirty, cacheId) {
      const editingRegistry = this.editingRegistry;
      const instances = editingRegistry[cacheId];

      if (instances) {
        if (instances.length <= 1) {
          // remove list
          delete editingRegistry[cacheId];
        } else {
          // only remove the instance with specific isEditing
          // var isEditing = dirty.get('isEditing');
          instances.removeObject(dirty);
        }
      }
    },

    /**
     * for getting cache id when syncing (only for when no adapter is given)
     */
    _generateCacheId(id, idPrefix) {
      if (idPrefix) {
        return `${idPrefix}/${id}`;
      }

      return id;
    },

    syncUpdate(syncOptions) {
      const {
        model,
        id,
        replaceId,
        idPrefix,
        action,
        propPath,
        propPaths,
        debug
      } = syncOptions; // @deprecate propPath, should us propPaths instead

      const adapters = syncOptions.adapters;
      this.set('isSyncing', true);

      if (debug) {
        debugger; //eslint-disable-line
      }

      if (replaceId) {
        const replaceCacheId = this._generateCacheId(replaceId, idPrefix); // const oldCacheId = this._generateCacheId(id, idPrefix)
        // if (!R.is(Array, adapters)) {
        //   adapters = [adapters]
        // }
        // adapters.forEach(adapterData => this._syncUpdate({ model, cacheId: replaceCacheId, adapterData, action, propPath }))


        this._syncUpdate({
          model,
          cacheId: replaceCacheId,
          action,
          propPath,
          propPaths
        }); // this._moveCached(replaceId, oldCacheId)

      } else if (R.is(Array, adapters) && !R.isEmpty(adapters)) {
        adapters.forEach(adapterData => this._syncUpdate({
          model,
          id,
          idPrefix,
          adapterData,
          action,
          propPath,
          propPaths
        }));
      } else {
        this._syncUpdate({
          model,
          id,
          idPrefix,
          action,
          propPath,
          propPaths
        });
      }

      this.set('isSyncing', false);
    },

    _syncUpdate({
      model,
      id,
      idPrefix,
      cacheId,
      adapterData = {},
      action,
      propPath,
      propPaths
    }) {
      const {
        adapterName,
        appendPath
      } = adapterData;

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('---sync update---'); //eslint-disable-line

        console.log(model); //eslint-disable-line

        console.log('adapterNames:'); //eslint-disable-line

        console.log(adapterName); //eslint-disable-line

        console.log(`appendPath: ${appendPath}`); //eslint-disable-line

        console.log(`id: ${id}`); //eslint-disable-line

        console.log(`idPrefix: ${idPrefix}`); //eslint-disable-line

        console.log(`cacheId: ${cacheId}`); //eslint-disable-line
      }

      if (adapterName) {
        if (R.is(Array, model)) {
          model = model || [];
          return this._syncUpdateList({
            adapterName,
            appendPath,
            list: model,
            action
          });
        }
      }

      if (!cacheId && adapterName) {
        cacheId = this._getCacheId(adapterName, model);
      }

      if (!cacheId && id) {
        cacheId = this._generateCacheId(id, idPrefix);
      }

      if (!Ember.isNone(cacheId)) {
        this.setEditingToOutOfDate(cacheId, model); // update all model instances of id with new model

        const cachedModel = this.getFromCache(cacheId);

        if (cachedModel) {
          if (action === 'updateProps' && propPaths) {
            if (!R.is(Array(propPaths))) {
              propPaths = [propPaths];
            }

            const partialData = R.reduce((acc, propData) => {
              acc[propData.path] = propData.value;
              return acc;
            }, {})(propPaths);
            cachedModel.populatePartial(partialData);
          } else if (action === 'updatePartial') {
            cachedModel.populatePartial(model);
          } else {
            // refresh model with sync model
            this._refreshModel({
              record: cachedModel,
              data: model,
              action,
              propPath
            });
          }

          const synced = Math.random();
          cachedModel.set('synced', synced);
        }
      }
    },

    // syncUpdateProperty (data, id, idPrefix) {
    //   // console.log('----sync update property--');
    //   if (!isNone(id)) {
    //     // update the model instance in the store with new data
    //     // @NOTE: syncUpdateProperty can only be used if it does not change model _rev
    //     // as it will not make model outdated
    //     //
    //     // @NOTE: if wanting to sync model data property need to use _data.property name
    //     const cacheId = this._generateCacheId(id, idPrefix)
    //     const model = this.getFromCache(cacheId)
    //     if (model) {
    //       // refresh model with sync data
    //       const keys = Object.keys(data)
    //       keys.forEach(function (key) {
    //         const value = data[key]
    //         model.set(key, value)
    //       })
    //     }
    //   }
    // },
    syncRemove({
      model,
      id,
      idPrefix,
      adapters,
      debug
    }) {
      if (debug) {
        debugger; //eslint-disable-line
      }

      this.set('isSyncing', true); // @TODO: should include who deleted model and when

      if (R.is(Array, adapters) && !R.isEmpty(adapters)) {
        adapters.forEach(adapterData => this._syncRemove({
          model,
          adapterData
        }));
      } else {
        this._syncRemove({
          model,
          id,
          idPrefix
        });
      }

      this.set('isSyncing', false);
    },

    _syncRemove({
      model,
      id,
      idPrefix,
      adapterData
    }) {
      // const { adapterName } = adapterData
      const {
        adapterName,
        appendPath
      } = adapterData;

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('---sync remove---'); //eslint-disable-line

        console.log(model); //eslint-disable-line

        console.log('adapterNames:'); //eslint-disable-line

        console.log(adapterName); //eslint-disable-line

        console.log(`appendPath: ${appendPath}`); //eslint-disable-line
      }

      let cacheId;

      if (!cacheId && id) {
        // @TODO remove
        cacheId = this._generateCacheId(id, idPrefix);
      }

      if (!cacheId && adapterName) {
        cacheId = this._getCacheId(adapterName, model);
      } // update all model instances of id with new data, set all isEditing to out of date


      if (!Ember.isNone(cacheId)) {
        this.setEditingToIsDeleted(cacheId);
        const cachedModel = this.getFromCache(cacheId);

        if (cachedModel) {
          this._removeModel(cachedModel);

          const synced = Math.random();
          cachedModel.set('synced', synced);
        }
      }
    },

    /**
     * SYNC
     * checks which instances for a specific model
     * are being edited and sets them to out of date.
     * @param  {string} id
     */
    setEditingToOutOfDate(cacheId, newData = {}) {
      const editingRegistry = this.editingRegistry;
      const editingInstances = editingRegistry[cacheId];

      if (editingInstances) {
        // set all editing instances as out of date
        editingInstances.forEach(dirty => {
          let setIsOutOfDate = false;
          let rev;

          if (dirty._data) {
            rev = dirty.getData('_rev');
          } else {
            rev = dirty._rev;
          }

          if (rev !== newData._rev) {
            setIsOutOfDate = true;
          }

          if (newData._data) {
            if (rev !== newData._data._rev) {
              setIsOutOfDate = true;
            }
          }

          if (!newData) {
            setIsOutOfDate = true;
          }

          if (setIsOutOfDate) {
            dirty.setOutOfDate(newData);
          }
        });
      }
    },

    setEditingToIsDeleted(cacheId) {
      const editingRegistry = this.editingRegistry;

      if (editingRegistry[cacheId]) {
        // model is currently being edited
        const instances = editingRegistry[cacheId]; // set all instances to deleted

        instances.forEach(function (dirty) {
          dirty.setIsDeleted();
        });
      }
    },

    fetchList(adapterName, appendPath, options = {}) {
      let listId = adapterName;

      if (appendPath) {
        listId = listId + '--' + appendPath.replace(/\//g, '-');
      }

      const adapter = this.getAdapter(adapterName);
      const cacheList = adapter.cacheList;
      const cached = this.getFromCache(listId);

      if (cached && cacheList !== false) {
        const excludeDeleted = R.reject(R.pathEq(['_data', 'isDeleted'], true))(cached);
        return Ember.RSVP.resolve(excludeDeleted);
      }

      options.cache = true;
      options.excludeDeleted = true;
      return this.findAll({
        adapterName,
        appendPath,
        options
      }).then(results => {
        return this._storeToCache(results, listId);
      });
    },

    _hasRecordInList({
      adapterName,
      list = [],
      record
    }) {
      const adapter = this.getAdapter(adapterName);
      const idParam = adapter.idParam;

      if (idParam) {
        const recordId = record.getData(idParam);
        const found = R.find(listRecord => {
          return listRecord.getData(idParam) === recordId;
        })(list);

        if (found) {
          return true;
        }
      }

      if (list.indexOf(record) > -1) {
        return true;
      }

      return false;
    },

    _syncUpdateList({
      adapterName,
      appendPath,
      list,
      action
    }) {
      let listId = adapterName;

      if (appendPath) {
        listId = listId + '--' + appendPath.replace(/\//g, '-');
      } // const cachedList = this.getFromCache(listId) || []


      const crudListId = R.replace(/\//g, '-')(listId);
      const crudList = this.crud.lists[crudListId];

      if (crudList !== null) {
        list.forEach(data => {
          const record = this.setAsRecord({
            adapterName,
            data
          });

          const hasRecordInList = this._hasRecordInList({
            adapterName,
            list: crudList,
            record
          });

          if (action) {
            if (action === 'arrayUnshift') {
              if (hasRecordInList) {
                // cachedList.unshifObject(record)
                crudList.unshifObject(record);
              }
            }

            if (action === 'arrayPush') {
              if (!hasRecordInList) {
                // cachedList.pushObject(record)
                crudList.pushObject(record);
              }
            }
          } else {
            if (!hasRecordInList) {
              // cachedList.pushObject(record)
              crudList.pushObject(record);
            }
          }
        });
      }
      /* Refresh list from crud */
      // this.crud.lists = this.crud.lists


      this._storeToCache(crudList, listId);
    },

    copyRecord(adapterName, record) {
      const adapter = this.getAdapter(adapterName);

      if (Ember.isNone(adapter)) {
        return _nventor.default.throw('Cannot copy, incorrect adapterName given: `' + adapterName + '`');
      }

      const data = adapter.serialize(record);
      const attrs = R.propOr([], '_attrs')(record);
      const attrsObj = R.reduce((acc, key) => {
        acc[key] = record.get(key);
        return acc;
      }, {})(attrs);
      return this.newRecord({
        adapterName,
        data,
        attrs: attrsObj
      });
    },

    batchImport({
      adapterName,
      appendPath = 'batch',
      batchData
    }) {
      const adapter = this.getAdapter(adapterName);

      if (Ember.isNone(adapter)) {
        return _nventor.default.throw('Incorrect adapterName given: `' + adapterName + '`');
      }

      const data = {
        batch: batchData
      };
      return adapter.saveBatch(data, appendPath).then(response => {
        return response;
      }).catch(err => {
        // pass error down the promise chain
        throw err;
      });
    },

    updateBatch({
      adapterName,
      appendPath = 'batch',
      dirtyBatch
    }) {
      const adapter = this.getAdapter(adapterName);

      if (Ember.isNone(adapter)) {
        return _nventor.default.throw('Incorrect adapterName given: `' + adapterName + '`');
      }

      const batch = R.map(dirty => {
        return adapter.serialize(dirty);
      })(dirtyBatch);
      const data = {
        batch
      };
      return adapter.updateBatch(data, appendPath).then(response => {
        return response;
      }).catch(err => {
        // pass error down the promise chain
        throw err;
      });
    }

  });

  _exports.default = _default;
});