define("client/pods/components/channels/shop/campaigns/panels-dash/component", ["exports", "ember-concurrency", "client/mixins/date", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by"], function (_exports, _emberConcurrency, _date, _campaignsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_campaignsListBy.default, _date.default, {
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['periods/months', 'periods/years', 'channels/shop/campaigns/reports/filters', 'taxMethods/filters']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));
      const period = this.getPeriod();
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      this.displayByMonth(period);
      this.tabsManager.setHeader(this.tabParent, this.intl.t('overview'));
    },

    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.display({
        dateStart,
        dateEnd
      });
    },

    display(period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      this.fetchReports({
        dateStart,
        dateEnd
      });
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),

    fetchReports({
      dateStart,
      dateEnd
    }) {
      this.fetchReportTask.perform({
        dateStart,
        dateEnd
      }); // this.fetchYearTask.perform({ dateStart, dateEnd })
    },

    fetchReportTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    }) {
      const monthParams = {
        docType: 'sales',
        dateStart,
        dateEnd,
        match: ['channel'],
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        query: 'shop',
        segments: ['dateZ', 'contact', 'item', 'channel', 'source', 'paymentMethodKey', 'promoCode'] // segments: ['dateZ', 'contact', 'item', 'itemSets', 'channel', 'source', 'paymentMethodKey', 'promoCode']

      };
      const month = yield this.server.call('GET', 'api/protected/reports/dashboard/', monthParams);
      this.set('monthlyReport', month.data);
    }),
    fetchYearTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    }) {// @NOTE: this is taking too long
      // const yearParams = {
      //   docType: 'sales',
      //   dateStart: this.formatDateForUrl(this.getYearStart(dateStart)),
      //   dateEnd: this.formatDateForUrl(this.getYearEnd(dateEnd)),
      //   match: ['channel'],
      //   status: this.get('constants.documentsStatus.final'),
      //   dispatchStatus: this.dispatchStatusFilter,
      //   query: 'shop',
      //   segments: ['month']
      // }
      // const year = yield this.server.call('GET', 'api/protected/reports/dashboard/', yearParams)
      // this.set('yearlyReport', year.data)
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    }),
    actions: {
      display(model) {
        this.display(model);
      },

      displayByMonth(period) {
        this.displayByMonth(period);
      },

      displayByCustom() {
        const dateEndString = this.customDateEnd;
        const dateStartString = this.customDateStart;
        let dateEnd;
        let dateStart;

        if (dateStartString > dateEndString) {
          dateEnd = this.formatDateForUrl(dateStartString);
          dateStart = this.formatDateForUrl(dateEndString);
        } else {
          dateEnd = this.formatDateForUrl(dateEndString);
          dateStart = this.formatDateForUrl(dateStartString);
        }

        const period = {
          dateStart,
          dateEnd
        };
        this.display(period);
      },

      fetchReports({
        dateStart,
        dateEnd
      }) {
        dateStart = dateStart || this.dateStart;
        dateEnd = dateEnd || this.dateEnd;
        this.fetchReports({
          dateStart,
          dateEnd
        });
      }

    }
  });

  _exports.default = _default;
});