define("client/pods/components/websites/panels-display/sub-panels-pages/nav-menu-nested/nav-nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-nested'],
    translate: true,
    item: null,
    isExpanded: false,
    childIsExpanded: false,
    disabled: false,
    isViewable: true,

    init() {
      this._super(...arguments);

      this.set('childIsExpanded', this.isExpanded);
      const selected = this.selected;
      const item = this.item;

      if (selected) {
        if (selected._data._key === item._data._key) {
          if (!this.disabled) {
            this.set('disabled', true);
          }
        }
      }
    },

    draggable: Ember.computed('item._data.isHome', function () {
      if (this.get('item._data.isHome')) {
        return false;
      }

      return true;
    }),
    actions: {
      drop() {
        if (this.onDrop) {
          this.onDrop(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});