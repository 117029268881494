define("client/pods/components/documents/document-print/footer-purchases-terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ixwT+yz",
    "block": "{\"symbols\":[\"term\"],\"statements\":[[4,\"if\",[[24,[\"selectedTerms\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"documents-print__terms documents-print__section\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--bold documents-print__label\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"terms\"],null],false],[0,\":\\n      \"],[7,\"span\",true],[10,\"class\",\"u-no-print\"],[8],[0,\"\\n\\n        \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"selectTerms\",[24,[\"terms1\"]]]],[8],[0,\"1\"],[9],[0,\"\\n        \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"selectTerms\",[24,[\"terms2\"]]]],[8],[0,\"2\"],[9],[0,\"\\n        \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"selectTerms\",[24,[\"terms3\"]]]],[8],[0,\"3\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"selectedTerms\"]]],null,{\"statements\":[[0,\"\\n        \"],[7,\"p\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-purchases-terms/template.hbs"
    }
  });

  _exports.default = _default;
});