define("client/pods/components/products/picker-modal/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    query: '',
    filters: null,
    autofocus: true,
    adapterName: 'products',
    searchMethod: 'searchInputTask',
    searchOptions: null,
    page: 1,
    count: 20,
    // init () {
    //   this.crud.addLists(this, [
    //     'products/lists/filters/search',
    //     'products/lists/filters/sort',
    //     'products/lists/filters/status',
    //     'products/lists/types',
    //     'filters/count',
    //     'filters/sortDirection'
    //   ])
    //   this._super(...arguments)
    // },
    actions: {
      select(onCloseDialogAction, model) {
        onCloseDialogAction();
        this.onSelect(model);
      },

      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});