define("client/pods/payments/virtual-accounts/lists/providers/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _nventor, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    settings: Ember.inject.service(),

    findAll() {
      const data = [{
        label: 'atm_eSun',
        value: 'eSun'
      }, {
        label: 'atm_ubot',
        value: 'ubot'
      }];
      const eSun = this.settings.getProp('tw_payments_eSun', {});
      const ubot = this.settings.getProp('tw_payments_ubot', {});
      const eSunVirtualAccounts = R.propOr([], 'virtualAccounts')(eSun);
      const ubotVirtualAccounts = R.propOr([], 'virtualAccounts')(ubot);
      const enabledProviders = R.pipe(R.concat(ubotVirtualAccounts), R.filter(R.propEq('enabled', true)), R.pluck('provider'))(eSunVirtualAccounts);
      const onlyEnabled = R.filter(list => {
        return R.includes(list.value)(enabledProviders);
      })(data);
      return _nventor.default.resolve(onlyEnabled);
    }

  });

  _exports.default = _default;
});