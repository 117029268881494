define("client/pods/auth/service", ["exports", "ember-concurrency", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    server: Ember.inject.service(),
    storage: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    socket: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    language: 'zh-tw',
    token: '',

    // token is only stored if its valid
    setLanguage(language) {
      if (language) {
        this.set('language', language);

        if (this.get('isLoggedIn')) {
          this.storage.set('language', language);
        }

        return this.intl.setLocale(language);
      }

      return false;
    },

    // setPreferredLanguage (language) {
    //   this.get('storage').set('preferredLanguage', language)
    // },
    loginTask: (0, _emberConcurrency.task)(function* (credentials) {
      this.set('loginFailed', false);

      try {
        const res = yield this.server.login(credentials);
        const token = R.prop('token')(res);
        const tokenData = this.parseToken(token); // const podData = res.data.podData
        // const company = podData.company

        const userId = tokenData.uid;
        const username = tokenData.username;
        const hasMultiplePods = R.pipe(R.propOr([], 'pods'), R.length, R.gt(R.__, 1))(tokenData);
        this.setAsLoggedIn('user', token, tokenData);

        if (hasMultiplePods) {
          return this.router.transitionTo('protected.companies', username, userId);
        }

        const podKey = tokenData.pod;
        const loginData = yield this.loginToCompanyTask.perform(podKey, {
          token,
          tokenData
        });
        return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company);
      } catch (err) {
        let reload = false;

        if (err.data === 'not connected') {
          reload = true;
        } // invalid credentials


        this.setNotAuthenticated('invalid username or password', reload);
      }
    }).drop(),
    loginToCompanyTask: (0, _emberConcurrency.task)(function* (podKey, {
      token,
      tokenData = {}
    } = {}) {
      try {
        if (RA.isNilOrEmpty(tokenData)) {
          const userToken = this.getToken();
          const res = yield this.server.loginToCompany(podKey, userToken);
          token = R.prop('token')(res);
          tokenData = this.parseToken(token);
        }

        if (RA.isNilOrEmpty(token) || !token) {
          return this.logout();
        }

        const company = tokenData.company;
        this.setAsLoggedIn(company, token, tokenData);
        return {
          company,
          username: tokenData.username,
          userId: tokenData.uid,
          language: this.language
        };
      } catch (err) {
        return this.logout();
      }
    }),

    setAsLoggedIn(company, token, tokenData) {
      this.storage.setup(company, tokenData.uid); // set is loggedIn = true

      this.storage.set('uid', tokenData.uid);
      this.storage.set('token', token);
      this.storage.set('tokenData', tokenData);
      const language = this.language || tokenData.language;
      this.storage.set('language', language);

      this._setIsLoggedIn(true);
    },

    getPods() {
      const tokenData = this.storage.get('tokenData') || {};
      return R.propOr([], 'pods')(tokenData);
    },

    getToken() {
      return this.storage.get('token') || this.token;
    },

    parseToken(token) {
      if (token) {
        try {
          return jwt_decode(token);
        } catch (err) {
          this.setNotAuthenticated('connection error');
          return false;
        }
      }
    },

    isAlreadyAuthenticated(companyName, userId, params) {
      if (companyName && userId) {
        this.storage.setup(companyName, userId);
        const language = params.language || this.language;
        this.set('language', language);
        const isLoggedIn = this.storage.get('isLoggedIn') || false;
        return this._setIsLoggedIn(isLoggedIn);
      }

      return this.logout();
    },

    _setIsLoggedIn(isLoggedIn) {
      if (isLoggedIn) {
        const language = this.storage.get('language');
        this.setLanguage(language);
        this.set('isLoggedIn', true);
        this.storage.set('isLoggedIn', true);
        this.socket.connect();
        return true;
      }

      return this.logout();
    },

    /**
     * set loginFailed
     */
    setNotAuthenticated(errorMsg, reload = false) {
      // remove token from session
      this.logout(reload);
      this.set('loginFailed', true);

      if (errorMsg) {
        this.set('loginErrorMsg', errorMsg);
      }
    },

    /**
     * log user out
     */
    logout(reload = false) {
      // set is loggedIn = false
      const pods = this.getUserProp('pods') || [];
      const userId = this.getUserProp('uid') || [];
      const toRemovePods = R.pipe(R.pluck('company'), R.flatten, R.concat(['user']))(pods);
      R.forEach(company => {
        this.storage.setup(company, userId);
        this.storage.clear();
      })(toRemovePods);
      this.set('loginFailed', false);
      this.set('isLoggedIn', false); // setting token

      this.set('token', '');
      this.dataManager.resetCache();
      this.tabsManager.resetTabs();
      this.socket.disconnect();

      if (reload) {
        return this.router.transitionTo('index', {
          queryParams: {
            reload: true
          }
        });
      }

      return this.router.transitionTo('index');
    },

    getUserProp(prop) {
      const userData = this.storage.get('tokenData');

      if (userData) {
        return userData[prop];
      }

      return '';
    },

    actions: {
      logout() {
        this.logout();
      }

    }
  });

  _exports.default = _default;
});