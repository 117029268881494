define("client/pods/components/store-location/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "odKRO7g0",
    "block": "{\"symbols\":[\"@filters\",\"@onDisplay\",\"@onEdit\",\"@results\",\"@onPaginationTask\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content flex-container-row flex-container--wrap flex-container--justify-center\"],[8],[0,\"\\n\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@lists\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@results\",\"@list\",\"@onPaginationTask\",\"@tab\",\"@tableViewOptions\"],[\"store-location/table-view/detail-row\",[23,1,[]],[23,0,[\"crud\",\"lists\"]],[23,2,[]],[23,3,[]],[23,2,[]],[23,4,[]],[23,4,[]],[23,5,[]],[23,6,[]],[28,\"hash\",null,[[\"adapterName\",\"onMainActionIcon\",\"onMainActionLabel\"],[[23,0,[\"adapterName\"]],\"fas fa-toilet-paper\",\"select\"]]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPage\",\"@tagName\"],[[23,1,[]],[23,4,[]],[23,5,[]],true,\"span\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/store-location/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});