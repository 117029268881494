define("client/pods/components/products/tab-container/component", ["exports", "client/mixins/crud", "ember-concurrency", "client/mixins/products-list-by", "client/utils/nventor"], function (_exports, _crud, _emberConcurrency, _productsListBy, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _productsListBy.default, {
    crud: Ember.inject.service(),
    results: null,
    resultsToggle: false,
    adapterName: 'products',
    filtersAdapterName: 'products/filters',

    init() {
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'filters/count', 'filters/criterias', 'products/lists/menu', 'products/inventory/lists/qtyTypes', 'products/lists/invoiceDescriptions', 'products/lists/tags', 'products/lists/tracking', 'products/lists/types', 'products/lists/setTypes', 'products/lists/setTypesFilters', 'settings/roundingAuto', 'statuses', 'toggles/yesNo', 'units', 'units/dimensions', 'units/weight']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
        this.dash({}, results);
      }
    }),

    dash(model, results) {
      this.set('allowQuickAdd', true);
      this.set('showResults', false);
      const tab = this.tab;

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        results = this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
      }

      this.set('newModel', false);
      const subTabOptions = {
        component: 'products/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    /**
     * display the product
     * @param  {object} product model
     */
    display(model, additionalOptions = {}) {
      this.set('allowQuickAdd', false);
      this.set('showResults', false);
      const panelOptions = {
        component: 'products/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    },

    importTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, importData) {
      const payload = {
        batch: importData
      };
      yield this.server.call('POST', 'api/protected/products/batch', payload);
      onCloseDialogAction();
    }).drop(),

    new(subTabOptions) {
      this.set('allowQuickAdd', false);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    inventory(subTabOptions) {
      // create tab within product tab
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    quickAddNew() {
      const name = this.name || '';
      const data = {
        name
      }; // setup new products model

      const model = this.crud.setupNewRecord({
        adapterName: 'products',
        data
      });
      this.set('newModel', model);
    },

    afterQuickAddNew() {
      this.quickAddNew();
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      const results = this.fetchDefaultDataTask.perform({
        adapterName,
        filters
      });
      this.set('results', results);
    },

    actions: {
      dash() {
        this.dash();
      },

      display(model, options) {
        if (model) {
          this.display(model, options);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      inventory(subTabOptions) {
        // create tab within product tab
        this.inventory(subTabOptions);
      },

      new(subTabOptions) {
        // create tab within product tab
        this.new(subTabOptions);
      },

      edit(model, additionalOptions) {
        this.set('allowQuickAdd', false);
        const tab = this.tab;
        const panelOptions = {
          component: 'products/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
      },

      import(subTabOptions) {
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },

      quickAddNew() {
        this.quickAddNew(...arguments);
      },

      afterQuickAddNew() {
        this.afterQuickAddNew(...arguments);
      }

    }
  });

  _exports.default = _default;
});