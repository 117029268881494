define("client/pods/components/websites/panels-display/sub-panels-pages/pages-new/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    saveTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      onAfter
    }, model, options) {
      yield this.createRecordTask.perform({
        adapterName,
        onAfter,
        model,
        ...options
      });
    }).drop(),
    actions: {
      onAfter(model) {
        model.set('preSaved', true);
        this.onDisplay(model);
      }

    }
  });

  _exports.default = _default;
});