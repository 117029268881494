define("client/pods/payments/card/transactions/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/pods/documents/model", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let PaymentsCardTransactionsModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, (_class = (_temp = class PaymentsCardTransactionsModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor3, this);
      (0, _defineProperty2.default)(this, "overDueAfter", 20);
    }

    // minutes
    get ccNumber() {
      const partialNumber = R.pathOr(null, ['_data', 'paymentCreditCard', 'partial_card_number'])(this);

      if (partialNumber) {
        return partialNumber;
      }

      const paymentCreditCardLastFourDigits = R.pathOr(null, ['_data', 'paymentCreditCardLastFourDigits'])(this);

      if (paymentCreditCardLastFourDigits) {
        return paymentCreditCardLastFourDigits;
      }

      const tapPayCardInfo = R.pathOr(null, ['_data', 'firstPaymentResponse', 'card_info'])(this);
      const lastFour = R.propOr(null, 'last_four')(tapPayCardInfo);

      if (lastFour) {
        return lastFour;
      }

      return '';
    }

    get isTapPay() {
      const provider = R.pathOr('', ['_data', 'paymentCreditCardProvider'])(this);
      return provider === 'tapPay';
    }

    get isSuccessfulTransaction() {
      const hasTransactionCode = R.pipe(R.pathOr('', ['_data', 'paymentCreditCardTransactionCode']), RA.isNotNilOrEmpty)(this);
      const hasSuccessfulMessage = R.pipe(R.pathOr('', ['_data', 'paymentResponse', 'msg']), R.toLower, R.equals('success'))(this);
      return hasTransactionCode && hasSuccessfulMessage;
    }

    get isUnsuccessfulTransaction() {
      const isUnsuccessful = R.pipe(R.pathOr('', ['_data', 'paymentsResponses', 'msg']), R.toLower, R.equals('success'), R.not)(this);

      if (isUnsuccessful && !this.isAwaitingPayment) {
        return true;
      } // const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending
      // const isNotSuccessful = this._data.isSuccessful === false
      // return isPending && isNotSuccessful


      return false;
    }

    get isAwaitingPayment() {
      const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending;
      const isNotSuccessful = this._data.isSuccessful === false;
      return isPending && isNotSuccessful && !this.paymentTransactionOverDue;
    }

    get paymentTransactionOverDue() {
      const has3ds = R.pathOr(false, ['_data', 'paymentCreditCardThreeDomainSecure'])(this);

      if (!has3ds) {
        return false;
      }

      const now = this.dateService.getMoment().subtract(this.overDueAfter, 'm');
      const paymentCreditCardTransactionTimestampZ = R.pipe(R.pathOr('', ['_data', 'timestampZ']), time => this.dateService.getMoment(time))(this);
      return now.isAfter(paymentCreditCardTransactionTimestampZ);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  /**
   * @typedef ccTemp
   * @type {Object}
   * @property {string} paymentCreditCardProvider
   * @property {string} paymentCreditCardStatus
   * @property {string} paymentsResponses
   * @property {boolean} isSuccessful
   */

  _exports.default = PaymentsCardTransactionsModel;
});