define("client/pods/components/open-hours/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let OpenHoursComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class OpenHoursComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor2, this);
      this.crud.addLists(this, ['date/lists/hours']);
    }

    get weekdays() {
      return this.date.getWeekdays();
    }

    copyOpeningHours(copyTo) {
      const copyToIndex = this.weekdays.indexOf(copyTo) - 1;
      const copyFrom = this.weekdays[copyToIndex];
      const open = R.view(R.lensPath(['args', 'model', '_data', 'openHours', copyFrom, 'open']))(this);
      const close = R.view(R.lensPath(['args', 'model', '_data', 'openHours', copyFrom, 'close']))(this);

      if (open !== 'none' && close !== 'none') {
        this.args.model.set(`_data.openHours.${copyTo}.open`, open);
        this.args.model.set(`_data.openHours.${copyTo}.close`, close);
      }
    }

    updateModel(day, openClose, openHoursList) {
      const time = openHoursList.get('_data.value');
      return this.args.model.set(`_data.openHours.${day}.${openClose}`, time);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "copyOpeningHours", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "copyOpeningHours"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateModel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateModel"), _class.prototype)), _class));
  _exports.default = OpenHoursComponent;
});