define("client/pods/channels/shop/campaigns/details/bulk-discount-schedules/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        bulkDiscountScheduleId: '',
        dateStartZ: '',
        dateEndZ: '',
        bulkDiscountId: ''
      };
    },

    isActive: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const todayZ = this.getStartOfDayZ();
      const dateStartZ = this.getData('dateStartZ');
      const dateEndZ = this.getData('dateEndZ');

      if (dateStartZ && !dateEndZ && dateStartZ <= todayZ) {
        return true;
      }

      if (dateEndZ && !dateStartZ && dateEndZ >= todayZ) {
        return true;
      }

      if (dateStartZ <= todayZ && dateEndZ >= todayZ) {
        return true;
      }

      return false;
    }),
    isActiveNext: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const todayZ = this.getStartOfDayZ();
      const dateStartZ = this.getData('dateStartZ');

      if (dateStartZ > todayZ) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});