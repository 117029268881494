define("client/pods/components/news/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrbfH1bm",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"news/news-editor\",null,[[\"model\",\"errors\",\"saveTask\",\"isNew\",\"isEditing\",\"isReadonly\",\"isDisabled\",\"tab\",\"isDuplicate\",\"onCancel\",\"onRemoveRecordTask\"],[[24,[\"dirty\"]],[24,[\"errors\"]],[24,[\"updateRecordTask\"]],false,true,false,false,[24,[\"tab\"]],[24,[\"isDuplicate\"]],[24,[\"onCancel\"]],[24,[\"removeRecordTask\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/news/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});