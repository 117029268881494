define("client/pods/components/channels/shop/campaigns/items/table-view/detail-limit-row/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',
    adapterName: 'channels/shop/campaigns/details/limits',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/lists/limit-types']);
    },

    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isSingleOrBulkEditing', function () {
      return !this.isSingleOrBulkEditing;
    }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function* () {
      var _this$tableViewOption;

      if ((_this$tableViewOption = this.tableViewOptions) !== null && _this$tableViewOption !== void 0 && _this$tableViewOption.isBulkEditing) {
        const model = this.model;
        const salesLimitedQty = model.getData('salesLimitedQty');
        const salesPreOrderLimitedQty = model.getData('salesPreOrderLimitedQty');
        const hasSalesLimitedQty = model.getData('hasSalesLimitedQty');
        const hasSalesPreOrderLimitedQty = model.getData('hasSalesPreOrderLimitedQty');
        const allowPreOrder = model.getData('allowPreOrder');

        if (hasSalesLimitedQty && RA.isNilOrEmpty(salesLimitedQty)) {
          return false;
        }

        if (allowPreOrder && hasSalesPreOrderLimitedQty && RA.isNilOrEmpty(salesPreOrderLimitedQty)) {
          return false;
        }

        yield this.updateTask.perform();
      }
    }),
    updateTask: (0, _emberConcurrency.task)(function* () {
      var _this$tableViewOption2, _this$tableViewOption3;

      const model = this.model;
      const product = model.product;
      const updated = yield this.crud.queueUpdateRecordTask.perform({
        adapterName: 'channels/shop/campaigns/details/limits',
        appendPath: model.getData('_key'),
        model,
        component: this
      });

      if (updated && !((_this$tableViewOption2 = this.tableViewOptions) !== null && _this$tableViewOption2 !== void 0 && _this$tableViewOption2.isBulkEditing)) {
        this.set('model', updated);
        this.set('original', false);
        this.set('isSingleEditing', false);
      } else if (updated && (_this$tableViewOption3 = this.tableViewOptions) !== null && _this$tableViewOption3 !== void 0 && _this$tableViewOption3.isBulkEditing) {
        const adapterName = this.adapterName;
        const dirty = this.crud.setupDirty({
          adapterName,
          model: updated,
          attrs: {
            product
          }
        });
        this.set('model', dirty);
      }

      if (updated) {
        this.set('errors', false);
      }
    }),

    cancel() {
      const original = this.original;
      const dirty = this.model;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.set('model', original);
      this.set('original', false);
      this.set('isSingleEditing', false);
      this.set('errors', false);
    },

    actions: {
      edit() {
        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.crud.setupDirty({
          adapterName,
          model,
          attrs: {
            product: model.product
          }
        });
        this.set('original', model); // dirty.set('product', model.get('product'))

        this.set('model', dirty);
        this.set('isSingleEditing', true);
        this.set('errors', false);
      },

      cancel() {
        this.cancel();
      },

      toggleHasSalesLimitedQty() {
        var _model$_data;

        const model = this.model;
        const hasSalesLimitedQty = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.hasSalesLimitedQty) || false;
        model.setData('salesLimitedQty', '');
        model.setData('salesPreOrderLimitedQty', '');

        if (!hasSalesLimitedQty) {
          model.setData('allowPreOrder', false);
          model.setData('hasSalesPreOrderLimitedQty', false);
        }

        this.updateBulkEditingTask.perform();
      },

      toggleAllowPreOrder() {
        const model = this.model;
        model.setData('salesPreOrderLimitedQty', '');
        model.setData('hasSalesPreOrderLimitedQty', false);
        this.updateBulkEditingTask.perform();
      },

      toggleHasPreOrderLimitedQty() {
        const model = this.model;
        model.setData('salesPreOrderLimitedQty', '');
        this.updateBulkEditingTask.perform();
      },

      setDefaultAllowPreOrder(model) {
        const overSalesLimitedQtyStatus = model.getData('overSalesLimitedQtyStatus');
        const allowPreOrder = model.getData('allowPreOrder');

        if (RA.isNilOrEmpty(allowPreOrder)) {
          model.setData('allowPreOrder', false);

          if (R.equals(overSalesLimitedQtyStatus, 'preOrder')) {
            model.setData('allowPreOrder', true);
          }
        }
      }

    }
  });

  _exports.default = _default;
});