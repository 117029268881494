define("client/pods/components/channels/shop/campaigns/items/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    date: Ember.inject.service(),
    tagName: '',
    adapterName: 'channels/shop/campaigns/details',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['filters/count', 'products/lists/menu', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypesFilters', 'statuses', 'channels/shop/campaigns/statuses', 'toggles/yesNo']);
    },

    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isSingleOrBulkEditing', function () {
      return !this.isSingleOrBulkEditing;
    }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function* ({
      appendPath = '',
      model
    }) {
      var _this$tableViewOption;

      if (RA.isNilOrEmpty(appendPath)) {
        appendPath = '';
      }

      if ((_this$tableViewOption = this.tableViewOptions) !== null && _this$tableViewOption !== void 0 && _this$tableViewOption.isBulkEditing) {
        yield this.updateTask.perform({
          appendPath,
          model
        });
      }
    }),
    updateTask: (0, _emberConcurrency.task)(function* ({
      appendPath,
      model
    }) {
      var _this$tableViewOption2;

      model = model || this.model;
      let dirty = model;

      if (!model.isDirty) {
        dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details',
          model
        });
      }

      const product = model.product;
      const updated = yield this.crud.replaceRecordTask.perform({
        adapterName: 'channels/shop/campaigns/details',
        appendPath,
        model: dirty,
        component: this
      });

      if (updated && !((_this$tableViewOption2 = this.tableViewOptions) !== null && _this$tableViewOption2 !== void 0 && _this$tableViewOption2.isBulkEditing)) {
        this.set('model', updated);
        this.set('original', false);
        this.set('isSingleEditing', false); // } else if (updated && this.tableViewOptions?.isBulkEditing) {
        // const adapterName = this.adapterName
        // const dirty = this.crud.setupDirty({
        //   adapterName,
        //   model: updated,
        //   attrs: {
        //     product
        //   }
        // })
        // this.set('model', dirty)
      }

      if (updated) {
        this.set('errors', false);
      }
    }),

    cancel() {
      const original = this.original;
      const dirty = this.model;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.set('model', original);
      this.set('original', false);
      this.set('isSingleEditing', false);
      this.set('errors', false);
    },

    rowClassName: Ember.computed('dropPosition', function () {
      const dropPosition = this.get('dropPosition') || '';
      return `table-view__detail-row ${dropPosition}`;
    }),

    mainActionWithModel(onCloseDialogAction, model) {
      if (this.onMainAction) {
        return this.onMainAction(model);
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },

    actions: {
      onCloseUpdateProductTags() {
        this.updateBulkEditingTask.perform({
          appendPath: '/bulk-discount-conflict'
        });
      },

      drop() {
        if (this.get('tableViewOptions.onDrop')) {
          this.get('tableViewOptions.onDrop')(...arguments);
        }
      },

      dragOver() {
        if (this.get('tableViewOptions.onDragOver')) {
          const dropPosition = this.get('tableViewOptions.onDragOver')(...arguments);
          this.set('dropPosition', dropPosition);
        }
      },

      dragStart() {
        if (this.get('tableViewOptions.onDragStart')) {
          this.get('tableViewOptions.onDragStart')(...arguments);
        }
      },

      edit() {
        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.crud.setupDirty({
          adapterName,
          model
        });
        this.set('original', model); // dirty.set('product', model.get('product'))

        this.set('model', dirty);
        this.set('isSingleEditing', true);
        this.set('errors', false);
      },

      cancel() {
        this.cancel();
      },

      updateBulkDiscountId(model) {
        const activeBulkDiscountId = model.get('activeBulkDiscountId') || '';
        model.setData('bulkDiscountId', activeBulkDiscountId);
      }

    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "mainActionWithModel", [_dec], Object.getOwnPropertyDescriptor(_obj, "mainActionWithModel"), _obj)), _obj)));

  _exports.default = _default;
});