define("client/pods/components/products/files/files-gallery-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9NBZ+96",
    "block": "{\"symbols\":[],\"statements\":[[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-row\",[[12,\"class\",\"has-text-centered\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"files-manager\",[],[[\"@tab\",\"@allowRemove\",\"@model\",\"@resource\",\"@resourceKey\",\"@showDefaultImagePreview\"],[[22,\"tab\"],true,[22,\"model\"],[22,\"resource\"],[22,\"resourceKey\"],[22,\"showDefaultImagePreview\"]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/files/files-gallery-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});