define("client/pods/components/admin/payments/card/panels-checkout-logs/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let AdminPaymentsCardPanelsCheckoutLogsTableViewDetailRowComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class AdminPaymentsCardPanelsCheckoutLogsTableViewDetailRowComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showCartItems", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "showSteps", _descriptor2, this);
    }

    toggleCartItemsView() {
      this.showCartItems = !this.showCartItems;
    }

    toggleStepsView() {
      this.showSteps = !this.showSteps;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showCartItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showSteps", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleCartItemsView", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCartItemsView"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleStepsView", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleStepsView"), _class.prototype)), _class));
  _exports.default = AdminPaymentsCardPanelsCheckoutLogsTableViewDetailRowComponent;
});