define("client/pods/components/menus/nav-tree/nav-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-tree__item'],
    translate: true,
    item: null,
    labelKey: 'label',
    label: Ember.computed('item', 'labelKey', function () {
      return this.item[this.labelKey];
    }),

    click() {
      const item = this.item;
      this.onSelect(item);
    }

  });

  _exports.default = _default;
});