define("client/pods/components/admin/payments/card/panels-abandoned/component", ["exports", "client/config/environment", "client/mixins/crud", "client/mixins/payments-list-by", "ember-concurrency"], function (_exports, _environment, _crud, _paymentsListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _paymentsListBy.default, {
    adapterName: 'admin/payments/card/temp',
    filtersAdapterName: 'admin/payments/card/filters',

    init() {
      this.set('config', _environment.default);

      this._super(...arguments);

      this.fetchAbandoned(true);
    },

    fetchAbandoned(allUnsuccessful = false) {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.set('provider', this.selectedProvider);
      filters.setData('provider', this.selectedProvider);
      filters.setData('abandoned', true);
      filters.setData('allUnsuccessful', allUnsuccessful);
      this.listByTask.perform({
        adapterName: 'admin/payments/card/temp',
        filters: filters,
        abandoned: true
      });
    },

    // customPaginateSearchRecordsTask: task(function * () {
    //   this.crud.paginateSearchRecordsTask.perform({
    //     filters: this.filters,
    //   })
    // },
    markIsSuccessfulTask: (0, _emberConcurrency.task)(function* (transactionCode, tempDoc) {
      const self = this;
      const key = tempDoc._data._key;

      if (window.confirm('successful?')) {
        yield self.get('server').call('PUT', `api/protected/admin/payments/card/abandoned/successful/${key}`, {
          transactionCode
        });
        tempDoc.setData('isUnsuccessful', false);
        tempDoc.setData('isSuccessful', true);

        if (transactionCode) {
          tempDoc.setData('paymentResponse.transactionCode', transactionCode);
        }
      }
    }),
    markIsUnsuccessfulTask: (0, _emberConcurrency.task)(function* (tempDoc) {
      const self = this;
      const key = tempDoc._data._key;
      yield self.get('server').call('PUT', `api/protected/admin/payments/card/abandoned/unsuccessful/${key}`, {});
      tempDoc.setData('isUnsuccessful', true);
      tempDoc.setData('isSuccessful', false);
    }),
    markIsUnsuccessfulAndConfirmedTask: (0, _emberConcurrency.task)(function* (tempDoc) {
      const self = this;
      const key = tempDoc._data._key;
      yield self.get('server').call('PUT', `api/protected/admin/payments/card/abandoned/unsuccessful/confirmed/${key}`, {});
      tempDoc.setData('isUnsuccessful', true);
      tempDoc.setData('isSuccessful', false);
      tempDoc.setData('isConfirmed', true);
    })
  });

  _exports.default = _default;
});