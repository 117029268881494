define("client/pods/components/documents/pending/tab-container/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda", "ramda-extension", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, R, R_, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  // import { computed } from '@ember/object'
  // import nventor from '../../../../../utils/nventor'
  let DocumentPendingComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = (_temp = class DocumentPendingComponent extends _component.default {
    constructor() {
      var _this$args, _this$args$tab, _this$args2, _this$args2$tab, _this$args3, _this$args3$tab, _this$filters, _this$filters$_data, _this$filters2, _this$filters2$_data, _this$filters3, _this$filters3$_data, _this$filters4, _this$filters4$_data, _this$filters5, _this$filters5$_data, _this$filters6, _this$filters6$_data;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "settings", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isLoaded", _descriptor7, this);
      const defaultTaxMethod = this.settings.getProp(`${(_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$tab = _this$args.tab) === null || _this$args$tab === void 0 ? void 0 : _this$args$tab.docType}PendingTaxMethod`) || 'exclusive';
      this.filters = this.search.setupFilters({
        adapterName: 'documents/data/pending/filters',
        defaultFiltersData: {
          docType: (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$tab = _this$args2.tab) === null || _this$args2$tab === void 0 ? void 0 : _this$args2$tab.docType,
          docName: (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$tab = _this$args3.tab) === null || _this$args3$tab === void 0 ? void 0 : _this$args3$tab.docName,
          taxMethod: defaultTaxMethod
        }
      }); // get taxMethod from 

      this.crud.addLists(this, ['documents/lists/types/docTypes', 'taxMethods/filters', {
        adapterName: 'documents/data/pending/types',
        appendPath: (_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : (_this$filters$_data = _this$filters._data) === null || _this$filters$_data === void 0 ? void 0 : _this$filters$_data.docType,
        options: {
          docType: (_this$filters2 = this.filters) === null || _this$filters2 === void 0 ? void 0 : (_this$filters2$_data = _this$filters2._data) === null || _this$filters2$_data === void 0 ? void 0 : _this$filters2$_data.docType
        }
      }, {
        adapterName: 'documents/data/pending/menu',
        appendPath: `${(_this$filters3 = this.filters) === null || _this$filters3 === void 0 ? void 0 : (_this$filters3$_data = _this$filters3._data) === null || _this$filters3$_data === void 0 ? void 0 : _this$filters3$_data.docType}-${(_this$filters4 = this.filters) === null || _this$filters4 === void 0 ? void 0 : (_this$filters4$_data = _this$filters4._data) === null || _this$filters4$_data === void 0 ? void 0 : _this$filters4$_data.docName}`,
        options: {
          docType: (_this$filters5 = this.filters) === null || _this$filters5 === void 0 ? void 0 : (_this$filters5$_data = _this$filters5._data) === null || _this$filters5$_data === void 0 ? void 0 : _this$filters5$_data.docType,
          docName: (_this$filters6 = this.filters) === null || _this$filters6 === void 0 ? void 0 : (_this$filters6$_data = _this$filters6._data) === null || _this$filters6$_data === void 0 ? void 0 : _this$filters6$_data.docName
        }
      }]);
    }

    afterLoadLists() {
      if (this.isLoaded) {
        return false;
      }

      this.isLoaded = true;
      const tab = this.args.tab;
      this.loadTabTask.perform({
        tab
      });
    }

    get listsMenu() {
      var _this$crud, _this$filters7, _this$filters7$_data, _this$filters8, _this$filters8$_data, _this$filters9, _this$filters9$_data, _this$filters10, _this$filters10$_data, _this$filters11, _this$filters11$_data, _this$filters12, _this$filters12$_data;

      const menu = ((_this$crud = this.crud) === null || _this$crud === void 0 ? void 0 : _this$crud.lists[`documents-data-pending-menu--${(_this$filters7 = this.filters) === null || _this$filters7 === void 0 ? void 0 : (_this$filters7$_data = _this$filters7._data) === null || _this$filters7$_data === void 0 ? void 0 : _this$filters7$_data.docType}-${(_this$filters8 = this.filters) === null || _this$filters8 === void 0 ? void 0 : (_this$filters8$_data = _this$filters8._data) === null || _this$filters8$_data === void 0 ? void 0 : _this$filters8$_data.docName}`]) || [];
      console.log(`docType: ${(_this$filters9 = this.filters) === null || _this$filters9 === void 0 ? void 0 : (_this$filters9$_data = _this$filters9._data) === null || _this$filters9$_data === void 0 ? void 0 : _this$filters9$_data.docType}`);
      console.log(`docName: ${(_this$filters10 = this.filters) === null || _this$filters10 === void 0 ? void 0 : (_this$filters10$_data = _this$filters10._data) === null || _this$filters10$_data === void 0 ? void 0 : _this$filters10$_data.docName}`);
      console.log(`listName: documents-data-pending-menu--${(_this$filters11 = this.filters) === null || _this$filters11 === void 0 ? void 0 : (_this$filters11$_data = _this$filters11._data) === null || _this$filters11$_data === void 0 ? void 0 : _this$filters11$_data.docType}-${(_this$filters12 = this.filters) === null || _this$filters12 === void 0 ? void 0 : (_this$filters12$_data = _this$filters12._data) === null || _this$filters12$_data === void 0 ? void 0 : _this$filters12$_data.docName}`);
      console.log(menu);
      return menu;
    }

    get listsPendingDocNames() {
      var _this$crud2, _this$filters13, _this$filters13$_data;

      return ((_this$crud2 = this.crud) === null || _this$crud2 === void 0 ? void 0 : _this$crud2.lists[`documents-data-pending-types--${(_this$filters13 = this.filters) === null || _this$filters13 === void 0 ? void 0 : (_this$filters13$_data = _this$filters13._data) === null || _this$filters13$_data === void 0 ? void 0 : _this$filters13$_data.docType}`]) || [];
    }

    *loadTabTask({
      tab
    }) {
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {// const model = loadInstructions.getData('model')
        // if (model) {
        //   this.display(loadInstructions.getData('model'))
        // } else {
        //   const id = loadInstructions.getData('find.id')
        //   if (id) {
        //     const found = yield this.findTask.perform({
        //       appendPath: id
        //     })
        //     this.display(found)
        //   }
        //   const query = loadInstructions.getData('search.query')
        //   if (query) {
        //     filters.setData('query', query)
        //     const results = yield this.searchRecordsTask.perform({ filters })
        //     const found = results.get('firstObject')
        //     this.display(found)
        //   }
        // }
      } else {
        const menu = this.listsMenu || [];
        const firstPanelModel = menu.firstObject || {};

        if (RA.isNotNilOrEmpty(firstPanelModel)) {
          this.display(firstPanelModel);
        }
      }
    }

    *refetchTask() {
      var _this$filters14, _this$filters14$_data, _this$filters15, _this$filters15$_data, _this$filters16, _this$filters16$_data, _this$filters17, _this$filters17$_data, _this$filters18, _this$filters18$_data, _this$filters19, _this$filters19$_data;

      // first update lists
      yield this.crud.addLists(this, [{
        adapterName: 'documents/data/pending/types',
        appendPath: (_this$filters14 = this.filters) === null || _this$filters14 === void 0 ? void 0 : (_this$filters14$_data = _this$filters14._data) === null || _this$filters14$_data === void 0 ? void 0 : _this$filters14$_data.docType,
        options: {
          docType: (_this$filters15 = this.filters) === null || _this$filters15 === void 0 ? void 0 : (_this$filters15$_data = _this$filters15._data) === null || _this$filters15$_data === void 0 ? void 0 : _this$filters15$_data.docType
        }
      }, {
        adapterName: 'documents/data/pending/menu',
        appendPath: `${(_this$filters16 = this.filters) === null || _this$filters16 === void 0 ? void 0 : (_this$filters16$_data = _this$filters16._data) === null || _this$filters16$_data === void 0 ? void 0 : _this$filters16$_data.docType}-${(_this$filters17 = this.filters) === null || _this$filters17 === void 0 ? void 0 : (_this$filters17$_data = _this$filters17._data) === null || _this$filters17$_data === void 0 ? void 0 : _this$filters17$_data.docName}`,
        options: {
          docType: (_this$filters18 = this.filters) === null || _this$filters18 === void 0 ? void 0 : (_this$filters18$_data = _this$filters18._data) === null || _this$filters18$_data === void 0 ? void 0 : _this$filters18$_data.docType,
          docName: (_this$filters19 = this.filters) === null || _this$filters19 === void 0 ? void 0 : (_this$filters19$_data = _this$filters19._data) === null || _this$filters19$_data === void 0 ? void 0 : _this$filters19$_data.docName
        }
      }]);
      const currentSubTab = this.tabsManager.getActiveSubTab(this.args.tab);

      if (currentSubTab) {
        return this.display(currentSubTab);
      }

      const menu = this.menuList || [];
      const firstPanelModel = menu.firstObject || {};
      return this.display(firstPanelModel);
    }

    display(subTabOptions) {
      var _subTabOptions$_data, _subTabOptions$_data2, _this$filters20, _this$filters20$_data;

      const tab = this.args.tab; // @NOTE: this is bad. due to a design flaw
      // when clicking a menu list item, the subTabOptions is a model.
      // after instantiating a subTab the model does not have _data
      // solution, also have ._data for subTab models (keep a copy of subTab in _data)

      this.filters.setData('combined', (subTabOptions === null || subTabOptions === void 0 ? void 0 : subTabOptions.combined) || (subTabOptions === null || subTabOptions === void 0 ? void 0 : (_subTabOptions$_data = subTabOptions._data) === null || _subTabOptions$_data === void 0 ? void 0 : _subTabOptions$_data.combined));
      this.filters.setData('type', (subTabOptions === null || subTabOptions === void 0 ? void 0 : subTabOptions.type) || (subTabOptions === null || subTabOptions === void 0 ? void 0 : (_subTabOptions$_data2 = subTabOptions._data) === null || _subTabOptions$_data2 === void 0 ? void 0 : _subTabOptions$_data2.type));
      this.tabsManager.setHeader(tab, this.intl.t(`${(_this$filters20 = this.filters) === null || _this$filters20 === void 0 ? void 0 : (_this$filters20$_data = _this$filters20._data) === null || _this$filters20$_data === void 0 ? void 0 : _this$filters20$_data.docType}`));
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    } // @action
    // displayByProducts () {
    // }
    // isDispatchedProcessModel: computed('crud.lists.[]', function () {
    //   const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   const isDispatched = this.get('constants.dispatchProcess.dispatched')
    //   return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses)
    // }),
    // updateDocInfoTask: task(function * (dirty) {
    //   const adapterName = this.adapterName
    //   return yield this.updateRecordTask.perform({
    //     adapterName,
    //     appendPath: '/info',
    //     model: dirty
    //   })
    // }).drop(),
    // documentsListsMenu: computed('listsIsLoaded', 'docType', 'docName', function () {
    //   const docType = this.filters?._data?.docType
    //   const docName = this.filters?._data?.docName
    //   return this.get(`crud.lists.documents-lists-menu--${docType}-${docName}`) || []
    // }),
    // resultsProxy: computed('results.[]', function () {
    //   const results = this.results || []
    //   return R.map(result => nventor.createModel(ModelProxy, {}, {
    //     model: result,
    //     isSelected: false,
    //     showDetails: false // @TODO: get this from settings
    //   }), results)
    // }),
    // dash (model, results) {
    //   const tab = this.tab
    //   this.set('showResults', false)
    //   if (nventor.isNilOrEmpty(results)) {
    //     const adapterName = this.adapterName
    //     const filters = this.filters
    //     filters.setData('query', '')
    //     results = this.fetchDefaultDataTask.perform({ adapterName, filters })
    //     this.set('results', results)
    //   }
    //   const subTabOptions = {
    //     component: 'documents/panel-dash'
    //   }
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },
    // /**
    //  * display document
    //  * @param  {object} document model
    //  */
    // display (model) {
    //   this.set('showResults', false)
    //   const component = this.get('config.displayComponent')
    //   const subTabOptions = {
    //     component: component,
    //     model: model,
    //     config: this.config
    //   }
    //   const tab = this.tab
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },
    // new () {
    //   this.set('showResults', false)
    //   const self = this
    //   const component = self.get('config.newComponent')
    //   const subTabOptions = {
    //     component: component,
    //     config: self.get('config')
    //   }
    //   const tab = this.tab
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },
    // actions: {
    //   dash () {
    //     this.dash()
    //   },
    //   display (model) {
    //     this.set('showResults', false)
    //     if (model) {
    //       this.display(model)
    //     } else {
    //       // if no model then display dash (when model has been deleted)
    //       this.dash()
    //     }
    //   },
    //   new () {
    //     this.new(...arguments)
    //   },
    //   close (panel) {
    //     this.tab.closePanel(panel)
    //   },
    //   edit (model) {
    //     this.set('showResults', false)
    //     const self = this
    //     const component = self.get('config.editComponent')
    //     const subTabOptions = {
    //       component: component,
    //       model: model,
    //       config: self.get('config')
    //     }
    //     const tab = this.tab
    //     this.tabsManager.replaceSubTab(tab, subTabOptions)
    //   }
    // }


  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "refetchTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "refetchTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "display", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype)), _class));
  _exports.default = DocumentPendingComponent;
});