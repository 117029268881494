define("client/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "": "",
    "#": "#",
    "%": "%",
    "...": "...",
    "1 day": "1 day",
    "1 hour": "1 hour",
    "1 point equal to": "1 Point = $",
    "1 week": "1 week",
    "120+ days": "120+ Days",
    "15 minutes": "15 minutes",
    "2 days": "2 days",
    "2 hours": "2 hours",
    "3 days": "3 days",
    "3 hours": "3 hours",
    "30 days": "30 Days",
    "30 minutes": "30 minutes",
    "3rd party": "3rd Party",
    "4 days": "4 days",
    "4 hours": "4 hours",
    "5 hours": "5 hours",
    "6 hours": "6 hours",
    "60 days": "60 Days",
    "90 days": "90 Days",
    "ATM_BOT": "Bank of Taiwan ATM",
    "ATM_CATHAY": "Cathay United Bank ATM(Not available)",
    "ATM_CHINATRUST": "Chinatrust Bank ATM",
    "ATM_ESUN": "E.SUN Bank ATM(Not available)",
    "ATM_FIRST": "First Bank ATM",
    "ATM_FUBON": "Taipei Fubon Bank ATM(Not available)",
    "ATM_LAND": "Land Bank of Taiwan ATM",
    "ATM_TACHONG": "Ta Chong Bank ATM",
    "ATM_TAISHIN": "Taishin Bank ATM",
    "HCT": "HCT",
    "Taiwan": "Taiwan",
    "_blank_": " ",
    "abandoned": "Abandoned",
    "access token": "Access token",
    "account": "Account",
    "account description": "Account Description",
    "account last 5 digits": "Acc Last 5 Digits",
    "account number": "Account Number",
    "account type": "Account Type",
    "accountant (view only)": "Accountant (View Only)",
    "accounts": "Accounts",
    "active": "Active",
    "activity": "Activity",
    "add": "Add",
    "add activity": "Add Activity",
    "add all": "Add All",
    "add bulk": "Bulk Add",
    "add business": "Add Business",
    "add cell": "Add Cell",
    "add custom worksheet": "Add Custom Worksheet",
    "add department": "Add Department",
    "add detail": "Add Detail",
    "add link to image": "Add link to image",
    "add new adjustment": "Add New Adjustment",
    "add person": "Add Person",
    "add product": "Add Product",
    "add product to shop": "Add product",
    "add row": "Add Row",
    "add schedule": "Add Schedule",
    "add surcharge": "Add Surcharge",
    "add tab": "Add Tab",
    "add to cart": "Add to Cart",
    "addAll": "Add All",
    "added": "Added",
    "additional description": "Additional Description",
    "additional free shipping rule": "Additional free shipping rule",
    "additional information": "Additional Information",
    "additional message": "Additional Message",
    "address": "Address",
    "addresses": "Addresses",
    "adjust all commission base costs": "Adjust all commission base costs",
    "adjust inventory": "Adjust Inventory",
    "adjust member level": "Adjust Member Level",
    "adjusted commission base cost": "Adjusted Commission Base Cost",
    "adjusted commission total amount": "Adjusted Commission Total Amount",
    "adjustment": "Adjustment",
    "adjustment this month": "Adjustment This Month",
    "admin": "Admin",
    "advanced": "Advanced",
    "advancedTemplate": "Advanced Template",
    "all": "All",
    "all activity": "all activity",
    "all qty": "All Qty",
    "all undispatched": "All Undispatched",
    "allow access date end": "Allow Access Date End",
    "allow access date start": "Allow Access Date Start",
    "allow discount for on sale items": "Allow Discount For On Sale Items",
    "allow e-invoices to be issued": "Allow e-invoices to be issued",
    "allow on sale items": "Allow On Sale Items",
    "allow pick up": "Allow Pick Up",
    "allow preOrder": "Allow Pre Order",
    "allow public template editor": "Allow public template editor",
    "allow shipping": "Allow Shipping",
    "allow to earn reward points": "Allow to Earn Reward Points",
    "allow to re-index all products": "Allow to Re-Index All Products",
    "allow to spend reward points": "Allow to Spend Reward Points",
    "allow track reward for non member": "Allow Track Reward For Non Member",
    "allowed product tags": "Allowed Product Tags",
    "allowed shop": "Allowed Shop",
    "already imported": "Already imported",
    "already sent": "Already Sent",
    "always charge shipping fee": "Always Charge Shipping Fee",
    "always display wizard": "Always Display Wizard",
    "always hide menu": "Always Hide Menu",
    "amount": "Amount",
    "amount due": "Amount Due",
    "answer": "Answer",
    "apply to all product": "Apply To All Product",
    "approve": "Approve",
    "are you sure you want to cancel invoice number": "Are you sure you want to cancel Invoice Number",
    "are you sure you want to cancel this order?": "Are you sure you want to cancel this Order?",
    "are you sure you want to cancel?": "Are you sure you want to Cancel?",
    "are you sure you want to clear page": "Are you sure you want to clear page?",
    "are you sure you want to close?": "Are you sure you want to Close?",
    "are you sure you want to delete": "Are you sure you want to Delete?",
    "are you sure you want to delete comment": "Are you sure you want to delete comment",
    "are you sure you want to delete image?": "Are you sure you want to Delete Image?",
    "are you sure you want to delete variant option": "Are you sure you want to delete variant option? Related child products will be deleted.",
    "are you sure you want to dispatch all?": "Are you sure you want to dispatch all?",
    "are you sure you want to hold all?": "Are you sure you want to hold all?",
    "are you sure you want to logout": "Are you sure you want to Logout?",
    "are you sure you want to mark as completed?": "Are you sure you want to mark as completed?",
    "are you sure you want to merge contacts": "Are you sure you want to Merge Contacts?",
    "are you sure you want to re-index all products? this may take some time.": "Are you sure you want to re-index all products? This may take some time.",
    "are you sure you want to re-sync product": "Are you sure you want to Re-Sync product?",
    "are you sure you want to remove": "Are you sure you want to Remove?",
    "are you sure you want to remove entire row?": "are you sure you want to remove entire row?",
    "are you sure you want to remove this cell?": "are you sure you want to remove this cell?",
    "are you sure you want to remove this custom sheet?": "are you sure you want to remove this custom sheet?",
    "are you sure you want to reprint": "Are you sure you want to reprint?",
    "are you sure you want to reset all rows?": "Are you sure you want to reset all rows?",
    "are you sure you want to revert to draft": "Are you sure you want to Revert to Draft?",
    "are you sure you want to save as template": "Are you sure you want to Save as Template?",
    "are you sure you want to undispatch all?": "Are you sure you want to undispatch all?",
    "are you sure you want to undo cancel?": "Are you sure you want to Undo Cancel?",
    "are you sure you want to undo void for document": "Are you sure you want to undo void for document",
    "are you sure you want to undo?": "Are you sure you want to undo?",
    "are you sure you want to unhold all?": "Are you sure you want to unhold all?",
    "are you sure you want to void document": "Are you sure you want to void document",
    "area": "Area",
    "array": {
      "min": "minimum requirement not met. {limit}"
    },
    "arrival date": "Arrival Date",
    "assign to": "Assign To",
    "at least 6 characters": "至少6個字符",
    "atm_approved": "Approved",
    "atm_attentionRequired": "Attention Required",
    "atm_eSun": "玉山銀行 E. Sun",
    "atm_expired": "Expired",
    "atm_pending": "Pending",
    "atm_ubot": "聯邦銀行 Union Bank Of Taiwan",
    "attentionRequired": "Attention Required",
    "auto": "Auto",
    "auto cancel expired atm orders": "Auto cancel expired atm orders",
    "auto generate detail row": "Auto Generate Detail Row",
    "auto generate sales history (optional)": "Auto Generate Sales History (optional)",
    "auto index": "Auto Index",
    "auto link member": "Auto Link Member",
    "auto print invoices": "Auto Print Invoices",
    "auto send birthday rewards": "Auto Send Birthday Rewards",
    "automatically end campaign": "Automatically End Campaign",
    "automatically send payment reminders": "Automatically Send Payment Reminders",
    "automatically update all following invoices": "Automatically update all following Invoices",
    "automation": "Automation",
    "automation priority": "Automation Priority",
    "automation_action_mayAdd": "Show Additional Items",
    "automation_action_mustAdd": "Automatically Add Items to Cart",
    "automation_selection_type": "How to add",
    "automation_then": "Then",
    "automation_title": "Message",
    "automation_when": "When",
    "automations": "Automations",
    "available": "Available",
    "available preOrder qty": "Available Pre Order Qty",
    "available qty": "Available Qty",
    "available to sell qty": "Available To Sell Qty",
    "average": "Average",
    "average item price": "Av. Item Price",
    "average order value": "Av. Order Value",
    "average price": "Average Price",
    "awaiting payment": "Awaiting Payment",
    "awaitingPayment": "Awaiting Payment",
    "b2b": "B2B,",
    "b2c": "B2C",
    "back to previous": "Back",
    "back_page": "Back",
    "balance sheet": "Balance Sheet",
    "bank": "Bank",
    "banking details": "Banking Details",
    "banner bottom": "Banner Bottom",
    "banner top": "Banner Top",
    "barcode": "Barcode",
    "base cost": "Base Cost",
    "basic": "basic",
    "batch number": "Batch Number",
    "beginning of month": "Beginning Of Month",
    "beta": "Beta",
    "bigordr-banner": "bigordr-banner",
    "bigordr-cell": "Cell",
    "bigordr-flex-cell": "bigordr flex cell",
    "bigordr-grid": "bigordr-grid",
    "bigordr-grid-images": "bigordr-grid-images",
    "bigordr-inner-row": "bigordr-inner-row",
    "bigordr-strip": "bigordr-strip",
    "billing address": "Billing Address",
    "billingAddress": "Billing Address",
    "birthday": "Birthday",
    "birthday email": "Birthday Email",
    "birthday reward recipients": "Birthday Reward Recipients",
    "birthday rewards": "Birthday Rewards",
    "blog": "Blog",
    "blogs": "Blogs",
    "blogs images ratio": "Blogs Images Ratio",
    "blogs tags": "Blogs Tags",
    "book qty": "Book Qty",
    "bot": "Bot",
    "browse": "Browse",
    "bulk discount": "Bulk Discount",
    "bulk discount schedules": "Bulk Discount Schedules",
    "bulk discounts": "Bulk Discounts",
    "bulk edit": "Bulk Edit",
    "businesses": "Businesses",
    "button": "Button",
    "button label": "Button Label",
    "button1": "button1",
    "button2": "button2",
    "button3": "button3",
    "by orders": "By Orders",
    "by products": "By Products",
    "byAllOrder": "Shipping Data - All Order",
    "byDetails": "Shipping Data with Custom Detail",
    "byDetailsWithGrandTotals": "Shipping Data with Custom Detail + Grand Totals",
    "callback url": "Callback Url",
    "campaign": "Campaign",
    "campaign images": "Campaign Images",
    "campaign name": "Campaign name",
    "campaign period": "Campaign Period",
    "campaign products": "Campaign Products",
    "campaign qty": "Campaign Qty",
    "campaign_description": "Campaign Description",
    "campaign_expired_template": "Expired Template",
    "campaigns": "Campaigns",
    "can preOrder": "Can pre-order",
    "can split by transporter": "Can Split By Transporter",
    "cancel": "Cancel",
    "cancel invoice": "Cancel Invoice",
    "cancel invoice reason": "Cancel Invoice Reason",
    "cancel order": "Cancel Order",
    "cancel_save": "Cancel",
    "cancelled": "Cancelled",
    "cancelled invoices": "Cancelled Invoices",
    "cannot import due to incorrect data": "Cannot import due to incorrect data",
    "cannot issue e-invoices": "Cannot Issue E-invoices",
    "cannot merge. contacts are linked to different members": "Cannot merge, contacts are linked to different members.",
    "cannot refund credit card": "Cannot Refund Credit Card",
    "cannot send": "Cannot Send",
    "cannot upload file, files must be under 10mb": "Cannot upload file. Files must be under 10 MB.",
    "cart": "cart",
    "cart items": "cart items",
    "cash": "Cash",
    "cash before delivery": "Cash Before Del.",
    "cash book": "Cash Book",
    "cash on delivery": "Cash On Delivery",
    "cash payment fee": "Cash Payment Fee",
    "cashBeforeDelivery": "Cash Before Del.",
    "category": "Category",
    "cc_approved": "Approved",
    "cc_awaitingPayment": "Awaiting Credit Card Payment",
    "cc_awaiting_3ds_verification": "Awaiting 3DS Verification",
    "cc_declined": "Declined",
    "cc_paidOut": "Paid Out",
    "cc_partial_card_number": "Partial Card Number",
    "cc_ref": "Payment Ref",
    "cc_refund": "Credit Card Refund",
    "cc_refundRequested": "Refund Requested",
    "cc_refund_total": "Refund Total",
    "cc_refunded": "Refunded",
    "cc_total": "Total",
    "cc_unsuccessful": "unsuccessful",
    "change language? will reload page": "Change Language? Will reload Page",
    "change password": "Change Password",
    "channel": "Channel",
    "channel id": "Channel Id",
    "channel secret": "Channel Secret",
    "channels": "Channels",
    "charge shipping fee": "Charge Shipping Fee",
    "charge to different account": "Charge to Different Account",
    "charge to different customer account": "Charge to Different Customer Account",
    "charge to different supplier account": "Charge to Different Supplier Account",
    "chat messages": "Chat Messages",
    "check order status": "check order status",
    "checkout": "checkout",
    "checkout logs": "Checkout Logs",
    "checkout/details": "Checkout Shipping (Step 2/4)",
    "checkout/payment": "Checkout Payment (Step 3/4)",
    "checkout/success": "Checkout Sucessful (Step 4/4)",
    "checkout/summary": "Checkout Summary (Step 1/4)",
    "choose between": "Choose Between",
    "choose from qty": "Choose From Qty",
    "choose qty": "Choose Qty",
    "choose template": "Choose a Template",
    "chooseBetween": "Choose Between",
    "clear page": "Clear page",
    "close": "Close",
    "close preview": "Close Preview",
    "closing balance": "Closing Balance",
    "cm": "cm",
    "code": "Code",
    "color template": "Color Template",
    "color theme": "Color Theme",
    "combine labels": "Combine Labels",
    "comment": "Comment",
    "comments": "Comments",
    "commission": "Commission",
    "commission %": "Commission %",
    "commission % per item": "Commission % Per Item",
    "commission amount": "Commission Amount",
    "commission amount per item": "Commission Amount Per Item",
    "commission base cost": "Commission Base Cost",
    "commission base cost per item": "Commission Base Cost per Item",
    "commission base costs": "Commission Base Costs",
    "commission calculation": "Commission Calculation",
    "commission due date": "Commission Due Date",
    "commission due from last month": "Commission Due From Last Month",
    "commission due this month": "Commission Due This Month",
    "commission payable to": "Commission Payable To",
    "commission per unit": "Commission per unit",
    "commission refunded this month": "Commissions Refunded This Month",
    "commission total": "Total Commission",
    "commission total amount": "Commission total amount",
    "commission_adjustment_error_msg": "Error! Max discount amount is 10",
    "commission_byTotal": "Commission",
    "commission_normalPercent": "Commission (%)",
    "commission_perItem": "Commission",
    "commission_twPercent": "Commission (折)",
    "commissions": "Commissions",
    "commissions adjustment": "Commissions Adjustment",
    "commissions calculation method": "Commissions calculation method",
    "commissions due": "Commissions Due",
    "commissions due returned": "Commissions Due Returned",
    "commissions generated": "Commissions Generated",
    "commissions generated returned": "Commissions Generated Returned",
    "commissions payouts": "Commissions Payouts",
    "commissions refunded": "Commissions Refunded",
    "commissions summary for this month": "Commissions Summary for This Month",
    "company": "Company",
    "company address": "Company Address",
    "company name": "Company Name",
    "company opening hours": "Oompany Hpening hours",
    "company telephone": "Company Telephone",
    "complete": "Complete",
    "complex": "complex",
    "compound": "Compound",
    "confirm password": "Confirm Password",
    "confirm_ignore": "confirm ignored",
    "connect domain": "Connect Domain",
    "connection error": "Connection Error",
    "consignment": "Consignment",
    "consignment delivery": "Consignment Delivery",
    "consignment qty": "Consignment Qty",
    "consignment receipt": "Consignment Receipt",
    "consignments": "Consignments",
    "contact": "Contact",
    "contact email": "Contact email",
    "contact info": "Contact Info",
    "contact is not a member, send reward": "Contact is not a member, send reward?",
    "contact name": "Contact name",
    "contact person": "Contact Person",
    "contact phone number": "Contact Phone Number",
    "contact us": "Contact Us",
    "contacts": "Contacts",
    "contacts rewards": "Contacts Rewards",
    "contacts without member level": "Contacts Without Member Level",
    "contains an invalid value": "contains an invalid value",
    "contains duplicate value": "contains duplicate value",
    "conversion rate": "conversion rate",
    "convert set items to only show set child items": "Convert set items to only show set child items",
    "convert to date": "Convert To Date",
    "convert to index": "Convert To Index",
    "convert to number": "Convert To Number",
    "copy": "Copy",
    "copy & paste": "Copy & Paste",
    "copy from above": "Copy From Above",
    "copy images": "Copy Images",
    "copy link": "Copy Link",
    "copy payment link": "Copy Payment Link",
    "cost of sales": "Cost Of Sales",
    "countries": "Countries",
    "country": "Country",
    "create from": "Create From",
    "create hct contact": "Create HCT Contact",
    "create new adjustment": "Create New Adjustment",
    "create new commissions": "Create New Commissions",
    "created by": "Created By",
    "created date": "Created Date",
    "credit": "Credit",
    "credit card": "Credit Card",
    "credit card history": "Credit Card History",
    "credit card provider: tap pay": "Credit Card provider: Tap Pay",
    "credit card refunded email": "Credit Card Refunded Email",
    "credit card status": "Credit Card Status",
    "credit card transactions": "Credit Card Transactions",
    "credit note": "Credit Note",
    "creditCard": "Credit Card",
    "creditors": "Creditors",
    "criteria": "Criteria",
    "currency": "Currency",
    "currency decimal rounding": "Currency Decimal Rounding",
    "current": "Current",
    "current active": "Current Active",
    "current bulk discount": "Current Bulk Discount",
    "current document": "Current Document",
    "current month": "Current Month",
    "current points": "Current Points",
    "current year": "Current Year",
    "current_lang": "ENGLISH",
    "custom": "Custom",
    "custom width": "Custom width",
    "customer": "Customer",
    "customer account": "Customer Account",
    "customer activity": "Customer Activity",
    "customer payment method": "Customer Payment Method",
    "customer payment terms": "Customer Payment Terms",
    "customer statements": "Customer Statements",
    "customer tags": "Customer Tags",
    "customize worksheet": "Customize Worksheet",
    "customized export": "Custom Export",
    "daily revenue": "Daily Revenue",
    "dashboard": "Dashboard",
    "data": "Data",
    "data out of date": "Data out of date",
    "data was updated": "Data was Updated",
    "date": "Date",
    "date conflict": "Date conflict",
    "date end": "Date End",
    "date start": "Date Start",
    "date_period": "Period",
    "day": "Day",
    "day of month": "Day Of Month",
    "days": "Days",
    "debit": "Debit",
    "debit note": "Debit Note",
    "debtors": "Debtors",
    "decrease": "Decrease",
    "default": "Default",
    "default email content": "Default Email Content",
    "default facebook page": "Default Facebook Page",
    "default for documents": "Default For Documents",
    "default image": "預設照片",
    "default item tax": "Default Item Tax",
    "default name": "Default Name",
    "default payment method": "Default Payment Method",
    "default purchase price setting": "Default Purchase Price Setting",
    "default selling price setting": "Default Selling Price Setting",
    "default settings": "Default Settings",
    "default shipping": "Default Shipping",
    "default status for when over sales limited qty": "Default Status For When Over Sales Limited Qty",
    "default tax": "Default Tax",
    "default tax on purchases for products": "Default Tax On Purchases For Products",
    "default tax on purchases for services": "Default Tax On Purchases For Services",
    "default tax on sales for products": "Default Tax On Sales For Products",
    "default tax on sales for services": "Default Tax On Sales For Services",
    "defaultCanceledEmailMessage": "Your order has been cancelled, the following is the information about canceling this order",
    "defaultDispatchedEmailMessage": "Your order has been shipped for you today",
    "defaultOrderedEmailMessage": "Thank you for your support and love, you have completed the order, the following is your order details",
    "defaultRefundedEmailMessage": "Has been refunded, the following products have been refunded",
    "defaults": "Defaults",
    "defaults faqs": "Default FAQs",
    "delete": "Delete",
    "delete_keyword": "DELETE",
    "deleted": "Deleted",
    "delivered": "Delivered",
    "delivered qty": "Delivered Qty",
    "deliveries": "Deliveries",
    "delivery": "Delivery",
    "delivery address": "Delivery Address",
    "delivery date": "Delivery Date",
    "delivery date options": "Delivery Date Options",
    "delivery note": "Delivery Note",
    "delivery notes": "Delivery Notes",
    "deliveryAddress": "Delivery Address",
    "denied product tags": "Denied Product Tags",
    "department": "Department",
    "department name": "Department Name",
    "departments": "Departments",
    "description": "Description",
    "deselect all": "Deselect All",
    "detail": "Detail",
    "detailLimitTypes_campaign": "campaign qty",
    "detailLimitTypes_inventory": "inventory",
    "detailType_address": "Address",
    "detailType_email": "Email",
    "detailType_note": "Note / Link",
    "detailType_phone": "Phone / Fax / Mobile",
    "detailed statement": "Detailed Statement",
    "details": "Details",
    "did not receive an activation email? resend activation email": "Did not receive an activation email? Resend activation email-",
    "dimensions_height": "H",
    "dimensions_length": "L",
    "dimensions_width": "W",
    "disc": "Disc",
    "discInclTotal": "Inclusive Discount",
    "discount": "Discount",
    "discount %": "Discount %",
    "discount amount": "Discount Amount",
    "discount for on sale items": "Disount For On Sale Items",
    "discount rate": "Discount %",
    "discount rate for on sale items": "Disount Percentage For On Sale Items",
    "discount total": "Discount Total",
    "discount when buy items": "discount when buy items",
    "discount when items value": "discount when items value",
    "discounts": "Discounts",
    "dispatch": "Dispatch",
    "dispatch (view only)": "Dispatch (View Only)",
    "dispatch all": "Dispatch All",
    "dispatch date": "Dispatch Date",
    "dispatch process for new orders": "Dispatch process for new orders",
    "dispatch qty": "Dispatch Qty",
    "dispatch status": "Dispatch Status",
    "dispatch today": "Dispatch Today",
    "dispatched": "Dispatched",
    "dispatched by date": "Dispatched By Date",
    "display in shop": "Display in Shop",
    "display order": "Display Order",
    "display products in shop": "Display Products In Shop",
    "display style": "Display Style",
    "display wizard": "Display Wizard",
    "display_faq": "Display",
    "district": "District",
    "districts": "Districts",
    "dividends": "Dividends",
    "doc": "Doc",
    "doc-pending": "Pending Orders",
    "document": "Document",
    "document delivery dates": "Document Delivery Dates",
    "document discount rates": "Document Discount Rates",
    "document number": "Document Number",
    "documentationId": "documentationId",
    "documents": "Documents",
    "does not contain required value": "does not contain required value",
    "domain": "Domain",
    "domain connected": "Domain Connected",
    "domain not connected": "Domain Not Connected",
    "domain_validation_step1": "<ol class='spaced-list'> <li>In a second browser window or tab, sign in to your domain host account. For details, see&nbsp;<a href='/a/answer/48323' rel='noopener'>Identify your domain&nbsp;host</a>.</li> <li>Go to your domain’s DNS records. The page might be called something like <strong>DNS Management</strong>, <strong>Name</strong> <strong>Server Management</strong>, <strong>Control Panel</strong>, or <strong>Advanced Settings</strong>. </li> <li>Select the option to add a new record.</li> </ol>",
    "domain_validation_step1_label": "Step 1: Go to your domain's DNS records",
    "domain_validation_step2": "<ol class='spaced-list'> <li>For the record type, select <strong>TXT</strong>.</li> <li>In the <strong>Name/Host/Alias</strong> field, enter <strong>@</strong> or leave it blank.<br> Your host might require you&nbsp;to enter your domain, which looks like <em>example.com</em>,&nbsp;into this field. Your other DNS records might indicate what you should enter. </li> <li>In the <strong>Time to Live (TTL)</strong> field, enter <strong>86400</strong> or leave the default.</li> <li>In the <strong>Value/Answer/Destination</strong> field, paste the verification record you copied in step 1.</li> <li>Save the record.</li> </ol>",
    "domain_validation_step2_label": "Step 2: Add the verification record to your domain host",
    "domain_validation_step3_label": "Step 3: Come back and verify",
    "done": "Done",
    "dont have an account? try control pod for free": "Don't have an account? Try Control Pod for FREE!",
    "double-row": "Double Row",
    "download": "Download",
    "draft": "Draft",
    "drafts": "Drafts",
    "due": "Due",
    "due date": "Due Date",
    "duplicate": "Duplicate",
    "duplicate addresses": "Duplicate Addressses",
    "duplicate product": "Duplicate Product",
    "duplicate value": "Duplicate Value",
    "duplicate waybills": "Duplicate Waybills",
    "duplicated contact data": "Duplicated Contact Data",
    "duration": "Duration",
    "each": "Each",
    "earliest": "Earliest",
    "earned points": "Earned Points",
    "earned reward points": "Earned Reward Points",
    "ecPay": "EC Pay",
    "ecPay_eInvoice": "EC Pay: E-Invoice",
    "ecPay_hash_iv": "Hash IV",
    "ecPay_hash_key": "Hash Key",
    "ecPay_merchant": "Merchant",
    "ecPay_merchant_id": "Merchant ID",
    "ecPay_payments": "ecPay_payments",
    "ecPay_virtualAccount": "ecPay_virtualAccount enabled",
    "edit": "Edit",
    "edit sales invoice": "Edit Sales Invoice",
    "edit sales order": "Edit Sales Order",
    "email": "Email",
    "email delivery note": "Email Delivery Note",
    "email document": "Email Document",
    "email message": "Email Message",
    "email not sent": "Email not sent",
    "email placeholder": "Email Placeholder",
    "email sent to": "Email sent to",
    "email settings": "Email Settings",
    "email signature": "Email Signature",
    "email subject": "Email Subject",
    "email_canceled_order_default_subject": "Your order has been canceled",
    "email_dispatched_default_subject": "Dispatch Notification",
    "email_event_delivered": "Delivered",
    "email_event_failed": "Failed",
    "email_event_opened": "Opened",
    "email_placeholder_desc": "Copy and paste the placeholder in your text to where you want to display",
    "email_queued": "Queued",
    "email_refunded_order_default_subject": "Your order has been refunded",
    "emails": "Emails",
    "emails to send": "Emails to send",
    "employee": "Employee",
    "empty": "Empty",
    "enable": "Enable",
    "enable facebook chat": "Enable Facebook Chat",
    "enable facebook chat by default": "Enable Facebook Chat By Default",
    "enable facebook pixel conversion api": "Enable Facebook Pixel Conversion API",
    "enable line chat": "Enable Line Chat",
    "enable line chat by default": "Enable Line Chat By Default",
    "enabled": "Enabled",
    "end": "End",
    "end date": "End Date",
    "end of invoice month": "End Of Invoice Month",
    "end of month": "End Of Month",
    "end on": "End On",
    "enquiry": "Enquiry",
    "enter fullscreen": "Enter Fullscreen",
    "entered amount exceeds the outstanding amount": "Entered amount exceeds the outstanding amount",
    "equals": "Equals",
    "error message": "Error Message",
    "error occurred": "Error Occurred",
    "error occurred please try again later": "Error occurred. Please try again later.",
    "errors": "Errors",
    "estimated earnings": "Estimated earnings",
    "exact date": "Exact Date",
    "example": "Example",
    "exclude shipping from sales history": "Exclude shipping from sales history",
    "excluding tax discount": "Excluding Tax Discount",
    "exclusive amount": "Excl. Amount",
    "exclusive base cost": "Exclusive Base Cost",
    "exclusive discount": "Exclusive Discount",
    "exclusive price": "Excl. Price",
    "exclusive selling price": "Excl. Selling Price",
    "exclusive total": "Exclusive Total",
    "exclusive total after discount": "Exclusive Total After Discount",
    "existing": "Existing",
    "exit fullscreen": "Exit Fullscreen",
    "expand sub menus": "Expand Sub Menus",
    "expected close date": "Expected Close Date",
    "expected dispatch date": "Expected Dispatch Date",
    "expenses": "Expenses",
    "expire after days": "Expire after days",
    "expired": "Expired",
    "export": "Export",
    "export HCT data": "Export HCT Data",
    "export all data": "Export All Data",
    "export count": "Export Count",
    "export data": "Export Data",
    "export data and update status": "Export Data & Update Status",
    "export dispatched data": "Export Dispatch Data",
    "export items by payment status": "Export items by payment status",
    "export limited qtys": "Export Limited Qtys",
    "export method": "Export Method",
    "export_all_transporters": "All Shipping Data",
    "export_eInvoices_all": "All E-Invoices",
    "export_eInvoices_cancelled": "Unsuccessful E-Invoices",
    "export_eInvoices_details": "Export E-Invoices & Product Details",
    "export_eInvoices_successful": "Successful E-Invoices",
    "export_orders_details": "Order Product Details",
    "export_orders_info": "Orders",
    "export_orders_per_row": "By Item Rows",
    "export_orders_total": "All Orders",
    "export_paid": "Paid",
    "export_paid-available": "Paid (Available)",
    "export_paid-preOrder": "Paid (Pre-Order)",
    "export_paid-total": "Paid (Available + Pre-Order)",
    "export_paid_export_orders_info": "Paid-Orders",
    "export_paid_export_transporter": "Paid-Shipping data",
    "export_pay_on_delivery": "Pay On Delivery",
    "export_pay_on_delivery_export_orders_info": "Pay On Delivery-Orders",
    "export_pay_on_delivery_export_transporter": "Pay On Delivery-Shipping Data",
    "export_total-available": "Total (Available)",
    "export_total-preOrder": "Total (Pre-Order)",
    "export_total-total": "Total (Available + Pre-Order)",
    "export_transporter": "Shipping Data",
    "export_unpaid-available": "Not Paid (Available)",
    "export_unpaid-preOrder": "Not Paid (Pre-Order)",
    "export_unpaid-total": "Not Paid (Available + Pre-Order)",
    "extensions": "Extensions",
    "external": "External",
    "facebook": "Facebook",
    "facebook chat": "Facebook Chat",
    "facebook login": "Facebook Login",
    "facebook page": "Facebook Page",
    "facebook page inbox": "FACEBOOK PAGE INBOX",
    "facebook page url": "Facebook Page Url",
    "facebook pixel": "Facebook Pixel",
    "facebook plugin": "Facebook Plugin",
    "faqs": "FAQs",
    "faqs for product": "FAQs For Product",
    "faqs for shop": "FAQs For Shop",
    "fax": "Fax",
    "features": "Features",
    "fees": "Fees",
    "female": "Female",
    "fetching data": "Fetching Data",
    "file name": "File Name",
    "files manager": "Files Manager",
    "files_manager_tags": "Tags",
    "filters": "Filters",
    "final": "Final",
    "first name": "First Name",
    "first waybill number": "First Waybill Number",
    "fiscal": "Fiscal",
    "flat fee": "Flat Fee",
    "floor": "Floor",
    "for documents": "For Documents",
    "forgot your password": "Forgot your Password?",
    "fourByThree": "4x3",
    "free": "Free",
    "free shipping condition": "Free Shipping Condition",
    "free shipping for orders greater and equal to": "Free Shipping For Orders Greater And Equal To",
    "frequently asked questions": "Frequently Asked Questions",
    "friday": "Friday",
    "friday opening hours": "Friday Opening Hours",
    "from": "From",
    "from day of order": "From day of Order",
    "from email address": "From Email Address",
    "from email name": "From Email Name",
    "from end of invoice month": "From End Of Invoice Month",
    "from invoice date": "From Invoice Date",
    "from name": "From Name",
    "from person": "From Person",
    "ftp password": "FTP Password",
    "ftp user": "FTP User",
    "full width": "Full width",
    "full width video": "Full width video",
    "g": "g",
    "gender": "Gender",
    "general": "General",
    "generate": "Generate",
    "generate sales invoice data": "Generate Sales Invoice Data",
    "generate settings": "Generate Settings",
    "get last price for all": "Get last price for all",
    "get last price for company": "Get last price for company",
    "get price set in products": "Get price set in products",
    "gjs-Attachment": "Attachment",
    "gjs-Background": "Background",
    "gjs-Background Color": "Background Color",
    "gjs-Blur": "Blur",
    "gjs-Border": "Border",
    "gjs-Border Color": "Border Color",
    "gjs-Border Radius": "Border Radius",
    "gjs-Border Style": "Border Style",
    "gjs-Border Width": "Border Width",
    "gjs-Bottom": "Bottom",
    "gjs-Box Shadow": "Box Shadow",
    "gjs-Center": "Center",
    "gjs-Color": "Color",
    "gjs-Dimension": "Dimension",
    "gjs-Font Family": "Font Family",
    "gjs-Font Size": "Font Size",
    "gjs-Height": "Height",
    "gjs-Image": "Image",
    "gjs-Images": "Images",
    "gjs-Insert your text here": "Insert your text here",
    "gjs-Inside": "Inside",
    "gjs-Justify": "Justify",
    "gjs-Layer 1": "Layer 1",
    "gjs-Left": "Left",
    "gjs-Margin": "Margin",
    "gjs-Max-width": "Max width",
    "gjs-Min-height": "Min height",
    "gjs-Opacity": "Opacity",
    "gjs-Outside": "Outside",
    "gjs-Padding": "Padding",
    "gjs-Position": "Position",
    "gjs-Repeat": "Repeat",
    "gjs-Repeat-x": "Repeat-x",
    "gjs-Repeat-y": "Repeat-y",
    "gjs-Right": "Right",
    "gjs-Shadow type": "Shadow type",
    "gjs-Size": "Size",
    "gjs-Spread": "Spread",
    "gjs-Style": "Style",
    "gjs-Text align": "Text align",
    "gjs-Top": "Top",
    "gjs-Width": "Width",
    "gjs-X position": "X position",
    "gjs-Y position": "Y position",
    "gjs-auto": "auto",
    "gjs-center bottom": "center bottom",
    "gjs-center center": "center center",
    "gjs-center top": "center top",
    "gjs-contain": "contain",
    "gjs-cover": "cover",
    "gjs-dashed": "dashed",
    "gjs-dotted": "dotted",
    "gjs-double": "double",
    "gjs-fixed": "fixed",
    "gjs-left bottom": "left bottom",
    "gjs-left center": "left center",
    "gjs-left top": "left top",
    "gjs-local": "local",
    "gjs-non-Repeat": "non-Repeat",
    "gjs-none": "none",
    "gjs-right bottom": "right bottom",
    "gjs-right center": "right center",
    "gjs-right top": "right top",
    "gjs-scroll": "scroll",
    "gjs-solid": "solid",
    "gjs-title": "title",
    "greater than": "Greater Than",
    "greater than or equal to": "Greater Than Or Equal To",
    "greaterThan": "Greater Than",
    "greaterThanOrEqualTo": "Greater Than Or Equal To",
    "grid": "Grid",
    "group by tags": "Group By Tags",
    "has images": "Has Images?",
    "has limit": "Has Limit",
    "has surcharge on free shipping": "Has Surcharge On Free Shipping",
    "has variants": "Has Variants",
    "hasVariants": "Has Variants",
    "hct contact": "HCT Contact",
    "hct summary": "HCT Waybills",
    "hct summary successfully submitted": "HCT Summary Successfully Submitted",
    "hct waybills": "HCT Waybills",
    "hct_region_error": "查無此區號，請檢查配送地址",
    "help": "Help",
    "hidden": "Hidden",
    "hide all": "Hide All",
    "hide payouts details": "Hide Payouts Details",
    "hold all": "Hold All",
    "home": "Home",
    "home fax": "Home Fax",
    "home page": "home page",
    "home phone": "Home Phone",
    "horizontal": "Horizontal",
    "href": "Href",
    "image": "Image",
    "image link": "Image Link",
    "images": "Images",
    "import": "Import",
    "import data": "Import Data",
    "import dispatched": "Import Dispatched",
    "import limited qtys": "Import Limited Qtys",
    "import paid": "Import Paid",
    "import_transporter_template": "Download Import Transporter Template",
    "inactive": "Inactive",
    "inclTotal": "Inclusive Total",
    "including tax discount": "Including Tax Discount",
    "inclusive amount": "Incl. Amount",
    "inclusive discount": "Inclusive Discount",
    "inclusive discounted price": "Inclusive Discounted Price",
    "inclusive price": "Incl. Price",
    "inclusive selling price": "Incl. Selling Price",
    "inclusive total": "Inclusive Total",
    "inclusive total after discount": "Inclusive Total After Discount",
    "inclusive total after returns": "Inclusive Total After Returns",
    "inclusive_total_short": "Incl. Total",
    "income statement": "Income Statement",
    "incorrect": "incorrect",
    "incorrect array order": "incorrect array order",
    "incorrect array order and/or incorrect number of items": "incorrect array order and/or incorrect number of items",
    "incorrect byte": "incorrect byte",
    "incorrect bytes": "incorrect bytes",
    "incorrect date": "incorrect date",
    "incorrect date format": "incorrect date format",
    "incorrect date range": "incorrect date range",
    "incorrect decimal places": "incorrect decimal places",
    "incorrect length": "Incorrect Length",
    "incorrect number": "incorrect number",
    "incorrect number of items": "incorrect number of items",
    "incorrect uri scheme": "incorrect uri scheme",
    "increase": "Increase",
    "increase/decrease limited qty": "+/- limited qty",
    "increase/decrease preOrder qty": "+/- Pre Order qty",
    "index": "Index",
    "individual": "Individual",
    "insert": "Insert",
    "instagram url": "Instagram Url",
    "invalid": "Invalid",
    "invalid image type": "Invalid Image Type",
    "invalid number of days": "Invalid number of Days",
    "invalid number of months": "Invalid number of Months",
    "invalid password": "密碼錯誤",
    "invalid rate": "Invalid Rate",
    "invalid status": "Invalid Status",
    "invalid username or password": "Invalid Username or Password!",
    "inventory": "Inventory",
    "inventory activity": "Inventory Activity",
    "inventory adjustment": "Inventory Adjustment",
    "inventory available": "Inventory Available",
    "inventory book qty": "Inventory Book Qty",
    "inventory in": "Inventory In",
    "inventory management": "Inventory Management",
    "inventory on hand": "Inventory On Hand",
    "inventory on hand qty": "Inventory On Hand Qty",
    "inventory out": "Inventory Out",
    "inventory qty": "Inventory Qty",
    "inventory report": "Inventory Report",
    "inventory status report": "Inventory Status Report",
    "invoice": "Invoice",
    "invoice date": "Invoice Date",
    "invoice description": "Invoice Description",
    "invoice number": "Invoice Number",
    "invoices": "Invoices",
    "is associates": "Is Associates",
    "is between": "Is Between",
    "is member": "Is Member",
    "is not allowed": "is not allowed",
    "is product set": "Is Product Set",
    "is transporter": "Is Transporter",
    "is unlimited": "Is Unlimited",
    "isBetween": "Is Between",
    "isComplete": "Completed",
    "isIncomplete": "Incomplete",
    "isPinned": "Pinned",
    "isSet": "Product Set",
    "isSetChild": "Product Set Child",
    "isSetWithChoices": "Product Set With Choices",
    "isVariantChild": "Variant Child",
    "issue invoice": "Issue Invoice",
    "issue invoices now": "Issue Invoices Now",
    "item": "Item",
    "item quantity": "Item Quantity",
    "item value": "Item Value",
    "item was not invoiced": "Item was not invoiced",
    "itemQuantity": "Item Quantity",
    "itemValue": "Item Value",
    "items": "Items",
    "items total price is not equal to the set price": "Items' total price is not equal to the set price!",
    "join business": "Join Business",
    "join with": "Join With",
    "kg": "kg",
    "label": "label",
    "label for transporter": "Label For Transporter",
    "language is now set to": "Language is now set to",
    "last 4 digits": "Last 4 Digits",
    "last month": "Last Month",
    "last name": "Last Name",
    "last waybill number": "Last Waybill Number",
    "latest": "Latest",
    "latest customers": "Latest Customers",
    "latest suppliers": "Latest Suppliers",
    "launch": "Launch",
    "lead time": "Lead Time",
    "ledger": "Ledger",
    "ledgers": "Ledgers",
    "left": "Left",
    "less than": "Less Than",
    "less than or equal to": "Less Than Or Equal To",
    "lessThan": "Less Than",
    "lessThanOrEqualTo": "Less Than Or Equal To",
    "limit by": "Limit By",
    "limit preOrder qty": "Limit Pre Order Qty",
    "limited preOrder qty": "Limited Pre Order Qty",
    "limited qty": "Limited Qty",
    "limited qty per person": "Limited Qty Per Person",
    "limited sales qty": "Limited Sales Qty",
    "line chat": "Line Chat",
    "line id": "Line Id",
    "link": "Link",
    "linked to": "Linked To",
    "list all": "List All",
    "list all abandoned": "List All Abandoned",
    "loading": "Loading",
    "locked": "Locked",
    "log into new company?": "Log into [ {company} ]? Will close all tabs",
    "login": "Login",
    "logo": "Logo",
    "logo size": "Logo Size",
    "logout": "Logout",
    "mailing list": "Mailing List",
    "main": "Main",
    "main account number": "Main Account Number",
    "male": "Male",
    "manage duplicates": "Manage Duplicates",
    "mark as completed": "Mark As Complete",
    "mark as paid": "Mark as paid",
    "match_free_shipping_any": "Any Products",
    "match_free_shipping_for": "For",
    "match_free_shipping_only": "Only if cart contains products",
    "max order price": "Maximum Order Price",
    "mayAdd": "Show Additional Items",
    "member": "Member",
    "member email": "Member Email",
    "member level": "Member Level",
    "member level description": "Member Level Description",
    "member level expiry time": "Member Level Expiry Time",
    "member level name": "Member Level Name",
    "member level point required": "Point Required",
    "member level points": "Member Level Points",
    "member level status": "Status",
    "member level will be adjusted to": "Member level will be adjusted to",
    "member levels": "Member Levels",
    "members": "Members",
    "menu": "Menu",
    "menu font size": "menu font size",
    "menu position": "Menu Position",
    "menu style": "Menu Style",
    "merge": "Merge",
    "merge_document": "Merge",
    "merged": "Merged",
    "merged_document": "Merged",
    "message": "Message",
    "message to display when campaign has ended": "Message To Display When Campaign Has Ended",
    "messages": "Messages",
    "method": "Method",
    "min order price": "Minimum Order Price",
    "minimum cart amount": "Minimum Cart Amount",
    "minimum spend requirement": "Minimum Spend Requirement",
    "missing data": "Missing Data",
    "mm": "mm",
    "mm1": "January",
    "mm10": "October",
    "mm11": "November",
    "mm12": "December",
    "mm2": "February",
    "mm3": "March",
    "mm4": "April",
    "mm5": "May",
    "mm6": "June",
    "mm7": "July",
    "mm8": "August",
    "mm9": "September",
    "mobile": "Mobile",
    "monday": "Monday",
    "monday opening hours": "Monday Opening Hours",
    "month": "Month",
    "month end": "Month End",
    "month1": "January",
    "month10": "October",
    "month11": "November",
    "month12": "December",
    "month2": "February",
    "month3": "March",
    "month4": "April",
    "month5": "May",
    "month6": "June",
    "month7": "July",
    "month8": "August",
    "month9": "September",
    "monthly revenue": "Monthly Revenue",
    "months": "Months",
    "more": "more",
    "multiple": "Multiple",
    "multiple delivery dates": "Multiple Delivery dates",
    "multiple discount rate": "Multiple Discount Rate",
    "multiple discounts": "Multiple Discounts",
    "must be a bigger number": "must be a bigger number",
    "must be a boolean": "must be a boolean",
    "must be a buffer or a string": "must be a buffer or a string",
    "must be a credit card": "must be a credit card",
    "must be a float or double": "must be a float or double",
    "must be a negative number": "must be a negative number",
    "must be a number": "must be a number",
    "must be a positive number": "must be a positive number",
    "must be a smaller number": "must be a smaller number",
    "must be a string": "must be a string",
    "must be a valid GUID": "must be a valid GUID",
    "must be a valid ISO 8601 date": "must be a valid ISO 8601 date",
    "must be a valid base64 string": "must be a valid base64 string",
    "must be a valid date": "must be a valid date",
    "must be a valid email": "must be a valid email",
    "must be a valid hostname": "must be a valid hostname",
    "must be a valid ip address": "must be a valid ip address",
    "must be a valid port": "must be a valid port",
    "must be a valid relative uri": "must be a valid relative uri",
    "must be a valid timestamp or number of milliseconds": "must be a valid timestamp or number of milliseconds",
    "must be a valid timestamp or number of second": "must be a valid timestamp or number of second",
    "must be a valid uri": "must be a valid uri",
    "must be an array": "must be an array",
    "must be an integer": "must be an integer",
    "must be unicode normalized": "must be unicode normalized",
    "must exclude value": "must exclude value",
    "must not have leading or trailing whitespace": "must not have leading or trailing whitespace",
    "must only contain alpha-numeric and underscore characters": "must only contain alpha-numeric and underscore characters",
    "must only contain alpha-numeric characters": "must only contain alpha-numeric characters",
    "must only contain hexadecimal characters": "must only contain hexadecimal characters",
    "must only contain uppercase characters": "must only contain uppercase characters",
    "mustAdd": "Automatically Add Items to Cart",
    "mutliple documents found. please capture manually": "Mutliple documents found. please capture manually",
    "my businesses": "My Businesses",
    "my profile": "My Profile",
    "name": "Name",
    "need points to reach level": "Need {points} points to reach",
    "never expire": "Never Expire",
    "new": "New",
    "new account": "New Account",
    "new bulk discount": "New Bulk Discount",
    "new customer": "New Customer",
    "new customer and supplier": "New Customer & Supplier",
    "new link": "New Link",
    "new member level": "new member level",
    "new orders": "New Orders",
    "new page": "New Page",
    "new private": "New Private",
    "new product": "New Product",
    "new products editor": "New Products Editor",
    "new sales invoice": "New Sales Invoice",
    "new sales order": "New Sales Order",
    "new shipping zone": "New Shipping Zone",
    "new supplier": "New Supplier",
    "new total must be equal to the original document total": "New total must be equal to the original document total",
    "new window": "New Window",
    "news": "News",
    "next": "Next",
    "next active": "Next Active",
    "next step": "Next Step",
    "next step conversion rate": "Next Step Conversion Rate",
    "next workflow": "Next Workflow",
    "night": "Night",
    "no": "No",
    "no active schedule": "No active schedule",
    "no comments": "No Comments",
    "no commission this month": "No Commission This Month!",
    "no data": "no data",
    "no data to export": "No Data To Export",
    "no details": "No Details",
    "no dispatched data": "no dispatched data",
    "no emails to send": "No emails to send",
    "no products added yet": "No Products Added Yet",
    "no products found, double click to refresh or add new products": "no products found, double click to refresh or add new products",
    "no reward expiry set": "No reward expiry set",
    "no reward points set": "No reward points set",
    "no tax allowed": "No Tax Allowed",
    "no_of_documents_unit": "documents",
    "no_of_times": "times",
    "none": "None",
    "noon": "Noon",
    "normal percentage": "Normal Percentage",
    "not a day of the month (1-31)": "Not a day of the month (1-31)",
    "not a url": "Not a Url",
    "not an email": "Not an Email",
    "not date": "Date Required",
    "not dispatched": "Not Dispatched",
    "not email": "Not Email",
    "not equals": "Not Equals",
    "not float": "Not a number",
    "not found": "Not Found",
    "not matching any of the allowed alternatives": "not matching any of the allowed alternatives",
    "not member": "Not Member",
    "not unique": "Not Unique",
    "not yet saved": "Not yet saved",
    "not yet set": "Not yet set",
    "notEquals": "Not Equals",
    "note": "Note",
    "note is required on checkout": "Note is Required on Checkout",
    "notes": "Notes",
    "notifications": "Notifications",
    "number": "Number",
    "number of days": "Number Of Days",
    "number of months": "Number Of Months",
    "number of orders": "Number of Orders",
    "number of results": "Number of Results",
    "number of selections": "Number of Selections",
    "number of transactions": "number of transactions",
    "off": "Off",
    "office fax": "Office Fax",
    "office phone": "Office Phone",
    "offline": "Offline",
    "old exclusive price": "Old Exclusive Price",
    "old inclusive price": "Old Inclusive Price",
    "on": "On",
    "on hand qty": "On Hand Qty",
    "on hold": "On Hold",
    "on sale": "On Sale",
    "on sale schedules": "On Sale Schedules",
    "on set date": "On set Date",
    "on total": "On Total",
    "onHold": "On Hold",
    "once off": "Once Off",
    "oneByOne": "1x1 (square)",
    "online": "Online",
    "only include items with sales activity": "Only include items with sales activity",
    "only show shipping description, rate is quoted after order": "Only show shipping description, rate is quoted after order",
    "open hours": "Open Hours",
    "open_tab": "Open Tab",
    "opening balance": "Opening Balance",
    "opening inventory book qty": "Opening Inventory Book Qty",
    "opening inventory book value": "Opening Inventory Book Value",
    "opening inventory on hand qty": "Opening Inventory On Hand Qty",
    "opening inventory on hand value": "Opening Inventory On Hand Value",
    "optional": "Optional",
    "options": "Options",
    "order": "Order",
    "order #": "Order #",
    "order canceled email": "Order Canceled Email",
    "order date": "Order Date",
    "order dispatched email": "Order Dispatched Email",
    "order number": "Order Number",
    "order success email": "Order Success Email",
    "order summary": "Order Summary",
    "order value": "Order Value",
    "orderValue": "Order Value",
    "ordered": "Ordered",
    "ordered date": "Ordered Date",
    "ordered qty": "Ordered Qty",
    "ordered_exclusive_total": "Ordered excl. total",
    "ordered_inclusive_total": "Ordered incl. total",
    "orders": "Orders",
    "orders by date": "Orders By Date",
    "original commission base cost": "Original Commission Base Cost",
    "original commission total amount": "Original Commission Total Amount",
    "original document total": "Original document total",
    "original inclusive price": "Original Inclusive Price",
    "original total commission": "Original Total Commission",
    "other": "Other",
    "other income": "Other Income",
    "out of stock": "Out Of Stock",
    "outOfStock": "Out Of Stock",
    "outstanding": "Outstanding",
    "outstanding qty": "Outstanding Qty",
    "outstanding total": "Outstanding Total",
    "outstanding_exclusive_total": "Outstanding excl. total",
    "outstanding_inclusive_total": "Outstanding incl. total",
    "overdue commission": "Overdue Commission",
    "overview": "Overview",
    "packages": "Packages",
    "packages_short": "Pkg",
    "packaging_dimensions": "Packaging Dimensions",
    "packaging_dimensions_unit_default": "Default Packaging Dimensions Unit",
    "page": "Page",
    "page is hidden on menu": "Page is Hidden On Menu",
    "page url": "Page Url",
    "page views": "page views",
    "paid": "Paid",
    "passed allowed refund period": "Passed Allowed Refund Period",
    "password": "Password",
    "password (at least 8 characters)": "Password (At Least 8 Characters)",
    "payment": "Payment",
    "payment amount": "Payment Amount",
    "payment date": "Payment Date",
    "payment due": "Payment Due",
    "payment due date": "Payment Due Date",
    "payment last 5 digits": "Acc Last 5 Digits",
    "payment link": "Payment Link",
    "payment method": "Payment Method",
    "payment methods": "Payment Methods",
    "payment reminder email": "Payment Reminder Email",
    "payment reminder email message": "Payment Reminder Email Message",
    "payment reminder email subject": "Payment Reminder Email Subject",
    "payment reminders": "Payment Reminders",
    "payment status": "Payment Status",
    "payment terms": "Payment Terms",
    "payments": "Payments",
    "payout": "Payout",
    "payouts this month": "Payouts This Month",
    "pending": "Pending",
    "pending points": "Pending Points",
    "pending qty": "Pending Qty",
    "pendingCancellation": "Pending Cancellation",
    "per line": "Per Line",
    "percentage of price": "Percentage Of Price",
    "person": "Person",
    "person + contact": "Person + Contact",
    "person name": "Person Name",
    "persons": "Persons",
    "persons_count": "No. of People",
    "phone": "Phone",
    "pick a color theme": "Pick a color theme",
    "pick up": "Pick Up",
    "pick up locations": "Pick Up Locations",
    "picking list": "Picking List",
    "pin": "Pin",
    "pinned": "Pinned",
    "pixel id": "Pixel ID",
    "plan": "Plan",
    "please select": "Please Select",
    "point": "point",
    "points": "points",
    "points to be earned": "Points To Be Earned",
    "position": "Position",
    "post office": "Post Office",
    "postal codes": "Postal Codes",
    "power": "Power",
    "pre order": "Pre Order",
    "preOrder": "Pre Order",
    "preferred delivery time": "Preferred Delivery Time",
    "prepare for dispatch": "Prepare For Dispatch",
    "preparing": "Preparing",
    "preset commissions": "Preset Commissions",
    "preview": "Preview",
    "preview merge": "Preview Merge",
    "previous": "Previous",
    "price": "Price",
    "price (for accounting purpose)": "Price (for Accounting Purpose)",
    "price range": "Price Range",
    "pricing": "Pricing",
    "print": "Print",
    "print all": "Print All",
    "print all delivery notes": "Print All Delivery Notes",
    "print all eInvoices": "Issue All E-Invoices",
    "print all invoices": "Print All Invoices",
    "print all waybills": "Print All Waybills",
    "print delivery note": "Print Delivery Note",
    "print hct summary": "Print HCT Summary",
    "print invoice": "Print Invoice",
    "print invoices": "Print Invoices",
    "print picking list": "Print Picking List",
    "print waybill": "Print Waybill",
    "print waybills starting position": "Print Waybills Starting Position",
    "print_delivery_note_prices": "Print Delivery Note (with prices)",
    "printer error, please check printer connection": "Printer Error, Please check printer connection.",
    "private": "Private",
    "private templates": "My Templates",
    "process_order": "Order",
    "processing": "Processing",
    "product": "Product",
    "product code": "Product Code",
    "product decimal places": "Product Decimal Places",
    "product grouping": "Product Grouping",
    "product images": "Product Images",
    "product info": "Product Info",
    "product information": "Product information",
    "product limits": "Product Limits",
    "product price rounding": "Product Price Rounding",
    "product schedules": "Product Schedules",
    "product set": "Product Set",
    "product set with choices": "Product Set With Choices",
    "product sets": "Product Sets",
    "product status": "Product Status",
    "product tags": "Product Tags",
    "product type": "Product Type",
    "product_description": "Product Description",
    "product_general": "general",
    "product_set": "Product Set",
    "product_set_price": "Set Price",
    "product_sets": "Sets",
    "product_sets_contents": "Product Set Contents",
    "product_sets_with_choices": "Sets With Choices",
    "product_variants_contents": "Product Variants Contents",
    "products": "Products",
    "products images ratio": "Products Images Ratio",
    "products overview name": "Shop overview name",
    "products overview style": "Shop overview style",
    "profile": "Profile",
    "promo code": "Promo Code",
    "promo code (at least 6 characters)": "Promo Code (at least 6 characters)",
    "promo codes": "Promo Codes",
    "promotion": "Promotion",
    "promotion email": "Promotion Email",
    "provider": "Provider",
    "public status": "Public Status (Visible to the public)",
    "public templates": "Default Templates",
    "purchase price setting": "Purchase Price Setting",
    "purchases": "Purchases",
    "purchases consignment": "Purchases Consignment",
    "purchases consignment overview": "Purchases Consignment Overview",
    "purchases consignment receipt": "Purchases Consignment Receipt",
    "purchases consignment return": "Purchases Consignment Return",
    "purchases enquiry": "Purchases Enquiry",
    "purchases invoice": "Purchases Invoice",
    "purchases order": "Purchases Order",
    "purchases price history": "Purchases Price History",
    "purchases return": "Purchases Return",
    "purchases terms": "Purchases Terms",
    "purchases-consignments": "Consignment Receipt",
    "purchases-consignments-returns": "Consignment Return",
    "purchases-invoices": "Purchases Invoice",
    "purchases-orders": "Purchases Order",
    "purchases-pending": "Purchases: Pending",
    "purchases-pending: display tax method": "Purchases Pending Report: Display tax method",
    "purchases-purchases-consignments": "Consignment Receipt",
    "purchases-purchases-consignments-returns": "Consignment Return",
    "purchases-purchases-invoices": "Purchases Invoice",
    "purchases-purchases-orders": "Purchases Order",
    "purchases-purchases-quotes": "Purchases Enquiry",
    "purchases-purchases-returns": "Debit Note",
    "purchases-quotes": "Purchases Enquiry",
    "purchases-returns": "Debit Note",
    "purchases: on consignment": "Purchases: On Consignment",
    "purchases: on consignment qty": "Purchases: On Consignment Qty",
    "purchases_delivered_exclusive_total": "Received excl. total",
    "purchases_delivered_inclusive_total": "Received incl. total",
    "purchasing exclusive price": "Purchasing Exclusive Price",
    "purchasing inclusive price": "Purchasing inclusive Price",
    "qty": "Qty",
    "qty sold": "Qty Sold",
    "quantity": "Quantity",
    "query": "Query",
    "question": "Question",
    "questions": "Questions",
    "queued": "Queued",
    "quick add": "Quick Add",
    "quickTip": "QuickTip",
    "quote": "Quote",
    "rate": "Rate",
    "ratio_original": "Original",
    "re-index all products": "Re-Index All Products",
    "re-login": "re-login",
    "re-order qty": "Re-Order Qty",
    "reason": "Reason",
    "receipt": "Receipt",
    "receipts": "Receipts",
    "record commissions": "Record Commissions",
    "ref": "Ref",
    "reference": "Reference",
    "referenced field is not a date": "referenced field is not a date",
    "referenced field is not a number": "referenced field is not a number",
    "refresh": "Refresh",
    "refund": "Refund",
    "refund amount": "Refund Amount",
    "refund credit card": "Refund Credit Card",
    "refund credit card total": "Refund Credit Card Total",
    "refunds": "Refunds",
    "regenerate document number": "regenerate document number",
    "region": "Region",
    "region code": "Region Code",
    "regions": "Regions",
    "related": "Related",
    "relationship": "Relationship",
    "relationships": "Relationships",
    "remove": "Remove",
    "remove all row": "Remove All Row",
    "remove cell": "Remove Cell",
    "replace": "Replace",
    "report": "Report",
    "report password": "Report Password",
    "reports": "Reports",
    "required": "Required",
    "resend": "Resend",
    "reset commission base costs value": "Reset commission base costs value",
    "resource": "Resource",
    "results": "Results",
    "return": "Return",
    "return qty": "Return Qty",
    "returns": "Returns",
    "returns application email": "Returns Application Email",
    "returns inclusive total": "Returns Inclusive Total",
    "revenue": "Revenue",
    "revenue by campaign": "Revenue By Campaign",
    "revenue by channel": "Revenue By Channel",
    "revenue by contact": "Revenue By Contact",
    "revenue by day": "Revenue By Day",
    "revenue by month": "Revenue By Month",
    "revenue by payment method": "Revenue By Payment Method",
    "revenue by product": "Revenue By Product",
    "revenue by promo code": "Revenue By Promo Code",
    "revenue for campaign": "Revenue For Campaign",
    "revenue for month": "Revenue For Month",
    "revenue for year": "Revenue For Year",
    "revert to draft": "Revert to Draft",
    "reward": "Reward",
    "reward earning rate from total order": "{rate}% from total price of order",
    "reward event": "Reward Event",
    "reward event allow on sale": "Allow on Sale",
    "reward event allow with promo code": "Allow with Promo Code",
    "reward event earning": "Reward Earning",
    "reward event expiry": "Expiry Time",
    "reward event max deduction": "Maximum Discount",
    "reward event rate per Point": "Exchange Rate Per Point",
    "reward event requirement": "Reward Requirement",
    "reward event start": "Start Time",
    "reward event status": "Status",
    "reward event type": "Reward Type",
    "reward history": "Reward History",
    "reward name": "Reward Name",
    "reward notification email": "Reward Notification Email",
    "reward points": "Reward Points",
    "rewardInclTotal": "Rewards Inclusive Total",
    "reward_adjustment": "Adjustment",
    "reward_birthday": "Birthday",
    "reward_opening": "Opening Balance",
    "reward_order": "Order",
    "reward_promotion": "Promotion",
    "reward_signup": "Sign up",
    "rewards": "Rewards",
    "rewards inclusive total": "Rewards Inclusive Total",
    "rewards used": "Rewards Used",
    "right": "Right",
    "role": "Role",
    "row": "Row",
    "sales": "Sales",
    "sales consignment": "Sales Consignment",
    "sales consignment delivery": "Sales Consignment Delivery",
    "sales consignment overview": "Sales Consignment Overview",
    "sales consignment return": "Sales Consignment Return",
    "sales invoice": "Sales Invoice",
    "sales order": "Sales Order",
    "sales person": "Sales Person",
    "sales persons": "Sales Persons",
    "sales price history": "Sales Price History",
    "sales quote": "Sales Quote",
    "sales report": "Sales Report",
    "sales return": "Sales Return",
    "sales terms": "Sales Terms",
    "sales-consignments": "Consignment Delivery",
    "sales-consignments-returns": "Consignment Return",
    "sales-invoices": "Sales Invoice",
    "sales-orders": "Sales Order",
    "sales-pending": "Sales: Pending",
    "sales-pending: display tax method": "Sales Pending Report: Display tax method",
    "sales-quotes": "Sales Quote",
    "sales-returns": "Credit Note",
    "sales-sales-consignments": "Consignment Delivery",
    "sales-sales-consignments-returns": "Consignment Return",
    "sales-sales-invoices": "Sales Invoice",
    "sales-sales-orders": "Sales Order",
    "sales-sales-quotes": "Sales Quote",
    "sales-sales-returns": "Credit Note",
    "sales: on consignment": "Sales: On Consignment",
    "sales: on consignment qty": "Sales: On Consignment Qty",
    "sales_delivered_exclusive_total": "Delivered excl. total",
    "sales_delivered_inclusive_total": "Delivered incl. total",
    "same as date end": "Same as date end",
    "saturday": "Saturday",
    "saturday opening hours": "Saturday Opening Hours",
    "save": "Save",
    "save and update next invoice number": "Save and Update Next Invoice Number",
    "save as color template": "Save As Color Template",
    "save as copy": "Save as Copy",
    "save as draft": "Save as Draft",
    "save as template": "Save as Template",
    "save as void": "Save as Void",
    "saved": "Saved",
    "schedule": "Schedule",
    "schedule status": "Schedule Status",
    "scheduled": "Scheduled",
    "scheduled_tasks": "Scheduled Tasks",
    "scheduled_time": "Scheduled Time",
    "schedules": "Schedules",
    "search": "Search",
    "search filters": "Search Filters",
    "search images": "Search Images",
    "search products": "Search Products",
    "search reference": "Search Reference",
    "search reward name": "Search Reward Name",
    "search shipping zones": "Search Shipping Zones",
    "select": "Select",
    "select all": "Select All",
    "select all main island": "Select All Main Island",
    "select all outlying": "Select All Outlying",
    "select company": "Select Company",
    "select dispatch date": "Select Dispatch Date",
    "select images": "Select Images",
    "select reward program first": "Select reward program first",
    "selected items": "Selected Items",
    "selected orders": "Selected Orders",
    "selling exclusive price": "Selling Exclusive Price",
    "selling inclusive price": "Selling Inclusive Price",
    "selling price setting": "Selling Price Setting",
    "send": "Send",
    "send a copy of order email to address": "Send a copy of order email to address",
    "send all dispatched emails": "Send All Dispatched Emails",
    "send all emails": "Send All Emails",
    "send birthday reward": "Send Birthday Reward",
    "send birthday rewards manually": "Send Birthday Rewards Manually",
    "send dispatched email": "Send Dispatched Email",
    "send email": "Send Email",
    "send email message": "Send Email Message",
    "send payment reminder when overdue by": "Send Payment Reminder When Overdue By",
    "send reward": "Send Reward",
    "send reward manually": "Send Reward Manually",
    "send void email": "Send Void Email",
    "service": "Service",
    "set as bundle": "Set as Bundle",
    "set as default": "Set as Default",
    "set as default business": "Set as Default Business",
    "set as default for": "Set as Default For",
    "set commission base cost": "Set commission base cost",
    "set commission total directly": "Set commission total directly",
    "set default": "Set Default",
    "set period": "Set Period",
    "set prices later": "Set prices later",
    "set prices now": "Set prices now",
    "set shipping zones": "Set Shipping Zones",
    "setTypes": "Set Types",
    "settings": "Settings",
    "shipping": "Shipping",
    "shipping default payment method": "Shipping Default Payment Method",
    "shipping fee": "Shipping Fee",
    "shipping fee type": "Shipping Fee Type",
    "shipping payment method": "Shipping Payment Method",
    "shipping rate": "Shipping Rate",
    "shipping type": "Shipping Type",
    "shipping zone": "Shipping Zone",
    "shipping zone details": "Shipping Zone Details",
    "shipping zones": "Shipping Zones",
    "shipping_multiple": "Additional Shipping Fee",
    "shipping_single": "Shipping",
    "shop": "Shop",
    "shop settings": "Shop Settings",
    "shop url": "Shop Url",
    "shop url (at least 6 characters)": "Shop Url (At Least 6 Characters)",
    "shop url prefix": "Shop Url Prefix",
    "shop_products_overview_style_condensed": "Condensed",
    "shop_products_overview_style_full": "Full",
    "short description": "Short Description",
    "show all": "Show All",
    "show all colors": "Show All Colors",
    "show available quantity": "Show Available Quantity",
    "show bot": "Show Bot",
    "show countdown timer": "Show Countdown Timer",
    "show discounted price vs original price": "Show Discounted Price vs. Original Price",
    "show label \"more\" on product": "show label \"more\" on product",
    "show label more": "show label more",
    "show less": "Show Less",
    "show limited qty": "Show Limited Qty",
    "show logo on menu": "Show Logo On Menu",
    "show more": "Show More",
    "show number of lines": "Show Number Of Lines",
    "show original price": "Show Original Price",
    "show page on menu": "Show Page On Menu",
    "show payouts details": "Show Payouts Details",
    "show prices": "Show Prices",
    "show product description": "Show Product Description",
    "show product info": "Show Product Info",
    "show returns": "Show Returns",
    "show sales quantity": "Show Sales Quantity",
    "show shipping options on dispatching": "Show Shipping Options On Dispatching",
    "show shipping waybills on dispatching": "Show Shipping Waybills On Dispatching",
    "show tags on filter": "Show tags on filter",
    "show tags on page": "Show tags on page",
    "show website name on menu": "Show Website Name On Menu",
    "sign up": "Sign Up",
    "signature": "Signature",
    "signup": "Sign up",
    "signup reward email": "Signup Reward Email",
    "simple": "Simple",
    "single delivery date": "Single Delivery date",
    "single discount": "Single Discount",
    "single discount rate": "Single Discount Rate",
    "slider": "Slider",
    "social links": "Social Links",
    "sold out": "Sold Out",
    "sold qty": "Sold Qty",
    "sold qty (to invoice)": "Sold Qty (to invoice)",
    "soldOut": "Sold Out",
    "some": "Some",
    "sort by": "Sort By",
    "sort customer order using shop product index": "Sort Customer Order Using Shop Product Index",
    "sort direction": "Sort Direction",
    "spend reward points": "Spend Reward Points",
    "split": "Split",
    "split qty": "Split Qty",
    "split_document": "split",
    "split_shipping": "Split",
    "stage": "Stage",
    "stages": "Stages",
    "start": "Start",
    "start date": "Start Date",
    "start from": "Start From",
    "startup": "Startup",
    "statement": "Statement",
    "stats for campaign": "Stats For Campaign",
    "stats for month": "Stats For Month",
    "stats for year": "Stats For Year",
    "status": "Status",
    "status for when over sales limited qty": "Status For When Over Sales Limited Qty",
    "step": "step",
    "sticky top": "Sticky top",
    "store location": "Store Location",
    "store locations": "Store Locations",
    "string": {
      "max": "max length {limit}"
    },
    "sub account number": "Sub Account Number",
    "sub page": "Sub Page",
    "subject": "Subject",
    "submit hct summary": "Submit HCT Summary",
    "subtotal": "Subtotal",
    "successful": "Successful",
    "sunday": "Sunday",
    "sunday opening hours": "Sunday Opening Hours",
    "super": "Super",
    "supplier": "Supplier",
    "supplier account": "Supplier Account",
    "supplier activity": "Supplier Activity",
    "supplier payment method": "Supplier Payment Method",
    "supplier payment terms": "Supplier Payment Terms",
    "supplier reconciliations": "Supplier Reconciliations",
    "surcharge": "Surcharge",
    "surcharge type": "Surcharge Type",
    "surcharge_floor": "Floor",
    "suspended": "Suspended",
    "systemlead": "Systemlead",
    "systemlead_companyId": "Company Id",
    "systemlead_remind": "Remind when E-Invoices are low",
    "systemlead_remindCount": "Remind when E-Invoices Count is Below Number",
    "systemlead_salt": "Salt",
    "systemlead_showZeroValueItems": "Show Zero Value Items",
    "tab name": "Tab Name",
    "tabs": "Tabs",
    "tag": "Tag",
    "tags": "Tags",
    "taiwan percentage": "Taiwan Percentage",
    "tapPay": "Tap Pay",
    "tapPay_3ds": "3DS Verification",
    "tapPay_merchant_id": "Merchant Id",
    "tapPay_partner_key": "Partner Key",
    "target": "Target",
    "task": "Task",
    "tax": "Tax",
    "tax %": "Tax %",
    "tax after discount": "Tax After Discount",
    "tax allowed": "Tax Allowed",
    "tax exclusive": "Tax Exclusive",
    "tax inclusive": "Tax Inclusive",
    "tax method": "Tax Method",
    "tax number": "Tax Number",
    "tax on purchases": "Tax On Purchases",
    "tax on sales": "Tax On Sales",
    "tax rate": "Tax Rate",
    "tax_auto": "Auto",
    "tax_exclusive": "Tax Exclusive",
    "tax_inclusive": "Tax Inclusive",
    "tax_none": "None",
    "taxes": "Taxes",
    "tel": "Tel",
    "telephone": "Telephone",
    "template": "Template",
    "terms": "Terms",
    "text": "Text",
    "themeGeneralLetterSpacing": "General Letter Spacing",
    "themeHeaderH1LetterSpacing": "H1 Letter Spacing",
    "themeHeaderH2LetterSpacing": "H2 Letter Spacing",
    "themeHeaderH3LetterSpacing": "H3 Letter Spacing",
    "themeHeaderH4LetterSpacing": "H4 Letter Spacing",
    "themeHeaderH5LetterSpacing": "H5 Letter Spacing",
    "themeHeaderH6LetterSpacing": "H6 Letter Spacing",
    "themeNavTreeItemMarginX": "Menu Item Margin (left/right)",
    "themeNavTreeItemPaddingX": "Menu Item Padding (left/right)",
    "themes": "Themes",
    "this may already have been dispatched on a previous day- are you sure you want to change its dispatch status": "This may already have been dispatched on a previous day. Are you sure you want to change its dispatch status?",
    "this month": "This Month",
    "this product set is outdated": "This product set is outdated",
    "this product set is outdated, click here to change it to the new type (set item prices individually)": "This product set is outdated. Click here to change it to the new type (set item prices individually)",
    "this window": "This Window",
    "threeByOne": "3x1",
    "threw an error when running default method": "threw an error when running default method",
    "thursday": "Thursday",
    "thursday opening hours": "Thursday Opening Hours",
    "time": "time",
    "title": "Title",
    "to": "To",
    "to issue e-invoices": "To Issue E-Invoices",
    "to print": "To Print",
    "to send": "To Send",
    "to send birthday": "To Send Birthday Reward",
    "to send promotion": "To Send Promotion Reward",
    "today": "Today",
    "toggles_no": "No",
    "toggles_yes": "Yes",
    "too long": "Too Long",
    "total": "Total",
    "total active contacts": "Total Active Contacts",
    "total adjustment": "Total Adjustment",
    "total amount paid": "Total Amount Paid",
    "total commission": "Total commission",
    "total commission base cost": "Total Commission Base Cost",
    "total commissions due": "Total Commissions Due",
    "total commissions due returned": "Total Commissions Due Returned",
    "total commissions due this month": "Total Commission Due This Month",
    "total commissions generated": "Total Commissions Generated",
    "total commissions generated returned": "Total Commissions Generated Returned",
    "total commissions generated this month": "Total Commissions Generated This Month",
    "total credit card amount": "Total Credit Card Amount",
    "total due after adjustment": "Total Due After Adjustment",
    "total due after payouts": "Total Due After Payouts",
    "total inclusive discounted price": "Total Inclusive Discounted Price",
    "total inclusive price": "Total Incl. Price",
    "total limited qty": "Total Limited Qty",
    "total orders": "Total Orders",
    "total returns": "Total Returns",
    "total revenue": "Total Revenue",
    "total sales": "Total Sales",
    "total_invoices_amount": "Total Invoices Amount",
    "total_invoices_cancelled": "Total Invoices Cancelled",
    "total_invoices_pending": "Total Invoices Pending",
    "total_invoices_pendingCancellation": "Total Invoices Pending Cancellation",
    "total_invoices_successful": "Total Invoices Successful",
    "total_rounding_difference_explanation": "Difference in total is due to rounding.",
    "track inventory": "Track Inventory",
    "tracking": "Tracking",
    "tracking code": "Tracking Code",
    "tracking codes": "Tracking Codes",
    "transport": "Transport",
    "transporter": "Transporter",
    "transporter account": "Transporter Account",
    "transporter accounts": "Transporter Accounts",
    "transporter notes": "Transporter Notes",
    "transporter_account_short": "Transporter Acc",
    "tuesday": "Tuesday",
    "tuesday opening hours": "Tuesday Opening Hours",
    "twEInvoicePrint": "E-Invoice Allow Print",
    "tw_auto_print_invoices": "Print E-Invoices Default",
    "tw_eInvoice_allow_print": "Allow Print",
    "tw_eInvoice_carrierType_app": "Mobile",
    "tw_eInvoice_carrierType_b2b": "B2B",
    "tw_eInvoice_carrierType_person": "Citizen Cert",
    "tw_eInvoice_carrier_id": "Carrier Id",
    "tw_eInvoice_carrier_type": "Carrier Type (Optional)",
    "tw_eInvoice_carrier_type_optional": "Carrier Type",
    "tw_eInvoice_company_name": "Company Name",
    "tw_eInvoice_print_custom_block": "Print Custom Block",
    "tw_eInvoice_print_custom_block_placeholder": "You are allow the following characters amount per line\\\\n 34 English Character per row\\\\n 17 Chinese Character per row\\\\n NOTE: If you mix both character sets on the same line we cannot guarantee a correct printing layout.\\\\n\n",
    "tw_eInvoice_print_reload": "Reload E-Invoice Printers",
    "tw_eInvoice_provider_bigOrdr": "BigOrdr",
    "tw_eInvoice_provider_systemlead": "Systemlead",
    "tw_eInvoice_reprint": "Reprint Invoice",
    "tw_eInvoices_allow_numbers": "Allowed E-Invoice Track Numbers",
    "tw_eInvoices_attention_required": "Attention Required",
    "tw_eInvoices_blankUploaded_successful": "Uploaded",
    "tw_eInvoices_blankUploaded_unsuccessful": "Not Yet Uploaded",
    "tw_eInvoices_book": "Book",
    "tw_eInvoices_captured": "Captured",
    "tw_eInvoices_captured_invoices": "Captured Invoices",
    "tw_eInvoices_captured_returns": "Captured Returns",
    "tw_eInvoices_count": "Count",
    "tw_eInvoices_custom_description": "Description",
    "tw_eInvoices_first_number": "First Number",
    "tw_eInvoices_issue_results": "E-Invoice Issue Results",
    "tw_eInvoices_last_number": "Last Number",
    "tw_eInvoices_no_track_numbers": "No E-Invoice Track Numbers",
    "tw_eInvoices_notify_email_address": "E-Invoice Notify Email Address",
    "tw_eInvoices_numbers": "E-Invoice Numbers",
    "tw_eInvoices_numbers_available_count": "Available E-Invoices",
    "tw_eInvoices_numbers_blank_end": "Blank Numbers End",
    "tw_eInvoices_numbers_blank_numbers": "Blank Numbers",
    "tw_eInvoices_numbers_blank_remaining_unused": "Unused E-Invoice Numbers",
    "tw_eInvoices_numbers_blank_start": "Blank Numbers Start",
    "tw_eInvoices_numbers_blank_upload_all_remaining_unused": "Include All Unused E-Invoice Numbers",
    "tw_eInvoices_numbers_books_end": "Books End",
    "tw_eInvoices_numbers_books_range": "Books Range",
    "tw_eInvoices_numbers_books_start": "Books Start",
    "tw_eInvoices_numbers_current_books_range": "Current Numbers Range",
    "tw_eInvoices_numbers_end": "End",
    "tw_eInvoices_numbers_enter_blanks": "Enter Blank Numbers",
    "tw_eInvoices_numbers_last": "Last",
    "tw_eInvoices_numbers_next": "Next",
    "tw_eInvoices_numbers_no_available_count": "No Available E-Invoices",
    "tw_eInvoices_numbers_remaining": "Remaining Numbers",
    "tw_eInvoices_numbers_start": "Start",
    "tw_eInvoices_numbers_upload_blanks": "Upload Blank Numbers",
    "tw_eInvoices_numbers_upload_blanks_not_uploaded": "Not Yet Uploaded",
    "tw_eInvoices_prefix": "Prefix",
    "tw_eInvoices_print_aesKey": "E-Invoice AESKey",
    "tw_eInvoices_print_allow_print": "E-Invoice Allow Print",
    "tw_eInvoices_print_allow_shop": "E-Invoice Allow Print on Shop",
    "tw_eInvoices_scheduled": "e-invoice scheduled for auto creating tonight: 9:00pm",
    "tw_eInvoices_uncaptured_total": "Uncaptured Inclusive Total",
    "tw_eInvoicing": "E-Invoices",
    "tw_eInvoives_include_until_current_date": "Include All E-Invoices Until",
    "tw_eInvoives_last_number": "Last Number",
    "tw_eInvoives_no_allocated": "No Allocated E-Invoice Numbers",
    "tw_invoicing": "Invoicing",
    "tw_issue_print_eInvoice": "Issue and Print E-Invoice",
    "tw_print_eInvoices": "Print E-Invoices",
    "tw_print_eInvoices_now": "Issue E-Invoices Now",
    "tw_print_invoices": "Print Invoices",
    "twoByOne": "2x1",
    "txt verification": "TXT Verification",
    "type": "Type",
    "type delete to delete": "Type DELETE to delete",
    "unable to add new busines- please try again later-": "Unable to add new busines- Please try again later-",
    "unable to add new option- please try again later-": "Unable to add new option- Please try again later-",
    "unable to create profile- please try again later-": "Unable to create profile- Please try again later-",
    "unable to get businesses- please try again later-": "Unable to get businesses- Please try again later-",
    "unable to update profile- please try again later-": "Unable to update profile- Please try again later-",
    "unable to verify account- please try again later-": "Unable to verify account- Please try again later-",
    "unallocated": "Unallocated",
    "unassigned": "Unassigned",
    "undispatched": "Not Yet Dispatched",
    "undispatched or due": "Not Yet Dispatched / Due",
    "undo": "Undo",
    "undo cancel invoice": "Undo Cancel Invoice",
    "undo void": "Undo Void",
    "unhold all": "Unhold All",
    "unit": "Unit",
    "unlink": "Unlink",
    "unprepared": "Unprepared",
    "unprinted": "Unprinted",
    "unsuccessful": "Unsuccessful",
    "update failed the data is out of date as it may have been changed by someone else please try again": "Update failed- The data is out of date as it may have been changed by someone else- Please try again-",
    "update required, please reload": "Update required. Please reload",
    "update status": "Update Status",
    "updated": "Updated",
    "updated date": "Updated Date",
    "upload files": "Upload Files",
    "upload image": "Upload Image",
    "upload images": "Upload Images",
    "upload images to campaigns": "Upload Images to Campaigns",
    "upload images to products": "Upload Images to Products",
    "upload logo": "Upload Logo",
    "upload status": "Upload Status",
    "uploading please wait": "uploading please wait...",
    "url": "Url",
    "usage limit": "Usage Limit",
    "use average price for each product": "Use average price for each product",
    "use campaign period": "Use Campaign Period",
    "use custom date": "Use Custom Date",
    "use default birthday message in rewards": "Use default birthday message in rewards",
    "use default birthday message in settings": "Use default birthday message in settings",
    "use default description": "Use Default Description",
    "use default message in settings": "Use default message in settings",
    "use default promotion message in rewards": "Use default promotion message in rewards",
    "use default promotion message in settings": "Use default promotion message in settings",
    "use default width": "Use default width",
    "use defaults from settings": "Use defaults from settings",
    "use exclusive selling price to calculate commission": "Use exclusive selling price to calculate commission",
    "use last price for all": "Use last price for all",
    "use last price for company": "Use last price for company",
    "use normal percentage": "Use normal percentage",
    "use preset commissions to fill commission value": "Use preset commissions to fill commission value",
    "use price set in products": "Use price set in products",
    "use product set children": "Use Product Set children",
    "use taiwan percentage": "Use taiwan percentage",
    "user": "User",
    "user colors": "User Colors",
    "user didnt complete the transaction": "user didnt complete the transaction",
    "username": "Username",
    "username or email": "Username or Email",
    "users": "Users",
    "valid": "Valid",
    "valid points": "Valid Points",
    "validate domain": "Validate Domain",
    "validity date": "Validity Date",
    "value": "Value",
    "value is required": "Value is Required",
    "values are": "Values are",
    "variant": "Variant",
    "variant child": "Variant Child",
    "variant code": "Variant Code",
    "variant options": "Variant Options",
    "variant type": "Variant Type",
    "variants": "Variants",
    "vat": "Vat",
    "vertical": "Vertical",
    "video": "Video",
    "view": "View",
    "view available placeholders": "View available placeholders",
    "virtual account": "Virtual Account",
    "virtual account bank code": "Virtual Account Bank Code",
    "virtual account bank name": "Virtual Account Bank Name",
    "virtual account expiry date": "Virtual Account Expiry Date",
    "virtual account number": "Virtual Account Number",
    "virtual_account_from_account": "From account",
    "virtual_account_provider_eSun": "E. Sun 玉山銀行虛擬帳號",
    "virtual_account_provider_ecPay": "EC Pay 綠界科技虛擬帳號",
    "virtual_account_provider_ubot": "Union Bank of Taiwan 聯邦銀行虛擬帳號",
    "void": "Void",
    "waybill": "Waybill",
    "waybills per page": "Waybills Per Page",
    "web": "Web",
    "webpage": "Web Page",
    "webpage_content": "Web Page Content",
    "webpage_show_on_footer": "Webpage Show On Footer",
    "webpages": "Web Pages",
    "website": "Website",
    "website admin mode": "Website Admin Mode",
    "website info": "Website Info",
    "website template": "Website Template",
    "website url": "Website URL",
    "website url (at least 6 characters)": "Website Url (At Least 6 Characters)",
    "website_action_color": "Action Button Color",
    "website_action_final_color": "Action Final Color",
    "website_action_final_hover_color": "Action Final Hover Color",
    "website_action_final_text": "Checkout Button Text",
    "website_action_final_text_color": "Action Final Text Color",
    "website_action_hover_color": "Hover Color",
    "website_action_text_color": "Action Button Text Color",
    "website_active_color": "Selected Color",
    "website_background_primary_color": "Primary Background Color",
    "website_background_secondary_color": "Secondary Background Color",
    "website_border_color": "Border Color",
    "website_button_border_color": "Button Border Color",
    "website_button_border_color2": "Button border color 2",
    "website_button_border_color3": "Button border color 3",
    "website_button_border_radius": "Button Border Radius",
    "website_button_border_radius_2": "Button Border Radius 2",
    "website_button_border_radius_3": "Button Border Radius 3",
    "website_button_color": "Button Color",
    "website_button_color2": "Button color 2",
    "website_button_color3": "Button color 3",
    "website_button_hover_color": "Button Hover Color",
    "website_button_hover_color2": "Button hover color 2",
    "website_button_hover_color3": "Button hover color 3",
    "website_button_hover_text_color": "Button Hover Text Color",
    "website_button_hover_text_color2": "Button Hover Text Color 2",
    "website_button_hover_text_color3": "Button Hover Text Color 3",
    "website_button_text_color": "Button Text Color",
    "website_button_text_color2": "Button text color 2",
    "website_button_text_color3": "Button text color 3",
    "website_chat_button_color": "Chat Button Color",
    "website_chat_button_hover_color": "Chat Button Hover Color",
    "website_chat_button_text_color": "Chat Button Text Color",
    "website_count_down_color": "Count Down Color",
    "website_count_down_text_color": "Countdown Text Color",
    "website_footer_background_color": "Footer Background Color",
    "website_footer_background_primary_color": "Footer Background Color",
    "website_footer_header_color": "Website Footer Header Color",
    "website_footer_link_color": "Footer Link Color",
    "website_footer_link_hover_color": "Footer Link Hover Color",
    "website_footer_message": "Footer Message",
    "website_footer_text_color": "Footer Text Color",
    "website_headers": "Custom HTML Head Content",
    "website_input_background_color": "Input Background Color",
    "website_input_border_color": "Input Border Color",
    "website_input_border_focus_color": "Input Border Focus Color",
    "website_input_text_color": "Input Text Color",
    "website_link__hover_color": "Link Hover Color",
    "website_link_color": "Link Color",
    "website_link_hover_color": "Link Hover Color",
    "website_menu_background_primary_color": "Menu Background Color",
    "website_menu_background_secondary_color": "Menu Background Secondary Color",
    "website_menu_border_color": "Menu Border Color",
    "website_menu_link_color": "Menu Text Color",
    "website_menu_link_hover_color": "Menu Text Hover Color",
    "website_menu_type": "Type",
    "website_primary_color": "Primary Color",
    "website_sale_color": "On Sale Tag Color",
    "website_sale_text_color": "On Sale Tag Text Color",
    "website_scripts": "Javascript",
    "website_section_action": "Add To Cart Button",
    "website_section_action_final_btn": "Checkout Button",
    "website_section_backgrounds": "Backgrounds",
    "website_section_button_1": "Button 1",
    "website_section_button_2": "Button 2",
    "website_section_button_3": "Button 3",
    "website_section_buttons": "Buttons",
    "website_section_chat": "Line/FB Chat",
    "website_section_count_down": "Countdown",
    "website_section_footer": "Footer",
    "website_section_inputs": "Inputs",
    "website_section_links": "Links",
    "website_section_nav": "Menu",
    "website_section_other": "Other",
    "website_section_sales": "Promotions",
    "website_section_tags": "Tags",
    "website_section_text": "Text",
    "website_section_text_basic": "Basic",
    "website_seo_description": "SEO Description",
    "website_seo_keywords": "SEO Keywords",
    "website_tag_color": "Tag Color",
    "website_tag_text_color": "Tag Text Color",
    "website_text_2_color": "Text 2 Color",
    "website_text_3_color": "Text 3 Color",
    "website_text_color": "Text Color",
    "website_type_link": "Link",
    "website_type_link_product": "Product",
    "website_type_link_url": "Url",
    "website_type_page": "Page",
    "websites": "Websites",
    "wednesday": "Wednesday",
    "wednesday opening hours": "Wednesday Opening Hours",
    "weekday1": "Monday",
    "weekday2": "Tuesday",
    "weekday3": "Wednesday",
    "weekday4": "Thursday",
    "weekday5": "Friday",
    "weekday6": "Saturday",
    "weekday7": "Sunday",
    "weeks": "Weeks",
    "weight": "Weight",
    "weight_unit_default": "Default Weight Unit",
    "welcome to bigordr": "Welcome to BigOrdr!",
    "with grand totals": "with Grand Totals",
    "with prices": "With Prices",
    "without prices": "Without Prices",
    "workflows": "Workflows",
    "workflows_items_activities_call": "Call",
    "workflows_items_activities_email": "Email",
    "workflows_items_activities_event": "Event",
    "workflows_items_activities_meeting": "Meeting",
    "workflows_items_activities_notes": "Notes",
    "workflows_items_activity": "Activity",
    "workflows_items_status_open": "Open",
    "workflows_items_status_re-open": "Re-Open",
    "workflows_items_status_successful": "Successful",
    "workflows_items_status_unsuccessful": "Unsuccessful",
    "year": "Year",
    "years": "Years",
    "yes": "Yes",
    "youtube channel url": "Youtube Channel Url",
    "zone priority": "Zone Priority",
    "zones fee": "Zones Fee"
  };
  _exports.default = _default;
});