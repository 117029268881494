define("client/pods/components/documents/table-view/doc-row/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/mixins/crud", "client/mixins/dispatch", "client/mixins/transporter", "client/utils/nventor", "ramda-extension"], function (_exports, _applyDecoratedDescriptor2, R, _crud, _dispatch, _transporter, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var _default = Ember.Component.extend(_crud.default, _dispatch.default, _transporter.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tagName: '',
    showAllDetails: false,
    showSplitMergePrintBtns: false,

    init() {
      this._super(...arguments);

      if (this.isFilters) {
        const defaultFiltersData = {};
        const source = R.path(['filters', '_data', 'source'])(this);

        if (source) {
          defaultFiltersData.query = source;
        }

        const campaignFilters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/filters',
          defaultFiltersData
        });
        this.set('campaignFilters', campaignFilters);
      }

      if (this.model) {
        const modelProxy = this.model;
        this.set('modelProxy', modelProxy);
        this.set('model', modelProxy.get('model'));
      }
    },

    hasReturnsDetails: Ember.computed('model._data.docName', function () {
      const docName = this.get('model._data.docName');

      if (docName === 'invoices') {
        return true;
      }

      return false;
    }),
    // {{if (isEven rowIndex) 'even' 'odd'}}
    detailInfoRowClassName: Ember.computed('isDetailRow', 'rowIndex', 'tableViewOptions.allowDispatching', function () {
      if (this.isDetailRow && this.get('tableViewOptions.allowDispatching')) {
        return 'dispatch-list__table-info-row';
      }

      if (this.isDetailRow) {
        if (_nventor.default.isEven(this.rowIndex)) {
          return 'even';
        }

        return 'odd';
      }

      return '';
    }),
    detailRowClassName: Ember.computed('isDetailRow', 'rowIndex', 'tableViewOptions.allowDispatching', function () {
      if (this.isDetailRow && this.get('tableViewOptions.allowDispatching')) {
        return '';
      }

      if (this.isDetailRow) {
        if (_nventor.default.isEven(this.rowIndex)) {
          return 'even';
        }

        return 'odd';
      }

      return '';
    }),
    dispatchProcess: Ember.computed('tableViewOptions.dispatchProcesses', function () {
      return this.get('tableViewOptions.dispatchProcess');
    }),
    allowCheckbox: Ember.computed('modelProxy.model._data.{status,dispatchProcess}', 'dispatchProcess', function () {
      const model = this.get('modelProxy.model');
      const currentDispatchProcess = this.get('dispatchProcess._data.code');
      const dispatchProcess = model.getData('dispatchProcess') || 'unprepared';

      if (dispatchProcess !== currentDispatchProcess && currentDispatchProcess !== 'all') {
        return false;
      }

      if (model.getData('status') === this.get('constants.documentsStatus.final')) {
        return true;
      }

      return false;
    }),
    hasDuplicateWaybill: Ember.computed('modelProxy.model._data.waybill', 'duplicateWaybillsDocNos.@each.selected', function () {
      const modelValue = this.get('modelProxy.model._data.waybill');
      const duplicates = this.duplicateWaybillsDocNos || [];
      const found = R.find(R.propEq('value', modelValue), duplicates);

      if (found) {
        return true;
      }

      return false;
    }),
    hasDuplicateContact: Ember.computed('modelProxy.model._data.address', 'duplicateAddressesDocNos.[]', function () {
      const model = this.get('modelProxy.model');
      const modelValue = model.getData('address');
      const duplicates = this.duplicateAddressesDocNos || [];
      const found = R.filter(R.allPass([R.propEq('value', modelValue), _nventor.default.allEqProps(['docType', 'docName'], model._data)]))(duplicates);

      if (!R.isEmpty(found)) {
        return true;
      }

      return false;
    }),

    toggleMoreBtns() {
      this.set('showSplitMergePrintBtns', !this.showSplitMergePrintBtns);
    },

    actions: {
      select()
      /* model */
      {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },

      loadShopSource(shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));

        if (this.onSearchTask) {
          this.onSearchTask.perform({
            filters: this.filters
          });
        }
      },

      unloadShopSource() {
        const filters = this.filters;
        filters.setData('sourceKey', '');

        if (this.onSearchTask) {
          this.onSearchTask.perform({
            filters: this.filters
          });
        }
      },

      selectDispatchProcesses() {
        const filters = this.filters;
        const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
        const dispatchProcesses = filters.getData('dispatchProcesses');
        const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
          return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
        }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
        filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
      },

      toggleAllDetails() {
        let showAllDetails = this.showAllDetails;

        if (showAllDetails) {
          showAllDetails = false;
        } else {
          showAllDetails = true;
        }

        this.set('showAllDetails', showAllDetails);
        const results = this.results;
        results.forEach(modelProxy => {
          modelProxy.set('showDetails', showAllDetails);
        });
      },

      afterSplit() {
        const dispatchProcess = this.dispatchProcess;
        const onAfterSplit = this.get('tableViewOptions.onAfterSplit');
        onAfterSplit(dispatchProcess);
      },

      afterMerge() {
        const dispatchProcess = this.dispatchProcess;
        const onAfterMerge = this.get('tableViewOptions.onAfterMerge');
        onAfterMerge(dispatchProcess);
      }

    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "toggleMoreBtns", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleMoreBtns"), _obj)), _obj)));

  _exports.default = _default;
});