define("client/pods/components/websites/websites-editor/3rd-party-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qbnI74v",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"settings/defaults/3rd-party-editor\",null,[[\"documentationResource\",\"isReadonly\",\"errors\",\"model\"],[\"websites\",[24,[\"isReadonly\"]],[24,[\"errors\"]],[24,[\"model\"]]]]],false],[0,\"\\n\\n\"],[4,\"forms/form-section\",null,[[\"title\",\"context\",\"documentationResource\"],[\"facebook pixel\",\"facebookPixel\",\"websites\"]],{\"statements\":[[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"isRequired\",\"errors\",\"context\",\"documentationResource\"],[\"enable facebook pixel conversion api\",true,[24,[\"errors\"]],\"facebookPixelEnable\",\"websites\"]],{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-toggler\",null,[[\"disabled\",\"toggleValue\",\"toggleOnValue\",\"toggleOffValue\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"facebookPixelEnable\"]],true,false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"facebookPixelEnable\"]]],null,{\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"pixel id\",[24,[\"errors\"]],\"facebookPixelId\",\"websites\"]],{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"facebookPixelId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"access token\",[24,[\"errors\"]],\"facebookAccessToken\",\"websites\"]],{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"facebookAccessToken\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/3rd-party-editor/template.hbs"
    }
  });

  _exports.default = _default;
});