// import Service, { inject as service } from '@ember/service'
// import { getOwner } from '@ember/application'
// let self
// Service.create({
//   init () {
//     self = this
//   }
// })
// // @TODO: test
// const uploads = {
//   transformFilesToCollection (data) {
//     // @TODO: remove, not used
//     const adapter = getOwner(self).lookup(`adapter:products`)
//     console.log('=====adapter====', adapter)
//     // const files = R.propOr([], 'files', data)
//     // return this.get('dataStore').startRecord('uploads', uploadsData)
//   }
// }
// export default uploads
define("client/pods/uploads/service", [], function () {
  "use strict";
});