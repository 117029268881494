define("client/pods/settings/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/settings/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    emails: Ember.inject.service(),
    resourcePath: 'api/protected/settings',
    modelObj: _model.default,
    idParam: '_id',

    serialize(model) {
      const data = this._super(model);

      data.orderedEmailCopyTo = this.emails.splitToArray(data.orderedEmailCopyTo);
      data.returnsEmailCopyTo = this.emails.splitToArray(data.returnsEmailCopyTo);
      data.tw_eInvoices_notifyEmailAddress = this.emails.splitToArray(data.tw_eInvoices_notifyEmailAddress);
      return data;
    }

  });

  _exports.default = _default;
});