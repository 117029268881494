define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/districts-column/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "showDistricts", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDistrictsForRegion", _descriptor3, this);
      this.setSelectedDistrictsForRegion();
    }

    setSelectedDistrictsForRegion() {
      var _country$_data;

      const region = this.args.region || {};
      const country = this.args.country;
      const allSelectedDistricts = (country === null || country === void 0 ? void 0 : (_country$_data = country._data) === null || _country$_data === void 0 ? void 0 : _country$_data.districts) || [];
      this.selectedDistrictsForRegion = _ramda.default.pipe(_ramda.default.pathOr([], ['_data', 'districts']), _ramda.default.filter(district => {
        const postcode = district.getData('postcode');
        return _ramda.default.includes(postcode)(allSelectedDistricts);
      }))(region);
    }

    get filteredDistricts() {
      const region = this.args.region || [];
      const districts = region.getData('districts') || [];
      return districts;
    }

    onToggleSelectAllDistrict() {
      const country = this.args.country;
      const region = this.args.region || {};

      const districtsForRegion = _ramda.default.pathOr([], ['_data', 'districts'])(region);

      const selectedDistricts = country.getData('districts') || []; // R.forEach(country => {
      // const code = country?._data?.code

      _ramda.default.forEach(district => {
        const postcode = _ramda.default.path(['_data', 'postcode'])(district);

        selectedDistricts.pushObject(postcode);
      })(districtsForRegion);

      country.setData('districts', []);
      this.showDistricts = false;
      Ember.run.scheduleOnce('afterRender', () => {
        country.setData('districts', selectedDistricts);
        this.setSelectedDistrictsForRegion();
        this.showDistricts = true;
      });
    }

    onToggleDeselectAllDistrict() {
      const country = this.args.country;
      const region = this.args.region || {};

      const districtsForRegion = _ramda.default.pathOr([], ['_data', 'districts'])(region);

      const selectedDistricts = country.getData('districts') || [];

      _ramda.default.forEach(district => {
        const postcode = _ramda.default.path(['_data', 'postcode'])(district);

        selectedDistricts.removeObject(postcode);
      })(districtsForRegion);

      this.showDistricts = false;
      country.setData('districts', []);
      Ember.run.scheduleOnce('afterRender', () => {
        country.setData('districts', selectedDistricts);
        this.setSelectedDistrictsForRegion();
        this.showDistricts = true;
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showDistricts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDistrictsForRegion", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setSelectedDistrictsForRegion", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedDistrictsForRegion"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSelectAllDistrict", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectAllDistrict"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleDeselectAllDistrict", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleDeselectAllDistrict"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent;
});