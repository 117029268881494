define("client/mixins/sortable-details", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortOrderChanged: 0,
    toSortProp: '_data.details',
    sortArrayInstructions: null,
    indexProp: '_data.index',
    // to be overriden if sort prop is different to detail
    toSortArray: Ember.computed('_data.details.@each.synced', 'synced', function () {
      return this.get('_data.details');
    }),
    _detailsArray: Ember.computed('toSortArray.[]', 'synced', 'toSortProp', 'sortOrderChanged', '_data._rev', function () {
      const toSortArray = this.toSortArray;
      return R.pluck('_data', toSortArray);
    }),
    sorted: Ember.computed('_detailsArray.@each.{_key,_id,_rev}', 'synced', 'sortOrderChanged', 'sortArrayInstructions.[]', function () {
      const detailsArray = this.toSortArray || [];
      let sortArrayInstructions = this.sortArrayInstructions || [{
        direction: 'ascend',
        prop: this.indexProp
      }];

      if (!R.is(Array, sortArrayInstructions)) {
        sortArrayInstructions = [sortArrayInstructions];
      }

      sortArrayInstructions = R.map(instructions => {
        const pathArray = R.split('.', instructions.prop);

        if (instructions.direction === 'descend') {
          return R.descend(R.path(pathArray));
        }

        return R.ascend(R.path(pathArray));
      })(sortArrayInstructions);
      return R.sortWith(sortArrayInstructions)(detailsArray);
    }),
    lastIndex: Ember.computed('sorted.[]', function () {
      const sorted = this.sorted || [];
      return this._getLastIndex(sorted);
    }),

    _getLastIndex(details) {
      const last = details.get('lastObject');
      const indexProp = this.indexProp;

      if (last) {
        return parseInt(last.get(indexProp)) || 0;
      }

      return 0;
    },

    nextIndex: Ember.computed('sorted.[]', 'lastIndex', function () {
      return this._getNextIndex();
    }),

    _getNextIndex(nextIndex) {
      const indexProp = this.indexProp;
      const sorted = this.sorted || [];

      if (nextIndex) {
        nextIndex = nextIndex + 1;
      } else {
        const lastIndex = this.lastIndex;
        nextIndex = parseInt(lastIndex) + 1;

        if (sorted.length > nextIndex) {
          nextIndex = sorted.length;
        }
      } // check if nextindex exists


      const path = R.split('.', indexProp);
      const exists = R.find(R.pathEq(path, nextIndex), sorted);

      if (exists) {
        nextIndex = this._getNextIndex(nextIndex);
      }

      return nextIndex;
    },

    moveDetailAbove(detail, fromIndex, toIndex) {
      const indexProp = this.indexProp;
      const currentIndex = detail.get(indexProp);

      if (currentIndex >= toIndex && currentIndex < fromIndex) {
        detail.set(indexProp, currentIndex + 1);
        detail.set('changed', true);
      }

      if (currentIndex === fromIndex) {
        detail.set(indexProp, toIndex);
        detail.set('changed', true);
      }

      this.resetSortOrder();
      return detail;
    },

    moveDetailBelow(detail, fromIndex, toIndex) {
      const indexProp = this.indexProp;
      const currentIndex = detail.get(indexProp);

      if (currentIndex > fromIndex && currentIndex <= toIndex) {
        detail.set(indexProp, currentIndex - 1);
        detail.set('changed', true);
      }

      if (currentIndex === fromIndex) {
        detail.set(indexProp, toIndex);
        detail.set('changed', true);
      }

      this.resetSortOrder();
      return detail;
    },

    resetSortOrder() {
      this.set('sortOrderChanged', Math.random());

      if (this.updateSynced) {
        this.updateSynced();
      }
    },

    resetIndexes() {
      this.resetSortOrder();
      let previousIndex;
      const indexProp = this.indexProp;
      const details = this.sorted || [];
      let currentIndex = 0;
      details.forEach(detail => {
        if (detail._data.isSetChild) {
          currentIndex = previousIndex || 1;
        } else {
          currentIndex = currentIndex + 1;
        }

        detail.set(indexProp, currentIndex);
        previousIndex = currentIndex;
      });
      this.resetSortOrder();
    },

    reorderDetails(detail, dragItem) {
      const toSortProp = this.toSortProp;
      const indexProp = this.indexProp;
      const dragIndex = dragItem.get(indexProp);
      const onOverItemIndex = detail.get(indexProp);
      const details = this.get(toSortProp);

      if (dragIndex === onOverItemIndex) {// do nothing
      } else if (dragIndex < onOverItemIndex) {
        // move to below onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailBelow(detail, dragIndex, onOverItemIndex)));
      } else {
        // move to above onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailAbove(detail, dragIndex, onOverItemIndex)));
      }

      if (this.updateSynced) {
        this.updateSynced();
      }

      if (this.sortDetails) {
        this.sortDetails();
      }

      return R.filter(R.pathEq(['changed'], true))(details);
    }

  });

  _exports.default = _default;
});