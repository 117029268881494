define("client/pods/components/reports/dashboard/card-page-views/component", ["exports", "ramda", "client/mixins/date"], function (_exports, R, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    tagName: '',
    chartLegend: 'revenue',
    metric: 'net',
    chartType: null,
    // chartHeight: null,
    chartWidth: null,
    translate: false,
    isTaxExclusive: true,
    averageByItemQty: false,
    isTableView: true,
    isReloadingChart: false,
    defaultLimitResults: 10,
    limitResults: 10,
    maxChartResults: 30,

    init() {
      this._super(...arguments);
    },

    sorted: Ember.computed('data.byPages.[]', function () {
      const data = this.get('data.byPages') || [];

      if (Ember.isEmpty(data)) {
        return [];
      }

      const sort = R.pipe(R.sortWith([R.descend(R.pipe(R.prop('views')))]));
      return sort(data);
    }),
    limited: Ember.computed('sorted.[]', 'limitResults', function () {
      const sorted = this.sorted;
      const limitResults = this.limitResults;

      if (limitResults) {
        return R.take(limitResults, sorted);
      }

      return sorted;
    }),
    hasMoreResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length > this.limited.length) {
        return true;
      }

      return false;
    }),
    hasLessResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length === this.limited.length) {
        return false;
      }

      return true;
    }),
    actions: {
      viewMore() {
        this.set('limitResults', false);
        this.reloadChart();
      },

      viewLess() {
        this.set('limitResults', this.defaultLimitResults);
        this.reloadChart();
      }

    }
  });

  _exports.default = _default;
});