define("client/pods/components/documents/document-totals/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    big: Ember.inject.service(),
    tagName: '',
    roundingCurrency: 0,
    isNotDocSalesInvoice: Ember.computed('model._data.{docName,docType}', function () {
      const docName = this.model.getData('docName');
      const docType = this.model.getData('docType');

      if (docName === 'invoices' && docType === 'sales') {
        return false;
      }

      return true;
    }),
    hasRewardEventForOrder: Ember.computed('rewardEventForOrder', function () {
      const rewardEventForOrder = this.get('rewardEventForOrder') || {};

      if (RA.isNotNilOrEmpty(rewardEventForOrder)) {
        return true;
      }

      return false;
    }),
    rewardAmountCalculated: Ember.computed('model._data.rewardPointApplied', 'rewardEventForOrder', 'model.rewardAmountCalculated', function () {
      var _this$model, _this$model2, _this$model2$_data;

      let rewardAmountCalculated = ((_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.rewardAmountCalculated) || 0;
      const rewardPointApplied = ((_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$_data = _this$model2._data) === null || _this$model2$_data === void 0 ? void 0 : _this$model2$_data.rewardPointApplied) || 0;
      const rewardEventForOrder = this.rewardEventForOrder || {};

      if (RA.isNotNilOrEmpty(rewardEventForOrder) && rewardPointApplied > 0) {
        const roundingCurrency = this.model.getData('roundingCurrency') || 0;
        const rewardRate = R.propOr(0, 'ratePerPoint')(rewardEventForOrder);
        rewardAmountCalculated = this.big.newBig(rewardPointApplied).times(rewardRate).toFixed(roundingCurrency);
      }

      return rewardAmountCalculated;
    }),
    actions: {
      removePromoCode(model) {
        model.setData('promoCode', '');
        model.setData('discountAmount', '');
        model.setData('discountRate', '');
      },

      toggleDiscountType(item) {
        const discType = item.getData('value');
        const model = this.model;

        if (discType === this.get('constants.discountTypes.rate')) {
          const currentDiscValue = model.getData('discountAmount') || model.getData('discountRate');
          model.setData('discountRate', currentDiscValue);
          model.setData('discountAmount', '');
        } else {
          const currentDiscValue = model.getData('discountRate') || model.getData('discountAmount');
          model.setData('discountAmount', currentDiscValue);
          model.setData('discountRate', '');
        }

        model.sumTotals();
      }

    }
  });

  _exports.default = _default;
});