define("client/pods/components/websites/panels-display/sub-panels-pages/pages-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbObHpIr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor/pages-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"isReadonly\",\"model\",\"website\",\"hasWebBuilder\",\"pagesCollection\",\"errors\",\"fullscreenOnInit\",\"onCancel\",\"onRemoveTask\",\"onSaveTask\",\"onRemove\",\"toggleShowWebsitePagesNav\"],[[24,[\"tab\"]],true,false,false,[24,[\"dirty\"]],[24,[\"website\"]],[24,[\"hasWebBuilder\"]],[24,[\"pagesCollection\"]],[24,[\"errors\"]],true,[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"removeRecordTask\"]],[28,\"perform\",[[24,[\"updateTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\"],[\"websites/pages\",[24,[\"dirty\"]]]]]],null],[28,\"perform\",[[24,[\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\"],[\"websites/pages\",[24,[\"dirty\"]],[24,[\"onAfterRemove\"]]]]]],null],[24,[\"toggleShowWebsitePagesNav\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/pages-edit/template.hbs"
    }
  });

  _exports.default = _default;
});