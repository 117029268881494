define("client/pods/specs/model", ["exports", "client/pods/base/model", "ramda", "ramda-extension", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, R_, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    init() {
      this._super(...arguments); // @TODO:
      // const specsPerMethod = {
      //   byOrders: ['', 'index', ]
      // }


      const specs = [{
        value: '',
        defaultLabel: '',
        prop: '',
        translate: false,
        allowedTransforms: [],
        disallowedTransforms: []
      }, {
        value: 'index',
        defaultLabel: 'index',
        prop: null,
        allowedTransforms: ['index'],
        disallowedTransforms: []
      }, {
        value: '#',
        defaultLabel: '#',
        prop: 'docNo',
        translate: false,
        allowedTransforms: [],
        disallowedTransforms: []
      }, {
        value: 'date',
        defaultLabel: 'date',
        prop: 'dateZ',
        type: 'date',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index'] // allowCustomOptions: true/false

      }, {
        value: 'person',
        defaultLabel: 'person',
        prop: 'person',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'contact',
        defaultLabel: 'contact',
        prop: 'contact',
        label: this.intl.t('customer'),
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'person + contact',
        defaultLabel: 'person + contact',
        prop: ['person', 'contact'],
        allowedTransforms: ['concat'],
        disallowedTransforms: ['index']
      }, {
        value: 'address',
        defaultLabel: 'address',
        prop: 'address',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'telephone',
        defaultLabel: 'telephone',
        prop: 'telephone',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'payment method',
        defaultLabel: 'payment method',
        prop: 'paymentMethodKey',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'total',
        defaultLabel: 'total',
        type: 'number',
        prop: 'inclTotal',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'packages',
        defaultLabel: 'packages',
        prop: 'packages',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'notes',
        defaultLabel: 'notes',
        prop: 'notes',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'ref',
        defaultLabel: 'ref',
        prop: 'ref',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'waybill',
        defaultLabel: 'waybill',
        prop: 'waybill',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'rewardInclTotal',
        defaultLabel: 'rewards inclusive total',
        prop: 'rewardInclTotal',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'discInclTotal',
        defaultLabel: 'discount amount',
        prop: 'discInclTotal',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'email',
        defaultLabel: 'email',
        prop: 'email',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'item',
        defaultLabel: 'item',
        item: '',
        // specific item
        isDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      } // {
      //   value: 'itemName',
      //   prop: 'item',
      //   isDetail: true,
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index', 'concat']
      // }, {
      //   value: 'itemCode',
      //   prop: 'itemCode',
      //   isDetail: true,
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index', 'concat']
      // }, {
      //   value: 'qty',
      //   prop: 'qty',
      //   isDetail: true,
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index', 'concat']
      // }, {
      //   value: 'price',
      //   prop: 'price',
      //   isDetail: true,
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index', 'concat']
      // }]
      ];
      const availableNormalSpecs = R.map(spec => {
        const hasLabel = spec.label;

        if (!hasLabel) {
          let label = spec.defaultLabel;

          if ((spec === null || spec === void 0 ? void 0 : spec.translate) !== false) {
            label = this.intl.t(spec.defaultLabel);
          }

          spec.label = label;
        }

        return Ember.Object.create(spec);
      })(specs);
      this.set('availableNormalSpecs', availableNormalSpecs);
    },

    availableCombineProps: Ember.computed('availableNormalSpecs.[]', function () {
      return R.pipe(R.propOr([], 'availableNormalSpecs'), R.reject(_nventor.default.isNilOrEmptyDotPath('prop')), R.filter(data => {
        const dataProp = R_.dotPath('prop', data);
        return R.is(String, dataProp);
      }))(this);
    }),

    populate(data, attrs) {
      const availableNormalSpecs = this.get('availableNormalSpecs');
      const dataSpecs = R.pipe(R.propOr([], 'specs'), R.map(R.map(spec => {
        return this.dataManager.setAsRecord({
          adapterName: 'specs/child',
          data: spec,
          attrs: {
            availableNormalSpecs
          }
        });
      })))(data);
      data.specs = dataSpecs;
      data.originalSpecsStructure = dataSpecs;

      this._super(...arguments);
    },

    columnNames: Ember.computed('_data.specs.[]', 'synced', function () {
      return R.pipe(R.pathOr([], ['_data', 'specs']), _nventor.default.safeHeadOr([]))(this);
    }),
    availableMethods: Ember.computed('_data.{}', function () {
      return [Ember.Object.create({
        label: 'byDetails',
        value: 'byDetails'
      }), Ember.Object.create({
        label: 'byAllOrders',
        value: 'byAllOrders'
      })];
    }),

    addNewRow(data = {}, insertAfterIndex = 0) {
      let insertAtIndex = 0;

      if (insertAfterIndex >= 0) {
        insertAtIndex = parseInt(insertAfterIndex) + 1;
      }

      const specs = this.get('_data.specs') || [];

      if (insertAfterIndex > specs.length) {
        insertAfterIndex = specs.length;
      }

      if (RA.isNilOrEmpty(data)) {
        data = {
          value: '',
          prop: '',
          label: '',
          translate: false
        };
      }

      const availableNormalSpecs = this.get('availableNormalSpecs');
      const newCell = this.dataManager.setAsRecord({
        adapterName: 'specs/child',
        data: data,
        attrs: {
          availableNormalSpecs
        }
      });
      specs.insertAt(insertAtIndex, RA.list(newCell));
    },

    sortedSpecs: Ember.computed('synced', function () {
      const sortedSpecs = R_.dotPath('_data.specs')(this);
      return sortedSpecs;
    })
  });

  _exports.default = _default;
});