define("client/pods/components/products/search-bar-container/search-bar/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    query: '',
    filters: null,
    autofocus: true,
    adapterName: 'products'
  });

  _exports.default = _default;
});