define("client/pods/workflows/items/activities/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data = {}) {
      data.users = this._transformUsers(data);
      data.related = this._transformRelated(data);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});