define("client/pods/components/contacts/panels-report/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  // import RA from 'ramda-adjunct'
  let ContactsPanelsReportComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class ContactsPanelsReportComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor4, this);
      const filters = this.search.setupFilters({
        adapterName: 'contacts/report/filters'
      });
      this.filters = filters;
    }

    get contactsWithNoReward() {
      const results = this.results;
      return R.pipe(R.find(R.propEq('reward', '')), R.propOr(0, 'total'))(results);
    }

    *fetchContactWithRewardDataTask() {
      const filters = this.filters;
      const response = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts/report',
        appendPath: '',
        filters,
        rawResponse: true
      });
      this.results = response;
    }

    getContactWithRewardData() {
      this.fetchContactWithRewardDataTask.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchContactWithRewardDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchContactWithRewardDataTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getContactWithRewardData", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getContactWithRewardData"), _class.prototype)), _class));
  _exports.default = ContactsPanelsReportComponent;
});