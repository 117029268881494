define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent = (_dec = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent extends _component.default {
    get modalDialogIsVisible() {
      const model = this.args.model;

      if (model) {
        const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];

        if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
          return true;
        }
      }

      return false;
    }

    onCloseBulkDiscountConflictDialog(onCloseDialogAction) {
      const model = this.args.model;
      const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];

      if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
        model.setData('bulkDiscountConflictDetails', []);
      }

      if (this.args.onDone) {
        this.args.onDone();
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCloseBulkDiscountConflictDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseBulkDiscountConflictDialog"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent;
});