define("client/pods/products/model", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/pods/base/model", "client/mixins/sortable-details", "client/mixins/products-children", "client/mixins/uploads", "client/mixins/templates", "client/pods/faqs/model", "client/utils/nventor"], function (_exports, _applyDecoratedDescriptor2, R, _model, _sortableDetails, _productsChildren, _uploads, _templates, _model2, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  var _default = _model.default.extend(_productsChildren.default, _sortableDetails.default, _templates.default, _uploads.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    toSortProp: '_data.setDetails',

    init() {
      this._super(...arguments);

      this.set('importParams', [{
        label: 'name',
        value: 'name'
      }]);
      this.set('setDetailsRequiredAttrs', ['item', 'itemKey', 'qty', 'status']);
      this.set('childAdapters', {
        choices: 'products/choices',
        'choices-details': 'products/choices/details',
        'sets-details': 'products/sets/details',
        variants: 'products/variants',
        'variants-options': 'products/variants/options',
        'variants-children': 'products/variants/children'
      });
    },

    defaults() {
      const settings = this.settings;
      return {
        type: settings.getProp('inventoryType', this.get('constants.productTypes.inventory')),
        tracking: settings.getProp('inventoryTracking', this.get('constants.productTracking.on')),
        status: this.get('constants.status.active'),
        salesTaxRateKey: this.get('constants.taxes.auto'),
        purchasesTaxRateKey: this.get('constants.taxes.auto'),
        weightUnit: settings.getProp('weightUnit', 'g'),
        packagingDimensionsUnit: settings.getProp('packagingDimensionsUnit', 'cm'),
        setDetails: [],
        choices: [],
        variants: [],
        faqs: [],
        images: [],
        templates: []
      };
    },

    toSortArray: Ember.computed('_data.setDetails.[]', 'synced', function () {
      return this.get('_data.setDetails');
    }),

    populate(data = {}) {
      if (data.tracking === this.get('constants.togglesOnOff.on')) {
        const inventory = this.transformInventory(data);
        this.set('inventory', inventory);
      }

      data.faqs = this._transformFaqs(data);
      data.templates = this._transformTemplates(data);

      if (RA.isNilOrEmpty(data.templates)) {
        data.templates.pushObject(this.dataManager.setAsRecord({
          adapterName: 'products/templates',
          data: this.getTemplateHash('')
        }));
      }

      this._super(...arguments);
    },

    _transformTemplates(data) {
      return R.pipe(R.pathOr([], ['templates']), R.map(template => this.dataManager.setAsRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash(template)
      })))(data);
    },

    getTemplateHash(args = {}) {
      const {
        template,
        header,
        status
      } = args;
      return {
        component: 'products/products-editor/template',

        get label() {
          return this.header;
        },

        action: 'new',
        tabType: 'reuseSubTab',
        header,
        template,
        status
      };
    },

    addNewTemplate() {
      var _this$_data, _this$_data$templates;

      const newTemplate = this.dataManager.newRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash()
      });
      this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : (_this$_data$templates = _this$_data.templates) === null || _this$_data$templates === void 0 ? void 0 : _this$_data$templates.pushObject(newTemplate);
      return newTemplate;
    },

    removeTemplate(template) {
      if (template._data) {
        var _this$_data2, _this$_data2$template;

        this === null || this === void 0 ? void 0 : (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : (_this$_data2$template = _this$_data2.templates) === null || _this$_data2$template === void 0 ? void 0 : _this$_data2$template.removeObject(template);
      } else {
        var _this$_data3, _this$_data3$template;

        const header = R.propOr('', 'header')(template);
        const templateModel = R.pipe(R.pathOr([], ['_data', 'templates']), R.find(R.pathEq(['_data', 'header'], header)))(this);
        this === null || this === void 0 ? void 0 : (_this$_data3 = this._data) === null || _this$_data3 === void 0 ? void 0 : (_this$_data3$template = _this$_data3.templates) === null || _this$_data3$template === void 0 ? void 0 : _this$_data3$template.removeObject(templateModel);
      }
    },

    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model2.default, faq)))(data);
    },

    hasDuplicateSetDetailsProduct(product) {
      const details = this.get('_data.setDetails') || [];
      const found = details.filterBy('_data.itemKey', product.getData('_key'));

      if (found.length > 1) {
        return true;
      }

      const itself = details.filterBy('_data.itemKey', this.getData('_key'));

      if (itself.length > 0) {
        return true;
      }

      return false;
    },

    hasDuplicateSetWithChoicesDetailsProduct(product) {
      const details = R.pipe(R.pathOr([], ['_data', 'choices']), R.pluck('_data'), R.pluck('details'), R.flatten)(this);
      const found = details.filterBy('_data.itemKey', product.getData('_key'));

      if (found.length > 1) {
        return true;
      }

      const itself = details.filterBy('_data.itemKey', this.getData('_key'));

      if (itself.length > 0) {
        return true;
      }

      return false;
    },

    loadChildProduct(detail, product) {
      const productData = product.serialize({
        exclude: ['_key', '_rev', '_id']
      });
      productData.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(child => {
        child.item = child.name;
        child.itemKey = child._key;
        return child;
      }))(productData);
      productData.setDetails = R.propOr([], 'setDetails')(productData);
      const itemData = detail.serialize();
      itemData.item = productData.name;
      itemData.productTags = productData.tags;
      itemData.roundingInventoryQty = productData.roundingInventoryQty || this.settings.getProp('roundingInventoryQty');
      itemData.roundingInventoryPrice = productData.roundingInventoryPrice || this.settings.getProp('roundingInventoryPrice');
      productData.salesInclPrice = '';
      productData.salesExclPrice = '';
      productData.purchasesInclPrice = '';
      productData.purchasesExclPrice = '';
      const newItemData = R.mergeRight(itemData, productData);
      detail.populate(newItemData);
    },

    hasInventory: Ember.computed('_data.{tracking,type}', function () {
      const type = this.get('_data.type');

      if (type === this.get('constants.productTypes.service')) {
        return false;
      }

      const tracking = this.get('_data.tracking');

      if (tracking === this.get('constants.productTracking.on')) {
        return true;
      }

      return false;
    }),

    transformInventory(rawData) {
      if (rawData.inventory) {
        const inventoryData = rawData.inventory;
        delete rawData.inventory;
        return this.dataManager.setAsRecord({
          adapterName: 'products/inventory',
          data: inventoryData
        });
      }

      return false;
    },

    newBlankSetDetail(newData) {
      const details = this.getOrSetData('setDetails', []);
      const lastDetail = details.get('lastObject');
      let mustAddDetail = true;

      if (lastDetail) {
        if (!lastDetail.getData('item') && !lastDetail.getData('itemCode')) {
          mustAddDetail = false;
        }
      }

      if (mustAddDetail) {
        return this._addSetDetail(newData);
      }

      return false;
    },

    /**
     * creates new detail row
     * @param {object} data (optional) - detail data
     * @return {object} detail object
     */
    _addSetDetail(data) {
      data = data || {}; // create details array if it doesnt exist

      let details = this.getData('setDetails');

      if (!details) {
        details = [];
        this.setData('setDetails', details);
      } // only add index if not exists


      if (data.index == null) {
        const index = details.length + 1;
        data.index = index;
      }

      const detailModel = this.dataManager.setAsRecord({
        adapterName: this.get('childAdapters.sets-details'),
        data
      }); // detailModel.set('requiredAttrs', this.get('setDetailsRequiredAttrs'))

      this.getData('setDetails').pushObject(detailModel);
      this.resetSortOrder();
      return detailModel;
    },

    removeSetDetail(detail) {
      const details = this.getData('setDetails'); // only remove detail if current line is not last line

      if (details.get('lastObject').getData('index') === detail.getData('index')) {
        // do not remove detail, clear detail only
        this.resetSetDetail(detail);
      } else {
        details.removeObject(detail);
        this.resetIndexes(details);
      }

      return details;
    },

    removeAllSetDetails() {
      this.setData('setDetails', []);
      this.resetSortOrder();
    },

    /**
     * resets detail line to blank state
     */
    resetSetDetail(detail) {
      // @TODO move this to detail model!?
      const data = detail.get('_data');
      const keys = Object.keys(data);
      keys.forEach(function (key) {
        let allowReset = true; // @TODO: could set a disallowResetAttrs array,
        // @TODO: eg. disallowResetAttrs: ['index', 'deliveryDateZ']
        // @TODO: then it will not allow resetting those values

        const allowResetKey = R.anyPass([R.equals('index'), R.equals('status')])(key);

        if (allowResetKey) {
          allowReset = false;
        }

        if (allowReset) {
          detail.setData(key, '');
        }
      });
    },

    addVariant(currentVariant, data) {
      const variants = this.getData('variants') || [];
      const lastVariant = variants.get('lastObject');
      const lastIndex = R.path(['_data', 'index'], lastVariant);
      let addVariant = false;

      if (!currentVariant) {
        addVariant = true;
      } else {
        if (R.path(['_data', 'index'], currentVariant) === lastIndex) {
          addVariant = true;
        }
      }

      if (addVariant) {
        let index = 1;

        if (lastIndex) {
          index = parseInt(lastIndex) + 1;
        }

        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active'),
          options: []
        };
        data = data || defaultData;
        const variantModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants'),
          data
        });
        variants.pushObject(variantModel);
        this.setData('variants', variants);
      }

      return data;
    },

    removeVariant(variant) {
      const variants = this.getData('variants') || [];
      variants.removeObject(variant);
      this.resetIndexes(variants);
      return variant;
    },

    addVariantOption(variant, currentOption, data) {
      const options = variant._data.options || [];
      const lastOption = options.get('lastObject');
      let addOption = false;

      if (!currentOption) {
        addOption = true;
      } else {
        if (R.path(['_data', 'index'], currentOption) === R.path(['_data', 'index'], lastOption)) {
          addOption = true;
        }
      }

      if (addOption) {
        let index = 1;

        if (lastOption) {
          const lastIndex = parseInt(lastOption._data.index) || 0;
          index = lastIndex + 1;
        }

        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active')
        };
        data = data || defaultData;
        data.optionUid = _nventor.default.random.alphaNum(10);
        const detailModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants-options'),
          data
        });
        options.pushObject(detailModel);
        variant.setData('options', options);
        variant.resetIndexes();
        return data;
      }

      return false;
    },

    removeVariantOption(variant, option) {
      const options = variant._data.options || [];

      if (options.get('lastObject._data.index') === option._data.index) {
        // do not remove detail, clear detail only
        this.resetVariantOption(option);
      } else {
        options.removeObject(option);
        this.resetIndexes(options);
      }

      variant.resetIndexes();
      return variant;
    },

    resetVariantOption(option) {
      option.name = '';
      return option;
    },

    addVariantChild(data, existingVariantChildren) {
      let found = R.find(R.pathEq(['_data', 'variantChildId'], data.variantChildId), existingVariantChildren);

      if (!found) {
        found = R.find(R.pathEq(['_data', 'variantChildUid'], data.variantChildUid), existingVariantChildren);
      }

      if (found) {
        const overwriteProps = ['salesExclPrice', 'salesInclPrice', 'purchasesExclPrice', 'purchasesInclPrice'];
        R.forEach(prop => {
          this.overwriteExisintVariantChildProp(found, prop, data[prop]);
        }, overwriteProps);
        const toSetProps = R.pipe(R.omit(overwriteProps), R.keys)(data);
        R.forEach(key => {
          const value = R.prop(key)(data);
          found.setData(key, value);
        })(toSetProps);
        return found;
      } else {
        const childModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants-children'),
          data
        });
        existingVariantChildren.pushObject(childModel);
        this.setData('_createVariantChildren', existingVariantChildren);
        return childModel;
      }
    },

    overwriteExisintVariantChildProp: R.curry((existingChildModel, prop, value) => {
      if (!existingChildModel.getData(prop)) {
        existingChildModel.setData(prop, value);
      }

      return existingChildModel;
    }),

    updateVariantChildrenList(originalModel) {
      // when first calling this, originalModel must be supplied,
      // then on input, originalModel must not be supplied
      const variants = this.getData('variants') || [];
      const existingVariantChildren = this.getData('_createVariantChildren') || [];
      let originalVariantChildren;

      if (originalModel) {
        originalVariantChildren = originalModel.getData('variantChildren');
      }

      const productName = this.getData('name');
      const productCode = this.getData('code');
      const allOptions = R.map(variant => {
        const variantType = R.trim(variant.getData('name')) || '';
        const variantIndex = variant.getData('index');
        let options = variant.getData('options') || [];
        options = R.pipe(R.map(option => {
          if (option.getData('name')) {
            const status = option.getData('status');
            const optionName = R.trim(option.getData('name')) || '';
            const optionUid = option.getData('optionUid');
            return {
              variant: variantType,
              variantIndex,
              name: optionName,
              code: option.getData('code'),
              optionId: `${variantType}:${optionName}`,
              optionUid,
              status
            };
          }

          return false;
        }), R.reject(R.equals(false)))(options);
        return options;
      })(variants);
      const combinations = R.pipe(R.reduce((acc, optionsData) => {
        if (R.isEmpty(acc)) {
          return R.map(optionData => [optionData])(optionsData);
        }

        if (R.isEmpty(optionsData)) {
          return acc;
        }

        return R.xprod(acc)(optionsData);
      }, []), R.map(R.flatten), _nventor.default.mapIndexed((optionData, index) => {
        const variantChildId = R.pipe(R.pluck('optionId'), _nventor.default.sortTextAscending, R.join(':'))(optionData); // the new prefered way to create variant child ids

        const variantChildUid = R.pipe(R.pluck('optionUid'), _nventor.default.sortTextAscending, R.join(':'))(optionData);
        const statuses = R.pluck('status')(optionData);
        let status = this.get('constants.status.active');

        if (R.includes(this.get('constants.status.inactive'), statuses)) {
          status = this.get('constants.status.inactive');
        }

        let name = R.pipe(R.sortWith([R.ascend(R.prop('variantIndex'))]), R.pluck('name'), R.reject(_nventor.default.isNilOrEmpty), R.join('-'))(optionData);

        if (name && productName) {
          name = `${productName}-${name}`;
        } else {
          name = '';
        }

        let code = R.pipe(R.sortWith([R.ascend(R.prop('variantIndex'))]), R.pluck('code'), R.reject(_nventor.default.isNilOrEmpty), R.join('-'))(optionData);

        if (code && productCode) {
          code = `${productCode}-${code}`;
        } else {
          code = '';
        }

        let key = '';
        let salesExclPrice = this.getData('salesExclPrice');
        let salesInclPrice = this.getData('salesInclPrice');
        let purchasesExclPrice = this.getData('purchasesExclPrice');
        let purchasesInclPrice = this.getData('purchasesInclPrice');

        if (!_nventor.default.isNilOrEmpty(originalVariantChildren)) {
          // first try match by variant uid
          let originalVariantChild = R.find(R.pathEq(['_data', 'variantChildUid'], variantChildUid))(originalVariantChildren); // second try match by option choices (old way: legacy purposes only)

          if (!originalVariantChild) {
            originalVariantChild = R.find(R.pathEq(['_data', 'variantChildId'], variantChildId))(originalVariantChildren);
          }

          if (originalVariantChild) {
            salesExclPrice = originalVariantChild.getData('salesExclPrice');
            salesInclPrice = originalVariantChild.getData('salesInclPrice');
            purchasesExclPrice = originalVariantChild.getData('purchasesExclPrice');
            purchasesInclPrice = originalVariantChild.getData('purchasesInclPrice');
            key = originalVariantChild.getData('_key'); // will always use original name & code

            code = originalVariantChild.getData('code');
            name = originalVariantChild.getData('name');
          }
        }

        const variantChildData = {
          _key: key,
          variantChildIndex: index + 1,
          variantChildId,
          variantChildUid,
          code,
          name,
          salesExclPrice,
          salesInclPrice,
          purchasesExclPrice,
          purchasesInclPrice,
          status
        };
        return this.addVariantChild(variantChildData, existingVariantChildren);
      }))(allOptions);
      this.removeUnusedVariantChildren(combinations);
    },

    removeUnusedVariantChildren(newChildren) {
      this.setData('_createVariantChildren', newChildren);
    },

    addChoice(currentChoice, data) {
      const choices = this.getData('choices') || [];
      const lastChoice = choices.get('lastObject');
      const lastIndex = R.path(['_data', 'index'], lastChoice);
      let index = 1;

      if (lastIndex) {
        index = parseInt(lastIndex) + 1;
      }

      const defaultData = {
        index,
        name: '',
        choicesQty: '',
        status: this.get('constants.status.active'),
        options: []
      };
      data = data || defaultData;
      const choiceModel = this.dataManager.setAsRecord({
        adapterName: this.get('childAdapters.choices'),
        data
      });
      choices.pushObject(choiceModel);
      this.setData('choices', choices);
    },

    removeChoice(choice) {
      const choices = this.get('_data.choices') || [];
      choices.removeObject(choice);
      this.resetIndexes(choices);
      return choice;
    },

    addFaq(faq) {
      this.getData('faqs').pushObject(faq);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "addNewTemplate", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewTemplate"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "removeTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeTemplate"), _obj)), _obj)));

  _exports.default = _default;
});