define("client/initializers/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'productsService', 'service:products');
    application.inject('adapter', 'productsService', 'service:products');
    application.inject('component', 'productsService', 'service:products');
  }

  var _default = {
    name: 'productsService',
    initialize: initialize
  };
  _exports.default = _default;
});