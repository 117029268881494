define("client/pods/components/workflows/items/editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let WorkflowsItemsEditorComponent = (_dec = Ember.inject.service('contacts'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('users'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class WorkflowsItemsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "contactsService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "usersService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor6, this);
      this.crud.addLists(this, ['users/related', 'workflows/lists/tags']);
    }

    get availableStages() {
      var _this$args$workflow, _this$args$workflow$_;

      const stages = ((_this$args$workflow = this.args.workflow) === null || _this$args$workflow === void 0 ? void 0 : (_this$args$workflow$_ = _this$args$workflow._data) === null || _this$args$workflow$_ === void 0 ? void 0 : _this$args$workflow$_.stages) || [];
      return R.reject(R.pathEq(['_data', 'stageId'], 'unassigned'))(stages);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkflowsItemsEditorComponent;
});