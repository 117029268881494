define("client/pods/specs/child/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      const hasLabel = data.label;
      const hasDetail = data.isDetail;

      if (!hasLabel) {
        let label = data.value;

        if (data.translate !== false) {
          label = this.intl.t(data.value);
        }

        data.label = label;
      }

      data.transforms = R.pipe(R.propOr([], 'transforms'), R.map(transformObject => {
        return this.dataManager.setAsRecord({
          adapterName: 'specs/child/transforms',
          data: transformObject
        });
      }))(data);
      attrs.hasOptions = RA.isNotNilOrEmpty(data.transforms);

      this._super(...arguments);
    },

    addTransform(data = {}) {
      const transforms = this.get('_data.transforms') || [];
      const transformModel = this.dataManager.setAsRecord({
        adapterName: 'specs/child/transforms',
        data
      });
      transforms.pushObject(transformModel);
      this.set('_data.transforms', transforms);
      return transformModel;
    },

    setDefaultTransform(specChild, data) {
      const availableOptions = specChild.get('availableOptions');
      let allIsTrue = false;
      R.forEach(allowedTransforms => {
        if (allowedTransforms === 'all') {
          allIsTrue = true;
        }
      })(data.get('allowedTransforms') || []);
      let selectedTransforms = [];

      if (availableOptions) {
        R.forEach(transformObject => {
          if (transformObject.fn && allIsTrue === false) {
            const updateData = this.dataManager.setAsRecord({
              adapterName: 'specs/child/transforms',
              data: transformObject
            });
            selectedTransforms = R.append(updateData, selectedTransforms);
          }
        })(availableOptions);
      }

      if (data.get('type')) {
        const typeArray = RA.list({
          type: data.get('type')
        });
        selectedTransforms = R.map(transformObject => {
          return this.dataManager.setAsRecord({
            adapterName: 'specs/child/transforms',
            data: transformObject
          });
        })(typeArray);
      }

      this.set('_data.transforms', selectedTransforms);

      if (RA.isNotNilOrEmpty(selectedTransforms)) {
        specChild.set('hasOptions', true);
        specChild.setData('hideDefault', true);
      }
    },

    availableOptions: Ember.computed('_data.{type,value}', 'availableNormalSpecs', function () {
      let propForConcat = this.get('_data.prop') || [];

      if (R.is(String, propForConcat)) {
        propForConcat = RA.list(propForConcat);
      }

      const allTransforms = [Ember.Object.create({
        label: 'auto index',
        fn: 'index'
      }), Ember.Object.create({
        label: 'combine labels',
        fn: 'concat',
        options: {
          joinWith: ' / ',
          prop: propForConcat
        }
      })];
      const availableNormalSpecs = this.get('availableNormalSpecs') || [];
      const selectedValue = this.getData('value');
      const normalSpec = R.find(R.propEq('value', selectedValue))(availableNormalSpecs);

      if (!normalSpec) {
        return [];
      }

      const allowedNames = normalSpec.get('allowedTransforms') || [];
      const disallowedNames = normalSpec.get('disallowedTransforms') || [];
      const availableOptions = R.filter(transforms => {
        const transformsName = transforms.fn;

        if (R.includes(transformsName, disallowedNames)) {
          return false;
        }

        if (R.includes('all', allowedNames)) {
          return true;
        }

        return R.includes(transformsName, allowedNames);
      })(allTransforms);
      const hasType = normalSpec.type;

      if (hasType === 'number') {
        availableOptions.pushObject(Ember.Object.create({
          label: 'convert to number',
          value: 'number'
        }));
      }

      if (hasType === 'date') {
        availableOptions.pushObject(Ember.Object.create({
          label: 'convert to date',
          value: 'date'
        }));
      } // if has data type...add data type options


      return availableOptions;
    })
  });

  _exports.default = _default;
});