define("client/pods/uploads/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    getUrl(transforms = '') {
      const cdnBase = this.uploadcare.get('cdnBase');
      const uuid = this.get('_data.uuid');
      return `${cdnBase}${uuid}${transforms}/`;
    }

  });

  _exports.default = _default;
});