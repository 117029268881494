define("client/pods/application/lists/main/adapter", ["exports", "ramda", "ramda-adjunct", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, R, RA, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    settings: Ember.inject.service(),
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false // async findAll (data = {}, appendPath) {
    //   let results = await this._super(...arguments)
    //   const eSun = this.settings.getProp('tw_payments_eSun', {})
    //   const ubot = this.settings.getProp('tw_payments_ubot', {})
    //   const eSunVirtualAccounts = R.propOr([], 'virtualAccounts')(eSun)
    //   const ubotVirtualAccounts = R.propOr([], 'virtualAccounts')(ubot)
    //   const enabledProviders = R.pipe(
    //     R.concat(ubotVirtualAccounts),
    //     R.filter(R.propEq('enabled', true)),
    //     R.pluck('provider')
    //   )(eSunVirtualAccounts)
    //   if (RA.isNilOrEmpty(enabledProviders)) {
    //     results = R.map(result => {
    //       if (result.label === 'payments') {
    //         result.sub = R.pipe(
    //           R.propOr([], 'sub'),
    //           R.reject(R.propEq('label', 'virtual account'))
    //         )(result)
    //       }
    //       return result
    //     })(results)
    //   }
    //   return results
    // }

  });

  _exports.default = _default;
});