define("client/pods/components/products/table-view/detail-row/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _applyDecoratedDescriptor2, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    shortcuts: Ember.inject.service(),
    tagName: '',
    showUploadsRow: false,
    hasShortcuts: true,

    init() {
      this._super(...arguments);

      this.set('shortcutsMap', [{
        keys: [['Meta', 'Enter'], ['Control', 'Enter']],
        handler: this.quickSaveTask
      }, {
        keys: ['Alt', 'Enter'],
        handler: this.showWizardModal
      }]);
    },

    isReadonly: Ember.computed('isEditing', 'isNew', function () {
      if (this.isEditing || this.isNew) {
        return false;
      }

      return true;
    }),
    isFiltersReadonly: Ember.computed('isEditing', 'isNew', function () {
      if (this.isEditing || this.isNew) {
        return true;
      }

      return false;
    }),
    isProductSetsReadonly: Ember.computed('isNew', 'isEditing', 'isReadonly', function () {
      if (this.isNew) {
        return false;
      }

      let isProductSetsReadonly = false; // option cannot be edited after saving

      if (this.isEditing) {
        isProductSetsReadonly = true;
      }

      if (!isProductSetsReadonly) {
        isProductSetsReadonly = this.isReadonly;
      }

      return isProductSetsReadonly;
    }),
    quickSaveTask: (0, _emberConcurrency.task)(function* () {
      var _this$tableViewOption;

      return yield this.crud.createRecordTask.perform({
        adapterName: 'products',
        model: this.model,
        onAfter: (_this$tableViewOption = this.tableViewOptions) === null || _this$tableViewOption === void 0 ? void 0 : _this$tableViewOption.onAfterQuickAddNew,
        component: this
      });
    }),

    showWizardModal(currentStep = 0) {
      const model = this.model;
      this.set('currentStep', 0);
      const setType = model.getData('setType');

      if (!setType) {
        return this.showStepTemplate();
      } // if (this.currentStep === 0) {


      return this.showStepSet(); // }
      // return this.showStepTemplate()
    },

    showStepSet() {
      this.set('currentStep', 0);
      const model = this.model;
      const setType = model.getData('setType');
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], setType))(panels);

      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },

    showStepTemplate() {
      this.set('currentStep', 1);
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], 'webpages'))(panels);

      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },

    actions: {
      clickRow() {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },

      /**
       * sales tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      salesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const purchasesTaxKey = model.getData('purchasesTaxRateKey'); // manually set tax rate

        model.setData('salesTaxRate', taxRate);

        if (purchasesTaxKey == null || purchasesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('purchasesTaxRateLabel', taxModel.getData('label'));
          model.setData('purchasesTaxRateKey', taxModel.getData('_key')); // model.setData('purchasesTaxRate', taxRate);
        }
      },

      /**
       * purchases tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      purchasesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const salesTaxKey = model.getData('salesTaxRateKey'); // manually set tax rate

        model.setData('purchasesTaxRate', taxRate);

        if (salesTaxKey == null || salesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('salesTaxRateLabel', taxModel.getData('label'));
          model.setData('salesTaxRateKey', taxModel.getData('_key')); // model.setData('salesTaxRate', taxRate);
        }
      },

      setSetType(setType) {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
        const selectedSetType = setType.getData('value');
        model.setData(selectedSetType, true); // this.setEditorSubPanels()
      },

      deselectSetType() {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
      },

      updateVariantChildrenList(originalModel) {
        const model = this.model;
        return model.updateVariantChildrenList(originalModel);
      },

      showWizardModal() {
        this.showWizardModal();
      },

      closeWizardModal() {
        this.set('isSetTypeModalVisible', false);
      }

    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "showWizardModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "showWizardModal"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "showStepSet", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showStepSet"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "showStepTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showStepTemplate"), _obj)), _obj)));

  _exports.default = _default;
});