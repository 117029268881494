define("client/pods/components/helpers/drag-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwVqOtHq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"draggable\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"hoverIcon\"],[\"fas fa-grip-lines\",\"fas fa-arrows-alt\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/drag-container/template.hbs"
    }
  });

  _exports.default = _default;
});