define("client/pods/components/channels/shop/items/search-bar/component", ["exports", "ember-concurrency", "ramda", "client/mixins/crud", "client/mixins/products-list-by"], function (_exports, _emberConcurrency, R, _crud, _productsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _productsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    query: '',
    filters: null,
    autofocus: true,
    adapterName: 'channels/shop/campaigns/details',
    filtersAdapterName: 'channels/shop/campaigns/details/filters',
    searchMethod: 'searchInputTask',
    searchOptions: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['products/lists/filters/search', 'products/lists/filters/sort', 'products/lists/filters/status', 'products/lists/types', 'filters/count', 'filters/sortDirection']);
      const shopKey = this.shopKey;
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          master: shopKey,
          sort: [{
            by: 'index',
            direction: 'asc'
          }]
        }
      });
      this.set('filters', filters);
      this.set('searchOptions', {
        adapterName: this.adapterName,
        appendPath: `/master/${shopKey}`
      });
      this.set('appendPath', `/master/${shopKey}`);
      const defaultTableViewOptions = {
        tab: this.tab,
        onMainActionIcon: 'fas fa-check-circle',
        onMainActionLabel: this.select,
        adapterName: this.adapterName,
        campaign: this.model,
        campaignDetails: this.results
      };
      const tableViewOptions = this.tableViewOptions || {};
      this.tableViewOptions = R.mergeRight(defaultTableViewOptions, tableViewOptions);
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchDefaultDataTask.perform();
    },

    fetchDefaultDataTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.listByTask.perform({
        adapterName: this.adapterName,
        appendPath: this.appendPath,
        filters: this.filters,
        onAfter: this.onAfterSearch
      });
      this.set('results', results);
    }),
    searchTask: (0, _emberConcurrency.task)(function* (customSearchOptions, defaultSearchOptions, ...rest) {
      const searchMethod = this.searchMethod;
      const searchOptions = R.mergeRight(defaultSearchOptions, customSearchOptions);
      const results = yield this.get(searchMethod).perform(searchOptions, ...rest);
      this.set('results', results);
    })
  });

  _exports.default = _default;
});