define("client/mixins/draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    attributeBindings: ['draggable'],
    classNameBindings: ['draggable:draggable', 'isDragging:is-dragging'],
    draggable: true,
    isDragging: false,

    dragStart(event) {
      if (this.draggable) {
        this.set('isDragging', true);
        let data = this.data; // if (nventor.confirm.isObject(data)) {

        data = JSON.stringify(this.data); // }

        event.dataTransfer.setData('text/data', data);

        if (this.onDragStart) {
          // @note this function may contain additional params that are passed to onDragStart
          // based on if fn was curried
          this.onDragStart(data);
        }
      }
    },

    dragEnd() {
      this.set('isDragging', false);
    },

    mouseOut() {
      if (this.isDragging) {
        this.set('isDragging', false);
      }
    }

  });

  _exports.default = _default;
});