define("client/pods/grapesjs/plugins/slider", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const options = R.pathOr({}, ['Config', 'pluginsOpts', 'bigordr-slider'])(editor);
    const {
      onDbClick,
      onDrop,
      intl,
      isReadOnly,

      /** @type {Categories} */
      categories
    } = options;
    const imagePlaceholder = {
      src: 'https://via.placeholder.com/2048x1024?text=Image',
      url: 'https://via.placeholder.com',
      ratio: '2048x1024',
      text: 'Image'
    };
    const {
      DomComponents,
      BlockManager
    } = editor;

    const updateImageRatio = el => {
      const images = el.children;
      R.forEach(image => {
        const pixelRatio = image.getAttribute('data-gjs-model-pixelratio');
        const src = image.getAttribute('src');

        if (src) {
          const scaled = src.includes('scale_crop');

          if (pixelRatio && !scaled) {
            const cropSrc = `${src}-/scale_crop/${pixelRatio}/center/`;
            image.setAttribute('src', cropSrc);
          }
        }
      })(images);
    };

    DomComponents.addType('bigordr-slider-image', {
      model: {
        defaults: {
          tagName: 'img',
          classes: ['full-width-image'],
          attributes: {
            'data-gjs-component': 'bigordr-slider-image',
            alt: 'none'
          },
          style: {
            width: '100%'
          },
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: false,
          highlightable: false,
          hoverable: false,
          layerable: false,
          removable: false,
          resizable: false,
          selectable: false,
          stylable: false,
          traits: []
        }
      }
    });
    DomComponents.addType('bigordr-slider', {
      model: {
        defaults: {
          defaultRatio: '2048x1024',
          defaultPlaceholder: imagePlaceholder,
          tagName: 'div',
          draggable: '.bigordr-cell, [data-gjs-type="wrapper"]',
          droppable: false,
          attributes: {
            'data-gjs-component': 'bigordr-slider',
            alt: 'none'
          },
          toolbar: [{
            attributes: {
              class: 'fas fa-pencil-alt'
            },

            command(editor) {
              const el = editor.getSelected().getEl();

              if (onDbClick) {
                onDbClick(el);
              }
            }

          }, {
            attributes: {
              class: 'fa fa-arrow-up'
            },
            command: 'select-parent'
          }, {
            attributes: {
              class: 'fa fa-arrows'
            },
            command: 'tlb-move'
          }, {
            attributes: {
              class: 'fa fa-clone'
            },
            command: 'tlb-clone'
          }, {
            attributes: {
              class: 'fa fa-trash'
            },
            command: 'tlb-delete'
          }],
          components: [{
            type: 'bigordr-slider-image',
            attributes: {
              defaultPlaceholder: true,
              src: imagePlaceholder.src
            },
            traits: []
          }],
          traits: []
        },

        updated() {
          this.removeDefaultPlaceholders();
          updateImageRatio(this.getEl());
        },

        removeDefaultPlaceholders() {
          const hasMoreComp = this.components().length > 1;

          if (hasMoreComp) {
            const components = this.components().models;
            R.pipe(R.filter(R.pathEq(['attributes', 'attributes', 'defaultPlaceholder'], true)), R.map(c => c.remove()))(components);
          }
        }

      },
      view: {
        // Add easily component specific listeners with `events`
        // Being component specific (eg. you can't attach here listeners to window)
        // you don't need to care about removing them when the component is removed,
        // they will be managed automatically by the editor
        events: {
          dblclick: 'onDbClick'
        },

        onDbClick(event) {
          if (onDbClick && !isReadOnly) {
            const element = event.target.parentElement;
            onDbClick(element);
          }
        },

        removeDefaultPlaceholders(model) {
          const hasMoreComp = model.components().length > 1;

          if (hasMoreComp) {
            const components = model.components().models;
            R.pipe(R.filter(R.pathEq(['attributes', 'attributes', 'defaultPlaceholder'], true)), R.map(c => c.remove()))(components);
          }
        },

        updateSliderImagesDisplay(el) {
          R.pipe(R.pathOr([], ['children']), R.map(img => {
            img.classList.remove('full-width-image--display-none');
            return img;
          }), R.tail, R.forEach(img => img.classList.add('full-width-image--display-none')))(el);
        },

        onRender({
          el
        }) {
          this.updateSliderImagesDisplay(el);
          this.removeDefaultPlaceholders(this.model);
          updateImageRatio(el);
        }

      }
    });
    BlockManager.add('bigordr-slider-block', {
      label: intl.t('slider'),
      category: categories.basic,
      content: {
        type: 'bigordr-slider'
      },
      render: () => `
      <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;">
        <g transform="matrix(1,0,0,1,-6.60663,21.9871)">
            <g transform="matrix(1.00794,-6.84695e-39,4.65812e-39,0.792537,158.108,-76.7203)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.00794,0,1.30506e-54,0.792537,83.8538,-2.46648)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.00794,-6.84695e-39,4.65812e-39,0.792537,17.5874,81.7874)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.08069,-5.87747e-39,5.87747e-39,1.08069,-43.4658,6.77548)">
                <g transform="matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)">
                    <circle cx="567.803" cy="388.754" r="27.858" style="stroke:black;stroke-width:18.25px;stroke-linecap:round;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)">
                    <g transform="matrix(-0.93623,0,0,0.939727,1014.78,130.716)">
                        <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z"/>
                    </g>
                    <g transform="matrix(-1.01027,0,0,0.756475,916.709,228.489)">
                        <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z"/>
                    </g>
                </g>
            </g>
        </g>
        <g transform="matrix(1,0,0,1,0.251863,-73.6856)">
            <g transform="matrix(1.43016,5.71454e-23,-5.71454e-23,1.43016,-956.422,144.288)">
                <g transform="matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)">
                    <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:22.38px;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(-0.794376,-9.7283e-17,9.7283e-17,-0.794376,1440.42,575.983)">
                    <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                    <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                </g>
            </g>
            <g transform="matrix(-1.43016,-1.75144e-16,1.75144e-16,-1.43016,1979.92,1536.01)">
                <g transform="matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)">
                    <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:22.38px;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(-0.794376,-9.7283e-17,9.7283e-17,-0.794376,1440.42,575.983)">
                    <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                    <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    `
    });
    editor.on('block:drag:stop', component => {
      if (component && component.is('bigordr-slider')) {
        editor.select(component);

        if (onDrop) {
          onDrop(component.getEl());
        }
      }
    });
  };

  _exports.default = _default;
});