define("client/pods/uploadcare/service", ["exports", "ramda", "client/config/environment"], function (_exports, R, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals uploadcare */
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  var _default = Ember.Service.extend({
    server: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const uploadcareConfig = _environment.default.uploadcare;
      const environment = _environment.default.environment;
      this.set('publicKey', uploadcareConfig[environment].public.publicKey);
      this.set('cdnBase', uploadcareConfig[environment].public.cdnBase);
      const locale = this.intl.get('locale')[0];
      this.setup(locale);
    },

    removeStartingAndEndingSlashes(segment) {
      return R.pipe(R.when(R.startsWith('/'), R.drop(1)), R.when(R.endsWith('/'), R.dropLast(1)))(segment);
    },

    /**
     * generate url with transforms
     *
     * @param {string} uuid - file uuid
     * @param {string} transforms - eg. '/-/resize/200x300'
     * @returns
     */
    getFileUrl(uuid, transforms = '') {
      const cdnBase = this.removeStartingAndEndingSlashes(this.cdnBase);
      let url = `${cdnBase}/${uuid}/`;

      if (transforms) {
        url = this.removeStartingAndEndingSlashes(url);
        transforms = this.removeStartingAndEndingSlashes(transforms);
        url = `${url}/${transforms}/`;
      }

      return url;
    },

    getUc() {
      return uploadcare;
    },

    setup(locale, options) {
      const localesMap = {
        'zh-tw': 'zhTW'
      };
      locale = localesMap[locale] || 'en';
      options = options || {};
      options.publicKey = this.publicKey;
      options.locale = locale;
      options.cdnBase = this.cdnBase;
      this.getUc().start(options);
    },

    getSecureToken() {
      // fetch secure token
      const self = this;
      return self.get('server').call('GET', 'api/protected/uploads/uploadcare/secure').then(function (response) {
        return response.data;
      });
    },

    uploadFile(file, settings) {
      const self = this;
      settings = settings || {};
      return self.getSecureToken().then(function (tokenData) {
        settings.secureSignature = tokenData.secureSignature;
        settings.secureExpire = tokenData.secureExpire;
        const upload = self.getUc().fileFrom('object', file, settings);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          upload.done(function (fileInfo) {
            resolve(fileInfo); // Upload has successfully completed and a file is ready.
          }).fail(function (error
          /* , fileInfo */
          ) {
            // console.log('---err---')
            // console.log(error)
            // console.log(fileInfo)
            window.alert(self.get('intl').t('error occurred'));
            reject(error); // reject(error)
            // Upload failed, or something else went wrong, a file is not ready.
          });
        });
      });
    },

    updateFileToServer(resource, resourceKey, info) {
      // @TODO: handle single uploads
      const self = this;
      const path = 'api/protected/uploads/uploadcare/file/store';
      info.resource = resource;
      info.resourceKey = resourceKey;
      return self.get('server').call('POST', path, info);
    },

    updateFileGroupToServer(resource, resourceKey, info) {
      // @TODO: handle single uploads
      const self = this;
      const path = 'api/protected/uploads/uploadcare/groups/store';
      info.resource = resource;
      info.resourceKey = resourceKey;
      return self.get('server').call('POST', path, info);
    },

    containsUuid: R.curry((src, image) => {
      const contains = R.includes(R.path(['_data', 'uuid'], image), src);

      if (contains) {
        return contains;
      }

      return R.includes(R.path(['uuid'], image), src);
    })
  });

  _exports.default = _default;
});