define("client/pods/components/dispatch/processes-editor/component", ["exports", "ramda", "client/mixins/crud"], function (_exports, R, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    shopsList: '',

    init() {
      this.crud.addLists(this, ['paymentMethods']);

      this._super(...arguments);

      const isEditing = this.isEditing;
      const model = this.model;

      if (isEditing) {
        const dirty = this.crud.setupDirty({
          adapterName: 'dispatch/lists/processes',
          model
        });
        this.set('model', dirty);
      } else {
        let data = {};

        if (model) {
          data = model.serialize();
        }

        const newModel = this.crud.setupNewRecord({
          adapterName: 'dispatch/lists/processes'
        });
        newModel.populate(data);
        this.set('model', newModel);

        if (!newModel.getData('isSystemProcess')) {
          const list = this.list;
          const onlyCustomSteps = R.filter(R.hasPath(['_data', '_key']))(list) || [];
          const lastStep = onlyCustomSteps.get('lastObject');
          let nextIndex = 0;

          if (lastStep) {
            var _lastStep$_data;

            nextIndex = (lastStep === null || lastStep === void 0 ? void 0 : (_lastStep$_data = lastStep._data) === null || _lastStep$_data === void 0 ? void 0 : _lastStep$_data.index) + 1;
          }

          newModel.setData('index', nextIndex);
        }
      }
    },

    afterCreateRecordTask() {
      this.onCloseDialogAction();
    },

    afterReplaceRecordTask() {
      this.onCloseDialogAction();
    },

    afterRemoveRecordTask() {
      this.onCloseDialogAction();
    },

    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
        }

        this.onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});