define("client/pods/components/channels/shop/rewards/search-results/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let ChannelsShopRewardsSearchResultsComponent = (_dec = Ember.inject.service, (_class = (_temp = class ChannelsShopRewardsSearchResultsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _defineProperty2.default)(this, "tagName", 'div');
      this.crud.addLists(this, ['statuses']);
    }
    /**
     * Add class for active/inactive status tags
     * @returns {String} className
     */


    get statusTagClassName() {
      const rewardStatus = this.args.result._data.status;

      if (rewardStatus === this.constants.status.active) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopRewardsSearchResultsComponent;
});