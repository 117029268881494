define("client/helpers/padStart", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params = [], hash = {}) {
      let str = R.pathOr('', [0])(params);
      const length = R.pathOr('', [1])(params);
      const char = R.pathOr({}, [2])(params);

      try {
        if (!R.is(String, str)) {
          str = str.toString();
        }
      } catch (e) {
        str = '';
      }

      return str.padStart(length, char);
    }

  });

  _exports.default = _default;
});