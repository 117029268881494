define("client/pods/components/channels/shop/campaigns/campaigns-editor/products-limits-editor/component", ["exports", "ramda", "ember-concurrency", "client/mixins/sortable-actions"], function (_exports, R, _emberConcurrency, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableActions.default, {
    crud: Ember.inject.service(),
    modelProp: 'model',
    indexProp: '_data.index',
    adapterName: 'channels/shop/campaigns/details/limits',
    filtersAdapterName: 'channels/shop/campaigns/details/limits/filters',
    // init () {
    //   this._super(...arguments)
    //   const model = this.model
    //   const master = model.getData('_key')
    //   const filters = this.search.setupFilters({
    //     adapterName: this.filtersAdapterName,
    //     defaultFiltersData: {
    //       master
    //     }
    //   })
    //   this.set('filters', filters)
    // },
    // didInsertElement () {
    //   this._super(...arguments)
    //   this.fetchCampaignDetailsLimitsTask.perform()
    // },
    // fetchCampaignDetailsLimitsTask: task(function * () {
    //   const model = this.model
    //   const master = model.getData('_key')
    //   let details = []
    //   if (master) {
    //     model.set('detailsLimitsUpdated', false)
    //     details = yield this.searchRecordsTask.perform({
    //       adapterName: this.adapterName,
    //       appendPath: `/master/${master}`,
    //       filters: this.filters
    //     })
    //     details = R.map(detail => {
    //       const dirty = this.setupDirty({
    //         adapterName: 'channels/shop/campaigns/details/limits',
    //         model: detail
    //       })
    //     })(details)
    //   }
    //   model.set('detailsLimits', details)
    // }),
    actions: {
      resetDetailsLimitsUpdated() {
        this.model.set('detailsLimitsUpdated', false);
      }

    }
  });

  _exports.default = _default;
});