define("client/pods/components/elements/element-checkbox/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ramda", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  // import TranslateMixin from '../../../../mixins/translate'
  // import jQuery from 'jquery'
  let ElementsElementCheckboxComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class ElementsElementCheckboxComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "_isSelected", _descriptor2, this);
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get allowToggle() {
      return R.pathOr(true, ['args', 'allowToggle'])(this);
    }

    get isSelected() {
      return R.pathOr(this._isSelected, ['args', 'value'])(this);
    } // didInsertElement () {
    //   this._super(...arguments)
    //   const initialWidth = jQuery(this.element).outerWidth()
    //   const spans = jQuery(this.element).children('span')
    //   let totalWidth = 0
    //   spans.each((key, element) => {
    //     element = jQuery(element)
    //     totalWidth = totalWidth + element.outerWidth()
    //   })
    //   totalWidth = Math.ceil(totalWidth + 10)
    //   if (initialWidth < totalWidth) {
    //     jQuery(this.element).outerWidth(totalWidth)
    //   }
    // },


    toggle() {
      if (!this.args.disabled && this.allowToggle) {
        const customToggler = this.args.onToggle;
        let isSelected = !this.isSelected;
        this._isSelected = isSelected;

        if (this.args.onToggleProperty) {
          this.args.onToggleProperty(isSelected);
        }

        if (customToggler) {
          return customToggler(isSelected);
        }

        const onAfterToggle = this.args.onAfterToggle;

        if (onAfterToggle) {
          return onAfterToggle(isSelected);
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_isSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = ElementsElementCheckboxComponent;
});