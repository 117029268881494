define("client/pods/components/channels/shop/rewards/search-filters/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let ChannelsShopRewardsSearchFiltersComponent = (_dec = Ember.inject.service, (_class = (_temp = class ChannelsShopRewardsSearchFiltersComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _defineProperty2.default)(this, "tagName", '');
      this.crud.addLists(this, ['channels/shop/rewards/lists/filters/search', 'channels/shop/rewards/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopRewardsSearchFiltersComponent;
});