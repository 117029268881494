define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, R_, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ChannelsShopShippingZonesShippingZonesEditorDetailsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "transporter", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "contacts", _descriptor4, this);
    }

    get allowedProductTagLists() {
      const model = this.args.model;
      const allProductTags = this.crud.lists['products-lists-tags'];
      const deniedProductTags = model.getData('denyProductTags') || [];
      return R.reject(productTag => {
        const productTagKey = productTag.getData('_key') || '';
        return R.includes(productTagKey, deniedProductTags);
      })(allProductTags);
    }

    get deniedProductTagLists() {
      const model = this.args.model;
      const allProductTags = this.crud.lists['products-lists-tags'];
      const allowedProductTags = model.getData('productTags') || [];
      return R.reject(productTag => {
        const productTagKey = productTag.getData('_key') || '';
        return R.includes(productTagKey, allowedProductTags);
      })(allProductTags);
    }

    get zonePaymentMethodsDefaultsList() {
      const paymentMethods = R_.dotPathOr([], 'crud.lists.paymentMethods-shop')(this);
      const allowedPaymentMethods = this.args.model.settings.getProp('shopPaymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }

    *setupTask() {
      const model = this.args.model;
      yield this.transporter.fetchTransporterTask.perform({
        model
      });
      const transportersList = this.transporter.transportersList;
      Ember.set(this, 'transportersList', transportersList); // setup rates

      if (!this.args.isReadonly) {
        const rates = model.getData('rates') || [];
        let lastRateHasMax = false;
        const lastRate = R.last(rates) || {};

        if (_ramdaAdjunct.default.isNotNilOrEmpty(lastRate)) {
          const lastMax = parseFloat(lastRate.getData('max')) || 0;

          if (lastMax > 0) {
            lastRateHasMax = true;
          }
        }

        if (rates.length === 0 || lastRateHasMax) {
          model.newRate();
        }
      }
    }

    setEmptyRates(model) {
      model.setData('rates', []);
      model.newRate({
        base: 'price',
        min: 0,
        max: 0,
        rate: 0
      });
    }

    loadTransporterForZone(model, transporter) {
      const key = transporter.getData('_key');
      model.setData('transporterKey', key);
      this.transporter.loadTransporterAccounts(model, transporter);
    }

    unloadTransporterForZone(model) {
      model.setData('transporterKey', ''); // model.setData('transporterExtension', '')

      model.setData('transporterAccountNumber', '');
      model.setData('transporterAccount', '');
    }

    setTransporterAccountLabel(model, account) {
      model.setData('transporterAccount', account.getData('name'));
    }

    setZonePaymentMethodsDefault(model) {
      const zonePaymentMethodsDefaultsList = R.propOr([], 'zonePaymentMethodsDefaultsList')(this);
      const currentDefault = model.getData('shopPaymentMethodsDefault');
      let resetDefault = false;

      if (!R.includes(currentDefault, zonePaymentMethodsDefaultsList)) {
        resetDefault = true;
      }

      if (!currentDefault) {
        resetDefault = true;
      }

      if (resetDefault) {
        const first = R.head(zonePaymentMethodsDefaultsList);

        if (first) {
          model.setData('shopPaymentMethodsDefault', first.getData('value'));
        } else {
          model.setData('shopPaymentMethodsDefault', '');
        }
      }
    }

    newBlankRate(rate) {
      const model = this.args.model;
      model.updateMinRates();
      const max = parseInt(rate.getData('max')) || 0;

      if (max !== 0) {
        model.newBlankRate(rate);
      }
    }

    removeRate(rate) {
      const model = this.args.model;
      model.removeRate(rate);
      model.updateMinRates();
    }

    onSubmitAddSurcharge() {
      const model = this.args.model;
      model.addSurcharge();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transporter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contacts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setEmptyRates", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setEmptyRates"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadTransporterForZone", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporterForZone"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unloadTransporterForZone", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "unloadTransporterForZone"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setTransporterAccountLabel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterAccountLabel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setZonePaymentMethodsDefault", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setZonePaymentMethodsDefault"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newBlankRate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "newBlankRate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeRate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "removeRate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddSurcharge", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddSurcharge"), _class.prototype)), _class));
  _exports.default = ChannelsShopShippingZonesShippingZonesEditorDetailsEditorComponent;
});