define("client/pods/ledgers/journals/cashbook/model", ["exports", "client/pods/base/model", "client/mixins/settings"], function (_exports, _model, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_settings.default, {
    /**
     * calculate payment after discount total
     */
    calculateTotals: Ember.observer('_data.amount', '_data.discount', function () {
      const roundingCurrency = this.roundingCurrency;
      const amountBig = this.getDataBig('amount');
      const discountBig = this.getDataBig('discount');
      const total = amountBig.plus(discountBig).toFixed(roundingCurrency);
      this.setData('total', total);
    }),
    isMinRequiredMet: Ember.computed('_data.total', function () {
      // @TODO: should also check contactKey, date etc..
      const total = this.getData('total');

      if (total > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});