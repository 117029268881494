define("client/pods/components/websites/tab-container/component", ["exports", "client/mixins/crud", "client/pods/components/websites/mixins/websites-list-by", "ember-concurrency", "ramda-extension"], function (_exports, _crud, _websitesListBy, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_websitesListBy.default, _crud.default, {
    crud: Ember.inject.service(),
    results: null,
    showResults: true,
    query: '',
    adapterName: 'websites',
    filtersAdapterName: 'websites/filters',

    init() {
      this.crud.addLists(this, ['websites/lists/menu']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
        this.set('results', results); // this.dash(results)
      }
    }),

    dash(results) {
      const menuActionsList = R_.dotPath('crud.lists.websites-lists-menu')(this) || [];
      const found = menuActionsList.findBy('_data.component', 'websites/panels-dash');

      if (!found) {
        return this.schedule();
      }

      if (!results) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
      }

      const tab = this.tab;
      const panelOptions = {
        component: 'websites/panels-dash'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    display(model, additionalOptions = {}) {
      this.set('showResults', false);
      const panelOptions = {
        component: 'websites/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    },

    new(panelOptions) {
      this.set('resultsToggle', false); // add new contact sub tab

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    actions: {
      dash() {
        this.set('showResults', false); // should deselect results if any..
        // this.deselectResult();

        this.dash();
      },

      schedule() {
        // should deselect results if any..
        // this.deselectResult();
        this.schedule();
      },

      display(model, options) {
        this.set('resultsToggle', false);

        if (model) {
          this.display(model, options);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(panelOptions) {
        this.new(...arguments);
      },

      edit(model, additionalOptions) {
        this.set('resultsToggle', false);
        const tab = this.tab;
        const panelOptions = {
          component: 'websites/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
      }

    }
  });

  _exports.default = _default;
});