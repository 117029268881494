define("client/pods/printer/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let PrinterService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class PrinterService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "printerModels", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedPrinter", _descriptor6, this);
    }

    get hasNoOnlinePrinters() {
      const printerModels = this.printerModels || [];
      return printerModels.length === 0;
    }

    *fetchOnlinePrintersTask() {
      const printerModels = yield this.crud.searchRecordsTask.perform({
        adapterName: 'extensions/apps/tw/e-invoices/printer',
        appendPath: '/online',
        filters: this.search.setupFilters({
          adapterName: 'extensions/apps/tw/e-invoices/printer/filters'
        })
      });
      this.printerModels = printerModels;

      if (printerModels.length >= 1) {
        this.selectedPrinter = R.pathOr('', ['printerModels', 0, '_data', 'printerId'])(this);
      }

      return printerModels;
    }

    *rePrintEInvoicesTask(component, document) {
      const msg = this.intl.t('are you sure you want to reprint');

      if (window.confirm(msg)) {
        const appendPath = `/${document.getData('_key')}`;
        const payload = {
          printerId: this.selectedPrinter
        };
        component.set('issueErrors', []);

        try {
          return yield this.server.getAdapter('extensions/apps/tw/e-invoices').rePrint({
            appendPath,
            payload
          });
        } catch (err) {
          this.crud.setValidationErrors('issueErrors', err, component);
          return false;
        }
      }
    }

    *issueAndPrintTask(component, onCloseDialogAction, document, custom, selectedPrinter) {
      const onlinePrinters = yield this.fetchOnlinePrintersTask.perform();

      if (R.isEmpty(onlinePrinters)) {
        throw new Error('no printers found');
      }

      custom.twEInvoicePrint = true;
      custom.twEInvoicePrinterId = selectedPrinter;
      component.set('issueErrors', []);
      const payload = {
        _key: document.getData('_key'),
        custom
      };

      try {
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
          payload
        });
        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "printerModels", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedPrinter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchOnlinePrintersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOnlinePrintersTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rePrintEInvoicesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "rePrintEInvoicesTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "issueAndPrintTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "issueAndPrintTask"), _class.prototype)), _class));
  _exports.default = PrinterService;
});