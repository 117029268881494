define("client/pods/components/documents/panel-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IF602kBf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onPaginationTask\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"tableViewOptions\"],[[24,[\"tab\"]],\"documents/table-view/doc-row\",[24,[\"results\"]],[24,[\"filters\"]],[24,[\"onSearchTask\"]],[24,[\"onReloadSearchRecordsTask\"]],[24,[\"onPaginationTask\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[24,[\"tableViewOptions\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-dash/template.hbs"
    }
  });

  _exports.default = _default;
});