define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
    }

    onSubmitAddBulkDiscount() {
      this.args.model.addBulkDiscount();
    }

    onSubmitRemoveBulkDiscount(bulkDiscount) {
      const bulkDiscounts = this.args.model._data.bulkDiscounts;
      bulkDiscounts.removeObject(bulkDiscount);
    }

    onSubmitAddRule(bulkDiscount) {
      bulkDiscount.addRule();
    }

    onToggleSetHasSchedule(bulkDiscount) {
      const hasSchedule = bulkDiscount.get('_data.hasSchedule');

      if (!hasSchedule) {
        bulkDiscount.set('_data.dateStartZ', '');
        bulkDiscount.set('_data.dateEndZ', '');
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddBulkDiscount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddBulkDiscount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveBulkDiscount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveBulkDiscount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddRule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetHasSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetHasSchedule"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorComponent;
});