define("client/pods/components/admin/payments/card/panels-checkout-logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DmqXYZyq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"fetchCheckoutIdLogs\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n\\n      \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@lists\",\"@onDisplay\",\"@onEdit\",\"@onReloadSearchRecordsTask\",\"@onSearchTask\",\"@results\",\"@onPaginationTask\"],[\"admin/payments/card/panels-checkout-logs/table-view/detail-row\",[23,0,[\"filters\"]],[23,0,[\"crud\",\"lists\"]],[22,\"onDisplay\"],[22,\"onEdit\"],[23,0,[\"fetchBlogsTask\"]],[23,0,[\"fetchBlogsTask\"]],[23,0,[\"filters\",\"results\"]],[22,\"onPaginationTask\"]]]],[0,\"\\n\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/panels-checkout-logs/template.hbs"
    }
  });

  _exports.default = _default;
});