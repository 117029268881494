define("client/pods/components/documents/editor-main/editor-btns-editing/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    documents: Ember.inject.service(),
    tagName: '',
    hasReturns: true,

    init() {
      this._super(...arguments);

      const docType = this.docType;
      const docName = this.docName;

      if (docType === 'sales' && docName === 'invoices') {
        this.setup.perform();
      } else {
        this.set('hasReturns', false);
      }

      const originalInclTotal = R.pathOr('', ['model', '_data', 'inclTotal'])(this);
      this.set('originalInclTotal', originalInclTotal);
    },

    setup: (0, _emberConcurrency.task)(function* () {
      const quickLinkFromDocType = R_.dotPathOr('sales', 'args.quickLinkFromDocType')(this);
      const quickLinkFromDocName = R_.dotPathOr('invoices', 'args.quickLinkFromDocName')(this);
      const quickLinkToDocType = R_.dotPathOr('sales', 'args.quickLinkToDocType')(this);
      const quickLinkToDocName = R_.dotPathOr('returns', 'args.quickLinkToDocName')(this);
      const model = this.model;
      const returnsByDetailKey = yield this.documents.hasReturns.perform(model, {
        docType: quickLinkFromDocType,
        docName: quickLinkFromDocName,
        toDocType: quickLinkToDocType,
        toDocName: quickLinkToDocName
      });
      this.set('hasReturns', RA.isNotNilOrEmpty(returnsByDetailKey));
    }),
    isOriginalPriceEqualNewPrice: Ember.computed('model.{isTaxExclusive,isSalesInvioces}', 'originalInclTotal', 'model._data.{inclTotal,detailsLocked}', function () {
      const detailsLocked = this.get('model._data.detailsLocked') || false;
      const isSalesInvioces = this.get('model.isSalesInvioces');

      if (detailsLocked && isSalesInvioces) {
        const newInclTotal = this.get('model._data.inclTotal') || '';
        const originalInclTotal = this.get('originalInclTotal') || '';

        if (R.equals(`${originalInclTotal}`, `${newInclTotal}`)) {
          return true;
        }

        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});