define("client/pods/components/ledgers/journals/cashbook/cashbook-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WApNEHbZ",
    "block": "{\"symbols\":[\"@tab\",\"@accountKey\"],\"statements\":[[7,\"span\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"setupTask\"]]],null]]],[8],[0,\"\\n\\t\"],[5,\"forms/form-section\",[],[[\"@title\"],[[23,1,[\"label\"]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@detailsRowComponent\",\"@filters\",\"@results\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@paginationIsFooter\",\"@isRunning\",\"@tableViewOptions\"],[[23,1,[]],\"ledgers/journals/cashbook/table-view/detail-row\",[23,0,[\"txFilters\"]],[23,0,[\"txFilters\",\"results\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],false,[28,\"or\",[[23,0,[\"fetchAccountTask\",\"isRunning\"]]],null],[28,\"hash\",null,[[\"tab\",\"accountKey\",\"account\",\"onFetchStatementTask\",\"onCreateModelTask\",\"onFetchAccountTask\",\"onReloadAccountTask\"],[[23,1,[]],[23,0,[\"accountKey\"]],[23,0,[\"account\"]],[28,\"perform\",[[23,0,[\"fetchStatementTask\"]]],null],[28,\"perform\",[[23,0,[\"createModelTask\"]]],null],[28,\"perform\",[[23,0,[\"fetchAccountTask\"]]],null],[28,\"perform\",[[23,0,[\"fetchAccountTask\"]],[28,\"hash\",null,[[\"accountKey\",\"txType\",\"txFilters\"],[[23,2,[]],[23,1,[\"txType\"]],[23,0,[\"txFilters\"]]]]]],null]]]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/journals/cashbook/cashbook-editor/template.hbs"
    }
  });

  _exports.default = _default;
});