define("client/pods/components/websites/panels-display/sub-panels-footer-btns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E1TdiEix",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"msgs/footer-msgs\",null,[[\"model\",\"hasErrors\",\"errors\"],[[24,[\"model\"]],[24,[\"hasErrors\"]],[24,[\"errors\"]]]]],false],[0,\"\\n\\n\"],[4,\"layouts/footer-btns\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"elements/btn-back\",null,[[\"tooltipLabel\",\"onSubmit\"],[\"overview\",[24,[\"onDash\"]]]]],false],[0,\"\\n\\n\\t\\t\\t\"],[1,[22,\"layouts/btns-seperator\"],false],[0,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[1,[28,\"comments/comments-manager\",null,[[\"tab\",\"type\",\"isPreview\",\"master\",\"metaData\"],[[24,[\"tab\"]],\"websites\",true,[24,[\"model\",\"_data\",\"_key\"]],[28,\"hash\",null,[[\"campaign\",\"campaignKey\"],[[24,[\"model\",\"_data\",\"name\"]],[24,[\"model\",\"_data\",\"_key\"]]]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasEditBtn\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"layouts/btns-seperator\"],false],[0,\"\\n\\n\\t\\t\\t\\t\"],[1,[28,\"elements/btn-edit\",null,[[\"disabled\",\"onSubmit\"],[[24,[\"disabled\"]],[24,[\"onEditPanel\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-footer-btns/template.hbs"
    }
  });

  _exports.default = _default;
});