define("client/pods/channels/shop/campaigns/bulk-discounts/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details", "client/mixins/date"], function (_exports, R, _model, _sortableDetails, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sortableDetails.default, _date.default, {
    defaults() {
      return {
        name: '',
        hasSchedule: false,
        dateStartZ: '',
        dateEndZ: '',
        rules: [],
        status: this.get('constants.status.active'),
        productTags: []
      };
    },

    populate(data, attrs) {
      data.rules = this._transformRules(data);
      const todayZ = this.getStartOfDayZ();
      const dateStartZ = R.propOr('', 'dateStartZ')(data);
      const dateEndZ = R.propOr('', 'dateEndZ')(data);

      if (data.hasSchedule) {
        if (dateStartZ && !dateEndZ && dateStartZ > todayZ) {
          data.status = this.constants.status.inactive;
        } else if (!dateStartZ && dateEndZ && dateEndZ < todayZ) {
          data.status = this.constants.status.inactive;
        } else if (dateStartZ && dateEndZ && (todayZ < dateStartZ || todayZ > dateEndZ)) {
          data.status = this.constants.status.inactive;
        } else {
          data.status = this.constants.status.active;
        }
      }

      this._super(data, attrs);
    },

    _transformRules(data) {
      const rules = R.propOr([], 'rules')(data);
      return R.map(rule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
          data: rule
        });
      })(rules);
    },

    addRule(rule = {}) {
      const rules = this.getData('rules') || [];
      const ruleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
        data: rule
      });
      rules.pushObject(ruleModel);
      this.setData('rules', rules);
    },

    statusClass: Ember.computed('status', function () {
      const status = this.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    })
  });

  _exports.default = _default;
});