define("client/pods/components/channels/shop/campaigns/campaigns-editor/products-limits-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOCUEyA7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-section\",null,[[\"context\",\"documentationResource\",\"isRunning\"],[\"limitsSettingsEditor\",\"campaigns\",[24,[\"fetchCampaignDetailsLimitsTask\",\"isRunning\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"paginationIsFooter\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onPaginationTask\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"tableViewOptions\"],[[24,[\"tab\"]],\"channels/shop/campaigns/items/table-view/detail-limit-row\",false,[24,[\"batch\"]],[24,[\"filters\"]],[23,0,[\"crud\",\"searchAdvancedTask\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[24,[\"onPaginationTask\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[28,\"hash\",null,[[\"adapterName\",\"campaign\",\"isBulkEditing\",\"isReadonly\"],[\"channels/shop/campaigns/details/limits\",[24,[\"model\"]],[24,[\"isBulkEditing\"]],[24,[\"isReadonly\"]]]]]]]],false],[0,\"\\n\\n\"],[4,\"forms/form-row\",null,null,{\"statements\":[[0,\"\\t\\t\"],[5,\"helpers/data-updated\",[],[[\"@wasUpdated\",\"@hasBadge\",\"@badge\",\"@isRunning\",\"@onSubmit\"],[[24,[\"model\",\"hasDetailsLimitsChanged\"]],true,[28,\"count\",[[24,[\"model\",\"hasDetailsLimitsChanged\"]]],null],[24,[\"reloadSearchRecordsTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"reloadSearchRecordsTask\"]],[28,\"hash\",null,[[\"filters\",\"onAfter\"],[[24,[\"filters\"]],[28,\"action\",[[23,0,[]],\"resetDetailsLimitsUpdated\"],null]]]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/products-limits-editor/template.hbs"
    }
  });

  _exports.default = _default;
});