define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R24G0wQu",
    "block": "{\"symbols\":[\"automationModel\",\"onCloseDialogAction\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchAutomationsCollectionTask\",\"isRunning\"]]]],{\"statements\":[[4,\"unless\",[[24,[\"isReadonly\"]]],null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"icon\",\"disabled\",\"closeOnOverlayClick\"],[\"add\",\"fas fa-plus\",[24,[\"isReadonly\"]],false]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"channels/shop/campaigns/campaigns-editor/automations-editor/panels-new\",null,[[\"tab\",\"showAdvanced\",\"searchProductsTask\",\"productsList\",\"master\",\"automationsCollection\",\"errors\",\"onCloseDialogAction\"],[[24,[\"tab\"]],true,[24,[\"searchProductsTask\"]],[24,[\"productsList\"]],[24,[\"model\"]],[24,[\"automationsCollection\"]],[24,[\"errors\"]],[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"automationsCollection\",\"sorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"u-container automations-container\"],[8],[0,\"\\n        \"],[1,[28,\"channels/shop/campaigns/campaigns-editor/automations-editor/panels-display\",null,[[\"tab\",\"searchProductsTask\",\"productsList\",\"master\",\"automationModel\",\"automationDetails\",\"adapterName\",\"onSetAsDefaultImageTask\",\"onRemove\",\"errors\"],[[24,[\"tab\"]],[24,[\"searchProductsTask\"]],[24,[\"productsList\"]],[24,[\"model\"]],[23,1,[]],[23,1,[\"sorted\"]],[24,[\"adapterName\"]],[24,[\"onSetAsDefaultImageTask\"]],[24,[\"onRemove\"]],[24,[\"errors\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/template.hbs"
    }
  });

  _exports.default = _default;
});