define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor/component", ["exports", "client/mixins/sortable-actions"], function (_exports, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableActions.default, {
    tagName: '',
    modelProp: 'automationModel'
  });

  _exports.default = _default;
});