define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/transporter", "ember-concurrency", "ramda-extension"], function (_exports, R, RA, _searchContacts, _crud, _transporter, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { task } from 'ember-concurrency'
  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporter.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    transporterAccounts: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/tags', 'dispatch/lists/processes']); // const shippingZonesFilters = this.search.setupFilters({
      //   adapterName: 'channels/shop/shipping-zones/filters',
      //   defaultFiltersData: {
      //     resultsProperty: 'shippingZonesLists',
      //     query: ''
      //   }
      // })
      // this.set('shippingZonesFilters', shippingZonesFilters)
    },

    shopPaymentMethodsDefaultsList: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.paymentMethods.[]', function () {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.get('model._data.paymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }),
    defaultShippingZonesIds: Ember.computed('crud.lists.channels-shop-shipping-zones-all.@each.synced', function () {
      const shippingZones = this.crud.lists['channels-shop-shipping-zones-all'] || [];
      return R.map(R.path(['_data', 'zoneId']))(shippingZones);
    }),
    selectedShippingZonesIds: Ember.computed('model._data.shippingZonesIds.[]', 'crud.lists.channels-shop-shipping-zones-all.@each.synced', function () {
      const shippingZonesIds = this.get('model._data.shippingZonesIds') || [];
      const shippingZones = this.crud.lists['channels-shop-shipping-zones-all'] || [];
      return R.filter(zone => {
        return R.pipe(R.path(['_data', 'zoneId']), R.indexOf(R.__, shippingZonesIds), R.gt(R.__, -1))(zone);
      })(shippingZones);
    }),
    allowedDispatchProcesses: Ember.computed('crud.lists.dispatch-lists-processes', function () {
      const allDispatchProcesses = this.get('crud.lists.dispatch-lists-processes') || [];
      return R.reject(R.pathEq(['_data', 'isDispatched'], true))(allDispatchProcesses);
    }),
    // /**
    //  * Fetch shipping zones
    //  * @param {Object} args
    //  * @param {string} query - query string
    //  * @return {Array<Model>} - lists of shipping zone
    //  */
    // fetchShippingZonesTask: task(function * ({
    //   query 
    // }) {
    //   if (!query) {
    //     const results = this.crud
    //       .lists['channels-shop-shipping-zones'] || 
    //       []
    //     this.set(
    //       'shippingZonesLists',
    //       results
    //     )
    //     return results
    //   }
    //   query = query.trim()
    //   if (query) {
    //     const shippingZonesFilters = this.get(
    //       'shippingZonesFilters'
    //     )
    //     const adapterName = 'channels/shop/shipping-zones'
    //     shippingZonesFilters.setData(
    //       'query',
    //       query
    //     )
    //     const results = yield this.crud
    //       .searchInputTask.perform(
    //         {
    //           adapterName,
    //           filters: shippingZonesFilters,
    //           component: this,
    //           resultsProperty: 'shippingZonesLists'
    //         }
    //       )
    //     return results
    //   }
    // }),
    actions: {
      loadTransporter(model, transporter) {
        model.setData('transporterKey', transporter.getData('_key'));
        this.loadTransporterAccounts(model, transporter);
      },

      unloadTransporter(model) {
        model.setData('transporterKey', '');
        model.setData('transporterAccountNumber', '');
        model.setData('transporterAccount', '');
        this.set('transporterAccounts', '');
      },

      setTransporterAccountLabel(model, account) {
        model.setData('transporterAccount', account.getData('name'));
      },

      assignSalesPerson() {
        this.onAssignSalesPerson(...arguments);
        this.loadAdjustmentTask.perform();
      },

      unassignSalesPerson() {
        this.onUnassignSalesPerson(...arguments);
      },

      toggleCommissionMethod() {
        this.onToggleCommissionMethod(...arguments);
      },

      setShopPaymentMethodsDefault(model) {
        const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
        const currentDefault = model.getData('paymentMethodsDefault');
        let resetDefault = false;

        if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
          resetDefault = true;
        }

        if (!currentDefault) {
          resetDefault = true;
        }

        if (resetDefault) {
          const first = shopPaymentMethodsDefaultsList.get('firstObject');

          if (first) {
            model.setData('paymentMethodsDefault', first.getData('value'));
          } else {
            model.setData('paymentMethodsDefault', '');
          }
        }
      },

      addDispatchProcess(model, dispatchProcess) {
        const dispatchProcessLabelTranslate = dispatchProcess.getData('translate');
        const dispatchProcessLabel = dispatchProcess.getData('name');
        model.setData('dispatchProcessLabel', dispatchProcessLabel);
        model.setData('dispatchProcessLabelTranslate', dispatchProcessLabelTranslate);
      },

      removeDispatchProcess(model) {
        model.setData('dispatchProcessLabel', '');
        model.setData('dispatchProcessLabelTranslate', '');
      } //   onSelectShippingZoneId (selectedItem) {
      //     const zoneId = selectedItem.getData('zoneId')
      //     if (RA.isNotNilOrEmpty(zoneId)) {
      //       let shippingZoneIds = this.model.getData('shippingZonesIds') || []
      //       shippingZoneIds = R.pipe(
      //         R.append(zoneId),
      //         R.uniq
      //       )(shippingZoneIds)
      //       this.model.setData('shippingZonesIds', shippingZoneIds)
      //     }
      //     this.model.set('shippingZoneName', '')
      //   }


    }
  });

  _exports.default = _default;
});