define("client/pods/components/msgs/deleted-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rWCbDoIk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"model\",\"isDeleted\"]],[24,[\"model\",\"isOutOfDate\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"deleted-error u-section\"],[8],[0,\"\\n    \"],[1,[28,\"tt\",[[24,[\"msg\"]]],null],false],[0,\"\\n    \"],[1,[28,\"msgs/sync-updated\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/deleted-error/template.hbs"
    }
  });

  _exports.default = _default;
});