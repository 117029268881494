define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commissions-adjustment-editor/adjustment-table/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorAdjustmentTable = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorAdjustmentTable extends _component.default {
    constructor() {
      var _this$args;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "big", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "campaignsProducts", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "productList", _descriptor6, this);
      (0, _defineProperty2.default)(this, "productsFilters", null);
      (0, _defineProperty2.default)(this, "productsFiltersAdapterName", 'products/filters');
      let excludes = [];

      if (this.isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }

      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.productsFilters = productsFilters;
      this.productList = [];

      if (this !== null && this !== void 0 && (_this$args = this.args) !== null && _this$args !== void 0 && _this$args.item) {
        var _this$args2;

        this.args.recalculateCommissionTotal({
          useExclAmt: this.useExclAmt,
          item: this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.item,
          isFromcommissionTotal: true
        });
      }

      this.commissionErrorMsg = this.intl.t('commission_adjustment_error_msg');
    }

    get useExclAmt() {
      var _this$args3, _this$args3$adjustmen, _this$args3$adjustmen2;

      let useExclAmt = this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$adjustmen = _this$args3.adjustmentModel) === null || _this$args3$adjustmen === void 0 ? void 0 : (_this$args3$adjustmen2 = _this$args3$adjustmen._data) === null || _this$args3$adjustmen2 === void 0 ? void 0 : _this$args3$adjustmen2.isCalculatingCommissionsByExclAmt;

      if (RA.isNilOrEmpty(useExclAmt)) {
        useExclAmt = true;
      }

      return useExclAmt;
    }

    get totalPrice() {
      let totalPrice;
      const qty = this.args.item.getData('qty');
      const isCalculatingCommissionsByExclAmt = this.args.adjustmentModel.getData('isCalculatingCommissionsByExclAmt');

      if (isCalculatingCommissionsByExclAmt) {
        const exclAmt = this.args.item.getData('exclAmt') || 0;
        totalPrice = this.big.newBig(qty).times(exclAmt);
        const discExclPrice = this.args.item.getData('discExclPrice');

        if (RA.isNotNilOrEmpty(discExclPrice)) {
          totalPrice = this.big.newBig(qty).times(discExclPrice);
        }

        return totalPrice.toFixed();
      }

      const inclAmt = this.args.item.getData('inclAmt') || 0;
      totalPrice = this.big.newBig(qty).times(inclAmt);
      const discInclPrice = this.args.item.getData('discInclPrice');

      if (RA.isNotNilOrEmpty(discInclPrice)) {
        totalPrice = this.big.newBig(qty).times(discInclPrice);
      }

      return totalPrice.toFixed();
    }

    loadItem(item, product) {
      item.setData('item', product.getData('name'));
    }

    removeAdjustmentRow(item) {
      const details = this.args.details;
      details.removeObject(item);
    }

    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const campaignKey = this.args.campaignKey;
        const filters = this.productsFilters;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadItem", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeAdjustmentRow", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdjustmentRow"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditorAdjustmentTable;
});