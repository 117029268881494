define("client/pods/documents/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll(data = {}, appendPath, options) {
      const docType = options.docType;
      const docName = options.docName;
      const customData = {
        list: [{
          component: 'documents/panels-dash',
          resource: `/api/protected/documents/${docType}/${docName}`,
          icon: 'fas fa-table',
          label: 'overview',
          action: 'dash'
        }, {
          component: 'documents/panels-new',
          resource: `/api/protected/documents/${docType}/${docName}`,
          icon: 'fas fa-plus',
          label: 'new',
          action: 'new'
        }]
      };
      const path = this.getFullPath('findAllPath');
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});