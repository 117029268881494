define("client/pods/components/export/worksheet/editor/customworksheet-nested/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Component from '@ember/component'
  class ExportWorksheetEditorCustomworksheetNestedComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "tagName", 'div');
      (0, _defineProperty2.default)(this, "classNames", ['nav-tree']);
      (0, _defineProperty2.default)(this, "translate", true);
      (0, _defineProperty2.default)(this, "isViewable", true);
      (0, _defineProperty2.default)(this, "disabled", false);
      (0, _defineProperty2.default)(this, "labelKey", 'label');
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "subProperty", 'sub');
    }

  }

  _exports.default = ExportWorksheetEditorCustomworksheetNestedComponent;
});