define("client/pods/export/service", ["exports", "client/mixins/big", "ramda", "ramda-adjunct", "ramda-extension", "client/utils/nventor"], function (_exports, _big, R, RA, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_big.default, {
    dateService: Ember.inject.service('date'),
    intl: Ember.inject.service(),

    exportByMethod({
      specs,
      docData
    }) {
      const today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD') | '';
      const method = specs.method;
      const subMethod = specs.subMethod;
      const paymentMethodKey = specs.paymentMethod;
      const description = specs.description;
      let translatedDescription = this.intl.t(description);

      if (R.includes('Missing translation', translatedDescription)) {
        translatedDescription = description;
      }

      if (R.equals('export_orders_total', description)) {
        translatedDescription = R.join('-', [translatedDescription, today]);
      }

      if (method === 'byPaymentMethod') {
        return {
          sheetName: translatedDescription,
          data: this[method]({
            specs,
            docData,
            subMethod,
            paymentMethodKey
          })
        };
      }

      return {
        sheetName: translatedDescription,
        data: this[method]({
          specs,
          docData
        })
      };
    },

    byDetails({
      specs,
      docData
    }) {
      const madeByDetail = this.makeByDetail({
        specs,
        docData
      });
      return R.propOr([], 'byDetailsWorksheet')(madeByDetail);
    },

    byDetailsWithGrandTotals({
      specs,
      docData
    }) {
      const madeByDetail = this.makeByDetail({
        specs,
        docData
      });
      const byDetailsWorksheet = R.propOr([], 'byDetailsWorksheet')(madeByDetail);
      const grandTotalRow = R.propOr([], 'grandTotalRow')(madeByDetail);
      return R.append(grandTotalRow)(byDetailsWorksheet);
    },

    byAllOrders({
      specs,
      docData
    }) {
      let index = 0;
      const separatedSpecs = this.separateInfoAndDetailSpecs({
        specs
      }); // only need first specs for column title

      const specsForColumnTitle = R.pipe(R.propOr([], 'infoSpecs'), R.take(1))(separatedSpecs);
      const columnTitle = this.getInfoColumnTitle({
        specs: specsForColumnTitle
      });
      return R.compose(R.prepend(columnTitle), R.unnest, R.map(eachDocData => {
        index = index + 1; // separate the info and detail specs

        const separatedSpecs = this.separateInfoAndDetailSpecs({
          specs
        });
        const infoSpecs = R.propOr({}, 'infoSpecs')(separatedSpecs);
        const detailSpecs = R.propOr({}, 'detailSpecs')(separatedSpecs); // get data for the info row.

        const infoRow = this.applySpec({
          specs: infoSpecs,
          eachDocData,
          index,
          type: 'multiple'
        }); // get data for the detail row

        const detailRows = this.createDetailRows({
          detailSpecs,
          eachDocData
        });
        return R.concat(infoRow)(detailRows);
      }))(docData);
    },

    byAllOrdersPerRow({
      specs,
      docData
    }) {
      let index = 0; // const separatedSpecs = this.separateInfoAndDetailSpecs({ specs })
      // only need first specs for column title

      const specsForColumnTitle = R.pipe(R.propOr([], 'specs'), R.take(1))(specs);
      const columnTitle = this.getInfoColumnTitle({
        specs: specsForColumnTitle,
        ignoreDetail: false
      });
      return R.compose(R.prepend(columnTitle), R.unnest, R.map(eachDocData => {
        index = index + 1;
        const specsArray = R_.dotPathOr([], 'specs', specs);
        const row = this.createMultipleRows({
          specsArray,
          eachDocData,
          index
        });
        return R.pipe(R.unnest, R.transpose)(row);
      }))(docData);
    },

    createMultipleRows({
      specsArray,
      eachDocData,
      index
    }) {
      return R.map(specs => {
        return R.map(spec => {
          const specNested = R.pipe(RA.list, RA.list)(spec);

          if (R.propEq('isDetail', true, spec)) {
            return R.unnest(this.createDetailRows({
              detailSpecs: specNested,
              eachDocData
            }));
          }

          const detailsLength = R_.dotPathOr(1, 'details.length')(eachDocData);
          const infoRow = this.applySpec({
            specs: specNested,
            eachDocData,
            index,
            type: 'multiple'
          });
          return R.pipe(_nventor.default.safeHeadOr(''), R.repeat(R.__, detailsLength), R.unnest)(infoRow);
        })(specs);
      })(specsArray);
    },

    byAllOrdersWithSummary({
      specs,
      docData
    }) {
      let summaryRows = this.createSummary({
        specs,
        docData
      });
      summaryRows = R.prepend([])(summaryRows);
      return R.concat(this.byAllOrders({
        specs,
        docData
      }))(summaryRows);
    },

    byAllOrdersPerRowWithSummary({
      specs,
      docData
    }) {
      let summaryRows = this.createSummary({
        specs,
        docData
      });
      summaryRows = R.prepend([])(summaryRows);
      return R.concat(this.byAllOrdersPerRow({
        specs,
        docData
      }))(summaryRows);
    },

    byPaymentMethod({
      specs,
      docData,
      subMethod = 'byAllOrdersWithSummary',
      paymentMethodKey = 'all'
    }) {
      const paidStatus = docData => {
        if (docData.paymentMethodKey === 'cash') {
          return 'unpaid';
        }

        return 'paid';
      };

      const batchGroupByPayment = R.groupBy(paidStatus)(docData);
      const applicablePaymentMethods = R.pipe(R.mapObjIndexed((groupData, groupPaymentMethodKey) => {
        if (paymentMethodKey === 'all') {
          return groupData;
        }

        if (groupPaymentMethodKey === paymentMethodKey) {
          return groupData;
        }

        return false;
      }), R.reject(R.equals(false)))(batchGroupByPayment);
      const paymentWorksheets = R.map(eachGroupByPayment => {
        return this[subMethod]({
          specs,
          docData: eachGroupByPayment
        });
      })(applicablePaymentMethods);
      return R.unnest(R.values(paymentWorksheets));
    },

    // EXPORT HELPER

    /**
     * get only column title for info. use this if the detail column title is not needed.
     * @param {Array} specsData  contains array of array from customer's specs
     * @return {Array}  array of column title in one row
     */
    getInfoColumnTitle({
      specs,
      ignoreDetail = true
    }) {
      return R.pipe(_nventor.default.safeHeadOr([]), R.map(spec => {
        const isDetail = R.prop('isDetail')(spec);

        if (isDetail && ignoreDetail) {
          return false;
        }

        const label = R.prop('label')(spec);
        let title = R.prop('column', spec);

        if (title) {
          title = this.intl.t(title);
        }

        if (label) {
          title = label;
        }

        return title || '';
      }), R.reject(R.equals(false)))(specs); // let columnTitle = []
      // R.forEach(specsForRow => {
      //   let columnTitleRow = []
      //   R.forEach(spec => {
      //     if (RA.isNilOrEmpty(R.prop('isDetail', spec))) {
      //       const label = R.prop('label')(spec)
      //       let title = R.prop('column', spec)
      //       if (title) {
      //         title = this.intl.t(title)
      //       }
      //       if (label) {
      //         title = label
      //       }
      //       columnTitleRow = R.append(title, columnTitleRow)
      //     }
      //   })(specsForRow)
      //   columnTitle = R.append(columnTitleRow, columnTitle)
      // })(specs)
      // return R.unnest(columnTitle)
    },

    getColumnsPrep({
      specs,
      docData
    }) {
      const infoColumnNameList = this.getInfoColumnTitle({
        specs: specs.specs
      });
      const [detailColumnNameList, detailItemKeyList] = this.getColumnNameList({
        specs,
        docData
      });
      return {
        infoColumnNameList,
        detailColumnNameList,
        detailItemKeyList
      };
    },

    /**
     * get all column title (info and detail in 1 row)
     * @param {Object} specsData
     * @param {Object} specsData.specs    specs from customer
     * @param {Object} specsData.docData  serialized dataset
     * @return {Array}  array of column title in one row
     */
    getAllColumnTitle({
      specs,
      docData
    }) {
      const columnsPrep = this.getColumnsPrep({
        specs,
        docData
      });
      const infoColumnNameList = R.propOr([], 'infoColumnNameList')(columnsPrep);
      const detailColumnNameList = R.propOr([], 'detailColumnNameList')(columnsPrep);
      return R.concat(infoColumnNameList, detailColumnNameList);
    },

    makeByDetail({
      specs,
      docData
    }) {
      let index = 0; // get all column title in one row

      const columnNameList = this.getAllColumnTitle({
        specs,
        docData
      }); // get item key list for validating unique item (even if it have different name)

      const columnsPrep = this.getColumnsPrep({
        specs,
        docData
      });
      const detailItemKeyList = R.propOr({}, 'detailItemKeyList')(columnsPrep);
      let combinedRows = [];
      let grandTotal = R.repeat(0, detailItemKeyList.length);
      const separatedSpecs = this.separateInfoAndDetailSpecs({
        specs
      });
      R.forEach(eachDocData => {
        index = index + 1; // get only infospecs with separate function

        const infoSpecs = R.propOr({}, 'infoSpecs')(separatedSpecs); // get info row from each data

        const infoRow = this.applySpec({
          specs: infoSpecs,
          eachDocData,
          index,
          type: 'single'
        }); // get detail row from each data

        const detailRow = this.getDetailQty({
          eachDocData,
          detailItemKeyList
        }); // make a combined row of info and detail

        const combinedRow = R.concat(infoRow, detailRow); // append all combined row into one combined rows

        combinedRows = R.append(combinedRow, combinedRows); // calculate grand total for each item while at it

        grandTotal = R_.mapIndexed((value, index) => R.add(value, detailRow[index]))(grandTotal);
      })(docData); // setting place for grand total below all of combined rows.

      let toRepeat = R.pipe(R.propOr([], 'infoColumnNameList'), R.length)(columnsPrep);

      if (toRepeat) {
        toRepeat = toRepeat - 1;
      }

      let blankSpace = R.repeat('', toRepeat);
      blankSpace = R.append('Total', blankSpace);
      const byDetailsWorksheet = R.prepend(columnNameList, combinedRows);
      const grandTotalRow = R.concat(blankSpace, grandTotal);
      return {
        byDetailsWorksheet,
        grandTotalRow
      };
    },

    getDetailQty({
      eachDocData,
      detailItemKeyList
    }) {
      const toDetails = R.propOr([], 'details')(eachDocData);
      let detailRow = [];
      R.forEach(detailItemKey => {
        let detailValue = 0;
        R.forEach(eachDocDataDetails => {
          const itemKeyVal = R.prop('itemKey')(eachDocDataDetails);

          if (RA.isNilOrEmpty(itemKeyVal)) {
            const item = R.prop('item')(eachDocDataDetails);

            if (detailItemKey === item) {
              detailValue += parseFloat(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
            }
          } else {
            if (detailItemKey === itemKeyVal) {
              detailValue += parseFloat(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
            }
          }
        })(toDetails);
        detailRow = R.append(detailValue)(detailRow);
      })(detailItemKeyList);
      return detailRow;
    },

    createSummary({
      specs,
      docData
    }) {
      const detailSpecs = R.pipe(R.propOr([], 'specs'), R.flatten, R.filter(R.propEq('isDetail', true)))(specs);
      const columnName = R.map(detailSpec => {
        const name = R.prop('value')(detailSpec);

        if (name) {
          return this.intl.t(name);
        }

        return '';
      })(detailSpecs);
      const propNames = R.map(detailSpec => {
        return R.prop('prop')(detailSpec);
      })(detailSpecs);

      const groupByItem = eachDocDataDetails => {
        const itemKey = R.prop('itemKey')(eachDocDataDetails);

        if (RA.isNotNilOrEmpty(itemKey)) {
          return itemKey;
        }

        return R.prop('item')(eachDocDataDetails);
      };

      let grandTotal = 0;
      let data = [];
      data = R.pipe(R.pluck('details'), R.flatten, R.reject(R.propEq('isSetChild', true)), R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
        let propValues = [];
        R.forEach(prop => {
          if (R.isEmpty(prop)) {
            // blank space
            propValues = R.append('', propValues);
          }

          if (prop === 'qty') {
            // qty
            const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0;
            propValues = R.append(groupTotalQty, propValues);
          }

          if (prop === 'postDiscInclAmt') {
            // postDiscInclAmt
            const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', groupData)) || 0;
            grandTotal = grandTotal + groupTotalInclAmt;
            propValues = R.append(groupTotalInclAmt, propValues);
          }

          if (prop === 'item') {
            // item
            const item = R.pipe(R.head, R.propOr('', 'item'))(groupData);
            propValues = R.append(item, propValues);
          }

          if (prop === 'itemCode') {
            // itemCode
            const itemCode = R.pipe(R.head, R.propOr('', 'itemCode'))(groupData);
            propValues = R.append(itemCode, propValues);
          } // add more for summary

        })(propNames);
        return propValues;
      }), R.values, R.concat([[], columnName]), R.append([]))(docData);
      let blankSpace = [];

      if (columnName.length >= 2) {
        blankSpace = R.repeat('', columnName.length - 2);
      }

      data = R.append(R.concat(blankSpace, ['total', grandTotal]), data);
      return data;
    },

    fillItemKeyAndColumnNameList({
      itemKeyVal,
      itemKeyList,
      itemVal,
      columnNameList,
      customColumnTitle = itemVal
    }) {
      if (RA.isNotNilOrEmpty(itemKeyVal)) {
        if (!R.includes(itemKeyVal, itemKeyList)) {
          itemKeyList = R.append(itemKeyVal, itemKeyList);
          columnNameList = R.append(customColumnTitle, columnNameList);
        }
      } else {
        if (!R.includes(itemVal, itemKeyList)) {
          itemKeyList = R.append(itemVal, itemKeyList);
          columnNameList = R.append(customColumnTitle, columnNameList);
        }
      }

      return [itemKeyList, columnNameList];
    },

    getColumnNameList({
      specs,
      docData
    }) {
      let itemKeyList = [];
      let columnNameList = [];

      if (!specs.autoGenerateByDetailsSummary) {
        // check if there's custom detail
        R.forEach(array => {
          R.forEach(spec => {
            if (spec.isDetail) {
              const itemKeyVal = R.prop('itemKey')(spec);
              const itemVal = R.prop('item')(spec);
              let customColumnTitle = R.prop('label')(spec); // || itemVal;

              if (customColumnTitle === 'item') {
                customColumnTitle = itemVal;
              }

              [itemKeyList, columnNameList] = this.fillItemKeyAndColumnNameList({
                itemKeyVal,
                itemKeyList,
                itemVal,
                columnNameList,
                customColumnTitle
              });
            }
          })(array);
        })(specs.specs);
      } else {
        R.forEach(eachDocData => {
          const toDetails = R.propOr([], 'details')(eachDocData);
          R.forEach(eachDocDataDetails => {
            const itemKeyVal = R.prop('itemKey')(eachDocDataDetails);
            const itemVal = R.prop('item')(eachDocDataDetails);
            [itemKeyList, columnNameList] = this.fillItemKeyAndColumnNameList({
              itemKeyVal,
              itemKeyList,
              itemVal,
              columnNameList
            });
          })(toDetails);
        })(docData);
      }

      return [columnNameList, itemKeyList];
    },

    createDetailRows({
      detailSpecs = [],
      eachDocData
    }) {
      detailSpecs = R.unnest(detailSpecs);
      let detailRows = [];
      const toDetails = R.propOr([], 'details')(eachDocData);
      R.forEach(eachDocDataDetails => {
        // make detail for each item bought
        let detailRow = [];
        R.forEach(detailSpec => {
          const propValue = R.prop('prop')(detailSpec); // add blank

          if (RA.isNilOrEmpty(propValue)) {
            detailRow = R.append('', detailRow);
          } // qty


          if (propValue === 'qty') {
            const query = parseInt(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
            detailRow = R.append(query, detailRow);
          } // item


          if (propValue === 'item') {
            let query = R.prop('item')(eachDocDataDetails);

            if (eachDocDataDetails.isSet) {
              query = `${this.intl.t('isSet')}: ${query}`;
            }

            if (eachDocDataDetails.isSetChild) {
              query = `${this.intl.t('product_sets_contents')}: ${query}`;
            }

            detailRow = R.append(query, detailRow);
          } // itemCode


          if (propValue === 'itemCode') {
            const query = R.prop('itemCode')(eachDocDataDetails);
            detailRow = R.append(query, detailRow);
          } // postDiscInclAmt


          if (propValue === 'postDiscInclAmt') {
            let query = R.prop('postDiscInclAmt')(eachDocDataDetails);
            query = parseFloat(query) || 0;

            if (eachDocDataDetails.isSetChild) {
              query = '';
            }

            detailRow = R.append(query, detailRow);
          } // add more below for custom detailRow
          // intentionally blank
          // intentionally blank
          // intentionally blank
          // intentionally blank

        })(detailSpecs); // append each item detail to make multiple item detail (if there are more than one item bought)

        detailRows = R.append(detailRow, detailRows);
      })(toDetails);
      return detailRows;
    },

    /**
     * separate info and detail specs to be used separately
     * @param  {Object} specsData contains specs from customer
     * @return {Object} separatedSpecs object containing info and detail specs
     */
    separateInfoAndDetailSpecs({
      specs
    }) {
      let infoSpecs = [];
      let detailSpecs = [];
      R.pipe(R.propOr([], 'specs'), R.forEach(array => {
        let infoSpec = [];
        let detailSpec = [];
        R.forEach(spec => {
          const isDetail = R.prop('isDetail')(spec);

          if (isDetail) {
            detailSpec = R.append(spec, detailSpec);
          } else {
            infoSpec = R.append(spec, infoSpec);
          }
        })(array);

        if (RA.isNotNilOrEmpty(infoSpec)) {
          infoSpecs = R.append(infoSpec, infoSpecs);
        }

        if (RA.isNotNilOrEmpty(detailSpec)) {
          detailSpecs = R.append(detailSpec, detailSpecs);
        }
      }))(specs);
      return {
        infoSpecs,
        detailSpecs
      };
    },

    applySpec({
      specs,
      eachDocData,
      index,
      type = 'single'
    }) {
      let infoRows = [];
      R.forEach(array => {
        let infoRow = [];
        R.forEach(spec => {
          let value = ''; // getprop

          value = this.transform_getProp({
            spec,
            eachDocData,
            value
          }); // transform Fn

          const transforms = R.prop('transforms')(spec);

          if (RA.isNotNilOrEmpty(transforms)) {
            if (R.is(String, transforms)) {
              value = this[`transform_${transforms}`]({
                spec,
                eachDocData,
                value,
                options: {},
                index
              });
            } else {
              R.map(eachFn => {
                const theFn = R.prop('fn')(eachFn);
                const options = R.prop('options')(eachFn);

                if (theFn) {
                  value = this[`transform_${theFn}`]({
                    spec,
                    eachDocData,
                    value,
                    options,
                    index
                  });
                }
              })(R.flatten(RA.list(transforms)));
            }
          } // type


          const type = R.prop('type')(spec);

          if (type && RA.isNotNilOrEmpty(type)) {
            value = this[`transform_${type}`]({
              spec,
              eachDocData,
              value
            });
          }

          infoRow = R.append(value, infoRow);
        })(array);
        infoRows = R.append(infoRow, infoRows);
      })(specs);

      if (type === 'single') {
        return R.unnest(infoRows);
      }

      return infoRows;
    },

    transform_concat({
      eachDocData,
      options
    }) {
      const joinWith = R.prop('joinWith')(options);
      const props = R.pipe(R.propOr([], 'prop'), RA.ensureArray)(options);
      return R.pipe(R.props(props), R.reject(RA.isNilOrEmpty), R.join(joinWith))(eachDocData);
    },

    transform_date({
      spec,
      value
    }) {
      const format = R.prop('dateFormat')(spec) || 'YYYY-MM-DD';

      if (RA.isNilOrEmpty(value)) {
        value = this.dateService.getMoment();
        return this.dateService.formatDate(value, format);
      } else {
        return this.dateService.formatDate(value, format);
      }
    },

    transform_index({
      index
    }) {
      return index;
    },

    transform_number({
      value
    }) {
      return parseFloat(value);
    },

    transform_getProp({
      spec,
      eachDocData
    }) {
      const prop = R.prop('prop')(spec);

      if (!R.is(String, prop)) {
        return '';
      }

      let propValue = '';

      if (RA.isNotNilOrEmpty(prop)) {
        propValue = R_.dotPath(prop)(eachDocData);
      } // select spec child, select prop=paymentMethodKey, availableNormalSpecs { prop: 'paymentMethodKey', translate: true } => becomes the spec child


      if (spec.translateFn) {
        return spec.translateFn(propValue, eachDocData);
      }

      let translate = spec.translate;
      const translateProp = spec.translateProp;

      if (translateProp) {
        translate = R_.dotPath(translateProp)(eachDocData);
      }

      if (translate) {
        return this.intl.t(propValue);
      } // @TODO: remove this and use the above method


      if (prop === 'paymentMethodKey') {
        return this.intl.t(propValue);
      }

      return propValue;
    },

    transform_round({
      value,
      options
    }) {
      const rounding = R.prop('rounding')(options);
      value = parseFloat(this.newBig(value).toFixed(rounding));
      return value;
    },

    transform_today({
      spec
    }) {
      const format = R.prop('dateFormat')(spec) || 'YYYY-MM-DD';
      const today = this.dateService.getMoment();
      return this.dateService.formatDate(today, format);
    },

    transform_getItemData({
      spec,
      eachDocData,
      value,
      typeOfTransformsArgs
    }) {
      let itemData = '';
      const options = R.propOr('', 'options')(spec);
      const hasAnyItemData = R.pipe(R.pick(['itemKey', 'itemCode', 'item']), R.reject(RA.isNilOrEmpty))(spec);

      if (RA.isNotNilOrEmpty(options)) {
        typeOfTransformsArgs = R.prop('prop')(options);
      }

      if (RA.isNotNilOrEmpty(hasAnyItemData)) {
        const hasItemPropValue = R.includes(R.__, R.values(hasAnyItemData));
        const toTransformData = R.propOr([], 'details')(eachDocData);
        R.map(eachDocDataDetails => {
          const docHasItemKey = R.prop('itemKey')(eachDocDataDetails);
          const docHasItemCode = R.prop('itemCode')(eachDocDataDetails);
          const docHasItem = R.prop('item')(eachDocDataDetails);
          const hasAnyItemProps = R.any(hasItemPropValue);

          if (hasAnyItemProps([docHasItemKey, docHasItemCode, docHasItem])) {
            const productQty = this.newBig(R.prop(typeOfTransformsArgs)(eachDocDataDetails));
            value = parseFloat(this.newBig(value).plus(productQty));
            itemData = R.props(['itemCode', 'itemKey'])(eachDocDataDetails);
          }
        })(toTransformData);
        itemData = R.append(value)(itemData);
      }

      return itemData;
    },

    transform_getItemCode({
      spec,
      eachDocData,
      value = '',
      typeOfTransformsArgs = 'qty'
    }) {
      const itemData = this.transform_getItemData({
        spec,
        eachDocData,
        value,
        typeOfTransformsArgs
      });
      return R.dropLast(1, itemData);
    },

    transform_getSum({
      spec,
      eachDocData,
      value = '',
      typeOfTransformsArgs = 'qty'
    }) {
      const itemData = this.transform_getItemData({
        spec,
        eachDocData,
        value,
        typeOfTransformsArgs
      });
      return R.last(itemData);
    }

  });

  _exports.default = _default;
});