define("client/pods/components/helpers/drop-container/component", ["exports", "client/mixins/droppable"], function (_exports, _droppable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_droppable.default, {
    tag: 'div',
    classNames: ['drop-container'],
    classNameBindings: ['dragPosition']
  });

  _exports.default = _default;
});