define("client/pods/components/helpers/header-sort/component", ["exports", "client/utils/nventor", "ramda", "ramda-extension"], function (_exports, _nventor, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['header-sort'],
    classNameBindings: ['isMain:is-main', '_hasHeadersSort::inactive'],
    _hasHeadersSort: Ember.computed('hasHeadersSort', 'filters', function () {
      if (!this.hasHeadersSort) {
        return false;
      }

      if (_nventor.default.isNilOrEmpty(this.filters)) {
        return false;
      }

      return true;
    }),
    sort: Ember.computed('filters._data.sort.[]', function () {
      return this.get('filters._data.sort') || [];
    }),
    direction: Ember.computed('sort.@each.{by,direction}', function () {
      const sortByObject = this.getSortByObject();
      const direction = R.pathOr('', ['_data', 'direction'], sortByObject);
      return direction;
    }),

    getSortByObject() {
      const sort = this.sort || [];
      let by = this.by;

      if (R.is(Array, by)) {
        by = by[0];
      }

      const found = R.find(R.pathEq(['_data', 'by'], by))(sort);

      if (found) {
        return found;
      }

      return '';
    },

    // direction: computed('sortByObject', function () {
    //   const sortByObject = this.get('sortByObject')
    //   return R.pathOr('', ['_data', 'direction'], sortByObject)
    // }),
    isMain: Ember.computed('by', 'sort.@each.by', function () {
      const sort = this.sort || [];
      let by = this.by;

      if (R.is(Array, by)) {
        by = by[0];
      }

      const index = R.findIndex(R.pathEq(['_data', 'by'], by))(sort);

      if (index === 0) {
        return true;
      }

      return false;
    }),

    click(e) {
      if (this._hasHeadersSort) {
        const by = this.by;
        const hasShiftKey = e.shiftKey || e.ctrlKey || e.metaKey;
        const previousDirection = this.direction;
        let direction;

        if (!previousDirection) {
          direction = 'ASC';
        } else if (previousDirection === 'ASC') {
          direction = 'DESC';
        } else if (previousDirection === 'DESC') {
          direction = '';
        }

        let params = by;

        if (!R.is(Array, by)) {
          params = [by];
        }

        const toNumber = this.toNumber;
        const filters = this.filters;
        R.forEach(param => {
          if (hasShiftKey) {
            filters.addSort(param, direction, {
              toNumber
            });
          } else {
            filters.replaceSort(param, direction, {
              toNumber
            });
          }
        })(params);

        if (filters.getData('rememberMe')) {
          this.search.setToStorage(filters);
        }

        const onSort = this.onSort;
        return onSort(filters);
      }
    }

  });

  _exports.default = _default;
});