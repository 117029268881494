define("client/pods/components/workflows/panels-display-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  // import * as RA from 'ramda-adjunct'
  let WorkflowsPanelDisplayItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('workflows'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, (_class = (_temp = class WorkflowsPanelDisplayItemComponent extends _component.default {
    constructor() {
      var _this$args$model, _this$args$model$_dat;

      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "workflowsService", _descriptor3, this);
      (0, _defineProperty2.default)(this, "adapterName", 'workflows/items/activities/collection');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'workflows/items/activities/collection/filters');
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "activitiesCollection", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "newItemActivityModel", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedActivityType", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "isChatVisible", _descriptor9, this);
      this.crud.addLists(this, ['date/lists/durations', 'date/lists/hours', 'workflows/lists/items/activities/types']);
      const workflowItemKey = (_this$args$model = this.args.model) === null || _this$args$model === void 0 ? void 0 : (_this$args$model$_dat = _this$args$model._data) === null || _this$args$model$_dat === void 0 ? void 0 : _this$args$model$_dat._key;
      this.filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          workflowItemKey,
          sort: [{
            by: 'timestampZ',
            direction: 'ASC'
          }]
        }
      });
      this.setupTask.perform();
    }

    *setupTask() {
      const activitiesCollection = yield this.crud.findTask.perform({
        adapterName: this.adapterName,
        filters: this.filters,
        component: this,
        resultsProperty: ''
      });
      this.activitiesCollection = activitiesCollection;
    }

    toggleIsChatVisible() {
      this.isChatVisible = !this.isChatVisible;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "workflowsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "activitiesCollection", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newItemActivityModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedActivityType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isChatVisible", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleIsChatVisible", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsChatVisible"), _class.prototype)), _class));
  _exports.default = WorkflowsPanelDisplayItemComponent;
});