define("client/pods/components/documents/panel-merge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+tTmqjzo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/editor-main\",null,[[\"tab\",\"model\",\"original\",\"toMergeWithModels\",\"selectedContact\",\"isEditing\",\"isSplitting\",\"isMerging\",\"onDash\",\"errors\",\"onPreSubmit\",\"onAddNewDetail\",\"onSubmitTask\",\"submitFinalAppendPath\",\"fetchHCTRegionDataTask\",\"onCancel\",\"onRemoveTask\"],[[24,[\"tab\"]],[24,[\"dirty\"]],[24,[\"model\"]],[24,[\"toMergeWithModels\"]],[24,[\"selectedContact\"]],true,false,true,[24,[\"onDash\"]],[24,[\"errors\"]],[28,\"action\",[[23,0,[]],\"preSubmit\"],null],[28,\"action\",[[23,0,[]],\"addNewDetail\",[24,[\"dirty\"]]],null],[24,[\"mergeAllRecordsTask\"]],[24,[\"appendPath\"]],[24,[\"fetchHCTRegionDataTask\"]],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\",\"onAfter\"],[[24,[\"dirty\"]],[24,[\"onCloseDialogAction\"]]]]]],null],[24,[\"removeRecordTask\"]]]]],false],[0,\"\\n  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-merge/template.hbs"
    }
  });

  _exports.default = _default;
});