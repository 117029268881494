define("client/pods/components/elements/btn-save/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e7rZEXwW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"tooltipLabel\",\"icon\",\"translate\",\"hasBadge\",\"badge\",\"disabled\",\"isRunning\",\"onSubmit\",\"debug\"],[[24,[\"btnClassNames\"]],[24,[\"label\"]],[24,[\"tooltipLabel\"]],[23,0,[\"defaultIcon\"]],[24,[\"translate\"]],[24,[\"hasBadge\"]],[24,[\"badge\"]],[24,[\"disabled\"]],[24,[\"isRunning\"]],[24,[\"onSubmit\"]],[24,[\"debug\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/btn-save/template.hbs"
    }
  });

  _exports.default = _default;
});