define("client/pods/components/websites/panels-display/sub-panels-activity/sub-panels-activity-doc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ugv9rXgd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-title\"],[8],[0,\"\\n\\n\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"placeholder\",\"autofocus\",\"value\",\"isRunning\",\"onSubmit\",\"onInput\"],[\"search\",true,[24,[\"query\"]],[24,[\"searchCampaignTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"searchCampaignTask\"]]],null],[28,\"perform\",[[24,[\"searchCampaignTask\"]]],null]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"documents/search-filters\",null,[[\"adapterName\",\"filters\",\"onListByTask\",\"resultsToggle\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\"]],[24,[\"fetchCampaignActivityTask\"]],[24,[\"resultsToggle\"]],[24,[\"results\"]]]]],false],[0,\"\\n\\t\\t\\t\\n\"],[0,\"\\t\\t\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[28,\"or\",[[24,[\"searchCampaignTask\",\"isRunning\"]],[24,[\"fetchCampaignActivityTask\",\"isRunning\"]]],null]]],{\"statements\":[[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"documents/activity-list\",null,[[\"tab\",\"hideExcl\",\"results\",\"showOrderDate\",\"isRunning\",\"onPaginationTask\",\"lastSearchSettings\",\"page\",\"count\"],[[24,[\"tab\"]],true,[24,[\"allActivity\"]],true,[24,[\"updateDispatchStatusTask\",\"isRunning\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"lastSearchSettings\"]],[24,[\"page\"]],[24,[\"count\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-activity/sub-panels-activity-doc/template.hbs"
    }
  });

  _exports.default = _default;
});