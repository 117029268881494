define("client/pods/components/products/panels-edit/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'products',

    init() {
      this.setup();

      this._super(...arguments);
    },

    setup() {
      const model = this.get('tab.model');
      const dirty = this.setupDirty({
        model
      });
      this.set('dirty', dirty);
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    }

  });

  _exports.default = _default;
});