define("client/pods/components/documents/tab-container/component", ["exports", "ramda", "ramda-extension", "client/mixins/crud", "client/mixins/doc-list-by", "client/utils/nventor", "client/pods/components/documents/docConfig", "ember-concurrency", "client/pods/documents/proxy/model"], function (_exports, R, R_, _crud, _docListBy, _nventor, _docConfig, _emberConcurrency, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, {
    crud: Ember.inject.service(),
    subTabsCopy: null,
    filtersAdapterName: 'documents/filters',

    init() {
      this._super(...arguments);

      const tab = this.tab;

      const docConfigData = _docConfig.default.getFor(tab);

      const {
        docType,
        docName
      } = docConfigData;
      this.crud.addLists(this, ['dispatch/lists/processes', 'units', 'taxes', 'taxMethods', 'contacts/lists/tags', 'discounts/types', 'dispatch/lists/processes', 'documents/lists/discountmethods', 'documents/lists/filters/statuses', 'paymentMethods', 'paymentTerms', 'paymentTerms/from', 'paymentTerms/end', 'documents/lists/deliverydatesoptions', 'dispatch/lists/processes', 'extensions/apps/tw/invoicingAuto', 'toggles/onOff', 'shipping/deliveryTimes', {
        adapterName: 'documents/lists/menu',
        appendPath: `${docType}/${docName}`,
        options: {
          docType,
          docName
        }
      }, {
        adapterName: 'users',
        appendPath: 'internal'
      }]);
      this.set('config', docConfigData);
      this.set('tab.tabs', []);
      const title = tab.get('docType') + '-' + tab.get('docName');
      this.set('docTitle', title);
      this.set('adapterName', this.get('config.adapterName'));
      this.set('docType', docType);
      this.set('docName', docName);
      const period = this.getPeriod();
      this.set('period', period);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
    },

    loadTabTask: (0, _emberConcurrency.task)(function* ({
      tab
    }) {
      const filters = this.filters;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');

          if (id) {
            const found = yield this.findTask.perform({
              appendPath: id
            });
            this.display(found);
          }

          const query = loadInstructions.getData('search.query');

          if (query) {
            filters.setData('query', query);
            const results = yield this.searchRecordsTask.perform({
              filters
            });
            const found = results.get('firstObject');
            this.display(found);
          }
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
        this.dash({}, results);
      }
    }),

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      this.set('listsIsLoaded', Math.random());
      this.loadTabTask.perform({
        tab
      });
    },

    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    updateDocInfoTask: (0, _emberConcurrency.task)(function* (dirty) {
      const adapterName = this.adapterName;
      return yield this.updateRecordTask.perform({
        adapterName,
        appendPath: '/info',
        model: dirty
      });
    }).drop(),
    documentsListsMenu: Ember.computed('listsIsLoaded', 'docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;
      return this.get(`crud.lists.documents-lists-menu--${docType}-${docName}`) || [];
    }),
    resultsProxy: Ember.computed('results.[]', function () {
      const results = this.results || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: false // @TODO: get this from settings

      }), results);
    }),

    dash(model, results) {
      const tab = this.tab;
      this.set('showResults', false);

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        results = this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
      }

      const subTabOptions = {
        component: 'documents/panel-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    /**
     * display document
     * @param  {object} document model
     */
    display(model) {
      this.set('showResults', false);
      const component = this.get('config.displayComponent');
      const subTabOptions = {
        component: component,
        model: model,
        config: this.config
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    new() {
      this.set('showResults', false);
      const self = this;
      const component = self.get('config.newComponent');
      const subTabOptions = {
        component: component,
        config: self.get('config')
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      dash() {
        this.dash();
      },

      display(model) {
        this.set('showResults', false);

        if (model) {
          this.display(model);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new() {
        this.new(...arguments);
      },

      close(panel) {
        this.tab.closePanel(panel);
      },

      edit(model) {
        this.set('showResults', false);
        const self = this;
        const component = self.get('config.editComponent');
        const subTabOptions = {
          component: component,
          model: model,
          config: self.get('config')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }

    }
  });

  _exports.default = _default;
});