define("client/pods/components/admin/payments/card/panels-payments/panels-statements/component", ["exports", "ramda", "client/mixins/crud", "client/mixins/date", "ember-concurrency"], function (_exports, R, _crud, _date, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',

    didInsertElement() {
      // fetch payments
      this._super(...arguments);

      const period = this.get('tab.period');
      this.set('period', period);
      this.fetchStatementsTask.perform(period);
    },

    fetchStatementsTask: (0, _emberConcurrency.task)(function* (period) {
      const appendPath = this.selectedProvider;
      const response = yield this.server.call('GET', `api/protected/admin/payments/card/statements/${appendPath}`, period);
      let results = response.data || [];
      results = R.map(result => Ember.Object.create(result), results);
      this.set('statements', results);
    }),
    fetchStatementDetailsTask: (0, _emberConcurrency.task)(function* ({
      statement,
      filters
    }) {
      filters.setData('provider', this.selectedProvider);
      const results = yield this.searchRecordsTask.perform({
        adapterName: 'admin/payments/card/statements/details',
        appendPath: `/${statement.getData('podKey')}`,
        filters,
        resultsProperty: ''
      });
      statement.set('details', results);
    }),
    paginateStatementDetailsTask: (0, _emberConcurrency.task)(function* ({
      context,
      filters
    }) {
      yield this.fetchStatementDetailsTask.perform({
        statement: context,
        filters
      });
    })
  });

  _exports.default = _default;
});