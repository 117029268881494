define("client/pods/components/froala-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-froala-editor/components/froala-editor", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _froalaEditor, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let FroalaEditor = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class FroalaEditor extends _froalaEditor.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "stringifyContent", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "editor", _descriptor3, this);
      (0, _defineProperty2.default)(this, "locale", R.pipe(R.pathOr(['en_us'], ['intl', 'locale']), _nventor.default.safeHeadOr('en_us'), R.replace('-', '_'))(this));
      (0, _defineProperty2.default)(this, "defaultOptions", {
        // width: '800',
        heightMin: 100,
        height: this.args.basicToolbar ? 100 : 600,
        attribution: false,
        // fontSizeDefaultSelection: '14', // doesnt work
        toolbarBottom: false,
        fontFamilyDefaultSelection: 'Arial',
        fontSizeDefaultSelection: '14',
        // Enable or disable file upload.
        fileUpload: false,
        // Enable or disable image upload.
        imageUpload: false,
        // Enable or disable video upload.
        videoUpload: false,
        quickInsertEnabled: false,
        // iconsTemplate: 'font_awesome_5',
        language: this.locale,
        toolbarStickyOffset: 80,
        // when toolbarBottom is true, should be 40
        toolbarButtons: this.setToolbarButtons(),
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', '|', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'] // iframe: true,
        // iframeStyle: 'body{height:300px;overflow:auto;}'

      });
      (0, _defineProperty2.default)(this, "externalOptions", this.args.options || {});
      (0, _defineProperty2.default)(this, "options", R.mergeRight(this.defaultOptions, this.externalOptions));
    }

    setToolbarButtons() {
      let toolbarButtons;

      if (this.args.basicToolbar) {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 6
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
            align: 'left',
            buttonsVisible: 4
          },
          moreRich: {
            buttons: ['insertLink'],
            align: 'left',
            buttonsVisible: 1
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        };
      } else {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 3
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 3
          },
          moreRich: {
            buttons: ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
            align: 'left',
            buttonsVisible: 3
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'print', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        };
      }

      const hasFileBrowser = R.pathOr(false, ['hasFileBrowser'])(this.args);
      const hasVideoBrowser = R.pathOr(false, ['hasVideoBrowser'])(this.args);

      if (hasFileBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('imageManager'))(toolbarButtons);
      }

      if (hasVideoBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('insertVideo'))(toolbarButtons);
      }

      return toolbarButtons;
    }
    /**
     * Add or Remove event listener scroll, only when Froala is on a Modal
     * @param {FroalaEditor} editor - Editor Instance
     * @param {string} lifeCycle
     */


    toggleScrollListenerOnModal(editor, lifeCycle) {
      let fnName = '';
      const outerModal = editor.$box.closest('.modal-dialog__content')[0];

      if (lifeCycle === 'initialized' && outerModal) {
        fnName = 'addEventListener';
      } else if (lifeCycle === 'destroy' && outerModal) {
        fnName = 'removeEventListener';
      } else {
        return null;
      }

      outerModal[fnName]('scroll', () => editor.position.refresh());
    }

    showFileUploader() {
      var _this$editor$image;

      this.editor.popups.hideAll();
      let currentImage = (_this$editor$image = this.editor.image) === null || _this$editor$image === void 0 ? void 0 : _this$editor$image.get();

      if (RA.isNotNilOrEmpty(currentImage)) {
        currentImage = R.pipe(cImage => cImage.toArray(), R.head, cImage => {
          cImage.setAttribute('data-replace-id', _nventor.default.random.alpha(40));
          return cImage;
        })(currentImage);
      }

      this.args.showFileUploader(this, currentImage);
    }
    /**
     * Event on Initialized
     * @link https://froala.com/wysiwyg-editor/docs/events#initialized
     * @param {FroalaEditor} editor - Editor Instance
     */


    'on-initialized'(editor) {
      this.toggleScrollListenerOnModal(editor, 'initialized');
    }
    /**
     * Event on Destroy
     * @link https://froala.com/wysiwyg-editor/docs/events#destroy
     * @param {FroalaEditor} editor - Editor Instance
     */


    'on-destroy'(editor) {
      this.toggleScrollListenerOnModal(editor, 'destroy');
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "stringifyContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "editor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showFileUploader", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showFileUploader"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'on-initialized', [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, 'on-initialized'), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, 'on-destroy', [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, 'on-destroy'), _class.prototype)), _class));
  _exports.default = FroalaEditor;
});