define("client/pods/date/service", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ramda", "ramda-adjunct", "moment"], function (_exports, _defineProperty2, R, RA, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "dateFormat", 'YYYY-MM-DD');
      (0, _defineProperty2.default)(this, "nonWorkDays", [6, 7]);
    }

    // isoWeekdays (1=mon, 7=sun). default non-working days are sat/sun
    // @NOTE: mm is never JS mm. its js mm + 1
    // eg. js for feb = 1. but here mm would be 2
    getWeekdays(startingFrom) {
      if (startingFrom === 'sunday') {
        return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      }

      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    } // utcOffset: 8,

    /**
     * return moment js
     * moment will check if string is an ISO8601 Date
     */


    getMoment(string, format) {
      // TODO: allow setting of utcOffset?
      // client will use devices time settings to generate date.
      // the problem is that if an offset is set, pikaday (datepicker) does not use the same offset
      // pikaday uses moment but always uses devices time settings
      // return moment(string, format).utcOffset(this.get('utcOffset'));
      if (string) {
        return moment(string, format);
      }

      return moment();
    }

    getLocaleData() {
      return moment.localeData();
    }

    getNowZ() {
      return this.getMoment().toISOString();
    }

    getToday() {
      return this.getMoment().startOf('day');
    }

    isBeforeToday(dateZ, format) {
      return this.getMoment(dateZ, format).isBefore(this.getToday());
    }

    isSameOrBefore(dateZ, format) {
      return this.getMoment(dateZ, format).isSameOrBefore(this.getToday());
    }
    /**
     * return the start of day at local time
     * (converted to Zulu Time)
     * @param {string} dateString (optional)
     * @param {string} format (optional) (eg. 'yyyy-mm-dd')
     * @return {string} Zulu Time in ISO 8601 format
     */


    getStartOfDayZ(dateString, format) {
      return this.getMoment(dateString, format).startOf('day').toISOString();
    }

    getEndfDayZ(dateString, format) {
      return this.getMoment(dateString, format).endOf('day').toISOString();
    }
    /**
     * get working next available working date
     * @param {moment|dateZ} - can be moment, dateZ or date string (will require format)
     * @param {integer} nonWorkDays - (optional) isoWeekday 1=monday, 7=sunday
     * @param {string} format - (optional) only required for date=string
     * @return {class} moment instance
     */


    getNextWorkDate(today, nonWorkDays, format) {
      const self = this;

      if (!today) {
        today = self.getMoment(today, format);
      }

      if (typeof nonWorkDays === 'string') {
        format = nonWorkDays;
      } // determines what are non working days


      nonWorkDays = nonWorkDays || self.get('nonWorkDays'); // if today is not a moment instance

      if (typeof today === 'string') {
        today = self.getMoment(today, format);
      }

      let tomorrow = today.add(1, 'days');
      let count = 1;

      while (count <= 7) {
        const isNonWorkDay = self._isNonWorkDay(tomorrow.isoWeekday(), nonWorkDays);

        if (isNonWorkDay) {
          tomorrow = tomorrow.add(1, 'days');
          count++;
        } else {
          count = 8;
        }
      }

      return tomorrow;
    }

    getTomorrow(today, format) {
      const self = this;

      if (!today) {
        today = self.getMoment(today, format);
      }

      if (typeof today === 'string') {
        today = self.getMoment(today, format);
      }

      return today.add(1, 'days');
    }

    getTomorrowZ(today, format) {
      const tomorrow = this.getTomorrow(today, format);
      return tomorrow.toISOString();
    }

    getMM(mm) {
      if (parseInt(mm) < 10) {
        return `0${mm}`;
      }

      return mm;
    }
    /**
     * get working next available working date
     * @param {moment|dateZ} - can be moment, dateZ or date string (will require format)
     * @param {integer} nonWorkDays - (optional) isoWeekday 1=monday, 7=sunday
     * @param {string} format - (optional) only required for date=string
     * @return {string} Zulu Time in ISO 8601 format
     */


    getNextWorkDateZ(today, nonWorkDays, format) {
      return this.getNextWorkDate(today, nonWorkDays, format).toISOString();
    }
    /**
     * check if day is a nonWorkingDay
     * @param  {integer}  day         isoWeekday (1=mon, 7=sun)
     * @param  {array}  nonWorkDays   array of nonWorkDays
     * @return {Boolean}
     */


    _isNonWorkDay(day, nonWorkDays) {
      nonWorkDays = nonWorkDays || this.nonWorkDays;

      if (nonWorkDays.indexOf(day) > -1) {
        // day is a nonWorkingDay
        return true;
      }

      return false;
    }

    isEqualWeekday(date, day) {
      if (typeof date === 'string') {
        date = this.getMoment(date);
      }

      if (date.isoWeekday() === day) {
        return true;
      }

      return false;
    }

    getMonthEnd(yyyy, mm) {
      // @NOTE: this mm is not js mm. its js mm + 1
      // eg. js for feb = 1. but here mm would be 2
      let current;

      if (yyyy && mm) {
        current = this.getMoment(`${yyyy}-${mm}-01`, this.dateFormat);
      } else if (yyyy) {
        current = this.getMoment(yyyy, this.dateFormat);
      } else {
        current = this.getToday();
      }

      return current.endOf('month');
    }

    getMonthStart(yyyy, mm) {
      let current;

      if (yyyy && mm) {
        current = this.getMoment(`${yyyy}-${mm}-01`, this.dateFormat);
      } else if (yyyy) {
        current = this.getMoment(yyyy, this.dateFormat);
      } else {
        current = this.getToday();
      }

      return current.startOf('month');
    }

    getYearEnd(yyyy, mm) {
      let current;

      if (yyyy && mm) {
        current = this.getMoment(`${yyyy}-${mm}-01`, this.dateFormat);
      } else if (yyyy) {
        current = this.getMoment(yyyy, this.dateFormat);
      } else {
        current = this.getToday();
      }

      return current.endOf('year');
    }

    getYearStart(yyyy, mm) {
      let current;

      if (yyyy && mm) {
        current = this.getMoment(`${yyyy}-${mm}-01`, this.dateFormat);
      } else if (yyyy) {
        current = this.getMoment(yyyy, this.dateFormat);
      } else {
        current = this.getToday();
      }

      return current.startOf('year');
    }

    getLocale() {
      return this.intl.get('locale')[0];
    }
    /**
     * get correct date format based on locale
     * @param  {string|date object} date - if string is passed, will convert to date object
     * @return {string} formatted date string
     */


    formatDate(date, format) {
      // uses moment to parse date into date object,
      // then use moment to show date using dateFormat
      if (!date) {
        return '';
      }

      if (typeof date === 'string') {
        date = this.getMoment(date);
      } // display date using format
      // WHY NOT using ember-intl:
      //  ember intl will display date for current locale
      //  BUT we cannot know which format it used.
      //  ie. if locale is en-us it will display date using 'mm/dd/yyyy',
      //  but we cannot get this locale date string in the code to parse dates.
      //  intl only allows u to specify how year, date and month
      //  is to be displayed but not the order in which they appear which is useless
      //  when you need to parse the date.
      // Reason for needing to parse date: when the user manually types in a date string
      //  in the input (instead of picking from calendar) it needs to be parsed
      //  then converted to dateZ.


      format = format || this.dateFormat;
      return date.format(format); // return this.get('intl').formatDate(date, {
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit'
      // });
    }

    formatDateForUrl(date) {
      if (typeof date === 'string') {
        date = this.getMoment(date, this.dateFormat);
      }

      return date.format('YYYY-MM-DD');
    }

    getAllDatesForMonth(date) {
      if (!date) {
        date = this.getToday();
      }

      if (typeof date === 'string') {
        date = this.getMoment(date);
      }

      const mm = date.month() + 1;
      const yyyy = date.year(); // get all days for month
      // const monthEnd = this.getMonthEnd(date)

      const daysInMonth = date.daysInMonth();
      return R.times(index => {
        const day = index + 1;
        return this.getMoment(`${yyyy}-${mm}-${day}`, 'YYYY-MM-DD');
      }, daysInMonth);
    }

    getDaysOfMonthForCalendar(date) {
      const dates = this.getAllDatesForMonth(date);
      const firstDayOfWeek = dates.get('firstObject').day();
      const lastDayOfWeek = dates.get('lastObject').day();
      const numberOfDaysInFirstWeek = 7 - firstDayOfWeek;
      const numberOfPreviousMonthDaysForFirstWeek = 7 - numberOfDaysInFirstWeek;
      const fillersForFirstWeek = R.times(() => R.clone({}), numberOfPreviousMonthDaysForFirstWeek);
      const numberOfDaysInLastWeek = lastDayOfWeek + 1;
      const numberOfPreviousMonthDaysForLastWeek = 7 - numberOfDaysInLastWeek;
      const fillersForLastWeek = R.times(() => R.clone({}), numberOfPreviousMonthDaysForLastWeek);
      const firstWeek = R.pipe(R.splitAt(numberOfDaysInFirstWeek), R.head, R.map(date => this.getPeriod(date)), R.prepend(fillersForFirstWeek), R.flatten)(dates);
      const lastWeek = R.pipe(R.splitAt(dates.length - numberOfDaysInLastWeek), R.last, R.map(date => this.getPeriod(date)), R.append(fillersForLastWeek), R.flatten)(dates);
      const byWeeks = R.pipe(R.drop(numberOfDaysInFirstWeek), R.dropLast(numberOfDaysInLastWeek), R.map(date => this.getPeriod(date)), R.splitEvery(7), R.prepend(firstWeek), R.append(lastWeek))(dates);
      return byWeeks;
    }
    /**
     * return array of past months
     * @param  {integer} number defaults to 12
     * @return {[type]}        [description]
     */


    getPastMonths(number, year) {
      number = number || 12;
      const today = this.getMoment();
      year = year || today.year();
      let month = today.month() + 1; // js month starts with 0 so need to add 1

      const pastMonths = [];

      while (number > 0) {
        if (month === 0) {
          month = 12; // decrease year

          year = year - 1;
        }

        const mm = month;
        const period = Ember.Object.create({
          name: `month${mm}`,
          // used for translation
          mm: mm,
          yyyy: year
        });
        pastMonths.push(period);
        month--;
        number--;
      }

      return pastMonths;
    }
    /**
     * return array of past years
     * @return {[type]}        [description]
     */


    getPastYears({
      number,
      year,
      futureYearsNumber = 0
    }) {
      const today = this.getMoment();
      year = year || today.year();
      number = number || 5;
      const pastYears = [];
      let futureYears = year + futureYearsNumber;

      while (futureYearsNumber > 0) {
        const period = Ember.Object.create({
          yyyy: futureYears
        });
        pastYears.push(period);
        futureYears--;
        futureYearsNumber--;
      }

      while (number > 0) {
        const period = Ember.Object.create({
          yyyy: year
        });
        pastYears.push(period);
        year--;
        number--;
      }

      return pastYears;
    }
    /**
     * return array of past days
     * @param  {integer} number defaults to 7
     * @param  {string} from (optional) - from date string (defaults to today)
     * @return {array} past days from date given
     */


    getPastDays(number, from) {
      const self = this;
      number = number || 7;
      from = this.getMoment(from).add(1, 'days'); // let date = today.date();

      const pastDays = [];

      while (number > 0) {
        const current = from.subtract(1, 'days');
        const mm = current.month() + 1;
        const period = Ember.Object.create({
          weekday: 'weekday' + current.isoWeekday(),
          dateZ: current.toISOString(),
          date: current.format(self.get('dateFormat')),
          day: current.isoWeekday(),
          dd: current.date(),
          mm: mm,
          yyyy: current.year(),
          q: current.quarter()
        });
        pastDays.push(period);
        number--;
      }

      return pastDays;
    }

    getPreviousPeriod(dateZ, format) {
      let current;

      if (!dateZ) {
        current = this.getToday();
      }

      if (typeof dateZ === 'string') {
        // if ISO format then no "format" is required
        // if other format then format must be supplied
        current = this.getMoment(dateZ, format);
      }

      if (!current) {
        current = dateZ;
      }

      const previous = current.subtract(1, 'months');
      return this.getPeriod(previous);
    }

    getNextPeriod(dateZ, format) {
      let current;

      if (!dateZ) {
        current = this.getToday();
      }

      if (typeof dateZ === 'string') {
        // if ISO format then no "format" is required
        // if other format then format must be supplied
        current = this.getMoment(dateZ, format);
      }

      if (!current) {
        current = dateZ;
      }

      const next = current.add(1, 'months');
      return this.getPeriod(next);
    }

    getPeriod(dateZ, format) {
      let current;

      if (!dateZ) {
        current = this.getToday();
      } else {
        if (typeof dateZ === 'string') {
          // if ISO format then no "format" is required
          // if other format then format must be supplied
          current = this.getMoment(dateZ, format);
        }
      }

      if (!current) {
        current = dateZ;
      } // @NOTE: date is the actual date for the current timezone.
      // eg. current timezone (UTC +8) may be 16th 0:00 am,
      // but dateZ will be 15th at 16:00 pm.


      const dd = current.date(); // @NOTE: js jan starts at 0
      // should +1 to month

      const mm = current.month() + 1;
      const yyyy = current.year();
      const quarter = current.quarter();
      return {
        weekday: 'weekday' + current.isoWeekday(),
        dateZ: current.toISOString(),
        date: current.format(this.dateFormat),
        dd: dd,
        mm: mm,
        yyyy: yyyy,
        q: quarter,
        monthEnd: this.getMonthEnd(yyyy, mm).format(this.dateFormat),
        monthEndZ: this.getMonthEnd(yyyy, mm).toISOString(),
        monthStart: this.getMonthStart(yyyy, mm).format(this.dateFormat),
        monthStartZ: this.getMonthStart(yyyy, mm).toISOString()
      };
    }

    getEvenInvoiceMonth(mm) {
      const currentDate = new Date();
      const currentMonth = mm || currentDate.getMonth() + 1;
      return RA.isOdd(currentMonth) ? currentMonth + 1 : currentMonth;
    }

    displayByMonth(period) {
      period = period || this.dateService.getPeriod();
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      return {
        dateStart,
        dateEnd
      };
    }

  }

  _exports.default = DateService;
});