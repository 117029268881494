define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/blanks-uploader/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = (_temp = class ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "eInvoicesService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "uploadUnusedBlanks", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "uploadedResults", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "wasUploaded", _descriptor7, this);
    }

    get blanksToUpload() {
      if (this.wasUploaded) {
        return R_.dotPath('uploadedResults.blanksStatus')(this) || [];
      }

      return R_.dotPath('args.track._data.blanksToUpload')(this) || [];
    }

    *uploadBlanksTask(selectedPeriod) {
      this.selectedPeriod = selectedPeriod;
      const mm = selectedPeriod.mm;
      const yyyy = this.args.yyyy;

      try {
        const results = yield this.eInvoicesService.uploadBlanksTask.perform({
          mm,
          yyyy,
          uploadUnusedBlanks: this.uploadUnusedBlanks
        });
        const data = R.propOr({}, 'data')(results);
        this.uploadedResults = {
          blanksStatus: data.blanksStatus || [],
          blanksToUpload: data.blanksToUpload || []
        };
        this.wasUploaded = true;
      } catch (err) {
        this.wasUploaded = true;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "eInvoicesService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadUnusedBlanks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadedResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "wasUploaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadBlanksTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "uploadBlanksTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent;
});