define("client/pods/components/websites/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K96T4g+o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"url\"]],[24,[\"query\"]]],null],false],[0,\" \"],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"channels/shop/shop-status\",null,[[\"model\"],[[24,[\"result\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/search-results/template.hbs"
    }
  });

  _exports.default = _default;
});