define("client/pods/components/settings/tab-container/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'settings',
    query: '',

    init() {
      this.crud.addLists(this, ['taxes']);

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchAndDisplayTask.perform();
    },

    fetchAndDisplayTask: (0, _emberConcurrency.task)(function* () {
      const model = yield this.findTask.perform();
      this.display(model);
    }),

    display(model, additionalOptions) {
      const subTabOptions = {
        component: 'settings/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    },

    actions: {
      display(model, additionalOptions) {
        this.display(model, additionalOptions);
      },

      edit(model, additionalOptions) {
        const tab = this.tab;
        const subTabOptions = {
          component: 'settings/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
      }

    }
  });

  _exports.default = _default;
});