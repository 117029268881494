define("client/pods/components/websites/panels-display/sub-panels-shop/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    filtersAdaptername: 'channels/shop/campaigns/filters',

    didInsertElement() {
      this._super(...arguments);

      const model = this.model;
      this.fetchShopTask.perform(model);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdaptername
      });
      this.set('filters', filters);
    },

    shopEditBtnIcon: Ember.computed('model._data.shopKey', function () {
      if (this.get('model._data.shopKey')) {
        return 'fas fa-pen';
      }

      return 'fas fa-plus';
    }),
    fetchShopTask: (0, _emberConcurrency.task)(function* (model) {
      const shopKey = model.getData('shopKey');

      if (shopKey) {
        const shop = yield this.findTask.perform({
          adapterName: 'channels/shop/campaigns',
          appendPath: `/${shopKey}`
        });
        this.setShopModel(shop);
      } else {
        this.unloadShopModel();
      }
    }),

    setShopModel(shop) {
      const tab = this.tab;
      tab.set('model', shop);
      this.set('shop', shop);
    },

    unloadShopModel() {
      this.set('shop', '');
      const tab = this.tab;
      tab.set('model', '');
    },

    actions: {
      loadShopSource(dirtyModel, shop) {
        dirtyModel.setData('shopKey', shop.getData('_key'));
      },

      unloadShopSource(dirtyModel) {
        dirtyModel.setData('shopKey', '');
      },

      fetchAndSetShopModel(onCloseDialogAction, model) {
        onCloseDialogAction();
        this.fetchShopTask.perform(model);
      },

      editShopSource(model) {
        const adapterName = 'websites';
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        this.set('dirtyModel', dirty);
      },

      cancelEditShopSource() {
        const dirty = this.dirtyModel;
        const adapterName = 'websites';
        this.cancelEditing({
          adapterName,
          dirty
        });
      }

    }
  });

  _exports.default = _default;
});