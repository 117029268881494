define("client/pods/components/contacts/panels-display/sub-panels-activity/component", ["exports", "ramda", "client/mixins/date", "client/mixins/crud", "client/mixins/doc-status"], function (_exports, R, _date, _crud, _docStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _docStatus.default, {
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const docType = this.get('tab.docType');
      this.set('docType', docType);
      this.crud.addLists(this, ['dispatch/lists/processes', 'documents/lists/types/sales', 'documents/lists/types/purchases']);
    },

    docTypesList: Ember.computed('docType', 'crud.lists.{documents-lists-types-sales.[],documents-lists-types-purchases.[]}', function () {
      const docType = this.docType;
      const docTypesList = this.crud.lists[`documents-lists-types-${docType}`] || [];
      return R.map(resourceData => {
        const docType = resourceData.getData('docType');
        const docName = resourceData.getData('docName');
        resourceData.setData('tabType', 'replaceSubTab');
        resourceData.setData('id', `${docType}-${docName}`);
        resourceData.setData('component', 'contacts/panels-display/sub-panels-activity/sub-panels-activity-doc');
        return resourceData;
      })(docTypesList);
    })
  });

  _exports.default = _default;
});