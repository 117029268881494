define("client/pods/users/lists/roles/internal/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'super',
        label: 'super'
      }, {
        value: 'power',
        label: 'power'
      }, {
        value: 'general',
        label: 'general'
      }, {
        value: 'accountant_view',
        label: 'accountant (view only)'
      }, {
        value: 'dispatch_view',
        label: 'dispatch (view only)'
      }, {
        value: 'external',
        label: 'external'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});