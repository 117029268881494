define("client/pods/components/tabs/tab-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WjsNX3Gg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"labelPrefix\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"tt\",[[24,[\"labelPrefix\"]]],null],false],[0,\":\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"tt\",[[24,[\"tabTitle\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"tab\",\"header\"]]],null,{\"statements\":[[0,\"    : \"],[1,[23,0,[\"tab\",\"header\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"allowClose\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"tab-header-close delete is-small\"],[3,\"action\",[[23,0,[]],\"closeTab\"]],[8],[9],[0,\"\\n\\n    \"],[2,\" <span>\\n      {{elements/element-pushbtn\\n        classNames='u-close-btn'\\n        tabindex=-1\\n        tab=tab\\n        onSubmit=(action 'closeTab')}}\\n    </span> \"],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/tabs/tab-header/template.hbs"
    }
  });

  _exports.default = _default;
});