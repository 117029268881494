define("client/pods/components/elements/print-content-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['print-content-container'],
    hasPrintBtn: true,

    didInsertElement() {
      this._super(...arguments);

      this.setPrintContainer();
    },

    willDestroyElement() {
      window.$('#print-container').html('');
    },

    setPrintContainer() {
      const html = this.$().find('.print-content:visible').html();
      window.$('#print-container').html(html);
    },

    actions: {
      printDialog() {
        this.setPrintContainer();
        window.print();
      }

    }
  });

  _exports.default = _default;
});