define("client/pods/components/files/files-gallery/gallery-item-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    hasError: false,
    onRemove: null,
    onSetDefault: null,
    defaultImage: null,
    confirmationOnRemove: true,
    removeConfirmationMsg: 'are you sure you want to delete image?',
    isHover: false,
    transform: '',
    allowModal: true,
    showModal: false,
    closeOnOverlayClick: true,
    // init () {
    //   this._super(...arguments)
    //   this.set('file', this.get('showPreviewModal'))
    // },
    isDefaultImage: Ember.computed('defaultImage', function () {
      if (this.get('file._data.uuid') === this.defaultImage) {
        return true;
      }

      return false;
    }),
    hasModalFooter: Ember.computed('onRemove', 'allowRemove', 'onSetAsDefaultImageTask', 'allowSetDefault', function () {
      const allowRemove = this.allowRemove;
      const onSetAsDefaultImageTask = this.onSetAsDefaultImageTask;
      const allowSetDefault = this.allowSetDefault;

      if (allowRemove || onSetAsDefaultImageTask && allowSetDefault) {
        return true;
      }

      return false;
    }),
    removeTask: (0, _emberConcurrency.task)(function* (file) {
      const msg = this.intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        this.set('hasError', false);
        const data = {
          uuid: file._data.uuid,
          resource: file._data.resource,
          resourceKey: file._data.resourceKey
        };

        try {
          yield this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
          this.set('showPreviewModal', false);

          if (this.onAfterRemove) {
            this.onAfterRemove(file);
          }
        } catch (err) {
          this.set('hasError', true);
        }
      }
    })
  });

  _exports.default = _default;
});