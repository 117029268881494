define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/product-schedules-dialog/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      this.setupInitSchedule();
    }

    setupInitSchedule() {
      const hasProductSchedules = this.args.model.getData('hasProductSchedules');
      const productSchedules = this.args.model.getData('productSchedules') || [];

      if (hasProductSchedules && _ramdaAdjunct.default.isNilOrEmpty(productSchedules)) {
        this.args.model.addProductSchedule();
      }
    }

    onSubmitAddProductSchedule() {
      this.args.model.addProductSchedule();
    }

    saveProductSchedules() {
      if (this.args.updateProductSchedule) {
        this.args.updateProductSchedule();
      }

      this.args.onCloseDialogAction();
    }

    onToggleSetHasProductSchedule() {
      this.setupInitSchedule();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitAddProductSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddProductSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveProductSchedules", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "saveProductSchedules"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSetHasProductSchedule", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetHasProductSchedule"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogComponent;
});