define("client/pods/components/documents/editor-main/editor-details/component", ["exports", "ramda", "client/mixins/date", "client/mixins/droppable", "client/mixins/settings", "client/mixins/crud", "client/mixins/errors", "ember-concurrency", "client/utils/nventor", "ramda-extension"], function (_exports, R, _date, _droppable, _settings, _crud, _errors, _emberConcurrency, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _droppable.default, _errors.default, _settings.default, {
    crud: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['documents__details-table-row'],
    classNameBindings: ['detail._data.isSet:documents__details-table-row--isSet', 'detail._data.isSetChild:documents__details-table-row--isSetChild', 'isLastChild:documents__details-table-row--isSetChild-last'],
    unroundedCurrency: 8,

    // number of decimal places to keep for unrounded amounts
    init() {
      this._super(...arguments);

      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }]
        }
      });
      this.set('productsFilters', productsFilters);
    },

    isLastChild: Ember.computed('detail._data.isSetChild', 'model.sorted.[]', function () {
      if (this.get('detail._data.isSetChild')) {
        const details = this.get('model.sorted');
        const index = this.get('detail._data.index');
        const childIndex = this.get('detail._data.childIndex');
        return R.pipe(R.filter(R.pathEq(['_data', 'index', index])), R.pluck('_data'), R.pluck('childIndex'), R.flatten, R.sort((a, b) => {
          return a - b;
        }), R.last, R.equals(childIndex))(details);
      }

      return false;
    }),
    isPriceReadonlyOrDisabled: Ember.computed('isReadonlyOrDisabled', 'isQuickLinkDoc', function () {
      if (this.isQuickLinkDoc) {
        return false;
      }

      return this.isReadonlyOrDisabled;
    }),
    isReadonlyOrDisabled: Ember.computed('model._data.detailsLocked', 'detail._data.isSetChild', 'isReadonly', 'isSplitting', 'isMerging', 'isQuickLinkDoc', function () {
      if (this.isQuickLinkDoc) {
        return true;
      } // if (this.model._data.detailsLocked) {
      //   return true
      // }


      if (this.isSplitting) {
        return true;
      }

      if (this.isMerging) {
        return true;
      }

      if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }

      return this.isReadonly;
    }),
    isReadonlyOrDisabledSplitQty: Ember.computed('model._data.detailsLocked', 'detail._data.isSetChild', 'isReadonly', 'isSplitting', function () {
      if (this.isQuickLinkDoc) {
        const originalRemainingQty = parseFloat(this.detail.originalRemainingQty) || 0;

        if (originalRemainingQty <= 0) {
          return true;
        }

        if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
          return true;
        }

        return false;
      }

      if (this.isSplitting) {
        var _this$detail, _this$detail$_data;

        if ((_this$detail = this.detail) !== null && _this$detail !== void 0 && (_this$detail$_data = _this$detail._data) !== null && _this$detail$_data !== void 0 && _this$detail$_data.isSetChild) {
          return true;
        }

        return false;
      }

      if (this.model._data.detailsLocked) {
        return true;
      }

      if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }

      return this.isReadonly;
    }),
    isDisableItem: Ember.computed('isReadonlyOrDisabled', 'detail._data.isSetChild', 'isReadonly', function () {
      const isReadonlyOrDisabled = this.isReadonlyOrDisabled;

      if (isReadonlyOrDisabled) {
        return true;
      }

      if (this.get('detail._data.isSet') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }

      return false;
    }),
    isSales: Ember.computed('docType', function () {
      if (this.docType === 'sales') {
        return true;
      }

      return false;
    }),
    isPurchases: Ember.computed('docType', function () {
      if (this.docType === 'purchases') {
        return true;
      }

      return false;
    }),

    /**
     * returns true if discount is a %
     * @type {Boolean}
     */
    isDiscountRate: Ember.computed('detail._data.discountType', function () {
      const detail = this.detail;
      const discType = detail.getData('discountType');

      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }

      return false;
    }),

    /**
     * search for links products to link from other documents
     */
    searchForLinksTask: (0, _emberConcurrency.task)(function* (query = '') {
      const adapterName = 'documents/data/links';
      this.set('linksList', []);
      const model = this.model;
      const contact = model.getData('contact');
      const contactKey = model.getData('contactKey') || '';

      if (!Ember.isEmpty(contact)) {
        const docType = this.docType;
        const docName = this.docName;
        const filters = this.search.setupFilters({
          adapterName: 'filters',
          defaultFiltersData: {
            query: query,
            contact: contact,
            contactKey: contactKey
          }
        }); // do not cache link lists

        const options = {
          cache: false
        };
        const results = yield this.searchRecordsTask.perform({
          adapterName,
          filters,
          options,
          appendPath: `/${docType}/${docName}`,
          resultsProperty: 'linksList'
        });
        return results;
      }
    }),

    /**
     * search product
     */
    searchProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const filters = this.productsFilters; // filters.setData('query', query)

        const results = yield this.searchInputTask.perform({
          adapterName,
          filters
        }, query);
        return results;
      }
    }),

    /**
     * triggered when product is selected
     */
    loadProductTask: (0, _emberConcurrency.task)(function* (product) {
      const model = this.model;
      this.set('product', product);
      const itemKey = product.getData('_key');
      const productsService = this.productsService;
      const {
        preferredHistoryData
      } = yield productsService.get('fetchDocHistoryTask').perform(model, 'contact', product, itemKey);
      const taxRate = this.productsService.getTaxRate(model.getData('docType'), R_.dotPath('crud.lists.taxes')(this), product);
      const detail = this.detail;
      model.loadProduct(taxRate, detail, product, preferredHistoryData);
      this.onAddNewDetail();
    }),
    mergeWithDetails: Ember.computed('isMerging', 'toMergeWithModels.[]', function () {
      if (this.isMerging) {
        const model = this.model;
        const detailData = this.get('detail._data');
        const toMergeWithModels = this.toMergeWithModels || [];
        const props = model.get('mergeDetailsProps');
        const toMergeWithDetails = R.pipe(R.pluck('_data'), R.pluck('details'), R.flatten)(toMergeWithModels);
        const a = R.filter(toMergeWithDetail => {
          return _nventor.default.allEqProps(props, detailData, toMergeWithDetail._data);
        }, toMergeWithDetails);
        return a;
      }
    }),
    // common actions (can be overridden from each document panel)
    actions: {
      searchForLinks(e, value) {
        this.searchForLinks(e, value);
      },

      preLoadLinkedDetailFromDropdown(linkFromDetail) {
        const detail = this.detail;
        return this.onPreLoadLinkedDetails(linkFromDetail, detail);
      },

      addAndLoadProduct(onCloseDialogAction, detail, product) {
        onCloseDialogAction();
        detail.setData('itemKey', product.getData('_key'));
        detail.setData('itemCode', product.getData('code'));
        this.loadProductTask.perform(product);
      },

      cancelAddProduct(onCloseDialogAction) {
        onCloseDialogAction();
      },

      setCommissionBaseCostAndOnSumTotal() {
        // const commissionBaseCostFinal = this.detail.getData('commissionBaseCostFinal')
        // this.detail.setData('commissionBaseCost', commissionBaseCostFinal)
        this.onSumTotals();
      },

      toggleDetailDiscountType(item) {
        const discType = item.getData('value');
        const detail = this.detail;

        if (discType === this.get('constants.discountTypes.rate')) {
          const currentDiscValue = detail.getData('discountAmount') || detail.getData('discountRate');
          detail.setData('discountRate', currentDiscValue);
          detail.setData('discountAmount', '');
        } else {
          const currentDiscValue = detail.getData('discountRate') || detail.getData('discountAmount');
          detail.setData('discountAmount', currentDiscValue);
          detail.setData('discountRate', '');
        }

        this.onSumTotals();
      }

    }
  });

  _exports.default = _default;
});