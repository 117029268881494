define("client/pods/channels/shop/shipping-zones/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        zoneId: '',
        zoneType: _constants.default.shippingFeeZoneType.single,
        name: '',
        isSplit: false,
        isDefault: false,
        isLabelOnly: false,
        priorityZone: '',
        productTags: [],
        denyProductTags: [],
        shopPaymentMethods: [],
        shopPaymentMethodsDefault: '',
        supplierKey: '',
        supplierLabel: '',
        transporterKey: '',
        transporterLabel: '',
        transporterAccount: '',
        transporterAccountNumber: '',
        transporterExtension: '',
        additionalFreeShippingRule: {},
        countries: [],
        rates: [],
        surcharges: [],
        hasSurchargeWhenFreeShipping: false
      };
    },

    populate(data, attrs) {
      data.countries = this._transformCountries(data);
      data.rates = this._transformRates(data);
      data.surcharges = this._transformSurcharges(data);

      this._super(data, attrs);
    },

    _transformCountries(data) {
      const countries = R.propOr([], 'countries')(data);
      return R.map(country => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/countries',
          data: country
        });
      })(countries);
    },

    _transformRates(data) {
      const rates = R.propOr([], 'rates')(data);
      return R.map(rate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/rates',
          data: rate
        });
      })(rates);
    },

    _transformSurcharges(data) {
      const surcharges = R.propOr([], 'surcharges')(data);
      return R.map(surcharge => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/surcharges',
          data: surcharge
        });
      })(surcharges);
    },

    addSurcharge(data = {}) {
      const surcharges = this.getData('surcharges') || [];
      const surchargeModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/surcharges',
        data
      });
      surcharges.pushObject(surchargeModel);
      this.setData('surcharges', surcharges);
    },

    clearIncomplete() {
      this._clearIncomplete('rates');

      this.set('updated', Math.random());
    },

    isLastRateIndex(rate) {
      const rates = this.getData('rates') || [];
      const rateIndex = rates.indexOf(rate);

      if (rateIndex === rates.length - 1) {
        return true;
      }

      return false;
    },

    updateMinRates() {
      const rates = this.getData('rates') || [];
      let previousRate;
      const newRates = R.map(rate => {
        let previousMax = 0;

        if (previousRate) {
          previousMax = previousRate.getData('max');
        }

        const currentMin = previousMax + 1;
        rate.setData('min', currentMin);
        let currentMax = parseFloat(rate.getData('max')) || 0;

        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }

          rate.setData('max', currentMax);
        }

        previousRate = rate;
        return rate;
      })(rates);
      this.setData('rates', newRates);
    },

    newBlankRate(rate = false, rateData = {}) {
      // check if current rate is the last one
      if (this.isLastRateIndex(rate)) {
        const hasRequiredData = rate.hasRequiredData();

        if (hasRequiredData) {
          this.newRate(rateData);
        }
      }
    },

    newRate(rateData = {}) {
      const rateModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/rates',
        data: rateData
      });
      this.addRate(rateModel);
    },

    addRate(rate) {
      const rates = this.getData('rates') || [];
      rates.pushObject(rate);
      this.setData('rates', rates);
    },

    removeRate(rate) {
      const rates = this.getData('rates') || [];
      const isLast = this.isLastRateIndex(rate);
      rates.removeObject(rate);

      if (isLast) {
        this.newRate();
      } else {
        this.setData('rates', rates);
      }

      return rate;
    },

    newCountry(countryData = {}) {
      return this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/countries',
        data: countryData
      });
    },

    addCountry(country) {
      const countries = this.getData('countries') || [];
      countries.pushObject(country);
      this.setData('countries', countries);
    },

    removeCountry(country) {
      const countries = this.getData('countries') || [];
      countries.removeObject(country);
      this.setData('countries', countries);
      return country;
    }

  });

  _exports.default = _default;
});