define("client/pods/components/forms/form-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TMOGgyx6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"form-cell \",[22,\"hasErrorClassNames\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"customBlock\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"forms/form-label\",null,[[\"label\",\"isRequired\",\"translate\"],[[24,[\"label\"]],[24,[\"isRequired\"]],[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[22,\"value\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"context\",\"tab\",\"label\"],[[24,[\"errors\"]],[24,[\"context\"]],[24,[\"tab\"]],[24,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-cell/template.hbs"
    }
  });

  _exports.default = _default;
});