define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-analytics/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/reports/filters', 'taxMethods/filters']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),

    didInsertElement() {
      this._super(...arguments);

      const model = this.model;
      const sourceKey = model.getData('_key');
      this.fetchReportTask.perform({
        sourceKey
      });
    },

    fetchReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const params = {
        sourceKey,
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'contact', 'item', 'itemSets', 'trackingCodes', 'paymentMethodKey', 'status']
      };
      const report = yield this.server.call('GET', 'api/protected/reports/campaigns/', params);
      this.set('report', report.data);
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});