define("client/pods/documents/lists/import/dispatched/specs/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    async findAll() {
      return [{
        headerValue: '',
        value: '',
        label: 'none'
      }, {
        headerValue: '收貨人',
        value: 'contact',
        label: 'contact',
        required: true
      }, {
        headerValue: '收貨人電話',
        value: 'telephone',
        label: 'telephone',
        required: true
      }, {
        headerValue: '收貨人地址',
        value: 'address',
        label: 'address',
        required: true
      }, {
        headerValue: '託運單號',
        value: 'waybill',
        label: 'waybill',
        required: true
      }, {
        headerValue: '參照編號',
        value: 'ref',
        prop: 'ref',
        label: 'ref'
      }, {
        headerValue: '#',
        value: 'docNo',
        prop: 'docNo',
        label: '#',
        translate: false
      }, {
        headerValue: '件數',
        value: 'packages',
        label: 'packages' // }, {
        //   headerValue: '託運單號',
        //   value: 'dispatchDateZ',
        //   label: 'dispatchDateZ'

      }];
    }

  });

  _exports.default = _default;
});