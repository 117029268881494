define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "wasSaved", _descriptor3, this);
    }

    get totalConflicts() {
      const conflictsList = this.args.conflict.getData('conflictsList') || [];
      return R.length(conflictsList);
    }

    *updateCampaignDetailBulkDiscountTask({
      model
    }) {
      Ember.set(this, 'wasSaved', false);
      const bulkDiscountId = model.getData('bulkDiscountId') || '';
      const hasBulkDiscountSchedules = model.getData('hasBulkDiscountSchedules') || false;

      if (hasBulkDiscountSchedules) {
        const bulkDiscountSchedules = model.getData('bulkDiscountSchedules') || [];
        model.setData('bulkDiscountSchedules', R.map(schedule => {
          const isActive = schedule.get('isActive');

          if (isActive) {
            schedule.setData('bulkDiscountId', bulkDiscountId);
          }

          return schedule;
        })(bulkDiscountSchedules));
      }

      try {
        const response = yield this.crud.replaceRecordTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath: '/bulk-discount-conflict',
          model,
          component: this
        });
        Ember.set(this, 'wasSaved', true);
        const conflictsList = response.getData('conflictsList') || [];
        R.forEach(conflict => {
          const conflictBulkDiscountId = conflict.getData('bulkDiscountId') || '';

          if (conflictBulkDiscountId === bulkDiscountId) {
            conflict.setData('isCurrent', true);
          } else {
            conflict.setData('isCurrent', false);
          }
        })(conflictsList);
      } catch (e) {
        Ember.set(this, 'wasSaved', false);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "wasSaved", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateCampaignDetailBulkDiscountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateCampaignDetailBulkDiscountTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent;
});