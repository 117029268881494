define("client/pods/components/modals/modal-dialog/modal-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['modal-dialog__header', 'u-no-print'],
    translate: true
  });

  _exports.default = _default;
});