define("client/pods/messages/users/model", ["exports", "ramda", "client/pods/base/model"], function (_exports, R, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        pageName: '',
        pageId: '',
        id: '',
        firstName: '',
        lastName: '',
        locale: '',
        timezone: 0,
        gender: '',
        name: '',
        profilePic: 'https://img.icons8.com/wired/2x/circled-user.png',
        timestampZ: '',
        unreadMessages: 0
      };
    },

    populate(data) {
      data = this.transformMessages(data);
      this.overwriteData(data);
    },

    transformMessages(data) {
      let lastTimestampZ = '';
      data.messages = R.pipe(R.propOr([], 'messages'), R.map(messageData => {
        const id = R.pathOr(false, ['_data', '_id'], messageData) || R.pathOr(false, ['_id'], messageData);

        if (!id) {
          return false;
        }

        if (messageData._data) {
          lastTimestampZ = messageData._data.timestampZ;
          return messageData;
        }

        lastTimestampZ = messageData.timestampZ;
        return this.dataManager.setAsRecord({
          adapterName: 'messages/messages',
          data: messageData
        });
      }), R.reject(R.equals(false)))(data);
      data.timestampZ = lastTimestampZ;
      return data;
    },

    setupMessages(messages = []) {
      const data = this.transformMessages({
        messages
      });
      this.setData('messages', data.messages);
      this.setData('timestampZ', data.timestampZ);
      this.setData('totalMessages', data.messages.length);
    },

    //
    // newMessageModel({ pageId, recipient, message }) {
    //   return this.dataManager.setAsRecord({
    //     adapterName: 'messages/messages',
    //     data: {
    //       pageId,
    //       sender: pageId,
    //       recipient,
    //       timestampZ: new Date(),
    //       fromUs: true,
    //       message: message
    //     }
    //   })
    // },
    resetNewMessagesCount() {
      const messagesArray = this.getData('messages');
      this.setData('totalMessages', messagesArray.length);
    },

    chatMessages: Ember.computed('synced', '_data.messages.[]', function () {
      const messages = this.getData('messages') || [];
      return R.sortWith([R.ascend(R.path(['_data', 'timestampZ']))])(messages);
    }),
    newMessagesCount: Ember.computed('synced', '_data.{messages.[],totalMessages,unreadMessagesCount}', function () {
      const messagesArray = this.getData('messages');
      const totalMessages = this.getData('totalMessages') || 0;
      const unreadMessages = this.getData('unreadMessages') || 0;
      const messageCount = messagesArray.length - totalMessages + unreadMessages;
      return messageCount;
    }),
    // fbsbx|line-scdn
    memberOf: Ember.computed('synced', '_data.profilePic', function () {
      // @TODO: rather add messageProvider to model from api
      const profilePic = this.getData('profilePic') || 'none';
      const isLineMember = R.test(/line-scdn/, profilePic);
      const isFacebookMember = R.test(/fbsbx/, profilePic);

      if (isLineMember) {
        return 'line';
      } else if (isFacebookMember) {
        return 'facebook';
      } else {
        return 'none';
      }
    })
  });

  _exports.default = _default;
});