define("client/pods/components/extensions/apps/tw/e-invoices/current-status/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ExtensionsAppsTwEInvoicesCurrentStatusComponent = (_dec = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, (_class = (_temp = class ExtensionsAppsTwEInvoicesCurrentStatusComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "eInvoicesService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "track", _descriptor3, this);
    }

    *setupTask() {
      this.track = yield this.eInvoicesService.fetchTrackNumbers.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "eInvoicesService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "track", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesCurrentStatusComponent;
});