define("client/pods/components/modals/modal-overlay/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    modalClassNames: '',
    closeOnOverlayClick: true,
    actions: {
      close() {
        if (this.closeOnOverlayClick) {
          this.set('toggle', false);

          if (this.onToggle) {
            this.onToggle();
          }

          if (this.onClose) {
            this.onClose();
          }
        }
      }

    }
  });

  _exports.default = _default;
});