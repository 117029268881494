define("client/pods/components/comments/comments-manager/display-item/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    classNames: ['comments__display-item'],
    username: '',
    isEditing: false,
    adapterName: 'comments',

    init() {
      this._super(...arguments);

      const tokenData = this.storage.get('tokenData');
      this.set('username', tokenData.username);
    },

    isOwner: Ember.computed('username', function () {
      const username = this.get('model._data.username');

      if (this.username === username) {
        return true;
      }

      return false;
    }) // actions: {
    //   edit () {
    //     this.set('isEditing', true)
    //   },
    //   cancel () {
    //     this.set('isEditing', false)
    //   }
    // }

  });

  _exports.default = _default;
});