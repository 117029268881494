define("client/pods/components/documents/payments-status-tag/component", ["exports", "client/mixins/doc-status", "client/mixins/date", "ember-concurrency", "ramda"], function (_exports, _docStatus, _date, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docStatus.default, {
    crud: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tagName: '',
    showDropdown: false,
    autoFetchLists: true,
    readonly: false,
    autoPosition: true,

    init() {
      this._super(...arguments);

      if (!this.dispatchProcesses && this.autoFetchLists) {
        this.crud.addLists(this, ['dispatch/lists/processes']);
      }
    },

    statusClassName: Ember.computed('status', function () {
      const status = this.status;

      if (status === 'approved') {
        return 'is-success';
      }

      if (status === 'refunded') {
        return 'is-danger';
      }

      return 'is-warning';
    }),
    virtualAccountStatusLabel: Ember.computed('model._data.paymentData.{status,paymentAmount}', function () {
      var _this$model, _this$model$_data, _this$model$_data$pay, _this$model2, _this$model2$_data, _this$model2$_data$pa;

      const status = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : (_this$model$_data$pay = _this$model$_data.paymentData) === null || _this$model$_data$pay === void 0 ? void 0 : _this$model$_data$pay.status;
      const statusLabel = this.intl.t(`atm_${status}`);
      const paymentAmount = ((_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$_data = _this$model2._data) === null || _this$model2$_data === void 0 ? void 0 : (_this$model2$_data$pa = _this$model2$_data.paymentData) === null || _this$model2$_data$pa === void 0 ? void 0 : _this$model2$_data$pa.paymentAmount) || '';

      if (paymentAmount) {
        if (status === 'approved') {
          return `${statusLabel}: $ ${this.intl.formatNumber(paymentAmount)}`;
        } else {
          return `${this.intl.t('paid')}: $ ${this.intl.formatNumber(paymentAmount)} (${statusLabel})`;
        }
      }

      return statusLabel;
    }),
    virtualAccountStatusClassName: Ember.computed('model._data.paymentData.status', function () {
      var _this$model3, _this$model3$_data, _this$model3$_data$pa;

      const status = (_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : (_this$model3$_data = _this$model3._data) === null || _this$model3$_data === void 0 ? void 0 : (_this$model3$_data$pa = _this$model3$_data.paymentData) === null || _this$model3$_data$pa === void 0 ? void 0 : _this$model3$_data$pa.status;

      if (status === 'approved') {
        return 'is-success';
      }

      if (status === 'attentionRequired') {
        return 'is-danger';
      }

      return 'is-warning';
    }),
    virtualAccountIsIncorrectAmountPaidAndAttentionRequired: Ember.computed('model._data.paymentData.{status,paymentAmount,paymentDifference}', function () {
      var _this$model4, _this$model4$_data, _this$model4$_data$pa, _this$model5, _this$model5$_data, _this$model5$_data$pa, _this$model6, _this$model6$_data;

      const paymentDifference = parseInt((_this$model4 = this.model) === null || _this$model4 === void 0 ? void 0 : (_this$model4$_data = _this$model4._data) === null || _this$model4$_data === void 0 ? void 0 : (_this$model4$_data$pa = _this$model4$_data.paymentData) === null || _this$model4$_data$pa === void 0 ? void 0 : _this$model4$_data$pa.paymentDifference) || 0;
      const paymentAmount = parseInt((_this$model5 = this.model) === null || _this$model5 === void 0 ? void 0 : (_this$model5$_data = _this$model5._data) === null || _this$model5$_data === void 0 ? void 0 : (_this$model5$_data$pa = _this$model5$_data.paymentData) === null || _this$model5$_data$pa === void 0 ? void 0 : _this$model5$_data$pa.paymentAmount) || 0;

      if (((_this$model6 = this.model) === null || _this$model6 === void 0 ? void 0 : (_this$model6$_data = _this$model6._data) === null || _this$model6$_data === void 0 ? void 0 : _this$model6$_data.paymentData.status) === 'attentionRequired' && paymentAmount > 0 && paymentDifference !== 0) {
        return true;
      }

      return false;
    }),
    virtualAccountIsExpiredAndAttenionRequired: Ember.computed('model._data.paymentData.{status,paymentAmount,paymentDifference}', function () {
      var _this$model7, _this$model7$_data, _this$model7$_data$pa, _this$model8, _this$model8$_data, _this$model8$_data$pa, _this$model9, _this$model9$_data;

      const paymentDifference = parseInt((_this$model7 = this.model) === null || _this$model7 === void 0 ? void 0 : (_this$model7$_data = _this$model7._data) === null || _this$model7$_data === void 0 ? void 0 : (_this$model7$_data$pa = _this$model7$_data.paymentData) === null || _this$model7$_data$pa === void 0 ? void 0 : _this$model7$_data$pa.paymentDifference) || 0;
      const paymentAmount = parseInt((_this$model8 = this.model) === null || _this$model8 === void 0 ? void 0 : (_this$model8$_data = _this$model8._data) === null || _this$model8$_data === void 0 ? void 0 : (_this$model8$_data$pa = _this$model8$_data.paymentData) === null || _this$model8$_data$pa === void 0 ? void 0 : _this$model8$_data$pa.paymentAmount) || 0;

      if (((_this$model9 = this.model) === null || _this$model9 === void 0 ? void 0 : (_this$model9$_data = _this$model9._data) === null || _this$model9$_data === void 0 ? void 0 : _this$model9$_data.paymentData.status) === 'attentionRequired' && paymentAmount > 0 && paymentDifference === 0) {
        return true;
      }

      return false;
    }),
    isDropdownVisible: Ember.computed('showDropdown', 'isBtn', function () {
      // const list = this.get('list') || []
      if (!this.isBtn) {
        return false;
      }

      if (this.readonly === false && this.showDropdown) {
        // if (this.get('readonly') === false && this.get('showDropdown') && list.length > 0) {
        return true;
      }

      return false;
    }),
    translatedDispatchProcess: Ember.computed('model._data.dispatchProcessLabel', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // translatedDispatchProcess: computed('model._data.dispatchProcess', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // const model = this.get('model')
      const dispatchProcessLabel = this.get('model._data.dispatchProcessLabel');
      const dispatchProcessLabelTranslate = this.get('model._data.dispatchProcessLabelTranslate');

      if (dispatchProcessLabelTranslate) {
        return this.intl.t(dispatchProcessLabel);
      }

      return dispatchProcessLabel;
    }),
    label: Ember.computed('model._data.{status,dispatchProcess,dispatchDateZ}', 'translatedDispatchProcess', function () {
      // @TODO: move to model?
      const model = this.model;
      const status = model.getData('status');
      const intl = this.intl;
      let label = intl.t(status);

      if (this.get('model._data.isMerged')) {
        return intl.t('merged_document');
      }

      if (!this.get('model.hasDispatchProcess')) {
        return label;
      }

      const dispatchProcess = model.getData('dispatchProcess');
      const dispatchDateZ = model.getData('dispatchDateZ');

      if (status === this.get('constants.documentsStatus.final')) {
        label = this.translatedDispatchProcess;

        if (dispatchProcess === this.get('constants.dispatchProcess.dispatched')) {
          const date = this.formatDate(dispatchDateZ);
          label = `${label}: ${date}`;
        }
      }

      if (!label) {
        label = intl.t(this.get('constants.dispatchProcess.unprepared'));
      }

      return label;
    }),
    paymentTransactionOverDue: Ember.computed('model._data.{paymentCreditCardProvider,paymentCreditCardTransactionTimestampZ}', function () {
      const paymentCreditCardProvider = R.pathOr('', ['model', '_data', 'paymentCreditCardProvider'])(this);

      if (paymentCreditCardProvider === 'tapPay') {
        const overDueAfter = 20; //Minutes

        const now = this.dateService.getMoment().subtract(overDueAfter, 'm');
        const paymentCreditCardTransactionTimestampZ = R.pipe(R.pathOr('', ['model', '_data', 'paymentCreditCardTransactionTimestampZ']), time => this.dateService.getMoment(time))(this);
        return now.isAfter(paymentCreditCardTransactionTimestampZ);
      }

      return null;
    }),
    updatePaymentStatusTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, dirty, paymentStatus) {
      dirty.setData('paymentData.status', paymentStatus);
      yield this.crud.updateRecordTask.perform({
        adapterName: 'documents/sales/invoices',
        model: dirty,
        appendPath: '/virtual-account/payment-status'
      });
      onCloseDialogAction();
    }),
    actions: {
      hideDropdown() {
        this.set('showDropdown', false);
      },

      showDropdown() {
        this.set('showDropdown', true);
      },

      submit() {
        const onSubmit = this.onSubmit;

        if (onSubmit) {
          onSubmit(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});