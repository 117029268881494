define("client/pods/components/contacts/panels-display/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/crud", "client/utils/nventor", "ramda-extension"], function (_exports, R, RA, _crud, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),

    init() {
      this._super(...arguments);

      const model = this.get('tab.model');
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
      this.crud.addLists(this, ['taxes', 'statuses', 'contacts/lists/relationships', 'contacts/lists/tags', 'contacts/lists/panels', 'contacts/lists/allowed-payment-method', 'dispatch/lists/processes']);
    },

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, this.panelsList);

      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    panelsList: Ember.computed('model.{hasSales,hasPurchases}', 'crud.lists.contacts-lists-panels.[]', function () {
      let panels = [];
      let allowedPaymentMethodForStatement = [];
      const model = this.model;
      const hasSales = model.get('hasSales');
      const hasPurchases = model.get('hasPurchases');
      const salesPaymentMethodKey = model.getData('salesPaymentMethodKey');
      const purchasesPaymentMethodKey = model.getData('purchasesPaymentMethodKey'); // @TODO: Make easier to change in the future
      // @TODO: make statement for other payment method. ignore for now

      panels = R_.dotPath('crud.lists.contacts-lists-panels')(this) || [];
      allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);

      if (!R.isEmpty(panels)) {
        if (!hasSales) {
          panels = R.reject(R.pathEq(['_data', 'docType'], 'sales'))(panels);
        }

        if (!hasPurchases) {
          panels = R.reject(R.pathEq(['_data', 'docType'], 'purchases'))(panels);
        }

        if (!R.includes(salesPaymentMethodKey, allowedPaymentMethodForStatement)) {
          panels = R.reject(R.pathEq(['_data', 'label'], 'customer statements'))(panels);
        }

        if (!R.includes(purchasesPaymentMethodKey, allowedPaymentMethodForStatement)) {
          panels = R.reject(R.pathEq(['_data', 'label'], 'supplier reconciliations'))(panels);
        }
      }

      return panels;
    }),

    /**
     * dynamically determine classname for status tag.
     * green if active, otherwise gray for inactive
     */
    statusTagClassName: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');

      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    }),
    relatedComments: Ember.computed('model._data._key', function () {
      const related = [];
      const model = this.model;

      if (model) {
        // get related comments for contact
        const contactKey = model.getData('_key');

        if (contactKey) {
          related.push({
            param: 'contactKey',
            value: contactKey
          });
        }
      }

      return related;
    })
  });

  _exports.default = _default;
});