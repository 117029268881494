define("client/pods/components/img-banner/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let ImgBannerComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class ImgBannerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "imageUuid", _descriptor, this);
      (0, _defineProperty2.default)(this, "height", '128');
      (0, _defineProperty2.default)(this, "width", '128');
      this.trackImageUuid();
    }

    get transform() {
      return this.args.transform || '/-/preview/200x200';
    }

    get valuePath() {
      return (0, _ramda.pathOr)('', ['args', 'valuePath'])(this);
    }

    trackImageUuid() {
      const imageUuidPath = (0, _ramda.pathOr)('', ['args', 'imageUuidPath'])(this);
      const model = (0, _ramda.pathOr)(null, ['args', 'model'])(this);

      if (model) {
        this.imageUuid = model.get(imageUuidPath);
      }
    }

    onSubmit(onCloseDialogAction, options, uploadsModel) {
      const banner = (0, _ramda.pathOr)({}, ['_data'])(uploadsModel);
      this.args.model.set(this.valuePath, banner);
      this.trackImageUuid();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    removeBanner() {
      this.args.model.set(this.valuePath, null);
      this.trackImageUuid();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "imageUuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeBanner", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeBanner"), _class.prototype)), _class));
  _exports.default = ImgBannerComponent;
});