define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/product-schedules-dialog/product-schedule-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogProductScheduleRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogProductScheduleRowComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor4, this);
    }

    get statusClass() {
      const status = this.args.schedule.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    }

    checkDateConflict() {
      const productSchedules = this.args.model.getData('productSchedules') || [];
      const hasDateConflict = R.pipe(R.filter(R.propEq('isActive', true)), R.length, R.gt(R.__, 1))(productSchedules);

      if (hasDateConflict) {
        R.pipe(R.filter(R.propEq('isActive', true)), R.forEach(schedule => {
          schedule.set('hasDateConflict', true);
        }))(productSchedules);
        this.args.model.set('hasProductScheduleDateConflict', true);
      } else {
        R.forEach(schedule => {
          schedule.set('hasDateConflict', false);
        })(productSchedules);
        this.args.model.set('hasProductScheduleDateConflict', false);
      }
    }

    onSubmitRemoveProductSchedule(productSchedule) {
      const productSchedules = this.args.model.getData('productSchedules');
      productSchedules.removeObject(productSchedule);
      this.checkDateConflict();
    }

    onChangeSetActivation() {
      this.checkDateConflict();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitRemoveProductSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveProductSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChangeSetActivation", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorProductSchedulesDialogProductScheduleRowComponent;
});