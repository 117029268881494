define("client/pods/components/payments/virtual-accounts/status-tag/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let PaymentsVirtualAccountsStatusTagComponent = (_dec = Ember.inject.service, (_class = (_temp = class PaymentsVirtualAccountsStatusTagComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
    }

    get statusClassName() {
      const model = this.args.model;
      const status = model.getData('status');

      if (status === this.constants.paymentVirtualAccountStatus.attentionRequired) {
        return 'is-danger';
      }

      if (status === this.constants.paymentVirtualAccountStatus.pending) {
        return 'is-warning';
      }

      if (status === this.constants.paymentVirtualAccountStatus.approved) {
        return 'is-success';
      }

      return '';
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PaymentsVirtualAccountsStatusTagComponent;
});