define("client/pods/components/dispatch/panels-display/component", ["exports", "ramda", "client/mixins/date", "client/mixins/emails-dispatched", "client/mixins/doc-status", "ramda-extension"], function (_exports, R, _date, _emailsDispatched, _docStatus, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docStatus.default, _emailsDispatched.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    print: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    showPrintDiaolog: false,
    showMoreBtns: false,
    allSelected: false,
    isDispatchedBeforeToday: false,
    customEmailMessage: '',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const dispatchProcess = this.get('tab.dispatchProcess');
      this.set('dispatchProcess', dispatchProcess);
      this.set('sortBy', this.get('tab.sortBy'));
      const period = this.get('tab.period');
      this.set('period', period); // const results = this.get('tab.results')
      // this.set('results', results)

      if (period) {
        const isDispatched = this.get('tab.isDispatched');
        this.set('isDispatched', isDispatched);

        if (this.isBeforeToday(period.dateZ) && isDispatched) {
          this.set('isDispatchedBeforeToday', true);
        }
      }

      this.tabsManager.setHeader(this.tabParent, this.tabHeader);
    },

    tabHeader: Ember.computed('dispatchProcess._data.{name,translate}', 'period.{weekday,dateZ}', function () {
      var _this$dispatchProcess, _this$dispatchProcess2, _this$dispatchProcess3, _this$dispatchProcess4;

      if (this.period) {
        return `${this.intl.t('dispatched')} ${this.intl.t(this.period.weekday)} ${this.dateService.formatDate(this.period.dateZ)}`;
      }

      const name = (_this$dispatchProcess = this.dispatchProcess) === null || _this$dispatchProcess === void 0 ? void 0 : (_this$dispatchProcess2 = _this$dispatchProcess._data) === null || _this$dispatchProcess2 === void 0 ? void 0 : _this$dispatchProcess2.name;

      if (!name) {
        return '';
      }

      if ((_this$dispatchProcess3 = this.dispatchProcess) !== null && _this$dispatchProcess3 !== void 0 && (_this$dispatchProcess4 = _this$dispatchProcess3._data) !== null && _this$dispatchProcess4 !== void 0 && _this$dispatchProcess4.translate) {
        return this.intl.t(name);
      }

      return name;
    }),
    dispatchProcessCode: Ember.computed('dispatchProcess', function () {
      return this.get('dispatchProcess._data.code');
    }),
    // dispatchSortByListName: computed('dispatchProcess', function () {
    //   return 'settings__' + this.get('dispatchProcess._data.code')
    // }),
    sortedResults: Ember.computed('results.@each.{isSelected,showDetails}', 'sortBy.@each.{direction,param}', function () {
      let sortBy = this.sortBy || [];
      const results = this.results || [];

      if (!R.isEmpty(results)) {
        sortBy = R.map(sortByObject => {
          if (sortByObject.direction === 'asc') {
            return R.ascend(R.path(['model', '_data', sortByObject.param]));
          }

          return R.descend(R.path(['model', '_data', sortByObject.param]));
        }, sortBy);
        return R.sortWith(sortBy)(results);
      }

      return [];
    }),
    // sortedUnpaginatedResults: computed('unpaginatedResults.@each.{isSelected,showDetails}', 'sortBy.@each.{direction,param}', function () {
    //   let sortBy = this.get('sortBy') || []
    //   const unpaginatedResults = this.get('unpaginatedResults') || []
    //   if (!R.isEmpty(unpaginatedResults)) {
    //     sortBy = R.map(sortByObject => {
    //       if (sortByObject.direction === 'asc') {
    //         return R.ascend(R.path(['model', '_data', sortByObject.param]))
    //       }
    //       return R.descend(R.path(['model', '_data', sortByObject.param]))
    //     }, sortBy)
    //     return R.sortWith(sortBy)(unpaginatedResults)
    //   }
    //   return []
    // }),
    hasSelected: Ember.computed('results.@each.isSelected', 'dispatchProcess._data.code', function () {
      const currentDispatchProcess = this.get('dispatchProcess._data.code');
      const results = this.results || [];
      return R.filter(proxyModel => {
        const dispatchProcess = R.path(['model', '_data', 'dispatchProcess'], proxyModel) || 'unprepared';

        if (R.propEq('isSelected', true, proxyModel) && (R.equals(dispatchProcess, currentDispatchProcess) || currentDispatchProcess === 'all')) {
          return true;
        }

        return false;
      })(results);
    }),
    hasSelectedModels: Ember.computed('hasSelected.[]', function () {
      const hasSelected = this.hasSelected || [];
      return R.map(R.prop('model'), hasSelected);
    }),
    allowProcesses: Ember.computed('isDispatched', 'period.date', function () {
      const isDispatched = this.isDispatched;
      const dispatchDateZ = this.get('period.date');

      if (isDispatched && this.isBeforeToday(dispatchDateZ)) {
        return false;
      }

      return true;
    }),
    // printBadge: computed('searchResults.@each.isSelected', 'filterByValue', 'hasSelected.@each.isSelected', function () {
    //   const selected = this.get('hasSelected') || []
    //   if (selected.length > 0) {
    //     return selected.length
    //   }
    //   const searched = this.get('searchResults') || []
    //   const filterByValue = this.get('filterByValue')
    //   if (searched.length > 0 && filterByValue) {
    //     return searched.length
    //   }
    //   return ''
    // }),
    // @TODO: remove
    dispatchProcessesList: Ember.computed('crud.lists.dispatch-lists-processes-menu.[]', function () {
      const dispatchProcessesList = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const isDispatchedProcessModel = this.isDispatchedProcessModel;
      return R.concat(dispatchProcessesList, [isDispatchedProcessModel]);
    }),
    duplicateWaybills: Ember.computed('dashData.duplicates.waybills', function () {
      return this.get('dashData.duplicates.waybills') || {};
    }),
    duplicateWaybillsDocNos: Ember.computed('duplicateWaybills', function () {
      const duplicateData = this.duplicateWaybills || {};
      return this._getDuplicateDocNos(duplicateData);
    }),
    duplicateAddresses: Ember.computed('dashData.duplicates.addresses.values.[]', function () {
      return this.get('dashData.duplicates.addresses') || {};
    }),
    duplicateAddressesDocNos: Ember.computed('duplicateAddresses.values.[]', function () {
      const duplicateData = this.duplicateAddresses || {};
      return this._getDuplicateDocNos(duplicateData);
    }),

    _getDuplicateDocNos(duplicateData) {
      return R.pipe(R.propOr([], 'values'), R.pluck('docs'), R.flatten)(duplicateData);
    },

    // exportTransporterExtensionDataTask: task(function * (transporterExtension) {
    //   const allHctData = this.get('hctExportData')
    //   const today = this.formatDate(this.getToday(), 'YYYYMMDD')
    //   const specs = this.get('settings').getProp('tw_hct_export_instructions')
    //   const columns = R.pluck('column', specs)
    //   const props = R.pluck('prop', specs)
    //   const data = R.pipe(
    //     R.map(doc => {
    //       return R.map(prop => {
    //         if (prop === '_today_') {
    //           return today
    //         }
    //         return R.propOr('', prop, doc)
    //       }, props)
    //     }),
    //     R.values,
    //     R.concat([columns])
    //   )(allHctData)
    //   yield this.get('excel').xlsx({
    // worksheets: [{
    //   sheetName: '1',
    //   data
    // }],
    //     title: `新竹物流匯出範例-${today}`
    //   })
    // }).drop(),
    _filterByTransporters(filterTransporters, transporterExtension) {
      if (Ember.isEmpty(filterTransporters)) {
        return true;
      }

      if (filterTransporters.indexOf(transporterExtension) > -1) {
        return true;
      }

      return false;
    },

    _filterByDispatchStatus(filterDispatchStatus, dispatchStatus) {
      // no filter set, dont filter
      if (Ember.isEmpty(filterDispatchStatus)) {
        return true;
      } // match status


      if (dispatchStatus === filterDispatchStatus) {
        return true;
      }

      return false;
    },

    modelsList: Ember.computed('sortedResults.[]', function () {
      const results = this.sortedResults || [];
      return R.map(R.prop('model'), results);
    }),
    searchResults: Ember.computed('sortedResults.[]', 'filterByValue', function () {
      const results = this.sortedResults;
      let filterByValue = this.filterByValue;

      if (filterByValue) {
        filterByValue = filterByValue.toLowerCase();
        return R.filter(result => {
          const doc = result.model;
          const data = R.prop('_data', doc);
          const details = R.pipe(R.propOr([], 'details'), R.pluck('_data'), R.map(R.pipe(R.pick(['item', 'itemCode']), R.values)), R.flatten, R.map(value => value.toLowerCase()))(data);
          const values = R.pipe(R.pick(['telephone', 'email', 'contact', 'person', 'waybill', 'address', 'docNo', 'ref', 'transporterLabel', 'source']), R.values, R.concat(details))(data);
          const matches = R.filter(value => {
            if (R.is(String, value)) {
              value = value.toLowerCase();
              filterByValue.toLowerCase();
            }

            if (value.indexOf(filterByValue) > -1) {
              return true;
            }

            return false;
          })(values);

          if (matches.length > 0) {
            return true;
          }

          return false;
        }, results);
      }

      return results;
    }),
    toPrintList: Ember.computed('hasSelected.[]', 'unpaginatedResults.[]', function () {
      const hasSelected = this.hasSelected || [];
      let results = hasSelected;

      if (hasSelected.length === 0) {
        results = this.unpaginatedResults || [];
      }

      if (this.isDispatched) {
        results = R.filter(R.pathEq(['model', '_data', 'dispatchProcess'], this.get('constants.dispatchProcess.dispatched')), results);
      } // debugger
      // let lastSetId = ''
      // results = R.map(detail => {
      //   if (detail._data.setId && detail._data.setId !== lastSetId) {
      //     detail.set('isFirstSetChoiceItem', true)
      //     lastSetId = detail._data.setId
      //   }
      //   return detail
      // })(results)


      return results;
    }),
    toPrintModelsList: Ember.computed('toPrintList.[]', function () {
      const toPrintList = this.toPrintList || [];
      return R.map(R.prop('model'))(toPrintList);
    }),

    deselectAll() {
      this.set('allSelected', false);
      const allSelected = this.allSelected;
      const results = this.searchResults;
      let isSelected = false;

      if (allSelected) {
        isSelected = true;
      }

      results.forEach(result => {
        if (result.model._data.status === this.get('constants.documentsStatus.final')) {
          result.set('isSelected', isSelected);
        }
      });
    },

    actions: {
      filterByValue() {
        const value = this.searchValue;
        this.deselectAll();
        this.set('filterByValue', value);
        this.set('reFilter', Math.random());
      },

      selectAll(allSelected) {
        this.set('allSelected', allSelected);
        const results = this.searchResults;
        results.forEach(result => {
          if (result.model._data.status === this.get('constants.documentsStatus.final')) {
            result.set('isSelected', allSelected);
          }
        });
      },

      deselectAll() {
        this.set('allSelected', false);
        const results = this.searchResults;
        results.forEach(result => {
          if (result.model._data.status === this.get('constants.documentsStatus.final')) {
            result.set('isSelected', false);
          }
        });
      },

      clearSearch() {
        this.set('filterByValue', '');
        this.set('searchValue', '');
      },

      fetchUnpaginatedData() {
        const dispatchProcess = this.dispatchProcess;
        const period = this.period;
        this.onFetchUnpaginatedDispatchDataTask.perform({
          dispatchProcess,
          period
        });
      }

    }
  });

  _exports.default = _default;
});