define("client/pods/components/elements/accordian-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['accordian-dialog-container'],
    translate: true,
    isViewable: false,
    isModal: true
  });

  _exports.default = _default;
});