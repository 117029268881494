define("client/pods/components/users/users-editor/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    users: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    isNew: false,

    init() {
      this.crud.addLists(this, ['users/lists/types', 'users/lists/roles/internal', 'users/lists/roles/external', 'statuses']);

      this._super(...arguments);
    },

    notAllowDelete: Ember.computed('model._data._key', function () {
      const tokenData = this.storage.get('tokenData');
      const uid = tokenData.uid;
      const userKey = R.path(['model', '_data', '_key'])(this);

      if (uid === userKey) {
        return true;
      }

      const role = tokenData.role || '';

      if (R.toLower(role) === 'super') {
        return false;
      }

      return true;
    }),
    disableChangeRoles: Ember.computed('model._data.role', function () {
      const role = this.get('users').getProp('role');

      if (R.toLower(role) === 'super') {
        return false;
      }

      return true;
    }),
    actions: {
      toggleType() {
        const model = this.model;

        if (model.getData('type') === this.get('constants.userTypes.external')) {
          model.setData('username', '');
          model.setData('role', 'external');
        }
      }

    }
  });

  _exports.default = _default;
});