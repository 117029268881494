define("client/pods/dispatch/service", ["exports", "ramda", "ember-concurrency", "client/mixins/date"], function (_exports, R, _emberConcurrency, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_date.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    server: Ember.inject.service(),
    constants: Ember.inject.service(),
    intl: Ember.inject.service(),

    getAsDirty(model) {
      const docType = model.getData('docType');
      const docName = model.getData('docName');
      const adapterName = `documents/${docType}/${docName}`;
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      return dirty;
    },

    getDispatchProcesses({
      hasDrafts = false,
      hasAll = false,
      hasDispatched = false,
      results = [],
      defaultExportSheets = []
    } = {}) {
      let defaults = [];

      if (hasDrafts) {
        defaults = R.concat(defaults, [{
          _id: 'steps_draft',
          systemProcessId: 'steps_draft',
          isSystemProcess: true,
          isEditable: false,
          name: 'draft',
          code: 'draft',
          index: -3,
          defaultExportSheets,
          defaultExportCustomSheets: {},
          translate: true,
          isLocked: true,
          criterias: {
            status: 'draft'
          }
        }]);
      }

      defaults = R.concat(defaults, [{
        _id: 'steps_unprepared',
        systemProcessId: 'steps_unprepared',
        isSystemProcess: true,
        isEditable: true,
        name: 'unprepared',
        code: this.get('constants.dispatchProcess.unprepared'),
        index: 0,
        defaultExportSheets,
        defaultExportCustomSheets: {},
        translate: true,
        isLocked: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: ''
          }, {
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.unprepared')
          }]
        }
      }, {
        _id: 'steps_awaitingPayment',
        systemProcessId: 'steps_awaitingPayment',
        isSystemProcess: true,
        isEditable: true,
        name: 'awaiting payment',
        code: this.get('constants.dispatchProcess.awaitingPayment'),
        index: -2,
        defaultExportSheets,
        defaultExportCustomSheets: {},
        translate: true,
        isLocked: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.awaitingPayment')
          }]
        }
      }, {
        _id: 'steps_outOfStock',
        systemProcessId: 'steps_outOfStock',
        isSystemProcess: true,
        isEditable: true,
        index: -1,
        name: 'out of stock',
        code: this.get('constants.dispatchProcess.outOfStock'),
        status: 'active',
        defaultExportSheets,
        defaultExportCustomSheets: {},
        translate: true,
        isLocked: true,
        isActionProces: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.outOfStock')
          }]
        }
      }]);

      if (hasDispatched) {
        defaults = R.concat(defaults, [{
          _id: 'steps_dispatched',
          systemProcessId: 'steps_dispatched',
          isSystemProcess: true,
          isEditable: true,
          isDispatched: true,
          allowEInvoices: true,
          allowEInvoicesForPaymentMethods: [],
          name: 'dispatched',
          code: this.get('constants.dispatchProcess.dispatched'),
          index: 10000,
          noPanel: true,
          defaultExportSheets,
          defaultExportCustomSheets: {},
          translate: true,
          isLocked: true,
          criterias: {
            status: 'final',
            dispatchProcesses: [{
              criteria: 'equals',
              value: this.get('constants.dispatchProcess.dispatched')
            }]
          }
        }]);
      }

      defaults = R.map(defaultData => {
        const settingsData = R.find(R.propEq('systemProcessId', defaultData.systemProcessId))(results) || {};
        return R.pipe(R.omit(['translate', 'code', 'name', 'isEditable']), R.mergeRight(defaultData))(settingsData);
      })(defaults);
      const customResults = R.reject(R.propEq('isSystemProcess', true))(results);
      defaults = R.concat(defaults, customResults);

      if (hasAll) {
        defaults = R.concat(defaults, [{
          _id: 'steps_All',
          systemProcessId: 'steps_All',
          isSystemProcess: true,
          isEditable: false,
          name: 'all',
          code: 'all',
          index: 1000,
          defaultExportSheets,
          defaultExportCustomSheets: {},
          noPanel: true,
          translate: true,
          isLocked: true,
          criterias: {
            dispatchProcesses: [{
              criteria: 'notEquals',
              value: this.get('constants.dispatchProcess.dispatched')
            }]
          }
        }]);
      }

      defaults = R.sortWith([R.ascend(R.prop('index'))])(defaults);
      return defaults;
    },

    findDispatchProcess(dispatchProcesses, dispatchProcessCode) {
      dispatchProcesses = dispatchProcesses || [];

      if (R.isEmpty(dispatchProcesses)) {
        return false;
      }

      return dispatchProcesses.findBy('_data.code', dispatchProcessCode);
    },

    updateEntireDispatchProcessTask: (0, _emberConcurrency.task)(function* (filters, toDispatchProcess, toDispatchDateZ) {
      const toDispatchProcessCode = toDispatchProcess.getData('code');
      const toDispatchProcessLabel = toDispatchProcess.getData('name');
      const toDispatchProcessLabelTranslate = toDispatchProcess.getData('translate');
      const filtersData = filters.serialize();
      const payload = R.mergeRight({
        toDispatchProcess: toDispatchProcessCode,
        toDispatchDateZ,
        toDispatchProcessLabel,
        toDispatchProcessLabelTranslate
      }, filtersData);
      return yield this.server.call('PATCH', 'api/protected/documents/sales/dispatch/process/batch', payload);
    }),
    setDispatchProcessTask: (0, _emberConcurrency.task)(function* (model, dispatchProcess, dispatchDateZ) {
      // do not need dirty. always send as batch.
      // payload = {
      // dispatchDateZ,
      // dispatchProcess,
      // etc,
      // batch: [_key]
      // }
      if (R.is(Array, model)) {
        const models = model;
        const dispatchProcessCode = dispatchProcess.getData('code');
        const dispatchProcessLabel = dispatchProcess.getData('name');
        const dispatchProcessLabelTranslate = dispatchProcess.getData('translate');
        const batch = R.map(model => {
          return {
            docType: model.getData('docType'),
            docName: model.getData('docName'),
            _key: model.getData('_key'),
            transporterRegion: model.getData('transporterRegion'),
            transporterExtension: model.getData('transporterExtension'),
            waybill: model.getData('waybill')
          };
        }, models);
        const payload = {
          batch,
          toDispatchProcess: dispatchProcessCode,
          toDispatchDateZ: dispatchDateZ,
          toDispatchProcessLabel: dispatchProcessLabel,
          toDispatchProcessLabelTranslate: dispatchProcessLabelTranslate
        };
        return yield this.server.call('PATCH', 'api/protected/documents/sales/dispatch/process/batch', payload);
      } else {
        let confirmed = true;

        if (model.get('isDispatchedBeforeToday')) {
          const msg = this.intl.t('this may already have been dispatched on a previous day- are you sure you want to change its dispatch status');
          confirmed = window.confirm(msg);
        }

        if (confirmed) {
          const docType = model.getData('docType');
          const docName = model.getData('docName');
          const adapterName = `documents/${docType}/${docName}`;
          const dirty = this.getAsDirty(model);

          if (dispatchDateZ) {
            dirty.setData('dispatchDateZ', dispatchDateZ);
          }

          dirty.setData('dispatchProcess', dispatchProcess.getData('code'));
          dirty.setData('dispatchProcessLabel', dispatchProcess.getData('name'));
          dirty.setData('dispatchProcessLabelTranslate', dispatchProcess.getData('translate'));
          const appendPath = 'dispatch/process';
          const saved = yield this.dataManager.updateRecord({
            adapterName,
            appendPath,
            dirty
          });
          return [saved];
        }
      }
    }).enqueue().maxConcurrency(1) // NOTE: important, this cannot be more than 1 otherwise creating waybill numbers will have racing conditions

  });

  _exports.default = _default;
});