define("client/pods/components/helpers/info-tooltip/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  /**
   * When to use: when the label and content are together.
   */
  let HelpersInfoTooltip = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class HelpersInfoTooltip extends _component.default {
    // clickToClose = false
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "tagName", '');
      (0, _initializerDefineProperty2.default)(this, "clickToView", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "translate", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "hasContent", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isTooltipVisible", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "mouseLeft", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "translate", _descriptor6, this);

      if (this.args.translate == null) {
        this.translate = true;
      } else {
        this.translate = this.args.translate;
      }

      if (this.args.clickToView == null) {
        this.clickToView = true;
      } else {
        this.clickToView = this.args.clickToView;
      }

      if (this.args.hasContent == null) {
        this.hasContent = true;
      } else {
        this.hasContent = this.args.hasContent;
      }

      if (this.args.hasModalOverlay == null) {
        this.hasModalOverlay = true;
      } else {
        this.hasModalOverlay = this.args.hasModalOverlay;
      }

      if (!this.clickToView) {
        this.hasModalOverlay = false;
      }
    }

    handleMouseEnter(e) {
      if (!this.clickToView) {
        this.isTooltipVisible = true;
        this.mouseLeft = false;
      }
    }

    handleMouseLeave(e) {
      if (!this.clickToView) {
        this.mouseLeft = true;
        window.setTimeout(() => {
          if (this.mouseLeft) {
            this.isTooltipVisible = false;
          }
        }, 100);
      }
    }

    toggleIsVisible(e) {
      if (this.clickToView) {
        if (!this.isTooltipVisible) {
          this.isTooltipVisible = true;
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clickToView", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "translate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'translate'])(this);
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasContent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isTooltipVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mouseLeft", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "translate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleMouseEnter", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleMouseLeave", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleIsVisible", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsVisible"), _class.prototype)), _class));
  _exports.default = HelpersInfoTooltip;
});