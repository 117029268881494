define("client/pods/channels/shop/promo-codes/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        status: this.constants.status.active,
        type: this.constants.discountTypes.rate,
        usageLimit: 1
      };
    } // populate (data = {}) {
    //   this._super(...arguments)
    //   // data = this.transformShops(data)
    // },
    // allowedShops (data) {
    //   // transform
    //   data.allowedShops = R.pipe(
    //     R.propOr([], 'allowedShops'),
    //     R.map()
    //   )(data)
    //   return data
    // }


  });

  _exports.default = _default;
});