define("client/pods/guests/service", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    server: Ember.inject.service(),
    storage: Ember.inject.service(),
    host: _environment.default.APP.serverUrl,
    resource: '',

    setup(resource) {
      this.set('resource', resource);
    },

    call(method, path, data = {}) {
      const token = this.getToken();
      return this.server.call(method, path, data, token);
    },

    setToken(token) {
      return this.storage.setWithPrefix(this.resource, 'guestToken', token);
    },

    getToken() {
      return this.storage.getWithPrefix(this.resource, 'guestToken');
    },

    getTokenData() {
      const token = this.getToken();
      const tokenData = this.auth.parseToken(token);
      return tokenData;
    }

  });

  _exports.default = _default;
});