define("client/pods/components/documents/pending/panel-display-by-orders/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let DocumentsPendingPanelDisplayByOrders = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class DocumentsPendingPanelDisplayByOrders extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      const tab = this.args.tab;
      this.loadTabTask.perform();
    }

    *loadTabTask() {
      const filters = this.args.filters;
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'documents/data/pending',
        appendPath: `${this.args.docType}/${this.args.docName}`,
        filters
      });
      return results;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype)), _class));
  _exports.default = DocumentsPendingPanelDisplayByOrders;
});