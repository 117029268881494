define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['automations/when', 'automations/when/itemQty', 'automations/criterias', 'automations/thenActions', 'automations/thenSelectionTypes', 'channels/shop/campaigns/statuses', 'products/lists/tags']);
      this.set('productsList', []); // fetch automations for campaign

      this.fetchAutomationsCollectionTask.perform();
    },

    fetchAutomationsCollectionTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const key = model.getData('_key');
      const collectionModel = yield this.findTask.perform({
        adapterName: 'channels/shop/campaigns/automations/collection',
        appendPath: key
      });
      this.set('automationsCollection', collectionModel);
    }),
    // addAutomationModelTask: task(function * (automationModel) {
    //   const model = this.get('model')
    //   automationModel.setData('master', model.getData('_key'))
    //   return yield this.get('createRecordTask').perform({
    //     adapterName: 'channels/shop/campaigns/automations',
    //     model: automationModel,
    //     errorsProp: 'automationErrors'
    //   })
    // }),
    actions: {
      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      },

      // newAutomationModel () {
      //   const automationsCollection = this.get('automationsCollection')
      //   const index = automationsCollection.get('lastObject._data.index') || 0
      //   const newBlankAutomationModel = this.setupNewRecord({
      //     adapterName: 'channels/shop/campaigns/automations',
      //     data: {
      //       index: index + 1
      //     }
      //   })
      //   this.set('newBlankAutomationModel', newBlankAutomationModel)
      // },
      // editAutomation (automationModel) {
      //   const dirty = this.get('dataManager').getDirtyRecord('channels/shop/campaigns/automations', automationModel)
      //   return dirty
      // },
      async cancelEditAutomation(automationModel) {
        return this.dataManager.cancelDirty('channels/shop/campaigns/automations', automationModel);
      },

      async updateAutomation(automationModel) {
        return this.replaceRecordTask.perform({
          adapterName: 'channels/shop/campaigns/automations',
          model: automationModel,
          errorsProp: 'automationErrors'
        });
      },

      async removeAutomation(automationModel) {
        return this.removeRecordTask.perform({
          adapterName: 'channels/shop/campaigns/automations',
          model: automationModel
        });
      }

    }
  });

  _exports.default = _default;
});