define("client/pods/components/emails/panels-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b7VDhs+D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__tab-header\"],[8],[0,\"\\n\\n    \"],[1,[28,\"elements/element-input\",null,[[\"placeholder\",\"autofocus\",\"value\",\"isRunning\",\"onSubmit\",\"onInput\"],[\"search\",true,[24,[\"filters\",\"_data\",\"query\"]],[24,[\"searchInputTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"searchInputTask\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]],[24,[\"query\"]]],null],[28,\"perform\",[[24,[\"searchInputTask\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]]],null]]]],false],[0,\"\\n\\n\"],[0,\"\\n    \"],[1,[28,\"emails/search-filters\",null,[[\"adapterName\",\"query\",\"filters\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\",\"_data\",\"query\"]],[24,[\"filters\"]],[24,[\"results\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content u-container\"],[8],[0,\"\\n    \"],[1,[28,\"layouts/table-view\",null,[[\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onPaginationTask\",\"onReloadSearchRecordsTask\",\"onEdit\",\"tableViewOptions\"],[\"emails/table-view/detail-row\",[24,[\"results\"]],[24,[\"filters\"]],[24,[\"searchAdvancedTask\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"reloadSearchRecordsTask\"]],[24,[\"onEdit\"]],[24,[\"tableViewOptions\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/panels-activity/template.hbs"
    }
  });

  _exports.default = _default;
});