define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oI07bS6D",
    "block": "{\"symbols\":[\"@isReadonly\",\"@onEditPanel\",\"@onDisplayItem\",\"@onEditItem\",\"@onAddItem\",\"@model\",\"@tab\",\"@errorMsg\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@onAssignSalesPerson\",\"@duplicateRecordTask\",\"@onCancelDuplicateRecord\",\"@onPrepareDuplicateRecord\",\"@allowDuplicating\",\"@dirty\",\"@copyErrors\"],\"statements\":[[1,[28,\"channels/shop/campaigns/campaigns-editor/products-editor\",null,[[\"tab\",\"model\",\"onAddItem\",\"onEditItem\",\"onDisplayItem\",\"onEditPanel\",\"isReadonly\",\"filters\",\"isBulkEditing\",\"fetchCampaignDetailsTask\"],[[23,7,[]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]],[23,0,[\"filters\"]],[23,0,[\"isBulkEditing\"]],[23,0,[\"fetchCampaignDetailsTask\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",null,[[\"tab\",\"hasEditBtn\",\"hideEditBtn\",\"copyErrors\",\"model\",\"dirty\",\"onEditPanel\",\"allowDuplicating\",\"onPrepareDuplicateRecord\",\"onCancelDuplicateRecord\",\"duplicateRecordTask\",\"onAssignSalesPerson\",\"onUnassignSalesPerson\",\"onToggleCommissionMethod\",\"errorMsg\",\"onBulkEdit\",\"isBulkEditing\",\"onToggleBulkEdit\",\"isRunning\"],[[23,7,[]],true,true,[23,17,[]],[23,6,[]],[23,16,[]],[23,2,[]],[23,15,[]],[23,14,[]],[23,13,[]],[23,12,[]],[23,11,[]],[23,10,[]],[23,9,[]],[23,8,[]],[28,\"fn\",[[23,0,[\"bulkEdit\"]]],null],[24,[\"isBulkEditing\"]],[28,\"fn\",[[23,0,[\"toggleBulkEdit\"]]],null],[23,0,[\"crud\",\"searchRecordsTask\",\"isRunning\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products/template.hbs"
    }
  });

  _exports.default = _default;
});