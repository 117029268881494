define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-reward/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "27XMCgKb",
    "block": "{\"symbols\":[\"@model\",\"@copyErrors\",\"@dirty\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/reward-editor\",[],[[\"@isEditing\",\"@isReadOnly\",\"@model\"],[false,true,[23,1,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@hasEditBtn\",\"@copyErrors\",\"@model\",\"@dirty\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],[true,[23,2,[]],[23,1,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-reward/template.hbs"
    }
  });

  _exports.default = _default;
});