define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-overview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R4CEOnga",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"channels/shop/campaigns/campaigns-report\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",null,[[\"hasEditBtn\",\"copyErrors\",\"model\",\"dirty\",\"onEditPanel\",\"allowDuplicating\",\"onPrepareDuplicateRecord\",\"onCancelDuplicateRecord\",\"duplicateRecordTask\",\"onAssignSalesPerson\",\"onUnassignSalesPerson\",\"onToggleCommissionMethod\",\"errorMsg\"],[true,[24,[\"copyErrors\"]],[24,[\"model\"]],[24,[\"dirty\"]],[24,[\"onEditPanel\"]],[24,[\"allowDuplicating\"]],[24,[\"onPrepareDuplicateRecord\"]],[24,[\"onCancelDuplicateRecord\"]],[24,[\"duplicateRecordTask\"]],[24,[\"onAssignSalesPerson\"]],[24,[\"onUnassignSalesPerson\"]],[24,[\"onToggleCommissionMethod\"]],[24,[\"errorMsg\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-overview/template.hbs"
    }
  });

  _exports.default = _default;
});