define("client/pods/components/dispatch/tab-container/component", ["exports", "ramda", "client/mixins/crud", "client/mixins/date", "ember-concurrency", "client/pods/dispatch/model", "client/utils/nventor", "ramda-extension"], function (_exports, R, _crud, _date, _emberConcurrency, _model, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    model: null,
    errors: null,
    unprepared: null,
    adapterName: 'dispatch',
    results: null,
    count: 20,
    page: 1,
    dispatchSortByListName: 'dispatchSortBy',
    showDetails: true,
    showSideBar: true,
    filtersAdapterName: 'documents/filters',
    resultsUpdated: '',

    init() {
      this._super(...arguments);

      this.set('results', []);
      this.set('filters', []);
      this.crud.addLists(this, ['dispatch/lists/menu', 'dispatch/lists/print-all-sub-panels', 'dispatch/lists/processes', 'dispatch/lists/processes/menu', 'dispatch/lists/filters/dispatchstatuses', 'dispatch/lists/filters/sort', 'paymentMethods', 'paymentTerms', 'contacts/lists/tags', 'contacts/transporters/filters', 'contacts/transporters', 'filters/count', 'filters/sortDirection']);
      const pastDays = this.getPastDays(7);
      this.set('pastDays', pastDays);
      this.set('today', this.getPeriod(this.getToday()));
      this.loadTabTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchByProcessesCountTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const dateZ = loadInstructions.getData('find.dateZ');
        yield this.displayDispatched({
          dateZ
        });
        return true;
      }

      return false;
    }),
    todayLabel: Ember.computed('today.dateZ', function () {
      const today = this.today;
      const dateZ = today.dateZ;
      const intl = this.intl;
      return intl.t('dispatched') + ': ' + this.formatDate(dateZ, 'MM/DD') + ' (' + intl.t('today') + ')';
    }),
    currentDispatchProcessLabel: Ember.computed('currentDispatchProcess', function () {
      const intl = this.intl;
      const currentDispatchProcess = this.currentDispatchProcess;

      if (currentDispatchProcess) {
        const name = currentDispatchProcess.getData('name');

        if (currentDispatchProcess.getData('translate') === true) {
          return intl.t(name);
        }

        return name;
      }

      return intl.t('undispatched');
    }),
    hasMinimizedDispatchProcesses: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];

      if (dispatchProcesses.length > 5) {
        return true;
      }

      return false;
    }),
    dispatchProcessesMenu: Ember.computed('crud.lists.dispatch-lists-processes-menu.@each.isDeleted', function () {
      const list = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      return R.pipe(R.reject(R.propEq('isDeleted', true)), R.sortWith([R.ascend(R.path(['_data', 'index']))]))(list);
    }),
    fetchByProcessesCountTask: (0, _emberConcurrency.task)(function* () {
      const adapterName = 'dispatch/processes/count';
      const dispatchProcessesByCount = yield this.findTask.perform({
        adapterName
      });
      this.set('dispatchProcessesByCount', dispatchProcessesByCount);
    }),
    fetchDispatchDataTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      filters
    }) {
      this.set('unpaginatedResults', []);
      filters.setData('dispatchProcessesCriterias', dispatchProcess.getData('criterias'));
      filters.setData('dispatchProcess', dispatchProcess.getData('code'));

      if (period) {
        filters.setData('dispatchDateZ', period.date);
      } else {
        filters.setData('dispatchDateZ', '');
      }

      const searchSettings = {
        adapterName: 'dispatch',
        resultsProperty: '',
        resultsToggle: '',
        query: '',
        filters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      this.setResultsAndSummaryData(results);
      return results;
    }),
    fetchUnpaginatedDispatchDataTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period
    }) {
      const filters = this.filters;
      const filtersData = filters.serialize();
      const defaultFiltersData = R.mergeRight(filtersData, {
        matches: [],
        count: 5000,
        dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
        dispatchProcess: dispatchProcess.getData('code'),
        sort: filters.getData('sort')
      });

      if (period) {
        defaultFiltersData.dispatchDateZ = period.date;
      } else {
        defaultFiltersData.dispatchDateZ = '';
      }

      const unpaginatedFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData
      });
      const searchSettings = {
        adapterName: 'dispatch',
        resultsProperty: '',
        resultsToggle: '',
        query: '',
        filters: unpaginatedFilters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      this.setResultsAndSummaryData(results, 'unpaginatedResults');
      return results;
    }),
    customSearchTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      filters
    } = {}, query) {
      adapterName = adapterName || this.adapterName;
      filters.setData('query', query);
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        filters,
        query
      });
      this.setResultsAndSummaryData(results);
      return this.results;
    }),
    customAdvancedSearchTask: (0, _emberConcurrency.task)(function* ({
      filters
    }) {
      filters.setData('query', '');
      const adapterName = this.adapterName;
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        filters
      });
      this.setResultsAndSummaryData(results);
      return this.results;
    }),
    customReloadSearchTask: (0, _emberConcurrency.task)(function* ({
      filters,
      lastSearchSettingsProperty = 'lastSearchSettings',
      searchSettings,
      reset = false
    }) {
      if (reset) {
        filters.reset();
      }

      yield this.customAdvancedSearchTask.perform({
        filters,
        lastSearchSettingsProperty,
        searchSettings,
        reset
      });
    }),
    paginateDispatchDataTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.paginateSearchRecordsTask.perform(...arguments);
      this.setResultsAndSummaryData(results);
    }),
    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    isAwaitingPaymentProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const awaitingPaymentCode = this.get('constants.dispatchProcess.awaitingPayment');
      return R.find(R.pathEq(['_data', 'code'], awaitingPaymentCode), dispatchProcesses);
    }),
    // undispatchedPanels: computed('crud.lists.dispatch-lists-processes.[]', function () {
    //   const processes = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   return R.reject(R.path(['_data', 'noPanel']), processes)
    // }),
    resultsProxy: Ember.computed('results.[]', function () {
      const results = this.results || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: true // @TODO: get this from settings

      }), results);
    }),
    unpaginatedResultsProxy: Ember.computed('unpaginatedResults.[]', function () {
      const results = this.unpaginatedResults || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: true // @TODO: get this from settings

      }), results);
    }),
    displayTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      isDispatched = false,
      onCloseDialogAction
    }) {
      this.set('currentDispatchProcess', dispatchProcess);
      const dispatchProcessCode = dispatchProcess.getData('code');

      if (!period && dispatchProcessCode === this.get('constants.dispatchStatus.dispatched')) {
        period = this.getPeriod();
      }

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        altStorageProp: this.filtersAdapterName + `__${dispatchProcessCode}`,
        rememberProps: ['sortBy'],
        rememberMe: true
      });
      this.set('filters', filters);
      const subTabOptions = {
        component: 'dispatch/panels-display',
        dispatchProcess: dispatchProcess,
        period: period,
        isDispatched,
        filters
      };
      yield this.fetchDispatchDataTask.perform({
        dispatchProcess,
        period,
        filters
      });

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }),

    displayDispatched({
      period,
      dateZ
    }) {
      if (!period && dateZ) {
        period = this.getPeriod(dateZ);
      }

      const dispatchProcess = this.isDispatchedProcessModel;
      this.displayTask.perform({
        dispatchProcess,
        period,
        isDispatched: true
      });
    },

    setResultsAndSummaryData(results, prop = 'results') {
      this.set('summaryData', R.prop('summaryData', results));
      this.set('dashData', R.prop('dashData', results));
      this.set(prop, R.prop('docs', results));
    },

    actions: {
      refresh(dispatchProcess, period, isDispatched, models, nextDispatchProcess) {
        // nextDispatchProcess = nextDispatchProcess || dispatchProcess
        nextDispatchProcess = dispatchProcess;
        this.displayTask.perform({
          dispatchProcess: nextDispatchProcess,
          period,
          isDispatched
        });
      },

      display(dispatchProcess, onCloseDialogAction) {
        this.displayTask.perform({
          dispatchProcess,
          onCloseDialogAction
        });
      },

      displayDispatched(period) {
        this.displayDispatched({
          period
        });
      },

      displayCustomDate(dateZ) {
        this.displayDispatched({
          dateZ
        });
      },

      displaySummaryPanel(resourceData) {
        const subTabOptions = {
          component: resourceData.getData('component'),
          value: resourceData.getData('value')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },

      afterSplit(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      },

      afterMerge(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      }

    }
  });

  _exports.default = _default;
});