define("client/pods/components/emails/dispatch-batch/component", ["exports", "ramda", "ember-concurrency", "client/utils/nventor"], function (_exports, R, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    useDefaultDispatchedEmailMessage: true,
    emailMessage: '',
    emailSubject: '',
    isCompilingMessage: false,
    tabsChanged: 0,

    init() {
      this.set('emailMessage', '');
      this.crud.addLists(this, ['emails/lists/toSendTabs']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || this.get('crud.lists.emails-lists-toSendTabs').get('firstObject');
      this.set('currentSubTabOptions', initialPanel);
      this.set('tabsChanged', Math.random());
      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    didInsertElement() {
      this._super(...arguments);

      const data = {
        useDefaultDispatchedEmailMessage: this.useDefaultDispatchedEmailMessage,
        dispatchDateZ: this.dispatchDateZ,
        docKey: this.docKey,
        docType: this.docType,
        docName: this.docName,
        dispatchProcess: this.dispatchProcess
      };
      this.getToSendEmailsTask.perform(data);
    },

    headerTabs: Ember.computed('crud.lists.emails-lists-toSendTabs.[]', 'allEmailsData.{toSend.count,alreadySent.count,cannotSend.count}', function () {
      const allEmailsData = this.allEmailsData || {};
      const list = this.crud.lists['emails-lists-toSendTabs'] || [];
      return R.pipe(R.map(header => {
        const headerValue = header._data.value;
        const hasData = R.pipe(R.path([headerValue]), _nventor.default.isNilOrEmpty, R.not)(allEmailsData);

        if (!hasData) {
          return false;
        }

        const badge = R.path([headerValue, 'count'])(allEmailsData) || 0;
        header.setData('badge', badge);
        return header;
      }), R.reject(R.equals(false)))(list);
    }),
    panelData: Ember.computed('allEmailsData.toSend.count', 'currentSubTabOptions', 'tabsChanged', function () {
      const subTabOptions = this.currentSubTabOptions || {};

      if (!_nventor.default.isNilOrEmpty(subTabOptions)) {
        const value = subTabOptions.getData('value');
        const allEmailsData = this.allEmailsData || {};
        const data = R.prop(value)(allEmailsData) || {};
        return data;
      }

      return {};
    }),
    hasCustomMessage: Ember.computed('isDispatched', 'useDefaultDispatchedEmailMessage', function () {
      if (!this.isDispatched) {
        return true;
      }

      if (this.isDispatched && !this.useDefaultDispatchedEmailMessage) {
        return true;
      }

      return false;
    }),
    getToSendEmailsTask: (0, _emberConcurrency.task)(function* (data) {
      const allEmailsData = yield this.onGetToSendEmailsTask.perform(data);
      this.set('allEmailsData', allEmailsData);
    }),
    actions: {
      displayPanel(allEmailsData, subTabOptions) {
        this.set('currentSubTabOptions', subTabOptions);
        this.set('tabsChanged', Math.random());
        this.tabsManager.displaySubPanel(this, subTabOptions);
      },

      next() {
        this.set('isCompilingMessage', true);
      },

      toggleCustomMessage() {
        const emailSubject = this.intl.t('email_dispatched_default_subject');
        this.set('translatedPlaceholder', emailSubject);

        if (this.useDefaultDispatchedEmailMessage) {
          this.set('emailSubject', '');
        } else {
          this.set('emailSubject', emailSubject);
        }
      }

    }
  });

  _exports.default = _default;
});