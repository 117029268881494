define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-bulk-discounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qH//0SiK",
    "block": "{\"symbols\":[\"@tab\",\"@model\",\"@copyErrors\",\"@dirty\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-editor\",[],[[\"@tab\",\"@isEditing\",\"@isReadonly\",\"@model\"],[[23,1,[]],false,true,[23,2,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@hasEditBtn\",\"@copyErrors\",\"@model\",\"@dirty\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],[true,[23,3,[]],[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-bulk-discounts/template.hbs"
    }
  });

  _exports.default = _default;
});