define("client/pods/components/websites/panels-display/sub-panels-activity/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      this.set('docType', 'sales');
      this.crud.addLists(this, ['documents/lists/types/sales']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || this.docTypesFiltered.get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    docTypesFiltered: Ember.computed('docType', 'crud.lists.documents-lists-types-sales.[]', function () {
      const docType = this.docType;
      const docTypesList = this.crud.lists[`documents-lists-types-${docType}`] || [];
      return R.pipe(R.filter(option => option._data.docName === 'invoices' || option._data.docName === 'returns'), R.map(resourceData => {
        const docType = resourceData.getData('docType');
        const docName = resourceData.getData('docName');
        resourceData.setData('tabType', 'replaceSubTab');
        resourceData.setData('id', `${docType}-${docName}`);
        resourceData.setData('component', 'products/panels-display/activity-display/activity-display-doc');
        return resourceData;
      }))(docTypesList);
    })
  });

  _exports.default = _default;
});