define("client/pods/components/guests/campaign-login/component", ["exports", "ramda", "ember-concurrency", "client/config/environment"], function (_exports, R, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    url: '',
    password: '',
    guests: Ember.inject.service(),
    router: Ember.inject.service(),

    init() {
      this.crud.addLists(this, ['languages']);

      this._super(...arguments);
    },

    loginAsGuestTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('errorMsg', '');
        const guestResource = this.guestResource;
        const credentials = {
          resource: guestResource,
          password: this.password
        };
        const data = yield this.guests.call('POST', 'api/guests/login', credentials);
        const token = R.prop('token', data);

        if (token) {
          this.guests.setup(guestResource);
          this.guests.setToken(token);
          return this.router.transitionTo('guests.shop.report', guestResource);
        }
      } catch (err) {
        this.set('report', false);

        if (err.data === 'not connected') {
          window.location.reload();
        }

        this.set('errorMsg', 'invalid password');
      }
    }).drop(),
    version: Ember.computed('env.version', function () {
      return _environment.default.version || '';
    }),
    actions: {
      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }

    }
  });

  _exports.default = _default;
});