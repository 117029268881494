define("client/pods/components/files/files-fetcher/component", ["exports", "ember-concurrency", "client/mixins/crud", "ramda"], function (_exports, _emberConcurrency, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: 'span',
    url: 'api/protected/uploads/images',
    fetchUploadsOnInit: true,
    fetchUploadsCollectionOnInit: false,
    page: 1,
    count: 20,
    sort: null,
    adapterName: 'uploads',
    appendPath: '',
    uploadsCollection: null,

    /**
     * @deprecated
     */
    defaultImage: Ember.computed('uploadsCollection._data.uploads.[]', function () {
      const uploadsCollection = this.uploadsCollection;

      if (uploadsCollection) {
        const defaultImage = R.pipe(R.pathOr([], ['_data', 'uploads']), R.filter(R.pathEq(['_data', 'isDefault'], true)), R.view(R.lensPath([0, '_data', 'uuid'])))(uploadsCollection);
        return defaultImage || uploadsCollection.defaultImage;
      }

      return null;
    }),
    didInsertTask: (0, _emberConcurrency.task)(function* (resourceKey) {
      if (this.fetchUploadsOnInit) {
        this._fetchUploadsTask.perform({
          resourceKey
        });
      }

      if (this.fetchUploadsCollectionOnInit) {
        this._fetchUploadsCollectionTask.perform({
          resourceKey
        });
      }

      this.set('sort', [{
        by: 'timestampZ',
        direction: 'DESC'
      }]);
    }),
    _fetchUploadsTask: (0, _emberConcurrency.task)(function* ({
      resource,
      resourceKey,
      adapterName,
      appendPath,
      filters = {}
    } = {}) {
      let results;

      if (resourceKey) {
        if (this.fetchUploads) {
          results = yield this.fetchUploads(...arguments);
        } else {
          resource = resource || this.resource;
          resourceKey = resourceKey || this.resourceKey;
          const count = this.count;
          const page = this.page;
          const sort = this.sort;
          const filterData = {
            count: R.pathOr(count, ['_data', 'count'], filters),
            page: R.pathOr(page, ['_data', 'page'], filters),
            sort: R.pathOr(sort, ['_data', 'sort'], filters)
          };
          results = yield this.dataManager.get('findAll').perform({
            resource,
            resourceKey,
            adapterName,
            appendPath,
            filters: filterData
          });
        }
      }

      this.set('uploads', results);

      if (this.onSetImage) {
        this.onSetImage(results);
      }

      return results;
    }),

    /**
     * @deprecated
     */
    _fetchUploadsCollectionTask: (0, _emberConcurrency.task)(function* ({
      resource,
      resourceKey,
      adapterName,
      appendPath,
      filters
    } = {}) {
      if (resourceKey) {
        this.set('uploadsCollection', '');
        resource = resource || this.resource;
        resourceKey = resourceKey || this.resourceKey;
        adapterName = adapterName || this.adapterName;
        appendPath = appendPath || this.appendPath;
        filters = filters || this.filters;
        let results;

        if (this.fetchUploads) {
          results = yield this.fetchUploads(...arguments);
        } else {
          const collectionModel = yield this.fetchUploadsTask.perform({
            resource,
            resourceKey,
            adapterName,
            appendPath,
            filters
          });
          results = R.pathOr([], ['_data', 'uploads'], collectionModel);
          const uploadsColl = R.over(R.lensPath(['_data', 'uploads']), uploads => {
            const newUploads = R.filter(R.pathEq(['_data', 'isDefault'], true))(uploads);
            const isEmpty = R.isEmpty(newUploads);
            return isEmpty ? uploads : newUploads;
          })(collectionModel);
          this.set('uploadsCollection', uploadsColl);
        }

        this.set('uploads', results);

        if (this.onSetImage) {
          this.onSetImage(results);
        }
      }
    })
  });

  _exports.default = _default;
});