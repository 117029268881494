define("client/pods/components/bot/message-window/component", ["exports", "ramda", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    storage: Ember.inject.service(),
    searchService: Ember.inject.service('search'),
    filtersAdapterName: 'messages/messages/filters',
    isBotVisible: false,

    async init() {
      this._super(...arguments);

      const tokenData = this.storage.get('tokenData');
      const locale = this.storage.get('language');
      const model = this.dataManager.setAsRecord({
        adapterName: 'messages/users',
        data: {
          id: tokenData.uid,
          name: tokenData.name,
          email: tokenData.email,
          locale
        }
      });
      this.set('model', model);
      this.getFlows.perform();
      this.getPrivateFlows.perform();
    },

    getFlows: (0, _emberConcurrency.task)(function* () {
      const flows = yield this.server.call('GET', 'api/protected/bot/flows');
      this.set('flows', flows.data);
    }),
    getPrivateFlows: (0, _emberConcurrency.task)(function* () {
      const privateFlows = yield this.server.call('GET', 'api/protected/bot/flows/private');
      this.set('privateFlows', privateFlows.data);
    })
  });

  _exports.default = _default;
});