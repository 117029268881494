define("client/pods/components/extensions/apps/tw/e-invoices/print-all-btn/component", ["exports", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices-printer", "client/mixins/date", "ember-concurrency"], function (_exports, _invoicesPrinter, _date, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_invoicesPrinter.default, _date.default, {
    crud: Ember.inject.service(),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    intl: Ember.inject.service(),
    server: Ember.inject.service(),
    printer: Ember.inject.service(),
    tagName: '',
    isReadonly: false,
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    includeBeforeStartDate: true,
    isSubmitted: false,

    init() {
      this.crud.addLists(this, ['paymentMethods']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          count: 20
        }
      });
      this.set('filters', filters);
    },

    printerButtonIsRunning: Ember.computed('eInvoicesService.{issueAllEInvoicesForPeriodTask,fetchToIssueEInvoicesTask}.isRunning', 'this.printer.fetchOnlinePrintersTask.isRunning', function () {
      return this.eInvoicesService.issueAllEInvoicesForPeriodTask.isRunning || this.eInvoicesService.fetchToIssueEInvoicesTask.isRunning || this.printer.fetchOnlinePrintersTask.isRunning;
    }),
    setupTask: (0, _emberConcurrency.task)(function* (onlyPrintableEInvoices = false) {
      yield this.printer.fetchOnlinePrintersTask.perform();

      if (onlyPrintableEInvoices) {
        this.filters.setData('twEInvoicePrint', true);
      } else {
        this.filters.setData('twEInvoicePrint', false);
      }

      yield this.eInvoicesService.fetchToIssueEInvoicesTask.perform({
        component: this,
        filters: this.filters,
        period: this.period,
        includeBeforeStartDate: this.includeBeforeStartDate,
        dispatchProcess: this.dispatchProcess
      });
    }),
    printToggleBtnLabel: Ember.computed('period.dateEnd', function () {
      return `${this.intl.t('tw_eInvoives_include_until_current_date')}: ${this.formatDate(this.period.date)}`;
    }),
    actions: {
      toggleSubmitted() {
        this.set('isSubmitted', true);
      }

    }
  });

  _exports.default = _default;
});