define("client/pods/comments/collection/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    init() {
      this._super(...arguments);

      this.set('adapters', {
        comments: 'comments'
      });
    },

    populate(data, attrs) {
      data.comments = this._transformComments(data.comments);

      this._super(data, attrs);
    },

    _transformComments(comments = []) {
      return comments.map(comment => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.comments'),
          data: comment
        });
      });
    }

  });

  _exports.default = _default;
});