define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "36fshGr0",
    "block": "{\"symbols\":[\"@isReadonly\",\"@onDisplayItem\",\"@onEditItem\",\"@onAddItem\",\"@tab\",\"@errorMsg\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@onAssignSalesPerson\",\"@duplicateRecordTask\",\"@onCancelDuplicateRecord\",\"@onPrepareDuplicateRecord\",\"@allowDuplicating\",\"@onEditPanel\",\"@model\",\"@copyErrors\"],\"statements\":[[7,\"div\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"fetchCampaignDetailsLimitsTask\"]]],null]]],[8],[0,\"\\n\\n\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-editor/products-limits-editor\",null,[[\"tab\",\"batch\",\"filters\",\"isBulkEditing\",\"onAddItem\",\"onEditItem\",\"onDisplayItem\",\"fetchCampaignDetailsLimitsTask\",\"onPaginationTask\",\"isReadonly\"],[[23,5,[]],[23,0,[\"filters\",\"results\"]],[23,0,[\"filters\"]],[23,0,[\"isBulkEditing\"]],[23,4,[]],[23,3,[]],[23,2,[]],[23,0,[\"fetchCampaignDetailsLimitsTask\"]],[23,0,[\"customPaginateSearchRecordsTask\"]],[23,1,[]]]]],false],[0,\"\\n\\n\\t\"],[1,[28,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",null,[[\"tab\",\"hasEditBtn\",\"copyErrors\",\"model\",\"onEditPanel\",\"allowDuplicating\",\"onPrepareDuplicateRecord\",\"onCancelDuplicateRecord\",\"duplicateRecordTask\",\"onAssignSalesPerson\",\"onUnassignSalesPerson\",\"onToggleCommissionMethod\",\"onBulkEdit\",\"isBulkEditing\",\"onToggleBulkEdit\",\"isRunning\",\"errorMsg\"],[[23,5,[]],false,[23,16,[]],[23,15,[]],[23,14,[]],[23,13,[]],[23,12,[]],[23,11,[]],[23,10,[]],[23,9,[]],[23,8,[]],[23,7,[]],[28,\"fn\",[[23,0,[\"bulkEdit\"]]],null],[24,[\"isBulkEditing\"]],[28,\"fn\",[[23,0,[\"toggleBulkEdit\"]]],null],[23,0,[\"crud\",\"searchRecordsTask\",\"isRunning\"]],[23,6,[]]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/template.hbs"
    }
  });

  _exports.default = _default;
});