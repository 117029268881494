define("client/pods/components/documents/panel-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZM62sX+M",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/editor-main\",null,[[\"tab\",\"model\",\"infoComponent\",\"detailsComponent\",\"isEditing\",\"isNew\",\"onDash\",\"errors\",\"onPreSubmit\",\"onAddNewDetail\",\"onSubmitTask\",\"fetchHCTRegionDataTask\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"tab\",\"editorInfoComponent\"]],[24,[\"tab\",\"editorDetailsComponent\"]],true,true,[24,[\"onDash\"]],[24,[\"errors\"]],[28,\"action\",[[23,0,[]],\"preSubmit\"],null],[28,\"action\",[[23,0,[]],\"addNewDetail\",[24,[\"model\"]]],null],[24,[\"createRecordTask\"]],[24,[\"fetchHCTRegionDataTask\"]],[24,[\"onDash\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-new/template.hbs"
    }
  });

  _exports.default = _default;
});