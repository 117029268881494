define("client/pods/components/extensions/apps/tw/hct/settings-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B7w25KN8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"transporterModel\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"translate\"],[[24,[\"transporterModel\",\"_data\",\"name\"]],false]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"unlink\",[24,[\"unlinkTransporterTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"unlinkTransporterTask\"]],[28,\"hash\",null,[[\"model\"],[[24,[\"transporterModel\"]]]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\"],[\"first waybill number\",[24,[\"errors\"]],\"hctWaybillStart\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"hctWaybillStart\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\"],[\"last waybill number\",[24,[\"errors\"]],\"hctWaybillEnd\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"hctWaybillEnd\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\\t\"],[1,[28,\"transporters/accounts-editor\",null,[[\"model\"],[[24,[\"transporterModel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[28,\"extensions/apps/tw/hct/create-contact\",null,[[\"onLoadTransporterContact\"],[[28,\"action\",[[23,0,[]],\"loadTransporter\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/hct/settings-editor/template.hbs"
    }
  });

  _exports.default = _default;
});