define("client/pods/grapesjs/service", ["exports", "handlebars/dist/handlebars", "client/pods/grapesjs/plugins/banner", "client/pods/grapesjs/plugins/blocks", "client/pods/grapesjs/plugins/button", "client/pods/grapesjs/categories", "client/pods/grapesjs/plugins/cell", "client/pods/grapesjs/plugins/full-width-image", "client/pods/grapesjs/plugins/image", "client/pods/grapesjs/plugins/non-editable", "client/pods/grapesjs/plugins/panels", "client/pods/grapesjs/plugins/product", "client/pods/grapesjs/plugins/products", "client/pods/grapesjs/plugins/row", "client/pods/grapesjs/plugins/slider", "client/pods/grapesjs/plugins/strip", "client/pods/grapesjs/plugins/video", "client/pods/grapesjs/plugins/grid", "client/pods/grapesjs/plugins/double-row", "client/pods/grapesjs/plugins/text", "client/pods/grapesjs/plugins/tabs", "client/pods/grapesjs/plugins/blogs", "client/pods/grapesjs/plugins/tabs-with-header", "ramda", "ramda-adjunct"], function (_exports, _handlebars, _banner, _blocks, _button, _categories, _cell, _fullWidthImage, _image, _nonEditable, _panels, _product, _products, _row, _slider, _strip, _video, _grid, _doubleRow, _text, _tabs, _blogs, _tabsWithHeader, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global grapesjs, store, CKEDITOR */
  if (CKEDITOR) {
    CKEDITOR.dtd = R.set(R.lensPath(['$editable', 'span']), 1)(CKEDITOR.dtd);
    CKEDITOR.dtd = R.set(R.lensPath(['$editable', 'a']), 1)(CKEDITOR.dtd);
  }

  const genBtnStyles = website => {
    const cssBtnThemes = R.pipe(R.pathOr([], ['colors']), R.filter(R.pipe(R.propOr('', 'key'), R.test(/^themeButton/))), R.groupBy(theme => {
      const {
        key = ''
      } = theme;
      return key.includes('3') ? 'button3' : key.includes('2') ? 'button2' : 'button';
    }), R.map(R.map(R.values)), R.map(R.map(R.over(R.lensIndex(0), R.replace(/\d/, '')))), R.map(R.fromPairs), R.mapObjIndexed(btnStyles => {
      if (!btnStyles.themeButtonBorderRadius) {
        btnStyles.themeButtonBorderRadius = '20px';
      }

      return btnStyles;
    }))(website);

    const cssTemplate = _handlebars.default.compile(`
  {{#each this.cssBtnThemes as |btnTheme index|}}
   .{{index}} {
      border: 1px solid {{btnTheme.themeButtonBorderColor}} !important;
      background-color: {{btnTheme.themeButtonColor}} !important;
      color: {{btnTheme.themeButtonTextColor}} !important;
      border-radius: {{btnTheme.themeButtonBorderRadius}} !important;
  }

   .{{index}}:hover {
      background-color: {{btnTheme.themeButtonHoverColor}} !important;
  }
{{/each}}
      `);

    return cssTemplate({
      cssBtnThemes
    });
  };

  const create = (id = 'gjs', storagePrefix, intl, opts = {}, btnStyles = '') => {
    const categories = (0, _categories.default)(intl);
    const plugins = R.pipe(R.propOr([], 'plugins'), R.concat([_row.default, _cell.default, 'gjs-preset-webpage', 'gjs-plugin-ckeditor', _panels.default, _text.default, _blocks.default, _fullWidthImage.default, _video.default, _slider.default, _button.default, _nonEditable.default, _image.default, _product.default, _products.default, _blogs.default, _strip.default, _banner.default, _grid.default, _doubleRow.default, _tabs.default, _tabsWithHeader.default]))(opts);
    opts = R.dissoc('plugins', opts); // language=CSS

    let baseCss = `
      * {
          box-sizing: border-box;
      }

      html, body, [data-gjs-type=wrapper] {
          min-height: 100%;
          background-color: var(--themeBackgroundPrimaryColor);
      }

      body {
          margin: 0;
          height: 100%;
      }

      [data-gjs-type=wrapper] {
          overflow: auto;
          overflow-x: hidden;
      }

      * ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1)
      }

      * ::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2)
      }

      * ::-webkit-scrollbar {
          width: 10px
      }

      /*********************
      ****** Flex Box ******
      **********************/
      .flex-container-column {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: vertical;
          -moz-box-direction: normal;
          -moz-box-orient: vertical;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
      }

      .flex-container-row {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
      }

      .flex-container--no-wrap {
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: none;
          flex-wrap: nowrap;
      }

      .flex-container--wrap-reverse {
          -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
      }

      /* The justify-content property is used to align the flex items */
      .flex-container--justify-center {
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
      }

      .flex-container--justify-start {
          -webkit-box-pack: start;
          -moz-box-pack: start;
          -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
      }

      .flex-container--justify-end {
          -webkit-box-pack: end;
          -moz-box-pack: end;
          -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
      }

      .flex-container--justify-space-around {
          -ms-flex-pack: distribute;
          -webkit-justify-content: space-around;
          justify-content: space-around;
      }

      .flex-container--justify-space-between {
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
      }

      /* The align-items property is used to align the flex items vertically */
      .flex-container--align-items-center {
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      .flex-container--align-items-start {
          -webkit-box-align: start;
          -moz-box-align: start;
          -ms-flex-align: start;
          -webkit-align-items: flex-start;
          align-items: flex-start;
      }

      .flex-container--align-items-end {
          -webkit-box-align: end;
          -moz-box-align: end;
          -ms-flex-align: end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
      }

      .flex-container--align-items-stretch {
          -webkit-box-align: stretch;
          -moz-box-align: stretch;
          -ms-flex-align: stretch;
          -webkit-align-items: stretch;
          align-items: stretch;
      }

      /* The align-content property is used to align the flex lines. used for multiple flex lines in a flex wrap container */
      .flex-container--align-content-center {
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
      }

      .flex-container--align-content-start {
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
      }

      .flex-container--align-content-end {
          -webkit-align-content: flex-end;
          -ms-flex-line-pack: end;
          align-content: flex-end;
      }

      .flex-container--align-content-space-around {
          -webkit-align-content: space-around;
          -ms-flex-line-pack: space-around;
          align-content: space-around;
      }

      .flex-container--align-content-space-between {
          -webkit-align-content: space-between;
          -ms-flex-line-pack: space-between;
          align-content: space-between;
      }

      .flex-container--align-content-space-evenly {
          -webkit-align-content: space-evenly;
          -ms-flex-line-pack: space-evenly;
          align-content: space-evenly;
      }

      /* The align-self property specifies the alignment for the selected item inside the flexible container. Note: The align-self property overrides the flexible container's align-items property. */
      .flex-item--align-self-center {
          -webkit-align-self: center;
          -ms-flex-item-align: center;
          align-self: center;
      }

      .flex-item--align-self-start {
          -webkit-align-self: flex-start;
          -ms-flex-item-align: start;
          align-self: flex-start;
      }

      .flex-item--align-self-end {
          -webkit-align-self: flex-end;
          -ms-flex-item-align: end;
          align-self: flex-end;
      }

      .flex-item--align-self-stretch {
          -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
          align-self: stretch;
      }

      .flex-row-space-between {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      .flex-row-space-around {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-pack: distribute;
          -webkit-justify-content: space-around;
          justify-content: space-around;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      /*********************
      ****** Flex Box ******
      **********************/

      ${btnStyles}
      .full-width-image--display-none {
          display: none;
      }

      /*********************
      ****** Flex Box ******
      **********************/

      .bigordr-tab {
          cursor: pointer;
          padding: 7px 14px;
          display: inline-block;
          border-radius: 3px;
          margin-right: 10px;
      }

      .bigordr-tab:hover {
          background-color: var(--themeActionHoverColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab:focus {
          outline: none;
      }

      .bigordr-tab.bigordr-tab-active {
          background-color: var(--themeActionColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab.bigordr-tab-active:hover {
          background-color: var(--themeActionHoverColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab-container {
          display: inline-block;
          padding-left: 25px;
      }

      .bigordr-tab-content {
          animation: fadeEffect 1s;
      }

      .bigordr-tab-contents {
          min-height: 100px;
          padding: 10px;
      }

      @keyframes fadeEffect {
          from {
              opacity: 0;
          }
          to {
              opacity: 1;
          }
      }

      /*********************
      ****** Flex Box ******
      **********************/
      .bigordr-blogs {
          display: flex;
      }

      .bigordr-blog {
          width: 100%;
          height: 100%;
          min-width: 5px;
          min-height: 5px;
      }

      .bigordr-products-wrapper {
          display: grid;
          grid-template-rows: auto;
          grid-template-areas: "btnLeft products btnRight";
          grid-template-columns: auto auto auto;
      }

      .bigordr-products-wrapper-controls {
          align-self: center;
          margin: 10px;
      }

      .bigordr-products {
          overflow-x: auto;
          white-space: nowrap;
          padding: 15px 0;
          display: flex;
      }

      .slider__products-card {
          display: inline-block;
          margin: 0 10px;
      }

      .checkout-form__extra-max-width{
          max-width: 1255px;
      }
      
      
  /*  Video  */

      .bigordr-video-inner-wrapper {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
      }

      .bigordr-video-inner-wrapper > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      .bigordr-video-inner-wrapper > video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      .bigordr-video-inner-wrapper > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
  `;

    if (!opts.isReadOnly) {
      baseCss += `
    #wrapper {
      padding-top: 10px;
      padding-bottom: 500px;
    }
    `;
    } // Editor config https://github.com/artf/grapesjs/blob/dev/src/editor/config/config.js


    const defaults = {
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: `#${id}`,
      allowScripts: 1,
      // dont allow scripts
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: false,
      // Size of the editor
      height: '100%',
      width: 'auto',
      // On creation of a new Component (via object), if the 'style' attribute is not
      // empty, all those roles will be moved in its new class
      forceClass: true,
      // Type of logs to print with the logger (by default is used the devtool console).
      // Available by default: debug, info, warning, error
      // You can use `false` to disable all of them or `true` to print all of them
      log: ['error'],
      // ['warning', 'error'],
      // By default Grapes injects base CSS into the canvas. For example, it sets body margin to 0
      // and sets a default background color of white. This CSS is desired in most cases.
      // use this property if you wish to overwrite the base CSS to your own CSS. This is most
      // useful if for example your template is not based off a document with 0 as body margin.
      baseCss,
      // Usually when you update the `style` of the component this changes the
      // element's `style` attribute. Unfortunately, inline styling doesn't allow
      // use of media queries (@media) or even pseudo selectors (eg. :hover).
      // When `avoidInlineStyle` is true all styles are inserted inside the css rule
      // @deprecated Don't use this option, we don't support inline styling anymore
      avoidInlineStyle: true,
      canvasCss: 'body {height: auto;}',
      canvas: {
        styles: ['https://services-production.bigordr.com/bigordr-gcloud-storage/production/api/proxy/file/vendor/css', 'https://services-production.bigordr.com/bigordr-gcloud-storage/production/api/proxy/file/shop/css'],
        scripts: ['https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/js/all.min.js']
      },
      // Disable the storage manager for the moment
      storageManager: {
        id: `${storagePrefix}-`,
        type: 'local',
        // Type of the storage
        autosave: true,
        // Store data automatically
        autoload: false,
        // Autoload stored data on init
        stepsBeforeSave: 1 // If autosave enabled, indicates how many changes are necessary before store method is
        // triggered

      },
      // Avoid any default panel
      styleManager: {
        appendTo: `#style-container-${id}`
      },
      traitManager: {
        appendTo: `#trait-container-${id}`
      },
      blockManager: {
        appendTo: `#blocks-container-${id}`
      },
      plugins,
      pluginsOpts: {
        'tabs': {
          categories,
          intl,
          ...opts
        },
        'bigordr-tabs-with-header': {
          categories,
          intl,
          ...opts
        },
        'bigordr-blogs': {
          categories,
          intl,
          ...opts
        },
        'double-row': {
          categories,
          intl,
          ...opts
        },
        text: {
          categories,
          intl,
          ...opts
        },
        row: {
          categories,
          intl,
          ...opts
        },
        cell: {
          categories,
          intl,
          ...opts
        },
        'full-width-image': {
          categories,
          intl,
          ...opts
        },
        'bigordr-grid': {
          categories,
          intl,
          ...opts
        },
        'bigordr-grid-images': {
          categories,
          intl,
          ...opts
        },
        'bigordr-blocks': {
          categories,
          intl,
          ...opts
        },
        'bigordr-banner': {
          categories,
          intl,
          ...opts
        },
        'bigordr-strip': {
          categories,
          intl,
          ...opts
        },
        'bigordr-panels': {
          categories,
          intl,
          ...opts
        },
        'bigordr-button': {
          categories,
          intl,
          ...opts
        },
        'bigordr-slider': {
          categories,
          intl,
          ...opts
        },
        'bigordr-video': {
          categories,
          intl,
          ...opts
        },
        'bigordr-product': {
          categories,
          intl,
          ...opts
        },
        'bigordr-products': {
          categories,
          intl,
          ...opts
        },
        'bigordr-video-full-width': {
          categories,
          intl,
          ...opts
        },
        'gjs-preset-webpage': {
          editorId: id,
          customStyleManager: [{
            name: intl.t('gjs-Style'),
            open: true,
            buildProps: ['font-family', 'font-size', 'color', 'text-align', // Background Settings
            'opacity', 'background-color', 'border-radius-c', 'border', 'box-shadow', 'background'],
            properties: [{
              name: intl.t('gjs-Font Size'),
              property: 'font-size'
            }, {
              name: intl.t('gjs-Font Family'),
              property: 'font-family'
            }, {
              name: intl.t('gjs-Text align'),
              property: 'text-align',
              type: 'radio',
              defaults: 'left',
              list: [{
                value: 'left',
                name: intl.t('gjs-Left'),
                className: 'fa fa-align-left'
              }, {
                value: 'center',
                name: intl.t('gjs-Center'),
                className: 'fa fa-align-center'
              }, {
                value: 'right',
                name: intl.t('gjs-Right'),
                className: 'fa fa-align-right'
              }, {
                value: 'justify',
                name: intl.t('gjs-Justify'),
                className: 'fa fa-align-justify'
              }]
            }, {
              name: intl.t('gjs-Color'),
              property: 'color',
              type: 'color'
            }, {
              name: intl.t('gjs-Background Color'),
              property: 'background-color',
              type: 'color'
            }, {
              name: intl.t('gjs-Border Radius'),
              property: 'border-radius',
              type: 'integer',
              units: ['px', '%'],
              defaults: 0,
              min: 0
            }, {
              name: intl.t('gjs-Border'),
              property: 'border',
              info: 'SOME BORDER INFO',
              properties: [{
                name: intl.t('gjs-Border Width'),
                property: 'border-width'
              }, {
                name: intl.t('gjs-Border Style'),
                property: 'border-style',
                options: [{
                  name: intl.t('gjs-none'),
                  value: 'none'
                }, {
                  name: intl.t('gjs-solid'),
                  value: 'solid'
                }, {
                  name: intl.t('gjs-dotted'),
                  value: 'dotted'
                }, {
                  name: intl.t('gjs-dashed'),
                  value: 'dashed'
                }, {
                  name: intl.t('gjs-double'),
                  value: 'double'
                }]
              }, {
                name: intl.t('gjs-Border Color'),
                property: 'border-color'
              }]
            }, {
              name: intl.t('gjs-Opacity'),
              type: 'slider',
              property: 'opacity',
              defaults: 1,
              step: 0.01,
              max: 1,
              min: 0
            }, {
              name: intl.t('gjs-Box Shadow'),
              property: 'box-shadow',
              properties: [{
                name: intl.t('gjs-X position'),
                property: 'box-shadow-h'
              }, {
                name: intl.t('gjs-Y position'),
                property: 'box-shadow-v'
              }, {
                name: intl.t('gjs-Blur'),
                property: 'box-shadow-blur'
              }, {
                name: intl.t('gjs-Spread'),
                property: 'box-shadow-spread'
              }, {
                name: intl.t('gjs-box-shadow-color'),
                property: 'box-shadow-color'
              }, {
                name: intl.t('gjs-Shadow type'),
                property: 'box-shadow-type'
              }]
            }, {
              name: intl.t('gjs-Background'),
              property: 'background',
              properties: [{
                name: intl.t('gjs-Image'),
                property: 'background-image'
              }, {
                name: intl.t('gjs-Repeat'),
                property: 'background-repeat'
              }, {
                name: intl.t('gjs-Position'),
                property: 'background-position'
              }, {
                name: intl.t('gjs-Attachment'),
                property: 'background-attachment'
              }, {
                name: intl.t('gjs-Size'),
                property: 'background-size'
              }]
            }]
          }, {
            name: intl.t('gjs-Dimension') || 'Dimension',
            open: false,
            buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
            properties: [{
              name: intl.t('gjs-Width') || 'Width',
              property: 'width'
            }, {
              name: intl.t('gjs-Height') || 'Height',
              property: 'height'
            }, {
              name: intl.t('gjs-Max-width') || 'Max width',
              property: 'max-width'
            }, {
              name: intl.t('gjs-Min-height') || 'Min height',
              property: 'min-height'
            }, {
              name: intl.t('gjs-Margin') || 'Margin',
              property: 'margin',
              properties: [{
                name: intl.t('gjs-Top') || 'Top',
                property: 'margin-top'
              }, {
                name: intl.t('gjs-Right') || 'Right',
                property: 'margin-right'
              }, {
                name: intl.t('gjs-Bottom') || 'Bottom',
                property: 'margin-bottom'
              }, {
                name: intl.t('gjs-Left') || 'Left',
                property: 'margin-left'
              }]
            }, {
              name: intl.t('gjs-Padding') || 'Padding',
              property: 'padding',
              properties: [{
                name: intl.t('gjs-Top') || 'Top',
                property: 'padding-top'
              }, {
                name: intl.t('gjs-Right') || 'Right',
                property: 'padding-right'
              }, {
                name: intl.t('gjs-Bottom') || 'Bottom',
                property: 'padding-bottom'
              }, {
                name: intl.t('gjs-Left') || 'Left',
                property: 'padding-left'
              }]
            }]
          }]
        },
        'grapesjs-custom-code': {
          blockCustomCode: {
            category: '',
            attributes: {
              class: 'fa fa-code'
            }
          }
        },
        'gjs-plugin-ckeditor': {
          options: {
            language: 'zh',
            skin: 'moono-lisa',
            plugins: 'dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc',
            font_defaultLabel: 'Arial',
            toolbar: [{
              name: 'basicstyles',
              items: ['Undo', 'Redo', '-', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
            }, {
              name: 'paragraph',
              items: ['Outdent', 'Indent', '-', 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
            }, {
              name: 'links',
              items: ['Link', 'Unlink']
            }, {
              name: 'insert',
              items: ['Table']
            }, '/', {
              name: 'styles',
              items: ['Font', 'Styles', 'FontSize']
            }, {
              name: 'colors',
              items: ['TextColor', 'BGColor']
            }]
          }
        },
        'bigordr-tabs': {
          categories,
          intl
        }
      }
    };
    opts = R.mergeDeepRight(defaults, opts);
    clearPanels(id);
    return grapesjs.init(opts);
  };

  const clearPanels = id => {
    const panelDevices = document.getElementsByClassName(`panel__devices-${id}`)[0];

    if (panelDevices) {
      while (panelDevices.firstChild) {
        panelDevices.removeChild(panelDevices.firstChild);
      }
    }

    const panelBasic = document.getElementsByClassName(`panel__basic-actions-${id}`)[0];

    if (panelBasic) {
      while (panelBasic.firstChild) {
        panelBasic.removeChild(panelBasic.firstChild);
      }
    }
  };

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    initialCanvasSize: 600,

    init() {
      this._super(...arguments);

      const storageService = this.storage;
      const activeEditors = storageService.get('activeEditors') || [];
      R.forEach(this.removeFromStorage, activeEditors);
      storageService.set('activeEditors', []);
    },

    didDestroyElement() {
      this._super(...arguments);

      window.removeEventListener('resize', this.resetHeight);
    },

    create(editorId, storagePrefix, pageDataString = '{}', options, model, website) {
      const storageService = this.storage;
      const activeEditors = storageService.get('activeEditors') || [];
      activeEditors.push(storagePrefix);
      storageService.set('activeEditors', activeEditors);
      let pageData = {};

      try {
        pageData = JSON.parse(pageDataString);
      } catch (e) {
        pageData = {};
      }

      const intl = this.intl;

      try {
        // const canvasHeight = options.canvasHeight
        options.model = model;
        options.editorId = editorId;
        options.intl = this.intl;
        const btnStyles = genBtnStyles(website);
        const editor = create(editorId, storagePrefix, intl, options, btnStyles); // Re-Bind delete and backspace to handle bug on GJS

        editor.Keymaps.add('delete', 'delete', 'core:component-delete');
        editor.Keymaps.add('backspace', 'backspace', 'core:component-delete');
        const {
          isReadOnly
        } = options;
        let components = R.pipe(R.propOr([], 'components'), R.filter(component => {
          if (component.tagName === '' && component.type === 'textnode') {
            return false;
          }

          return true;
        }))(pageData);

        if (RA.isNilOrEmpty(components)) {
          components = pageData.html;
        }

        const styles = pageData.styles || pageData.css;

        if (isReadOnly) {
          editor.setComponents('<div></div>');
          editor.DomComponents.getWrapper().onAll(comp => comp.set({
            badgable: false,
            copyable: false,
            draggable: false,
            droppable: false,
            editable: false,
            highlightable: false,
            hoverable: false,
            layerable: false,
            propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
            removable: false,
            resizable: false,
            selectable: false,
            stylable: false
          }));
          const timeoutTime = options.canvasHeight ? 0 : 2000;
          editor.on('update', () => setTimeout(() => this.resetHeight(editor, {
            isReadOnly,
            canvasHeight: options.canvasHeight
          }), timeoutTime));
          editor.on('component:add', () => this.resetHeight(editor, {
            addComponent: true,
            isReadOnly
          }));
          window.addEventListener('resize', () => this.resetHeight(editor, {
            isReadOnly
          }));
        } else {
          editor.on('component:selected', () => editor.refresh({
            tools: true
          }));
          editor.on('component:deselected', () => editor.refresh({
            tools: true
          }));
        }

        editor.setComponents(components); // TODO: THIS CLEANER STYLE SHOULD BE REMOVE ONCE ALL THE WEBSITES ARE MODIFY

        let cleanStyles = styles;

        if (R.is(Array, styles)) {
          cleanStyles = R.map(R.over(R.lensPath(['selectorsAdd']), (s = '') => R.replace('#wrapper > ', '')(s)))(styles); // @NOTE: this cleans up old templates where button has static style in template
          // cleanStyles = R.pipe(
          //   R.map(item => {
          //     const isButton = R.pipe(
          //       R.propOr([], 'selectors'),
          //       R.find(R.propEq('name', 'button'))
          //     )(item)
          //     if (!isButton) {
          //       return item
          //     }
          //     debugger
          //     if (item.style) {
          //       return R.omit(['style'])(item)
          //     }
          //     return item
          //   })
          // )(cleanStyles)
        }

        editor.setStyle(cleanStyles);
        editor.getStyle().add(this.genThemeFromWebsiteModel(website));
        return editor;
      } catch (e) {
        console.error('==> Error Initializing GrapesJs <== \n%O\n==> Error Initializing GrapesJs <== ', e);
      }
    },

    resetHeight(editor, options = {}) {
      const {
        addComponent,
        isReadOnly
      } = options;
      let canvasHeight = options.canvasHeight;

      if (editor.Canvas) {
        const canvasDocument = editor.Canvas.getDocument();

        if (canvasDocument) {
          const canvasBody = canvasDocument.body;
          const canvasHtml = canvasDocument.documentElement;

          if (!canvasHeight) {
            canvasHeight = Math.max(canvasBody.clientHeight, canvasBody.scrollHeight, canvasBody.offsetHeight, canvasHtml.clientHeight, canvasHtml.scrollHeight, canvasHtml.offsetHeight);
          } // const isInitial = editor.getEl().parentElement.style.height === '300px' || editor.getEl().parentElement.style.height === ''


          if (isReadOnly) {
            this.set('initialCanvasSize', canvasHeight - 400);
          } else if (addComponent) {
            this.set('initialCanvasSize', this.initialCanvasSize + 200);
          }

          editor.getEl().parentElement.style.height = `${this.initialCanvasSize + 800}px`;
        }
      }
    },

    getPlugins() {
      return {
        FullWidthImagePlugin: _fullWidthImage.default,
        ImagePlugin: _image.default,
        NonEditablePlugin: _nonEditable.default,
        ProductPlugin: _product.default
      };
    },

    getPluginsAsArray() {
      const obj = this.getPlugins();
      return R.values(obj);
    },

    getPageDataStringFromStorage(editor, storagePrefix) {
      editor.store();
      const pageData = this.getPageDataFromStorage(editor, storagePrefix);
      const pageDataString = JSON.stringify(pageData);
      const html = editor.getHtml();
      return {
        css: R.propOr('', 'css', pageData),
        html: html || R.propOr('', 'html', pageData),
        pageDataString
      };
    },

    getPageDataFromStorage(editor, storagePrefix) {
      editor.store();
      const pageData = {
        html: store.get(`${storagePrefix}-html`) || '',
        css: store.get(`${storagePrefix}-css`) || '',
        assets: store.get(`${storagePrefix}-assets`),
        styles: store.get(`${storagePrefix}-styles`),
        components: store.get(`${storagePrefix}-components`)
      };
      return pageData;
    },

    setPageDataToStorage(storagePrefix, pageData) {
      const html = pageData.html || '';
      const css = pageData.css || '';
      const assets = pageData.assets || [];
      const styles = pageData.styles || [];
      const components = pageData.components || [];
      return {
        html: store.set(`${storagePrefix}-html`, html),
        css: store.set(`${storagePrefix}-css`, css),
        assets: store.set(`${storagePrefix}-assets`, assets),
        styles: store.set(`${storagePrefix}-styles`, styles),
        components: store.set(`${storagePrefix}-components`, components)
      };
    },

    removeFromStorage(storagePrefix) {
      // using global store property as grapesjs doesnt store these using storage service
      if (storagePrefix) {
        store.remove(`${storagePrefix}-html`);
        store.remove(`${storagePrefix}-css`);
        store.remove(`${storagePrefix}-components`);
        store.remove(`${storagePrefix}-styles`);
        store.remove(`${storagePrefix}-assets`);
      }
    },

    clearPage(editor, website) {
      if (editor) {
        const domComponents = editor.DomComponents;
        const cssComposer = editor.CssComposer;
        domComponents.clear();
        cssComposer.clear();
        editor.getStyle().add(this.genThemeFromWebsiteModel(website));
      }
    },

    /**
     * Generate css var from website
     * @param {WebsiteType} website
     * @return {string}
     */
    genThemeFromWebsiteModel(website) {
      const customTheme = R.propOr({}, '_data')(website);
      const themeData = {
        themeWidthDefault: '1344px'
      };
      const activeColor = '#000000';
      const backgroundColor = '#ffffff';
      const borderColor = '#eeeeee';
      const chatColor = '#66aee4';
      const chatHoverColor = '#4390ca';
      const chatTextColor = '#ffffff';
      const linkColor = '#000000';
      const linkHoverColor = '#262626';
      const neutralColor = '#ffffff';
      const neutralHoverColor = '#eeeeee';
      const primaryColor = '#e4666f';
      const primaryContrastColor = '#ffffff';
      const primaryHoverColor = '#fa2e3c';
      const saleColor = '#ff4a68';
      const saleTextColor = '#ffffff';
      const secondaryBackgroundColor = '#eeeeee';
      const secondaryBackgroundLinkColor = '#000000';
      const secondaryBackgroundLinkHoverColor = '#262626';
      const secondaryBackgroundTextColor = '#666666';
      const secondaryColor = '#000000';
      const secondaryContrastColor = '#ffffff';
      const secondaryHoverColor = '#262626';
      const textColor = '#666666';
      const themeChatButtonColor = R.propOr(chatColor, 'themeChatButtonColor', customTheme);
      const themeChatButtonTextColor = R.propOr(chatTextColor, 'themeChatButtonTextColor', customTheme);
      const white = '#ffffff';

      if (themeChatButtonColor === white && themeChatButtonTextColor === white) {
        website.setData('themeChatButtonColor', chatColor);
      }

      themeData.themeActionColor = R.propOr(primaryColor, 'themeActionColor', customTheme);
      themeData.themeActionFinalColor = R.propOr(secondaryColor, 'themeActionFinalColor', customTheme);
      themeData.themeActionFinalHoverColor = R.propOr(secondaryHoverColor, 'themeActionFinalHoverColor', customTheme);
      themeData.themeActionFinalTextColor = R.propOr(secondaryContrastColor, 'themeActionFinalTextColor', customTheme);
      themeData.themeActionHoverColor = R.propOr(primaryHoverColor, 'themeActionHoverColor', customTheme);
      themeData.themeActionTextColor = R.propOr(primaryContrastColor, 'themeActionTextColor', customTheme);
      themeData.themeActiveColor = R.propOr(activeColor, 'themeActiveColor', customTheme);
      themeData.themeBackgroundPrimaryColor = R.propOr(backgroundColor, 'themeBackgroundPrimaryColor', customTheme);
      themeData.themeBackgroundSecondaryColor = R.propOr(backgroundColor, 'themeBackgroundSecondaryColor', customTheme);
      themeData.themeBorderColor = R.propOr(borderColor, 'themeBorderColor', customTheme);
      themeData.themeButtonBorderColor = R.propOr(borderColor, 'themeButtonBorderColor', customTheme);
      themeData.themeButtonColor = R.propOr(neutralColor, 'themeButtonColor', customTheme);
      themeData.themeButtonHoverColor = R.propOr(neutralHoverColor, 'themeButtonHoverColor', customTheme);
      themeData.themeButtonHoverTextColor = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor', customTheme);
      themeData.themeButtonHoverTextColor1 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor1', customTheme);
      themeData.themeButtonTextColor = R.propOr(textColor, 'themeButtonTextColor', customTheme);
      themeData.themeButtonBorderColor1 = R.propOr(borderColor, 'themeButtonBorderColor1', customTheme);
      themeData.themeButtonColor1 = R.propOr(neutralColor, 'themeButtonColor1', customTheme);
      themeData.themeButtonHoverColor1 = R.propOr(neutralHoverColor, 'themeButtonHoverColor1', customTheme);
      themeData.themeButtonTextColor1 = R.propOr(textColor, 'themeButtonTextColor1', customTheme);
      themeData.themeButtonHoverTextColor2 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor2', customTheme);
      themeData.themeButtonBorderColor2 = R.propOr(borderColor, 'themeButtonBorderColor2', customTheme);
      themeData.themeButtonColor2 = R.propOr(neutralColor, 'themeButtonColor2', customTheme);
      themeData.themeButtonHoverColor2 = R.propOr(neutralHoverColor, 'themeButtonHoverColor2', customTheme);
      themeData.themeButtonTextColor2 = R.propOr(textColor, 'themeButtonTextColor2', customTheme);
      themeData.themeButtonHoverTextColor3 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor3', customTheme);
      themeData.themeButtonBorderColor3 = R.propOr(borderColor, 'themeButtonBorderColor3', customTheme);
      themeData.themeButtonColor3 = R.propOr(neutralColor, 'themeButtonColor3', customTheme);
      themeData.themeButtonHoverColor3 = R.propOr(neutralHoverColor, 'themeButtonHoverColor3', customTheme);
      themeData.themeButtonTextColor3 = R.propOr(textColor, 'themeButtonTextColor3', customTheme);
      themeData.themeButtonBorderRadius = R.prop('themeButtonBorderRadius')(customTheme) || '20px';
      themeData.themeButtonBorderRadius2 = R.prop('themeButtonBorderRadius2')(customTheme) || '20px';
      themeData.themeButtonBorderRadius3 = R.prop('themeButtonBorderRadius3')(customTheme) || '20px';
      themeData.themeChatButtonColor = R.propOr(chatColor, 'themeChatButtonColor', customTheme);
      themeData.themeChatButtonHoverColor = R.propOr(chatHoverColor, 'themeChatButtonHoverColor', customTheme);
      themeData.themeChatButtonTextColor = R.propOr(chatTextColor, 'themeChatButtonTextColor', customTheme);
      themeData.themeCountDownColor = R.propOr(secondaryColor, 'themeCountDownColor', customTheme);
      themeData.themeFooterBackgroundColor = R.propOr(secondaryBackgroundColor, 'themeFooterBackgroundColor', customTheme);
      themeData.themeFooterLinkColor = R.propOr(secondaryBackgroundLinkColor, 'themeFooterLinkColor', customTheme);
      themeData.themeFooterLinkHoverColor = R.propOr(secondaryBackgroundLinkHoverColor, 'themeFooterLinkHoverColor', customTheme);
      themeData.themeFooterTextColor = R.propOr(secondaryBackgroundTextColor, 'themeFooterTextColor', customTheme);
      themeData.themeInputBackgroundColor = R.propOr(backgroundColor, 'themeInputBackgroundColor', customTheme);
      themeData.themeInputBorderColor = R.propOr(borderColor, 'themeInputBorderColor', customTheme);
      themeData.themeInputBorderFocusColor = R.propOr(borderColor, 'themeInputBorderFocusColor', customTheme);
      themeData.themeLinkColor = R.propOr(linkColor, 'themeLinkColor', customTheme);
      themeData.themeLinkHoverColor = R.propOr(linkHoverColor, 'themeLinkHoverColor', customTheme);
      themeData.themeMenuBackgroundPrimaryColor = R.propOr(backgroundColor, 'themeMenuBackgroundPrimaryColor', customTheme);
      themeData.themeMenuBackgroundSecondaryColor = R.propOr(backgroundColor, 'themeMenuBackgroundSecondaryColor', customTheme);
      themeData.themeMenuBorderColor = R.propOr(borderColor, 'themeMenuBorderColor', customTheme);
      themeData.themeMenuLinkColor = R.propOr(linkColor, 'themeMenuLinkColor', customTheme);
      themeData.themeMenuLinkHoverColor = R.propOr(linkHoverColor, 'themeMenuLinkHoverColor', customTheme);
      themeData.themeSaleColor = R.propOr(saleColor, 'themeSaleColor', customTheme);
      themeData.themeSaleTextColor = R.propOr(saleTextColor, 'themeSaleTextColor', customTheme);
      themeData.themeText2Color = R.propOr(textColor, 'themeText2Color', customTheme);
      themeData.themeText3Color = R.propOr(textColor, 'themeText3Color', customTheme);
      themeData.themeTextColor = R.propOr(textColor, 'themeTextColor', customTheme);

      if (themeData) {
        let stylesToInsert = ':root {';
        Object.keys(themeData).forEach(key => {
          stylesToInsert += `--${key}: ${themeData[key]} !important;`;
        });
        stylesToInsert += '}';
        return stylesToInsert;
      }
    }

  });

  _exports.default = _default;
});