define("client/pods/components/channels/shop/shipping-zones/panels-edit/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopShippingZonesPanelsEditComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class ChannelsShopShippingZonesPanelsEditComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor3, this);
      (0, _defineProperty2.default)(this, "adapterName", 'channels/shop/shipping-zones');
      const model = this.args.tab.model;
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model
      });
      Ember.set(this, 'dirty', dirty);
    }

    getActiveSubTab() {
      const activeSubTab = this.tabsManager.getActiveSubTab(this.args.tab);
      return {
        loadInstructions: {
          component: activeSubTab.get('component')
        }
      };
    }

    _display(model) {
      const tabOptions = this.getActiveSubTab();
      this.onDisplay(model, tabOptions);
    }

    afterCancelEditing(original) {
      this._display(original);
    }

    afterUpdateRecordTask(model) {
      this._display(model);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopShippingZonesPanelsEditComponent;
});