define("client/pods/grapesjs/plugins/tabs/components/TabContent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.role = void 0;
  const role = 'tabpanel';
  _exports.role = role;

  var _default = (dc, config) => {
    dc.addType(config.typeTabContent, {
      model: {
        defaults: {
          name: 'Tab Content',
          draggable: false,
          copyable: false,
          removable: false,
          highlightable: false,
          attributes: {
            role
          },
          classes: config.classTabContent,
          traits: [],
          ...config.tabContentProps
        }
      }
    });
  };

  _exports.default = _default;
});