define("client/pods/components/channels/shop/campaigns/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJMptlS9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"channels/shop/campaigns/campaigns-editor\",null,[[\"tab\",\"model\",\"errors\",\"isEditing\",\"isNew\",\"isReadonly\",\"onAddItem\",\"onEditItem\",\"onDisplayItem\",\"saveTask\",\"removeRecordTask\",\"onSetAsDefaultImageTask\",\"onDash\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"dirty\"]],[24,[\"errors\"]],true,false,false,[24,[\"onAddItem\"]],[24,[\"onEditItem\"]],[24,[\"onDisplayItem\"]],[24,[\"replaceRecordTask\"]],[24,[\"removeRecordTask\"]],[24,[\"onSetAsDefaultImageTask\"]],[24,[\"onDash\"]],[28,\"action\",[[23,0,[]],\"cancel\",[24,[\"dirty\"]]],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});