define("client/pods/components/files/image-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0yC98of3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showImage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",false],[12,\"loading\",\"lazy\"],[12,\"class\",[29,[\"files__image-item \",[22,\"imageClassNames\"]]]],[12,\"src\",[29,[[28,\"uploadcareSrc\",[[24,[\"image\"]],[24,[\"_transform\"]]],null]]]],[12,\"alt\",\"Placeholder image\"],[3,\"action\",[[23,0,[]],\"onSubmit\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/image-item/template.hbs"
    }
  });

  _exports.default = _default;
});