define("client/mixins/emails-dispatched", ["exports", "ramda", "ember-concurrency", "client/utils/nventor"], function (_exports, R, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getToSendBatchEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const response = yield this.server.call('GET', 'api/protected/documents/emails/process', {
          dispatchDateZ,
          dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
          dispatchProcess: dispatchProcess.getData('code')
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);

        _nventor.default.throw('getToSendBatchEmailsTask failed', err);

        return false;
      }
    }),
    sendBatchEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      subject,
      message,
      dispatchProcess,
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const resp = yield this.server.call('PUT', 'api/protected/documents/emails/process', {
          subject,
          message,
          dispatchDateZ,
          dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
          dispatchProcess: dispatchProcess.getData('code')
        });
        let sent = 0;

        if (resp.data) {
          sent = resp.data.length;
        }

        onCloseDialogAction();
        window.alert('sent: ' + sent + ' emails.');
      } catch (err) {
        this.setValidationErrors(errorsProp, err);

        _nventor.default.throw('getToSendBatchEmailsTask failed', err);

        return false;
      }
    }),
    getToSendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const response = yield this.server.call('GET', 'api/protected/documents/emails/dispatched', {
          dispatchDateZ
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);

        _nventor.default.throw('getToSendBatchEmailsTask failed', err);

        return false;
      }
    }),
    sendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      useDefaultDispatchedEmailMessage,
      subject,
      message,
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');

        if (useDefaultDispatchedEmailMessage) {
          subject = '';
          message = '';
        }

        const resp = yield this.server.call('PUT', 'api/protected/documents/emails/dispatched', {
          subject,
          message,
          dispatchDateZ,
          useDefaultDispatchedEmailMessage
        });
        let sent = 0;

        if (resp.data) {
          sent = resp.data.length;
        }

        onCloseDialogAction();
        window.alert('sent: ' + sent + ' emails.');
      } catch (err) {
        this.setValidationErrors(errorsProp, err);

        _nventor.default.throw('getToSendBatchEmailsTask failed', err);

        return false;
      }
    }),
    getToSendDispatchedEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      const docKey = this.get('model._data._key');

      try {
        this.clearValidationErrors(errorsProp);
        const docType = this.get('model._data.docType');
        const docName = this.get('model._data.docName');
        const response = yield this.server.call('GET', `api/protected/documents/emails/dispatched/${docKey}`, {
          docType,
          docName
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);

        _nventor.default.throw('getToSendBatchEmailsTask failed', err);

        return false;
      }
    }),
    sendDispatchedEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      useDefaultDispatchedEmailMessage,
      message,
      dispatchDateZ,
      docKey,
      docType,
      docName
    }) {
      dispatchDateZ = dispatchDateZ || this.get('period.dateZ');

      if (useDefaultDispatchedEmailMessage) {
        message = '';
      }

      const resp = yield this.server.call('PUT', `api/protected/documents/emails/dispatched/${docKey}`, {
        message,
        dispatchDateZ,
        docType,
        docName
      });
      const email = R.path(['data', 'email'], resp);
      let msg = this.intl.t('email not sent');

      if (email) {
        msg = this.intl.t('email sent to') + ': ' + email;
        onCloseDialogAction();
      }

      window.alert(msg);
    })
  });

  _exports.default = _default;
});