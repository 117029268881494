define("client/pods/components/importer/import-btn/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let ImporterDataImportComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class ImporterDataImportComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "adapterName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "batchString", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "showImportModal", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "rowsData", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "response", _descriptor7, this);
      this.adapterName = this.args.adapterName;
      this.columns = this.args.columns;
      this.response = false;
    }

    toggleImportModal() {
      this.showImportModal = !this.showImportModal;
    }

    next(onCloseDialogAction) {
      this.response = false;
      const batchDataString = this.batchString || ''; // let batchData = {}
      // try {
      //   // data is json
      //   batchData = JSON.parse(batchDataString)
      // } catch (err) {
      // convert lines to json

      const byRows = R.pipe(R.trim, R.split(/\n/g), R.reject(RA.isNilOrEmpty), R.map(R.trim), R.map(R.split(/\t/g)))(batchDataString);

      const possibleColumns = _nventor.default.safeHeadOr([])(byRows);

      if (RA.isNilOrEmpty(possibleColumns)) {
        return {};
      }

      this.rowsData = byRows; // }

      onCloseDialogAction();
      this.showImportModal = true;
    }

    cancel() {
      this.batchString = '';
    }

    *importTask() {
      const rowsData = this.rowsData;
      const columnsString = this.columns || '';
      const columns = R.split(' ')(columnsString);
      const batchData = R.map(row => {
        return R.zipObj(columns)(row);
      })(rowsData);
      const self = this;
      return this.dataManager.batchImport({
        adapterName: this.adapterName,
        batchData
      }).then(response => {
        this.response = response;
        this.batchString = '';
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "adapterName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "batchString", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showImportModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rowsData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "response", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleImportModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleImportModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "next", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "importTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "importTask"), _class.prototype)), _class));
  _exports.default = ImporterDataImportComponent;
});