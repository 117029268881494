define("client/pods/components/documentations/documentation/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let DocumentationComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class DocumentationComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "documentation", _descriptor2, this);
      (0, _defineProperty2.default)(this, "adapterName", 'documentation');
    }

    // constructor () {
    //   super(...arguments)
    //   this.crud.addLists(this, [
    //     'documentation/lists/tags',
    //     'statuses'
    //   ])
    // }
    get documentationId() {// if (this.args.options) {
      //   const { documentationId, documentationResource, context } = this.args.options
      //   if (documentationId) {
      //     return documentationId
      //   }
      //   if (documentationResource && context) {
      //     return `${documentationResource}-${context}`
      //   }
      //   return null
      // }
      // return null
    }

    updateDocumentation(model) {
      this.documentation = model;
    }

    deleteDocumentation() {
      this.documentation = {};
    }

    *setupTask() {
      if (this.documentationId) {// @TODO: re-enable
        // this.documentation = yield this.crud.findTask.perform({
        //   adapterName: this.adapterName,
        //   appendPath: this.documentationId || ''
        // })
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documentation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateDocumentation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateDocumentation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteDocumentation", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteDocumentation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class));
  _exports.default = DocumentationComponent;
});