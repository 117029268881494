define("client/pods/index/route", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // inject ember intl service
    auth: Ember.inject.service(),
    queryParams: {
      reload: {
        refreshModel: true
      },
      lang: {
        refreshModel: false
      }
    },

    model(params) {
      return {
        params
      };
    },

    redirect(model) {
      const params = model.params || {}; // debugger

      if (params.reload) {
        _nventor.default.removeAndUpdateQueryParams(['reload']);

        window.location.reload();
      }
    }

  });

  _exports.default = _default;
});