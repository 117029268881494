define("client/pods/components/channels/shop/campaigns/page-import-btn/campaigns-details-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NOZahsoD",
    "block": "{\"symbols\":[\"detail\",\"templateModel\"],\"statements\":[[4,\"each\",[[24,[\"campaignsDetailsList\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"_data\",\"templates\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"page-import-btn__item-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[23,1,[\"_data\",\"item\"]],false],[0,\"\\n\"],[4,\"if\",[[23,2,[\"_data\",\"header\"]]],null,{\"statements\":[[0,\"          - \"],[1,[23,2,[\"_data\",\"header\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"page-import-btn__template-preview\"],[8],[0,\"\\n        \"],[1,[28,\"helpers/content-display\",null,[[\"content\"],[[23,2,[\"_data\",\"template\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"replace\",[28,\"action\",[[23,0,[]],[24,[\"onReplaceTemplate\"]],[23,2,[\"_data\",\"template\"]],[23,1,[\"_data\",\"templateImages\"]]],null]]]],false],[0,\"\\n      \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"insert\",[28,\"action\",[[23,0,[]],[24,[\"onInsertTemplate\"]],[23,2,[\"_data\",\"template\"]],[23,1,[\"_data\",\"templateImages\"]]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"helpers/pagination-controls\",null,[[\"showPage\",\"filters\",\"list\",\"onPaginationTask\"],[true,[24,[\"campaignsFilter\"]],[24,[\"campaignsDetailsList\"]],[24,[\"onPaginationTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/page-import-btn/campaigns-details-results/template.hbs"
    }
  });

  _exports.default = _default;
});