define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/component", ["exports", "ramda", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices-printer", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _invoicesPrinter, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _invoicesPrinter.default, {
    tagName: '',
    document: null,
    invoices: null,
    isDisplayNumberOnly: false,
    adapterName: 'extensions/apps/tw/e-invoices',
    collectionAdapterName: 'extensions/apps/tw/e-invoices/collection',
    hasManagementBtns: true,
    hasPrintBtnLabel: true,
    autoFetch: true,

    init() {
      this._super(...arguments);

      this.set('taxNumber', this.get('document._data.taxNumber'));
      this.set('email', this.get('document._data.email'));
      this.set('telephone', this.get('document._data.telephone'));
      this.set('address', this.get('document._data.address'));
    },

    didInsertElement() {
      this._super(...arguments);

      const document = this.document;
      const isAllowedEInvoice = this.isAllowedEInvoice;

      if (isAllowedEInvoice && this.autoFetch) {
        const invoiceMasterKey = document.getData('_key');
        this.set('invoiceMasterKey', invoiceMasterKey);
        this.fetchEInvoicesTask.perform({
          master: invoiceMasterKey
        });
      }

      this.autoUpdatePendingStatus();
    },

    isAllowedEInvoice: Ember.computed('isAllowed', 'document._data.{autoPrintInvoices,isDispatched}', 'settings.settingsModel._data.autoPrintInvoices', function () {
      const document = this.document;

      if (!document._data.isDispatched && this.isAllowed !== true) {
        return false;
      }

      return this.settings.hasTwEInvoices(document);
    }),

    autoUpdatePendingStatus() {
      const isAllowedEInvoice = this.isAllowedEInvoice;

      if (isAllowedEInvoice) {
        const hasPendingEInvoices = this.hasPendingEInvoices;

        if (!R.isEmpty(hasPendingEInvoices)) {
          R.forEach(invoice => {
            this.queryEInvoiceTask.perform(invoice);
          })(hasPendingEInvoices);
        }
      }
    },

    eInvoicesCollection: Ember.computed('document.eInvoicesCollection.[]', function () {
      return this.get('document.eInvoicesCollection') || [];
    }),
    eInvoicesModels: Ember.computed('eInvoicesCollection._data.invoices.[]', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      return R.sortWith([R.descend(R.path(['_data', 'timestampZ']))])(invoices);
    }),
    eInvoicesData: Ember.computed('eInvoicesCollection._data.invoices.[]', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      return R.pipe(R.pluck('_data'), R.sortWith([R.descend(R.path(['timestampZ']))]))(invoices);
    }),
    hasPendingEInvoices: Ember.computed('eInvoicesModels.[]', function () {
      const eInvoicesModels = this.eInvoicesModels || [];
      const pendingInvoices = R.filter(R.anyPass([R.pathEq(['_data', 'status'], 'pending'), R.pathEq(['_data', 'status'], 'pendingCancellation')]))(eInvoicesModels);
      return pendingInvoices;
    }),
    hasPrintBtn: Ember.computed('eInvoicesData.@each.status', function () {
      const invoices = this.eInvoicesData || [];
      return R.pipe(R.reject(R.anyPass([R.propEq('status', 'cancelled'), R.propEq('status', 'unsuccessful')])), R.isEmpty)(invoices);
    }),

    getLastSuccessfulEInvoice(eInvoicesCollection) {
      const invoices = R.pathOr([], ['_data', 'invoices'])(eInvoicesCollection);
      const successful = R.filter(R.pathEq(['_data', 'status'], 'successful'), invoices);

      if (successful.length > 0) {
        return R.head(successful);
      }

      return false;
    },

    fetchEInvoicesTask: (0, _emberConcurrency.task)(function* ({
      master
    }) {
      const collectionAdapterName = this.collectionAdapterName;
      const params = {
        master
      };
      const eInvoicesCollection = yield this.findTask.perform({
        adapterName: collectionAdapterName,
        params,
        resultsProperty: false
      }); // this.set('documentWithReturns', documentWithReturns)

      this.set('document.eInvoicesCollection', eInvoicesCollection);
      this.document.updateSynced();
      this.set('lastSuccessfulEInvoice', this.getLastSuccessfulEInvoice(eInvoicesCollection));
    }),
    latestInvoiceData: Ember.computed('eInvoicesData.@each.status', function () {
      const invoices = this.eInvoicesData || [];
      return invoices.get('firstObject') || false;
    }),
    invoiceBtnLabel: Ember.computed('eInvoicesData.@each.status', function () {
      const latestInvoiceData = this.latestInvoiceData;

      if (R.propEq('status', 'successful', latestInvoiceData)) {
        return latestInvoiceData.number;
      }

      return this.intl.t('tw_invoicing');
    }),
    invoiceBtnClass: Ember.computed('eInvoicesData.@each.status', function () {
      const latestInvoiceModel = this.eInvoicesModels.get('firstObject');

      if (!latestInvoiceModel) {
        return '';
      }

      return latestInvoiceModel.get('statusClassName') || 'is-danger';
    }),
    actions: {
      fetchInvoices() {
        const document = this.document;
        const invoiceMasterKey = document.getData('_key');
        this.fetchEInvoicesTask.perform({
          master: invoiceMasterKey
        });
      }

    }
  });

  _exports.default = _default;
});