define("client/pods/components/documentations/documentation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ed69XxPd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"setupTask\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"documentations/documentation-icon\",[],[[\"@documentation\",\"@documentationId\",\"@component\"],[[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]],[23,0,[]]]]],[0,\"\\n\\n    \"],[5,\"documentations/documentation-desc\",[],[[\"@documentation\",\"@documentationId\"],[[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentations/documentation/template.hbs"
    }
  });

  _exports.default = _default;
});