define("client/pods/websites/lists/shared-color-themes/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/websites/sharedColorThemes',
    modelObj: _model.default,
    idParam: false // findAll () {
    //   const data = [{
    //     value: 'privateTemplates',
    //     label: 'private templates',
    //     component: 'websites/websites-editor/templates/private-template'
    //   }]
    //
    //   return nventor.resolve(data)
    // }

  });

  _exports.default = _default;
});