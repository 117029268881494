define("client/mixins/contacts-details", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.setData('details', []);
    },

    /**
     * transforms details array into array of models
     * (should be called before populate)
     */
    transformDetails(rawData) {
      const self = this;

      if (Ember.isArray(rawData.details)) {
        const transformed = rawData.details.map(function (data) {
          return self._createDetailModel(data);
        });
        rawData.details = transformed;
      }
    },

    /**
     * create detail model
     */
    _createDetailModel(data) {
      return this.dataManager.setAsRecord({
        adapterName: 'contacts/details',
        data
      });
    },

    addDetail(data) {
      data = data || {};

      const detail = this._createDetailModel(data);

      const details = this.getOrSetData('details', []);

      const detailId = _nventor.default.random.alphaNum(8);

      detail.setData('detailId', detailId);
      this.setIfNotHasDefault(detail);
      details.pushObject(detail);
      return detail;
    },

    removeDetail(detail) {
      const details = this.getData('details');
      details.removeObject(detail);
    },

    // setFirstAsDefault (detail) {
    //   const type = detail.getData('type')
    //   if (type !== 'note') {
    //     const details = this.getData('details')
    //     let found
    //     if (type === 'address') {
    //       found = details.findBy('_data.type', type)
    //     } else {
    //       const subType = detail.getData('subType')
    //       found = nventor.findByDotPaths({
    //         '_data.type': type,
    //         '_data.subType': subType
    //       }, details)
    //     }
    //     if (!found) {
    //       detail.setData('isDefault', true)
    //     }
    //   }
    // },
    setIfNotHasDefault(detail) {
      const type = detail.getData('type');

      if (type !== 'note') {
        const details = this.getData('details');
        let found;

        if (type === 'address') {
          found = _nventor.default.findByDotPaths({
            '_data.type': type,
            '_data.isDefault': true
          }, details);
        } else {
          const subType = detail.getData('subType');
          found = _nventor.default.findByDotPaths({
            '_data.type': type,
            '_data.subType': subType,
            '_data.isDefault': true
          }, details);
        }

        if (!found) {
          detail.setData('isDefault', true);
        }
      }
    }

  });

  _exports.default = _default;
});