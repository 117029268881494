define("client/pods/channels/shop/shipping-zones/surcharges/model", ["exports", "ramda", "client/pods/base/model"], function (_exports, R, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        surchargeId: '',
        label: '',
        rate: ''
      };
    },

    populate(data, attrs) {
      this._super(data, attrs);
    }

  });

  _exports.default = _default;
});