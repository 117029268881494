define("client/pods/components/menus/nav-tree/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yyS6Dqdy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"item\",\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"classNames\",\"icon\"],[\"nav-tree__icon\",[24,[\"item\",\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-tree/nav-item/template.hbs"
    }
  });

  _exports.default = _default;
});