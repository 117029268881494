define("client/pods/components/specs/specs-editor/specs-table/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let SpecsSpecsEditorSpecsTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class SpecsSpecsEditorSpecsTableComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "dragAndDrop", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _defineProperty2.default)(this, "tagName", 'span');
      (0, _defineProperty2.default)(this, "classNames", []);
      (0, _defineProperty2.default)(this, "translate", true);
      (0, _defineProperty2.default)(this, "item", null);
      (0, _defineProperty2.default)(this, "isViewable", true);
      (0, _defineProperty2.default)(this, "labelKey", 'label');
      (0, _defineProperty2.default)(this, "isDraggable", true);
      (0, _defineProperty2.default)(this, "hasDropBelowContainer", true);
      (0, _defineProperty2.default)(this, "hasDropAboveContainer", true);
      (0, _defineProperty2.default)(this, "draggable", true);
      (0, _initializerDefineProperty2.default)(this, "productsFilters", _descriptor4, this);
      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }]
        }
      });
      this.productsFilters = productsFilters;
    }

    get _hasDropAboveContainer() {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }

      return false;
    }
    /**
     * search product
     */


    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query.trim() !== '') {
        const filters = this.productsFilters; // filters.setData('query', query)

        const results = yield this.crud.searchInputTask.perform({
          adapterName: 'products',
          filters,
          component: this,
          resultsProperty: 'productsList'
        }, query);
        return results;
      }
    }

    drop() {
      if (this.onDrop) {
        this.onDrop(...arguments);
      }
    }

    select(disabled, item) {
      if (!disabled) {
        this.onSelect(item);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dragAndDrop", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "productsFilters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drop", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "select", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = SpecsSpecsEditorSpecsTableComponent;
});