define("client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices-printer", ["exports", "ramda", "ember-concurrency", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: move fetchInvoice and cancelInvoice out of this mixin,
  // those are for the outer components whereas the other functions are for inner components
  // outer component: makes use of 'invoices' (array of invoices for given masterKey)
  // inner component: only makes use of 1 invoice
  var _default = Ember.Mixin.create(_crud.default, {
    intl: Ember.inject.service(),
    constants: Ember.inject.service(),
    printError: '',
    // old..to remove
    printErrors: '',
    notifyEInvoiceTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, invoice, custom = {}) {
      const adapterName = this.adapterName;
      const data = {
        _key: invoice.getData('_key'),
        email: custom.email,
        telephone: custom.telephone
      };
      const adapter = this.server.getAdapter(adapterName);
      const path = `${adapter.resourcePath}/notify`;

      try {
        const response = yield this.server.call('POST', path, data);
        onCloseDialogAction();
        return response;
      } catch (err) {
        this.setValidationErrors('notifyErrors', err);
      }
    }).drop(),
    queryEInvoiceTask: (0, _emberConcurrency.task)(function* (invoice) {// const adapterName = this.adapterName
      // const data = {
      //   _key: invoice.getData('_key')
      // }
      // const adapter = this.server.getAdapter(adapterName)
      // const path = `${adapter.resourcePath}`
      // try {
      //   const response = yield this.server.call('PATCH', path, data)
      //   return response
      // } catch (err) {
      //   this.setValidationErrors('notifyErrors', err)
      // }
    }).enqueue().maxConcurrency(1),
    removeEInvoiceTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, models) {
      const adapterName = 'extensions/apps/tw/e-invoices';
      const errorsProp = 'cancelErrors';
      const msg = this.intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        R.forEach(model => {
          model.setData('reason', model.reason);
          model.setData('status', this.constants.eInvoicesStatus.pendingCancellation);
        })(models);
        yield this.removeRecordTask.perform({
          adapterName,
          batch: models,
          errorsProp,
          noConfirm: true
        });
        onCloseDialogAction();
      }
    }),
    actions: {
      printInvoice(onCloseDialogAction, dialog) {
        onCloseDialogAction();
        const self = this;
        const number = dialog.customInvoiceNumber;
        const dateZ = dialog.customInvoiceDateZ;
        const document = self.get('document');
        const invoices = [document];
        self.get('printAllInvoicesTask').perform(invoices, number, dateZ);
      }

    }
  });

  _exports.default = _default;
});