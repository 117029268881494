define("client/pods/components/layouts/table-view/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showFilters: false,
    headerRowComponent: '',
    filtersRowComponent: '',
    detailsRowComponent: '',
    hasHeaders: true,
    hasHeadersSort: true,
    hasFilters: true,
    paginationIsFooter: true,
    showPage: true,

    init() {
      this._super(...arguments);

      const detailsRowComponent = this.detailsRowComponent;
      const headerRowComponent = this.headerRowComponent;

      if (!headerRowComponent) {
        this.set('headerRowComponent', detailsRowComponent);
      }

      const filtersRowComponent = this.filtersRowComponent;

      if (!filtersRowComponent) {
        this.set('filtersRowComponent', detailsRowComponent);
      }

      const filters = this.filters;

      if (filters) {
        const currentFiltersData = this.getCurrentFiltersState();
        this.setCurrentFiltersState(currentFiltersData);
      }
    },

    getCurrentFiltersState() {
      const filters = this.filters;
      return filters.serialize();
    },

    setCurrentFiltersState(currentFiltersData) {
      this.set('previousFiltersData', currentFiltersData);
    },

    updateFiltersAndSearchTask: (0, _emberConcurrency.task)(function* () {
      if (this.filters) {
        const previousFiltersData = this.previousFiltersData;
        const currentFiltersData = this.getCurrentFiltersState();

        if (!R.equals(previousFiltersData, currentFiltersData)) {
          this.setCurrentFiltersState(currentFiltersData);

          if (this.onSearchTask.perform) {
            return this.onSearchTask.linked().perform(...arguments);
          }

          return this.onSearchTask({
            filters: this.filters
          });
        }
      }
    }),

    getElementFromNextAvailableRow(direction, currentRow, elementClassName, e) {
      let nextRow;

      if (direction === 'next') {
        nextRow = $(currentRow).next();
      } else {
        nextRow = $(currentRow).prev();
      }

      if (nextRow.length === 0) {
        return false;
      }

      const nextInput = nextRow.find(`.${elementClassName} input`);

      if (nextInput.length === 0) {
        return this.getElementFromNextAvailableRow(direction, nextRow, elementClassName, e);
      }

      return nextInput;
    },

    goToNextRow(elementClassName, e) {
      // get current row...go up or down based on key pressed
      const keyCode = e.keyCode || e.which; // go up 1 row

      if (keyCode === 38) {
        e.preventDefault();
        const parentRow = $(e.target).closest('.table-view__detail-row');
        const prevInput = this.getElementFromNextAvailableRow('prev', parentRow, elementClassName, e);

        if (prevInput) {
          prevInput.select();
          prevInput.focus();
        }
      } // go down 1 row


      if (keyCode === 40) {
        e.preventDefault();
        const parentRow = $(e.target).closest('.table-view__detail-row');
        const nextInput = this.getElementFromNextAvailableRow('next', parentRow, elementClassName, e);

        if (nextInput) {
          nextInput.select();
          nextInput.focus();
        }
      }
    },

    actions: {
      goToNextRow(elementClassName, e) {
        this.goToNextRow(elementClassName, e);
      }

    }
  });

  _exports.default = _default;
});