define("client/pods/components/channels/shop/items/item-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bYYV00VR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"item\",\"isOnSale\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"or\",[[28,\"lte\",[[24,[\"item\",\"oldPrice\"]],\"0\"],null],[28,\"eq\",[[24,[\"item\",\"oldPrice\"]],[24,[\"item\",\"currentPrice\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"channels/shop/items/item-price/item-price-old\",null,[[\"campaign\",\"item\",\"hasStrikeThrough\"],[[24,[\"campaign\"]],[24,[\"item\"]],[24,[\"hasStrikeThrough\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"store-item__price \",[28,\"if\",[[24,[\"item\",\"isOnSale\"]],\"store-item__price--sale\"],null]]]],[8],[0,\"\\n  \"],[1,[28,\"channels/shop/items/item-price/item-price-current\",null,[[\"campaign\",\"item\"],[[24,[\"campaign\"]],[24,[\"item\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/item-price/template.hbs"
    }
  });

  _exports.default = _default;
});