define("client/mixins/hct-fetch", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fetchHCTRegionDataTask: (0, _emberConcurrency.task)(function* (model) {
      model.setData('transporterRegion', '');
      model.setData('transporterRegionIsOuter', '');
      const data = {
        address: model.getData('address')
      };

      try {
        const result = yield this.server.call('GET', `api/protected/extensions/apps/tw/transporters/hct`, data);
        model.set('isFetching', false);
        let region = '';

        if (result.data.region) {
          region = result.data.region;
          model.setData('transporterRegion', result.data.region);
          model.setData('transporterRegionIsOuter', result.data.isOuter);
        } else {
          model.setData('transporterRegion', '');
          model.setData('transporterRegionIsOuter', '');
        }

        return region;
      } catch (err) {
        this.set('errors', err);
      }
    }),

    // async fetchHCTRegionData (model) {
    //   // model.set('isFetching', true)
    //   this.get('fetchHCTRegionDataTask').perform(model)
    // },
    isHCTIncomplete(model) {
      let errors = [];
      let hasErrors = false;

      if (model.getData('transporterExtension') === 'hct') {
        if (!model.getData('address')) {
          errors.push({
            context: {
              key: 'address'
            },
            message: 'required'
          });
          hasErrors = true;
        }

        if (model.getData('transporterLabel') === false || model.getData('transporterLabel') === 'false') {
          errors.push({
            context: {
              key: 'transporterLabel'
            },
            message: 'required'
          });
          hasErrors = true;
        }

        const packages = parseInt(model.getData('packages'));

        if (packages <= 0 || isNaN(packages)) {
          errors.push({
            context: {
              key: 'packages'
            },
            message: 'required'
          });
          hasErrors = true;
        }

        if (!model.getData('telephone')) {
          errors.push({
            context: {
              key: 'telephone'
            },
            message: 'required'
          });
          hasErrors = true;
        }

        if (!model.getData('transporterAccountNumber')) {
          errors.push({
            context: {
              key: 'transporterAccountNumber'
            },
            message: 'required'
          });
          hasErrors = true;
        }

        if (!model.getData('transporterRegion')) {
          errors.push({
            context: {
              key: 'transporterRegion'
            },
            message: 'hct_region_error'
          });
          hasErrors = true;
        }
      }

      if (hasErrors) {
        this.set('errors', errors);
        return errors;
      }

      this.set('errors', '');
      return false;
    }

  });

  _exports.default = _default;
});