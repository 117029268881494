define("client/pods/channels/shop/rewards/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        name: '',
        websites: [],
        emailFromName: '',
        emailFromAddress: '',
        emailSignature: '',
        emailContent: '',
        status: _constants.default.status.active
      };
    },

    populate(data, attrs) {
      data.memberLevels = this._transformMemberLevels(data);

      this._super(data, attrs);
    },

    _transformMemberLevels(data) {
      const memberLevels = R.propOr([], 'memberLevels')(data);
      return R.map(memberLevel => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels',
          data: memberLevel
        });
      })(memberLevels);
    },

    addMemberLevel(data = {}) {
      const memberLevels = this.getData('memberLevels') || [];
      const memberLevelModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards/member-levels',
        data
      });
      memberLevels.pushObject(memberLevelModel);
      this.setData('memberLevels', memberLevels);
      memberLevelModel.addRewardEvent();
    }

  });

  _exports.default = _default;
});