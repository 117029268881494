define("client/pods/components/files/uploadcare-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kXPFVkBN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"role\",\"uploadcare-uploader\"],[11,\"name\",[22,\"name\"]],[11,\"data-public-key\",[22,\"publicKey\"]],[11,\"data-multiple\",[22,\"multiple\"]],[11,\"data-multiple-max\",[22,\"multipleMax\"]],[11,\"data-multiple-min\",[22,\"multipleMin\"]],[11,\"data-images-only\",[22,\"imagesOnly\"]],[10,\"data-preview-step\",\"false\"],[11,\"data-crop\",[22,\"crop\"]],[11,\"data-image-shrink\",[22,\"imageShrink\"]],[11,\"data-clearable\",[22,\"clearable\"]],[11,\"data-secure-signature\",[22,\"secureSignature\"]],[11,\"data-secure-expire\",[22,\"secureExpire\"]],[11,\"data-tabs\",[22,\"tabs\"]],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n\\n\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"icon\",\"onSubmit\"],[[24,[\"label\"]],[24,[\"icon\"]],[28,\"action\",[[23,0,[]],\"triggerUploadBtn\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/uploadcare-widget/template.hbs"
    }
  });

  _exports.default = _default;
});