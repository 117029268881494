define("client/pods/channels/shop/campaigns/model", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "client/pods/base/model", "client/mixins/templates", "client/mixins/sales-persons", "client/mixins/sortable-details", "client/utils/nventor", "client/pods/faqs/model", "ramda", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, _model, _templates, _salesPersons, _sortableDetails, _nventor, _model2, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var _default = _model.default.extend(_salesPersons.default, _sortableDetails.default, _templates.default, (_dec = Ember._action, (_obj = {
    users: Ember.inject.service(),
    crud: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    indexProp: '_data.index',
    toSortProp: 'details',

    init() {
      this._super(...arguments);

      this.set('requiredDataAttrs', ['name', 'description', 'shippingFee', 'shippingFeeCondition']);
      this.set('detailsRequiredAttrs', ['itemKey', 'item']);
      this.set('draftRequiredDataAttrs', ['name']);
      this.set('childAdapters.salesPersons', 'users');
      this.set('childAdapters.automations', 'channels/shop/campaigns/automations/details');
      this.set('childAdapters.products', 'products');
      this.set('childAdapters.details', 'channels/shop/campaigns/details');
      this.set('childAdapters.choices', 'products/choices');
      this.set('childAdapters.choices-details', 'products/choices/details');
      this.set('childAdapters.sets-details', 'products/sets/details');
      this.set('childAdapters.variants', 'products/variants');
      this.set('childAdapters.variants-options', 'products/variants/options');
      this.set('childAdapters.variants-children', 'products/variants/children');
    },

    defaults() {
      const shopPaymentMethods = this.settings.getProp('shopPaymentMethods');
      const shopGroupByTags = this.settings.getProp('shopGroupByTags');
      const shopAllowShipping = this.settings.getProp('shopAllowShipping');
      const shopAllowPickup = this.settings.getProp('shopAllowPickup');
      const shopPaymentMethodsDefault = this.settings.getProp('shopPaymentMethodsDefault');
      const cashShopPaymentMethodFee = this.settings.getProp('cashShopPaymentMethodFee');
      const shopCampaignExpiredTemplate = this.settings.getProp('shopCampaignExpiredTemplate');
      const shopOverSalesLimitedQtyStatus = this.settings.getProp('shopOverSalesLimitedQtyStatus');
      const facebookPage = this.settings.getProp('facebookDefaultPage');

      const password = _nventor.default.random.alphaNum(8).toLowerCase();

      const useDefaultsShippingZones = this.settings.getProp('shopUseDefaultsShippingZones') || false;
      return {
        autoEnd: this.get('constants.togglesAuto.off'),
        showCountdownTimer: this.get('constants.togglesOnOff.off'),
        // onSale: this.get('constants.togglesOnOff.off'),
        showLimitedQty: this.get('constants.togglesOnOff.off'),
        showProductDescription: false,
        showItemDescriptionLinesCount: 2,
        deliveryDateSettings: this.get('constants.deliveryDateSettings.fromOrderDate'),
        status: this.get('constants.status.active'),
        autoPrintInvoices: this.settings.getProp('autoPrintInvoices'),
        taxMethodKey: this.get('constants.taxMethods.inclusive'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        leadTime: 0,
        shippingFee: this.settings.getProp('shippingFee'),
        shippingFeeCondition: this.settings.getProp('shippingFeeCondition'),
        useDefaultsSocialLinks: true,
        useDefaultsFacebook: true,
        useDefaultsContactInfo: true,
        facebookPluginEnable: true,
        paymentMethods: shopPaymentMethods,
        paymentMethodsDefault: shopPaymentMethodsDefault,
        cashPaymentMethodFee: cashShopPaymentMethodFee,
        expiredTemplate: shopCampaignExpiredTemplate,
        groupByTags: shopGroupByTags,
        allowShipping: shopAllowShipping,
        allowPickup: shopAllowPickup,
        overSalesLimitedQtyStatus: shopOverSalesLimitedQtyStatus,
        facebookPage: facebookPage,
        productsImagesRatio: this.settings.getProp('shopProductsImagesRatio'),
        productsOverviewStyle: this.settings.getProp('shopProductsOverviewStyle'),
        password: password,
        shippingZonesIds: [],
        useDefaultsShippingZones,
        faqs: [],
        isAllowEarnReward: true,
        isAllowSpendReward: true,
        isCheckoutNotesRequired: false,
        advancedTemplate: {}
      };
    },

    populate(data, attrs) {
      data.salesPersons = this._transformSalesPersons(data.salesPersons);
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);
      data.faqs = this._transformFaqs(data);
      data.rewardData = this._transformRewardData(data);
      data.bulkDiscounts = this._transformBulkDiscounts(data);
      data.bulkDiscountConflictDetails = this._transformBulkDiscountConflictDetails(data);
      data.advancedTemplate = this._transformAdvancedTemplate(data);

      this._super(data, attrs);
    },

    populatePartial(data, attrs) {
      data.bulkDiscountConflictDetails = this._transformBulkDiscountConflictDetails(data);

      if (data.detailsUpdated) {
        data.detailsLimitsUpdated = true;
        data.detailsUpdated = R.pipe(R.propOr([], 'detailsUpdated'), R.map(newDetailData => {
          return this.dataManager.setPartialRecord({
            adapterName: this.get('childAdapters.details'),
            data: newDetailData
          });
        }))(data);
      }

      this._super(data, attrs);
    },

    _transformBulkDiscountConflictDetails(data) {
      const bulkDiscountConflictDetails = R.propOr([], 'bulkDiscountConflictDetails')(data);
      return R.map(conflict => {
        return this.dataManager.setPartialRecord({
          adapterName: 'channels/shop/campaigns/details',
          data: conflict
        });
      })(bulkDiscountConflictDetails);
    },

    _transformRewardData(data) {
      const rewardData = R.propOr({}, 'rewardData')(data);
      const memberLevelsData = R.propOr([], 'memberLevels')(rewardData);
      const rewardDataModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards',
        data: rewardData
      });

      if (RA.isNotNilOrEmpty(memberLevelsData)) {
        rewardDataModel._data.memberLevels = R.map(memberLevel => {
          const rewardsEventsData = R.propOr([], 'rewardsEvents')(memberLevel);
          const memberLevelModel = this.dataManager.setAsRecord({
            adapterName: 'channels/shop/rewards/member-levels',
            data: memberLevel
          });

          if (RA.isNotNilOrEmpty(rewardsEventsData)) {
            memberLevelModel._data.rewardsEvents = R.map(rewardsEvent => {
              return this.dataManager.setAsRecord({
                adapterName: 'channels/shop/rewards/member-levels/rewards-events',
                data: rewardsEvent
              });
            })(rewardsEventsData);
          }

          return memberLevelModel;
        })(memberLevelsData);
      }

      return rewardDataModel;
    },

    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model2.default, faq)))(data);
    },

    _transformSalesPersons(salesPersons = []) {
      return salesPersons.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.salesPersons'),
          data
        });
      });
    },

    isOnSale: Ember.computed('_data.onSale', function () {
      if (this.get('_data.onSale') === this.get('constants.togglesOnOff.on')) {
        return true;
      }

      return false;
    }),
    isPage: Ember.computed('_data', function () {
      return true;
    }),
    hasCashPaymentFee: Ember.computed('_data.paymentMethods.[]', function () {
      const paymentMethods = this.getData('paymentMethods');

      if (paymentMethods.indexOf('cash') > -1) {
        return true;
      }

      return false;
    }),
    hasCashBeforeDelivery: Ember.computed('_data.paymentMethods.[]', function () {
      const paymentMethods = this.getData('paymentMethods');

      if (paymentMethods.indexOf('cashBeforeDelivery') > -1) {
        return true;
      }

      return false;
    }),
    hasSalesPersons: Ember.computed('_data.salesPersons.[]', function () {
      const salesPersons = this.getData('salesPersons') || [];

      if (R.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),

    addDetail(data) {
      const adapterName = this.childAdapters.details;

      const detail = this._addDetail({
        adapterName,
        data
      });

      const onSale = this.getData('onSale');
      detail.setData('onSale', onSale);
      return detail;
    },

    addAutomation(parentMaster, data) {
      const adapterName = this.childAdapters.automations;

      const detail = this._addDetail({
        adapterName,
        data
      });

      detail.setData('parentMaster', parentMaster);
      return detail;
    },

    _addDetail({
      adapterName,
      data
    }) {
      const detail = this.crud.setupNewRecord({
        adapterName,
        data
      });
      const master = this.getData('_key');
      const overSalesLimitedQtyStatus = this.getData('overSalesLimitedQtyStatus');
      detail.setData('master', master);
      detail.setData('overSalesLimitedQtyStatus', overSalesLimitedQtyStatus);
      return detail;
    },

    addFaq(faq) {
      this.getData('faqs').pushObject(faq);
    },

    hasDetailsChanged: Ember.computed('detailsUpdated.[]', function () {
      const detailsUpdated = this.detailsUpdated || [];

      if (R.isEmpty(detailsUpdated)) {
        return [];
      }

      return detailsUpdated;
    }),
    hasDetailsChangedByOthers: Ember.computed('hasDetailsChanged.[]', function () {
      const hasDetailsChanged = this.hasDetailsChanged || [];
      const uid = this.users.getProp('uid');
      const detailsUpdatedByOthers = R.reject(R.pathEq(['_data', 'creator', 'userKey'], uid))(hasDetailsChanged);

      if (R.isEmpty(detailsUpdatedByOthers)) {
        return [];
      }

      return detailsUpdatedByOthers;
    }),
    hasDetailsLimitsChanged: Ember.computed('hasDetailsChanged.[]', 'detailsLimitsUpdated', function () {
      const hasDetailsChanged = this.hasDetailsChanged || [];

      if (this.detailsLimitsUpdated) {
        if (R.isEmpty(hasDetailsChanged)) {
          return false;
        }

        return hasDetailsChanged;
      }

      return false;
    }),

    _transformBulkDiscounts(data) {
      const bulkDiscounts = R.propOr([], 'bulkDiscounts')(data);
      return R.map(bulkDiscount => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts',
          data: bulkDiscount
        });
      })(bulkDiscounts);
    },

    addBulkDiscount(bulkDiscount = {}) {
      const bulkDiscounts = this.getData('bulkDiscounts') || [];
      const bulkDiscountModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts',
        data: bulkDiscount
      });
      bulkDiscounts.pushObject(bulkDiscountModel);
      this.setData('bulkDiscounts', bulkDiscounts);
      bulkDiscountModel.addRule();
    },

    _transformAdvancedTemplate(data) {
      return R.pipe(R.propOr({}, 'advancedTemplate'), advancedTemplate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/advanced-template',
          data: {
            displayInShop: false,
            displayProductsInShop: true,
            url: data.url,
            ...advancedTemplate,
            hasTemplate: true
          }
        });
      })(data);
    },

    reorderDetails(details, detail, dragItem) {
      const toSortProp = this.toSortProp;
      const indexProp = this.indexProp;
      const dragIndex = dragItem.get(indexProp);
      const onOverItemIndex = detail.get(indexProp);

      if (dragIndex === onOverItemIndex) {// do nothing
      } else if (dragIndex < onOverItemIndex) {
        // move to below onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailBelow(detail, dragIndex, onOverItemIndex)));
      } else {
        // move to above onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailAbove(detail, dragIndex, onOverItemIndex)));
      }

      if (this.updateSynced) {
        this.updateSynced();
      }

      if (this.sortDetails) {
        this.sortDetails();
      }

      return R.filter(R.pathEq(['changed'], true))(details);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "reorderDetails", [_dec], Object.getOwnPropertyDescriptor(_obj, "reorderDetails"), _obj)), _obj)));

  _exports.default = _default;
});