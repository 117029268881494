define("client/pods/channels/shop/campaigns/details/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda", "client/pods/base/model", "client/mixins/products-children", "client/mixins/uploads", "client/mixins/statuses", "client/mixins/templates", "client/mixins/date", "client/utils/nventor", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _initializerWarningHelper2, _applyDecoratedDescriptor2, R, _model, _productsChildren, _uploads, _statuses, _templates, _date, _nventor, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  const ChannelsShopCampaignsDetailsBaseModel = _model.default.extend(_productsChildren.default, _uploads.default, _statuses.default, _templates.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    init() {
      this._super(...arguments);

      this.set('childAdapters.detailsLimits', 'channels/shop/campaigns/details/limits');
    },

    defaults() {
      return {
        status: this.get('constants.status.active'),
        deliveryDateSettings: this.get('constants.togglesAuto.auto'),
        onSale: this.get('constants.togglesAuto.on'),
        showLimitedQty: this.get('constants.togglesAuto.auto'),
        showCountdownTimer: this.get('constants.togglesAuto.auto'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        commissionMethod: this.get('constants.togglesAuto.auto'),
        hasProductSchedules: false,
        hasOnSaleSchedules: false,
        hasBulkDiscountSchedules: false,
        templates: []
      };
    },

    populate(data, attrs) {
      data = this._transformLimits(data);
      data.productSchedules = this._transformProductSchedules(data);
      data.onSaleSchedules = this._transformOnSaleSchedules(data);
      data.bulkDiscountSchedules = this._transformBulkDiscountSchedules(data);
      data.templates = this._transformTemplates(data);

      const commissionBaseCostNew = this._transformCommissionBaseCost(data); // if (RA.isNotNilOrEmpty(commissionBaseCostNew)) {


      this.set('commissionBaseCostNew', commissionBaseCostNew); // }

      data.conflictsList = this._transformConflictsList(data);

      this._super(data, attrs);
    },

    _transformConflictsList(data) {
      const conflictsList = R.propOr([], 'conflictsList')(data);
      return R.map(conflict => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/bulk-discount-conflicts',
          data: conflict
        });
      })(conflictsList);
    },

    activeBulkDiscountSchedule: Ember.computed('_data.bulkDiscountSchedules.@each.isActive', function () {
      const bulkDiscountSchedules = this.getData('bulkDiscountSchedules') || [];
      return R.find(R.propEq('isActive', true))(bulkDiscountSchedules);
    }),
    nextBulkDiscountSchedule: Ember.computed('_data.bulkDiscountSchedules.@each.isActiveNext', function () {
      const bulkDiscountSchedules = this.getData('bulkDiscountSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(bulkDiscountSchedules);
    }),
    activeBulkDiscountId: Ember.computed('_data.hasBulkDiscountSchedules', 'activeBulkDiscountSchedule._data.bulkDiscountId', '_data.bulkDiscountId', function () {
      const hasBulkDiscountSchedules = this.getData('hasBulkDiscountSchedules') || false;
      const activeBulkDiscountSchedule = this.get('activeBulkDiscountSchedule');

      if (hasBulkDiscountSchedules && _ramdaAdjunct.default.isNotNilOrEmpty(activeBulkDiscountSchedule)) {
        return activeBulkDiscountSchedule.getData('bulkDiscountId');
      }

      return this.getData('bulkDiscountId');
    }),
    activeProductSchedule: Ember.computed('_data.productSchedules.@each.isActive', function () {
      const productSchedules = this.getData('productSchedules') || [];
      return R.find(R.propEq('isActive', true))(productSchedules);
    }),
    nextProductSchedule: Ember.computed('_data.productSchedules.@each.isActiveNext', function () {
      const productSchedules = this.getData('productSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(productSchedules);
    }),
    activeStatus: Ember.computed('_data.hasProductSchedules', 'activeProductSchedule._data.status', '_data.status', function () {
      const hasProductSchedules = this.getData('hasProductSchedules') || false;
      const activeProductSchedule = this.get('activeProductSchedule');

      if (hasProductSchedules && _ramdaAdjunct.default.isNotNilOrEmpty(activeProductSchedule)) {
        return activeProductSchedule.getData('status');
      }

      return this.getData('status');
    }),
    activeOnSaleSchedule: Ember.computed('_data.onSaleSchedules.@each.isActive', function () {
      const onSaleSchedules = this.getData('onSaleSchedules') || [];
      return R.find(R.propEq('isActive', true))(onSaleSchedules);
    }),
    nextOnSaleSchedule: Ember.computed('_data.onSaleSchedules.@each.isActiveNext', function () {
      const onSaleSchedules = this.getData('onSaleSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(onSaleSchedules);
    }),
    isOnSale: Ember.computed('_data.hasOnSaleSchedules', 'activeOnSaleSchedule', '_data.onSale', function () {
      const hasOnSaleSchedules = this.getData('hasOnSaleSchedules') || false;

      if (!hasOnSaleSchedules) {
        const onSale = this.getData('onSale');

        if (onSale === 'on') {
          return true;
        }

        return false;
      }

      const activeOnSaleSchedule = this.get('activeOnSaleSchedule');

      if (_ramdaAdjunct.default.isNotNilOrEmpty(activeOnSaleSchedule)) {
        return true;
      }

      return false;
    }),
    isStandardProduct: Ember.computed('_data.hasVariants', '_data.isSet', '_data.isSetNew', '_data.isSetPriceOnBundle', '_data.isSetWithChoices', function () {
      const hasVariants = this.getData('hasVariants') || false;
      const isSet = this.getData('isSet') || false;
      const isSetNew = this.getData('isSetNew') || false;
      const isSetPriceOnBundle = this.getData('isSetPriceOnBundle') || false;
      const isSetWithChoices = this.getData('isSetWithChoices') || false;

      if (hasVariants || isSet || isSetNew || isSetPriceOnBundle || isSetWithChoices) {
        return false;
      }

      return true;
    }),

    _transformTemplates(data) {
      return R.pipe(R.pathOr([], ['templates']), R.map(template => this.dataManager.setAsRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash(template)
      })))(data);
    },

    getTemplateHash(args = {}) {
      const {
        template,
        header,
        status
      } = args;
      return {
        component: 'products/products-editor/template',

        get label() {
          return this.header;
        },

        action: 'new',
        tabType: 'reuseSubTab',
        header,
        template,
        status
      };
    },

    addNewTemplate() {
      var _this$_data, _this$_data$templates;

      const newTemplate = this.dataManager.newRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash()
      });
      this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : (_this$_data$templates = _this$_data.templates) === null || _this$_data$templates === void 0 ? void 0 : _this$_data$templates.pushObject(newTemplate);
      return newTemplate;
    },

    removeTemplate(template) {
      if (template._data) {
        var _this$_data2, _this$_data2$template;

        this === null || this === void 0 ? void 0 : (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : (_this$_data2$template = _this$_data2.templates) === null || _this$_data2$template === void 0 ? void 0 : _this$_data2$template.removeObject(template);
      } else {
        var _this$_data3, _this$_data3$template;

        const header = R.propOr('', 'header')(template);
        const templateModel = R.pipe(R.pathOr([], ['_data', 'templates']), R.find(R.pathEq(['_data', 'header'], header)))(this);
        this === null || this === void 0 ? void 0 : (_this$_data3 = this._data) === null || _this$_data3 === void 0 ? void 0 : (_this$_data3$template = _this$_data3.templates) === null || _this$_data3$template === void 0 ? void 0 : _this$_data3$template.removeObject(templateModel);
      }
    },

    _transformProductSchedules(data) {
      const productSchedules = R.propOr([], 'productSchedules')(data);
      return R.map(productSchedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/product-schedules',
          data: productSchedule
        });
      })(productSchedules);
    },

    addProductSchedule(productSchedule = {}) {
      const productSchedules = this.getData('productSchedules') || [];
      const productScheduleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/details/product-schedules',
        data: productSchedule
      });
      productSchedules.pushObject(productScheduleModel);
      this.setData('productSchedules', productSchedules);
    },

    _transformOnSaleSchedules(data) {
      const onSaleSchedules = R.propOr([], 'onSaleSchedules')(data);
      return R.map(onSaleSchedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/on-sale-schedules',
          data: onSaleSchedule
        });
      })(onSaleSchedules);
    },

    addOnSaleSchedule(onSaleSchedule = {}) {
      const onSaleSchedules = this.getData('onSaleSchedules') || [];
      const onSaleScheduleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/details/on-sale-schedules',
        data: onSaleSchedule
      });
      onSaleSchedules.pushObject(onSaleScheduleModel);
      this.setData('onSaleSchedules', onSaleSchedules);
    },

    _transformBulkDiscountSchedules(data) {
      const bulkDiscountSchedules = R.propOr([], 'bulkDiscountSchedules')(data);
      return R.map(bulkDiscountSchedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/bulk-discount-schedules',
          data: bulkDiscountSchedule
        });
      })(bulkDiscountSchedules);
    },

    addBulkDiscountSchedule(bulkDiscountSchedule = {}) {
      const bulkDiscountSchedules = this.getData('bulkDiscountSchedules') || [];
      const bulkDiscountScheduleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/details/bulk-discount-schedules',
        data: bulkDiscountSchedule
      });
      bulkDiscountSchedules.pushObject(bulkDiscountScheduleModel);
      this.setData('bulkDiscountSchedules', bulkDiscountSchedules);
    },

    _transformCommissionBaseCost(data) {
      let commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew')(data); // if (RA.isNotNilOrEmpty(commissionBaseCostNew)) {

      delete data.commissionBaseCostNew;
      const mapIndexed = R.addIndex(R.map);

      const mergeArrayOfObjects = (key, left, right) => {
        if (R.equals(key, 'setDetails') || R.equals(key, 'variantChildren')) {
          return mapIndexed((val, idx) => R.mergeDeepRight(val, right[idx]), left);
        }

        if (R.equals(key, 'choices') || R.equals(key, 'details')) {
          return mapIndexed((val, idx) => {
            return R.mergeDeepWithKey(mergeArrayOfObjects, val, right[idx]);
          })(left);
        }

        return right;
      };

      commissionBaseCostNew = R.pipe(R.pick(['choices', 'setDetails', 'variantChildren', 'itemKey', 'item', 'salesQty', 'campaignKey', 'isSet', 'isSetWithChoices', 'hasVariants']), R.mergeDeepWithKey(mergeArrayOfObjects, commissionBaseCostNew))(data);
      commissionBaseCostNew.master = data._key;
      commissionBaseCostNew.campaignKey = data.master;

      if (commissionBaseCostNew._key) {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      } else {
        return this.dataManager.newRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      } // }
      // return commissionBaseCostNew

    },

    _transformLimits(data) {
      if (data.limits) {
        const limits = R.pipe(R.propOr([], 'limits'), // R.sortWith([
        //   R.ascend(R.prop('item'))
        // ]),
        R.map(limitData => {
          return this.dataManager.setPartialRecord({
            adapterName: this.get('childAdapters.detailsLimits'),
            data: limitData
          });
        }))(data);
        this.set('limits', limits);
        delete data.limits;
      }

      return data;
    },

    // overSalesLimitedQtyStatusClass: computed('_data.overSalesLimitedQtyStatus', function () {
    //   const status = this.get('_data.overSalesLimitedQtyStatus')
    //   return this.get('productsService').getStatusClass(status)
    // }),
    limitsSorted: Ember.computed('limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.pipe(R.filter(R.pathEq(['_data', 'hasSalesLimitedQty'], true)), R.sortWith([R.ascend(R.path(['_data', 'salesLimitedQty']))]))(limits);
    }),
    limitsProducts: Ember.computed('limits.@each.synced', 'limits.@each.product', function () {
      const limits = this.limits || [];
      return R.pluck('product')(limits);
    }),
    limitsSortedByItem: Ember.computed('limits.@each.synced', 'limitsProducts.@each.synced', function () {
      const limits = this.limits || [];
      return _nventor.default.sortTextAscendingByPath(['product', '_data', 'name'])(limits);
    }),
    hasAnySalesLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        var _limitedDetail$_data;

        if (acc) {
          return acc;
        }

        if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data = limitedDetail._data) !== null && _limitedDetail$_data !== void 0 && _limitedDetail$_data.hasSalesLimitedQty) {
          return true;
        }

        return false;
      }, false)(limits);
    }),
    hasAnyAllowSalesPreOrder: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        var _limitedDetail$_data2;

        if (acc) {
          return acc;
        }

        if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data2 = limitedDetail._data) !== null && _limitedDetail$_data2 !== void 0 && _limitedDetail$_data2.allowPreOrder) {
          return true;
        }

        return false;
      }, false)(limits);
    }),
    hasAnySalesPreOrderLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        var _limitedDetail$_data3;

        if (acc) {
          return acc;
        }

        if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data3 = limitedDetail._data) !== null && _limitedDetail$_data3 !== void 0 && _limitedDetail$_data3.hasSalesPreOrderLimitedQty) {
          return true;
        }

        return false;
      }, false)(limits);
    }),
    minSalesLimitedQtyLabel: Ember.computed('synced', '_data.isSetWithChoices', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', 'hasAnySalesLimitedQty', function () {
      var _this$_data4, _this$minSalesLimited, _this$minSalesLimited2;

      if (!this.hasAnySalesLimitedQty) {
        return '-';
      }

      if ((_this$_data4 = this._data) !== null && _this$_data4 !== void 0 && _this$_data4.isSetWithChoices && !((_this$minSalesLimited = this.minSalesLimitedData) !== null && _this$minSalesLimited !== void 0 && _this$minSalesLimited.hasSalesLimitedChoices)) {
        return '-';
      }

      return this.intl.formatNumber(((_this$minSalesLimited2 = this.minSalesLimitedData) === null || _this$minSalesLimited2 === void 0 ? void 0 : _this$minSalesLimited2.minSalesLimitedQty) || 0);
    }),
    minSalesPreOrderLimitedQtyLabel: Ember.computed('synced', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', 'hasAnyAllowSalesPreOrder', 'hasAnySalesPreOrderLimitedQty', function () {
      var _this$minSalesLimited3;

      if (!this.hasAnyAllowSalesPreOrder) {
        return '-';
      }

      if (!this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }

      return this.intl.formatNumber(((_this$minSalesLimited3 = this.minSalesLimitedData) === null || _this$minSalesLimited3 === void 0 ? void 0 : _this$minSalesLimited3.minSalesPreOrderLimitedQty) || 0);
    }),
    minAvailableToSellQtyLabel: Ember.computed('_data.isSetWithChoices', 'hasAnySalesLimitedQty', 'hasAnyAllowSalesPreOrder', 'hasAnySalesPreOrderLimitedQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      var _this$_data5, _this$minSalesLimited4, _this$minSalesLimited5;

      if (!this.hasAnySalesLimitedQty) {
        return '∞';
      }

      if (this.hasAnyAllowSalesPreOrder && !this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }

      if (this !== null && this !== void 0 && (_this$_data5 = this._data) !== null && _this$_data5 !== void 0 && _this$_data5.isSetWithChoices && !((_this$minSalesLimited4 = this.minSalesLimitedData) !== null && _this$minSalesLimited4 !== void 0 && _this$minSalesLimited4.hasSalesLimitedChoices)) {
        return '∞';
      }

      let minAvailableSalesLimitedQty = ((_this$minSalesLimited5 = this.minSalesLimitedData) === null || _this$minSalesLimited5 === void 0 ? void 0 : _this$minSalesLimited5.minAvailableSalesLimitedQty) || 0;
      let minAvailableSalesPreOrderLimitedQty = 0;

      if (this.hasAnyAllowSalesPreOrder) {
        var _this$minSalesLimited6;

        minAvailableSalesPreOrderLimitedQty = (_this$minSalesLimited6 = this.minSalesLimitedData) === null || _this$minSalesLimited6 === void 0 ? void 0 : _this$minSalesLimited6.minAvailableSalesPreOrderLimitedQty;
      }

      return this.intl.formatNumber(minAvailableSalesLimitedQty + minAvailableSalesPreOrderLimitedQty);
    }),
    isOverSalesLimitedQtyItems: Ember.computed('hasAnySalesLimitedQty', 'limits.@each.synced', 'synced', function () {
      const hasAnySalesLimitedQty = this.hasAnySalesLimitedQty;

      if (hasAnySalesLimitedQty) {
        const limitsSorted = this.limitsSorted || [];
        return R.filter(limitDetail => {
          return R.path(['_data', 'isOverSalesLimitedQty'])(limitDetail);
        })(limitsSorted);
      }

      return [];
    }),

    setChildProductPrice(campaign, child, historyData) {
      const isCampaign = R.prop('campaign', historyData);

      this._setItemPrice(campaign, isCampaign, child, historyData);

      this._setVariantChildrenPrices(campaign, isCampaign, child, historyData);
    },

    _setItemPrice(campaign, isCampaign, item, historyData) {
      if (isCampaign) {
        const price = R.propOr('', 'price', historyData);
        const priceDiscounted = R.propOr('', 'priceDiscounted', historyData);
        const priceFinal = R.propOr('', 'priceFinal', historyData);
        const onSale = R.propOr('', 'onSale', historyData);
        const isSetNew = R.propOr('', 'isSetNew', historyData);

        if (onSale) {
          item.setData('onSale', onSale);
        }

        if (isSetNew) {
          item.setData('isSetNew', isSetNew);
        }

        item.setData('price', price);
        item.setData('priceDiscounted', priceDiscounted);
        item.setData('priceFinal', priceFinal); // if (campaign.hasSalesPersons) {
        //   const commissionBaseCost = R.propOr('', 'commissionBaseCost', historyData)
        //   item.setData('commissionBaseCost', commissionBaseCost)
        // }
      } else {
        let price;
        price = historyData.inclPrice;

        if (!price) {
          price = R.propOr('', 'salesInclPrice', historyData);
        }

        item.setData('price', price);
        item.setData('priceDiscounted', '');
      }
    },

    _setVariantChildrenPrices(campaign, isCampaign, item, historyData) {
      const variantChildrenHistory = R.propOr([], 'variantChildren')(historyData);
      R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.forEach(child => {
        const variantChildId = child._data.variantChildId;
        let childHistory = R.find(R.propEq('variantChildId', variantChildId))(variantChildrenHistory);

        if (!childHistory) {
          const variantChildUid = child._data.variantChildUid;
          childHistory = R.find(R.propEq('variantChildUid', variantChildUid))(variantChildrenHistory);
        }

        if (childHistory) {
          this._setItemPrice(campaign, isCampaign, child, childHistory);
        }
      }))(item);
    },

    _setSetDetailsPrices(campaign, isCampaign, item, historyData) {
      const setDetailsHistory = R.propOr([], 'setDetails')(historyData);
      R.pipe(R.pathOr([], ['_data', 'setDetails']), R.forEach(child => {
        var _child$_data;

        const setDetailItemKey = child === null || child === void 0 ? void 0 : (_child$_data = child._data) === null || _child$_data === void 0 ? void 0 : _child$_data.itemKey;
        const setDetailHistory = R.find(R.propEq('itemKey', setDetailItemKey))(setDetailsHistory);

        if (setDetailHistory) {
          this._setItemPrice(campaign, isCampaign, child, setDetailHistory);
        }
      }))(item);
    },

    isOverSalesLimitedQty: Ember.computed('isOverSalesLimitedQtyItems.[]', '_data.status', function () {
      if (this._data.status === this.constants.productsStatus.inactive) {
        return false;
      }

      const isOverSalesLimitedQtyItems = this.isOverSalesLimitedQtyItems;

      if (!R.isEmpty(isOverSalesLimitedQtyItems)) {
        return R.reduce((acc, limitDetail) => {
          if (acc) {
            return acc;
          }

          return R.path(['_data', 'isOverSalesLimitedQty'])(limitDetail);
        }, false)(isOverSalesLimitedQtyItems);
      }

      return false;
    }),
    isOverSalesLimitedQtyStatus: Ember.computed('isOverSalesLimitedQtyItems.[]', 'isOverSalesLimitedQty', function () {
      const isOverSalesLimitedQtyItems = this.isOverSalesLimitedQtyItems;

      if (!R.isEmpty(isOverSalesLimitedQtyItems)) {
        const result = R.reduce((acc, limitDetail) => {
          if (acc === this.constants.productsStatus.soldOut) {
            return acc;
          }

          return R.path(['_data', 'overSalesLimitedQtyStatus'])(limitDetail);
        }, false)(isOverSalesLimitedQtyItems);

        if (!result) {
          return this._data.overSalesLimitedQtyStatus;
        }

        return result;
      }

      return this._data.overSalesLimitedQtyStatus;
    }),

    // minSalesDetailsLimitData: computed('hasAnySalesLimitedQty', 'limitsSorted.@each.synced', '_data.salesLimitedType', function () {
    //   const hasAnySalesLimitedQty = this.hasAnySalesLimitedQty
    //   if (hasAnySalesLimitedQty) {
    //     const limitsSorted = this.limitsSorted || []
    //     return nventor.safeHeadOr({})(limitsSorted)
    //   }
    //   return {}
    // }),
    // minSalesLimitedQty: computed('minSalesDetailsLimitData.{itemKey,salesLimitedQty,salesLimitedType}', function () {
    //   const minSalesDetailsLimitData = this.minSalesDetailsLimitData || {}
    //   const preOrderQty = parseInt(R.pathOr(0, ['salesPreOrderLimitedQty'])(minSalesDetailsLimitData)) || 0
    //   const salesLimitedQty = parseInt(R.pathOr(0, ['salesLimitedQty'])(minSalesDetailsLimitData)) || 0
    //   return preOrderQty + salesLimitedQty
    // }),
    _calculateMinSalesLimitedDataForSet(detail, limits) {
      var _detail$_data, _limitedDetail$_data4, _limitedDetail$_data7, _limitedDetail$_data11;

      const limitedDetail = R.find(R.pathEq(['_data', 'itemKey'], detail === null || detail === void 0 ? void 0 : (_detail$_data = detail._data) === null || _detail$_data === void 0 ? void 0 : _detail$_data.itemKey))(limits);
      const salesLimitedQtys = [];
      const salesPreOrderLimitedQtys = [];
      const availableSalesLimitedQtys = [];
      const availableSalesPreOrderLimitedQtys = [];

      if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data4 = limitedDetail._data) !== null && _limitedDetail$_data4 !== void 0 && _limitedDetail$_data4.hasSalesLimitedQty) {
        var _limitedDetail$_data5, _limitedDetail$_data6;

        salesLimitedQtys.push(parseInt(limitedDetail === null || limitedDetail === void 0 ? void 0 : (_limitedDetail$_data5 = limitedDetail._data) === null || _limitedDetail$_data5 === void 0 ? void 0 : _limitedDetail$_data5.salesLimitedQty) || 0);
        availableSalesLimitedQtys.push(parseInt(limitedDetail === null || limitedDetail === void 0 ? void 0 : (_limitedDetail$_data6 = limitedDetail._data) === null || _limitedDetail$_data6 === void 0 ? void 0 : _limitedDetail$_data6.calculatedAvailableQty) || 0);
      }

      if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data7 = limitedDetail._data) !== null && _limitedDetail$_data7 !== void 0 && _limitedDetail$_data7.allowPreOrder) {
        var _limitedDetail$_data8;

        if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data8 = limitedDetail._data) !== null && _limitedDetail$_data8 !== void 0 && _limitedDetail$_data8.hasSalesPreOrderLimitedQty) {
          var _limitedDetail$_data9, _limitedDetail$_data10;

          salesPreOrderLimitedQtys.push(parseInt(limitedDetail === null || limitedDetail === void 0 ? void 0 : (_limitedDetail$_data9 = limitedDetail._data) === null || _limitedDetail$_data9 === void 0 ? void 0 : _limitedDetail$_data9.salesPreOrderLimitedQty) || 0);
          availableSalesPreOrderLimitedQtys.push(parseInt(limitedDetail === null || limitedDetail === void 0 ? void 0 : (_limitedDetail$_data10 = limitedDetail._data) === null || _limitedDetail$_data10 === void 0 ? void 0 : _limitedDetail$_data10.calculatedAvailablePreOrderQtyOnly) || 0);
        }
      } else if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data11 = limitedDetail._data) !== null && _limitedDetail$_data11 !== void 0 && _limitedDetail$_data11.hasSalesLimitedQty) {
        salesPreOrderLimitedQtys.push(0);
        availableSalesPreOrderLimitedQtys.push(0);
      }

      R.pipe(R.pathOr([], ['_data', 'setDetails']), R.forEach(setDetail => {
        var _setDetail$_data, _setDetail$_data2;

        const childLimitedDetail = R.find(R.pathEq(['_data', 'itemKey'], setDetail === null || setDetail === void 0 ? void 0 : (_setDetail$_data = setDetail._data) === null || _setDetail$_data === void 0 ? void 0 : _setDetail$_data.itemKey))(limits);
        const setChildQty = parseInt(setDetail === null || setDetail === void 0 ? void 0 : (_setDetail$_data2 = setDetail._data) === null || _setDetail$_data2 === void 0 ? void 0 : _setDetail$_data2.qty) || 1;

        if (childLimitedDetail) {
          var _childLimitedDetail$_, _childLimitedDetail$_4, _childLimitedDetail$_7;

          if (childLimitedDetail !== null && childLimitedDetail !== void 0 && (_childLimitedDetail$_ = childLimitedDetail._data) !== null && _childLimitedDetail$_ !== void 0 && _childLimitedDetail$_.hasSalesLimitedQty) {
            var _childLimitedDetail$_2, _childLimitedDetail$_3;

            const salesLimitedQty = parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_2 = childLimitedDetail._data) === null || _childLimitedDetail$_2 === void 0 ? void 0 : _childLimitedDetail$_2.salesLimitedQty) || 0;
            const parentSalesLimitedQty = Math.floor(salesLimitedQty / setChildQty);
            salesLimitedQtys.push(parentSalesLimitedQty);
            const availableSalesLimitedQty = parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_3 = childLimitedDetail._data) === null || _childLimitedDetail$_3 === void 0 ? void 0 : _childLimitedDetail$_3.calculatedAvailableQty) || 0;
            const parentAvailableSalesLimitedQty = Math.floor(availableSalesLimitedQty / setChildQty);
            availableSalesLimitedQtys.push(parentAvailableSalesLimitedQty);
          }

          if (childLimitedDetail !== null && childLimitedDetail !== void 0 && (_childLimitedDetail$_4 = childLimitedDetail._data) !== null && _childLimitedDetail$_4 !== void 0 && _childLimitedDetail$_4.allowPreOrder) {
            if (childLimitedDetail !== null && childLimitedDetail !== void 0 && childLimitedDetail._data.hasSalesLimitedQty) {
              var _childLimitedDetail$_5, _childLimitedDetail$_6;

              const salesPreOrderLimitedQty = parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_5 = childLimitedDetail._data) === null || _childLimitedDetail$_5 === void 0 ? void 0 : _childLimitedDetail$_5.salesPreOrderLimitedQty) || 0;
              const parentSalesPreOrderLimitedQty = Math.floor(salesPreOrderLimitedQty / setChildQty);
              salesPreOrderLimitedQtys.push(parentSalesPreOrderLimitedQty);
              const availableSalesPreOrderLimitedQty = parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_6 = childLimitedDetail._data) === null || _childLimitedDetail$_6 === void 0 ? void 0 : _childLimitedDetail$_6.calculatedAvailablePreOrderQtyOnly) || 0;
              const parentAvailableSalesPreOrderLimitedQty = Math.floor(availableSalesPreOrderLimitedQty / setChildQty);
              availableSalesPreOrderLimitedQtys.push(parentAvailableSalesPreOrderLimitedQty);
            }
          } else if (childLimitedDetail !== null && childLimitedDetail !== void 0 && (_childLimitedDetail$_7 = childLimitedDetail._data) !== null && _childLimitedDetail$_7 !== void 0 && _childLimitedDetail$_7.hasSalesLimitedQty) {
            salesPreOrderLimitedQtys.push(0);
            availableSalesPreOrderLimitedQtys.push(0);
          }
        }
      }))(detail);
      return {
        hasSalesLimitedChoices: false,
        minSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesLimitedQtys),
        minSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesPreOrderLimitedQtys),
        minAvailableSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesLimitedQtys),
        minAvailableSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesPreOrderLimitedQtys)
      };
    },

    minSalesLimitedData: Ember.computed('limits.@each.synced', 'synced', function () {
      var _this$_data6, _this$_data7, _this$_data8, _limitedDetail$_data12;

      const limits = this.limits || [];
      const isSet = this === null || this === void 0 ? void 0 : (_this$_data6 = this._data) === null || _this$_data6 === void 0 ? void 0 : _this$_data6.isSet;

      if (isSet) {
        return this._calculateMinSalesLimitedDataForSet(this, limits);
      }

      const isSetWithChoices = this === null || this === void 0 ? void 0 : (_this$_data7 = this._data) === null || _this$_data7 === void 0 ? void 0 : _this$_data7.isSetWithChoices;

      if (isSetWithChoices) {
        const salesLimitedQtys = [];
        const salesPreOrderLimitedQtys = [];
        const availableSalesLimitedQtys = [];
        const availableSalesPreOrderLimitedQtys = [];
        let hasSalesLimitedChoices = false;
        R.pipe(R.pathOr([], ['_data', 'choices']), R.forEach(choice => {
          const choiceSalesLimitedQtys = [];
          const choiceSalesPreOrderLimitedQtys = [];
          const choiceAvailableSalesLimitedQtys = [];
          const choiceAvailableSalesPreOrderLimitedQtys = [];
          const choiceDetails = R.pathOr([], ['_data', 'details'])(choice);
          R.forEach(detail => {
            var _detail$_data2;

            if (detail !== null && detail !== void 0 && (_detail$_data2 = detail._data) !== null && _detail$_data2 !== void 0 && _detail$_data2.isSet) {
              const setLimitedData = this._calculateMinSalesLimitedDataForSet(detail, limits);

              if (setLimitedData.minSalesLimitedQty !== false) {
                choiceSalesLimitedQtys.push(parseInt(setLimitedData.minSalesLimitedQty) || 0);
              }

              if (setLimitedData.minAvailableSalesLimitedQty !== false) {
                choiceAvailableSalesLimitedQtys.push(parseInt(setLimitedData.minAvailableSalesLimitedQty) || 0);
              }

              if (setLimitedData.minSalesLimitedQty !== false) {
                choiceSalesPreOrderLimitedQtys.push(parseInt(setLimitedData.minSalesPreOrderLimitedQty) || 0);
              }

              if (setLimitedData.minAvailableSalesPreOrderLimitedQty !== false) {
                choiceAvailableSalesPreOrderLimitedQtys.push(parseInt(setLimitedData.minAvailableSalesPreOrderLimitedQty) || 0);
              }
            } else {
              var _childLimitedDetail$_9, _childLimitedDetail$_11;

              // find childLimitData
              const childLimitedDetail = R.find(R.pathEq(['_data', 'itemKey'], detail === null || detail === void 0 ? void 0 : detail._data.itemKey))(limits);

              if (childLimitedDetail !== null && childLimitedDetail !== void 0 && childLimitedDetail._data.hasSalesLimitedQty) {
                var _childLimitedDetail$_8;

                choiceSalesLimitedQtys.push(parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : childLimitedDetail._data.salesLimitedQty) || 0);
                choiceAvailableSalesLimitedQtys.push(parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_8 = childLimitedDetail._data) === null || _childLimitedDetail$_8 === void 0 ? void 0 : _childLimitedDetail$_8.calculatedAvailableQty) || 0);
              }

              if (childLimitedDetail !== null && childLimitedDetail !== void 0 && (_childLimitedDetail$_9 = childLimitedDetail._data) !== null && _childLimitedDetail$_9 !== void 0 && _childLimitedDetail$_9.allowPreOrder) {
                if (childLimitedDetail !== null && childLimitedDetail !== void 0 && childLimitedDetail._data.hasSalesPreOrderLimitedQty) {
                  var _childLimitedDetail$_10;

                  choiceSalesPreOrderLimitedQtys.push(parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : childLimitedDetail._data.salesPreOrderLimitedQty) || 0);
                  choiceAvailableSalesPreOrderLimitedQtys.push(parseInt(childLimitedDetail === null || childLimitedDetail === void 0 ? void 0 : (_childLimitedDetail$_10 = childLimitedDetail._data) === null || _childLimitedDetail$_10 === void 0 ? void 0 : _childLimitedDetail$_10.calculatedAvailablePreOrderQtyOnly) || 0);
                }
              } else if (childLimitedDetail !== null && childLimitedDetail !== void 0 && (_childLimitedDetail$_11 = childLimitedDetail._data) !== null && _childLimitedDetail$_11 !== void 0 && _childLimitedDetail$_11.hasSalesLimitedQty) {
                choiceSalesPreOrderLimitedQtys.push(0);
                choiceAvailableSalesPreOrderLimitedQtys.push(0);
              }
            }
          })(choiceDetails);

          if (choiceSalesLimitedQtys.length === choiceDetails.length) {
            var _choice$_data;

            hasSalesLimitedChoices = true;
            const choiceQty = (choice === null || choice === void 0 ? void 0 : (_choice$_data = choice._data) === null || _choice$_data === void 0 ? void 0 : _choice$_data.qty) || 1;

            if (choiceSalesLimitedQtys.length > 0) {
              salesLimitedQtys.push(Math.floor(R.sum(choiceSalesLimitedQtys) / choiceQty));
            }

            if (choiceAvailableSalesLimitedQtys.length > 0) {
              availableSalesLimitedQtys.push(Math.floor(R.sum(choiceAvailableSalesLimitedQtys) / choiceQty));
            }

            if (choiceSalesPreOrderLimitedQtys.length > 0) {
              salesPreOrderLimitedQtys.push(Math.floor(R.sum(choiceSalesPreOrderLimitedQtys) / choiceQty));
            }

            if (choiceAvailableSalesPreOrderLimitedQtys.length > 0) {
              availableSalesPreOrderLimitedQtys.push(Math.floor(R.sum(choiceAvailableSalesPreOrderLimitedQtys) / choiceQty));
            }
          }
        }))(this);
        return {
          hasSalesLimitedChoices,
          minSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesLimitedQtys),
          minSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesPreOrderLimitedQtys),
          minAvailableSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesLimitedQtys),
          minAvailableSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesPreOrderLimitedQtys)
        };
      }

      const limitedDetail = R.find(R.pathEq(['_data', 'itemKey'], this === null || this === void 0 ? void 0 : (_this$_data8 = this._data) === null || _this$_data8 === void 0 ? void 0 : _this$_data8.itemKey))(limits);
      let minSalesLimitedQty = false;
      let minAvailableSalesLimitedQty = false;

      if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data12 = limitedDetail._data) !== null && _limitedDetail$_data12 !== void 0 && _limitedDetail$_data12.hasSalesLimitedQty) {
        minSalesLimitedQty = R.pathOr(false, ['_data', 'salesLimitedQty'])(limitedDetail);
        minAvailableSalesLimitedQty = R.pathOr(false, ['_data', 'calculatedAvailableQty'])(limitedDetail);
      }

      let minSalesPreOrderLimitedQty = false;
      let minAvailableSalesPreOrderLimitedQty = false;

      if (limitedDetail !== null && limitedDetail !== void 0 && limitedDetail._data.allowPreOrder) {
        var _limitedDetail$_data13;

        if (limitedDetail !== null && limitedDetail !== void 0 && (_limitedDetail$_data13 = limitedDetail._data) !== null && _limitedDetail$_data13 !== void 0 && _limitedDetail$_data13.hasSalesPreOrderLimitedQty) {
          minSalesPreOrderLimitedQty = R.pathOr(false, ['_data', 'salesPreOrderLimitedQty'])(limitedDetail);
          minAvailableSalesPreOrderLimitedQty = R.pathOr(false, ['_data', 'calculatedAvailablePreOrderQtyOnly'])(limitedDetail);
        }
      }

      return {
        hasSalesLimitedChoices: false,
        minSalesLimitedQty,
        minSalesPreOrderLimitedQty,
        minAvailableSalesLimitedQty,
        minAvailableSalesPreOrderLimitedQty
      };
    }),
    availableQty: Ember.computed('_data.salesQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      var _this$minSalesLimited7;

      const minAvailableSalesLimitedQty = (_this$minSalesLimited7 = this.minSalesLimitedData) === null || _this$minSalesLimited7 === void 0 ? void 0 : _this$minSalesLimited7.minAvailableSalesLimitedQty;

      if (minAvailableSalesLimitedQty === false) {
        return '';
      }

      if (this._data.isSetWithChoices) {
        var _this$minSalesLimited8;

        if (!((_this$minSalesLimited8 = this.minSalesLimitedData) !== null && _this$minSalesLimited8 !== void 0 && _this$minSalesLimited8.hasSalesLimitedChoices)) {
          return '';
        }
      }

      return this.intl.formatNumber(parseInt(minAvailableSalesLimitedQty) || 0);
    }),
    availablePreOrderQty: Ember.computed('_data.salesQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      var _this$minSalesLimited9;

      const minAvailableSalesPreOrderLimitedQty = (_this$minSalesLimited9 = this.minSalesLimitedData) === null || _this$minSalesLimited9 === void 0 ? void 0 : _this$minSalesLimited9.minAvailableSalesPreOrderLimitedQty;

      if (minAvailableSalesPreOrderLimitedQty === false) {
        return '';
      }

      if (this._data.isSetWithChoices) {
        var _this$minSalesLimited10;

        if (!((_this$minSalesLimited10 = this.minSalesLimitedData) !== null && _this$minSalesLimited10 !== void 0 && _this$minSalesLimited10.hasSalesLimitedChoices)) {
          return '';
        }
      }

      return this.intl.formatNumber(parseInt(minAvailableSalesPreOrderLimitedQty) || 0);
    }),

    // currentStatus: computed('hasAnySalesLimitedQty', '_data.status', 'limitsSorted.[]', function () {
    //   const limits = this.get('limitsSorted') || []
    //   if (limits)
    // }),
    loadProduct(campaign, item, product, historyData) {
      this.resetDetail();
      const productData = product.serialize({
        exclude: ['_key', '_rev', '_id', 'status']
      });
      const itemData = this.serialize();
      itemData.item = product.getData('name');
      itemData.itemCode = product.getData('code');
      itemData.itemKey = product.getData('_key');
      itemData.productTags = R.pathOr([], ['_data', 'tags'], product);
      itemData.roundingInventoryQty = product.getData('roundingInventoryQty') || this.settings.getProp('roundingInventoryQty');
      itemData.roundingInventoryPrice = product.getData('roundingInventoryPrice') || this.settings.getProp('roundingInventoryPrice');
      const onSale = itemData.onSale;

      this._loadIsSetWithChoices(campaign, productData, onSale);

      this._loadVariantChildren(campaign, productData, onSale);

      const newItemData = R.mergeRight(itemData, productData);
      this.populate(newItemData);
      this.setDetailPrice(campaign, item, historyData);
    },

    _loadIsSetWithChoices(campaign, productData, onSale) {
      // isSetWithChoices is ignored for now (refactoring on new branch)
      if (productData.isSetWithChoices) {
        productData.choices = R.pipe(R.propOr([], 'choices'), R.map(choiceData => {
          // const details = choiceData.details || []
          choiceData.onSale = onSale;
          choiceData.details = R.pipe(R.propOr([], 'details'), R.map(detailData => {
            detailData.onSale = onSale;

            if (detailData.hasVariants) {
              detailData = this._loadVariantChildren(campaign, detailData, onSale);
            }

            return detailData;
          }))(choiceData);
          return choiceData;
        }))(productData);
        productData.price = 0;
        productData.priceDiscounted = 0;
      }

      return productData;
    },

    _loadVariantChildren(campaign, productData, onSale) {
      const hasVariants = productData.hasVariants;

      if (hasVariants) {
        productData.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(variantChild => {
          variantChild.onSale = onSale; // when child product is isSetWithChoice.variant it already has child.itemKey
          // but when child product is from hasVariants (not in set with choice) then it does not have itemKey (as its coming from the products collection)

          if (!variantChild.itemKey) {
            variantChild.itemKey = variantChild._key;
          }

          if (!variantChild.item) {
            variantChild.item = variantChild.name;
          }

          if (!variantChild.itemCode) {
            variantChild.itemCode = variantChild.code;
          }

          variantChild.price = '';
          variantChild.priceDiscounted = '';
          return variantChild;
        }))(productData);
      }

      return productData;
    },

    setDetailPrice(campaign, item, historyData) {
      // if from campaign:
      //    - use price
      //    - set priceDiscounted
      //    - set onSale
      //
      // if from history doc
      //    - use inclPrice
      // if from product
      //    - use salesInclPrice
      const isCampaign = R.prop('campaign', historyData);

      this._setItemPrice(campaign, isCampaign, item, historyData); // @TODO darwin: refactor set with choices in new branch


      this._setIsSetWithChoicesPrices(campaign, isCampaign, item, historyData);

      this._setVariantChildrenPrices(campaign, isCampaign, item, historyData);

      this._setSetDetailsPrices(campaign, isCampaign, item, historyData);

      this.setProductCommissionsBaseCost(campaign, item, historyData);
      this.recalculatePrice(item);
    },

    // @TODO darwin: refactor in new branch
    _setIsSetWithChoicesPrices(campaign, isCampaign, item, historyData) {
      const choicesChildrenHistory = R.pipe(R.propOr([], 'choices'), R.pluck('details'), R.flatten)(historyData);
      const choicesHistory = R.pipe(R.propOr([], 'choices'), R.flatten)(historyData);
      R.pipe(R.pathOr([], ['_data', 'choices']), R.forEach(choice => {
        const index = choice.getData('index');
        const choiceHistory = R.find(R.propEq('index', index), choicesHistory);

        if (!choiceHistory) {
          return false;
        }

        if (choiceHistory.isSetPriceOnBundle) {
          choice.setData('isSetPriceOnBundle', choiceHistory.isSetPriceOnBundle);
        }

        if (choiceHistory.price) {
          choice.setData('price', choiceHistory.price);
        }

        if (choiceHistory.priceDiscounted) {
          choice.setData('priceDiscounted', choiceHistory.priceDiscounted);
        }

        if (choiceHistory.priceFinal) {
          choice.setData('priceFinal', choiceHistory.priceFinal);
        } // if (choiceHistory.commissionBaseCost) {
        //   choice.setData('commissionBaseCost', choiceHistory.commissionBaseCost)
        // }


        choice.setData('qty', choiceHistory.qty);
        choice.setData('name', choiceHistory.name);
      }))(item);
      R.pipe(R.pathOr([], ['_data', 'choices']), R.pluck('_data'), R.pluck('details'), R.flatten, R.forEach(child => {
        const itemKey = child.getData('itemKey');
        const childHistory = R.find(R.propEq('itemKey', itemKey))(choicesChildrenHistory);

        this._setVariantChildrenPrices(campaign, isCampaign, child, childHistory);

        this._setSetDetailsPrices(campaign, isCampaign, child, childHistory);

        this._setItemPrice(campaign, isCampaign, child, childHistory);
      }))(item);
    },

    setProductCommissionsBaseCost(campaign, item, historyData) {
      const hasSalesPersons = campaign.hasSalesPersons; // if (hasSalesPersons) {
      //   const commissionBaseCost = R.propOr(0, 'commissionBaseCost', historyData)
      //   if (commissionBaseCost !== 0 && commissionBaseCost !== '0') {
      //     // set base cost only if its non 0
      //     item.setData('commissionBaseCost', commissionBaseCost)
      //   }
      // } else {
      //   item.setData('commissionBaseCost', 0)
      // }
      //   // @TODO: should check the sales person from linking doc and to linking doc.
      //   // @TODO: commission may be different from sales persons
      //   const commissionBaseCost = R.propOr(0, 'commissionBaseCost', historyData)
      //   if (commissionBaseCost !== 0 && commissionBaseCost !== '0') {
      //     // set base cost only if its non 0
      //     item.setData('commissionBaseCost', commissionBaseCost)
      //   }
      // }
    },

    resetDetail: function () {
      this.setData('item', '');
      this.setData('itemKey', '');
      this.setData('itemCode', '');
      this.setData('setType', '');
      this.setData('hasVariants', false);
      this.setData('variantChildren', []);
      this.setData('isSetWithChoices', false);
      this.setData('choices', []);
      this.setData('isSet', false);
      this.setData('setDetails', []);
    },

    recalculatePriceForSet(detail, parent) {
      const isSetNew = R.pathOr(false, ['_data', 'isSetNew'], detail);

      if (isSetNew) {
        const setDetailsArray = _nventor.default.ensurePathOr([], ['_data', 'setDetails'], detail);

        let totalPrice = detail.big.newBig(0);
        let totalPriceDiscounted = detail.big.newBig(0);
        let totalPriceFinal = detail.big.newBig(0);
        let totalCommissionBaseCost = detail.big.newBig(0);
        R.forEach(detailChild => {
          const detailChildData = R.propOr({}, '_data', detailChild);
          const detailChildPrice = detail.big.newBig(detailChildData.price || 0).times(detailChildData.qty);
          const detailChildPriceDiscounted = detail.big.newBig(detailChildData.priceDiscounted || 0).times(detailChildData.qty);
          const detailChildPriceFinal = detail.big.newBig(detailChildData.priceFinal || 0).times(detailChildData.qty);
          const detailChildCommissionBaseCost = detail.big.newBig(detailChildData.commissionBaseCost || 0).times(detailChildData.qty);
          totalPrice = totalPrice.plus(detailChildPrice);
          totalPriceDiscounted = totalPriceDiscounted.plus(detailChildPriceDiscounted);
          totalPriceFinal = totalPriceFinal.plus(detailChildPriceFinal);
          totalCommissionBaseCost = totalCommissionBaseCost.plus(detailChildCommissionBaseCost);
        })(setDetailsArray);
        detail.setData('price', totalPrice.toFixed(0));
        detail.setData('priceDiscounted', totalPriceDiscounted.toFixed(0));
        detail.setData('priceFinal', totalPriceFinal.toFixed(0)); // detail.setData('commissionBaseCost', totalCommissionBaseCost.toFixed(0))

        if (parent !== null && parent !== void 0 && parent.updateSynced) {
          parent.updateSynced();
        }
      }

      return detail;
    },

    recalculatePrice(detail) {
      if (detail.updateSynced) {
        detail.updateSynced();
      }

      return detail;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "addNewTemplate", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewTemplate"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "removeTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeTemplate"), _obj)), _obj)));

  let ChannelsShopCampaignsDetailsModel = (_dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsDetailsModel extends ChannelsShopCampaignsDetailsBaseModel {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "uploadcare", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor2, this);
    }

    getDefaultImageUrl(transform) {
      const defaultUploadUuid = R.pipe(R.pathOr([], ['_data', 'uploads']), R.find(R.pathEq(['_data', 'isDefault'], true)), R.pathOr('', ['_data', 'uuid']))(this);
      return this.uploadcare.getFileUrl(defaultUploadUuid, transform);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadcare", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getDefaultImageUrl", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultImageUrl"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsDetailsModel;
});