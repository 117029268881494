define("client/pods/components/documents/btn-refund/btn-void-dialog/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _applyDecoratedDescriptor2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let DocumentsBtnRefundBtnVoidDialogComponent = (_class = class DocumentsBtnRefundBtnVoidDialogComponent extends _component.default {
    *onSubmitVoid(onCloseDialogAction) {
      yield this.args.onSubmit();
      onCloseDialogAction();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmitVoid", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitVoid"), _class.prototype)), _class);
  _exports.default = DocumentsBtnRefundBtnVoidDialogComponent;
});