define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-commissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwTE+q21",
    "block": "{\"symbols\":[\"@tab\",\"@model\",\"@dirty\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\",\"@copyErrors\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor\",[],[[\"@tab\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@dirty\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],[[23,1,[]],false,true,[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@tab\",\"@hasEditBtn\",\"@copyErrors\",\"@model\",\"@dirty\",\"@onEditPanel\",\"@allowDuplicating\",\"@onPrepareDuplicateRecord\",\"@onCancelDuplicateRecord\",\"@duplicateRecordTask\",\"@onAssignSalesPerson\",\"@onUnassignSalesPerson\",\"@onToggleCommissionMethod\",\"@errorMsg\"],[[23,1,[]],true,[23,8,[]],[23,2,[]],[23,3,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-commissions/template.hbs"
    }
  });

  _exports.default = _default;
});