define("client/pods/components/channels/shop/campaigns/panels-edit/component", ["exports", "client/mixins/crud", "ramda-extension"], function (_exports, _crud, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      this.crud.addLists(this, ['channels/shop/campaigns/lists/subPanels']);
      const model = this.get('tab.model');
      const dirty = this.setupDirty({
        model
      });
      this.set('dirty', dirty); // if (!dirty.getData('password')) {
      //   const password = nventor.random.alphaNum(8).toLowerCase()
      //   dirty.setData('password', password)
      // }

      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },

    _displayCampaign(model) {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || [];
      const subTabOptions = subPanels.findBy('_data.value', activeSubTab.get('value'));
      const tabOptions = {
        loadInstructions: {
          component: subTabOptions.get('_data.component'),
          value: subTabOptions.get('_data.value')
        }
      };
      return this.onDisplay(model, tabOptions);
    },

    afterReplaceRecordTask(saved) {
      this._displayCampaign(saved);
    },

    afterCancelEditing(original) {
      this._displayCampaign(original);
    },

    actions: {
      cancel(dirty) {
        this.cancelEditing({
          adapterName: this.adapterName,
          dirty: dirty
        });
      }

    }
  });

  _exports.default = _default;
});