define("client/pods/channels/shop/rewards/lists/sub-panels-editor/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'details',
        label: 'details',
        component: 'channels/shop/rewards/rewards-editor/details-editor',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        errorsTracker: [{
          context: 'name',
          label: 'name'
        }, {
          context: 'websiteKeys',
          label: 'websiteKeys'
        }, {
          context: 'isAllowTrackRewardForNonMember',
          label: 'isAllowTrackRewardForNonMember'
        }, {
          context: 'status',
          label: 'status'
        }]
      }, {
        value: 'member levels',
        label: 'member levels',
        component: 'channels/shop/rewards/rewards-editor/member-levels-editor',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        errorsTracker: [{
          context: 'memberLevels.name',
          label: 'name'
        }, {
          context: 'memberLevels.description',
          label: 'description'
        }, {
          context: 'memberLevels.requiredPoints',
          label: 'requiredPoints'
        }, {
          context: 'memberLevels.expiryTime',
          label: 'expiryTime'
        }, {
          context: 'memberLevels.expiryPeriod',
          label: 'expiryPeriod'
        }, {
          context: 'memberLevels.rewardsEvents.type',
          label: 'type'
        }, {
          context: 'memberLevels.rewardsEvents.earnRequirementType',
          label: 'earnRequirementType'
        }, {
          context: 'memberLevels.rewardsEvents.earnRequirementValue',
          label: 'earnRequirementValue'
        }, {
          context: 'memberLevels.rewardsEvents.earnType',
          label: 'earnType'
        }, {
          context: 'memberLevels.rewardsEvents.earnRate',
          label: 'earnRate'
        }, {
          context: 'memberLevels.rewardsEvents.earnAmount',
          label: 'earnAmount'
        }, {
          context: 'memberLevels.rewardsEvents.startPeriod',
          label: 'startPeriod'
        }, {
          context: 'memberLevels.rewardsEvents.startTime',
          label: 'startTime'
        }, {
          context: 'memberLevels.rewardsEvents.expiryPeriod',
          label: 'expiryPeriod'
        }, {
          context: 'memberLevels.rewardsEvents.expiryTime',
          label: 'expiryTime'
        }, {
          context: 'memberLevels.rewardsEvents.isNeverExpire',
          label: 'isNeverExpire'
        }, {
          context: 'memberLevels.rewardsEvents.spendMaximumRate',
          label: 'spendMaximumRate'
        }, {
          context: 'memberLevels.rewardsEvents.ratePerPoint',
          label: 'ratePerPoint'
        }, {
          context: 'memberLevels.rewardsEvents.allowOnSale',
          label: 'allowOnSale'
        }, {
          context: 'memberLevels.rewardsEvents.allowWithPromoCode',
          label: 'allowWithPromoCode'
        }, {
          context: 'memberLevels.rewardsEvents.messageSuccess',
          label: 'messageSuccess'
        }, {
          context: 'memberLevels.rewardsEvents.spendRequirementAmount',
          label: 'spendRequirementAmount'
        }, {
          context: 'memberLevels.rewardsEvents.spendMaximumAmount',
          label: 'spendMaximumAmount'
        }, {
          context: 'memberLevels.rewardsEvents.status',
          label: 'status'
        }]
      }, {
        value: 'emails',
        label: 'emails',
        component: 'channels/shop/rewards/rewards-editor/emails-editor',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        errorsTracker: [{
          context: 'emailFromName',
          label: 'emailFromName'
        }, {
          context: 'emailFromAddress',
          label: 'emailFromAddress'
        }, {
          context: 'emailSignature',
          label: 'emailSignature'
        }, {
          context: 'birthdayEmailSubject',
          label: 'birthdayEmailSubject'
        }, {
          context: 'birthdayEmailMessage',
          label: 'birthdayEmailMessage'
        }, {
          context: 'signupEmailSubject',
          label: 'signupEmailSubject'
        }, {
          context: 'signupEmailMessage',
          label: 'signupEmailMessage'
        }, {
          context: 'promotionEmailSubject',
          label: 'promotionEmailSubject'
        }, {
          context: 'promotionEmailMessage',
          label: 'promotionEmailMessage'
        }, {
          context: 'rewardNotificationEmailSubject',
          label: 'rewardNotificationEmailSubject'
        }, {
          context: 'rewardNotificationEmailMessage',
          label: 'rewardNotificationEmailMessage'
        }]
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});