define("client/pods/components/lists/list-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-results'],
    itemLabelKey: '_data.label',
    // key that points to item's label
    itemValueKey: '_data.value',
    // key that points to item's value
    rememberSelected: false,
    // keep track of selected item
    selected: null,
    selectedItemWrappers: null,
    translate: false,
    value: '',
    showPagination: true,
    isViewable: true,

    init() {
      this._super(...arguments);

      this.set('itemClassNames', ['list-results__item']);
      this.set('legendClassNames', ['list-results__legend']);
    },

    hasPagination: Ember.computed('showPagination', function () {
      if (this.onPaginationTask && this.showPagination) {
        return true;
      }

      return false;
    }),

    selectItem(item
    /* , itemComponent */
    ) {
      // send action to onSelect event..only used for when list is in a dropdown
      if (this.onSelect) {
        this.onSelect(item);
      }
    },

    actions: {
      /**
       * listen for toggle event sent from items component
       * list-results only allows select (no deselect)
       */
      toggleItem(item, itemWrapper) {
        // select item
        this.selectItem(item, itemWrapper);
      }

    }
  });

  _exports.default = _default;
});