define("client/pods/components/products/panels-display/pages-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f4kg9mkb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__sidebar products-images__container\"],[8],[0,\"\\n    \"],[1,[28,\"products/files/files-gallery-uploader\",null,[[\"tab\",\"model\",\"allowUploads\",\"resource\",\"resourceKey\",\"allowSetDefault\",\"showDefaultImagePreview\"],[[24,[\"tab\"]],[24,[\"model\"]],true,\"products\",[24,[\"model\",\"_data\",\"_key\"]],true,true]]],false],[0,\"\\n\\n\"],[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"      \"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"translate\",\"value\",\"optionValueKey\"],[[23,0,[\"crud\",\"lists\",\"products-lists-tags\"]],false,[24,[\"model\",\"_data\",\"tags\"]],\"_data._key\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[1,[28,\"products/products-editor/pages-editor\",null,[[\"tab\",\"model\",\"isReadonly\",\"isDisabled\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"isReadonly\"]],[24,[\"isDisabled\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/pages-display/template.hbs"
    }
  });

  _exports.default = _default;
});