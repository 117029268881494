define("client/pods/components/documents/document-print/sub-header-references/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9iHMNMwc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSubHeader\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"documents-print__sub-header-table u-full-width\"],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"      \\n\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"ref\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\",true],[10,\"class\",\"documents__section documents__sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"tt\",[\"reference\"],null],false],[0,\": \"],[1,[24,[\"model\",\"_data\",\"ref\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"paymentMethodKey\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\",true],[10,\"class\",\"documents__section documents__sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"tt\",[\"payment method\"],null],false],[0,\": \"],[1,[28,\"tt\",[[24,[\"model\",\"_data\",\"paymentMethodKey\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"paymentLastFiveDigits\"]]],null,{\"statements\":[[0,\"            (\"],[1,[28,\"elements/element-label\",null,[[\"label\",\"translate\",\"classNames\"],[\"account last 5 digits\",true,\"u-desc\"]]],false],[0,\": \"],[1,[24,[\"model\",\"_data\",\"paymentLastFiveDigits\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showFromPerson\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\",true],[10,\"class\",\"documents__section documents__sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"tt\",[\"from person\"],null],false],[0,\": \"],[1,[28,\"user/get\",[\"name\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/sub-header-references/template.hbs"
    }
  });

  _exports.default = _default;
});