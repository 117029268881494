define("client/pods/components/ledgers/journals/cashbook/editor-row/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  let LedgersJournalsCashbookEditorRow = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('contacts'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class LedgersJournalsCashbookEditorRow extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "contactsService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "contactsList", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "contactRelationshipsList", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedContact", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "statement", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "isSingleEditing", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "dirtyModel", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "originalModel", _descriptor12, this);
      (0, _defineProperty2.default)(this, "adapterName", 'ledgers/journals/cashbook');
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'contacts/lists/relationships']);
      const thisMonth = this.date.getPeriod();
      this.monthStart = thisMonth.monthStart;
      this.monthEnd = thisMonth.monthEnd;
    }

    afterLoadLists() {
      var _this$args, _this$args2;

      if (this !== null && this !== void 0 && (_this$args = this.args) !== null && _this$args !== void 0 && _this$args.isNew || this !== null && this !== void 0 && (_this$args2 = this.args) !== null && _this$args2 !== void 0 && _this$args2.isSingleEditing) {
        this.setupDirtyModel();
      }
    }

    get isSingleOrBulkEditing() {
      var _this$args3;

      return this.isSingleEditing || (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.isBulkEditing);
    }

    get currentModel() {
      if (this.isSingleOrBulkEditing) {
        return this.dirtyModel;
      }

      return this.args.model;
    }

    get defaultDescription() {
      var _this$args4, _this$args4$tab;

      let desc = 'payment';

      if ((this === null || this === void 0 ? void 0 : (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$tab = _this$args4.tab) === null || _this$args4$tab === void 0 ? void 0 : _this$args4$tab.txType) === 'cashIn') {
        desc = 'receipt';
      }

      return this.intl.t(desc);
    }

    setupDirtyModel() {
      var _this$args5, _this$args6, _this$args7, _this$args7$tab, _this$args8, _this$args8$tab;

      const model = this === null || this === void 0 ? void 0 : (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.model;
      const isNew = this === null || this === void 0 ? void 0 : (_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : _this$args6.isNew;
      const account = this.args.account;
      const data = {
        dateZ: this.date.getStartOfDayZ(),
        description: this.defaultDescription,
        txType: this === null || this === void 0 ? void 0 : (_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : (_this$args7$tab = _this$args7.tab) === null || _this$args7$tab === void 0 ? void 0 : _this$args7$tab.txType,
        docType: this === null || this === void 0 ? void 0 : (_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : (_this$args8$tab = _this$args8.tab) === null || _this$args8$tab === void 0 ? void 0 : _this$args8$tab.docType
      };

      if (account) {
        var _account$_data, _account$_data2;

        data.accountKey = (account === null || account === void 0 ? void 0 : (_account$_data = account._data) === null || _account$_data === void 0 ? void 0 : _account$_data._key) || '';
        data.account = (account === null || account === void 0 ? void 0 : (_account$_data2 = account._data) === null || _account$_data2 === void 0 ? void 0 : _account$_data2.name) || '';
      }

      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        isNew,
        data
      });
      this.originalModel = model;
      this.dirtyModel = dirty;
      this.isSingleEditing = true;

      if (!isNew) {
        const contactKey = dirty.getData('contactKey');
        const docType = dirty.getData('docType');
        this.fetchContactTask.perform(contactKey, docType);
      } // this.errors = false

    }

    *fetchContactTask(contactKey, docType) {
      const contact = yield this.crud.findTask.perform({
        adapterName: 'contacts',
        appendPath: contactKey
      });
      this.selectedContact = contact; // @TODO fetch contact using crud.findTask

      this.setContactRelationshipsList();
      this.setDefaultRelationship();
      yield this.createStatementTask.perform(contactKey, docType);
    }

    setContactRelationshipsList() {
      const contact = (this === null || this === void 0 ? void 0 : this.selectedContact) || '';
      let contactRelationshipsList = [];

      if (contact) {
        var _contact$_data;

        const list = R_.dotPath('crud.lists.contacts-lists-relationships')(this) || [];
        const relationships = contact === null || contact === void 0 ? void 0 : (_contact$_data = contact._data) === null || _contact$_data === void 0 ? void 0 : _contact$_data.relationships;
        contactRelationshipsList = R.filter(listItem => {
          var _listItem$_data;

          const value = (listItem === null || listItem === void 0 ? void 0 : (_listItem$_data = listItem._data) === null || _listItem$_data === void 0 ? void 0 : _listItem$_data.value) || '';

          if (R.indexOf(value, relationships) > -1) {
            return true;
          }

          return false;
        })(list);
      }

      this.contactRelationshipsList = contactRelationshipsList;
    }

    setDefaultRelationship() {
      var _contact$_data2, _model$_data, _model$_data2;

      const list = this.contactRelationshipsList;
      const contact = this.selectedContact;
      const model = this.currentModel;
      const relationships = (contact === null || contact === void 0 ? void 0 : (_contact$_data2 = contact._data) === null || _contact$_data2 === void 0 ? void 0 : _contact$_data2.relationships) || '';
      const txType = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.txType) || '';
      const relationship = (model === null || model === void 0 ? void 0 : (_model$_data2 = model._data) === null || _model$_data2 === void 0 ? void 0 : _model$_data2.relationship) || '';
      R.forEach(listItem => {
        var _listItem$_data2;

        const value = (listItem === null || listItem === void 0 ? void 0 : (_listItem$_data2 = listItem._data) === null || _listItem$_data2 === void 0 ? void 0 : _listItem$_data2.value) || '';

        if (R.indexOf(value, relationships) > -1) {
          if (R.isEmpty(relationship)) {
            if (txType === 'cashIn' && value === 'customer') {
              model.setData('relationship', value);
            }

            if (txType === 'cashOut' && value === 'supplier') {
              model.setData('relationship', value);
            }
          }
        }
      })(list);

      if (R.isEmpty(relationship) && list.length > 0) {
        const first = (list === null || list === void 0 ? void 0 : list.firstObject) || {};
        model.setData('relationship', (first === null || first === void 0 ? void 0 : first._data.value) || '');
      }

      this.changeDocTypeBasedOnRelationship();
    }

    *createStatementTask(contactKey, docType) {
      const statement = yield this.args.onFetchStatementTask({
        key: contactKey,
        docType,
        dateStart: this.monthStart,
        dateEnd: this.monthEnd
      });
      this.statement = statement;
    }

    get statementList() {
      const statementList = [];
      const statementLabel = ['current', '30 days', '60 days', '90 days', '120+ days'];
      const statement = this.statement || [];
      R.pipe(R.propOr({}, '_data'), R.filter(R.has('total')), R_.mapIndexed((data, index) => {
        data.statementLabel = `${this.intl.t(statementLabel[index])} : $${data.total}`;
        const emberObjData = Ember.Object.create(data);
        statementList.pushObject(emberObjData);
      }))(statement);
      return statementList;
    }

    *loadContactAndStatementTask(contact) {
      var _contact$_data3, _model$_data3;

      const model = this.currentModel;
      const contactKey = (contact === null || contact === void 0 ? void 0 : (_contact$_data3 = contact._data) === null || _contact$_data3 === void 0 ? void 0 : _contact$_data3._key) || '';
      const docType = (model === null || model === void 0 ? void 0 : (_model$_data3 = model._data) === null || _model$_data3 === void 0 ? void 0 : _model$_data3.docType) || '';
      model.setData('contactKey', contactKey);
      this.selectedContact = contact;
      this.setContactRelationshipsList();
      this.setDefaultRelationship();
      yield this.createStatementTask.perform(contactKey, docType);
    }

    unloadContactAndStatement() {
      const model = this.currentModel;
      model.setData('contact', '');
      model.setData('contactKey', '');
      model.setData('relationship', '');
      this.selectedContact = null;
      this.contactRelationshipsList = [];
    }

    cancel() {
      var _this$args9;

      const isNew = this === null || this === void 0 ? void 0 : (_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : _this$args9.isNew;

      if (isNew) {
        return this.setupDirtyModel();
      }

      const dirty = this.dirtyModel;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.dirtyModel = '';
      this.isSingleEditing = false; // this.errors = false
    }

    onAfter() {
      this.cancel();
      this.args.onReloadAccountTask();
    }

    changeDocTypeBasedOnRelationship() {
      var _this$currentModel, _this$currentModel$_d;

      const relationship = (_this$currentModel = this.currentModel) === null || _this$currentModel === void 0 ? void 0 : (_this$currentModel$_d = _this$currentModel._data) === null || _this$currentModel$_d === void 0 ? void 0 : _this$currentModel$_d.relationship;

      if (R.equals(relationship, 'customer')) {
        this.currentModel.setData('docType', 'sales');
      }

      if (R.equals(relationship, 'supplier')) {
        this.currentModel.setData('docType', 'purchases');
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactsList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactRelationshipsList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedContact", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "statement", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSingleEditing", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirtyModel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "originalModel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupDirtyModel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirtyModel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchContactTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchContactTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setContactRelationshipsList", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setContactRelationshipsList"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDefaultRelationship", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultRelationship"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createStatementTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadContactAndStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactAndStatementTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unloadContactAndStatement", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "unloadContactAndStatement"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onAfter", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onAfter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeDocTypeBasedOnRelationship", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "changeDocTypeBasedOnRelationship"), _class.prototype)), _class));
  _exports.default = LedgersJournalsCashbookEditorRow;
});