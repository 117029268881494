define("client/pods/components/forms/form-section/component", ["exports", "ramda"], function (_exports, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['getClassNames'],
    getClassNames: Ember.computed('betaPath', 'isBetaAllowed', function () {
      if (this.betaPath && this.isBetaAllowed === false) {
        return 'form-section__outer-container--no-border';
      }

      return 'form-section__outer-container';
    }),
    translate: true,
    toggle: true,
    isModal: false,
    toggleOnIconClassName: 'fas fa-caret-down',
    toggleOffIconClassName: 'fas fa-caret-up',
    isViewable: true,
    isAccordian: false,
    isAccordianCollapsed: Ember.computed('toggle', 'isAccordian', function () {
      if (this.isAccordian && this.toggle === false) {
        return true;
      }

      return false;
    }),
    hasErrorClassNames: Ember.computed('error', function () {
      const error = this.error;

      if (error) {
        if (_ramda.default.is(Array, error) && _ramda.default.isEmpty(error)) {
          return '';
        }

        return 'has-error';
      }

      return '';
    }),
    isBetaAllowed: Ember.computed('betaPath', function () {
      if (!this.betaPath) {
        return true;
      }

      return this.settings.getProp(this.betaPath);
    }),
    actions: {
      preToggleAction(toggleAction) {
        if (this.isAccordian) {
          toggleAction();
        }
      }

    }
  });

  _exports.default = _default;
});