define("client/pods/components/settings/defaults/3rd-party-editor/facebook-plugins/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kUIS4M/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"facebook page\",[24,[\"errors\"]],\"facebookPage\",\"settings\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"lists/list-dropdown\",null,[[\"disabled\",\"isBtn\",\"allowDeselect\",\"translate\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\"],[[24,[\"isReadonly\"]],true,true,false,[24,[\"settings\",\"settingsModel\",\"_data\",\"facebookPages\"]],\"_data.id\",\"_data.name\",[24,[\"model\",\"_data\",\"facebookPage\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/defaults/3rd-party-editor/facebook-plugins/template.hbs"
    }
  });

  _exports.default = _default;
});