define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  // import * as R from 'ramda'
  // import * as RA from 'ramda-adjunct'
  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "commissionBaseCostHistoryFilters", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "commissionBaseCostHistoryData", _descriptor4, this);
      (0, _defineProperty2.default)(this, "tagName", 'span');
      (0, _defineProperty2.default)(this, "classNames", ['']);
      (0, _defineProperty2.default)(this, "isReadonly", false);
      (0, _defineProperty2.default)(this, "adapterName", 'channels/shop/campaigns/details/commission-base-cost/history');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'channels/shop/campaigns/details/commission-base-cost/history/filters');
      const commissionBaseCostHistoryFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.commissionBaseCostHistoryFilters = commissionBaseCostHistoryFilters;
    }

    *fetchHistoryOnInitTask() {
      var _this$args, _this$args2;

      const isUsingAverage = this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.isUsingAverage;
      const itemKey = this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.itemKey;
      const selectedCommissionBaseCostMethod = this.args.selectedCommissionBaseCostMethod;
      const commissionBaseCostHistoryFilters = this.commissionBaseCostHistoryFilters;
      commissionBaseCostHistoryFilters.setData('itemKey', itemKey);
      commissionBaseCostHistoryFilters.setData('isUsingAverage', isUsingAverage);
      commissionBaseCostHistoryFilters.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionBaseCostHistoryFilters
      });
      this.commissionBaseCostHistoryData = results;
    }

    get isDisabled() {
      var _this$args3;

      if (!(this !== null && this !== void 0 && (_this$args3 = this.args) !== null && _this$args3 !== void 0 && _this$args3.itemKey)) {
        return true;
      }

      return this.isReadonly;
    }

    select(model, history, onHideDropdown) {
      this.args.onSelect.perform({
        model,
        history,
        onHideDropdown
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commissionBaseCostHistoryFilters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commissionBaseCostHistoryData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchHistoryOnInitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchHistoryOnInitTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "select", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector;
});