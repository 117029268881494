define("client/pods/components/products/products-editor/pages-editor/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ramda-adjunct"], function (_exports, _applyDecoratedDescriptor2, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    tabsManager: Ember.inject.service(),
    activeTemplateIndex: null,

    didInsertElement() {
      this._super(...arguments);

      if (!this.model) {
        return false;
      }

      const templateModel = this.model.getData('templates').get('firstObject');
      this.selectTemplate(this, templateModel);
    },

    updateContent(template) {
      this.model.setData('template', template);
    },

    newTemplate(model) {
      const templateModel = model.addNewTemplate();
      this.selectTemplate(templateModel);
    },

    selectTemplate(templateModel = false) {
      var _this$model, _this$model$_data;

      const templates = ((_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$_data = _this$model._data) === null || _this$model$_data === void 0 ? void 0 : _this$model$_data.templates) || [];
      let index = (templates === null || templates === void 0 ? void 0 : templates.indexOf(templateModel)) || 0;

      if (index < 0) {
        index = 0;
      }

      this.set('activeTemplateIndex', index);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "newTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "newTemplate"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "selectTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj)), _obj)));

  _exports.default = _default;
});