define("client/pods/components/channels/shop/shipping-zones/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y+pVyghO",
    "block": "{\"symbols\":[\"@tab\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\"],\"statements\":[[5,\"layouts/table-view\",[],[[\"@tab\",\"@tableClassNames\",\"@detailsRowComponent\",\"@hasHeadersSort\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\",\"@onMainAction\"],[[23,1,[]],\"shipping-zones-dash\",\"channels/shop/shipping-zones/table-view/detail-row\",true,[23,2,[\"results\"]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,7,[]]]]],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"u-table-align-middle u-full-width\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[5,\"helpers/pagination-controls\",[],[[\"@showPage\",\"@classNames\",\"@filters\",\"@list\",\"@onPaginationTask\"],[true,\"pagination-controls-container--small\",[23,2,[]],[23,2,[\"results\"]],[23,5,[]]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});