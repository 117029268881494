define("client/pods/extensions/apps/tw/e-invoices/service", ["exports", "client/config/environment", "ember-concurrency", "ramda"], function (_exports, _environment, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import AjaxMixin from '../../mixins/ajax'

  /**
   * performs and manages connections to servers
   */
  var _default = Ember.Service.extend({
    crud: Ember.inject.service(),
    server: Ember.inject.service(),
    printer: Ember.inject.service(),
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    host: _environment.default.APP.serverUrl,
    fetchToIssueEInvoicesTask: (0, _emberConcurrency.task)(function* ({
      component,
      adapterName = 'extensions/apps/tw/e-invoices/to-issue',
      appendPath,
      filters,
      period = {},
      dispatchProcess,
      includeBeforeStartDate
    } = {}) {
      const data = {
        isByDispatchProcess: !period.date,
        dateEnd: period.date || ''
      };

      if (!includeBeforeStartDate) {
        data.dateStart = period.date;
      }

      if (dispatchProcess && !period.date) {
        data.dispatchProcess = dispatchProcess.serialize();
      }

      try {
        const results = yield this.crud.searchRecordsTask.perform({
          adapterName,
          appendPath,
          filters,
          data
        });
        component.set('toIssue', R.pathOr([], ['toIssue'])(results));
        component.set('invalid', R.pathOr([], ['invalid'])(results));
        component.set('invalidCount', R.pathOr([], ['invalidCount'])(results));
        component.set('toIssueCount', R.pathOr([], ['toIssueCount'])(results));
        return results;
      } catch (err) {// this.setValidationErrors('notifyErrors', err)
      }
    }),
    fetchTrackNumbers: (0, _emberConcurrency.task)(function* ({
      component,
      resultProp = 'track',
      mm = '',
      yyyy = '',
      period
    } = {}) {
      if (period) {
        mm = period.mm;
        yyyy = period.yyyy;
      }

      const trackModel = yield this.crud.findTask.perform({
        adapterName: 'extensions/apps/tw/e-invoices/tracks',
        params: {
          mm,
          yyyy
        }
      });

      if (component && resultProp) {
        component[resultProp] = trackModel;
      }

      return trackModel;
    }),
    uploadBlanksTask: (0, _emberConcurrency.task)(function* ({
      mm = '',
      yyyy = '',
      period,
      uploadUnusedBlanks
    } = {}) {
      if (period) {
        mm = period.mm;
        yyyy = period.yyyy;
      }

      return yield this.server.call('POST', 'api/protected/extensions/apps/tw/e-invoices/upload-blanks', {
        mm,
        yyyy,
        uploadUnusedBlanks
      });
    }),
    customPaginateSearchRecordsTask: (0, _emberConcurrency.task)(function* (component, ...args) {
      const results = yield this.crud.paginateSearchRecordsTask.perform(...args);
      component.set('toIssue', R.pathOr([], ['toIssue'])(results));
      component.set('invalid', R.pathOr([], ['invalid'])(results));
      component.set('invalidCount', R.pathOr([], ['invalidCount'])(results));
      component.set('toIssueCount', R.pathOr([], ['toIssueCount'])(results));
    }),
    issueAllEInvoicesForPeriodTask: (0, _emberConcurrency.task)(function* (args = {}) {
      const {
        component,
        onCloseDialogAction,
        period = {},
        dispatchProcess,
        includeBeforeStartDate,
        twEInvoicePrint = false,
        twEInvoicePrinterId = null
      } = args;
      const payload = {
        isByDispatchProcess: !period.date,
        dateEnd: period.date || '',
        twEInvoicePrint,
        twEInvoicePrinterId
      };

      if (!includeBeforeStartDate) {
        payload.dateStart = period.date;
      }

      if (dispatchProcess && !period.date) {
        payload.dispatchProcess = dispatchProcess.serialize();
      }

      component.set('printErrors', []);

      try {
        component.set('hasResults', false);
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issueBatch({
          payload
        });
        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('printErrors', err, component);
        return false;
      }
    }).drop(),
    issueTask: (0, _emberConcurrency.task)(function* (component, onCloseDialogAction, document, custom) {
      const payload = {
        _key: document.getData('_key'),
        custom
      };
      component.set('issueErrors', []);

      try {
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
          payload
        });
        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }).drop()
  });

  _exports.default = _default;
});