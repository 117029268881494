define("client/pods/components/elements/element-textarea/component", ["exports", "client/mixins/translate"], function (_exports, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import isDirtyMixin from '../../../../mixins/is-dirty';
  var _default = Ember.Component.extend(_translate.default, {
    // export default Ember.Component.extend(
    //   translateMixin,
    //   isDirtyMixin, {
    tagName: 'span',
    classNames: ['element-textarea'],
    autofocus: false,
    translate: true,
    // set default rows to 2 lines
    rows: 1,
    maxRows: 5,
    autoResize: true,
    onInput: null,

    init() {
      // @NOTE: ember textarea becomes two lines if value=undefined
      this._super(...arguments);

      if (this.value == null) {
        this.set('value', '');
      }
    },

    actions: {
      submit: function () {
        // send onSubmit action
        if (this.onSubmit) {
          this.onSubmit();
        }
      }
    }
  });

  _exports.default = _default;
});