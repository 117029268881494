define("client/pods/components/modals/modal-dialog/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  let ModalsModalDialogComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class ModalsModalDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "modalElement", _descriptor, this);
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get closeOnOverlayClick() {
      return R.pathOr(true, ['args', 'closeOnOverlayClick'])(this);
    }

    didInsert(element) {
      const tabGrid = element.closest('.tab-grid');

      if (tabGrid) {
        this.modalElement = element;
        const children = tabGrid.children || [];
        const modalContainer = R.find(child => {
          return R.includes('tab-grid__modal', child.className);
        })(children);

        if (modalContainer) {
          modalContainer.appendChild(element);
        }
      }
    }

    close() {
      if (this.modalElement) {
        this.modalElement.remove();
      }

      if (this.args.onToggle) {
        this.args.onToggle(false);
      }

      if (this.args.onClose) {
        this.args.onClose();
      }
    }

    willDestroyElement() {
      this.close();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modalElement", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype)), _class));
  _exports.default = ModalsModalDialogComponent;
});