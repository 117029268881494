define("client/pods/workflows/stages-collection/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data = {}) {
      data = this._transformWorkflow(data);
      data.collection = this._transformCollection(data);

      this._super(...arguments);
    },

    _transformWorkflow(data) {
      const workflow = R.path(['workflow'])(data);

      if (RA.isNotNilOrEmpty(workflow)) {
        data.workflow = this.dataManager.setAsRecord({
          adapterName: 'workflows',
          // @TODO: this should be the workflow model instead
          data: workflow
        });
      }

      return data;
    },

    _transformCollection(data) {
      return R.pipe(R.pathOr([], ['collection']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/items',
          data
        });
      }))(data);
    },

    byStages: Ember.computed('synced', '_data.{collection.@each.synced,stages.@each.synced,workflow.synced}', function () {
      var _this$_data, _this$_data2;

      const workflow = ((_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.workflow) || [];
      const stages = R.pathOr([], ['_data', 'stages'])(workflow);
      const itemsCollection = ((_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : _this$_data2.collection) || [];
      const allStageIds = R.pipe(R.map(R.path(['_data', 'stageId'])), R.reject(R.equals('unassigned')))(stages);
      return R.map(stage => {
        var _stage$_data;

        const stageId = (_stage$_data = stage._data) === null || _stage$_data === void 0 ? void 0 : _stage$_data.stageId;
        let items = [];
        let isHidden = false;

        if (stageId === 'unassigned') {
          items = R.reject(item => {
            const stageId = item.getData('stageId');
            return R.includes(stageId)(allStageIds);
          })(itemsCollection);

          if (items.length === 0) {
            isHidden = true;
          }
        } else {
          items = R.filter(R.pathEq(['_data', 'stageId'], stageId))(itemsCollection);
        }

        items = R.reject(R.anyPass([R.pathEq(['isDeleted'], true), R.pathEq(['_data', 'isDeleted'], true)]))(items);
        return {
          count: items.length,
          stage,
          items,
          isHidden
        };
      })(stages);
    })
  });

  _exports.default = _default;
});