define("client/pods/components/admin/payments/card/panels-checkout-logs/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let AdminPaymentsCardPanelsCheckoutLogsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class AdminPaymentsCardPanelsCheckoutLogsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor3, this);
      this.filters = this.search.setupFilters({
        adapterName: 'admin/payments/card/checkout-logs/filters'
      });
      this.fetchCheckoutIdLogs.perform();
    }

    *fetchCheckoutIdLogs() {
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'admin/payments/card/checkout-logs',
        filters: this.filters
      });
      console.log(results);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchCheckoutIdLogs", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCheckoutIdLogs"), _class.prototype)), _class));
  _exports.default = AdminPaymentsCardPanelsCheckoutLogsComponent;
});