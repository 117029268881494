define("client/pods/components/products/panels-inventory/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'products/inventory',
    filtersAdapterName: 'products/inventory/filters',
    count: 20,
    page: 1,
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),

    didInsertElement() {
      this._super(...arguments);

      this.fetchAdjustmentsTask.perform();
    },

    fetchAdjustmentsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      const searchSettings = {
        adapterName: 'products/inventory/adjustments',
        resultsProperty: '',
        resultsToggle: '',
        filters
      };
      const adjustmentsData = yield this.searchRecordsTask.perform(searchSettings);
      this.set('adjustmentsData', adjustmentsData);
      return adjustmentsData;
    })
  });

  _exports.default = _default;
});