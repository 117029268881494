define("client/pods/components/products/files/mixins/products-files", ["exports", "ember-concurrency", "client/mixins/crud", "client/utils/nventor"], function (_exports, _emberConcurrency, _crud, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, {
    adapterNameForSetDefault: 'products',
    adapterName: 'products',
    fetchProduct: true,

    // @deprecated. remove this
    didInsertElement() {
      this._super(...arguments);

      if (this.fetchProduct) {
        this.fetchProductsTask.perform();
      }
    },

    didInsertTask: (0, _emberConcurrency.task)(function* (resourceKey) {
      if (this.fetchProduct) {
        this.fetchProductsTask.perform(resourceKey);
      }
    }),
    fetchProductsTask: (0, _emberConcurrency.task)(function* (resourceKey) {
      resourceKey = resourceKey || this.resourceKey;

      if (resourceKey) {
        const adapterName = 'products';
        const product = yield this.dataManager.findRecord({
          adapterName,
          appendPath: `/${resourceKey}`
        });
        this.set('product', product);
      }
    }),
    setAsDefaultImageTask: (0, _emberConcurrency.task)(function* (file) {
      if (this.allowSetDefault) {
        // no need to send entire record over, should only update record, not replace
        const adapterNameForSetDefault = this.adapterNameForSetDefault;
        const resourceKey = this.resourceKey;
        const product = yield this.dataManager.findRecord({
          adapterName: adapterNameForSetDefault,
          appendPath: `/${resourceKey}`
        });
        const dirty = this.dataManager.getDirtyRecord(adapterNameForSetDefault, product);
        dirty.setData('defaultImage', file._data.uuid);

        try {
          return yield this.dataManager.updateRecord({
            adapterName: adapterNameForSetDefault,
            dirty
          });
        } catch (err) {
          this.set('errors', err.data);

          _nventor.default.throw('setAsDefaultImageTask failed', err);
        }
      }
    }).drop()
  });

  _exports.default = _default;
});