define("client/pods/components/channels/shop/promo-codes/tab-container/component", ["exports", "ember-concurrency", "client/utils/nventor", "ramda"], function (_exports, _emberConcurrency, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    results: null,
    resultsToggle: false,
    adapterName: 'channels/shop/promo-codes',
    filtersAdapterName: 'channels/shop/promo-codes/filters',

    init() {
      this.crud.addLists(this, ['filters/count', 'filters/criterias', 'channels/shop/promo-codes/lists/menu', 'discounts/types', 'statuses', 'toggles/yesNo']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.crud.findTask.perform({
            appendPath: id
          });
          this.set('isOnLoad', true);
          this.display(found);
        }
      } else {
        this.set('isOnLoad', true);
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.crud.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          listByTask: this.listByTask
        });
        this.set('results', results);
        this.dash({}, results);
      }
    }),
    listByTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      match,
      status,
      byLatest,
      filters,
      query,
      resultsProperty = 'results',
      resultsToggle
    } = {}, onHideDropdown) {
      // when list by always set to page 1
      filters.setData('page', 1);

      if (match) {
        filters.setData('match', match);
      }

      if (status) {
        if (!R.is(Array, status)) {
          status = [status];
        }

        filters.setData('status', status);
      }

      if (byLatest) {
        query = '';
        filters.setData('query', '');
        filters.setData('sort', [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]);
      }

      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      this.set(resultsProperty, results);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }),

    dash(model, results) {
      this.set('showResults', false);
      const tab = this.tab;

      if (_nventor.default.isNilOrEmpty(results) && !this.get('isOnLoad')) {
        const filters = this.filters;
        filters.setData('query', '');
        this.loadTabTask.perform();
      }

      this.set('isOnLoad', false);
      const subTabOptions = {
        component: 'channels/shop/promo-codes/panels-dash'
      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    },

    display(model, additionalOptions = {}) {
      this.set('showResults', false);
      const panelOptions = {
        component: 'channels/shop/promo-codes/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    },

    new(subTabOptions) {
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      dash() {
        this.dash();
      },

      display(model, options) {
        if (model) {
          this.display(model, options);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(subTabOptions) {
        // create tab within product tab
        this.new(subTabOptions);
      },

      edit(model, additionalOptions) {
        const tab = this.tab;
        const panelOptions = {
          component: 'channels/shop/promo-codes/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
      },

      import(subTabOptions) {
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }

    }
  });

  _exports.default = _default;
});