define("client/pods/components/channels/shop/promo-codes/promo-codes-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ChannelsShopPromoCodesPromoCodesEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ChannelsShopPromoCodesPromoCodesEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "constants", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor3, this);
      (0, _defineProperty2.default)(this, "disabled", false);
      (0, _defineProperty2.default)(this, "translate", true);
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'channels/shop/campaigns/filters');
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'products/lists/editorSubPanels', 'settings/roundingAuto', 'units', 'units/dimensions', 'units/weight', 'statuses' // 'rounding'
      ]);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.campaignFilters = campaignFilters;
    }

    loadShopSource(model, shop) {
      model.setData('allowedShopKey', shop.getData('_key'));
    }

    unloadShopSource(model) {
      model.setData('allowedShopKey', '');
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadShopSource", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unloadShopSource", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype)), _class));
  _exports.default = ChannelsShopPromoCodesPromoCodesEditorComponent;
});