define("client/pods/channels/shop/campaigns/automations/collection/model", ["exports", "client/pods/base/model", "client/mixins/sortable-details", "ramda"], function (_exports, _model, _sortableDetails, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sortableDetails.default, {
    toSortProp: '_data.automations',
    toSortArray: Ember.computed('_detailsArray.[]', 'synced', function () {
      return this.get('_data.automations');
    }),

    init() {
      this._super(...arguments);

      this.set('childAdapters.automations', 'channels/shop/campaigns/automations');
    },

    populate(data, attrs) {
      data.automations = this._transformAutomations(data.automations);

      this._super(data, attrs);
    },

    _transformAutomations(automations = []) {
      return automations.map(automation => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.automations'),
          data: automation
        });
      });
    },

    newBlankAutomation(data) {
      return this.dataManager.newRecord({
        adapterName: this.get('childAdapters.automations'),
        data: data
      });
    },

    _nextIndex(details = []) {
      return details.length + 1;
    },

    pushAutomation(automationModel) {
      const automations = this.getData('automations') || [];
      automationModel.setData('index', this._nextIndex(automations));
      automations.pushObject(automationModel);
    },

    removeAutomation(automation) {
      // @TODO: is this used?
      const automations = this.getData('automations'); // remove detail

      automations.removeObject(automation); // recalulate index for each detail

      automations.forEach((automation, index) => {
        automation.setData('index', index + 1);
      });
    },

    sorted: Ember.computed('_data.automations.@each.synced', 'synced', function () {
      var _this$_data;

      const detailsArray = (this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.automations) || [];
      let sortArrayInstructions = this.sortArrayInstructions || [{
        direction: 'ascend',
        prop: this.indexProp
      }];

      if (!R.is(Array, sortArrayInstructions)) {
        sortArrayInstructions = [sortArrayInstructions];
      }

      sortArrayInstructions = R.map(instructions => {
        const pathArray = R.split('.', instructions.prop);

        if (instructions.direction === 'descend') {
          return R.descend(R.path(pathArray));
        }

        return R.ascend(R.path(pathArray));
      })(sortArrayInstructions);
      return R.sortWith(sortArrayInstructions)(detailsArray);
    })
  });

  _exports.default = _default;
});