define("client/pods/components/files-manager/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  let FilesManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 10
  }), (_class = (_temp = class FilesManagerComponent extends _component.default {
    // individual model in uploadsCollection._data.uploads
    // collection model that holds _data.uploads
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tabsManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "server", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "loading", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "uploadsCollection", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedResource", _descriptor8, this);
      (0, _defineProperty2.default)(this, "adapterName", 'uploads');
      (0, _defineProperty2.default)(this, "collectionAdapterName", 'uploads/collection');
      (0, _defineProperty2.default)(this, "filtersAdapterName", 'uploads/filters');
      (0, _defineProperty2.default)(this, "finderLabel", this.args.finderLabel || 'files_manager_tags');
      this.crud.addLists(this, ['uploads/lists/resources']);
      this.fetchUploadsTask.perform({
        excludeCopies: false,
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });
    }

    get translate() {
      return R_.dotPathOr(true, 'args.translate')(this);
    }

    get label() {
      return R_.dotPathOr('upload images', 'args.label')(this);
    }

    get isRunning() {
      return this.loading && this.uploadsCollection || this.fetchUploadsTask.isRunning;
    }

    get allowUploads() {
      return R.pathOr(true, ['args', 'allowUploads'])(this);
    }

    get filesBrowserFilter() {
      return this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
    }

    get onlyDisplayDefault() {
      return R.pathOr(false, ['args', 'onlyDisplayDefault'])(this);
    }

    get imageWidth() {
      return R.pathOr('80', ['args', 'imageWidth'])(this);
    }

    get allowMultipleItems() {
      return R.pathOr(true, ['args', 'allowMultipleItems'])(this);
    }

    animateLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }

    *afterUploadTask(onCloseDialogActionListBtn) {
      yield this.fetchUploadsTask.perform();

      if (onCloseDialogActionListBtn) {
        onCloseDialogActionListBtn();
      }
    }

    *fetchUploadsTask(options = {}) {
      const {
        excludeCopies = false,
        onlyDisplayDefault = false
      } = options;
      this.uploadsCollection = yield this.crud.fetchUploadsTask.perform({
        resource: this.args.resource,
        resourceKey: this.args.resourceKey,
        adapterName: this.collectionAdapterName,
        params: {
          excludeCopies,
          onlyDisplayDefault,
          count: 30
        }
      });
    }

    *copyImageTask(onCloseDialogAction, options, uploadsModel, data) {
      if (RA.isNilOrEmpty(uploadsModel)) {
        return;
      }

      if (this.args.onSubmit) {
        return this.args.onSubmit(onCloseDialogAction, options, uploadsModel, data);
      }

      if (R.type(uploadsModel) !== 'Array') {
        uploadsModel = [uploadsModel];
      }

      this.animateLoading();
      const copyAllImagesTasks = R.map(item => this.copyAll.perform(item, options))(uploadsModel);
      yield (0, _emberConcurrency.all)(copyAllImagesTasks);
      this.fetchUploadsTask.perform({
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    *copyAll(item = [], options) {
      const newModel = this.crud.setupNewRecord({
        adapterName: this.adapterName,
        data: item.serialize()
      });
      const hasOptions = RA.isNotEmpty(options);

      if (hasOptions) {
        R.forEachObjIndexed((value, key) => newModel.setData(key, value))(options);
      }

      newModel.setData('resource', this.args.resource);
      newModel.setData('resourceKey', this.args.resourceKey);
      yield this.crud.createRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/copy',
        model: newModel
      });
    }

    *remove(onCloseDialogAction, options, uploadsModel) {
      const msg = this.intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        const removeAllTasks = R.map(item => this.removeAll.perform(item))(uploadsModel);
        yield (0, _emberConcurrency.all)(removeAllTasks);
        yield this.fetchUploadsTask.perform({
          onlyDisplayDefault: this.args.onlyDisplayDefault
        });

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      }
    }

    *removeAll(file) {
      const data = {
        uuid: file._data.uuid,
        resource: file._data.resource,
        resourceKey: file._data.resourceKey
      };
      return yield this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
    }

    *setAsDefaultImageTask(onCloseDialogActionListBtn, options, uploadsModel, data) {
      this.animateLoading();
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model: uploadsModel
      });
      const hasOptions = RA.isNotEmpty(options);

      if (hasOptions) {
        R.forEachObjIndexed((value, key) => dirty.setData(key, value))(options);
      }

      yield this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/default',
        model: dirty
      });
      yield this.fetchUploadsTask.perform({
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });

      if (this.args.onSetAsDefaultImageTask) {
        yield this.args.onSetAsDefaultImageTask();
      }

      if (onCloseDialogActionListBtn) {
        onCloseDialogActionListBtn();
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "server", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadsCollection", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedResource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "afterUploadTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploadTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchUploadsTask", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "fetchUploadsTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "copyImageTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "copyImageTask"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "copyAll", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "copyAll"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "remove", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeAll", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAll"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAsDefaultImageTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setAsDefaultImageTask"), _class.prototype)), _class));
  _exports.default = FilesManagerComponent;
});