define("client/pods/components/contacts/panels-duplicates/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let ContactsPanelsDuplicatesComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = (_temp = class ContactsPanelsDuplicatesComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "duplicatesByEmails", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "duplicates", _descriptor3, this);
    }

    onDidInsert() {
      this.fetchDuplicatesTask.perform();
    }

    *fetchDuplicatesTask() {
      this.duplicatesByEmails = yield this.dataManager.ajaxCall('GET', 'contacts', '/duplicates', {});
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "duplicatesByEmails", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "duplicates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDidInsert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchDuplicatesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDuplicatesTask"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDuplicatesComponent;
});