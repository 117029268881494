define("client/pods/grapesjs/plugins/full-width-image", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global grapesjs */
  const pluginName = 'full-width-image';
  const Plugin = grapesjs.plugins.add(pluginName, (editor, options = {}) => {
    const components = editor.DomComponents;
    const styles = `
    <style>
      .full-width-image {
        width: 100%;
      }
    </style>
  `;
    const defaultOptions = {
      extend: 'image',
      model: {
        defaults: {
          resizable: false,
          components: [styles],
          classes: [pluginName],
          attributes: {
            type: pluginName
          }
        }
      },
      view: {
        onRender({
          model
          /* , editor, el */

        }) {
          model.removeStyle('width'); // add id as class name. so, style can be added to a specific image

          model.addClass(model.getId());
        }

      }
    };
    editor.Commands.add('open-assets', {
      run(editor, sender, opts = {}) {
        if (options.onOpenAssets) {
          let imgType = R.path(['target', 'attributes', 'imgType'], opts);

          if (!imgType) {
            imgType = 'background-img';
          }

          options.onOpenAssets(imgType, {
            onImageSelect(type, url, mimeType) {
              if (opts.target) {
                if (type === 'background-img') {
                  opts.target.addStyle({
                    'background-image': `url(${url})`
                  });
                } else {
                  opts.target.set('src', url);
                  opts.target.addAttributes({
                    'data-gjs-type': type,
                    'data-mime-type': mimeType,
                    imgType
                  });
                }
              }
            }

          });
        }
      }

    });
    options = R.mergeDeepRight(defaultOptions, options);
    components.addType(pluginName, options);
    const pluginOptions = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      /** @type {Categories} */
      categories
    } = pluginOptions;
    editor.BlockManager.add(pluginName, {
      label: 'Full Width Image',
      category: categories.basic,
      content: {
        style: {
          width: '100%'
        },
        activeOnRender: 1,
        badgable: true,
        type: pluginName,
        imgType: pluginName,
        traits: []
      },
      render: () => `
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1,-3.32468,1.7053e-13)'>
            <g>
                <g transform='matrix(1,0,0,1,-3.28194,-1.08002e-12)'>
                    <g transform='matrix(1.37733,0,0,1.65228,-180.565,-864.206)'>
                        <path d='M207.809,832.911L800.909,832.911' style='fill:none;stroke:black;stroke-width:29.59px;'/>
                    </g>
                    <g transform='matrix(1,0,0,1,118.44,399.43)'>
                        <g transform='matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                        <g transform='matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                    </g>
                    <g transform='matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)'>
                        <g transform='matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                        <g transform='matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                    </g>
                </g>
                <g transform='matrix(1,0,0,1,1.86342,37.2616)'>
                    <g transform='matrix(1.00794,0,0,0.792537,12.4421,44.5258)'>
                        <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:38.6px;stroke-linecap:square;stroke-linejoin:miter;'/>
                    </g>
                    <g transform='matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)'>
                        <g transform='matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)'>
                            <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:18.25px;'/>
                        </g>
                        <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                            <g transform='matrix(-0.93623,0,0,0.939727,1014.78,130.716)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                            <g transform='matrix(-1.01027,0,0,0.756475,916.709,228.489)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

`
    });
  });
  var _default = Plugin;
  _exports.default = _default;
});