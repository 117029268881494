define("client/pods/components/workflows/items/mark-as-closed/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let WorkflowsItemsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('workflows'), _dec3 = Ember.inject.service('date'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = (_temp = class WorkflowsItemsEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "workflowsService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dateService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "dirty", _descriptor5, this);
    }

    setupDirty() {
      var _this$args$workflow$_;

      const dirty = this.crud.setupDirty({
        adapterName: 'workflows/items',
        component: this,
        model: this.args.model,
        resultsProperty: 'dirty'
      });
      const isSuccessful = this.args.isSuccessful;
      dirty.setData('isSuccessful', isSuccessful);
      dirty.setData('isClosed', true);
      const nowZ = this.dateService.getNowZ();
      dirty.setData('dateZClosed', nowZ);

      if (isSuccessful) {
        dirty.setData('status', 'successful');
      } else {
        dirty.setData('status', 'unsuccessful');
      }

      if (this.args.isSuccessful && (_this$args$workflow$_ = this.args.workflow._data) !== null && _this$args$workflow$_ !== void 0 && _this$args$workflow$_.nextWorkflowKey) {
        var _this$args$workflow$_2;

        const nextWorkflowData = this.workflowsService.getNextWorkflow({
          dirty: this.dirty,
          workflows: this.args.workflows || [],
          nextWorkflowKey: (_this$args$workflow$_2 = this.args.workflow._data) === null || _this$args$workflow$_2 === void 0 ? void 0 : _this$args$workflow$_2.nextWorkflowKey
        });

        if (RA.isNotNilOrEmpty(nextWorkflowData.workflow)) {
          dirty.setData('workflowKey', nextWorkflowData.workflow.getData('_key'));

          if (RA.isNotNilOrEmpty(nextWorkflowData.stage)) {
            dirty.setData('stageId', nextWorkflowData.stage.getData('stageId'));
            dirty.set('stage', nextWorkflowData.stage.getData('name'));
          } // reset status when has next status


          dirty.setData('status', 'open');
          dirty.setData('isSuccessful', '');
          dirty.setData('isClosed', false);
          dirty.setData('dateZClosed', '');
        }
      }

      this.dirty = dirty;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "workflowsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dirty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupDirty", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype)), _class));
  _exports.default = WorkflowsItemsEditorComponent;
});