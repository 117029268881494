define("client/pods/websites/lists/sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll(data = {}, appendPath) {
      const list = [{
        value: 'settings',
        label: 'settings',
        component: 'websites/panels-display/sub-panels-settings',
        resource: 'api/protected/websites/lists/menu/sub-panels/settings',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/settings-editor'
      }, {
        value: 'webpages',
        label: 'webpages',
        component: 'websites/panels-display/sub-panels-pages',
        resource: 'api/protected/websites/lists/menu/sub-panels/webpages',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/pages-editor'
      }, {
        value: 'shop',
        label: 'shop',
        component: 'websites/panels-display/sub-panels-shop',
        resource: 'api/protected/websites/lists/menu/sub-panels/shop',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/shop-editor'
      }, {
        value: 'blogs',
        label: 'blogs',
        component: 'websites/panels-display/sub-panels-blogs',
        resource: 'api/protected/websites/lists/menu/sub-panels/blogs',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/blogs-editor'
      }, {
        value: 'news',
        label: 'news',
        component: 'websites/panels-display/sub-panels-news',
        resource: 'api/protected/websites/lists/menu/sub-panels/news',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/news-editor'
      }, {
        value: 'storeLocation',
        label: 'store locations',
        component: 'websites/panels-display/sub-panels-store-location',
        resource: 'api/protected/websites/lists/menu/sub-panels/store-location',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/store-location'
      }, {
        value: '3rdParty',
        label: '3rd party',
        component: 'websites/panels-display/sub-panels-3rd-party',
        resource: 'api/protected/websites/lists/menu/sub-panels/3rd-party',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/3rd-party-editor'
      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});