define("client/pods/components/ledgers/accounts/aging/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _date, _crud, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    adapterName: 'contacts/aging',
    filtersAdapterName: 'contacts/aging/filters',

    init() {
      this._super(...arguments);

      const tab = this.tab;
      const docType = tab.get('docType');
      this.set('docType', docType);
      const pastMonths = this.getPastMonths(12);
      this.set('pastMonths', pastMonths);
      const agingFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.agingFilters = agingFilters;
      this.crud.addLists(this, ['contacts/lists/allowed-payment-method']);
    },

    display(period, filters) {
      const subTabOptions = {
        component: 'ledgers/accounts/aging/panels-display',
        period: period,
        filters: filters
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    displayAgingTask: (0, _emberConcurrency.task)(function* (period) {
      const docType = this.docType;
      const yyyy = period.get('yyyy');
      const mm = period.get('mm');
      const allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);
      const agingFilters = this.agingFilters;
      agingFilters.setData('docType', docType);
      agingFilters.setData('yyyy', yyyy);
      agingFilters.setData('mm', mm);
      agingFilters.setData('allowedPaymentMethodForStatement', allowedPaymentMethodForStatement);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        appendPath: `/${docType}`,
        filters: agingFilters
      });
      this.display(period, agingFilters);
    })
  });

  _exports.default = _default;
});