define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-row/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Component.extend(CampaignsProductsMixin, CrudMixin, DroppableMixin, ErrorsMixin, {
  var _default = Ember.Component.extend({
    tagName: '',
    adapterName: 'channels/shop/campaigns/automations/details'
  });

  _exports.default = _default;
});