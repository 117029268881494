define("client/mixins/doc-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { isEmpty } from '@ember/utils'
  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      match,
      documentStatus,
      dispatchStatus,
      filters,
      query,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      isVoid,
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      if (match) {
        filters.setData('match', match);
      }

      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }

      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }

      if (isVoid) {
        filters.setData('isVoid', true);
      }

      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }

        filters.setData('documentStatus', documentStatus);
      }

      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }

        filters.setData('dispatchStatus', dispatchStatus);
      }

      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }

        filters.setData('notDispatchStatus', notDispatchStatus);
      }

      if (date) {
        filters.setData('match', ['dateZ']);
        filters.setData('query', date);
      }

      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }

      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }

      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }),
    listByDetailsTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      match,
      documentStatus,
      dispatchStatus,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      itemKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);

      if (match) {
        filters.setData('match', match);
      }

      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }

      if (itemKey) {
        filters.setData('itemKey', itemKey);
      }

      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }

      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }

        filters.setData('documentStatus', documentStatus);
      }

      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }

        filters.setData('dispatchStatus', dispatchStatus);
      }

      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }

        filters.setData('notDispatchStatus', notDispatchStatus);
      }

      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }

      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }

      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }

      const searchSettings = {
        adapterName,
        appendPath: '/details',
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);

      if (onHideDropdown) {
        onHideDropdown();
      }

      return results;
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.listByTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});