define("client/pods/components/elements/print-content-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCPO7fMC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"action\",[[23,0,[]],\"printDialog\"],null]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasPrintBtn\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[4,\"layouts/footer-btns\",null,null,{\"statements\":[[0,\"      \\n\"],[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-right\"]],{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-btn\",null,[[\"tooltipLabel\",\"icon\",\"onSubmit\"],[\"print\",\"fas fa-print\",[28,\"action\",[[23,0,[]],\"printDialog\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/print-content-container/template.hbs"
    }
  });

  _exports.default = _default;
});