define("client/pods/components/dispatch/export-excel-btn/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/big", "client/mixins/adapters-new", "client/mixins/crud", "client/utils/nventor", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _emberConcurrency, _date, _big, _adaptersNew, _crud, _nventor, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_adaptersNew.default, _big.default, _crud.default, _date.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    excel: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    exportService: Ember.inject.service('export'),
    dateService: Ember.inject.service('date'),
    tagName: '',
    isExporting: false,
    nextDispatchProcessCode: '',
    exportOrdersTotal: true,
    exportOrdersDetails: true,
    exportPayOnDeliveryOrders: true,
    exportPayOnDeliveryTransporter: true,
    exportPaidOrders: true,
    exportPaidTransporter: true,
    exportTransportersTotal: true,
    filtersAdapterName: 'channels/shop/campaigns/filters',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['dispatch/lists/processes' // 'dispatch/lists/statuses'
      ]);
      this.set('source', (this === null || this === void 0 ? void 0 : this.source) || '');
      this.set('sourceKey', (this === null || this === void 0 ? void 0 : this.sourceKey) || '');
      this.set('filename', this.getDefaultFilename());
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      this.set('dispatchDateStart', dateStart);
      this.set('dispatchDateEnd', dateEnd);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('campaignFilters', campaignFilters);

      if (this.dispatchProcess) {
        const model = this.crud.setupDirty({
          adapterName: 'dispatch/lists/processes',
          model: this.dispatchProcess
        });
        this.set('model', model);
      }

      const defaultExportSheets = this.get('model._data.defaultExportSheets') || [];
      const defaultExportCustomSheets = this.get('model._data.defaultExportCustomSheets') || {};
      this.set('sharedWorksheets', defaultExportSheets);
      this.set('customWorksheets', defaultExportCustomSheets);
      this.set('selectedDispatchProcess', []);
      this.set('selectedDispatchProcessCode', []);
    },

    getDefaultFilename() {
      const today = this.formatDate(this.getToday(), 'YYYYMMDD');
      return `export-${today}`;
    },

    fetchExportDataTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      sourceKey
    }) {
      // const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
      // const dispatchProcess = R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses)
      // this.set('dispatchProcess', dispatchProcess)
      // const period = this.get('period')
      // const filterData = {
      //   matches: [],
      //   count: 500,
      //   dispatchProcessesCriterias: dispatchProcess.getData('matches'),
      //   dispatchProcess: dispatchProcess.getData('code'),
      //   sourceKey,
      //   sort: [{
      //     by: 'transporterLabel',
      //     direction: 'ASC'
      //   }, {
      //     by: 'transporterAccount',
      //     direction: 'ASC'
      //   }, {
      //     by: 'timestampZ',
      //     direction: 'ASC'
      //   }]
      // }
      let dispatchProcessesCriterias = {};
      R.forEach(dispatchProcess => {
        if (RA.isNilOrEmpty(dispatchProcessesCriterias)) {
          dispatchProcessesCriterias = dispatchProcess.getData('criterias');
        } else {
          var _dispatchProcessesCri;

          const criterias = R_.dotPathOr([], '_data.criterias.dispatchProcesses')(dispatchProcess);
          dispatchProcessesCriterias.dispatchProcesses = R.concat(((_dispatchProcessesCri = dispatchProcessesCriterias) === null || _dispatchProcessesCri === void 0 ? void 0 : _dispatchProcessesCri.dispatchProcesses) || [], criterias);
        }
      })(this.selectedDispatchProcess);
      const filters = this.search.setupFilters({
        adapterName: 'dispatch/filters',
        defaultFiltersData: {
          count: 5000,
          dispatchProcessesCriterias,
          dispatchProcess: 'customSelected',
          sourceKey,
          sort: [{
            by: 'transporterLabel',
            direction: 'ASC'
          }, {
            by: 'transporterAccount',
            direction: 'ASC'
          }, {
            by: 'timestampZ',
            direction: 'ASC'
          }]
        }
      });
      let results = [];
      filters.setData('dateStart', this.dateStart);
      filters.setData('dateEnd', this.dateEnd);

      if (this.hasDispatchSelected) {
        filters.setData('dispatchDateStart', this.dispatchDateStart);
        filters.setData('dispatchDateEnd', this.dispatchDateEnd);
      }

      const path = 'api/protected/dispatch/processes/export';
      const response = yield this.server.call('GET', path, filters.serialize());
      results = R.pathOr([], ['data', 'docs'], response); // this.set('results', results)
      // this.setResultsAndSummaryData(results, 'unpaginatedResults')

      return results;
    }),
    fetchSpecsTask: (0, _emberConcurrency.task)(function* () {
      const specsCollection = yield this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      this.set('sharedSpecs', specsCollection._data.shared);
      this.set('specsCollection', specsCollection);
    }),
    exportTask: (0, _emberConcurrency.task)(function* ()
    /* filter */
    {
      yield this.fetchSpecsTask.perform();

      if (this.hasUpdateStatus && !this.nextDispatchProcessCode) {
        this.set('errorMsg', 'please select');
        return false;
      }

      this.set('isExporting', true);
      let filename = this.filename;

      if (!filename) {
        filename = this.getDefaultFilename();
      }

      const dispatchProcess = this.dispatchProcess;
      const period = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      };
      const sourceKey = this.sourceKey;
      let serialized;

      if (this.onFetchExportDataTask) {
        // use passed in fetch task
        serialized = this.onFetchExportDataTask();
      }

      serialized = yield this.fetchExportDataTask.perform({
        dispatchProcess,
        period,
        sourceKey
      });
      const serializedCustomSpecs = this.specsCollection.getSerializedByProp({
        prop: 'custom',
        worksheets: this.customWorksheets,
        selected: true
      });
      const serializedSharedSpecs = this.specsCollection.getSerializedByProp({
        prop: 'shared',
        worksheets: this.sharedWorksheets,
        selected: false
      });
      let newSerializedCustomSpecs = [];
      R.forEach(customWorksheet => {
        const key = R_.dotPathOr('', '_data.value', customWorksheet);
        R.forEach(serializedCustomSpec => {
          if (R.equals(key, serializedCustomSpec._key)) {
            newSerializedCustomSpecs = R.append(R.merge(customWorksheet, serializedCustomSpec), newSerializedCustomSpecs);
          }
        })(serializedCustomSpecs);
      })(this.customWorksheets);
      const worksheets = []; // const serialized = this.resultsData || []

      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);

        if (method) {
          const sharedSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized
          });
          worksheets.push(sharedSheetToPush);
        }
      })(serializedSharedSpecs);
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);

        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized
          });
          worksheets.push(customSheetToPush);
        }
      })(newSerializedCustomSpecs);

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });

        if (this.hasUpdateStatus) {
          yield this.setDispatchProcessAfterExportTask.perform();
        }

        this.set('isExporting', false);
        this.onCloseDialogAction();
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
    }).drop(),

    _exportOrders(specs, detailsSpecs = [], results, hasSummary = false) {
      const columns = R.pluck('column', specs);
      let detailProps = false;

      if (!R.isEmpty(detailsSpecs)) {
        detailProps = R.pluck('prop', detailsSpecs);
      }

      let index = 0; // let totalQty = 0

      let data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = [];

        if (this.exportOrdersDetails) {
          detailsRows = R.propOr([], 'details', doc);
        }

        let inclTotalBig = this.newBig(0); // detailsRows = R.pipe(
        // @TODO: filter by product/tag/supplier
        // R.filter(
        //   detail => {
        //     if (R.pathEq(['_data', 'itemKey'], '3040094470030', detail)) {
        //       totalQty = totalQty + parseInt(R.path(['_data', 'qty'], detail))
        //     }
        //     return R.pathEq(['_data', 'itemKey'], '3040094470030', detail) || R.pathEq(['_data', 'item'], '運費', detail)
        //   }
        // ),

        detailsRows = R.map(detailData => {
          inclTotalBig = inclTotalBig.plus(detailData.postDiscInclAmt);
          return this._getDataBySpecs({
            data: detailData
          }, detailsSpecs);
        })(detailsRows); // return R.pipe(
        //   R.pick(detailProps),
        //   R.values,
        //   R.concat(['']),
        //   R.concat([''])
        // )(detailData)
        // })
        // )(detailsRows)
        // }

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          inclTotalBig,
          index
        }, specs);

        if (detailProps) {
          return R.concat([docInfoRow], detailsRows);
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);

      if (hasSummary && detailProps) {
        data = this._summaryRows(data, results);
      }

      return data;
    },

    _summaryRows(data, results) {
      const intl = this.intl;

      const groupByItem = doc => {
        const itemKey = R.prop('itemKey')(doc);

        if (itemKey) {
          return itemKey;
        }

        return R.prop('item')(doc);
      };

      let grandTotal = 0;
      data = R.pipe(R.pluck('details'), R.flatten, R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
        // const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0
        const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', groupData)) || 0;
        const groupedByPrice = R.groupBy(R.prop('discInclPrice'))(groupData);
        const item = R.pipe(R.head, R.propOr('', 'item'))(groupData);
        const itemCode = R.pipe(R.head, R.propOr('', 'itemCode'))(groupData);
        const byPricesRows = R.pipe(R.mapObjIndexed((byPriceGroupData, groupPrice) => {
          const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', byPriceGroupData)) || 0;
          const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', byPriceGroupData)) || 0;
          groupPrice = R.pipe(this.bigService.toFixed(2), parseInt)(groupPrice);
          return ['', item, itemCode, groupTotalQty, groupPrice, groupTotalInclAmt];
        }), R.values)(groupedByPrice);
        grandTotal = grandTotal + groupTotalInclAmt;
        return byPricesRows; // let mainRow = [
        //   groupTotalQty,
        //   item,
        //   itemCode,
        //   groupTotalInclAmt
        // ]
        // // if (byPricesRows.length > 1) {
        // mainRow = R.concat([mainRow])(byPricesRows)
        // return mainRow
        // }
        // return [mainRow]
      }), R.values, R.unnest, R.concat([[], ['', intl.t('product'), intl.t('code'), intl.t('qty'), intl.t('price'), intl.t('inclusive amount')]]), R.concat(data), R.append([]))(results);
      data = R.append(['', '', '', intl.t('total'), grandTotal], data);
      return data;
    },

    _exportSummary(today, specs, detailSpecs = [], results, hasSummary = false) {
      // const intl = this.get('intl')
      const allSpecs = R.concat(specs, detailSpecs);
      const columns = R.pluck('column', allSpecs); // const props = R.pluck('prop', specs)

      if (!R.is(Array)(detailSpecs)) {
        detailSpecs = [detailSpecs];
      }

      let detailProps = false;

      if (!R.isEmpty(detailSpecs)) {
        detailProps = R.pipe(R.pluck('prop'), R.uniq)(detailSpecs);
      }

      let index = 0; // let totalQty = 0

      const data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = R.propOr([], 'details', doc);
        detailsRows = R.pipe(R.map(R.pick(['item', 'qty'])), R.groupBy(R.prop('item')), R.mapObjIndexed(group => {
          return R.reduce((acc, detail) => {
            acc.item = detail.item;
            const qty = parseInt(detail.qty);
            acc.qty = qty + acc.qty;
            return acc;
          }, {
            qty: 0
          })(group);
        }), R.values, R.flatten)(detailsRows);
        detailsRows = this._getDataBySpecs({
          data: detailsRows
        }, detailSpecs);

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          index,
          today
        }, specs);

        if (detailProps) {
          return [R.concat([docInfoRow[0], docInfoRow[1]], detailsRows[0])];
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);
      return data;
    },

    _getDataBySpecs({
      data,
      inclTotalBig = false,
      index,
      today
    }, specs) {
      return R.map(spec => {
        let props = R.prop('prop', spec);

        if (!R.is(Array, props)) {
          props = [props];
        }

        const values = R.map(prop => {
          return this._getPropData({
            today,
            index,
            data,
            spec,
            prop,
            inclTotalBig
          });
        })(props);

        if (props.length > 1) {
          const joinWith = R.prop('joinWith', spec) || '-';
          return R.pipe(R.reject(RA.isNilOrEmpty), R.join(joinWith))(values);
        }

        return _nventor.default.safeHeadOr('', values);
      }, specs);
    },

    _getPropData({
      today,
      index,
      data,
      spec,
      prop,
      inclTotalBig
    }) {
      const intl = this.intl;

      if (prop == null) {
        return '';
      }

      if (spec.returnProp) {
        return R.propOr('', spec.returnProp)(data);
      }

      const transform = R.prop('transform')(spec);
      let value = R_.dotPathOr('', prop, data);

      if (prop === '_index_') {
        value = index;
      }

      if (prop === '_today_') {
        return today;
      }

      if (prop === 'paymentMethodKey') {
        const v = R_.dotPathOr('', prop, data);

        if (v) {
          value = intl.t(v);
        }
      }

      if (prop === '_inclTotal_' && inclTotalBig) {
        value = inclTotalBig.toFixed(0);
      }

      if (transform) {
        value = transform(value, data);
      }

      return value;
    },

    exportData({
      results,
      filterByProp,
      filterValue,
      isReject = false,
      fromNo,
      toNo
    } = {}) {
      if (fromNo != null && toNo != null) {
        results = R.pipe(R.drop(fromNo), R.take(toNo))(results);
      }

      let filterByPath = filterByProp;

      if (!R.is(Array, filterByProp)) {
        filterByPath = [filterByProp];
      }

      if (filterByProp) {
        if (isReject) {
          results = R.reject(R.pathEq(filterByPath, filterValue))(results);
        } else {
          results = R.filter(R.pathEq(filterByPath, filterValue))(results);
        }
      }

      return results;
    },

    hasUpdateStatus: Ember.computed('exportOption', function () {
      if (this.exportOption === 'exportAndUpdateStatus') {
        return true;
      }

      return false;
    }),
    resultsData: Ember.computed('filteredResults.[]', function () {
      const results = this.filteredResults;
      return R.pipe(R.map(result => {
        const model = result.model;
        return this.serialize(model);
      }))(results);
    }),
    hasDispatchSelected: Ember.computed('selectedDispatchProcessCode.[]', function () {
      const selectedDispatchProcessCode = this.selectedDispatchProcessCode;

      if (R.includes('dispatched', selectedDispatchProcessCode)) {
        return true;
      }

      return false;
    }),
    actions: {
      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      },

      selectDispatchProcess(dispatchProcess) {
        this.set('dispatchProcess', dispatchProcess);
      },

      addDispatchProcess(dispatchProcess) {
        let selectedDispatchProcess = this.get('selectedDispatchProcess');
        selectedDispatchProcess = R.append(dispatchProcess, selectedDispatchProcess);
        this.set('selectedDispatchProcess', selectedDispatchProcess);
      },

      removeDispatchProcess(dispatchProcess) {
        let selectedDispatchProcess = this.get('selectedDispatchProcess');
        selectedDispatchProcess = R.reject(selected => {
          var _selected$_data;

          return R.pathEq(['_data', 'code'], (selected === null || selected === void 0 ? void 0 : (_selected$_data = selected._data) === null || _selected$_data === void 0 ? void 0 : _selected$_data.code) || '', dispatchProcess);
        }, selectedDispatchProcess);
        this.set('selectedDispatchProcess', selectedDispatchProcess);
      },

      loadShopSource(shop) {
        this.set('sourceKey', shop.getData('_key'));
      },

      unloadShopSource() {
        this.set('sourceKey', '');
      }

    }
  });

  _exports.default = _default;
});