define("client/pods/grapesjs/plugins/tabs/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = ({
    intl = {
      t: txt => txt
    }
  }) => ({
    // Object to extend the default tabs block, eg. `{ label: 'Tabs', attributes: { ... } }`
    // Pass a falsy value to avoid adding the block
    tabsBlock: {},
    // Object to extend the default tabs properties, eg. `{ name: 'My Tabs', droppable: false, ... }`
    tabsProps: {},
    // Object to extend the default tab container properties
    tabContainerProps: {},
    // Object to extend the default tab properties
    tabProps: {},
    // Object to extend the default tab content properties
    tabContentProps: {},
    // Object to extend the default tab contents properties
    tabContentsProps: {},
    // Default class to use on tab
    classTab: 'bigordr-tab',
    // Default class to use on tab container
    classTabContainer: ['bigordr-tab-container', 'bigordr-tab-container__sticky-tabs'],
    // Class used on tabs when active
    classTabActive: 'bigordr-tab-active',
    // Default class to use on tab content
    classTabContent: 'bigordr-tab-content',
    // Default class to use on tab contents
    classTabContents: 'bigordr-tab-contents',
    // The attribute used inside tabs as a selector for tab contents
    selectorTab: 'aria-controls',
    // Tabs component id
    typeTabs: 'tabs',
    // TabContainer component id
    typeTabContainer: 'tab-container',
    // Tab component id
    typeTab: 'tab',
    // TabContent component id
    typeTabContent: 'tab-content',
    // TabContents component id
    typeTabContents: 'tab-contents',
    // Default template for new tabs
    templateTab: () => ({
      type: 'text',
      tagName: 'span',
      draggable: false,
      content: intl.t('tab name')
    }),
    // Default template for new tab contents
    templateTabContent: () => [{
      type: 'row',
      draggable: false
    }],
    intl: {
      t: txt => txt
    }
  });

  _exports.default = _default;
});