define("client/pods/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // inject ember intl service
    intl: Ember.inject.service(),
    storage: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    beforeModel: function () {
      // set locale
      // @TODO: set locale dynamically based on url/location
      // let preferredLanguage = this.get('storage').get('preferredLanguage')
      // if (!preferredLanguage) {
      const preferredLanguage = 'zh-tw'; // }
      // this.get('storage').set('preferredLanguage', preferredLanguage)

      return this.intl.setLocale(preferredLanguage);
    }
  });

  _exports.default = _default;
});