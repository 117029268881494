define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-qty/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: '',

    init() {
      this._super(...arguments);

      this.filters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          onlyForCampaignKey: this.master._data._key,
          resultsProperty: 'productsList' // excludes: [{
          //   key: 'setType',
          //   value: 'isSetWithChoices'
          // }, {
          //   key: 'setType',
          //   value: 'hasVariants'
          // }]

        }
      });
    }

  });

  _exports.default = _default;
});