define("client/pods/components/faqs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2chDoNcn",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@adapterName\",\"@lists\",\"@model\",\"@tab\",\"@isDisabled\",\"@isReadonly\"],\"statements\":[[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"onPaginationTask\",\"onReloadSearchRecordsTask\",\"onRemove\",\"onSearchTask\",\"results\",\"tableViewOptions\"],[[23,5,[]],[23,0,[\"detailsRowComponent\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"reloadSearchRecordsTask\"]],[28,\"fn\",[[23,0,[\"addFaq\"]],[24,[\"onCloseDialogAction\"]]],null],[24,[\"searchAdvancedTask\"]],[23,0,[\"faqsProxy\"]],[28,\"hash\",null,[[\"adapterName\",\"isDisabled\",\"isReadonly\"],[[23,2,[]],[23,6,[]],[23,7,[]]]]]]]],false],[0,\"\\n\\n\"],[4,\"forms/form-row\",null,null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"modalDialogTitle\",\"icon\",\"disabled\",\"closeOnOverlayClick\"],[\"frequently asked questions\",\"fas fa-plus\",[23,6,[]],false]],{\"statements\":[[0,\"\\n    \"],[5,\"faqs/faqs-editor\",[],[[\"@adapterName\",\"@isDisabled\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@lists\",\"@model\",\"@onAfter\",\"@tab\"],[[23,2,[]],false,false,true,false,[23,3,[]],[23,4,[]],[28,\"fn\",[[23,0,[\"addFaq\"]],[23,1,[]]],null],[23,5,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/faqs/template.hbs"
    }
  });

  _exports.default = _default;
});