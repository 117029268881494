define("client/pods/components/products/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5qviaSxx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"products/products-editor\",null,[[\"model\",\"originalModel\",\"errors\",\"isEditing\",\"adapterName\",\"isRunning\",\"onSaveTask\",\"onDash\",\"tab\",\"onCancel\"],[[24,[\"dirty\"]],[24,[\"model\"]],[24,[\"errors\"]],true,[23,0,[\"adapterName\"]],[28,\"or\",[[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],[23,0,[\"crud\",\"removeRecordTask\",\"isRunning\"]]],null],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"excludeProps\",\"onAfter\",\"tab\"],[[23,0,[\"adapterName\"]],[24,[\"dirty\"]],[28,\"array\",[\"faqs\"],null],[24,[\"onDisplay\"]],[24,[\"tab\"]]]]]],null],[24,[\"onDash\"]],[24,[\"tab\"]],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\",\"onAfter\"],[[24,[\"dirty\"]],[28,\"action\",[[23,0,[]],[24,[\"onDisplay\"]],[24,[\"model\"]]],null]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});