define("client/pods/workflows/model", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "client/pods/base/model", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _applyDecoratedDescriptor2, _model, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    defaults() {
      return {
        related: [],
        stages: []
      };
    },

    populate(data = {}) {
      data.stages = this._transformStages(data);
      data.related = this._transformRelated(data);
      data.users = this._transformUsers(data);
      data.tags = R.pipe(R.propOr([], 'tags'), _nventor.default.safeHeadOr(''))(data);

      this._super(...arguments);
    },

    _transformStages(data) {
      return R.pipe(R.propOr([], 'stages'), R.reject(RA.isNilOrEmpty), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/stages',
          data
        });
      }))(data);
    },

    addStage(data = {}) {
      data = data || {};
      const stages = this.getOrSetData('stages', []); // get last index

      let index = 0;
      const last = stages.get('lastObject');

      if (RA.isNotNilOrEmpty(last)) {
        var _last$_data;

        index = (last === null || last === void 0 ? void 0 : (_last$_data = last._data) === null || _last$_data === void 0 ? void 0 : _last$_data.index) + 1;
      }

      data.index = index;
      const stage = this.dataManager.setAsRecord({
        adapterName: 'workflows/stages',
        data
      });
      stages.pushObject(stage);
      this.setData('stages', stages);
      return stage;
    },

    removeStage(stage) {
      const stages = this.getData('stages') || [];
      stages.removeObject(stage);
    },

    removeUnassignedStage() {
      const stages = this.getData('stages') || [];
      const unassigned = R.find(R.pathEq(['_data', 'stageId'], 'unassigned'))(stages);
      stages.removeObject(unassigned);
    },

    getNewItemData() {
      let stages = this.getData('stages') || [];
      stages = R.reject(R.pathEq(['_data', 'stageId'], 'unassigned'))(stages);
      const firstStage = stages.get('firstObject');

      if (RA.isNotNilOrEmpty(firstStage)) {
        var _this$_data, _this$_data2, _firstStage$_data;

        return {
          workflowKey: (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data._key,
          users: (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : _this$_data2.users,
          stageId: (_firstStage$_data = firstStage._data) === null || _firstStage$_data === void 0 ? void 0 : _firstStage$_data.stageId
        };
      }

      return {};
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "addStage", [_dec], Object.getOwnPropertyDescriptor(_obj, "addStage"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "removeStage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeStage"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "removeUnassignedStage", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeUnassignedStage"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "getNewItemData", [_dec4], Object.getOwnPropertyDescriptor(_obj, "getNewItemData"), _obj)), _obj)));

  _exports.default = _default;
});