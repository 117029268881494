define("client/pods/components/settings/settings-editor/company-editor/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      unsetDefaultLogo() {
        const model = this.model;
        model.setData('defaultLogo', '');
      },

      setDefaultLogo(resource, resourceKey, file) {
        const model = this.model;
        const uuid = file.uuid || R.path(['_data', 'uuid'], file);
        model.setData('defaultLogo', uuid);
        return new Ember.RSVP.Promise(function (resolve
        /* , reject */
        ) {
          resolve(true);
        });
      }

    }
  });

  _exports.default = _default;
});