define("client/pods/payment-methods/shop/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'creditCard',
        hasPaymentTerms: false,
        label: 'credit card',
        index: 1
      }, {
        value: 'cash',
        hasPaymentTerms: false,
        label: 'cash',
        index: 2
      }, {
        value: 'cashBeforeDelivery',
        hasPaymentTerms: false,
        label: 'cash before delivery',
        index: 3
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});