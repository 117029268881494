define("client/pods/transporter/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let TransporterService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = class TransporterService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "listsIsLoaded", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "externalTransportersList", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "transporterAccounts", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "transportersList", _descriptor5, this);
      this.crud.addLists(this, ['contacts/transporters']);
    }

    get transportersList() {
      const transporters = this.crud.lists['contacts-transporters'] || [];

      if (this.externalTransportersList) {
        return this.externalTransportersList;
      }

      return transporters || [];
    }

    get defaultTransporterAccount() {
      const transporterAccounts = this.transporterAccounts || [];
      const defaultAccount = R.find(R.pathEq(['_data', 'isDefault'], true))(transporterAccounts);

      if (RA.isNilOrEmpty(defaultAccount)) {
        return R.head(transporterAccounts) || {};
      }

      return defaultAccount;
    }

    get defaultTransporterAccountNumber() {
      return this.get('defaultTransporterAccount._data.account') || '';
    }

    loadTransporterAccounts(model, transporter) {
      const transporterAccounts = transporter.getData('transporterAccounts') || [];
      Ember.set(this, 'transporterAccounts', transporterAccounts);

      if (!model.getData('transporterAccountNumber')) {
        const setDefaultTransporterAccount = R.pipe(R.filter(account => account.getData('isDefault') === true), R.map(defaultAccount => {
          model.setData('transporterAccount', defaultAccount.getData('name'));
          model.setData('transporterAccountNumber', defaultAccount.getData('account'));
        }));
        setDefaultTransporterAccount(transporterAccounts);
      }
    }

    *fetchTransporterTask({
      model
    }) {
      const transporterKey = model.getData('transporterKey');

      if (transporterKey) {
        const transporter = yield this.crud.findTask.perform({
          adapterName: 'contacts',
          appendPath: transporterKey
        });
        this.loadTransporterAccounts(model, transporter);
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listsIsLoaded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "externalTransportersList", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transporterAccounts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transportersList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype)), _class));
  _exports.default = TransporterService;
});