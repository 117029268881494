define("client/pods/components/products/panels-display/component", ["exports", "ramda", "client/mixins/crud", "client/utils/nventor", "ember-concurrency", "ramda-extension"], function (_exports, R, _crud, _nventor, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'products',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    trackingTagLabel: Ember.computed(function () {
      return this.intl.t('tracking') + ': ';
    }),

    init() {
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/lists/displaySubPanels', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/setTypes/variants', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'statuses']);

      this._super(...arguments);

      this.setup();
    },

    setup() {
      const model = this.get('tab.model'); // images is required to be an array
      // if (!isArray(model.getData('images'))) {
      //   model.setData('images', [])
      // }

      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchParentModelTask.perform();
    },

    fetchParentModelTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const isVariantChildOf = model.getData('isVariantChildOf');

      if (isVariantChildOf) {
        const adapterName = 'products';
        const appendPath = `/${isVariantChildOf}`;
        const parent = yield this.findTask.perform({
          adapterName,
          appendPath
        });
        this.set('parent', parent);
      }
    }),

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      const subPanels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    displaySubPanels: Ember.computed('crud.lists.products-lists-displaySubPanels.[]', 'model._data.setType', function () {
      let panels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];
      let removeAll = false;
      const setType = this.get('model._data.setType');

      if (this.settings.getProp('beta.productSets')) {
        if (_nventor.default.isNilOrEmpty(setType)) {
          removeAll = true;
        }
      }

      if (setType !== 'isSet' && setType !== 'isSetWithChoices' && setType !== 'hasVariants') {
        removeAll = true;
      }

      if (setType === 'isSet' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      if (setType === 'isSetWithChoices' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
      }

      if (setType === 'hasVariants' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      return panels;
    }),

    /**
     * dynamically determine classname for status tag.
     * green if active, otherwise gray for inactive
     */
    statusTagClassName: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');

      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    }),

    displaySubPanel(resourceData) {
      const subTabOptions = {
        component: resourceData.getData('component'),
        value: resourceData.getData('value'),
        docType: resourceData.getData('docType'),
        id: resourceData.getData('value')
      };
      const tab = this.tab;
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    },

    actions: {
      editPanel() {
        const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
        const subPanels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];
        const subTabOptions = subPanels.findBy('_data.component', activeSubTab.get('component'));
        const tabOptions = {
          loadInstructions: {
            component: subTabOptions.get('_data.editingComponent'),
            value: subTabOptions.get('_data.value')
          }
        };
        this.onEdit(this.model, tabOptions);
      },

      prepareDuplicateRecord() {
        const model = this.model;
        const adapterName = this.adapterName;
        const adapter = this.dataManager.getAdapter(adapterName);
        const data = adapter.serialize(model);
        const newModel = this.setupNewRecord({
          adapterName,
          data
        });
        let name = model.getData('name');
        let code = model.getData('code');
        let barcode = model.getData('barcode');
        name = `${name}-copy-` + _nventor.default.random.digits(3);

        if (code) {
          code = `${code}-copy-` + _nventor.default.random.digits(3);
        }

        if (barcode) {
          barcode = `${barcode}-copy-` + _nventor.default.random.digits(3);
        }

        newModel.setData('status', this.get('constants.status.active'));
        newModel.setData('name', name);
        newModel.setData('password', '');
        newModel.setData('code', code);
        newModel.setData('barcode', barcode);
        newModel.setData('_key', '');
        newModel.setData('_id', '');
        newModel.setData('_rev', '');
        newModel.setData('modified', '');
        newModel.setData('created', '');
        newModel.setData('copyFromKey', model.getData('_key'));
        newModel.setData('copyImages', true);
        this.set('dirty', newModel);
      },

      cancelCopyProduct(onCloseDialogAction) {
        const dirty = this.dirty;
        const adapterName = this.adapterName;
        this.dataManager.cancelDirty(adapterName, dirty);
        onCloseDialogAction();
      },

      displayProduct(onCloseDialogAction, saved) {
        const dirty = this.dirty;
        const adapterName = this.adapterName;
        this.dataManager.cancelDirty(adapterName, dirty);
        this.set('dirty', '');
        onCloseDialogAction();
        return this.onDisplay(saved);
      }

    }
  });

  _exports.default = _default;
});