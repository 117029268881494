define("client/pods/products/templates/model", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "client/pods/base/model", "ramda"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let ProductsTemplatesModel = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class ProductsTemplatesModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "_data", _descriptor, this);
    }

    defaults() {
      return {
        template: '',
        header: this.intl.t('product information'),
        status: this.get('constants.status.active')
      };
    }

    replaceTemplate(template, templateImages = []) {
      this.setData('template', template);
      this.setData('templateImages', templateImages);
    }

    insertTemplate(template, templateImages = []) {
      const currentTemplate = this.getData('template');
      const currentTemplateImages = this.getData('templateImages') || [];
      let newTemplate = '';

      if (currentTemplate) {
        newTemplate = `${currentTemplate}<br>`;
      }

      newTemplate = `${newTemplate} ${template}`;
      const newTemplateImages = R.concat(currentTemplateImages, templateImages);
      this.setData('template', newTemplate);
      this.setData('templateImages', newTemplateImages);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "replaceTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "replaceTemplate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "insertTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "insertTemplate"), _class.prototype)), _class));
  _exports.default = ProductsTemplatesModel;
});