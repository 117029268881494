define("client/pods/components/settings/panels-edit/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'settings',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model: this.get('tab.model')
      });
      this.set('dirty', dirty);
    },

    getActiveSubTab() {
      const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
      return {
        loadInstructions: {
          component: activeSubTab.get('component')
        }
      };
    },

    _display(model) {
      const tabOptions = this.getActiveSubTab();
      this.onDisplay(model, tabOptions);
    },

    afterCancelEditing(original) {
      this._display(original);
    },

    afterUpdateRecordTask(model) {
      this._display(model);
    }

  });

  _exports.default = _default;
});