define("client/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "client/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('protected', {
      path: 'p'
    }, function () {
      this.route('companies', {
        path: '/:user_name/:user_id'
      });
      this.route('company', {
        path: '/c/:user_name/:user_id/:company_name'
      });
    });
    this.route('guests', function () {
      this.route('shop', function () {
        this.route('login', {
          path: '/login/:guest_resource'
        });
        this.route('report', {
          path: '/report/:guest_resource'
        });
      });
    });
    this.route('payments', function () {
      this.route('card', function () {
        this.route('lists', function () {
          this.route('panels');
        });
      });
    });
  });
});