define("client/pods/channels/shop/campaigns/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'settings',
        label: 'settings',
        component: 'channels/shop/campaigns/campaigns-editor/settings-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'name',
          label: 'name'
        }, {
          context: 'shopUrl',
          label: 'shop url (at least 6 characters)'
        }, {
          context: 'password (at least 8 characters)',
          label: 'password'
        }, {
          context: 'shippingFee',
          label: 'shipping fee'
        }, {
          context: 'description',
          label: 'campaign_description'
        }, {
          context: 'dateStartZ',
          label: 'date start'
        }, {
          context: 'dateEndZ',
          label: 'date end'
        }, {
          context: 'autoEnd',
          label: 'automatically end campaign'
        }, {
          context: 'showCountdownTimer',
          label: 'show countdown timer'
        }, {
          context: 'allowPickup',
          label: 'allow pick up'
        }, {
          context: 'allowShipping',
          label: 'allow shipping'
        }, {
          context: 'transporterLabel',
          label: 'transporter'
        }, {
          context: 'transporterAccountNumber',
          label: 'transporter account'
        }, {
          context: 'shippingFeeType',
          label: 'shipping fee type'
        }, {
          context: 'shippingFee',
          label: 'shipping fee'
        }, {
          context: 'shippingFeeAlwaysCharge',
          label: 'always charge shipping fee'
        }, {
          context: 'shippingFeeCondition',
          label: 'free shipping for orders greater and equal to'
        }, {
          context: 'pickupLocations',
          label: 'pick up locations'
        }, {
          context: 'shopPaymentMethods',
          label: 'payment methods'
        }, {
          context: 'paymentMethodsDefault',
          label: 'default payment method'
        }, {
          context: 'cashPaymentMethodFee',
          label: 'cash payment fee'
        }, {
          context: 'bankingDetails',
          label: 'banking details'
        }, {
          context: 'autoPrintInvoices',
          label: 'auto print invoices'
        }, {
          context: 'commissionMethod',
          label: 'commission calculation'
        }, {
          context: 'commissionPercentage',
          label: 'commission % per item'
        }, {
          context: 'commissionFlat',
          label: 'commission amount per item'
        }, // {
        //   context: 'commissionBaseCost',
        //   label: 'base cost'
        // },
        {
          context: 'companyName',
          label: 'company name'
        }, {
          context: 'companyTel',
          label: 'companyTel'
        }, {
          context: 'contactsTags',
          label: 'customer tags'
        }]
      }, {
        value: 'webpages',
        label: 'webpages',
        component: 'channels/shop/campaigns/campaigns-editor/pages-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'template',
          label: 'home page'
        }, {
          context: 'expiredTemplate',
          label: 'message to display when campaign has ended'
        }]
      }, {
        value: 'advancedTemplate',
        label: 'advancedTemplate',
        component: 'websites/websites-editor/pages-editor',
        resource: 'api/protected/websites/lists/menu/sub-panels/webpages',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/pages-editor'
      }, // {
      //   value: 'products',
      //   label: 'products',
      //   component: 'channels/shop/campaigns/campaigns-editor/products-editor',
      //   tabType: 'reuseSubTab',
      //   errorsTracker: [
      //     {
      //       context: 'groupByTags',
      //       label: 'product grouping'
      //     },
      //     {
      //       context: 'details',
      //       label: 'products'
      //     }
      //   ]
      // },
      {
        value: 'emails',
        label: 'emails',
        component: 'channels/shop/campaigns/campaigns-editor/emails-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'emailFromName',
          label: 'from email name'
        }, {
          context: 'emailFromAddress',
          label: 'from email address'
        }, {
          context: 'emailSignature',
          label: 'email signature'
        }, {
          context: 'orderedEmailSubject',
          label: 'email subject'
        }, {
          context: 'orderedEmailMessage',
          label: 'email message'
        }, {
          context: 'orderedEmailCopyTo',
          label: 'send a copy of order email to address'
        }, {
          context: 'dispatchedEmailSubject',
          label: 'email subject'
        }, {
          context: 'dispatchedEmailMessage',
          label: 'email message'
        }]
      }, {
        value: '3rdParty',
        label: '3rd party',
        component: 'channels/shop/campaigns/campaigns-editor/3rd-party-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'facebookUrl',
          label: 'facebook page url'
        }, {
          context: 'instagramUrl',
          label: 'instagram url'
        }, {
          context: 'youtubeUrl',
          label: 'youtube channel url'
        }, {
          context: 'lineId',
          label: 'line id'
        }]
      }, {
        value: 'reward',
        label: 'reward',
        component: 'channels/shop/campaigns/campaigns-editor/reward-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'rewardName',
          label: 'reward name'
        }]
      }, {
        value: 'commissions',
        label: 'commissions',
        component: 'channels/shop/campaigns/panels-display/sub-panels-commissions',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/commissions',
        editingComponent: 'channels/shop/campaigns/campaigns-editor/commissions-editor'
      }, {
        value: 'bulk discounts',
        label: 'bulk discounts',
        component: 'channels/shop/campaigns/campaigns-editor/bulk-discounts-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'name',
          label: 'name'
        }]
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});