define("client/helpers/isEmpty", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      const obj = params[0];
      const prop = params[1];
      const value = obj.get(prop);
      return RA.isNilOrEmpty(value);
    }

  });

  _exports.default = _default;
});