define("client/pods/products/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../utils/nventor'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll(data = {}, appendPath) {
      const list = [{
        value: 'details',
        label: 'details',
        resource: 'api/protected/products/sub-panels-edit/details',
        component: 'products/products-editor/details-editor',
        tabType: 'reuseSubTab',
        errorsTracker: ['name', 'code', 'auto', 'unit', 'salesExclPrice', 'salesInclPrice', 'salesTaxRateKey', 'purchasesExclPrice', 'purchasesInclPrice', 'purchasesTaxRateKey', 'roundingInventoryQty', 'roundingInventoryPrice', 'weight', 'barcode', 'notes', 'openingOnBookQty', 'openingOnBookValue']
      }, {
        value: 'isSet',
        label: 'product_sets',
        resource: 'api/protected/products/sub-panels-edit/sets',
        component: 'products/products-editor/sets-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'setDetails',
          label: 'product set'
        }]
      }, {
        value: 'isSetWithChoices',
        label: 'product_sets_with_choices',
        resource: 'api/protected/products/sub-panels-edit/sets-with-choices',
        component: 'products/products-editor/sets-with-choices-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'choices',
          label: 'product set with choices'
        }]
      }, {
        value: 'hasVariants',
        label: 'variants',
        resource: 'api/protected/products/sub-panels-edit/variants',
        component: 'products/products-editor/variants-editor',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'variants',
          label: 'variants'
        }, {
          context: 'variants.options',
          label: 'variant options'
        }]
      }, {
        value: 'webpages',
        label: 'webpages',
        resource: 'api/protected/products/sub-panels-edit/webpages',
        component: 'products/products-editor/pages-editor',
        tabType: 'reuseSubTab',
        errorsTracker: ['description', 'template']
      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});