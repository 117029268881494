define("client/pods/settings/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'company',
        label: 'company',
        component: 'settings/settings-editor/company-editor',
        resource: 'api/protected/settings/sub-panels-edit/settings',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'company',
          label: 'company'
        }, {
          context: 'companyTel',
          label: 'telephone'
        }, {
          context: 'companyFax',
          label: 'fax'
        }, {
          context: 'taxNumber',
          label: 'tax number'
        }, {
          context: 'shopUrlPrefix',
          label: 'shop url prefix'
        }, {
          context: 'bankingDetails',
          label: 'banking details'
        }, {
          context: '3rdParty',
          label: '3rd party'
        }]
      }, {
        value: 'taxes',
        label: 'taxes',
        component: 'settings/settings-editor/taxes-editor',
        resource: 'api/protected/settings/sub-panels-edit/taxes',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'salesTaxMethodKey',
          label: 'tax on sales'
        }, {
          context: 'purchasesTaxMethodKey',
          label: 'tax on purchases'
        }, {
          context: 'tw_eInvoices',
          label: 'tw_print_eInvoices'
        }, {
          context: 'tw_invoices',
          label: 'tw_print_invoices'
        }, {
          context: 'autoPrintInvoices',
          label: 'tw_auto_print_invoices'
        }, {
          context: 'taxRateKey',
          label: 'tax rate'
        }]
      }, {
        value: 'products',
        label: 'products',
        component: 'settings/settings-editor/products-editor',
        resource: 'api/protected/settings/sub-panels-edit/products',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'inventoryType',
          label: 'product type'
        }, {
          context: 'inventoryTracking',
          label: 'track inventory'
        }, {
          context: 'roundingCurrency',
          label: 'currency decimal rounding'
        }, {
          context: 'roundingInventoryQty',
          label: 'product decimal places'
        }, {
          context: 'roundingInventoryPrice',
          label: 'product price rounding'
        }]
      }, {
        value: 'documents',
        label: 'documents',
        component: 'settings/settings-editor/documents-editor',
        resource: 'api/protected/settings/sub-panels-edit/documents',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'discountType',
          label: 'inclusive discount'
        }, {
          context: 'salesQuotesTerms',
          label: 'sales-quotes'
        }, {
          context: 'salesOrdersTerms',
          label: 'sales-orders'
        }, {
          context: 'salesInvoicesTerms',
          label: 'sales-invoices'
        }, {
          context: 'salesReturnsTerms',
          label: 'sales-returns'
        }, {
          context: 'salesConsignmentsTerms',
          label: 'sales-consignments'
        }, {
          context: 'salesConsignmentsReturnsTerms',
          label: 'sales-consignments-returns'
        }, {
          context: 'purchasesQuotesTerms',
          label: 'purchases-quotes'
        }, {
          context: 'purchasesOrdersTerms',
          label: 'purchases-orders'
        }, {
          context: 'purchasesInvoicesTerms',
          label: 'purchases-invoices'
        }, {
          context: 'purchasesReturnsTerms',
          label: 'purchases-returns'
        }, {
          context: 'purchasesConsignmentsTerms',
          label: 'purchases-consignments'
        }, {
          context: 'purchasesConsignmentsReturnsTerms',
          label: 'purchases-consignments-returns'
        }]
      }, {
        value: 'emails',
        label: 'emails',
        component: 'settings/settings-editor/emails-editor',
        resource: 'api/protected/settings/sub-panels-edit/emails',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'emailFromName',
          label: 'from email name'
        }, {
          context: 'emailFromAddress',
          label: 'from email address'
        }, {
          context: 'emailSignature',
          label: 'email signature'
        }, {
          context: 'orderedEmailSubject',
          label: 'email subject'
        }, {
          context: 'orderedEmailMessage',
          label: 'email message'
        }, {
          context: 'orderedEmailCopyTo',
          label: 'send a copy of order email to address'
        }, {
          context: 'returnsEmailSubject',
          label: 'email subject'
        }, {
          context: 'returnsEmailMessage',
          label: 'email message'
        }, {
          context: 'returnsEmailCopyTo',
          label: 'send a copy of order email to address'
        }, {
          context: 'dispatchedEmailSubject',
          label: 'email subject'
        }, {
          context: 'dispatchedEmailMessage',
          label: 'email message'
        }, {
          context: 'paymentReminderEmailDays',
          label: 'send payment reminder when overdue by'
        }, {
          context: 'paymentReminderEmailSubject',
          label: 'payment reminder email subject'
        }, {
          context: 'paymentReminderEmailMessage',
          label: 'payment reminder email message'
        }]
      }, {
        value: 'shipping',
        label: 'shipping',
        component: 'settings/settings-editor/shipping-editor',
        resource: 'api/protected/settings/sub-panels-edit/shipping',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'hctWaybillStart',
          label: 'first waybill number'
        }, {
          context: 'hctWaybillEnd',
          label: 'last waybill number'
        }]
      }, {
        value: 'shop',
        label: 'shop',
        component: 'settings/settings-editor/shop-editor',
        resource: 'api/protected/settings/sub-panels-edit/shop',
        tabType: 'reuseSubTab',
        errorsTracker: [{
          context: 'shopGroupByTags',
          label: 'product grouping'
        }, {
          context: 'shopAllowShipping',
          label: 'shipping'
        }, {
          context: 'shippingFeeType',
          label: 'shipping fee type'
        }, {
          context: 'shippingFee',
          label: 'shipping fee'
        }, {
          context: 'shippingFeeAlwaysCharge',
          label: 'shippingFeeAlwaysCharge'
        }, {
          context: 'shippingFeeCondition',
          label: 'free shipping for orders greater and equal to'
        }, {
          context: 'shopAllowPickup',
          label: 'pick up'
        }, {
          context: 'shopPaymentMethods',
          label: 'payment methods'
        }, {
          context: 'cashShopPaymentMethodFee',
          label: 'default payment method'
        }, {
          context: 'cashShopPaymentMethodFee',
          label: 'cash payment fee'
        }]
      }];
      data.pushObject({
        value: '3rd party',
        label: '3rd party',
        component: 'settings/settings-editor/3rd-party-editor',
        resource: 'api/protected/settings/sub-panels-edit/3rd-party',
        tabType: 'reuseSubTab'
      });
      data.pushObject({
        value: 'beta',
        label: 'beta',
        component: 'settings/settings-editor/beta-editor',
        resource: 'api/protected/settings/sub-panels-edit/beta',
        tabType: 'reuseSubTab'
      });
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});