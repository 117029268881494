define("client/pods/documents/lists/types/purchases/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../../utils/nventor'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: 'value',

    findAll(data = {}, appendPath) {
      const customData = {
        list: [{
          resource: '/api/protected/documents/purchases/quotes',
          label: 'purchases-quotes',
          value: 'purchases-quotes',
          docType: 'purchases',
          docName: 'quotes'
        }, {
          resource: '/api/protected/documents/purchases/orders',
          label: 'purchases-orders',
          value: 'purchases-orders',
          docType: 'purchases',
          docName: 'orders'
        }, {
          resource: '/api/protected/documents/purchases/consignments',
          label: 'purchases-consignments',
          value: 'purchases-consignments',
          docType: 'purchases',
          docName: 'consignments'
        }, {
          resource: '/api/protected/documents/purchases/consignments-returns',
          label: 'purchases-consignments-returns',
          value: 'purchases-consignments-returns',
          docType: 'purchases',
          docName: 'consignments-returns'
        }, {
          resource: '/api/protected/documents/purchases/invoices',
          label: 'purchases-invoices',
          value: 'purchases-invoices',
          docType: 'purchases',
          docName: 'invoices'
        }, {
          resource: '/api/protected/documents/purchases/returns',
          label: 'purchases-returns',
          value: 'purchases-returns',
          docType: 'purchases',
          docName: 'returns'
        }]
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});