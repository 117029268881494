define("client/pods/extensions/apps/tw/e-invoices/tracks/numbers/allocated/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      data.blanks = R.pipe(R.propOr([], 'blanks'), R.map(blank => {
        return Ember.Object.create(blank);
      }))(data);

      this._super(...arguments);

      this.checkBlanksAllowed(false);
    },

    addBlankNumberSet(blank = {
      startNumber: '',
      endNumber: ''
    }) {
      const blanks = this.getData('blanks') || [];
      blank.startNumber = '';
      blank.endNumber = '';
      blank = Ember.Object.create(blank);
      blanks.pushObject(blank);
      this.set('_data.blanks', blanks);
      return blank;
    },

    removeBlankNumberSet(blank) {
      const blanks = this.getData('blanks') || [];
      blanks.removeObject(blank);
      this.setData('blanks', blanks);
    },

    checkBlanksAllowed(isFocusOut) {
      const blanks = this.getData('blanks') || [];
      R.forEach(blank => {
        this._checkBlanksAllowed('startNumber', isFocusOut, blank);

        this._checkBlanksAllowed('endNumber', isFocusOut, blank);
      })(blanks);
    },

    _checkBlanksAllowed(prop, isFocusOut, blank) {
      const endNumber = this.getData('endNumberString') || this.getData('endNumber');
      const startNumber = this.getData('startNumberString') || this.getData('startNumber');
      const allowedProp = `${prop}Allowed`;
      const startNumberString = `${startNumber}`;
      let value = blank.get(prop);
      const valueString = `${value}`;

      if (valueString.length !== startNumberString.length) {
        blank.set(allowedProp, false);
        blank.set('numbersAllowed', false);
        return blank;
      }

      value = parseInt(value) || 0;

      if (value < parseInt(startNumber) || value > parseInt(endNumber)) {
        blank.set(allowedProp, false);
        blank.set('numbersAllowed', false);
        return blank;
      } // is within range
      // check if exists in other blanks


      blank.set('isSelf', true);
      const blanks = this.getData('blanks') || [];
      const isNotInOtherBlanks = R.reduce((acc, otherBlank) => {
        if (acc === false) {
          return false;
        }

        if (otherBlank.isSelf) {
          // dont compare when is itself
          return true;
        }

        if (value >= otherBlank.startNumber && value <= otherBlank.endNumber) {
          return false;
        }

        return true;
      }, true)(blanks);
      blank.set(allowedProp, isNotInOtherBlanks);
      const numbersAllowed = blank.get('startNumberAllowed') && blank.get('endNumberAllowed');
      blank.set('numbersAllowed', numbersAllowed);
      blank.set('isSelf', false);

      if (isFocusOut && numbersAllowed) {
        if (blank.startNumber > blank.endNumber) {
          const newEndNumber = blank.startNumber;
          const newStartNumber = blank.endNumber;
          blank.set('startNumber', newStartNumber);
          blank.set('endNumber', newEndNumber);
        }
      }

      return blank;
    },

    hasDisallowedBlanks: Ember.computed('_data.blanks.@each.numbersAllowed', function () {
      const blanks = this.getData('blanks') || [];
      return R.reduce((acc, blank) => {
        if (acc === true) {
          return true;
        }

        return !blank.numbersAllowed;
      }, false)(blanks);
    }),
    prefix: Ember.computed('_data.prefix', function () {
      return this._data.prefix;
    }),
    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    startNumber: Ember.computed('_data.startNumber', function () {
      return this._data.startNumber;
    }),
    endNumber: Ember.computed('_data.endNumber', function () {
      return this._data.endNumber;
    })
  });

  _exports.default = _default;
});