define("client/pods/components/users/users-selector/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'users/associates',
    filtersName: 'users/associates/filters',
    isSingleSelect: true,
    autofocus: false,

    init() {
      this.crud.addLists(this, ['users/lists/menu']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.set('filters', filters);
    },

    afterLoadLists() {
      this._super(...arguments);

      if (!this.readonly) {
        this.loadTabTask.perform();
      }
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters,
        component: this,
        resultsProperty: 'results'
      });
    }),
    actions: {
      toggleResults(results = []) {
        if (results.length > 0) {
          this.set('resultsToggle', true);
        }
      },

      assignSalesPerson(person) {
        if (this.onAssignSalesPerson) {
          this.onAssignSalesPerson(person);
        }

        this.set('resultsToggle', false);
      },

      unassignSalesPerson(person) {
        if (this.onUnassignSalesPerson) {
          this.onUnassignSalesPerson(person);
        }

        this.set('resultsToggle', false);
      }

    }
  });

  _exports.default = _default;
});