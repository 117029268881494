define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _ramda, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "crud", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dataManager", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "countries", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "regionQuery", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "districtQuery", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "temp", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "regionsByCountries", _descriptor8, this);
      (0, _defineProperty2.default)(this, "hasSelectedIcon", null);
      (0, _defineProperty2.default)(this, "tagAutoFocus", false);
      (0, _defineProperty2.default)(this, "isShowDeselectClassNames", null);
      const model = this.args.model;
      const countries = model.getData('countries') || [];
      this.countries = countries;

      const countryNames = _ramda.default.map(_ramda.default.path(['_data', 'code']))(countries);

      const temp = Ember.Object.create({
        countryNames
      });
      this.temp = temp;
      const regions = this.crud.lists['settings-countries-regions'] || [];

      const regionsByCountries = _ramda.default.groupBy(_ramda.default.path(['_data', 'code']))(regions);

      this.regionsByCountries = regionsByCountries;
    }

    updateCountries(selectedCountry) {
      const model = this.args.model;
      const countries = model.getData('countries') || [];
      const code = selectedCountry.getData('code');

      const toRemove = _ramda.default.find(_ramda.default.pathEq(['_data', 'code'], code))(countries);

      if (toRemove) {
        model.removeCountry(toRemove);
      } else {
        const code = selectedCountry.getData('code');
        const countryModel = model.newCountry({
          name: selectedCountry.getData('name'),
          code,
          regions: [],
          districts: []
        });
        model.addCountry(countryModel);
      }
    }

    onToggleSelectAllRegion() {
      const model = this.args.model;
      const countries = model.getData('countries') || [];

      _ramda.default.forEach(country => {
        var _country$_data;

        const code = country === null || country === void 0 ? void 0 : (_country$_data = country._data) === null || _country$_data === void 0 ? void 0 : _country$_data.code;
        const regions = this.crud.lists['settings-countries-regions'];

        const selectedRegions = _ramda.default.pipe(_ramda.default.filter(_ramda.default.pathEq(['_data', 'code'], code)), _ramda.default.map(_ramda.default.path(['_data', 'key'])))(regions);

        country.set('_data.regions', selectedRegions);
      })(countries);

      model.setData('countries', []);
      Ember.run.scheduleOnce('afterRender', () => {
        model.setData('countries', countries);
      });
    }

    onToggleDeselectAllRegion() {
      const model = this.args.model;
      const countries = model.getData('countries') || [];

      _ramda.default.forEach(country => {
        country.set('_data.regions', []);
      })(countries);

      model.setData('countries', []);
      Ember.run.scheduleOnce('afterRender', () => {
        model.setData('countries', countries);
      });
    }

    onToggleSelectRegionsGroup(isOutlying) {
      const isOutlyingValue = isOutlying.toString();
      const model = this.args.model;
      const countries = model.getData('countries') || [];

      _ramda.default.forEach(country => {
        const regions = this.crud.lists['settings-countries-regions'];

        const selected = _ramda.default.pipe(_ramda.default.filter(region => {
          const districts = _ramda.default.pipe(_ramda.default.pathOr([], ['_data', 'districts']), _ramda.default.filter(_ramda.default.pathEq(['_data', 'is_outlying_island'], isOutlyingValue)))(region);

          if (_ramdaAdjunct.default.isNotNilOrEmpty(districts)) {
            return true;
          }

          return false;
        }))(regions);

        const selectedRegions = _ramda.default.map(_ramda.default.path(['_data', 'key']))(selected);

        const selectedDistricts = _ramda.default.pipe(_ramda.default.map(region => {
          const districts = _ramda.default.pipe(_ramda.default.pathOr([], ['_data', 'districts']), _ramda.default.filter(_ramda.default.pathEq(['_data', 'is_outlying_island'], isOutlyingValue)), _ramda.default.map(_ramda.default.path(['_data', 'postcode'])))(region);

          const totalDistricts = _ramda.default.length(region._data.districts);

          if (totalDistricts !== _ramda.default.length(districts)) {
            return districts;
          }

          return false;
        }), _ramda.default.reject(_ramda.default.equals(false)), _ramda.default.flatten)(selected);

        country.set('_data.regions', selectedRegions);
        country.set('_data.districts', selectedDistricts);
      })(countries);

      model.setData('countries', []);
      Ember.run.scheduleOnce('afterRender', () => {
        model.setData('countries', countries);
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "countries", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "regionQuery", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "districtQuery", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "temp", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "regionsByCountries", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateCountries", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateCountries"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSelectAllRegion", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectAllRegion"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleDeselectAllRegion", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleDeselectAllRegion"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onToggleSelectRegionsGroup", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectRegionsGroup"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesComponent;
});